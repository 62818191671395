// 项目情况-平台情况
import React from "react";
import {
    Form,
    Row,
    Col,
    Radio,
    Card,
    Button,
    Input,
    Select,
    DatePicker,
    Table,
    Modal,
    Tooltip,
    Spin,
    message
} from 'antd';
import "./index.scss";
import {
    QyLogo,
    QyBackground,
    Iconrws,
    Iconrwsxg,
    Iconrwsyl,
    Iconwj,
    Iconsjzb,
    Iconndhb,
    Iconscbg,
    Icontbjsal,
    Iconfrlxra,
    Iconjxbg
} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {ScoolIcon2, Chengjiu, Tuandui, Upiconwc, Upicondele, Upicon} from "../../../components/iconSvg";
import XmtdAddmodal from './xmtd_Addmodal/index'
import {
    getProjectHeadBasic,
    getProjectMember,
    deleteProjectMember,
    updateProjectHeadBasic,
    addProjectMember,
    updateProjectMember
} from '../../../api/school'
import Cookies from "js-cookie";
import {isNotEmpty} from "../../../until/isEmpty";
import UploadFileComment from "./uploadFile";
import moment from "moment";
import {updateProcessStatus} from "../../../api/effect";

function disabledDate(current) {
    return current && current > moment().endOf('day');
}

const {MonthPicker} = DatePicker;
const monthFormat = 'YYYY-MM-DD';
const {TextArea} = Input;
export default Form.create()(
    withRouter(
        class Xiangmutd_Qk extends React.Component {
            state = {
                mainColumns: [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: '200',
                        render: (text, record, index) => {
                            return <span>{index + 1}</span>
                        }
                    },
                    {
                        title: '姓名',
                        dataIndex: 'memberName',
                        key: 'memberName',
                        align: 'center',
                        width: '200'
                    },
                    {
                        title: '所在单位',
                        dataIndex: 'memberUnit',
                        key: 'memberUnit',
                        align: 'center',
                        width: '150'
                    },
                    {
                        title: '专业技术职务',
                        dataIndex: 'twoLevelPositionName',
                        key: 'twoLevelPositionName',
                        align: 'center',
                        width: '200',
                    },
                    {
                        title: '行政职务',
                        dataIndex: 'administrativePosition',
                        key: 'administrativePosition',
                        align: 'center',
                        width: '150'
                    },
                    {title: '承担任务', dataIndex: 'bearTask', key: 'bearTask', align: 'center', width: '150'},
                    {
                        title: '人员来源',
                        dataIndex: 'personnelConstitute',
                        key: 'personnelConstitute',
                        align: 'center',
                        width: '150',
                        render: (text, record, index) => {
                            // return
                            if (text === 1) {
                                return "无"
                            } else if (text === 2) {
                                return "学校人员"
                            } else if (text === 3) {
                                return "企业人员"
                            } else {
                                return ""
                            }
                        }
                    },
                    {
                        title: '操作', dataIndex: '', key: '', align: 'center', width: '150',
                        render: (text, record) => {
                            return <div className={'cycaozuo'}>
                                <span onClick={() => this.showAddModal(record, '编辑成员')}>编辑</span>
                                <span onClick={() => this.deleteProjectMemberMethod(text, record)}>删除</span>
                            </div>
                        }

                    },
                ],
                // 团队成员列表
                groupPeopleList: [],
                xzcyModal: false,
                xzcyModalTile: '新增成员',
                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                sex: 0,
                projectNum: 0,
                schoolNum: 0,
                enterpriseNum: 0,
                enterpriseTechnologyNum: 0,
                technicalPositionList: [],
                fromDetail: [],
                saveUpdate: 0, // 0是保存  1是修改
                projectHeadId: '',
                fileModelList: [],
                academicDegreeList: [
                    {
                        value: 1,
                        title: '学士'
                    },
                    {
                        value: 2,
                        title: '硕士'
                    },
                    {
                        value: 3,
                        title: '博士'
                    },
                    {
                        value: 4,
                        title: '其他'
                    }
                ]
            };

            componentDidMount() {
                this.getProjectHeadBasic()
                this.getProjectMember()
            }

            // section 项目信息
            getProjectHeadBasic = () => {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    taskId: this.state.schoolInfo.taskId,
                }
                getProjectHeadBasic(data).then(res => {
                    console.log("getProjectHeadBasic: ", res)
                    if (res) {
                        this.setState({
                            sex: res.sex,
                            birthday: res.birthday,
                            projectHeadId: res.id,
                            fileModelList: res.files || []
                        }, () => {
                            let objc = {
                                userName: res.userName,
                                sex: res.sex,
                                // birthday: res.birthday,
                                education: res.education,
                                academicDegree: res.academicDegree,
                                workPhone: res.workPhone,
                                contactMode: res.contactMode,
                                userPost: res.userPost,
                                professionalTechnology: res.professionalTechnology,
                             //   department: res.department,
                                email: res.email,
                                userAchievement: res.userAchievement,
                                teamDescription: res.teamDescription,
                                projectTeam: res.projectTeam,
                            }

                            console.log("当前钩子", objc)
                            this.props.form.setFieldsValue(objc)
                        })

                    }

                })
            }
            // section 获取成员列表
            getProjectMember = () => {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    taskId: this.state.schoolInfo.taskId,
                }
                getProjectMember(data).then(res => {
                    if (res) {
                        console.log('获取成员列表 ', res)

                        let groupPeopleList = (res.isMainMemberVos ? res.isMainMemberVos : []).concat((res.notMainDataSource ? res.notMainDataSource : []))

                        this.setState({
                            // groupPeopleList: res.isMainMemberVos + res.notMainDataSource,
                            groupPeopleList: groupPeopleList,

                            projectNum: res.projectNum,
                            schoolNum: res.schoolNum,
                            enterpriseNum: res.enterpriseNum,
                            enterpriseTechnologyNum: res.enterpriseTechnologyNum,
                        })
                    }

                })
            }

            //新增、编辑成员显示弹窗
            showAddModal = (record, title) => {
                console.log('record', record)
                this.setState({
                    updateId: record.id,
                    saveUpdate: 1,
                    xzcyModal: true,
                    xzcyModalTile: title,
                    fromDetail: record
                })
            }

            // 添加成员按钮
            save = (record, title) => {
                console.log('record', record)
                this.setState({
                    saveUpdate: 0,
                    xzcyModal: true,
                    xzcyModalTile: title,
                    fromDetail: null,
                })
            }

            // section 人员信息保存/ 修改
            savePeople = () => {
                this.XmtdAddmodal.props.form.validateFields((err, value) => {
                    console.log('value', value)
                    if (!err) {
                        let peopleData = {
                            administrativePosition: value.administrativePosition,
                            bearTask: value.bearTask,
                            memberName: value.memberName,
                            memberUnit: value.memberUnit,
                            personnelConstitute: value.personnelConstitute,
                            unitNature: value.unitNature,
                            oneLevelPosition: value.oneLevelPosition,
                            twoLevelPosition: value.twoLevelPosition,
                        }
                        if (isNotEmpty(value.id)) {
                            // 修改
                            peopleData['id'] = value.id
                            console.log("上传参数")
                            console.log('data', peopleData)
                            updateProjectMember(peopleData).then(res => {
                                if (res) {
                                    this.getProjectMember()
                                }

                            })
                        } else {
                            // 新增
                            let addData = {
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                taskId: this.state.schoolInfo.taskId,
                                projectMemberQoList: [peopleData].concat((this.state.groupPeopleList ? this.state.groupPeopleList : []))
                            }

                            addProjectMember(addData).then(res => {
                                if (res) {
                                    this.getProjectMember()
                                }

                            })
                        }

                        this.setState({
                            xzcyModal: false
                        })
                    }
                })
            }

            // section 删除成员信息
            deleteProjectMemberMethod = (value) => {
                let data = {
                    id: value.id
                }

                deleteProjectMember(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getProjectMember()
                    }
                })
            }

            //关闭弹窗
            HandleCancel = () => {
                this.setState({
                    xzcyModal: false
                })
            }

            //编辑
            edit = () => {

            }
            preStep = () => {
                this.props.history.push({
                    pathname: '/project_school',
                })
            }

            // 下一步
            nextStep = () => {
                this.props.form.validateFields((err, value) => {
                    console.log(err)
                    if (!err) {
                        if (!this.state.fileModelList || this.state.fileModelList.length === 0) {
                            message.error("请先上传附件")
                            return;
                        }

                        if (this.state.groupPeopleList.length <= 0) {
                            message.error("请添加团队成员")
                            return
                        }
                        let Api_fileIdList = this.state.fileModelList.map(function (item) {
                            return item.id
                        })

                        let birthday = moment(new Date(value.birthday)).format(monthFormat);

                        let data = {
                            id: this.state.projectHeadId,
                            taskId: this.state.schoolInfo.taskId,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            userName: value.userName,
                            sex: value.sex,
                            birthday: birthday,
                            education: value.education,
                            academicDegree: value.academicDegree,
                            workPhone: value.workPhone,
                            contactMode: value.contactMode,
                            userPost: value.userPost,
                            professionalTechnology: value.professionalTechnology,
                         //   department: value.department,
                            email: value.email,
                            userAchievement: value.userAchievement,
                            teamDescription: value.teamDescription,
                            projectTeam: value.projectTeam,
                            fileIdList: Api_fileIdList
                        }

                        console.log("保存更新的参数:", data)

                        updateProjectHeadBasic(data).then(res => {
                            if (res) {
                                // 更新进度
                                let progressData = {
                                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                    taskId: this.state.schoolInfo.taskId,
                                    processCode: this.props.processCode
                                }

                                updateProcessStatus(progressData).then(res => {
                                    this.props.history.push({
                                        pathname: '/xmxxjs_renwushu',
                                    })
                                })
                            }
                        })
                    }
                });
            }

            //重置
            handleReset = () => {
                this.props.form.resetFields();
            }
            onRef = (ref) => {
                this.child = ref
            }
            //新建成员窗口保存
            // savePeople = () =>{
            //    this.setState({
            //
            //    },() =>{
            //        this.addmodalSave() //调用子组件新建成员窗口方法
            //    })
            // }

            // section 开始上传
            uploadFileCallBackResult = (fileModelList) => {
                console.log("call back", fileModelList)
                this.setState({
                    fileModelList: fileModelList
                })
            }

            change = (e) => {
                console.log("change", e)
            }

            // section 上传结束

            render() {
                const {getFieldDecorator, sex} = this.props.form
                const {
                    saveUpdate,
                    fromDetail,
                    projectNum,
                    schoolNum,
                    enterpriseNum,
                    enterpriseTechnologyNum,
                    mainColumns,
                    groupPeopleList,
                    xzcyModal,
                    xzcyModalTile,
                    fileModelList,
                    birthday,
                    academicDegreeList
                } = this.state;

                return (
                    <div className="xiangmutd_Qk">
                        <div className={'wrap'}>
                            <h3 className={'small-title'}
                                style={{fontSize: '16px'}}>
                                2-2-1 项目团队
                            </h3>
                            <Form>
                                <div className={'xmjibenxinxi'}>
                                    <h4 className={'ssmall-title'}><ScoolIcon2/>1. 项目负责人基本信息</h4>
                                    <div className={'group'}>
                                        <Form.Item className="Item-li" label={`姓名`}>
                                            {getFieldDecorator('userName', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写项目负责人姓名"
                                                    }
                                                ],
                                            })(<Input className="input"/>)}
                                        </Form.Item>
                                        <Form.Item className="Item-li" label={`性别`}>
                                            {getFieldDecorator('sex', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写项目负责人性别"
                                                    }
                                                ],
                                                initialValue: {sex}
                                            })(<Radio.Group>
                                                <Radio value={1}>男</Radio>
                                                <Radio value={2}>女</Radio>
                                            </Radio.Group>)}
                                        </Form.Item>
                                        {/*<Form.Item className="Item-li Item-li1" label={`出生日期`}>*/}
                                        {/*    {getFieldDecorator('birthday', {*/}
                                        {/*        rules: [*/}
                                        {/*            {*/}
                                        {/*                required: true,*/}
                                        {/*                message: `请选择填表日期`,*/}
                                        {/*            },*/}
                                        {/*        ],*/}
                                        {/*        initialValue: birthday? moment(birthday, 'YYYY-MM-DD') : null*/}

                                        {/*    })(<DatePicker className="input" format={monthFormat}*/}
                                        {/*                    dropdownClassName='Date-main'/>)}*/}
                                        {/*</Form.Item>*/}


                                        <Form.Item className="Item-li" label={`出生日期`}
                                                   labelCol={{style: {width: '104px'}}}>
                                            {getFieldDecorator('birthday', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: `请选择填表日期`,
                                                    },
                                                ],
                                                initialValue: birthday ? moment(birthday, 'YYYY-MM-DD') : null
                                            })(
                                                <DatePicker disabledDate={disabledDate}
                                                            className="input"
                                                            format={monthFormat}
                                                            dropdownClassName='Date-main'/>)
                                            }


                                        </Form.Item>

                                    </div>
                                    <div className={'group'}>
                                        <Form.Item className="Item-li" label={`学历`}>
                                            {getFieldDecorator('education', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写学历"
                                                    }
                                                ],
                                            })(<Input className="input"/>)}
                                        </Form.Item>
                                        <Form.Item className="Item-li" label={`学位`}>
                                            {getFieldDecorator('academicDegree', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请选择学位"
                                                    },
                                                ],
                                            })(<Select>
                                                {
                                                    academicDegreeList.map(item => (
                                                        <option value={item.value}>{item.title}
                                                        </option>
                                                    ))
                                                }
                                            </Select>)}
                                        </Form.Item>
                                        <Form.Item className="Item-li Item-li1" label={`办公电话`}>
                                            {getFieldDecorator('workPhone', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写办公电话"
                                                    },
                                                ],
                                            })(<Input className="input" placeholder={'请输入办公电话'}/>)}
                                        </Form.Item>
                                    </div>
                                    <div className={'group'}>
                                        <Form.Item className="Item-li" label={`联系方式`}>
                                            {getFieldDecorator('contactMode', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写联系方式"
                                                    },
                                                ],
                                            })(<Input className="input" placeholder={'请输入联系方式'}/>)}
                                        </Form.Item>
                                        <Form.Item className="Item-li" label={`行政职务`}>
                                            {getFieldDecorator('userPost', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写行政职务"
                                                    },
                                                ],
                                            })(<Input className="input" placeholder={'请输入行政职务'}/>)}
                                        </Form.Item>
                                        <Form.Item className="Item-li Item-li1" label={`职称`}>
                                            {getFieldDecorator('professionalTechnology', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写职称"
                                                    },
                                                ],
                                            })(<Input className="input" placeholder={'请输入职称'}/>)}
                                        </Form.Item>
                                    </div>
                                    <div className={'group'}>
                                        {/* <Form.Item className="Item-li" label={`院系`}>
                                            {getFieldDecorator('department', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写院系"
                                                    },
                                                ],
                                            })(<Input className="input" placeholder={'请输入院系'}/>)}
                                        </Form.Item> */}
                                        <Form.Item className="Item-li" label={`电子邮箱`}>
                                            {getFieldDecorator('email', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "电子邮箱"
                                                    },
                                                ],
                                            })(<Input className="input" placeholder={'请输入电子邮箱'}/>)}
                                        </Form.Item>
                                    </div>

                                </div>
                                <div className={'chengjiu'}>
                                    <h4 className={'ssmall-title'}><Chengjiu/>2. 项目负责人代表性成就</h4>
                                    <Form.Item>
                                        <div className={'textare-wrap'}>
                                            {getFieldDecorator('userAchievement', {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写代表性成就"
                                                    },
                                                ],
                                            })(<TextArea
                                                className="textare"
                                                maxLength={1000}
                                                placeholder={'请简述项目负责人近5年来与基地建设相关的成果及获奖情况'}>
                                            </TextArea>)}

                                        </div>

                                    </Form.Item>

                                    <Form.Item>
                                        <div className={"textAreaBottomView"}>
                                            <UploadFileComment
                                                props_fileModelList={fileModelList}
                                                uploadResult={this.uploadFileCallBackResult}
                                            />
                                            <div className='textarea_len'>
                                                {this.props.form.getFieldValue(`userAchievement`) ? this.props.form.getFieldValue(`userAchievement`).length : 0}/1000
                                            </div>
                                        </div>
                                    </Form.Item>
                                </div>
                                {/*<div className={'chengjiu'}>*/}
                                {/*    <h4 className={'ssmall-title'}><Tuandui/>项目团队情况</h4>*/}
                                {/*    <Form.Item>*/}
                                {/*        <div className={'textare-wrap'}>*/}
                                {/*        {getFieldDecorator('projectTeam', {*/}
                                {/*            rules: [*/}

                                {/*            ],*/}
                                {/*        })(<TextArea  className="textare" maxLength={500} placeholder={'团队教学和研发水平、队伍结构、特色优势等'}></TextArea>)}*/}
                                {/*        <span className='textarea_len'>*/}
                                {/*                    {this.props.form.getFieldValue(`projectTeam`) ? this.props.form.getFieldValue(`projectTeam`).length : 0}/500*/}
                                {/*        </span>*/}
                                {/*        </div>*/}
                                {/*    </Form.Item>*/}
                                {/*</div>*/}

                                <div className={'tuanduicy'}>
                                    <h4 className={'ssmall-title'}><ScoolIcon2/>3. 团队成员</h4>
                                    <div className={'chengyua-table'}>
                                        {/*<h4>项目团队成员</h4>*/}
                                        <Table className="main-table-cy" ce llspacing={20} columns={mainColumns}
                                               dataSource={groupPeopleList}
                                               pagination={false}
                                               bordered={true}
                                               locale={{
                                                   emptyText: <>
                                                       <div className="Empty-conet">
                                                           <div className="img">
                                                               <img
                                                                   src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                               <span>暂无数据</span>
                                                           </div>
                                                       </div>
                                                   </>
                                               }}
                                        />
                                        {/*<h4>其他成员</h4>*/}
                                        {/*<Table className="main-table-cy" cellspacing={20} columns={mainColumns}*/}
                                        {/*       dataSource={notMainDataSource}*/}
                                        {/*       pagination={false}*/}
                                        {/*       locale={{*/}
                                        {/*           emptyText: <>*/}
                                        {/*               <div className="Empty-conet">*/}
                                        {/*                   <div className="img">*/}
                                        {/*                     <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>*/}
                                        {/*                       <span>暂无数据</span>*/}
                                        {/*                   </div>*/}
                                        {/*               </div>*/}
                                        {/*           </>*/}
                                        {/*       }}*/}
                                        {/*/>*/}
                                    </div>

                                    <div className={'chengyua-table'} style={{border: 'none', width: '60%'}}>
                                        <div className={'addpeople'} onClick={() => this.save('', '新增成员')}><ScoolIcon2/>添加成员
                                        </div>
                                        <p className={'total'}>合计 项目团队总数（人） <span>{projectNum}</span> |
                                            学校人员数量（人） <span>{schoolNum}</span> | 企业人员数量（人） <span>{enterpriseNum}</span>
                                        </p>
                                    </div>

                                    <div className={'chengjiu'}>
                                        <h4 className={'ssmall-title'}><Chengjiu/>4. 团队成员成果说明</h4>
                                        <Form.Item>
                                            <div className={'textare-wrap'}>
                                                {getFieldDecorator('teamDescription', {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "请填写代表性成就"
                                                        },
                                                    ],
                                                })(<TextArea
                                                    className="textare"
                                                    maxLength={2000}
                                                    placeholder={'请简述项团队成员近5年来与基地建设相关的成果及获奖情况'}>
                                                </TextArea>)}

                                            </div>

                                        </Form.Item>
                                        <Form.Item>
                                            <div className={"textAreaBottomView"}>
                                                <div className='textarea_len'>
                                                    {this.props.form.getFieldValue(`teamDescription`) ? this.props.form.getFieldValue(`teamDescription`).length : 0}/2000
                                                </div>
                                            </div>
                                        </Form.Item>
                                        <div className={'border-bottom'}/>

                                    </div>

                                    <div className={'button-group'}>
                                        <Button className={'up-btn'} onClick={() => this.preStep()}>上一步</Button>
                                        <Button className={'next-btn'} onClick={() => this.nextStep()}>保存并进入下一步</Button>
                                        <Button onClick={() => this.handleReset()}>重置</Button>
                                    </div>
                                </div>

                            </Form>
                        </div>
                        {/*新增编辑成员*/}
                        <Modal
                            title={xzcyModalTile}
                            visible={xzcyModal}
                            width={660}
                            closable={false}
                            className='xzcyModal'
                            maskClosable={false}
                            centered={true}
                            destroyOnClose={true}
                            footer={
                                <div>
                                    <Button onClick={this.HandleCancel}>取消</Button>
                                    <Button type={'primary'} onClick={() => this.savePeople()}>保存</Button>
                                </div>
                            }
                        >
                            <XmtdAddmodal wrappedComponentRef={form => {
                                this.XmtdAddmodal = form;
                            }} saveUpdate={saveUpdate} groupPeopleList={groupPeopleList}
                                          fromDetail={fromDetail}
                                          onRef={this.onRef}/>
                        </Modal>
                    </div>
                );
            }
        }
    )
);
