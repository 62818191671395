// 添加人才培养模式改革
import React, {Component} from 'react';
import {Button, Form, Input, message, InputNumber, Modal} from 'antd';
import moment from "moment";
import {Deleticon,EdioIcon,UpIcon} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import "./index.scss";
const { TextArea } = Input;
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
@withRouter
class AddPersonne extends Component {
    state = {
        name:'',
        formlist:[
            {
                'namea':'各级财政投入经费金额（万元）',
                'nameb':'比例（%）'
            },
            // {
            //     'namea':'举办方投入经费金额（万元）',
            //     'nameb':'比例（%）'
            // },
            {
                'namea':'行业企业支持经费金额（万元）',
                'nameb':'比例（%）'
            },
            {
                'namea':'学校自筹经费（万元）',
                'nameb':'比例（%）'
            }
        ],
    };

    componentDidMount() {

    }
    // 输入框剩余字数联动
    totalGoalTextLength = (e) =>{
        this.setState({name:e.target.value})
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        const {tableData,fileNumber} = this.state;
        if ((tableData.length+list.length+fileNumber) > 20){
            message.error("备课包资源数量不能超过20")
            return;
        }
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        this.setState({
            file:list[0]
        })
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
                return;
            }
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            let fileId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            fileId = `zjyd-space-front-geren/${ymd}/${fileId}`
            k["fileId"] = fileId
            if (!tableData.find((v) => v.resourceName === k.name)) {
                tableData.push({
                    fileId: fileId,
                    resourceName: k.name,
                    resourceFormat: fileType,
                    resourceSize: (k.size / 1024).toFixed(2),
                    operationTime: transTime(k.lastModifiedDate),
                    createTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    plan: 0,
                    lastModified:k.lastModified
                });
            }
        });
        if (flag){
            return;
        }
        this.setState({
            tableData: tableData,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
        this.setState({
            inputValue:""
        })
    };
    //删除
    delFormItem = async () => {
        Modal.confirm({
            className: 'delete-warp',
            title: <div>

                消息提醒
            </div>,
            centered: true,
            content: "确定要删除吗?",
            onOk: async () => {
            }
        })
    }
    render() {
        const {getFieldDecorator }=this.props.form
        const {name,formlist} = this.state;
        const {formData, ifComplete, ifEdit,num, subName} = this.props
        // const formmain =formlist?formlist((item,index)=>{
        //     return(
        //         <div className="form_li" key={index}>
        //             <Form.Item label={item.namea}>
        //                 {getFieldDecorator(`namea`, {
        //                     initialValue:'' ,
        //                     rules: [
        //                         {
        //                             required:true,
        //                             message: "请填写数字",
        //                         }
        //                     ],
        //                 })(<InputNumber min={0} max={100} maxLength={6} />)}
        //             </Form.Item>
        //             <Form.Item label={item.nameb} className="li_rightinput">
        //                 {getFieldDecorator(`nameb`, {
        //                     initialValue:'' ,
        //                     rules: [
        //                         {
        //                             required:true,
        //                             message: "请填写数字",
        //                         }
        //                     ],
        //                 })(<InputNumber className="input_num" min={0} max={100} maxLength={6} />)}
        //             </Form.Item>
        //         </div>
        //     )
        // })
        return (
            <div className="Comment-AddPersonne">
                <div className="AddForm_top">
                    <span className="span_icon" onClick={()=>{this.delFormItem()}}><Deleticon/>删除</span>
                    <span className="span_icon"><EdioIcon/>编辑</span>
                </div>
                <Form>
                    <Form.Item label={`建设内容-子项${this.props.num+1}`} onFocus={this.doFocus} onBlur={this.doBlur}>
                        {getFieldDecorator('name', {
                            initialValue:name ,
                            rules: [
                                {
                                    required:true,
                                    message: `请填写建设内容-子项${this.props.num+1}`,
                                },
                                {
                                    min:2,
                                    message: "文本最少2字"
                                },
                                {
                                    max:1000,
                                    message: "文本最多100字"
                                },
                            ],
                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                        })(<TextArea className="TextArea-pic" rows={5} />)}
                        <p className="font-num">{this.props.form.getFieldValue(`assignmentName`)?this.props.form.getFieldValue(`assignmentName`).length:0}/1000</p>
                    </Form.Item>
                    <div className="form_conbottom">
                        {/*{formmain}*/}
                        <div className="form_li">
                            <Form.Item label='各级财政投入经费金额（万元）'>
                                {getFieldDecorator(`namea`, {
                                    initialValue:'' ,
                                    rules: [
                                        {
                                            required:true,
                                            message: "请填写数字",
                                        }
                                    ],
                                })(<InputNumber min={0} max={100} maxLength={6} />)}
                            </Form.Item>
                            <Form.Item label='比例（%）' className="li_rightinput">
                                {getFieldDecorator(`nameb`, {
                                    initialValue:'' ,
                                    rules: [
                                        {
                                            required:true,
                                            message: "请填写数字",
                                        }
                                    ],
                                })(<InputNumber className="input_num" min={0} max={100} maxLength={6} />)}
                            </Form.Item>
                        </div>
                    </div>
                    <div className="summation_bottom">
                        <div className="bottom_c">
                            <i></i>
                            <span className="span span_on">小计</span>
                            <span className="span">｜</span>
                            <span className="span">金额（万元）：</span>
                            <span className="red">625852.00 </span>
                            <span className="span">比例（%）：</span>
                            <span className="red">80</span>
                        </div>
                    </div>
                </Form>
                <div className="bottom-but">
                    <Button className="but-li"
                            htmlType="submit">
                        重置
                    </Button>
                    <Button className="but-li"
                            type="primary"
                            htmlType="submit">
                        保存
                    </Button>
                </div>
            </div>
        );
    }
}

const AddPersonnelist = Form.create()(AddPersonne);
export default AddPersonnelist;

