import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import clns from 'classnames'
import "./index.scss"


export default class RectCmp extends PureComponent {
      render() {
        let {className, style,text, status, displayOrder} = this.props
        return <span>
           <div   className={clns("rect-cmp", className, {
             "actived": !!status
           })} style={{...style}} onClick={() => {
             this.props.onClick&&this.props.onClick(displayOrder)
           }}>
               {text}
           </div>
        </span>
      }
}
RectCmp.propTypes = {
   text:PropTypes.string,
   displayOrder:PropTypes.bool,
   onClick:PropTypes.func,
   className:PropTypes.any,
   style:PropTypes.any
}