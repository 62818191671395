// 建设方案描述-预览
import React from "react";
import {Form, Row, Col, Radio, Table, Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import {Uptop} from '../../components/iconSvg'
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import {withRouter} from "react-router-dom";
import {constructionPlanPreview, subtaskPreview} from "../../api/preview";
import Cookies from "js-cookie";

export default Form.create()(
    withRouter(
        class TasksSchedule extends React.Component {
            state = {
                previewParam: Cookies.get("previewParam") ? JSON.parse(decodeURI(Cookies.get("previewParam"))) : {},
                subTaskList: []
            }

            componentDidMount() {
                this.subtaskPreview();
            }

            subtaskPreview = () => {
                let {previewParam} = this.state;
                subtaskPreview({taskId: previewParam.taskId, schoolEduCode: previewParam.schoolEduCode}).then(res => {
                    if (res) {
                        this.setState({
                            subTaskList: res
                        });
                    }
                })
            }
            secondPreviewPage = () => {
                this.props.history.push('/building_project')
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {subTaskList} = this.state;
                return <div className="tasks-schedule" style={{minHeight: "1200px"}}>
                    <Heder/>
                    <div className="Task-preview clearfix">
                        <div className="main_cont" id="main_cont">
                            <div className="Task-bottom-preview">
                                <Row type="flex" justify="space-between">
                                    <Col span={4} className="preview_left" id="leftmain" style={{"top": '0px'}}>
                                        <LeftMeinlist processCode={'1-4'}/>
                                    </Col>
                                    <Col span={20} className="preview_right">
                                        <div className='content'>
                                            {/*<div className={'up-top'}><Uptop/><span>返回顶部</span></div>*/}
                                            <h2 className={'title'}>4. 建设任务和进度</h2>
                                            {/*<div className={'border-bottom'}/>*/}

                                            <div className='top-right'>
                                                <h6 className='mission '>任务书预览页</h6>
                                                <span><i><b>4</b>/7</i></span>
                                            </div>
                                            <div className={'small-cont'}>
                                                {subTaskList ? subTaskList.map((item, index) => (
                                                    <div>
                                                        <h2 className={'small-title'}>{item.seniorName}</h2>
                                                        {item ? item.subTaskVos.map((itemTwo, indexTwo) => (
                                                            <div>
                                                                <h3 className={'small-title1'}>{itemTwo.subtaskName}</h3>
                                                                <div className={'zirenwu-cont'}>
                                                                    {itemTwo ? itemTwo.subTaskYearVos.map((itemThree, indexThree) => (
                                                                        <div>
                                                                            <span
                                                                                className={'title-span'}>{itemThree.yearName}</span>
                                                                            <div
                                                                                className={'cont2021'}>{itemThree.itemDescription}</div>
                                                                        </div>
                                                                    )) : ""}
                                                                </div>
                                                            </div>
                                                        )) : ""}
                                                    </div>
                                                )) : ""}
                                            </div>
                                            <div className="bottom-but">
                                                <Button className="but-li" type="primary"
                                                        onClick={() => this.secondPreviewPage()}>
                                                    下一页
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>;
            }
        }
    )
);
