/*eslint eqeqeq: ["off", "smart"]*/
import axios from "axios"
import {setToken} from "./token";
import {createHashHistory} from 'history';
import md5 from "js-md5";
import {isEmpty} from './isEmpty'
import messageObj from './err_code'
import Cookies from 'js-cookie';
import {mesError, mesWarning} from '../components/global_components/confirm/index'
import {clearAllCookie, getUserInfo} from "./cookie";

let requestFlag = true;
const Http = axios.create({
    timeout: 15000,
    withCredentials: false,
    // baseURL
})
const history = createHashHistory();
Http.interceptors.request.use(
    (config) => {
        let data = config.data;
        data && data.isBold && (config.responseType = "blob");


        let now = new Date().getTime();
        let vsToken = Cookies.get('vsToken'),
            token = null;
        if (vsToken) {
            token = vsToken;
        } else {
            clearAllCookie()
            history.replace("/login")

        }
        config.headers = {
            ...config.headers,
            'Authentication': token,
            "z-ts": now,
            "z-sign": md5(now + "")
        }
        console.log("config", config)
        return config
    },
    error => {
        Promise.reject(error).catch(e => {
        })
    }
);

Http.interceptors.response.use(
    response => {
        const res = response.data
        let token = response.headers["Authentication"]
        if (res.code === 551005 || res.code === 551004 || res.code === 551002 || res.code === 551003) {
            // window.location = `${loginUrl}?url=${window.location.origin}`
        }
        if (!isEmpty(token)) {
            setToken(token)
        }
        if (res.code === 0
            || (response.config && response.config.responseType === "blob")
            // || (response.config)
        ) {
            if (res.result) {
                return res.result
            } else {
                return res
            }
        } else {
            return Promise.reject(res)
        }
    },
    err => {
        if (err.response && err.response.status) {
            if (err.response.status === 401) {
                clearAllCookie()
                history.replace("/login")
            }
            if (err.response.status === 401) {
                if (err.response.status === 5503) {
                } else {
                    // message.warning('哎呀，有意外了，攻城狮正火速来援');
                    mesWarning('哎呀，有意外了，攻城狮正火速来援');
                }

            }
        }
    }
)
export const get = (url, params = {}) => {
    return new Promise(resolve => {
        Http.get(url, {params: params})
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code !== 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        mesWarning(messageObj[err.code])
                        return;
                    } else if (err.code === 401) {
                        mesError(err.message);
                        clearAllCookie()
                        history.replace("/login")
                        // window.location = 'http://www.zjyd.testa/loginCenter/index.html?url=http://cbs.zjyd.testa/'
                    } else {
                        mesError(err.message)
                    }
                    // errs(err)
                }
            })
        // .catch(err => {
        //     if (err.code !== 0) {
        //         if (messageObj[err.code]) {
        //             // message.warning(messageObj[err.code]);
        //             mesWarning(messageObj[err.code])
        //             return;
        //         } else if (err.code === 551005 || err.code === 551004 || err.code === 551002 || err.code === 551003) {
        //             mesError(err.message);
        //             //history.replace("/login")
        //             // window.location = 'http://www.zjyd.testa/loginCenter/index.html?url=http://cbs.zjyd.testa/'
        //         } else {
        //             mesError(err.message)
        //         }
        //     }
        // })
    })
}
export const post = (url, params = {}, needUserId = true) => {
    return new Promise((resolve, errs) => {
        console.log(getUserInfo())
        if (needUserId === true) params["userId"] = getUserInfo().id ?? ""

        Http.post(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code !== 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        mesWarning(messageObj[err.code])
                        return;
                    } else if (err.code === 401) {
                        mesError(err.message);
                        clearAllCookie()
                        history.replace("/login")
                        // window.location = 'http://www.zjyd.testa/loginCenter/index.html?url=http://cbs.zjyd.testa/'
                    } else {
                        mesError(err.message)
                    }
                    errs(err)
                }
            })
    })
}
export const del = (url, params = {}) => {
    return new Promise(resolve => {
        Http.delete(url, {params: params})
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code !== 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        mesWarning(messageObj[err.code])
                        return;
                    }
                    mesError(err.message)
                }
            })
    })
}
export const put = (url, params = {}) => {
    return new Promise(resolve => {
        Http.put(url, params)
            .then(res => {
                resolve(res)
            })
            .catch(err => {
                if (err.code != 0) {
                    if (messageObj[err.code]) {
                        // message.warning(messageObj[err.code]);
                        mesWarning(messageObj[err.code])
                        return;
                    }
                    mesError(err.message)
                }
            })
    })
}

export default Http

