import React, { Component } from "react";
import { Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Radio, Row, Spin, Table, Tooltip } from 'antd';
import { Professionalicon } from "@@/iconSvg";
import ImportComponent from "@@/importComponent/ImportComponent";
import Forms from './Forms';
import "./styles.scss";
import { downTemplate, updatePersonPeriod, addPersonPeriod, getPersonPeriod, deletePersonPeriod } from "../../../../api/sharing";
import axios from "axios";

export default class Index extends Component {
  state = {
    total: 0,
    pageSize: 0,
    current: 1,
    data: [],
    modalVisible: false,
    planFactorContent: 0,
    planTotal: 0,
    editObj: {},
    totalNum: 0,
    vsNum: 0,
    pageNum: 1,
  }

  componentDidMount() {
    // console.log('componentDidMount',this.props)
    // this.setState({
    //   data:this.props.datas.personPeriodVoList.data || []
    // })
    this.initGetList()
  }

  // 请求数据
  initGetList = async (pageNum = 1, pageSize = 10) => {
    const params = {
      pageNum,
      pageSize,
      targetId: this.props.getTargetId,
      schoolEduCode: this.props.schoolEduCode
    }
    getPersonPeriod(params).then(res => {
      if (res) {
        const { pageNum, pageSize, total, data } = res.personPeriodVoPageData;
        data.map((element, i) => element.index = (pageNum - 1) * pageSize + (i + 1));

        console.log('res请求数据2.1.4', res.personPeriodVoPageData)
        
        this.props.cultivationTalentsList&&this.props.cultivationTalentsList(true)
        // if (res.code == 0) {
        this.setState({
          pageNum,
          pageSize,
          total,
          data,
          vsNum: res.vsNum,
          totalNum: res.totalNum
        });
        // }
      }
    })
  }

  // 添加单条数据
  onInsertData = () => {
    this.setState({ modalVisible: true, editObj: {} });
  };

  // 修改单条数据
  onUpdateRecord = editObj => this.setState({ editObj }, () => this.setState({ modalVisible: true }));


  // 删除单条数据
  onDeleteRecord = (e) => {
    // console.log(e)
    const { getTargetId } = this.props;
    deletePersonPeriod({ id: e.id, targetId: getTargetId }).then(res => {
      if (res) {
        if (res.code == 0) {
          message.success('成功');
          this.initGetList(1, this.state.pageSize);
        }
      }
    })
  };

  // 提交表单
  onSubmitForm = (loop=false) => {
    // console.log(this.modalForm.props.form)
    if (this.state.editObj?.id) {
      this.modalForm.props.form.validateFields((err, value) => {
        const { getTargetId } = this.props;
        value.targetId = getTargetId;
        value.schoolEduCode = this.props.schoolEduCode;
        value.id = this.state.editObj?.id;
        // console.log('修改  ：：： ', value)
        if (value.courseName && value.interTrainNum != undefined && value.isCloudVs != undefined && value.isVs != undefined && value.localTrainNum != undefined && value.trainPeriod != undefined) {
          updatePersonPeriod(value).then(res => {
            if (res) {
              if (res.code == 0) {
                message.success('修改');
                this.setState({ modalVisible: false });
                this.initGetList(this.state.pageNum, this.state.pageSize);
              }
            }
          })
        }
        if (!err) {

        }
      })
    } else {
      this.modalForm.props.form.validateFields((err, value) => {
        const { getTargetId } = this.props;
        value.targetId = getTargetId;
        value.schoolEduCode = this.props.schoolEduCode;
        // console.log('专业新增  ：：： ', value)
        if (value.courseName && value.interTrainNum != undefined && value.isCloudVs != undefined && value.isVs != undefined && value.localTrainNum != undefined && value.trainPeriod != undefined) {
          addPersonPeriod(value).then(res => {
            if (res) {
              // debugger
              if (res.code == 0) {
                message.success('成功');
                if(loop) {
                  this.modalForm.props.form.resetFields()
                } else {
                  this.setState({ modalVisible: false });
                }
                
                this.initGetList();
              }
            }
          })
        }

        if (!err) {
          console.log('err', err)
        }
      })
    }
  }

  // 切换页码
  onPageChange = (current, pageSize) => {
    this.setState({ current, pageSize }, () => { this.initGetList(current, pageSize); });
  };

  // 切换页码
  onShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize }, () => { this.initGetList(1, pageSize); });
  };
  updatePlanNum = (value) => {
    // console.log('value', value)
    // let data = {
    //     id: this.state.trainPlanInfo.factorId,
    //     factorContent: Number(value)
    // }
    // updateFactor(data).then(res => {
    //     if (res) {
    //         // message.success("操作成功")

    //     }
    // })
  }
  planGiaBian = (value) => {
    this.setState({
      planFactorContent: Number(value)
    })
  }
  render() {
    const { pageNum ,totalNum, vsNum, editObj, total, pageSize, data, modalVisible, planTotal, planFactorContent } = this.state;
    const { canEdit, getTargetId, schoolEduCode, datas,personPeriodNum } = this.props;
    // console.log('this.props', this.props)
    let columns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        align: "center",
        width: 80,
        
      },
      {
        title: "课程名称",
        dataIndex: "courseName",
        key: "courseName",
        align: "center",
      },
      {
        title: "是否为虚拟仿真实训",
        dataIndex: "isVs",
        key: "isVs",
        align: "center",
        render: (text, record) =>
          text === 0 ? <span>否</span> : <span>是</span>,
      },
      {
        title: "是否为云端虚拟仿真实训",
        dataIndex: "isCloudVs",
        key: "isCloudVs",
        align: "center",
        render: (text, record) =>
          text === 0 ? <span>否</span> : <span>是</span>,
      },
      {
        title: "课程实训人数（本校学生）",
        dataIndex: "localTrainNum",
        key: "localTrainNum",
        align: "center",
      },
      {
        title: "课程实训人数（国内共享院校学生）",
        dataIndex: "interTrainNum",
        key: "interTrainNum",
        align: "center",
      },
      {
        title: "实训学时",
        dataIndex: "trainPeriod",
        key: "trainPeriod",
        align: "center",
      },
    ];

    if (canEdit) {
      columns.push({
        title: '操作',
        align: 'center',
        width: 200,
        dataIndex: 'oper',
        key: 'oper',
        render: (_, record) => <div className='render-span vertical_j oper'>
          <div>
            <span className="cz" onClick={() => this.onUpdateRecord(record)}>编辑</span>
            <span className="cz" onClick={() => this.onDeleteRecord(record)}>删除</span>
          </div>
        </div>
      })
    }

    return (
      <div className="effective_1_5_5_wrap">
        <ul className={'tongjiul1'}>
          <li>
            {/* 在校生参加虚拟仿真实训总人时数/在校生参加实训总人时数×100%，其中：实训总人时数=Σ(单门课程实训人数×实训学时) */}
            <span className={'span '}>总人时数</span>
            <div className={'btn'}>{totalNum ? totalNum : 0}</div>
          </li>
          <li>
            <span className={'span'}>在校生参加虚拟仿真实训总人时数</span>
            {/* <div className={' btn1'}>
              <InputNumber min={0} className='input_bg' disabled={!canEdit}
                onBlur={e => this.updatePlanNum(e.target.value)}
                onChange={e => this.planGiaBian(e)}
                value={planFactorContent ? planFactorContent : 0}
                placeholder={'数值填充'} />
            </div> */}
            <div className={'btn'}>{vsNum ? vsNum : 0}</div>
          </li>
          
          <li>
            <span className={'span'}>占比</span>
            <div
              // className={'btn'}>{planFactorContent !== 0 ? ((planTotal / planFactorContent) * 100).toFixed(2) : 0}%
              className={'btn'} style={((vsNum/totalNum) * 100).toFixed(2) < 30 ?{borderColor:'#d75746'}:{borderColor:'#d9d9d9'}}>{((vsNum / totalNum) * 100).toFixed(2) == NaN  || (!totalNum && !totalNum)? 0 : ((vsNum / totalNum) * 100).toFixed(2)}%

            </div>
            {
              ((vsNum / totalNum) * 100).toFixed(2) <30 && (
                <span className="span span1" style={{color:'#d75746'}}>*该项指标需达30%</span>
              )
            }
          </li>
        </ul>

        <div className="insert_upload_button">
          {canEdit ? (
            <div className="conteBtnDiv conteBtnDivtadd" onClick={this.onInsertData}>
              <Professionalicon />
              <span className="conteBtnDivSpan">添加</span>
            </div>
          ) : (
            ""
          )}
          {
            canEdit ? (
              <ImportComponent
                targetId={this.props.getTargetId}
                targetMark="2.1.4"
                downloadName="在校生参加虚拟仿真实训的人时占比"
                onImportSuccess={() => {
                  // todo 刷新此项即可
                  // alert("成功")
                  this.initGetList(1);
                }}
              />
            ):(
              ""
            )
          }
          
        </div>

        <div style={{ paddingBottom: "50px", marginRight:'70px' }}>
          <div className="conteTable  conteTable214">
            <Table
              columns={columns}
              dataSource={data}
              bordered={true}
              locale={{
                emptyText: (
                  <>
                    <div className="Empty-content">
                      <div className="text">
                        <img src={window.$$imgSrcLcl + "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"} />
                        <span>暂无数据</span>
                      </div>
                    </div>
                  </>
                ),
              }}
              pagination={{
                total: total,
                pageSize: pageSize,
                current: pageNum,
                onChange: this.onPageChange,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
        <div className={'cont1'} >
          <div
            className={'titleName'}>2.1.5 在校生（本校学生）云端虚拟仿真实训总人时
          </div>
          <ul className={'tongjiul1'} style={{flexDirection:'column'}}>
            <li>
              <span className={'span '}>云端虚拟仿真实训总人时数</span>
              <div className={'btn'}>{personPeriodNum   ? personPeriodNum   : 0}</div>
            </li>
            <li style={{marginTop:'10px'}}>
              <span className={'span'} style={{color:'#d75746'}}>*计算结果来自2.1.4中表格数据</span>
            </li>
          </ul>

        </div>

        <Modal
          // title={'添加课程'}
          title={!editObj.id ? '添加' : '编辑'}
          visible={this.state.modalVisible}
          width={700}
          destroyOnClose={true}
          closable={false}
          maskClosable={false}
          centered={true}
          onCancel={() => { }}
          className="Professional_con Modal214"
          wrapClassName="wappop_up Modal214"
          footer={
            <div className='addModalBtn'>
              <Button onClick={() => this.setState({ modalVisible: false })}>取 消</Button>
              <Button onClick={this.onSubmitForm.bind(this, false)} type='primary'>提交</Button>
              {
                editObj.id ? '' : <Button onClick={() => {
                  this.onSubmitForm(true)
                }} type='primary'>提交并继续添加</Button>
              }
              
            </div>
          }
        >
          <Forms
            record={this.props.editingTaskRecord}
            wrappedComponentRef={form => { this.modalForm = form }}
            editObj={editObj}

          />
        </Modal>
      </div>
    );
  }
}
