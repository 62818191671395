import React from "react";
import {Button, message, Modal} from "antd";
import './index.scss';
import {TianJia} from "../iconSvg";
import AddWashable from "../../page/platform_Coursesharing/components/add_Washable";
import FileListComponent from "./FileListComponent";
import {addTargetFile, deleteTargetFile, selectTargetFile} from "../../api/uploadFile";
import Cookies from "js-cookie";
// 目前请求均在内部请求,部分是在外部
// 使用方式主要是直接传targetId即可,onGetFiles和onDelete可不传(兼容旧业务使用而已)
// 设置targetId和editEnable即可,注意:editEnable必须设置,否则不会显示(默认为false),
// editEnable主要是为了兼容预览模式(设置为外部的canEdit的值即可,一般页面的render函数里都已经写好了)

class UploaderComponent extends React.Component {
    state = {
        //显示添加文件弹窗
        showAddFile: false,
        // addEvidence
        // 显示文件列表
        showFileList: false,
        fileList: [],
        targetId: null,
        hasRequest: false,
    }

    // 显示添加附件视图
    showAddFileView = () => {
        const value = this.state.showAddFile
        this.setState({
            showAddFile: !value
        })
    }
    // 显示附件列表视图
    showFileListView = () => {
        const value = this.state.showFileList
        this.setState({
            showFileList: !value
        })
    }
    // 上传文件
    addFile = () => {
        const files = this.Addevidence.getState()
        const checked = this.Addevidence.state.checked
        if (files.length === 0) {
            message.warning("请上传文件")
            return false;
        }
        if (!checked) {
            message.warning("请先勾选我承诺上传的资料真实可信")
            return false;
        }

        if (files.length > 0) {
            // 如果是带targetId的,则本地直接同意请求
            // 否则给外部去处理文件列表
            if (this.props.targetId) {
                this.handleAddFiles(files);
            } else {
                this.props.onGetFiles(files);
            }
            this.setState({
                showAddFile: false
            })
        }
    }

    get getSchoolEduCode() {
        return JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : ""
    }

    handleAddFiles = (files) => {
        const schoolEduCode = this.getSchoolEduCode
        const fileIds = files.map(file => file.id)
        console.log(fileIds)
        addTargetFile(this.props.targetId, fileIds, schoolEduCode).then(() => {
            message.success("添加成功")
            return this.getFileList(this.props.targetId)
        })
    }

    handleDeleteFile = (index) => {
        const schoolEduCode = this.getSchoolEduCode
        deleteTargetFile(this.props.targetId, this.state.fileList[index].id, schoolEduCode).then(() => {
            message.success("删除成功")
            return this.getFileList(this.props.targetId)
        })
    }
    getFileList = (targetId) => {
        const schoolEduCode = this.getSchoolEduCode
        selectTargetFile(targetId, schoolEduCode).then(res => {
            this.setState({
                fileList: res,
                hasRequest: true
            })
        })
    }

    handleCancel = () => {
        this.setState({
            showFileList: false,
        })
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (!this.state.hasRequest && nextProps.targetId) {
            this.getFileList(nextProps.targetId)
        }
    }

// section Render
    render() {
        const fileList = (this.props.targetId ? this.state.fileList : this.props.fileList) ?? [];
        return (<div>
            <div className={'btn_group'}>
                {this.props.editEnable ? <Button type={'primary'} className="icon" id="uploadBtn"
                                                 onClick={this.showAddFileView}><TianJia/>{this.props.uploadTitle}
                </Button> : ""}
                {fileList.length > 0 ?
                    <span id={'checkTitle'}
                          onClick={() => this.showFileListView()}>{this.props.checkTitle}></span> : ""
                }
            </div>
            <Modal
                title={'添加附件'}
                visible={this.state.showAddFile}
                width={800}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                centered={true}
                className={'Addevidence'}
                wrapClassName="Addevidence_warp"
                footer={
                    <div className='footer-btn'>
                        <Button className={'quxiao'}
                                onClick={() => this.setState({showAddFile: false})}>取消</Button>
                        <Button type='primary' onClick={() => this.addFile()}>提交</Button>
                    </div>
                }
            >
                <AddWashable onRef={(ref) => this.Addevidence = ref}
                    // targetId={this.state.addTargetId}
                />
            </Modal>

            <Modal
                title={this.props.checkTitle}
                visible={this.state.showFileList}
                width={800}
                centered={true}
                destroyOnClose={true}
                className={'Addevidence'}
                wrapClassName="Addevidence_warp"
                onCancel={this.handleCancel}
                footer=""
            >
                <FileListComponent editEnable={this.props.editEnable}
                                   onDelete={this.props.targetId ? this.handleDeleteFile : this.props.onDelete}
                                   defaultList={fileList}
                />
            </Modal>
        </div>)

    }
}


UploaderComponent.defaultProps = {
    targetId: null,
    // 是否能编辑(可上传可删除)
    editEnable: false,


    uploadTitle: '上传佐证材料',
    checkTitle: '查看佐证材料',
    // 要显示的文件列表
    fileList: [],
    // 获取文件回调
    onGetFiles: (files) => {

    },
    onDelete: (index) => {

    }
}
export default UploaderComponent
