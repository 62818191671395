import { post } from '../../until/axios_instans';
// let url = "/api/vb/api/v1/factor/";
// 修改要素
// export function updateFactor(data) {
//     return post(url+"updateFactor", data);
// }

export function updateFactor(data) {
    return post(window.apiShixun + '/api/v1/factor/updateFactor', data);
}

export function updateSchoolTeacherTotalNum(data) {
    return post(window.apiShixun + '/api/v1/school/updateSchoolTeacherTotalNum', data);
}

