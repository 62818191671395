import React from "react";
import {Button, Input, message} from "antd";
import {Daoru, ExcelImport} from "../iconSvg";
import axios from "axios";
import {downTemplate} from "../../api/sharing";
import md5 from "js-md5";
import {importFile} from "../../api/targetResource";
import './styles.scss';
import Cookies from "js-cookie";
// 每个小点的导入和下载模板组件,使用方式:
// 1.外部传入每个小点的targetId和targetMark(节点标题,如1.3.1),组件内部会直接调用对应的导入接口,后台会统一分发出去
// 2.补全getDownloadName中的下载名称,一般写死对应的小节的名称即可
// 3.组件的onImportSuccess为导入成功的回调,外部直接刷新当前当前小节列表即可

class ImportComponent extends React.Component {
    // 这里根据传进来的数据来导入文件,例如外部id等
    importFileResources = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }

        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append("templateNo",this.props.targetMark)
        fromData.append('targetId', this.props.targetId)
        const schoolEduCode = JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : ""
        fromData.append('schoolEduCode', schoolEduCode)
        importFile(fromData).then(res => {
            message.success('导入成功')
            // 之后给回调到外部重新刷新当前选项即可(暂时简单的刷新,不做本地局部添加数据处理)
            // this.selectTargetResourceReplace()
            this.props.onImportSuccess(res)
        })
    }

    dowLoadFile = () => {
        let now = new Date().getTime();
        axios.get(downTemplate(this.props.targetMark), {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${this.props.downloadName}.xls`);
            link.click();
        })
    }

    render() {
        const com = (<span className='import_component'>
            <Button type="primary"><Input id='importFile' type="file" accept="image" onClick={(event) => {
                event.target.value = null
            }} onChange={this.importFileResources}/><ExcelImport/>excel导入</Button>
            <Button type={'primary'}
                    onClick={() => this.dowLoadFile()}><Daoru/>下载导入模版</Button>
        </span>)
        return this.props.editEnable ? com : ''
    }
}

ImportComponent.defaultProps = {
    editEnable: true,
    // 标签,如1.3.1
    targetMark: "",
    // 下载文件的名称,即节点名称,传标签后面的名称即可
    downloadName: "",
    targetId: "",
    onImportSuccess: () => {

    }
}
export default ImportComponent
