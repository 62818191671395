// 查看佐证材料
import React from "react";
import OSS from "ali-oss";
import {getOssToken} from '../../../api/ali';
import {DatePicker, Form, Input, message, Radio, Table, Modal, Button, Drawer} from 'antd';
import "./index.scss";
import LookCheck from "../../components/look_Check";
import {selectEvidenceByTargetId, deleteEvidence, deleteApplyResources} from "../../../api/target";
import {WenxinTip} from "../../../components/iconSvg";
import {getStudentNum} from "../../../api/constructionResults";
import Cookies from "js-cookie";
import moment from "moment";
import FileLook from "../../components/file_look";
import { BaseUrl } from "../../../config/common";

class Lookevidence extends React.Component {
    state = {
        pageNum:1,//页数
        pageSize:10,//条数
        total:0,
        taskBookTable:[],
        examine:false,
        resourceId:'',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    };

    componentDidMount() {
        let {showList,studentNumInfo} = this.props
        console.log('studentNumInfoList', showList)
        console.log('studentNumInfo', studentNumInfo)
        this.setState({
            taskBookTable: showList,
            studentNumInfo: studentNumInfo
        }, () =>{
            this.selectEvidenceByTargetId()
            this.getOssToken();
            this.props.onRef(this)
        })
    }

    async getOssToken() {
        let res = await getOssToken();
        // console.log(res)
        if (res) {
            let client = new OSS({
                region: "oss-cn-beijing",
                accessKeyId: res.accessKeyId,
                accessKeySecret: res.keySecret,
                stsToken: res.token,
                bucket: res.bucket,
                // endpoint:'oss-cn-beijing.aliyuncs.com',
                secure: true,
            });
            this.client = client;
        }
    }

    selectEvidenceByTargetId =()=>{
        let data={
            id:this.props.targetId
        }
        selectEvidenceByTargetId(data).then(e=>{
            this.setState({
                taskBookTable: e,
                total:e.length
            })
        })
    }

    getStudentNum =()=>{
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.studentNumInfo.id,
        }
        getStudentNum(data).then(res =>{
            console.log('res 009', res)
            if(res){
                this.setState({
                    taskBookTable: res.resourceVoList.data,
                }, ()=>{
                    this.props.getStudentNum()
                })
            }
        })
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize });
    }
    delete =(e)=>{
        let data ={
            id :e.id,
            targetId: this.state.studentNumInfo.id
        }
        deleteEvidence(data).then(e=>{
            message.success("删除成功！")
            this.getStudentNum()
        })
    }
      // 查看资料
      handLook = (record) => {
        const url=BaseUrl+record.fileId
        this.props.history.push({pathname:"/file_Book", query :{ openUrl: url}} )
    }
       /**
             *  隐藏预览的回调
            */
           dismiss = () =>{
            this.setState({
                isShow:false
            })
        }

    render() {
        // const {getFieldDecorator }=this.props.form
        const { taskBookTable,total,pageSize,examine,resourceId, isShow,
            openUrl} = this.state
        const columns = [
            {
                title: '材料名称',
                dataIndex: 'resourceName',
                key: 'resourceName',
                width: 200,
            },
            {
                title: '格式',
                dataIndex: 'resourceSuffix',
                key: 'resourceSuffix',
                width: 100,
            },
            {
                title: '大小',
                dataIndex: 'resourceSize',
                key: 'resourceSize',
                width: 100,
                render: (e)=><div>
                    {e>=1048576?(e / 1048576).toFixed(2) + "MB":(e / 1024).toFixed(2) + "K"}
                </div>
            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 100,
                render: (e)=><div>
                    {moment(e).format('YYYY-MM-DD HH:mm')}
                </div>
            },
            {
                title: '操作',
                key: 'operation',
                width: 150,
                render:(text, record) => (
                    <div className='operating'>
                        <div className="li">
                            <span onClick={() => this.handLook(record)}>查看</span>
                            <span onClick={() => this.delete(record)}>删除</span>
                        </div>
                    </div>
                )
            },
        ]
        return (
            <div className="Addevidence_cont" >
                    <span className="span" style={{paddingTop:'0'}}>共计 {taskBookTable?taskBookTable.length:''}个</span>
                    <div className="table_bot">
                        <Table columns={columns} dataSource={taskBookTable} pagination={false}
                               locale={{emptyText:<><div className="Empty-content">
                                       <div className="text">
                                           <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div></>}}
                               // pagination={
                               //     {
                               //         total: total,
                               //         pageSize: pageSize,
                               //         onChange: this.pageChange,
                               //         onShowSizeChange: this.onShowSizeChange,
                               //         pageSizeOptions: ['10', '20', '30', '40'],
                               //         showSizeChanger: true,
                               //     }
                               // }
                        />
                    </div>
                {/*查看资料*/}
                <FileLook  openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
            </div>

        );
    }
}
export default Lookevidence;