// 项目情况-平台情况
import React from "react";
import {
    Form,
    Row,
    Col,
    Radio,
    Table,
    Button,
    Card,
    Input,
    Select,
    Modal,
    Upload,
    message,
    InputNumber,
    Drawer
} from 'antd';
import {withRouter} from "react-router-dom";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import AddPlatforMsg from "../../components/addPlatforMsg";
import AddCertificate from "../../components/addCertificate";
import LookCheck from "../components/look_Check";
import Cookies from 'js-cookie';
import {
    getInterflow,
    addInterflowPlatform,
    updateInterflowPlatform,
    deleteInterflowPlatform,
    updateInterflowPlatformFactor,
    getInterflowPlatforms,
    addInterflowData,
    deleteInterflowData,
    updateInterflowData,
    getInterflowDataList,
    getInterflowData,
    importPlatform,
    importData
} from "../../api/platformEach";
import {
    updateFactor
} from '../../api/factor';
import "./index.scss";
import {
    AddPlatformInformation,
    SubmissionSupportingMaterials,
    WenxinTip,
    ExcelImport,
    TaperedEnd,
    Daoru
} from "../../components/iconSvg";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import axios from "axios";
import {downExcelTemplateUrl} from "../../api/sharing";
import {getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType} from "../../until/cookie";
import {updateCondition} from "../../api/platformAbility";
import UploaderComponent from "../../components/uploader_component";
import {validData} from "../../api/target";

let that;
export default Form.create()(
    withRouter(
        class PlatformCommunication extends React.Component {
            //section State
            state = {
                dataSourceMaterial: [],
                addInterflowDataFlag: false,
                editDataFlag: false,
                editPlantFormId: '',
                editPlanFormInfo: '',
                editPlantFormFlag: false,
                deletePlanModal: false,
                addInterflowPlatformFlag: false,
                schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
                // targetId: Cookies.get("twoLevelTargetId") ? Cookies.get("twoLevelTargetId") : '',
                targetId: getTargetId("platform_Communication"),
                //1.2.1Info
                interflowInfo: '',

                condition_121: '',
                addPlatforMsgModal: false,//添加平台信息Modal
                addCertificateModal: false,//新增证明材料Modal
                examine: false,//查看资料
                resourceValue: null,// 资源互通
                backRecordValue: null,//是否定级备案
                evaluationValue: null,//是否等保测评
                dataList: [],
                pageNum: 1,//页数
                pageSize: 10,//条数
                total: 0,
                pageNumone: 1,//页数
                pageSizeone: 10,//条数
                totalone: 0,
                dataSource: [],
                isindependent: false,   //二次确认是否独立具备
                // targetValue:1,

                // 放重复请求用,选择直接用本地的值,之后不再请求数据渲染
                condition_122_selectedValue: null,
                condition_123_1_selectedValue: null,
                condition_123_2_selectedValue: null,
                condition_123_3_selectedValue: null
            };

            // 1.2.2 的值
            get condition_122Value() {
                const remoteValue = this.state.interflowInfo.interflowPlatformTargetVo ? this.state.interflowInfo.interflowDataTargetVo.conditionVo.confirmStatus : null
                return this.state.condition_122_selectedValue ?? remoteValue
            }

            get condition_122Id() {
                return this.state.interflowInfo.interflowDataTargetVo.conditionVo.id
            }

            get condition_122TargetId() {
                return this.state.interflowInfo.interflowDataTargetVo ? this.state.interflowInfo.interflowDataTargetVo.id : null
            }

            get condition_123_1Value() {
                const remoteValue = this.state.interflowInfo.interflowNetworkTargetVo ? this.state.interflowInfo.interflowNetworkTargetVo.conditionVos[0].confirmStatus : null;
                return this.state.condition_123_1_selectedValue ?? remoteValue
            }

            get condition_123_1Id() {
                return this.state.interflowInfo.interflowNetworkTargetVo.conditionVos[0].id
            }

            get condition_123TargetId() {
                return this.state.interflowInfo.interflowNetworkTargetVo ? this.state.interflowInfo.interflowNetworkTargetVo.id : null
            }


            get condition_123_2Value() {
                const remoteValue = this.state.interflowInfo.interflowNetworkTargetVo ? this.state.interflowInfo.interflowNetworkTargetVo.conditionVos[1].confirmStatus : null
                return this.state.condition_123_2_selectedValue ?? remoteValue
            }

            get condition_123_2Id() {
                return this.state.interflowInfo.interflowNetworkTargetVo.conditionVos[1].id
            }


            get condition_124TargetId() {

                return this.state.interflowInfo.interFlowManagementTargetVo ? this.state.interflowInfo.interFlowManagementTargetVo.id : null
            }

            componentDidMount() {
                that = this
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
                //获取平台互通信息
                this.getInterflow()
            }

            //编辑平台信息
            editPlantForm = (record) => {
                this.setState({
                    editPlanFormInfo: record
                }, () => {
                    this.setState({
                        editPlantFormId: record.id,
                        addPlatforMsgModal: true,
                        editPlantFormFlag: true,
                    })
                })
            }
            //删除前二次确认
            deleteAsk = () => {
                message.success("删除成功")
                // this.setState({deletePlanModal:true})
            }

            //删除平台信息
            deletePlantForm = (record) => {
                let data = {
                    id: record.id
                }
                deleteInterflowPlatform(data).then(e => {
                    if (e) {
                        message.success("删除成功")
                        this.setState({pageNum: 1}, this.getInterflowPlatforms)
                    }
                })
            }

            //获取平台互通信息
            getInterflow = () => {
                const {targetId} = this.state
                let data = {
                    id: targetId
                }
                getInterflow(data).then(e => {
                    if (e) {
                        this.setState({
                            interflowInfo: e,
                            condition_121: e.interflowPlatformTargetVo.conditionVo ? e.interflowPlatformTargetVo.conditionVo : "",
                            value: e.interflowPlatformTargetVo.conditionVo ? e.interflowPlatformTargetVo.conditionVo.confirmStatus : null,
                            factorId: e.interflowDataTargetVo ? e.interflowDataTargetVo.factorId : '',
                            factorContent: e.interflowDataTargetVo ? e.interflowDataTargetVo.factorContent : '',
                            total: e.interflowDataTargetVo ? e.interflowDataTargetVo.interflowDataVoList.total : 0,
                        }, this.getInterflowPlatforms)
                    }
                })
            }

            componentWillUnmount() {
                clearInterval(this.timer);
            }

            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }


            //修改1.2.1指标条件
            onChange = e => {
                const {interflowInfo, condition_121, dataSource} = this.state
                // console.log('radio checked', e.target.value);
                let data = {
                    confirmStatus: e.target.value,
                    id: condition_121.id,
                    targetId: interflowInfo.interflowPlatformTargetVo ? interflowInfo.interflowPlatformTargetVo.id : ''
                }
                if (e.target.value === 0 && dataSource.length > 0) {
                    this.setState({
                        targetValue: e.target.value,
                        isindependent: true
                    })
                    return;
                }
                updateInterflowPlatformFactor(data).then(res => {
                    if (res) {
                        // message.success("修改成功")
                        this.setState({
                            value: e.target.value,
                        })
                    }
                })
            };
            onCondition_122Value = e => {
                let data = {
                    confirmStatus: Number(e.target.value),
                    id: this.condition_122Id,
                    targetId: this.condition_122TargetId
                }
                updateCondition(data).then(res => {
                    this.setState({
                        condition_122_selectedValue: e.target.value
                    })
                })
            }
            onCondition_123_1Value = e => {
                let data = {
                    confirmStatus: Number(e.target.value),
                    id: this.condition_123_1Id,
                    targetId: this.condition_123TargetId
                }
                updateCondition(data).then(res => {
                    this.setState({
                        condition_123_1_selectedValue: e.target.value
                    })
                })
            }
            onCondition_123_2Value = e => {
                let data = {
                    confirmStatus: Number(e.target.value),
                    id: this.condition_123_2Id,
                    targetId: this.condition_123TargetId
                }
                updateCondition(data).then(res => {
                    this.setState({
                        condition_123_2_selectedValue: e.target.value
                    })
                })
            }

            //获取平台信息列表
            getInterflowPlatforms = () => {
                const {interflowInfo, pageNum, pageSize} = this.state
                let data = {
                    id: interflowInfo.interflowPlatformTargetVo ? interflowInfo.interflowPlatformTargetVo.id : '',
                    pageNum: pageNum,
                    pageSize: pageSize,
                }
                getInterflowPlatforms(data).then(e => {
                    if (e) {
                        this.setState({
                            dataSource: e.data,
                            total: e.total
                        })
                    }
                })
            }

            //点否二次确认
            isindependentOk = () => {
                const {interflowInfo, condition_121, targetValue} = this.state
                let data = {
                    confirmStatus: targetValue,
                    id: condition_121.id,
                    targetId: interflowInfo.interflowPlatformTargetVo ? interflowInfo.interflowPlatformTargetVo.id : ''
                }
                updateInterflowPlatformFactor(data).then(res => {
                    if (res) {
                        // message.success("修改成功")
                        this.setState({
                            value: targetValue,
                        }, () => {
                            this.getInterflowPlatforms()
                        })
                    }
                })
                this.setState({
                    isindependent: false
                })
            }
            //添加/编辑平台信息
            addInterflowPlatform = (flag) => {
                this.setState({
                    addInterflowPlatformFlag: true
                })
                const {schoolEduCode, interflowInfo, editPlantFormId, editPlantFormFlag} = this.state
                // console.log("111",JSON.parse(decodeURI(Cookies.get("info"))).schoolInfo.schoolEduCode);return ;
                this.AddPlatforMsgChild.props.form.validateFields(async (err, subjectInfo) => {
                    if (!err) {
                        if (editPlantFormFlag) {
                            //编辑
                            let data = {
                                id: editPlantFormId,
                                platformAddress: subjectInfo.platformURL,
                                platformBuild: subjectInfo.platformProject,
                                platformName: subjectInfo.platformName
                            }
                            updateInterflowPlatform(data).then(e => {
                                if (e) {
                                    message.success("修改成功")
                                    this.AddPlatforMsgChild.clearForm()
                                    this.setState({
                                        addPlatforMsgModal: flag
                                    }, () => {
                                        this.getInterflowPlatforms()
                                    })
                                }
                            })
                        } else {
                            //新增
                            let data = {
                                platformAddress: subjectInfo.platformURL,
                                platformBuild: subjectInfo.platformProject,
                                platformName: subjectInfo.platformName,
                                schoolEduCode: schoolEduCode,
                                targetId: interflowInfo.interflowPlatformTargetVo.id
                            }
                            addInterflowPlatform(data).then(e => {
                                if (e) {
                                    message.success("添加成功")
                                    this.AddPlatforMsgChild.clearForm()
                                    this.setState({
                                        addPlatforMsgModal: flag,
                                        pageNum: 1,
                                    }, () => {
                                        this.getInterflowPlatforms()
                                    })
                                }
                            })
                        }
                    }
                })
                setTimeout(function () {
                    that.setState({
                        addInterflowPlatformFlag: false
                    })
                }, 100)
            }

            //删除证明材料
            deleteInterflowData = (record) => {
                let data = {
                    id: record.id
                }
                deleteInterflowData(data).then(e => {
                    if (e) {
                        message.success("删除成功")
                        //获取证明材料列表
                        this.getInterflowDataList()
                    }
                })
                // Modal.confirm({
                //     className: 'content_delete',
                //     title: '温馨提示',
                //     centered: true,
                //     content: <div className="bottom_dele">
                //         <WenxinTip/>确定要删除吗?
                //     </div>,
                //     onOk: async () => {
                //         await  deleteInterflowData(data).then(e=>{
                //             if (e){
                //                 message.success("删除成功")
                //                 //获取证明材料列表
                //                 this.getInterflowDataList()
                //             }
                //         })
                //     }
                // })

            }

            //获取证明材料列表
            getInterflowDataList = () => {
                const {interflowInfo, pageNumone, pageSizeone} = this.state
                let data = {
                    id: interflowInfo.interflowDataTargetVo.id,
                    pageNum: pageNumone,
                    pageSize: pageSizeone,
                }
                getInterflowDataList(data).then(e => {
                    if (e) {
                        this.setState({
                            dataSourceMaterial: e.data,
                            totalone: e.total
                        })
                    }
                })
            }

            //编辑证明材料
            editInterflowData = (record) => {
                console.log("record", record)
                this.setState({
                    editInterflowDataInfo: record,
                    fileData: record.evidenceInfoVo
                }, () => {
                    this.setState({
                        editInterflowDataId: record.id,
                        addCertificateModal: true,
                        editDataFlag: true,
                    })
                })
            }

            //提交/编辑证明材料
            addInterflowData = (flag) => {
                this.setState({
                    addInterflowDataFlag: true
                })
                const {editDataFlag, interflowInfo, schoolEduCode, editInterflowDataId} = this.state
                let file = this.AddCertificateChild.getTableData() ? this.AddCertificateChild.getTableData() : ''
                this.AddCertificateChild.props.form.validateFields(async (err, subjectInfo) => {
                    if (!err) {
                        if (editDataFlag) {
                            //修改
                            let data = {
                                evidenceQo: {
                                    fileId: file.fileId,
                                    resourceName: file.originName,
                                    resourceSize: file.fileSize,
                                    resourceSuffix: file.fileSuffix
                                },
                                id: editInterflowDataId,
                                platformAddress: subjectInfo.platformURL,
                                platformName: subjectInfo.platformName,
                                schoolEduCode: schoolEduCode,
                                targetId: interflowInfo.interflowDataTargetVo.id
                            }
                            updateInterflowData(data).then(e => {
                                if (e) {
                                    message.success("修改成功")
                                    // this.AddCertificateChild.clearForm()
                                    this.setState({
                                        addCertificateModal: flag
                                    }, () => {
                                        //获取证明材料列表
                                        this.getInterflowDataList()
                                    })
                                }
                            })
                        } else {
                            //新增
                            let data = {
                                evidenceQo: {
                                    fileId: file.fileId,
                                    resourceName: file.originName,
                                    resourceSize: file.fileSize,
                                    resourceSuffix: file.fileSuffix
                                },
                                platformAddress: subjectInfo.platformURL,
                                platformName: subjectInfo.platformName,
                                schoolEduCode: schoolEduCode,
                                targetId: interflowInfo.interflowDataTargetVo.id
                            }
                            addInterflowData(data).then(e => {
                                if (e) {
                                    message.success("添加成功")
                                    this.AddCertificateChild.reset()
                                    this.setState({
                                        addCertificateModal: flag
                                    }, () => {
                                        //获取证明材料列表
                                        this.getInterflowDataList()
                                    })
                                }
                            })
                        }
                    }
                })
                setTimeout(function () {
                    that.setState({
                        addInterflowDataFlag: false
                    })
                }, 100)
            }

            //修改要素
            updateFactor = (e) => {
                console.log("updateFactor", Number(e.target.value))
                let factorContent = Number(e.target.value)
                // this.setState({
                //     factorContent:factorContent
                // })
                const {factorId} = this.state
                let data = {
                    id: factorId,
                    factorContent: factorContent
                }
                updateFactor(data).then(e => {
                    if (e) {
                        // message.success("修改成功")
                        this.setState({
                            factorContent: factorContent
                        })
                    }
                })
            }


            addPlatforMsg = () => {
                this.setState({
                    editPlanFormInfo: ''
                }, () => {
                    this.setState({
                        addPlatforMsgModal: true,
                        editPlantFormFlag: false,

                    })
                })
            }

            //新增证明材料
            addCertificate = () => {
                this.setState({
                    editInterflowDataInfo: ''
                }, () => {
                    this.setState({
                        addCertificateModal: true,
                        editDataFlag: false
                    })
                })
            }

            handleCancel = () => {
                this.setState({
                    addCertificateModal: false,
                    addPlatforMsgModal: false,
                })
            }

            // 上一步
            previous = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }


                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "money_Perform") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/money_Perform',
                            })
                            break;
                        }
                    }
                }
            }
            // 下一步
            next = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }


                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "platform_Schoolresources") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/platform_Schoolresources',
                            })
                            break;
                        }
                    }
                }
            }

            //分页切换
            pageChange = (pageNum) => {
                this.setState({pageNum}, () => {
                    this.getInterflowPlatforms()
                });
            }
            //分页条数切换
            onShowSizeChange = (pageNum, pageSize) => {
                this.setState({pageNum: 1, pageSize}, () => {
                    this.getInterflowPlatforms()
                });
            }
            //第二个表格分页切换
            pageChangeone = (pageNum) => {
                this.setState({pageNumone: pageNum}, () => {

                });
            }
            //第二个表格分页条数切换
            onShowSizeChangeone = (pageNum, pageSize) => {
                this.setState({pageSizeone: pageSize}, () => {

                });
            }
            // 查看资料
            handLook = (record) => {
                if (!record.evidenceInfoVo) {
                    message.warning('未上传材料')
                    return;
                }
                if (record.evidenceInfoVo.transStatus === 2) {
                    message.warning("该文件转码失败")
                    return;
                }
                if (record.evidenceInfoVo.transStatus === 3) {
                    message.warning("转码中")
                    return;
                }
                this.setState({
                    resourceId: record.evidenceInfoVo.id
                }, () => {
                    this.setState({
                        examine: true,
                    })
                })
            }
            handExamine = () => {
                this.setState({
                    examine: true
                })
            }
            onCancel = () => {
                this.setState({
                    examine: false
                })
            }
            // 导入文件
            importFile121 = async (e) => {
                let {interflowInfo, schoolEduCode} = this.state;
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    document.getElementById("file").value = '';
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('schoolEduCode', schoolEduCode)
                fromData.append('targetId', interflowInfo.interflowPlatformTargetVo.id)
                importPlatform(fromData).then(res => {
                    message.success('导入成功')
                    this.getInterflowPlatforms()
                })
            }
            // 导入文件
            importFile122 = async (e) => {
                let {interflowInfo, schoolEduCode} = this.state;
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    document.getElementById("file").value = '';
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('schoolEduCode', schoolEduCode)
                fromData.append('targetId', interflowInfo.interflowDataTargetVo.id)
                importData(fromData).then(res => {
                    message.success('导入成功')
                    this.getInterflowDataList()
                })
            }
            //下载模板
            dowLoadFile = (targetMark, targetName) => {
                let data = {
                    type: targetMark
                }
                axios.get(downExcelTemplateUrl, {
                    responseType: 'blob',
                    params: data
                }).then(res => {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download", `${targetName}.xls`);

                    document.body.appendChild(link);
                    console.log(res);

                    link.click();
                })
            }
            onchangePersonNum = (e) => {
                this.setState({factorContent: e})
            }

            // section Render
            render() {
                const {
                    deletePlanModal,
                    dataSource,
                    addPlatforMsgModal,
                    addCertificateModal,
                    isindependent,
                    interflowInfo,
                    condition_121,
                    targetId,
                    addInterflowPlatformFlag,
                    editPlantFormFlag,
                    editPlanFormInfo,
                    editDataFlag,
                    addInterflowDataFlag,
                    factorContent,
                    dataSourceMaterial,
                    pageSize,
                    pageNum,
                    total,
                    totalone,
                    pageSizeone,
                    editInterflowDataInfo,
                    fileData,
                    examine,
                    value,
                    // 资源数据互通的值
                    resourceValue,
                    resourceId
                } = this.state;
                const canEdit = getCanEdit()
                const columns = [
                    {
                        title: '序号', dataIndex: 'platformId', key: 'platformId', align: 'center', width: 80,
                        render: (text, record, index) => {
                            return `${pageSize * (pageNum - 1) + index + 1}`
                        }
                    },
                    {title: '平台名称', dataIndex: 'platformName', key: 'platformName', align: 'center', width: 200},
                    {title: '平台建设方', dataIndex: 'platformBuild', key: 'platformBuild', align: 'center', width: 150},
                    {
                        title: '平台地址',
                        dataIndex: 'platformAddress',
                        key: 'platformAddress',
                        align: 'center',
                        width: 150
                    }
                ]
                if (canEdit) columns.push({
                    title: '操作', align: 'center',
                    width: 200,
                    dataIndex: 'oper',
                    key: 'oper',
                    render: (text, record) => <p className='render-span vertical_j oper'>
                        {
                            this.state.value === 0 ? (
                                <div className="disabled_span">
                                    <span>编辑</span>
                                    <span>删除</span>
                                </div>
                            ) : (
                                <div>
                                    <span onClick={() => this.editPlantForm(record)}>编辑</span>
                                    <span onClick={() => this.deletePlantForm(record)}>删除</span>
                                </div>
                            )
                        }
                    </p>
                })

                const {getFieldDecorator} = this.props.form;
                const {Option} = Select;
                return (
                    <div className="PlatformCommunication_main Fillside_warp">
                        <Heder/>
                        <div className="Efficiency_header">
                            <div className="main_cont">
                                <EffTop/>
                            </div>
                        </div>
                        <div className="Fillside_bottom clearfix">
                            <div className="main_cont">
                                <div className="Fillside_warp" id='Fillside_warp'>
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="leftcollect" id="leftcollect">
                                            <div className="Double-left-table">
                                                <LeftEfficiencyside onRef={(ref) => {
                                                    this.LeftEfficiencyside = ref;
                                                }}/>
                                            </div>
                                        </Col>
                                        <Col span={20} className="Task_right">
                                            <div className="right_ReformPersonnel">
                                                <div className="title_top">
                                                    <h4>1.2.1 虚拟仿真实训教学管理及资源共享平台</h4>
                                                </div>
                                                <div className="conte">

                                                    <span>{"是否具有独立的虚拟仿真实训教学管理及资源共享平台？"}</span>
                                                    <Radio.Group disabled={!canEdit} onChange={this.onChange}
                                                                 value={value}>
                                                        <Radio value={1}>是</Radio>
                                                        <Radio value={0}>否</Radio>
                                                    </Radio.Group>


                                                    <div style={{marginBottom: '0px', overflow: 'hidden'}}>
                                                        {
                                                            canEdit ? (<div className="button_bottom">
                                                                <div className='conteBtnDiv'
                                                                     onClick={this.addPlatforMsg}>
                                                                    <AddPlatformInformation/><span
                                                                    className='conteBtnDivSpan'>添加平台信息</span>
                                                                </div>
                                                            </div>) : ""
                                                        }
                                                    </div>

                                                    <div
                                                        className={value === 0 ? 'conteTable conteTable_on' : "conteTable"}>
                                                        {
                                                            value === 0 ? (
                                                                <div className="taper_cont">
                                                                    <span className="span_time">
                                                                        <TaperedEnd/>
                                                                        <span>上锁</span>
                                                                    </span>
                                                                </div>
                                                            ) : ""
                                                        }
                                                        <Table columns={columns} dataSource={dataSource} bordered={true}
                                                               locale={{
                                                                   emptyText: <>
                                                                       <div className="Empty-content">
                                                                           <div className="text">
                                                                               <img
                                                                                   src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                               <span>暂无数据</span>
                                                                           </div>
                                                                       </div>
                                                                   </>
                                                               }}
                                                               pagination={
                                                                   {
                                                                       total: total,
                                                                       pageSize: pageSize,
                                                                       current: pageNum,
                                                                       onChange: this.pageChange,
                                                                       onShowSizeChange: this.onShowSizeChange,
                                                                       pageSizeOptions: ['10', '20', '30', '40'],
                                                                       showSizeChanger: true,
                                                                   }
                                                               }
                                                        />
                                                    </div>

                                                </div>


                                                <div className="title_top title_topB">
                                                    <h4>{"1.2.2 平台互联、数据互通"}</h4>
                                                </div>
                                                <div className="conte conte-bottom">
                                                    <span>是否实现指南“宏观架构”要求的互联互通</span>
                                                    <Radio.Group disabled={!canEdit}
                                                                 onChange={this.onCondition_122Value}
                                                                 value={this.condition_122Value}>
                                                        <Radio value={1}>是</Radio>
                                                        <Radio value={0}>否</Radio>
                                                    </Radio.Group>
                                                </div>
                                                <div className="conte">
                                                    <UploaderComponent
                                                        editEnable={canEdit}
                                                        targetId={this.condition_122TargetId}
                                                    />
                                                </div>

                                                <div className="title_top title_topB">
                                                    <h4>{"1.2.3 网络安全等级保护情况"}</h4>
                                                </div>
                                                <div className="conte conte-bottom">
                                                    {/*todo 需要修改,目前写死的*/}
                                                    <span>是否完成网络安全定级备案？</span>
                                                    <Radio.Group disabled={!canEdit}
                                                                 onChange={this.onCondition_123_2Value}
                                                                 value={this.condition_123_2Value}>
                                                        <Radio value={1}>是</Radio>
                                                        <Radio value={0}>否</Radio>
                                                    </Radio.Group>
                                                </div>
                                                <div className="conte conte-bottom">
                                                    {/*todo 需要修改,目前写死的*/}
                                                    <span>是否通过信息安全等级保护测评？</span>
                                                    <Radio.Group disabled={!canEdit}
                                                                 onChange={this.onCondition_123_1Value}
                                                                 value={this.condition_123_1Value}>
                                                        <Radio value={1}>是</Radio>
                                                        <Radio value={0}>否</Radio>
                                                    </Radio.Group>
                                                </div>

                                                <div className="conte">
                                                    <UploaderComponent
                                                        editEnable={canEdit}
                                                        targetId={this.condition_123TargetId}
                                                    />
                                                </div>


                                                <div className="title_top title_topB">
                                                    {/*todo 需要修改,目前写死的*/}
                                                    <h4>{"1.2.4 管理科学规范"}</h4>
                                                </div>
                                                <div className="conte conte-bottom">
                                                    {/*todo 需要修改,目前写死的*/}
                                                    <div>请提供反映虚拟仿真实训过程的基础数据和原始记录</div>
                                                    <div style={{
                                                        color: "#D75746",
                                                        paddingTop: "10px"
                                                    }}>*要求全面、可视化、智能化程度高，能开展诊断改进
                                                    </div>
                                                </div>
                                                <div className="conte">
                                                    <UploaderComponent
                                                        editEnable={canEdit}
                                                        targetId={this.condition_124TargetId}
                                                    />
                                                </div>


                                                {canEdit ? <div className="bottom-but">
                                                    <Button className="but-li" onClick={() => this.previous()}>
                                                        上一页
                                                    </Button>
                                                    <Button className="but-li" type="primary"
                                                            onClick={() => this.next()}>
                                                        下一页
                                                    </Button>
                                                </div> : ""}

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {/*二次确认删除*/}
                        <Modal
                            title={'温馨提示'}
                            visible={deletePlanModal}
                            width={430}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            className={'wenxintishi'}
                            footer={
                                <div className='footer-btn'>
                                    <Button className={'quxiao'}
                                            onClick={() => this.setState({deletePlanModal: false})}>取消</Button>
                                    <Button type='primary' onClick={this.deletePlantForm}>确定</Button>
                                </div>
                            }
                        >
                            <div className={'tipwrap'}>
                                <WenxinTip/><span>是否确定删除？</span>
                            </div>
                        </Modal>

                        {/*二次确认*/}
                        <Modal
                            title={'温馨提示'}
                            visible={isindependent}
                            width={430}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            className={'wenxintishi'}
                            footer={
                                <div className='footer-btn'>
                                    <Button className={'quxiao'}
                                            onClick={() => this.setState({isindependent: false})}>取消</Button>
                                    <Button type='primary' onClick={this.isindependentOk}>确定</Button>
                                </div>
                            }
                        >
                            <div className={'tipwrap'}>
                                <WenxinTip/><span>选择否，将清空当前已填写的数据，请确认是否继续？</span>
                            </div>
                        </Modal>
                        {/*新增平台信息*/}
                        <Modal
                            title={editPlantFormFlag ? "编辑平台信息" : "添加平台信息"}
                            visible={addPlatforMsgModal}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            width={660}
                            destroyOnClose={true}
                            wrapClassName="wappop_up"
                            footer={
                                <div className='addModalBtn'>
                                    <Button className={'quxiao'}
                                            onClick={() => this.setState({addPlatforMsgModal: false})}>取 消</Button>
                                    <Button disabled={addInterflowPlatformFlag}
                                            onClick={() => this.addInterflowPlatform(false)} type='primary'>提交</Button>
                                    {editPlantFormFlag ? "" : <Button disabled={addInterflowPlatformFlag}
                                                                      onClick={() => this.addInterflowPlatform(true)}
                                                                      type='primary'>提交并继续添加</Button>}
                                </div>
                            }
                        >
                            <AddPlatforMsg onRef={(ref) => this.AddPlatforMsgChild = ref} targetId={targetId}
                                           editPlanFormInfo={editPlanFormInfo} editPlantFormFlag={editPlantFormFlag}/>
                        </Modal>

                        {/*新增证明材料*/}
                        <Modal
                            title={editDataFlag ? "编辑证明材料" : "新增证明材料"}
                            visible={addCertificateModal}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            width={660}
                            destroyOnClose={true}
                            wrapClassName="wappop_up"
                            footer={
                                <div className='addModalBtn'>
                                    <Button className={'quxiao'}
                                            onClick={() => this.setState({addCertificateModal: false})}>取 消</Button>
                                    <Button disabled={addInterflowDataFlag} onClick={() => this.addInterflowData(false)}
                                            type='primary'>提交</Button>
                                    {editDataFlag ? "" : <Button disabled={addInterflowDataFlag}
                                                                 onClick={() => this.addInterflowData(true)}
                                                                 type='primary'>提交并继续添加</Button>}
                                </div>
                            }
                        >
                            <AddCertificate onRef={(ref) => this.AddCertificateChild = ref} editDataFlag={editDataFlag}
                                            editInterflowDataInfo={editInterflowDataInfo} fileData={fileData}/>
                        </Modal>
                        {/*查看资料*/}
                        <Drawer
                            title={'查看资料'}
                            visible={examine}
                            onOk={this.handExamine}
                            onClose={this.onCancel}
                            destroyOnClose={true}
                            closable={true}
                            maskClosable={true}
                            wrapClassName="wappop_up lookMaterial"
                            footer={
                                <div className='addModalBtn' style={{display: 'none'}}>
                                    <Button className={'quxiao'} onClick={() => this.setState({examine: false})}>取
                                        消</Button>
                                    <Button type='primary'>确定</Button>
                                </div>
                            }
                        >
                            <LookCheck id={resourceId}/>
                        </Drawer>

                        {/*顶部star*/}
                        <Footer/>
                    </div>
                )
            }
        }
    )
)
