// 项目情况-平台情况
import React from "react";
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Spin, Tooltip,} from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import axios from "axios";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import {
    saveSatisfaction,
    selectAllResourcesService,
    selectSatisfaction, validData,
} from '../../api/target'
import {downTemplate} from "../../api/sharing";
import {uploadFile} from '../../api/uploadFile'
import {TianJia, Upicon, Upicondele, Upiconwc} from '../../components/iconSvg/index'
import moment from "moment";
import md5 from "js-md5";
import Footer from "../../components/footer";
import Cookies from "js-cookie";
import {getCanEdit, getSecondTargetList, getTargetId} from "../../until/cookie";
import FileLook from "../components/file_look";
import {BaseUrl} from "../../config/common";
import PlatformServiceComponent_2_2_1 from "../../components/platform_service/2_2_1";
import PlatformServiceComponent_2_2_2 from "../../components/platform_service/2_2_2";
import PlatformServiceComponent_2_2_4 from "../../components/platform_service/2_2_4";
import UploaderComponent from "../../components/uploader_component";
import PlatformServiceComponent_2_2_3 from "../../components/platform_service/2_2_3";
import PlatformServiceComponent_2_2_5 from "../../components/platform_service/2_2_5";

const {TextArea} = Input;

class PlatformService extends React.Component {
    // section State
    state = {
        allDataDic: {
            // 2.2.1
            socialTrainVo: {},
            // 2.2.2
            socialCourseVo: {},
            // 2.2.3
            socialCourseCloudPeopleVo: {},
            // 2.2.4
            socialProfessionalSkillVo: {},
            // 2.2.5
            socialProfessionalSkillNumVo: {},
            // 2.2.6
            socialQuestionnaireSurveyVo: {}
        },
        data_2_2_4_have_data: false,
        dataSource1: [],
        dataSource2: [],
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        showAddJl: false,//添加记录1,2
        // targetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
        targetId: getTargetId("platform_service"),
        degreeTargetId: getSecondTargetList("platform_service")[2],
        total: 0,
        pageSizeone: 10,//条数
        totalone: 0,
        usePeriod1: '',
        resourcesPeriod1: '',
        personNum2: '',
        usePeriod2: '',
        studentNum3: '',
        tableData: [],
        targetId1: '',
        targetId2: '',
        isEdit: false,
        examine: false,
        evidenceId: '',
        addEvidence: false,  //上传满意度报告
        satisfactionInfo: {
            fileIdList: [],
            id: 0,
            schoolEduCode: "",
            // 社会人员满意度
            socDegree: 0,
            // 学生满意度
            stuDegree: 0,
            targetId: "",
            taskId: "",
            // 教师满意度
            teaDegree: 0,
            files: []
        }
    };

    get getDegreeTargetId() {
        if (this.state.allDataDic && this.state.allDataDic.socialQuestionnaireSurveyVo) {
            return this.state.allDataDic.socialQuestionnaireSurveyVo.id || ""
        }
        return ""
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.selectAllResourcesService()
    }

    selectAllResourcesService = () => {
        let data = {
            id: this.state.targetId
        }
        selectAllResourcesService(data).then(e => {
            let haveData = (e?.socialProfessionalSkillVo?.pageData?.data ? e?.socialProfessionalSkillVo?.pageData?.data.length : 0) > 0
            console.log(haveData)
            this.setState({
                allDataDic: e,
                data_2_2_4_have_data: haveData
            }, e => {
                this.selectSatisfaction()
                console.log(this.state.dataSource2)
            })
        })
    }
    // 获取满意度
    selectSatisfaction = () => {
        if (this.state.schoolEduCode) {
            selectSatisfaction(this.state.schoolEduCode, this.getDegreeTargetId).then(satisfactionInfo => {
                if (undefined === satisfactionInfo) {
                    return
                }
                const files = satisfactionInfo.files || [];
                satisfactionInfo.fileIdList = files.map(file => file.id)
                this.setState({
                    satisfactionInfo
                })
            });
        }
    }

    // 上一步
    previous = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }




        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_talenttraining") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_talenttraining',
                    })
                    break;
                }
            }
        }
    }
    // 下一步
    next = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }



        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "Platform_coursesharing") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/Platform_coursesharing',
                    })
                    break;
                }
            }
        }
    }

    handlerUploadFiles = (files) => {
        //获取原有信息
        const satisfactionInfo = this.state.satisfactionInfo

        // 文件列表
        const finalFiles = satisfactionInfo.files ?? []
        // 添加插入的文件列表
        finalFiles.splice(0, 0, ...files)
        // 获取文件id
        satisfactionInfo.fileIdList = finalFiles.map(file => file.id)
        satisfactionInfo.files = finalFiles

        // 保存文件id列表并请求后台告知
        this.setState({satisfactionInfo}, () => {
            if (this.state.satisfactionInfo) {
                const params = this.state.satisfactionInfo
                params.schoolEduCode = this.state.schoolEduCode
                params.targetId = this.getDegreeTargetId
                saveSatisfaction(params).then(res => {
                    message.success("添加成功")
                    this.selectSatisfaction()
                })
            }
        })
    }
    handlerDeleteFile = (index) => {
        //获取原有信息
        const satisfactionInfo = this.state.satisfactionInfo

        // 文件列表
        const finalFiles = satisfactionInfo.files ?? []
        // 删除下表下的元素
        finalFiles.splice(index, 1)
        // 获取文件id
        satisfactionInfo.fileIdList = finalFiles.map(file => file.id)
        satisfactionInfo.files = finalFiles

        // 保存文件id列表并请求后台告知
        this.setState({satisfactionInfo}, () => {
            if (this.state.satisfactionInfo) {
                const params = this.state.satisfactionInfo
                params.schoolEduCode = this.state.schoolEduCode
                params.targetId = this.getDegreeTargetId
                saveSatisfaction(params).then(res => {
                    message.success("删除成功")
                    this.selectSatisfaction()
                })
            }
        })
    }
    // 更新满意度
    saveSatisfactionInfo = (value) => {
        if (this.state.satisfactionInfo) {
            const params = this.state.satisfactionInfo
            params.schoolEduCode = this.state.schoolEduCode
            params.targetId = this.getDegreeTargetId
            saveSatisfaction(params).then(res => {
            })
        }
    }

    onChangeSocDegree = (e) => {
        const satisfactionInfo = this.state.satisfactionInfo
        satisfactionInfo.socDegree = e
        this.setState({satisfactionInfo})
    }

    // 查看资料
    handLook = (record) => {
        const url = BaseUrl + record.fileId
        this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})
    }

    // 2.2.2数据更新后, 更新2.2.3
    data_2_2_2_update_to_2_2_3 = (socialCourseCloudPeopleVo) => {
        const {allDataDic} = this.state
        allDataDic.socialCourseCloudPeopleVo = socialCourseCloudPeopleVo
        this.setState({
            allDataDic: allDataDic
        })
    }

    data_2_2_4_update_to_2_2_5 = (socialProfessionalSkillNumVo, data_2_2_4_have_data) => {
        console.log("修改后的2.2.5", socialProfessionalSkillNumVo)
        let allDataDic = this.state.allDataDic
        allDataDic.socialProfessionalSkillNumVo = socialProfessionalSkillNumVo
        this.setState({
            allDataDic: allDataDic,
            data_2_2_4_have_data: data_2_2_4_have_data
        })
    }

    // section Render
    render() {
        const canEdit = getCanEdit()

        const {
            allDataDic,
            data_2_2_4_have_data
        } = this.state
        // // 2.2.1
        // socialTrainVo: {},
        // // 2.2.2
        // socialCourseVo: {},
        // // 2.2.3
        // socialCourseCloudPeopleVo: {},
        // // 2.2.4
        // socialProfessionalSkillVo: {},
        // // 2.2.5
        // socialProfessionalSkillNumVo: {},
        // // 2.2.6
        // socialQuestionnaireSurveyVo: {}

        const platformService_2_2_1 = <PlatformServiceComponent_2_2_1
            data_2_2_1={allDataDic ? allDataDic.socialTrainVo : {}}/>
        const platformService_2_2_2 = <PlatformServiceComponent_2_2_2
            data_2_2_2={allDataDic ? allDataDic.socialCourseVo : {}}
            dataUpdate_to_2_2_3={this.data_2_2_2_update_to_2_2_3}/>
        const platformService_2_2_3 = <PlatformServiceComponent_2_2_3
            data_2_2_3={allDataDic ? allDataDic.socialCourseCloudPeopleVo : {}}/>
        const platformService_2_2_4 = <PlatformServiceComponent_2_2_4
            data_2_2_4={allDataDic ? allDataDic.socialProfessionalSkillVo : {}}
            dataUpdate_to_2_2_5={this.data_2_2_4_update_to_2_2_5}/>
        const platformService_2_2_5 = <PlatformServiceComponent_2_2_5
            data_2_2_4_have_data={data_2_2_4_have_data}
            data_2_2_5={allDataDic ? allDataDic.socialProfessionalSkillNumVo : {}}/>


        return (
            <div className="PlatformService main-bg">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id='Fillside_warp'>
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect">
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside onRef={(ref) => {
                                            this.LeftEfficiencyside = ref;
                                        }}/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right">
                                    <div className='content'>
                                        {platformService_2_2_1}
                                        {platformService_2_2_2}
                                        {platformService_2_2_3}
                                        {platformService_2_2_4}
                                        {platformService_2_2_5}

                                        {/*<div className={'cont1'}>*/}
                                        {/*    <div className={'titleName'}>2.2.6 社会人员对虚拟仿真实训的满意度</div>*/}

                                        {/*</div>*/}

                                        <div className={'cont1'} style={{marginBottom: '30px'}}>
                                            <div className={'titleName'}>2.2.6 社会人员对虚拟仿真实训的满意度</div>
                                            <span>社会人员满意度</span>
                                            <span style={{marginLeft: "20px"}} className={'btn btn1'}>
                                                    <InputNumber min={0}
                                                        // type={'number'}
                                                        // value={this.state.resourcesPeriod2}
                                                                 formatter={value => value ? value + "%" : null}
                                                                 max={100}
                                                                 disabled={!canEdit}
                                                                 value={this.state.satisfactionInfo.socDegree}
                                                                 onChange={this.onChangeSocDegree}
                                                                 placeholder={'数值填充'}
                                                                 onBlur={e => this.saveSatisfactionInfo(e.target.value)}/>
                                                </span>
                                        </div>
                                        <UploaderComponent
                                            editEnable={canEdit}
                                            uploadTitle="上传调查问卷报告"
                                            checkTitle="查看调查问卷报告"
                                            fileList={this.state.satisfactionInfo.files}
                                            onDelete={this.handlerDeleteFile}
                                            onGetFiles={this.handlerUploadFiles}/>
                                        {canEdit ? <div className={'footer-btn'}>
                                            <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                            <Button type={'primary'} onClick={() => this.next()}>下一步</Button>
                                        </div> : ""}

                                        {/*添加记录1,2*/}

                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

PlatformService = Form.create()(PlatformService);
export default PlatformService;
