// 项目情况-平台情况
import React from "react";
import {Form, Input, message, Radio, Button, Select, DatePicker, InputNumber, Tooltip, Spin} from 'antd';
import "./index.scss";
import moment from "moment";
import {uploadFile} from "../../../../api/uploadFile";
import {Upicon, Upicondele, Upiconwc} from "../../../../components/iconSvg";
class AddActivities extends React.Component {
    state = {
    };

    componentDidMount() {
        let {data} = this.props;
        console.log('activity', data)
        if (data) {
            let upFile = {
                originName:data.resourceName,
                fileId:data.fileId,
                fileSuffix:data.resourceSuffix,
                fileSize:(data.fileSize / 1024).toFixed(2)
            }
            this.setState({
                file: data.fileId ? upFile : null,
            })
            this.props.form.setFieldsValue({
                activityName: data.activityName,
                activityType: data.activityType,
                activityTime: moment(data.activityTime),
                activityAddress: data.activityAddress,
                peopleNumber: data.peopleNumber,
                organizers: data.organizers,
                fileId: data.fileId,
            })
        }
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null,
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    
    render() {
        const disabledDay = current => {
            // 只能选择当前日期的两个月范围内
            return current && current > moment();
            
          }
        const {getFieldDecorator }=this.props.form
        let {file} = this.state
        return (
            <div className="AddActivites" >
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>活动类型</span>
                        {getFieldDecorator('activityType', {
                            initialValue: null,
                            rules: [
                                {
                                    required: true,
                                    message: `请选择活动类型`,
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>研讨会</Radio>
                                <Radio value={2}>师资培训</Radio>
                                <Radio value={3}>行业交流</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>活动名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('activityName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写活动名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入活动名称' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`activityName`) ? this.props.form.getFieldValue(`activityName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>活动时间</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('activityTime', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择活动时间`,
                                    },
                                ],
                            })(
                                <DatePicker  disabledDate ={ disabledDay } showNow={false}
                                  placeholder={'请选择活动时间'} format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }}/>
                                )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>活动地点</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('activityAddress', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写活动地点`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入活动地点' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`activityAddress`) ? this.props.form.getFieldValue(`activityAddress`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>参会人数</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('peopleNumber', {
                                initialValue: null,
                                rules: [
                                    {
                                        required: true,
                                        // pattern: new RegExp(/^[0-9]*[1-9][0-9]*$/),
                                        message: `请填写参会人数`,
                                    },
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: "只能输入大于等于0的正整数！",
                                    },
                                ],
                            })(
                                <InputNumber className="input" maxLength={10} placeholder={'请输入参会人数'} min={0}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>举办方</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('organizers', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写活动地点`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入举办方名称' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`organizers`) ? this.props.form.getFieldValue(`organizers`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item className="li_formup">
                        <span className={'label'}><span className='red'>*</span>活动成果</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择活动成果`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        onClick={(event)=> { event.target.value = null }}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {/*上传中*/}
                                    {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                </ul>
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
AddActivities = Form.create()(AddActivities);
export default AddActivities;