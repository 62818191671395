import React, {Component} from 'react'
import {Form, Input, Button, Checkbox, message, Row, Col} from 'antd'
import {withRouter} from 'react-router-dom'
import './index.scss'
import LeftComponent from '../demo_Info/components/Left_Efficiencyside'
import Heder from "../../components/header";
import  Demo2 from '../demo2'
class DemoInfo extends Component {

    state = {
        id: this.props.location.state!=null?this.props.location.state.id:31
    };
    dismiss=()=>{
        this.props.history.goBack()
    }
    render() {
        const id=this.props.location.state!=null?this.props.location.state.id:31
        console.log("id======="+id)
        return (
            <div className="Demo_LevelTwo">
            
                <Heder/>

                <div className="content">
                <Row>
                    <Col span={4}>
                        <div>
                            <LeftComponent id={id}></LeftComponent>
                        </div>
                    </Col>
                    <Col span={20}>
                        
                        <div className="right_content">
                        <div style={{paddingTop:"30px",color:"#2A283D",fontSize:"16px",marginLeft:"40px"}}>
                           <span className="title">2.2 党务文件落实工作流程</span>
                        </div>
                            <Demo2></Demo2>
                        </div>
                    </Col>
                </Row>
                </div>
               

                
                 
                
            </div>
        )
    }
}


export default withRouter(Form.create()(DemoInfo))