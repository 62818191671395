import React, {Component} from 'react';
import {Card, Row, Col, Drawer, Button} from 'antd';
import {withRouter} from "react-router-dom";
import "./index.scss";
import {xdocUrlHttps, xdocUrl} from '../../../config/common';

/***
 *
 *  文件的预览
 */
@withRouter
class FileLook extends Component {
    state = {
        fileId: '',
        fileUrl: '',
        isShow: this.props.isShow,
        openUrl: "https://cdn.zjyjc.com/%E8%B5%84%E6%BA%90/%E6%95%99%E8%BE%85/%E6%95%B0%E5%AD%A6%E4%B8%89%E5%B9%B4%E7%BA%A7%E4%B8%8B%E5%86%8C/2%20%E9%99%A4%E6%95%B0%E6%98%AF%E4%B8%80%E4%BD%8D%E6%95%B0%E7%9A%84%E9%99%A4%E6%B3%95/1.%E5%8F%A3%E7%AE%97%E9%99%A4%E6%B3%95/%E7%9F%A5%E8%AF%86%E8%AE%B2%E8%A7%A3/%E4%B8%80%E4%BD%8D%E6%95%B0%E9%99%A4%E5%87%A0%E7%99%BE%E5%87%A0%E5%8D%81%E3%80%81%E5%87%A0%E5%8D%83%E5%87%A0%E7%99%BE%E7%9A%84%E5%8F%A3%E7%AE%97%EF%BC%88%E5%8F%AF%E8%BD%AC%E5%8C%96%E4%B8%BA%E8%A1%A8%E5%86%85%E9%99%A4%E6%B3%95%EF%BC%89.pptx"
    };

    componentDidMount() {

    }

    onCancel = () => {
        this.props.dismiss()

    }

    render() {
        const {fileId, openUrl, isShow} = this.props;
        console.log(openUrl)
        return (
            <Drawer
                title={'查看资料'}
                visible={isShow}
                onOk={this.handExamine}
                onClose={this.onCancel}
                destroyOnClose={true}
                closable={true}
                maskClosable={true}
                wrapClassName="lookMaterial"
                footer={
                    <div className='addModalBtn' style={{display: 'none'}}>
                        <Button className={'quxiao'} onClick={this.onCancel}>取 消</Button>
                        <Button type='primary'>确定</Button>
                    </div>
                }
            >{openUrl ? <div className='neirong'>
                <iframe className="preview" title="iframe"
                        src={openUrl.includes('https') ? (xdocUrlHttps + openUrl) : openUrl.includes('http') ? (xdocUrl + openUrl) : (xdocUrl + openUrl)}
                        width="100%" frameBorder='0'></iframe>
            </div> : ""}
            </Drawer>
        );
    }
}

export default FileLook;
