import React, {Component} from 'react'
import moment from "moment";
import { uploadFile } from '../../api/uploadFile';
import {Input, Select, Form, Button, Upload, message, Modal, Tooltip, Spin, Drawer} from "antd";
import "./index.scss";
import LookCheck from "../../page/components/look_Check";
import {Upicon,Upicondele,Upiconwc} from "../iconSvg";

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
class AddCertificate extends React.Component {
    state = {
        tableData:[],
        platformName:'',
        platformURL:'',
        examine: false,
        file: null,
    }

    componentDidMount() {
        this.props.onRef(this)
        const {editInterflowDataInfo,fileData,editDataFlag} = this.props
        if (editDataFlag){
            if (fileData){
                let data = fileData
                data.originName = fileData.resourceName
                this.setState({
                    file: data
                })
            }
            this.setState({
                platformName: editInterflowDataInfo.platformName,
                platformURL: editInterflowDataInfo.platformAddress,
            })
        }
    }

    handleCancel = () =>{
        this.setState({
            addCertificateModal:false,
        })
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let { tableData } = this.state;
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let checkStatus=false
        list.forEach((k) => {
            const fileNameindex =  k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen =  k.name.length; // 取到文件名长度
            const fileType =  k.name.substring(fileNameindex+1, fileNameLen); // 截
            const type =  k.name.substring(fileNameindex, fileNameLen); // 截
            if(!this.checkFileType(fileType)){
                this.mesError('您上传文件后缀不符，请查看格式说明')
                checkStatus=true
                return
            }
            if (tableData.find((v) => v.resourceName === k.name)){
                this.mesWarning('该资源已存在')
                document.getElementById("file").value = '';
                checkStatus=true
                return
            }
            if (k.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            // if(k.size / 1048576>1024){
            //     this.mesWarning('资源大小不能超过1G')
            //     document.getElementById("file").value = '';
            //     checkStatus=true
            //     return
            // }
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            if (!tableData.find((v) => v.resourceName === k.name)) {
                tableData = []
                tableData.push({
                    // fieldId:fieldId,
                    originName: k.name,
                    fileSuffix: fileType,
                    fileSize: k.size>=1048576?(k.size / 1048576).toFixed(2) + "MB":(k.size / 1024).toFixed(2) + "K",
                    operationTime: transTime(k.lastModifiedDate),
                    time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    plan: 0,
                });
            }
        });
        //类型不符停止进行
        if (checkStatus){
            document.getElementById("file").value = '';
            return
        }
        await this.setState({
            tableData: tableData,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        const {fileType} = this.state
        if (!v) return;
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        return new Promise((reslove, reject) => {
            if (v.size > 1024 * 1024 * 1024) {
                return this.mesWarning('文件大小≤1G');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };

    //返回上传list列表
    getTableData = () => {
        return this.state.file
    };

    //组件重置
    reset = () => {
        this.setState({
            fileResult: [],
            platformName: '',
            platformURL: '',
            file: null
        })
        this.props.form.resetFields();
    };

    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc/docx、xls/xlsx、ppt/pptx、txt、jpg、jpeg、png、bmp、gif"
        return checkType.includes(type)
    };

    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    //查看资源
    checkFile = () => {
        this.setState({
            examine: true,
        })
    }
    handExamine = () =>{
        this.setState({
            examine:true
        })
    }
    onCancel = () =>{
        this.setState({
            examine:false
        })
    }

    render() {
        const {platformName,platformURL,tableData,examine,file} = this.state
        const {getFieldDecorator} = this.props.form;

        return (
            <div className='AddCertificate'>
                <Form>
                    <Form.Item label="平台名称" >
                        {
                            getFieldDecorator('platformName', {
                                initialValue: platformName,
                                rules: [
                                    {
                                        required: true,
                                        message: '平台名称不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`platformName`) ? this.props.form.getFieldValue(`platformName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="平台地址">
                        {
                            getFieldDecorator('platformURL', {
                                initialValue: platformURL,
                                rules: [
                                    {
                                        required: true,
                                        message: '平台地址不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容'  maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`platformURL`) ? this.props.form.getFieldValue(`platformURL`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="材料证明"
                               className={'li_formup input-wrap'} >
                        {
                            getFieldDecorator('fileId', {
                                initialValue: file,
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择材料证明`,
                                    },

                                ]
                            })(
                                <div className="up_button">
                                    <div className="span_right">
                                        <label htmlFor="file"  className="inputlabelBox">
                                            <span><Upicon/>上传资料</span>
                                        </label>
                                        <input
                                            type="file"
                                            accept="image"
                                            onClick={(event)=> { event.target.value = null }}
                                            onChange={this.multipartUpload}
                                            name="myfile"
                                            id="file"
                                            style={{ display: "none" }}
                                        />
                                        <span className={'tip'}>
                                                支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                            </span>
                                    </div>
                                    <ul className="bottom_tit">
                                        {/*上传中*/}
                                        {file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}
                                        {/*已完成*/}
                                        {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                    </ul>
                                </div>
                                // <div className="span_right">
                                //     <label htmlFor="file"  className="inputlabelBox">
                                //         {tableData.length>0?tableData[0].originName:<span className='upLoadText'>上传资料</span>}
                                //     </label>
                                //     <input
                                //         type="file"
                                //         accept="image"
                                //         onChange={this.multipartUpload}
                                //         name="myfile"
                                //         id="file"
                                //         style={{ display: "none" }}
                                //     />
                                //     <span className={'len'}>{tableData.length}/1</span>
                                //     {tableData.length>0?<><Button className='MaBtn' onClick={this.checkFile}>查看</Button><Button className='MaBtn' onClick={this.deleteTableData}>删除</Button></>:''}
                                // </div>
                            )}
                    </Form.Item>
                </Form>
                {/*查看资料*/}
                <Drawer
                    title={'查看资料'}
                    visible={examine}
                    onOk={this.handExamine}
                    onClose={this.onCancel}
                    destroyOnClose={true}
                    closable={true}
                    maskClosable={true}
                    wrapClassName="lookMaterial"
                    footer={
                        <div className='addModalBtn' style={{display:'none'}}>
                            <Button className={'quxiao'} onClick={() => this.setState({examine: false})}>取 消</Button>
                            <Button type='primary'>确定</Button>
                        </div>
                    }
                >
                    <LookCheck id={tableData.length>0?tableData[0].id:''}/>
                </Drawer>
            </div>
        )
    }
}


AddCertificate = Form.create()(AddCertificate);
export default AddCertificate;
