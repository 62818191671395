import React from 'react'
import { Table } from 'antd'
export default class TableCmp extends React.Component {
  state = {}

  pageChange = (page, pageSize) => {
    let { changePage } = this.props
    changePage && changePage(page, pageSize) 
  }
  pageSizeChange = (page, size) => {
    let { pageSizeChange } = this.props
    pageSizeChange && pageSizeChange(1, size)
  }
  render() {
    let { total, pageSize, pageNum, columns = [], data = [] , loading} = this.props
    return (
      <Table columns={columns} dataSource={data} rowKey={record => record.id}
        bordered={true} className='table1'
        loading={loading}
        locale={{
          emptyText: <>
            <div className="Empty-content">
              <div className="text">
                <img
                  src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'} />
                <span>暂无数据</span>
              </div>
            </div>
          </>
        }}
        pagination={
          {
            current: pageNum,
            total: total,
            pageSize: pageSize,
            onChange: this.pageChange,
            onShowSizeChange: this.pageSizeChange,
            pageSizeOptions: ['10', '20', '30', '40'],
            showSizeChanger: true,
            // position:'bottomRight'
          }
        }
      />
    )
  }
}

TableCmp.defaultProps = {
  total: 0,
  pageSize: 10,
  pageNum: 1,
  columns: [],
  data: []
}