import React, { Component } from "react";
import { Table, Modal, Button, message } from "antd";
import { Professionalicon } from "@@/iconSvg";
import ImportComponent from "@@/importComponent/ImportComponent";
import { getLaboratoryUsing, savelaboratoryUsing, deletelaboratoryUsing } from '@/api/constructionResults'
import Cookies from "js-cookie";
import Forms from './Forms';
import "./styles.scss";

export default class Effective_1_5_4 extends Component {
  state = {
    total: 0,
    pageSize: 0,
    pageNum: 1,
    data: [],
    modalVisible: false,
    schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    editObj: {},
    usingRatio: '',
  }

  componentDidMount() {
    this.initGetList();
  } 

  // 请求数据
  initGetList = async (pageNum = 1, pageSize = 10) => {
    /**
     * "pageNum": "", 当前页
     * "pageSize": "", 每页记录数
     * "schoolEduCode": "", 学校编码
     * "targetId": ""  指标ID
    */

    const params = {
      pageNum,
      pageSize,
      "schoolEduCode": this.state.schoolInfo.schoolEduCode,
	    "targetId": this.props.getTargetId
    }

    const res = await getLaboratoryUsing(params);

    if(res) {
      const { pageNum, pageSize, total, data } = res.data;
      
      data.map((element, i) => element.index = (pageNum - 1) * pageSize + (i + 1));

      this.setState({ 
        pageNum,
        pageSize,
        total,
        data,
        usingRatio: (res?.usingRatio * 1) || ''
      })
    }
  }

  // 添加单条数据
  onInsertData = () => this.setState({ editObj: {} }, () => this.setState({ modalVisible: true }));

  // 修改单条数据
  onUpdateRecord = editObj => this.setState({ editObj }, () => this.setState({ modalVisible: true }));

  // 删除单条数据
  onDeleteRecord = async id => {
    const params = {
      id,
      "pageNum": 1,
      "pageSize": 10
    }

    const res = await deletelaboratoryUsing(params);

    if(res === 1) {
      message.success('删除成功');

      this.initGetList(1, this.state.pageSize);
    }
  };

  // 提交表单
  onSubmitForm = (isContinue = false) => {
    /**
      *  "id": "", 主键ID
      *  "laboratoryName": "", 名称
      *  "yearSchoolPeriod": "", 年度在校生计划实训学时
      *  "yearSocialPeriod": '' 年度社会人员计划实训学时
      *  "yearApplyPeriod": "",  "年度计划外申请实训学时",
      *  "yearMaxPeriod": "",  "年度最大实训学时",
      *  "schoolEduCode": "", 学校编码
      *  "targetId": ""  指标ID
    */

    const params = {
      schoolEduCode: this.state.schoolInfo.schoolEduCode,
      targetId: this.props.getTargetId,
    }
    
    if (this.state.editObj?.id) {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          const values = {...params, ...value, id: this.state.editObj?.id}
          const res = await savelaboratoryUsing(values);
          if(res === 1) {
            message.success('修改成功');
            this.setState({ modalVisible: false });
            this.initGetList(this.state.pageNum, this.state.pageSize);
          } 
        }
      })
    } else {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          const values = { ...params, ...value }
          const res = await savelaboratoryUsing(values);
          if(res === 1) {
            message.success('新增成功');
            this.initGetList(1);

            isContinue ? this.modalForm.props.form.resetFields() : this.setState({ modalVisible: false });
          }
        }
      })
    }
  }

  // 切换页码
  onPageChange = (pageNum, pageSize) => {
    console.log(pageNum, pageSize)
    this.setState({ pageNum, pageSize }, () => { this.initGetList(pageNum, pageSize); });
  };

  // 切换页码
  onShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize }, () => { this.initGetList(1, pageSize); });
  };
  
  render() {
    const { usingRatio, total, pageSize, pageNum, data, modalVisible, editObj } = this.state;
    const { canEdit, getTargetId, } = this.props;

    let columns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        align: "center",
        width: 80
      },
      {
        title: "实训室名称",
        dataIndex: "laboratoryName",
        key: "laboratoryName",
        align: "center",
      },
      {
        title: "年度在校生计划实训学时",
        dataIndex: "yearSchoolPeriod",
        key: "yearSchoolPeriod",
        align: "center",
      },
      {
        title: "年度社会人员计划实训学时",
        dataIndex: "yearSocialPeriod",
        key: "yearSocialPeriod",
        align: "center",
      },
      {
        title: "年度计划外申请实训学时",
        dataIndex: "yearApplyPeriod",
        key: "yearApplyPeriod",
        align: "center",
      },
      {
        title: "年度最大实训学时",
        dataIndex: "yearMaxPeriod",
        key: "yearMaxPeriod",
        align: "center",
      },
      {
        title: "利用率%",
        dataIndex: "usingRatio",
        key: "usingRatio",
        align: "center",
        render: (text) => {
          return text + '%'
        }
      }
    ];

    if (canEdit) { 
      columns.push({
        title: '操作', 
        align: 'center',
        width: 200,
        dataIndex: 'oper',
        key: 'oper',
        render: (_, record) => <div className='render-span vertical_j oper'>
          <div>
            <span className="cz" onClick={() => this.onUpdateRecord(record)}>编辑</span>
            <span className="cz" onClick={() => this.onDeleteRecord(record.id)}>删除</span>
          </div>
        </div>
      })
    }

    return (
      <div className="effective_1_5_5_wrap">
        <div className="conteCompPercent">
          <span>平均利用率</span>
          <div 
            className="span_input"
            style={{border: usingRatio && usingRatio < 20 &&  usingRatio !== 0 ? '1px solid #D75746' : '1px solid #D9D9D9'}}
          >{usingRatio}{usingRatio ? '%' : ''}</div>
          {
            usingRatio && usingRatio < 20 &&  usingRatio !== 0 ? <span style={{color: '#D75746'}}>*该项指标需高于20%</span> : null
          }
        </div>

        <div className="insert_upload_button">
          {canEdit ? (
            <div className="conteBtnDiv" onClick={this.onInsertData}>
              <Professionalicon />
              <span className="conteBtnDivSpan">添加</span>
            </div>
          ) : (
            ""
          )}

          <ImportComponent
            editEnable={canEdit}
            targetId={getTargetId}
            targetMark="1.5.5"
            downloadName="虚拟仿真实训基地年度平均利用率"
            onImportSuccess={(res) => {
              if(res === 1) {
                this.initGetList(1);
              }
            }}
          />
        </div>

        <div style={{ marginBottom: "100px" }}>
          <div className="conteTable" style={{ marginTop: '0px' }}>
            <Table
              columns={columns}
              dataSource={data}
              bordered={true}
              locale={{
                emptyText: (
                  <>
                    <div className="Empty-content">
                      <div className="text">
                        <img src={ window.$$imgSrcLcl + "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg" } />
                        <span>暂无数据</span>
                      </div>
                    </div>
                  </>
                ),
              }}
              pagination={{
                total: total,
                pageSize: pageSize,
                current: pageNum,
                onChange: this.onPageChange,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
        
        {
          <Modal
            title={!editObj?.id ? '添加' : '编辑'}
            visible={modalVisible}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            onCancel={() => {}}
            className="effective_1_5_4_modal"
            footer={
              <div className='addModalBtn'>
                <Button onClick={() => this.setState({modalVisible: false})}>取 消</Button>
                <Button onClick={()=>this.onSubmitForm(false)} type='primary'>提交</Button>
                {!editObj?.id && <Button onClick={()=>this.onSubmitForm(true)} type='primary'>提交并继续添加</Button>}
              </div>
            }
          >
            <Forms 
              record={this.props.editingTaskRecord} 
              wrappedComponentRef={form => { this.modalForm = form }}
              editObj={editObj}
            />
          </Modal>
        }
        
      </div>
    );
  }
}
