// 平台应用-平台情况
import React from "react";
import {
    Form,
    Row,
    Col,
    Radio,
    Table,
    Button,
    Input,
    Select,
    Modal,
    message,
    InputNumber, Tooltip, Spin, Drawer,
} from 'antd';
import { withRouter } from "react-router-dom";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import Pesourcename from "../../components/addpesource";
import {
    AddPlatformInformation,
    Daoru, WenxinTip, TianJia,
    SubmissionSupportingMaterials, Upicon, ExcelImport, TaperedEnd, Upiconwc, Upicondele,
} from "../../components/iconSvg";
import PlatformService from "../platform_Service";
import axios from "axios";
import md5 from "js-md5";
import Addevidence from "./Add_evidencelist/index";
import Addevidencelook from "./Add_evidencelist/look_evidence";
import {
    selectAllPlatformApplication,
    addApplyResources,
    updateApplyResources,
    deleteApplyResources,
    selectApplyResourcesById,
    saveConditionStatus,
    updateFactor,
    importPlatformApplication,
    addEvidence,
    updateMajorNum,
    checkPlatformService, selectEvidenceByTargetId
} from '../../api/target'
import {downTemplate} from "../../api/sharing";
import "./index.scss";
import {uploadFile} from '../../api/uploadFile'
import Cookies from "js-cookie";
import LookCheck from "../components/look_Check";

class ApplicationPlatform extends React.Component {
    state = {
        schoolEduCode:JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        addPlatforMsgModal:false,//添加平台信息Modal
        addCertificateModal:false,//新增证明材料Modal
        addEvidence:false,  //添加佐证材料
        isindependent:false, //二次确认
        value: 2,
        dataList:[],
        pageNum:1,//页数
        pageSize:10,//条数
        total1: 0,
        total2: 0,
        pageNumone:1,//页数
        pageSizeone:10,//条数
        totalone: 0,
        pageNumtwo:1,//页数
        pageSizetwo:10,//条数
        totaltwo: 0,
        dataSource1:[],
        dataSource2:[],
        columnsPersent:[
            {title: '序号', dataIndex: '', key: '', align: 'center', width: '200',
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{index+1}</span>
                }},
            {title: '互通平台名称', dataIndex: 'objectName', key: 'objectName', align: 'center', width: '200'},
            {title: '平台地址', dataIndex: 'websiteLink', key: 'websiteLink', align: 'center', width: '150'},
            {title: '证明材料', dataIndex: 'evidenceId', key: 'evidenceId', align: 'center', width: '150',
                render:  (text, record) =>  record.evidenceId? <span className={'chakan'} onClick={()=>this.handLook(record)}>查看</span> : <span>未上传</span>},

            {
                title: '操作', align: 'center',
                width: 220,
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => <div className='render-span vertical_j oper'>
                    <div>
                        <span className={'cz'} onClick={()=>{this.edit(record.id)}}>编辑</span>
                        <span className={'cz'} onClick={() => this.deleteAsk(record)}>删除</span>
                    </div>
                </div>
            }
        ],
        schoolStudentNum:'',
        socialNum:'',
        majorNum:'',
        targetId1:'',
        targetId2:'',
        targetId3:'',
        targetName3:'',
        targetMark3:'',
        targetId4:'',
        targetName4:'',
        targetMark4:'',
        isEdit:false,
        targetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
        socailNumFactorId:'',
        conditionId:'',
        conditionStatus:'',
        isIndependent:false,
        resultData:[]
    };
    componentDidMount() {
        this.selectAllPlatformApplication()
    }
    //检查是否全部填写
    check1 =(id)=>{
        let isCheck = false
        if(this.state.majorNum){
            isCheck = true
        }
        let data ={
            addAll:isCheck,
            targetId:id
        }
        checkPlatformService(data).then(e=>{
        })
    }
    check2 =(id)=>{
        let isCheck = false
        if(this.state.majorNum && this.state.socialNum && this.state.value !=0){
            isCheck = true
        }
        let data ={
            addAll:isCheck,
            targetId:id
        }
        checkPlatformService(data).then(e=>{
        })
    }
    check3 =(id)=>{
        let isCheck = false
        if((this.state.dataSource1.length > 0 && this.state.value ==1) || this.state.value ==2){
            isCheck = true
        }
        let data ={
            addAll:isCheck,
            targetId:id
        }
        checkPlatformService(data).then(e=>{
        })
    }
    // 查看资料
    handLook = (record) =>{
        console.log('---------------',record)
        if (record.transStatus === 2){
            message.warning("该文件转码失败")
            return ;
        }
        if (record.transStatus === 3){
            message.warning("转码中")
            return ;
        }
        this.setState({
            evidenceId: record.evidenceId
        },()=>{
            this.setState({
                examine:true,
            })
        })
    }
    selectAllPlatformApplication =()=>{
        let data = {
            targetId:this.state.targetId,
            schoolEduCode:this.state.schoolEduCode
        }
        selectAllPlatformApplication(data).then(e=>{
            let data1={
                id:e.proportionVos[0].id
            }
            selectEvidenceByTargetId(data1).then(e=>{
                this.setState({
                    total1:e.length
                })
            })
            let data2={
                id:e.proportionVos[1].id
            }
            selectEvidenceByTargetId(data2).then(e=>{
                this.setState({
                    total2:e.length
                })
            })
            this.setState({
                targetId1:e.proportionVos[0].id,
                targetId2:e.proportionVos[1].id,
                targetId3:e.applyVos[0].id,
                targetId4:e.applyVos[1].id,
                dataSource1:e.applyVos[0].pageData.data,
                dataSource2:e.applyVos[1].pageData.data,
                schoolStudentNum:e.proportionVos[0].studentNumber,
                socialNum:e.proportionVos[1].factors[0].factorContent,
                majorNum:e.proportionVos[0].majorNumber,
                schoolId:e.proportionVos[0].schoolId,
                value:e.applyVos[0].confirmStatus,
                socailNumFactorId: e.proportionVos[1].factors[0].id,
                conditionId:e.applyVos[0].conditionId,
                targetName3:e.applyVos[0].targetName,
                targetMark3:e.applyVos[0].targetMark,
                targetName4:e.applyVos[1].targetName,
                targetMark4:e.applyVos[1].targetMark,
            })
        })
    }
    // 导入文件
    importPlatformApplication = async (e,id) => {
        let list = [...e.target.files];
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        // let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                // flag = true
            }else{
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('schoolEduCode', this.state.schoolEduCode)
                fromData.append('targetId', id)
                importPlatformApplication(fromData).then(res => {
                    message.success('导入成功')
                    this.selectAllPlatformApplication()
                });
            }
        });
    }

    //下载文件
    dowLoadFile = (targetMark, targetName) => {
        let now = new Date().getTime();
        axios.get(downTemplate(targetMark), {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${targetName}.xls`);
            link.click();
        })
    }
    addPlatforMsg = () =>{
        this.setState({
            addPlatforMsgModal:true,
        })
    }
    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    //删除前二次确认
    deleteAsk =(e) => {
        let data ={
            id :e.id
        }
        deleteApplyResources(data).then(e=>{
            message.success("删除成功")
            this.selectAllPlatformApplication()
        })
    }
    addCertificate = (id)  =>{
        this.setState({
            isEdit:false,
            addCertificateModal:true,
            addTargetId:id,
            file:''
        })
    }
    handleCancel = () =>{
        this.selectAllPlatformApplication()
        this.setState({
            addCertificateModal:false,
            LookEvidence:false
        })
    }
    // 上一步
    previous = async () => {
        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId=''
        for (let i = 0; i <targetList.length; i++) {
            for (let j = 0; j <targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_service"){
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_service',
                    })
                    break;
                }
            }
        }
    }
    // 下一步
    next = async () => {
        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId=''
        for (let i = 0; i <targetList.length; i++) {
            for (let j = 0; j <targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "Platform_coursesharing"){
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/Platform_coursesharing',
                    })
                    break;
                }
            }
        }
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize });
    }
    //第二个表格分页切换
    pageChangeone = (pageNum) => {
        this.setState({ pageNumone:pageNum });
    }
    //第二个表格分页条数切换
    onShowSizeChangeone = (pageNum, pageSize) => {
        this.setState({ pageSizeone:pageSize });
    }
    // 添加佐证材料
    onHandevid = (id) =>{
        this.setState({
            addEvidence:true,
            addTargetId:id
        })
    }
    // 查看佐证材料
    lookHandevid = (id) =>{
        this.setState({
            LookEvidence:true,
            addTargetId:id
        })
    }

    // 校验网址格式
    validatehttp =  (rule, value, callback) => {
        let pattern =  /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
        if (value && !pattern.test(value)){
            callback('请输入正确网址链接')
        } else {
            callback();
        }
    }

    handleOk = (id,isContinue) =>{
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let data = {
                    schoolEduCode:this.state.schoolEduCode,
                    targetId:id,
                    resourcesName:value.name,
                    objectName:value.objectName,
                    websiteLink:value.websiteLink,
                    evidenceQo:{
                        fileId:this.state.file.fileId,
                        resourceName:this.state.file.originName,
                        resourceSuffix:this.state.file.fileSuffix,
                        resourceSize:this.state.file.fileSize,
                    }
                }
                if(this.state.isEdit ===false){
                    addApplyResources(data).then(e=>{
                        message.success('添加成功');
                        if(isContinue == false){
                            this.setState({
                                addCertificateModal:false
                            },e=>{
                                this.selectAllPlatformApplication()
                            })
                        }else{
                            this.props.form.resetFields()
                            this.props.form.setFieldsValue({'certificate':''})
                            this.setState({
                                file:''
                            },e=>{
                                this.selectAllPlatformApplication()
                            })
                        }
                    })
                }else{
                    updateApplyResources(data).then(e=>{
                        message.success('修改成功');
                    })
                }
            }
        })
    }

    edit =(id) =>{
        this.setState({
            addTargetId:id,
            isEdit:true,
            addCertificateModal:true
        })
        let data={
            id : id
        }
        selectApplyResourcesById(data).then(e=>{
            if(e.fileId){
                let data ={
                    resourceId:e.resourceId,
                    originName:e.resourceName,
                    fileId:e.fileId
                }
                this.setState({
                    file:data
                })
            }else{
                this.setState({
                    file:''
                })
            }
            this.props.form.setFieldsValue({'name':e.name,'objectName':e.objectName,'websiteLink':e.websiteLink,'certificate':this.state.file})
        })
    }

    submitIndependent = () =>{
        this.setState({value:0})
        let data={
            conditionId:this.state.conditionId,
            conditionStatus:0,
            targetId:this.state.targetId3,
        }
        saveConditionStatus(data).then(res=>{
            message.success("修改成功")
            this.setState({
                isindependent:false
            },()=>{
                this.selectAllPlatformApplication()
            })
        })
    }
    delete =(e)=>{
        let data ={
            id :e.id
        }
        deleteApplyResources(data).then(e=>{
            message.success("删除成功")
        })
    }

    onChange =e=> {
        this.setState({value: e.target.value})
        let data = {
            conditionId: this.state.conditionId,
            conditionStatus: e.target.value,
            targetId: this.state.targetId3,
        }
        if (e.target.value === 0) {
            if(this.state.dataSource1.length===0){
                saveConditionStatus(data).then(res => {
                    this.check3(this.state.targetId3)
                })
            }else{
                this.setState({
                    targetValue: e.target.value,
                    isindependent: true
                })
            }
        } else {
            saveConditionStatus(data).then(res => {
                this.check3(this.state.targetId3)
            })
        }
    }
    //点否二次确认
    // isindependentOk = () => {
    //     this.setState({
    //         isindependent: false
    //     },e=>{
    //         this.submitIndependent()
    //     })
    // }


    saveFactor = (factorContent,id)=>{
        if(!factorContent){
            message.error('社会人员及非本校学生的总数不能为空');
            return
        }
        let data ={
            id:id,
            factorContent:factorContent
        }
        updateFactor(data).then(e=>{
            if (e) {
                this.check2(this.state.targetId2)
            }
        })
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null,
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    getResult =(e)=>{
        this.setState({
            resultData:e
        })
    }
    getCheck =(e)=>{
        this.setState({
            getCheck:e
        })
    }
    submit =(e)=>{
        if(this.state.resultData.length===0){
            message.warning("资料上传中！")
            return
        }

        if(this.state.getCheck === false){
            message.warning("请勾选承诺上传的资料真实可信！")
            return
        }
        let data = {
            list:this.state.resultData,
            targetId:e,
            schoolEduCode:this.state.schoolEduCode
        }
        addEvidence(data).then(e=>{
            message.success("添加成功！")
            this.setState({
                addEvidence:false
            },()=>{
                this.selectAllPlatformApplication()
            })
        })
    }

    saveMajorNum1 =(e)=>{
        if(!e){
            message.error('专业数量不能为空');
            return
        }
        let data = {
            schoolEduCode:this.state.schoolEduCode,
            majorNum:e
        }
        updateMajorNum(data).then(e=>{
        })
    }
    saveMajorNum2 =(e)=>{
        let data = {
            schoolEduCode:this.state.schoolEduCode,
            majorNum:e
        }
        updateMajorNum(data).then(e=>{
        })
    }
    onCancel = () =>{
        this.setState({
            examine:false
        })
    }
    onChangeFactor =(value)=>{
        this.setState({
            majorNum: Number(value)
        })
    }

    cailiaoTijiao = () =>{
        this.setState({lookEvidence: false})
    }
    render() {
        const {dataSource1,dataSource2,columnsPersent,addPlatforMsgModal,addCertificateModal,pageSize,total,totalone,LookEvidence,
            pageSizeone,addEvidence,value,isindependent,file,examine,evidenceId} = this.state;
        const { Option } = Select;
        const {getFieldDecorator} = this.props.form;
        return(
            <div className="ApplicationPlatform main-bg">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id='Fillside_warp'>
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect" >
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside onRef={(ref) => { this.LeftEfficiencyside = ref; }}/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right" >
                                    <div className="right_ReformPersonnel">
                                        <div className="title_top">
                                            <h4>2.3.1 平台实现跨专业服务校内学生的数量占比</h4>
                                        </div>
                                        <div className="conte">
                                            <div className='conteCompPercent'>
                                                <span>学校学生数量</span><Input disabled className='conteInput' value={this.state.schoolStudentNum}/>
                                                <span>专业数量</span><Input className='input_bg' placeholder="数值填充" value={this.state.majorNum}  onChange={e => this.onChangeFactor(e.target.value)}  onBlur={e => this.saveMajorNum1(e.target.value)}/>
                                                <span style={{marginRight:'17px'}}>占比</span>
                                                <Select defaultValue="%" disabled>
                                                    <Option value="%" disabled>{(this.state.schoolStudentNum && this.state.majorNum && this.state.majorNum !=0 )?Math.floor((this.state.schoolStudentNum/this.state.majorNum)*100):0}%</Option>
                                                </Select>
                                            </div>
                                            <div className='conteTable'>
                                            </div>
                                            <div className={'btn_group'}>
                                                <div className='conteBtnDiv' onClick={()=>this.onHandevid(this.state.targetId1)}>
                                                    <SubmissionSupportingMaterials/><span className='conteBtnDivSpan'>添加佐证材料</span>
                                                </div>
                                                {this.state.total1 === 0?'':<span className={'chakanzzcl'} onClick={()=>this.lookHandevid(this.state.targetId1)}>查看佐证材料></span>}
                                            </div>

                                        </div>
                                        <div className='title_topp'>
                                            <div className="title_top">
                                                <h4>2.3.2 平台实现跨专业服务社会人员及非本校学生的数量占比</h4>
                                            </div>
                                            <div className="conte">
                                                <div className='conteCompPercent'>
                                                    <span>社会人员及非本校学生的总数</span>
                                                    <InputNumber style={{margin:'0 10px'}} min={0} value={this.state.socialNum} onChange={e=>this.setState({socialNum:e})} placeholder={'数值填充'} onBlur={e => this.saveFactor(e.target.value,this.state.socailNumFactorId)}/>
                                                    <span>专业数量</span><Input className='input_bg' value={this.state.majorNum}  onChange={e => this.onChangeFactor(e.target.value)} placeholder={'数值填充'} onBlur={e => this.saveMajorNum2(e.target.value)}/>
                                                    <span style={{marginRight:'17px'}}>占比</span>
                                                    <Select defaultValue="%" disabled>
                                                        <Option value="%" disabled>{(this.state.socialNum && this.state.majorNum && this.state.majorNum !=0 )?Math.floor((this.state.socialNum/this.state.majorNum)*100):0}%</Option>
                                                    </Select>
                                                </div>
                                                <div className='conteTable'>
                                                </div>
                                                <div className={'btn_group'}>
                                                    <div className='conteBtnDiv' onClick={()=>this.onHandevid(this.state.targetId2)}>
                                                        <SubmissionSupportingMaterials/><span className='conteBtnDivSpan'>添加佐证材料</span>
                                                    </div>
                                                    {this.state.total2 === 0?'':<span className={'chakanzzcl'} onClick={()=>this.lookHandevid(this.state.targetId2)}>查看佐证材料></span>}
                                                </div>
                                            </div>

                                        </div>
                                        <div className='title_topp'>
                                            <div className="title_top">
                                                <h4>2.3.3 校本优质虚拟仿真资源库建设</h4>
                                            </div>
                                            <div className="conte">
                                                <span>是否具备独立的虚拟仿真实训教学管理和共享平台</span>
                                                <Radio.Group onChange={this.onChange} value={value}>
                                                    <Radio value={1}>是</Radio>
                                                    <Radio value={0}>否</Radio>
                                                </Radio.Group>
                                                <div className='conteCompPercentt'>
                                                    <span>能支持教师直接编辑虚拟仿真资源，并上传到平台实现校内的虚拟仿真资源共享</span>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="table_bot">
                                            <div className={ value === 0?'conteTable_e conteTable_on':'conteTable_e'}>
                                                {
                                                    value === 0?(
                                                        <div className="taper_cont">
                                                                    <span className="span_time">
                                                                        <TaperedEnd/>
                                                                        <span>上锁</span>
                                                                    </span>
                                                        </div>
                                                    ):""
                                                }
                                                <Table columns={columnsPersent} dataSource={dataSource1}  bordered={true}
                                                       locale={{emptyText:<><div className="Empty-content">
                                                               <div className="text">
                                                                   <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div></>}}
                                                       pagination={
                                                           {
                                                               total: total,
                                                               pageSize: pageSize,
                                                               onChange: this.pageChange,
                                                               onShowSizeChange: this.onShowSizeChange,
                                                               pageSizeOptions: ['10', '20', '30', '40'],
                                                               showSizeChanger: true,
                                                               // position:'bottomRight'
                                                           }
                                                       }
                                                />
                                            </div>
                                        </div>
                                        {
                                            value===0?(
                                                <div className="button_bottom button_bottom_on">
                                                    <div className='conteBtnDivt'>
                                                        <TianJia/><span className='conteBtnDivSpan'>添加</span>
                                                    </div>
                                                    <div className='conteBtnDivt'>
                                                        <ExcelImport/><span className='conteBtnDivSpan'>excel导入</span>
                                                    </div>
                                                    <div className='conteBtnDivt'>
                                                        <Daoru/><span className='conteBtnDivSpan'>下载导入模版</span>
                                                    </div>
                                                </div>
                                            ):(
                                                <div className="button_bottom">
                                                    <div className='conteBtnDivt'  onClick={() =>this.addCertificate(this.state.targetId3)}>
                                                        <TianJia/><span className='conteBtnDivSpan'>添加</span>
                                                    </div>
                                                    <div className='conteBtnDivt'>
                                                        <Input id='importFile' type="file" accept="image"
                                                               onClick={(event)=> { event.target.value = null }}  onChange={(e)=>this.importPlatformApplication(e,this.state.targetId3)}/>
                                                        <ExcelImport/><span className='conteBtnDivSpan'>excel导入</span>
                                                    </div>
                                                    <div className='conteBtnDivt' onClick={() => this.dowLoadFile(this.state.targetMark3,this.state.targetName3)}>
                                                        <Daoru/><span className='conteBtnDivSpan'>下载导入模版</span>
                                                    </div>
                                                </div>
                                            )
                                        }


                                            <div className='title_topp'>
                                                <div className="title_top">
                                                    <h4>2.3.4 支持教师完成混合虚拟仿真精品课程建设的数量</h4>
                                                </div>
                                                <div className="conte">
                                                    <span>教师通过平台建设含虚拟仿真的精品混合课程数量</span><Input disabled className='conteInput' value={dataSource2.length}/>
                                                    <div className='conteTable'>
                                                        <Table columns={columnsPersent} dataSource={dataSource2} bordered={true}
                                                               locale={{emptyText:<><div className="Empty-content">
                                                                       <div className="text">
                                                                           <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                           <span>暂无数据</span>
                                                                       </div>
                                                                   </div></>}}
                                                               pagination={
                                                                   {
                                                                       total: totalone,
                                                                       pageSize: pageSizeone,
                                                                       onChange: this.pageChangeone,
                                                                       onShowSizeChange: this.onShowSizeChangeone,
                                                                       pageSizeOptions: ['10', '20', '30', '40'],
                                                                       showSizeChanger: true,
                                                                       // position:'bottomRight'
                                                                   }
                                                               }
                                                        />
                                                    </div>
                                                    <div className="button_bottom">
                                                        <div className='conteBtnDivv' onClick={() =>this.addCertificate(this.state.targetId4)}>
                                                            <TianJia/><span className='conteBtnDivSpan'>添加</span>
                                                        </div>
                                                        <div className='conteBtnDivv'>
                                                            <Input id='importFile' type="file" accept="image"
                                                                   onClick={(event)=> { event.target.value = null }}  onChange={(e)=>this.importPlatformApplication(e,this.state.targetId4)}/>
                                                            <ExcelImport/><span className='conteBtnDivSpan'>excel导入</span>
                                                        </div>
                                                        <div className='conteBtnDivv' onClick={() => this.dowLoadFile(this.state.targetMark4,this.state.targetName4)}>
                                                            <Daoru/><span className='conteBtnDivSpan'>下载导入模版</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        <div className="bottom_cont">
                                           
                                            <div className={'footer-btn'}>
                                                <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                                <Button type={'primary'} onClick={ () => this.next()}>下一步</Button>
                                            </div>

                                        </div>

                                    </div>


                                </Col>
                            </Row>

                        </div>
                    </div>

                </div>
                {/*添加佐证材料*/}
                <Modal
                    title={'添加佐证材料'}
                    visible={addEvidence}
                    width={800}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    className={'Addevidence'}
                    wrapClassName="Addevidence_warp"
                    footer={
                        <div className='footer-btn'>
                            <Button className={'quxiao'} onClick={()=>this.setState({addEvidence: false,resultData:[]})}>取消</Button>
                            <Button type='primary' onClick={()=>this.submit(this.state.addTargetId)} disabled={this.state.resultData.length>0?false:true}>提交</Button>
                        </div>
                    }
                >
                    {/*<Addevidence onRef={(ref) => this.Addevidence = ref} targetId={this.state.addTargetId}/>*/}
                    <Addevidence getResult={this.getResult} getCheck={this.getCheck} targetId={this.state.addTargetId}/>
                </Modal>
               {/* 添加资源名称*/}
                <Modal
                    title={this.state.isEdit==false?'添加源名称':'编辑源名称'}
                    visible={addPlatforMsgModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                    wrapClassName="wappop_up"
                    className="Adding_resourcetop"
                    width={660}
                    footer={
                        <div className='addModalBtn'>
                            <Button onClick={() => this.setState({addPlatforMsgModal: false})}>取 消</Button>
                            <Button onClick={() => this.handleOk(this.state.addTargetId,false)} type='primary'>提交</Button>
                            <Button onClick={() => this.handleOk(this.state.addTargetId,true)} type='primary'>提交并添加</Button>
                        </div>
                    }
                >
                   <Pesourcename/>
                </Modal>
               {/* 添加专业群*/}
                <Modal
                    title={this.state.isEdit==false?'添加源名称':'编辑源名称'}
                    visible={addCertificateModal}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    width={660}
                    destroyOnClose={true}
                    wrapClassName="wappop_up"
                    className="Adding_resource"
                    footer={
                        <div className='addModalBtn'>
                            <Button onClick={() => this.setState({addCertificateModal: false})}>取 消</Button>
                            <Button onClick={()=>this.handleOk(this.state.addTargetId,false)} type='primary'>提交</Button>
                            <Button onClick={()=>this.handleOk(this.state.addTargetId,true)} type='primary'>提交并添加</Button>
                        </div>
                    }
                >
                    <Form>
                        <Form.Item label="资源名称" >
                            {
                                getFieldDecorator('name', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '平台名称不能为空'
                                        }
                                    ]
                                })(
                                    <Input placeholder='请输入内容' maxLength={30} suffix={<span className='len'>
                                        {this.props.form.getFieldValue(`name`) ? this.props.form.getFieldValue(`name`).length : 0}/30</span>}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item label="创建人" >
                            {
                                getFieldDecorator('objectName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '平台地址不能为空'
                                        }
                                    ]
                                })(
                                    <Input placeholder='请输入内容'  maxLength={30} suffix={<span className='len'>
                                        {this.props.form.getFieldValue(`objectName`) ? this.props.form.getFieldValue(`objectName`).length : 0}/30</span>}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item label="网址链接" >
                            {
                                getFieldDecorator('websiteLink', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '网址链接不能为空'
                                        },
                                        {
                                            validator:this.validatehttp
                                        }
                                    ]
                                })(
                                    <Input placeholder='请输入内容'  maxLength={30} suffix={<span className='len'>
                                        {this.props.form.getFieldValue(`websiteLink`) ? this.props.form.getFieldValue(`websiteLink`).length : 0}/30</span>}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item className="li_formup">
                            <span className={'label'}><span className='red'>*</span>佐证材料</span>
                            {
                                getFieldDecorator('certificate', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '佐证材料不能为空'
                                        },
                                    ]
                                })(
                                    <div className="up_button">
                                        <div className="span_right">
                                            <label htmlFor="file"  className="inputlabelBox">
                                                <span><Upicon/>上传资料</span>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image"
                                                onChange={this.multipartUpload}
                                                name="myfile"
                                                id="file"
                                                style={{ display: "none" }}
                                            />
                                            <span className={'tip'}>
                                                支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                            </span>
                                        </div>
                                        <ul className="bottom_tit">
                                            {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}
                                            {/*上传中*/}
                                            {/*{file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}*/}
                                            {/*已完成*/}
                                            {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                        </ul>
                                    </div>
                                )
                            }
                        </Form.Item>

                    </Form>
                </Modal>
                {/*查看佐证材料*/}
                <Modal
                    title={'查看佐证材料'}
                    visible={LookEvidence}
                    width={800}
                    centered={true}
                    destroyOnClose={true}
                    className={'Addevidence'}
                    wrapClassName="Addevidence_warp"
                    onCancel={this.handleCancel}
                    footer={
                        <div className='footer-btn' style={{display:'none'}}>
                            <Button className={'quxiao'} onClick={()=>this.setState({LookEvidence: false})}>取消</Button>
                            <Button type='primary' onClick={()=>this.cailiaoTijiao()}>提交</Button>
                        </div>
                    }
                >
                    <Addevidencelook selectAllPlatformApplication={this.selectAllPlatformApplication} targetId={this.state.addTargetId}/>
                </Modal>
                {/*二次确认*/}
                <Modal
                    title={'温馨提示'}
                    visible={isindependent}
                    width={430}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    className={'wenxintishi'}
                    footer={
                        <div className='footer-btn'>
                            <Button className={'quxiao'} onClick={()=>this.setState({isindependent: false,value: 1})}>取消</Button>
                            <Button type='primary' onClick={this.submitIndependent}>确定</Button>
                        </div>
                    }
                >
                    <div className={'tipwrap'}>
                        <WenxinTip/><span>选择否，将清空当前已填写的数据，请确认是否继续？</span>
                    </div>
                </Modal>
                {/*查看资料*/}
                <Drawer
                    title={'查看资料'}
                    visible={examine}
                    onOk={this.handExamine}
                    onClose={this.onCancel}
                    destroyOnClose={true}
                    closable={true}
                    maskClosable={true}
                    wrapClassName="wappop_up lookMaterial"
                    footer={
                        <div className='addModalBtn' style={{display:'none'}}>
                            <Button className={'quxiao'} onClick={() => this.setState({examine: false})}>取 消</Button>
                            <Button type='primary'>确定</Button>
                        </div>
                    }
                >
                    <LookCheck id={evidenceId}/>
                </Drawer>
                <Footer/>
            </div>
        )
    }
}

ApplicationPlatform = Form.create()(ApplicationPlatform);
export default ApplicationPlatform;