import React, {Component} from 'react';
import {Card, Row, Col, Button, Form, Input, Select, Checkbox, message} from 'antd';
import './index.scss';
import Header from '../../components/header'
import Footer from '../../components/footer'
import StepTop from '../components/StepTop'
import moment from "moment";
import Cookies from "js-cookie";
import {completeFill} from "../../api/process";


const {Search} = Input;
const {TextArea} = Input;
const monthFormat = 'YYYY-MM-DD';
const {Option} = Select;

function disabledDate(current) {
    return current && current > moment().endOf('day');
}

class contentValidation extends React.Component {
    state = {
        proTextValue: '',
        hostTextValue: '',
        taskBookList: '',
        proTextLength: '',
        organizerTextLength: '',
        schoolId: '',
        userId: '',
        category: '',
        chinaAllProvinceList: [{
            label: '1',
            value: '1'
        }],
        fillingDate: "",
        isChange: true,
        secondCode: '',
        sysType: '',
        isCheck: false,
        userInfo: Cookies.get("schoolInfo") ? JSON.parse(decodeURI(Cookies.get("schoolInfo"))) : {},
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        currentData:moment().format('YYYY年MM月DD日')
    };

    componentDidMount() {

    }

    onChange = (e) => {
        this.setState({isCheck: e.target.checked})
    }

    //  上一步
    goBack = () => {
        this.props.history.goBack()
    };
    // 完成填报
    complete = () => {


        const {taskId, isCheck} = this.state

        if (!isCheck) {
            message.error("请您勾选上述承诺")
            return
        }
        let data = {
            isPromise: 1,
            processCode: "5",
            taskId: taskId
        }
        completeFill(data).then((res) => {
            if (res) {
                message.success("填报成功")
                this.props.history.replace("/mission_statement")
            }

        });

    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {
            proTextValue,
            hostTextValue,
            proTextLength,
            organizerTextLength,
            chinaAllProvinceList,
            fillingDate, currentData
        } = this.state;
        return (
            <div className="contentValidation">
                {/*顶部star*/}
                <Header/>
                <div className="Task-top main_cont">
                    <div className="main-cont">
                        <Card>
                            <Row>
                                <Col span={24} className="col-li">
                                    <StepTop setCode={this.setCode}/>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
                <div className="Mission-bottom clearfix main_cont">
                    <div className="main-cont Mission">
                        <div className="Mission-pic">

                            <div className="content-main">
                                <h4>内容真实性责任承诺书</h4>
                                <h3><span>{this.state.userInfo.schoolName}</span>对本校职业教育示范性虚拟仿真实训基地建设项目所填报的《建设方案》《任务书》及相关佐证材料等内容的真实性承担一切责任，并对因数据虚假引发的一系列后果承担全部责任，特此承诺。
                                </h3>
                                <div className='checkbox'>
                                    <Checkbox onChange={this.onChange}> <span>我代表学校做出上述承诺</span></Checkbox>
                                </div>

                                 <div className="right"><span>{currentData}</span></div>

                            </div>

                            <div className="but-tj">
                                <Button className="but" onClick={this.goBack}>上一步</Button>
                                <Button type="primary" onClick={this.complete} className="but">完成填报</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*顶部star*/}
                <Footer/>
            </div>
        );
    }
}

contentValidation = Form.create()(contentValidation);
export default contentValidation;
