// 效能填报
import React from "react";
import {Button, Col, DatePicker, Form, message, Radio, Row, Table} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import {withRouter} from "react-router-dom";
import {selectRegion} from '../../api/index';
import {getTargetTimes, modifyReportingTime, targetManage} from '../../api/target';
import Cookies from "js-cookie";
import RenderButton from '../../components/renderButton/index'
import moment from "moment";
import SelectItemView from "../index/component/select_item_view";
import 'moment/locale/zh-cn';
import {getRoleType, RoleType} from "../../until/cookie";

const {RangePicker} = DatePicker;
// 绩效填报管理页
const monthFormat = 'YYYY-MM-DD';

function disabledDate(current) {
    return current && current > moment().endOf('day');
}

export default Form.create()(
    withRouter(
        class EffectivenessAllowed extends React.Component {
            rangeRef = React.createRef()
            // section State
            state = {
                pageNum: 1,//页数
                pageSize: 10,//条数
                total: 0,
                targetManageTable: [],
                provinceList: [],
                isReporting: null,
                category: null,
                isFinalCheck: null,
                provinceCode: null,
                fillingDate: "",
                dateDisable: true,
                timesInfo: {
                    beginTime: '',
                    endTime: ''
                },
            };

            componentDidMount() {
                this.targetManage();
                this.selectRegion();
                this.updateTime()
            }

            async selectRegion() {
                const region = await selectRegion();
                if (region) {
                    region.unshift({code: null, name: '全部'})
                }
                this.setState({
                    provinceList: region
                });
            }

            get getBeginTime() {
                const beginTime = this.state.timesInfo.beginTime
                return beginTime ? moment(beginTime) : null
            }

            get getEndTime() {
                const endTime = this.state.timesInfo.endTime
                return endTime ? moment(endTime) : null
            }

            get getDates() {
                return [this.getBeginTime, this.getEndTime]
            }

            updateTime = () => {
                getTargetTimes(1).then(res => {
                    this.setState({
                        timesInfo: res,
                    })
                })
            }

            async targetManage() {
                let {pageNum, pageSize, isReporting, writeStatus, isFinalCheck, provinceCode} = this.state;
                const params = {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    isReporting: isReporting,
                    writeStatus: writeStatus,
                    isFinalCheck: isFinalCheck,
                    provinceCode: provinceCode
                };
                const page = await targetManage(params);

                this.setState({
                    targetManageTable: page?.data,
                    total: page?.total
                });
            }

            componentWillUnmount() {
                clearInterval(this.timer);
            }

            writeStatusChange = e => {
                this.setState({
                    pageNum: 1,
                    writeStatus: e.target.value,
                }, () => {
                    this.targetManage();
                })
            }
            isFinalCheckChange = e => {
                this.setState({
                    pageNum: 1,
                    isFinalCheck: e.target.value,
                }, () => {
                    this.targetManage();
                })
            }
            provinceChange = e => {
                this.setState({
                    pageNum: 1,
                    provinceCode: e.target.value,
                }, () => {
                    this.targetManage();
                })
            }
            //分页切换
            pageChange = (pageNum) => {
                this.setState({pageNum}, () => this.targetManage());
            }
            //分页条数切换
            onShowSizeChange = (pageNum, pageSize) => {
                this.setState({pageSize}, () => this.targetManage());
            }
            writeTarget = (record) => {
                console.log('record', record)
                let schoolInfo = {
                    taskId: record.taskId,
                    schoolEduCode: record.schoolEduCode,
                    schoolName: record.schoolName,
                    baseName: record.baseName,
                    provinceCode: record.provinceCode,
                    category: record.category,
                    writeYear: record.writeYear
                }
                Cookies.set('schoolInfo', schoolInfo);
                this.props.history.push('/money_Perform')
            }

            editDate = () => {

                const currentValue = this.state.dateDisable
                const beginTime = this.state.timesInfo.beginTime
                if (currentValue === false && beginTime && this.state.timesInfo.id) {
                    modifyReportingTime(this.getBeginTime, this.getEndTime, this.state.timesInfo.id).then(res => {
                        message.success('修改成功');
                    });
                }
                this.setState({
                    dateDisable: !currentValue
                });
            }
            onDateChange = (dates, dateStrings) => {
                const beginTime = dates[0]
                const endTime = dates[1]
                const id = this.state.timesInfo.id
                this.setState({
                    timesInfo: {
                        beginTime: beginTime,
                        endTime: endTime,
                        id
                    },
                })
            };


            // section Render
            render() {
                const {getFieldDecorator} = this.props.form
                const {targetManageTable, total, pageSize, provinceList, fillingDate} = this.state;
                const columns = [
                    {
                        title: '序号',
                        dataIndex: 'key',
                        key: 'key',
                        align: 'center',
                        width: 60,
                        fixed: 'left',
                        render: (text, record, index) => `${index + 1}`
                    },
                    {
                        title: '学校名称',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        width: 180,
                        fixed: 'left',
                    },
                    {
                        // todo 少字段?
                        title: '所在省份',
                        dataIndex: 'province',
                        key: 'province',
                        width: 210,
                        render: (e) => e ? <span>{e}</span> : <span>--</span>
                    },
                    {
                        title: '项目名称',
                        // todo 用什么字段
                        dataIndex: 'writeYear',
                        key: 'writeYear',
                        width: 210,
                    },
                    {
                        title: '效能填报状态',
                        dataIndex: 'writeStatus',
                        key: 'writeStatus',
                        width: 210,
                        render: (e) => <div>{e === 1 ? "已完成" : "未完成"}</div>
                    },
                    {
                        title: '填报完成时间',
                        dataIndex: 'writeTime',
                        key: 'writeTime',
                        width: 210,
                        render: (e) => e ? <div>{moment(e).format('YYYY-MM-DD HH:mm:ss')}</div> : <span>--</span>
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        fixed: 'right',
                        width: 300,
                        render: (text, record) => (
                            <div className='operating'>
                                <div className="li fin-list">
                                    <RenderButton id='VB-01-A02-a_btnWriteTarget'>
                                        <Button onClick={() => this.writeTarget(record)}><span>查看</span></Button>
                                    </RenderButton>
                                </div>
                            </div>
                        )
                    },
                ]
                console.log(moment(moment(this.state.timesInfo.beginTime).format(monthFormat), monthFormat))
                console.log(moment(moment(this.state.timesInfo.beginTime).format(monthFormat), monthFormat))
                console.log(moment(moment(this.state.timesInfo.beginTime).format(monthFormat), monthFormat))
                console.log(moment(moment(this.state.timesInfo.beginTime).format(monthFormat), monthFormat))
                console.log(moment(moment(this.state.timesInfo.beginTime).format(monthFormat), monthFormat))
                console.log(moment(this.state.timesInfo.endTime).format(monthFormat))
                console.log(moment(this.state.timesInfo.endTime).format(monthFormat))
                console.log(moment(this.state.timesInfo.endTime).format(monthFormat))
                console.log(moment(this.state.timesInfo.endTime).format(monthFormat))
                return (
                    <div className="EffectivenessAllowed_cont">
                        <Heder navName={'effectiveness_Allowed'}/>
                        {getRoleType() === RoleType.manager ? (<Row className="date_header main_cont">
                            <span className="date_title">效能填报开放时间</span>
                            <RangePicker ref={this.rangeRef} className="date_picker"
                                         format={monthFormat}
                                         disabled={this.state.dateDisable}
                                         onChange={this.onDateChange}
                                         value={this.getDates}
                            />
                            <Button onClick={this.editDate}
                                    className="antd-button">{this.state.dateDisable === true ? "编辑" : "完成"}</Button>

                        </Row>) : ""}

                        <div className="Management_table main_cont">
                            <Row className="IndexHome-top">
                                <SelectItemView title="效能填报状态" left="未完成" right="已完成"
                                                onChange={this.writeStatusChange}/>
                                <Col span={24} className="li-col">
                                    <span className="span-left">院校所在省份</span>
                                    <Radio.Group onChange={this.provinceChange} className="nav-tab" defaultValue={null}>
                                        {
                                            provinceList.map((item, index) => {
                                                return <Radio.Button key={index}
                                                                     value={item.code}>{item.name}</Radio.Button>
                                            })
                                        }
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <div className="home-list">
                                <div className="top-title">
                                    <h3><span>共{total}所学校</span></h3>
                                </div>
                                <div className="bottom-table">
                                    <Table columns={columns} dataSource={targetManageTable} scroll={{x: 1600}}
                                           locale={{
                                               emptyText: <>
                                                   <div className="Empty-content">
                                                       <div className="text">
                                                           <img
                                                               src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                           <span>暂无数据</span>
                                                       </div>
                                                   </div>
                                               </>
                                           }}
                                           pagination={
                                               {
                                                   total: total,
                                                   pageSize: pageSize,
                                                   onChange: this.pageChange,
                                                   onShowSizeChange: this.onShowSizeChange,
                                                   pageSizeOptions: ['10', '20', '30', '40'],
                                                   showSizeChanger: true,
                                               }
                                           }
                                    />
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
