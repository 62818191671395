// 预期成效和保障-特色创新
import React from "react";
import { Form,Row, Col,Input,Card,Button,Radio,message } from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import StepTop from "../components/StepTop";
import Leftmien from "../components/Left_mien";
import FormComponent from "../components/addForm";
import {AddIcon} from '@/components/iconSvg'
import { withRouter } from "react-router-dom";
import {getEffectReview,saveEffectReview,updateEffectReview,saveEffectReviewDetail,updateEffectReviewDetail,deleteEffectReviewDetail} from "../../api/effect"
import Cookies from "js-cookie";
const {TextArea} = Input;


export default Form.create()(
  withRouter(
    class FeaturesInnovation extends React.Component {
      state = {
          itemNum: 0,
          itemNums: 0,
          formObj: [
                  {
                      "formItem": '0',
                  },
                  {
                      "formItem": '1',
                  }
              ],
          schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
          upseniorId:Cookies.get('processCode') ? Cookies.get('processCode'): '',
      };

      componentDidMount() {
           window.scrollTo({
               top: 0,
               behavior: 'smooth',
           });
           window.addEventListener('scroll', this.bindHandleScroll)
       }
       componentWillUnmount() {

       }


       //保存特色创新综述
        saveEffectReview =()=>{
          console.log('333')
            this.props.form.validateFields((err, value) => {
                console.log('value', value)
                if(!err){
                    let data = {
                        taskId: this.state.schoolInfo.taskId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        seniorId: this.state.upseniorId,
                        overview: value.overview
                    }
                    saveEffectReview(data).then(res =>{
                        if(res){
                            message.success("操作成功")
                        }
                    })
                }

            })
        }

        // 重置特色创新综述
        resetEffect =()=>{
            this.props.form.resetFields();
        }




        bindHandleScroll=(event)=> {
            let ctx=this;
            let clientHeight = document.documentElement.clientHeight; //可视区域高度
            let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
            if (scrollTop > 200) {
                document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight-240}px`);
            } else {
                document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight-240}px`);
            }
        }
        //新增
        addFormItem=()=> {
            const {formObj,itemNum,itemNums} = this.state
            formObj.push(
                {
                    "name": itemNums + 1,
                    "assignmentName":'详细说明',
                })
            this.setState({
                formObj: formObj,
                itemNums: itemNums + 1,
            })

        }
      render() {
          const {getFieldDecorator,formObj }=this.props.form
          const { } = this.state;
          const  formArr = formObj ? formObj.map((item,index)=>{
              return(
                  <FormComponent formData={item}  name={item.formItem} delFormItem={this.delFormItem}  key={item.formItem} num={index} wrappedComponentRef={(form) => this['formAssignment'+item.formItem] = form}/>
              )
          }) : ''
        return (
            <div className="CharacteristicCreation_main">
                <Heder/>
                <div className="Task-top">
                    <div className="main_cont">
                        <Card>
                            <Row>
                                <Col span={24} className="col-li">
                                    <StepTop />
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
                <div className="Task-bottom clearfix">
                    <div className="main_cont">
                        <div className="Task-bottom-main">
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="Task_left" id="left-top" >
                                    <div className="Double-left-table">
                                        <Leftmien/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right" >
                                    <div className="Characteristic_cont">
                                        <Form>
                                            <div className="form_top">
                                                <Form.Item label={`特色创新综述`} onFocus={this.doFocus} onBlur={this.doBlur}>
                                                    {getFieldDecorator('overview', {
                                                        initialValue:'' ,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请填写特色创新综述`,
                                                            },
                                                            {
                                                                min:2,
                                                                message: "文本最少2字"
                                                            },
                                                            {
                                                                max:1000,
                                                                message: "文本最多1000字"
                                                            },
                                                        ],
                                                    })(<TextArea className="TextArea-pic" rows={3} />)}
                                                    <p className="font-num">{this.props.form.getFieldValue(`overview`)?this.props.form.getFieldValue(`overview`).length:0}/1000</p>
                                                </Form.Item>
                                                <div className="bottom-but">
                                                    <Button className="but-li"
                                                            htmlType="submit" onClick={this.resetEffect}>
                                                        重置
                                                    </Button>
                                                    <Button className="but-li"
                                                            type="primary"
                                                            htmlType="submit" onClick={() =>this.saveEffectReview()}>
                                                        保存
                                                    </Button>
                                                </div>
                                            </div>
                                            <div className="form_bottom">
                                                <h3>可列举项目在与行业、企业合作共建共享，推动教学形式创新、技术创新、组织模式创新的关键特色或创新点</h3>
                                                <div className="form_con">
                                                    <FormComponent/>
                                                    {formArr}
                                                </div>
                                                <div className="bottom_more">
                                                    <span className="more_open" onClick={this.addFormItem}><AddIcon/>添加特色创新</span>
                                                </div>
                                            </div>
                                        </Form>
                                        <div className="bottom_antd">
                                            <Button className="but-li" type="primary"
                                                    htmlType="submit">
                                                上一步
                                            </Button>
                                            <Button className="but-li"
                                                    htmlType="submit">
                                                下一步
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
      }
    }
  )
);
