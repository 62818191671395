// 添加附件
import React from "react";
import {Form, message, Table, Button, Modal, Checkbox, Drawer, Tooltip, Spin} from 'antd';
import OSS from "ali-oss";
import {getOssToken} from '@/api/ali';//getbase64Img
import moment from "moment";
import { uploadFile } from '../../../api/uploadFile';
import {
    addEvidence
} from '../../../api/platformAbility';
import {Mimacz, Querwc, Upicondele, Upiconwc} from "../../../components/iconSvg";
import {deleteApply} from "../../../api/platformAbility";
import Cookies from "js-cookie";
import LookCheck from "../../components/look_Check";
import "./index.scss";
import FileLook from "../../components/file_look";
import { BaseUrl } from "../../../config/common";
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
class AddAttachment extends React.Component {
    state = {
        examine: false,
        schoolEduCode:JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        pageNum:1,//页数
        pageSize:10,//条数
        total:0,
        tableData:[],
        cailiaoType:''
    };

    componentDidMount() {
        let {studentNumInfoList,studentNumInfo} = this.props
        console.log('studentNumInfoList', studentNumInfoList)
        this.setState({
            studentNumInfo: studentNumInfo
        }, () =>{
            this.getOssToken();
            this.props.onRef(this)
        })
    }

    getState =()=>{
        return this.state.tableData
    }

     // 查看资料
     checkFile = (record) => {
        const url=BaseUrl+record.fileId
        this.props.history.push({pathname:"/file_Book", query :{ openUrl: url}} )
    }
       /**
             *  隐藏预览的回调
            */
           dismiss = () =>{
            this.setState({
                isShow:false
            })
        }

    //提交材料
    addData = () => {
        const {materialTargetId} = this.props
        const {tableData,schoolEduCode} = this.state
        let data = {
            list: tableData,
            schoolEduCode: schoolEduCode,
            targetId: materialTargetId
        }
        addEvidence(data).then(e=>{
            if (e){
                message.success("保存成功")
            }else {
            }
        })
    }

    async getOssToken() {
        let res = await getOssToken();
        // console.log(res)
        if (res) {
            let client = new OSS({
                region: "oss-cn-beijing",
                accessKeyId: res.accessKeyId,
                accessKeySecret: res.keySecret,
                stsToken: res.token,
                bucket: res.bucket,
                // endpoint:'oss-cn-beijing.aliyuncs.com',
                secure: true,
            });
            this.client = client;
        }
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        const {tableData,fileNumber} = this.state;
        if ((tableData.length+list.length+fileNumber) > 10){
            message.error("资源数量不能超过10")
            return;
        }
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        this.setState({
            file:list[0]
        })
        let flag = false;
        list.forEach((k) => {
            console.log("k.size",k.size)
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
                return;
            }
            if(k.size/1048576 > 5){
                this.mesWarning('资源大小不能超过5MB')
                document.getElementById("file").value = '';
                flag = true
                return;
            }
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            let fileId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            fileId = `zjyd-space-front-geren/${ymd}/${fileId}`
            k["fileId"] = fileId
            // if (!tableData.find((v) => v.resourceName === k.name)) {
            //     tableData.push({
            //         fileId: fileId,
            //         resourceName: k.name,
            //         resourceFormat: fileType,
            //         resourceSize: (k.size / 1024).toFixed(2),
            //         operationTime: transTime(k.lastModifiedDate),
            //         createTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            //         // plan: 0,
            //         lastModified:k.lastModified
            //     });
            // }
        });
        if (flag){
            return;
        }
        this.setState({
            tableData: tableData,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
        this.setState({
            inputValue:""
        })
    };
    //上传文件
    uploadFile = async (v) => {
        const {fileType} = this.state
        if (!v) return;
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    // console.log('res----------------', res)
                    this.clearFile(v);
                    this.getResult(res);
                    reslove(1);
                }
            })
        });
    };
    //上传完成获取返回结果过
    getResult = (val) => {
        console.log('res------------',val)
        let {tableData} = this.state;
        // console.log("tableData",tableData)
        // tableData.forEach(item => {
        //     if(item.originName === val.originFileName){
        //         item.originName = val.originFileName
        //         item.fileId = val.fpName
        //         item.fileSuffix = val.mediaType
        //         item.coursewareId = val.id
        //         item.fileSize = (val.fileSize / 1024).toFixed(2)
        //     }
        // })
        tableData.push({
            fileId: val.fpName,
            resourceName: val.originFileName,
            resourceSize: val.fileSize,
            resourceSuffix: val.mediaType
        })
        this.setState({tableData}, () => {
            console.log('上传后的数据：', this.state.tableData)
        })
    };
    //上传完成
    clearFile = (file) => {
        if (!file) return;
        //小于100kb的文件不支持分片上传，等返回上传成功结果后直接调整进度未100%
        let {tableData} = this.state;
        // console.log(tableData);
        // console.log("888888*********")
        let item = tableData.find((v) => v.originName === file.name);
        // item.plan = 1;
        this.setState({tableData});
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        delete opt[file.name];
        localStorage.setItem("uploadList", JSON.stringify(opt));
        document.getElementById('file').value = null;
        // console.log("上传完成");
        console.log("tableData", this.state.tableData)
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc/docx、xls/xlsx、ppt/pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //材料类型切换
    onChange = (e) =>{
        this.setState({cailiaoType: 1})
    }
    // 内容类型切换
    changeType = (e) =>{
        this.setState({neirongType: e.target.value})
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize });
    }
    //删除
    deleteItem = (record) => {
        // console.log("record",record)
        let { tableData } = this.state;
        let index = tableData.findIndex(
            (val) => val.fileId === record.fileId
        );
        tableData.splice(index, 1);
        this.setState({ tableData },()=>{
            console.log('tableData1',this.state.tableData)
        });
    };
   
    cailiaoType =()=>{
        return this.state.cailiaoType
    }
    onCancel = () =>{
        this.setState({
            examine:false
        })
    }
    render() {
        const {getFieldDecorator }=this.props.form
        const { file,tableData,total,pageSize,examine,resourceId,isShow,
            openUrl} = this.state
        const columns = [
            {
                title: '材料名称',
                dataIndex: 'resourceName',
                key: 'resourceName',
                width: 200,
            },
            {
                title: '格式',
                dataIndex: 'resourceSuffix',
                key: 'resourceSuffix',
                width: 100,
            },
            {
                title: '大小',
                dataIndex: 'resourceSize',
                key: 'resourceSize',
                width: 100,
                render(text,record){
                    return (
                        <div>
                            {text>=1048576?(text / 1048576).toFixed(2) + "MB":(text / 1024).toFixed(2) + "K"}
                        </div>
                    )
                }
            },
            {
                title: '操作',
                key: 'operation',
                width: 150,
                render:(text, record) => (
                    <div className='operating'>
                        <div className="li">
                            <span onClick={() => this.deleteItem(record)}>删除</span>
                        </div>
                    </div>
                )
            },
        ]
        return (
            <div className="Addevidence_cont" >
                <Form>
                    <div className="title_top">
                        <Form.Item>
                            {getFieldDecorator('cailiao', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: false,
                                        message: `请选择支撑材料`,
                                    },
                                ],
                            })(
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    {/*<Button className='MaBtn'>查看</Button>*/}
                                    {/*<Button className='MaBtn'>删除</Button>*/}
                                </div>
                            )}
                            <span className={'tip'}>
                                支持：pdf、doc/docx、xls/xlsx、ppt/pptx、txt、jpg、jpeg、png、bmp、gif格式
                            </span>
                        </Form.Item>
                    </div>
                    <span className="span">文件目录（{tableData.length}/10）本地上传时文件大小不超过5MB</span>
                    <div className="table_bot">
                        <Table columns={columns} dataSource={tableData} pagination = {false}
                               locale={{emptyText:<><div className="Empty-content">
                                       <div className="text">
                                           <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div></>}}
                               // pagination={
                               //     {
                               //         total: total,
                               //         pageSize: pageSize,
                               //         onChange: this.pageChange,
                               //         onShowSizeChange: this.onShowSizeChange,
                               //         pageSizeOptions: ['10', '20', '30', '40'],
                               //         showSizeChanger: true,
                               //     }
                               // }
                        />
                    </div>
                    <div className="Checkbox_bottom">
                        <Checkbox onChange={this.onChange}>我承诺上传的资料真实可信</Checkbox>
                    </div>
                </Form>
                {/*查看资料*/}
                <FileLook  openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
            </div>
        );
    }
}
AddAttachment = Form.create()(AddAttachment);
export default AddAttachment;
