// 查看佐证材料
import React from "react";
import {DatePicker, Form, Input, message, Radio, Table, Modal, Button, Drawer} from 'antd';
import "./index.scss";
import LookCheck from "../../../components/look_Check";
import moment from "moment";
import {selectEvidenceByTargetId, deleteEvidence, deleteApplyResources} from "../../../../api/target";
import {WenxinTip} from "../../../../components/iconSvg";
import {getNum} from "../../../../api/courseSharing";
import Cookies from "js-cookie";
import {BaseUrl} from "../../../../config/common";
import FileLook from "../../../components/file_look";

class Lookevidence extends React.Component {
    state = {
        pageNum: 1,//页数
        pageSize: 10,//条数
        total: 0,
        taskBookTable: [],
        examine: false,
        resourceId: '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    };

    componentDidMount() {
        let {showList, columnsDData} = this.props
        console.log('showList', showList)
        this.setState({
            taskBookTable: showList,
            columnsDData: columnsDData
        }, () => {
        })
    }

    getNum = () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsDData.id,
        }
        getNum(data).then(res => {
            console.log('2020 getNum ', res)
            if (res) {
                this.setState({
                    taskBookTable: res.resourceVoList.data
                }, () => {
                    this.props.getNum()
                })
            }
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum});
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageSize});
    }
    delete = (e) => {
        console.log('2020 e', e)
        let data = {
            id: e.resourceId,
            targetId: this.state.columnsDData.id
        }
        deleteEvidence(data).then(e => {
            message.success("删除成功！")
            this.getNum()
        })
    }
    onCancel = () => {
        this.setState({
            examine: false
        })
    }
    // 查看资料
    see = (record) => {
        const url = BaseUrl + record.fileId
        this.setState({
            openUrl: url,
            isShow: true
        })
    }
    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {
            taskBookTable, total, pageSize, examine, resourceId, isShow,
            openUrl
        } = this.state
        console.log('渲染的：：', taskBookTable)
        const columns = [
            {
                title: '材料名称',
                dataIndex: 'resourceName',
                key: 'resourceName',
                width: 200,
            },
            {
                title: '格式',
                dataIndex: 'resourceSuffix',
                key: 'resourceSuffix',
                width: 100,
            },
            {
                title: '大小',
                dataIndex: 'resourceSize',
                key: 'resourceSize',
                width: 100,
                render: (e) => <div>
                    {e >= 1048576 ? (e / 1048576).toFixed(2) + "MB" : (e / 1024).toFixed(2) + "K"}
                </div>
            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 100,
                render: (e) => <div>
                    {moment(e).format('YYYY-MM-DD HH:mm')}
                </div>
            },
            {
                title: '操作',
                key: 'operation',
                width: 150,
                render: (text, record) => (
                    <div className='operating'>
                        <div className="li">
                            <span onClick={() => this.see(record)}>查看</span>
                            {this.props.editEnable ? <span onClick={() => this.delete(record)}>删除</span> : ""}
                        </div>
                    </div>
                )
            },
        ]
        return (
            <div className="Addevidence_cont">
                <span className="span" style={{paddingTop: '0'}}>共计 {taskBookTable.length}个</span>
                <div className="table_bot">
                    <Table columns={columns} dataSource={taskBookTable} pagination={false}
                           locale={{
                               emptyText: <>
                                   <div className="Empty-content">
                                       <div className="text">
                                           <img
                                               src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div>
                               </>
                           }}
                        // pagination={
                        //     {
                        //         total: total,
                        //         pageSize: pageSize,
                        //         onChange: this.pageChange,
                        //         onShowSizeChange: this.onShowSizeChange,
                        //         pageSizeOptions: ['10', '20', '30', '40'],
                        //         showSizeChanger: true,
                        //     }
                        // }
                    />
                </div>
                {/*查看资料*/}
                <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
            </div>

        );
    }
}

Lookevidence = Form.create()(Lookevidence);
Lookevidence.defaultProps = {
    // 避免在其他地方使用的时候影响到,这里默认值给true
    editEnable: true
}
export default Lookevidence;
