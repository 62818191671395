import React, {Component} from 'react'
import {Input, Select, Form, Cascader, Radio, Tooltip, Spin, message} from "antd";
import "./index.scss";
import {Upicon, Upicondele, Upiconwc} from "../iconSvg";
import {uploadFile} from "../../api/uploadFile";

const {TextArea} = Input;


class AddConstruction extends React.Component {
    // section State
    state = {
        file: null
        // majorList: this.props.majorList,
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 5242880) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        id: res.id,
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        url: res.url,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };

    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    componentDidMount() {
        let {record} = this.props;
        console.log(record);
        if (record) {
            this.props.form.setFieldsValue({
                ...record
            });
        } else {
            this.props.form.resetFields()
        }

    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log('专业的 nextProps：：：', nextProps.data)
    //     if(nextProps.data !== this.state.data){
    //         let data = nextProps.data
    //         let courseMajor = []
    //         courseMajor.push(data.majorOne)
    //         courseMajor.push(data.majorTwo)
    //         courseMajor.push(data.courseMajor)
    //         this.setState({
    //             data: nextProps.data
    //         }, () =>{
    //             this.props.form.setFieldsValue({
    //                 majorName: courseMajor,
    //                 majorCode: data.majorCode,
    //             })
    //         })
    //     }
    // }


    handleCancel = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {file} = this.state
        return (
            <>
                <Form>
                    <Form.Item label="实训中痛点难点描述" className="li_fon">
                        {
                            getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message: '实训中痛点难点描述'
                                    },
                                ]
                            })(
                                // <Cascader options={majorList}/>
                                <TextArea maxLength={100} style={{height: '150px'}}/>
                            )
                        }
                         <p className="font-num">{this.props.form.getFieldValue(`description`)?this.props.form.getFieldValue(`description`).length:0}/100</p>
                    </Form.Item>
                    <Form.Item className="li_fo">
                        <span className={'label'}><span className='red'>*</span>是否通过虚拟仿真实训有效解决：</span>
                        {getFieldDecorator('hasSolve', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否完成'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item label="解决措施说明" className="li_focshi">
                        {
                            getFieldDecorator('measures', {
                                rules: [
                                    {
                                        required: true,
                                        message: '解决措施说明'
                                    },
                                ]
                            })(
                                // <Cascader options={majorList}/>
                                <TextArea maxLength={100} style={{height: '150px'}}/>
                            )
                        }
                         <p className="font-num">{this.props.form.getFieldValue(`measures`)?this.props.form.getFieldValue(`measures`).length:0}/100</p>
                    </Form.Item>


                </Form>
            </>
        )
    }
}


AddConstruction = Form.create()(AddConstruction);
AddConstruction.defaultProps = {
    record: null
}
export default AddConstruction;
