// 项目情况-平台情况
import React from "react";
import {Form, Row, Col, Card, Button, Input, Table, Modal, Radio, Select, message, Drawer} from 'antd';
import Heder from "../../components/header";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import Addevidence from "./components/Add_evidence/index";
import Addevidencelook from "./components/Add_evidence/look_evidence";
import md5 from "js-md5";
import axios from 'axios';
import {downExcelTemplateUrl} from '../../api/sharing';
import {
    AddSupportingMaterial,
    AddApplication,
    BulkPort,
    WenxinTip,
    TaperedEnd,
    Daoru,
    Formdr,
    ExcelImport
} from '../../components/iconSvg'
import {
    getAbility,
    updateCondition,
    addEvidence,
    addApply,
    updateApply,
    deleteApply,
    getApplies,
    getEvidences,
    importApply,
    updateApplyFactor
} from '../../api/platformAbility';
import AddZzcl from './components/add_zzcl'
import Cookies from "js-cookie";
import "./index.scss";
import {importData, updateInterflowData, updateInterflowPlatformFactor} from "../../api/platformEach";
import Footer from "../../components/footer";
import LookCheck from "../components/look_Check";
import {getRoleType, RoleType} from "../../until/cookie";

const {TextArea} = Input;
let that;

class PlatformAbility extends React.Component {
    state = {
        mediaFlagMark: '',
        mediaFlag131: true,
        mediaFlag132: true,
        mediaFlag133: true,
        mediaFlag135: true,
        resourceId: '',
        examine: false,
        editAppFlag: false,
        isindependent: false,   //二次确认平台是否支持线上、线下和虚拟仿真实训混合课程的建设与教学应用
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        targetId: Cookies.get("twoLevelTargetId") ? Cookies.get("twoLevelTargetId") : '',
        dataSource1: [],
        showfzclModel1: false,//添加应用
        addEvidence: false,//添加佐证材料
        LookEvidence: false,//查看佐证材料
        pageNum: 1,//页数
        pageSize: 10,//条数
        total: 0,
        yingyong: null

    };

    componentDidMount() {
        that = this
        //获取平台能力信息
        this.getAbility()
    }

    //获取平台能力信息
    getAbility = () => {
        const {targetId} = this.state
        let data = {
            id: targetId
        }
        getAbility(data).then(e => {
            if (e) {
                this.setState({
                    indicatorVo131: e.indicatorVo131 ? e.indicatorVo131 : '',
                    indicatorVo132: e.indicatorVo132 ? e.indicatorVo132 : '',
                    indicatorVo133: e.indicatorVo133 ? e.indicatorVo133 : '',
                    indicatorVo134: e.indicatorVo134 ? e.indicatorVo134 : '',
                    indicatorVo135: e.indicatorVo135 ? e.indicatorVo135 : '',
                    tiaojian1: e.indicatorVo131 ? e.indicatorVo131.list[0].confirmStatus : null,
                    tiaojian2: e.indicatorVo131 ? e.indicatorVo131.list[1].confirmStatus : null,
                    tiaojian3: e.indicatorVo131 ? e.indicatorVo131.list[2].confirmStatus : null,
                    guifan: e.indicatorVo132 ? e.indicatorVo132.list[0].confirmStatus : null,
                    pingjia: e.indicatorVo133 ? e.indicatorVo133.list[0].confirmStatus : null,
                    yingyong: e.indicatorVo134 ? e.indicatorVo134.conditionVo.confirmStatus : null,
                    xuqiu: e.indicatorVo135 ? e.indicatorVo135.list[0].confirmStatus : null,
                    dataSource1: e.indicatorVo134.list ? e.indicatorVo134.list.data : [],
                    total: e.indicatorVo134.list ? e.indicatorVo134.list.total : 0,
                    targetValue: e.indicatorVo134 ? e.indicatorVo134.conditionVo.confirmStatus : null,
                })
            }
        })
    }


    //新增佐证材料提交
    cailiaoTijiao = () => {
        this.setState({showfzclModel1: false})
    }
    //资源添加提交继续添加
    cailiaoTijiaoAdd = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {neirong, neirongType, jidiName, cailiaoType, cailiao} = value
                this.props.form.resetFields();
            }
        })
    }
    // 上一步
    previous = async () => {
        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_Communication") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_Communication',
                    })
                    break;
                }
            }
        }
    }
    // 下一步
    next = async () => {
        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_Schoolresources") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_Schoolresources',
                    })
                    break;
                }
            }
        }
    }

    //点否二次确认
    isindependentOk = () => {
        const {targetValue, indicatorVo134} = this.state
        let data = {
            confirmStatus: Number(targetValue),
            id: indicatorVo134.conditionVo.id,
            targetId: indicatorVo134.id
        }
        updateApplyFactor(data).then(e => {
            if (e) {
                // message.success("修改成功")
                this.setState({
                    yingyong: targetValue,
                }, () => {
                    this.getApplies()
                })
            }
        })
        this.setState({
            isindependent: false
        })
    }

    //修改1.3.4指标条件
    updateApplyFactor = e => {
        const {indicatorVo134, dataSource1} = this.state
        if (e.target.value === 0 && dataSource1.length > 0) {
            this.setState({
                targetValue: e.target.value,
                isindependent: true,
            })
            return;
        }
        let data = {
            confirmStatus: Number(e.target.value),
            id: indicatorVo134.conditionVo.id,
            targetId: indicatorVo134.id
        }
        updateApplyFactor(data).then(res => {
            if (res) {
                // message.success("修改成功")
                this.setState({
                    yingyong: e.target.value,
                })
            }
        })
    }

    //修改指标条件
    updateCondition = (e, id, targetId) => {
        const {} = this.state
        let data = {
            confirmStatus: Number(e.target.value),
            id: id,
            targetId: targetId
        }
        updateCondition(data).then(e => {
            if (e) {
                // message.success("修改成功")
            }
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            //获取应用列表
            this.getApplies()
        });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageSize}, () => {
            //获取应用列表
            this.getApplies()
        });
    }
    // 添加佐证材料
    onHandevid = (targetId, targetMark) => {
        // console.log("targetMark",targetMark)
        this.setState({
            materialTargetId: targetId,
            mediaFlagMark: targetMark
        }, () => {
            this.setState({
                addEvidence: true
            })
        })
    }
    // 查看佐证材料
    lookHandevid = (targetId, targetMark) => {
        this.setState({
            materialTargetId: targetId,
            mediaFlagMark: targetMark
        }, () => {
            this.setState({
                LookEvidence: true
            })
        })
    }
    // 关闭
    handleCancel = () => {
        this.setState({LookEvidence: false});
    };
    //删除前二次确认
    deleteAsk = (record) => {
        let data = {
            id: record.id
        }
        deleteApply(data).then(e => {
            if (e) {
                message.success("删除成功")
                //获取应用列表
                this.getApplies()
            }
        })
        // this.setState({deletePlanModal:true})
    }
    //获取1.3.4应用列表
    getApplies = () => {
        const {indicatorVo134, pageNum, pageSize} = this.state
        let data = {
            id: indicatorVo134.id,
            pageNum: pageNum,
            pageSize: pageSize
        }
        getApplies(data).then(e => {
            if (e) {
                this.setState({
                    dataSource1: e.data ? e.data : [],
                    total: e.total
                })
            }
        })
    }
    //提交应用
    addMaterial = (flag) => {
        this.setState({
            addInterflowDataFlag: true
        })
        const {editAppFlag, schoolEduCode, indicatorVo134, editAppInfo} = this.state
        let file = this.AddZzclChild.getTableData() ? this.AddZzclChild.getTableData() : ''
        this.AddZzclChild.props.form.validateFields(async (err, subjectInfo) => {
            if (!err) {
                let address;
                if (subjectInfo.cailiaoType === 1 || subjectInfo.cailiaoType1 === 1) {
                    if (this.checkLinkAddress(subjectInfo.lianjie)) {
                        address = subjectInfo.lianjie
                    } else {
                        message.warning('链接格式错误')
                        return;
                    }
                } else if (subjectInfo.cailiaoType === 2 || subjectInfo.cailiaoType1 === 2) {
                    address = subjectInfo.address
                }
                let type;
                if (subjectInfo.neirongType === 1) {
                    type = subjectInfo.cailiaoType
                } else if (subjectInfo.neirongType === 2) {
                    type = subjectInfo.cailiaoType1
                }
                if (editAppFlag) {
                    //修改
                    let data = {
                        applyName: subjectInfo.neirong,
                        applyType: subjectInfo.neirongType,
                        baseName: subjectInfo.jidiName,
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: editAppInfo.id,
                        linkAddress: address,
                        type: type
                    }
                    updateApply(data).then(e => {
                        if (e) {
                            message.success("修改成功")
                            this.AddZzclChild.reset()
                            this.setState({
                                showfzclModel1: flag
                            }, () => {
                                //获取应用列表
                                this.getApplies()
                            })
                        }
                    })
                } else {
                    //新增
                    let data = {
                        applyName: subjectInfo.neirong,
                        applyType: subjectInfo.neirongType,
                        baseName: subjectInfo.jidiName,
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        linkAddress: address,
                        schoolEduCode: schoolEduCode,
                        targetId: indicatorVo134.id,
                        type: type
                    }
                    addApply(data).then(e => {
                        if (e) {
                            message.success("添加成功")
                            this.AddZzclChild.reset()
                            this.setState({
                                showfzclModel1: flag
                            }, () => {
                                //获取应用列表
                                this.getApplies()
                            })
                        }
                    })
                }
            }
        })
        setTimeout(function () {
            that.setState({
                addInterflowDataFlag: false
            })
        }, 100)
    }
    //编辑应用
    editApplication = (record) => {
        this.setState({
            editAppInfo: record,
            fileData: record.evidenceInfoVo
        }, () => {
            this.setState({
                showfzclModel1: true,
                editAppFlag: true
            })
        })
    }
    //添加应用
    addApplication = () => {
        this.setState({
            editAppInfo: ''
        }, () => {
            this.setState({
                showfzclModel1: true,
                editAppFlag: false
            })
        })
    }
    // 导入文件
    importFile = async (e) => {
        let {indicatorVo134, schoolEduCode} = this.state;
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('targetId', indicatorVo134.id)
        importApply(fromData).then(res => {
            message.success('导入成功')
            this.getApplies()
        })
    }
    //下载模板
    dowLoadFile = (targetMark, targetName) => {
        let data = {
            type: targetMark
        }
        axios.get(downExcelTemplateUrl, {
            responseType: 'blob',
            params: data
        }).then(res => {
            let url = window.URL.createObjectURL(new Blob([res.data]));
            let link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.setAttribute("download", `${targetName}.xls`);

            document.body.appendChild(link);
            // console.log(res);

            link.click();
        })
    }
    //添加佐证材料判断方法
    setMaterialFlag = (mediaFlagMark) => {
        const {indicatorVo131, indicatorVo132, indicatorVo133, indicatorVo135} = this.state
        let indicator
        if (mediaFlagMark === '1.3.1') {
            indicator = indicatorVo131
            indicator.materialFlag = true
            this.setState({
                indicatorVo131: indicator
            })
        } else if (mediaFlagMark === '1.3.2') {
            indicator = indicatorVo132
            indicator.materialFlag = true
            this.setState({
                indicatorVo132: indicator
            })
        } else if (mediaFlagMark === '1.3.3') {
            indicator = indicatorVo133
            indicator.materialFlag = true
            this.setState({
                mediaFlag133: indicator
            })
        } else if (mediaFlagMark === '1.3.5') {
            indicator = indicatorVo135
            indicator.materialFlag = true
            this.setState({
                indicatorVo135: indicator
            })
        }
    }

    //查看佐证材料判断方法
    setMediaFlag = (mediaFlagMark) => {
        console.log("111", mediaFlagMark)
        if (mediaFlagMark === '1.3.1') {
            this.setState({
                mediaFlag131: false
            })
        } else if (mediaFlagMark === '1.3.2') {
            this.setState({
                mediaFlag132: false
            })
        } else if (mediaFlagMark === '1.3.3') {
            this.setState({
                mediaFlag133: false
            })
        } else if (mediaFlagMark === '1.3.5') {
            this.setState({
                mediaFlag135: false
            })
        }
    }
    //提交佐证材料
    addData = () => {
        let flag = this.AddevidenceChild.addData()
        if (!flag) {
            return;
        }
        setTimeout(function () {
            that.setState({
                addEvidence: false,
            })
        }, 1000)
        const {mediaFlagMark} = this.state
        if (mediaFlagMark === '1.3.1') {
            this.setState({
                mediaFlag131: true
            })
        } else if (mediaFlagMark === '1.3.2') {
            this.setState({
                mediaFlag132: true
            })
        } else if (mediaFlagMark === '1.3.3') {
            this.setState({
                mediaFlag133: true
            })
        } else if (mediaFlagMark === '1.3.5') {
            this.setState({
                mediaFlag135: true
            })
        }
    }
    // 查看资料
    handLook = (record) => {
        if (record.evidenceInfoVo.transStatus === 2) {
            message.warning("该文件转码失败")
            return;
        }
        if (record.evidenceInfoVo.transStatus === 3) {
            message.warning("转码中")
            return;
        }
        this.setState({
            resourceId: record.evidenceInfoVo.id
        }, () => {
            this.setState({
                examine: true,
            })
        })
    }
    handExamine = () => {
        this.setState({
            examine: true
        })
    }
    onCancel = () => {
        this.setState({
            examine: false
        })
    }
    //校验网址
    checkLinkAddress = (str) => {
        if (str !== undefined && str !== '') {
            let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
            if (str.match(reg) == null) {
                return false;
            } else {
                return true;
            }
        }
    }

    // section Render
    render() {
        const roleType = getRoleType()
        const canEdit = roleType === RoleType.school
        const {getFieldDecorator} = this.props.form
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 60,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{index + 1}</span>
                }
            },
            {
                title: '课程或教学应用名称',
                dataIndex: 'applyName',
                key: 'applyName',
                align: 'center',
                width: 150
            },
            {
                title: '类型',
                dataIndex: 'applyType',
                key: 'applyType',
                align: 'center',
                width: 60,
                render(text, record) {
                    return (
                        <div>
                            {text === 1 ? "线上" : text === 2 ? "线下" : ''}
                        </div>
                    )
                }
            },
            {
                title: '虚拟仿真实训基地名称',
                dataIndex: 'baseName',
                key: 'baseName',
                align: 'center',
                width: 150
            },
            {
                title: '佐证材料',
                dataIndex: 'type',
                key: 'type',
                align: 'center',
                width: 100,
                render: (text, record) => <div className='text_td'>
                    {text === 1 || text === 2 ? <span>{record.linkAddress}</span> : text === 3 ? record.evidenceInfoVo ?
                        <span onClick={() => this.handLook(record)}>查看</span> : '未上传' : ''}
                </div>
            }
        ]
        if (canEdit) columns1.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            width: 100,
            render: (text, record) => <div>
                <span className={'cz'} onClick={() => this.editApplication(record)}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteAsk(record)}>删除</span>
            </div>
        })
        const {
            dataSource1,
            showfzclModel1,
            cailiaoType,
            neirongType,
            indicatorVo131,
            indicatorVo132,
            indicatorVo133,
            indicatorVo134,
            indicatorVo135,
            tiaojian1,
            tiaojian2,
            tiaojian3,
            guifan,
            pingjia,
            yingyong,
            xuqiu,
            isindependent,
            pageNum,
            pageSize,
            total,
            addEvidence,
            LookEvidence,
            materialTargetId,
            targetValue,
            editAppFlag,
            editAppInfo,
            addInterflowDataFlag,
            fileData,
            examine,
            resourceId,
            mediaFlag131,
            mediaFlag132,
            mediaFlag133,
            mediaFlag135,
            mediaFlagMark
        } = this.state
        return (
            <div className="PlatformAbility main-bg">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id='Fillside_warp'>
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect">
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside onRef={(ref) => {
                                            this.LeftEfficiencyside = ref;
                                        }}/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right">
                                    <div className='content'>
                                        <Form>
                                            <div className={'cont1'}>
                                                <div
                                                    className={'titleName'}>{indicatorVo131 ? indicatorVo131.targetMark : ''}{indicatorVo131 ? indicatorVo131.targetName : ''}</div>
                                                <div className={'cont1-cont'}>
                                                    <span>平台需通过一个平台实现统筹管理，基本具备以下三个条件：</span>
                                                    <ul className={'tiaojianul'}>
                                                        <li>
                                                            <span>①{indicatorVo131 ? indicatorVo131.list[0].conditionName : ''}</span>
                                                            {canEdit ? (<Form.Item>
                                                                {getFieldDecorator('tiaojian1', {
                                                                    initialValue: tiaojian1,
                                                                })(
                                                                    <Radio.Group className={'radio-right'}
                                                                                 defaultValue={tiaojian1}
                                                                                 onChange={e => this.updateCondition(e, indicatorVo131.list[0].id, indicatorVo131.id)}>
                                                                        <Radio value={1}>是</Radio>
                                                                        <Radio value={0}>否</Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item>) : ""}

                                                        </li>
                                                        <li>
                                                            <span>②{indicatorVo131 ? indicatorVo131.list[1].conditionName : ''}</span>
                                                            {canEdit ? <Form.Item>
                                                                {getFieldDecorator('tiaojian2', {
                                                                    initialValue: tiaojian2,
                                                                })(
                                                                    <Radio.Group className={'radio-right'}
                                                                                 defaultValue={tiaojian2}
                                                                                 onChange={e => this.updateCondition(e, indicatorVo131.list[1].id, indicatorVo131.id)}>
                                                                        <Radio value={1}>是</Radio>
                                                                        <Radio value={0}>否</Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item> : ""}

                                                        </li>
                                                        <li>
                                                            <span>③{indicatorVo131 ? indicatorVo131.list[2].conditionName : ''}</span>
                                                            {canEdit ? <Form.Item>
                                                                {getFieldDecorator('tiaojian3', {
                                                                    initialValue: tiaojian3,
                                                                })(
                                                                    <Radio.Group className={'radio-right'}
                                                                                 defaultValue={tiaojian3}
                                                                                 onChange={e => this.updateCondition(e, indicatorVo131.list[2].id, indicatorVo131.id)}>
                                                                        <Radio value={1}>是</Radio>
                                                                        <Radio value={0}>否</Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item> : ""}

                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={'btn_group'}>
                                                    {canEdit ? <Button type={'primary'}
                                                                       onClick={() => this.onHandevid(indicatorVo131.id, indicatorVo131.targetMark)}><AddSupportingMaterial/>添加佐证材料</Button> : ""}
                                                    {indicatorVo131 ? indicatorVo131.materialFlag && mediaFlag131 ?
                                                        <span className={'chakanzzcl'}
                                                              onClick={() => this.lookHandevid(indicatorVo131.id, indicatorVo131.targetMark)}>查看佐证材料></span> : '' : ''}
                                                </div>
                                            </div>
                                            <div className={'cont1'}>
                                                <div
                                                    className={'titleName'}>{indicatorVo132 ? indicatorVo132.targetMark : ''}{indicatorVo132 ? indicatorVo132.targetName : ''}</div>
                                                <div className={'cont1-cont'}>
                                                    <ul className={'tiaojianul'}>
                                                        <li>
                                                            <span>{indicatorVo132 ? indicatorVo132.list[0].conditionName : ''}</span>
                                                            {canEdit ? <Form.Item>
                                                                {getFieldDecorator('guifan', {
                                                                    initialValue: guifan,
                                                                })(
                                                                    <Radio.Group className={'radio-right'}
                                                                                 defaultValue={guifan}
                                                                                 onChange={e => this.updateCondition(e, indicatorVo132.list[0].id, indicatorVo132.id)}>
                                                                        <Radio value={1}>是</Radio>
                                                                        <Radio value={0}>否</Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item> : ""}

                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={'btn_group'}>
                                                    {canEdit ? <Button type={'primary'}
                                                                       onClick={() => this.onHandevid(indicatorVo132.id, indicatorVo132.targetMark)}><AddSupportingMaterial/>添加佐证材料</Button> : ""}
                                                    {indicatorVo132 ? indicatorVo132.materialFlag && mediaFlag132 ?
                                                        <span className={'chakanzzcl'}
                                                              onClick={() => this.lookHandevid(indicatorVo132.id, indicatorVo132.targetMark)}>查看佐证材料></span> : '' : ''}
                                                </div>
                                            </div>
                                            <div className={'cont1'}>
                                                <div
                                                    className={'titleName'}>{indicatorVo133 ? indicatorVo133.targetMark : ''}{indicatorVo133 ? indicatorVo133.targetName : ''}</div>
                                                <div className={'cont1-cont'}>
                                                    <ul className={'tiaojianul'}>
                                                        <li>
                                                            <span>{indicatorVo133 ? indicatorVo133.list[0].conditionName : ''}</span>
                                                            {canEdit ? <Form.Item>
                                                                {getFieldDecorator('pingjia', {
                                                                    initialValue: pingjia,
                                                                })(
                                                                    <Radio.Group className={'radio-right'}
                                                                                 defaultValue={pingjia}
                                                                                 onChange={e => this.updateCondition(e, indicatorVo133.list[0].id, indicatorVo133.id)}>
                                                                        <Radio value={1}>是</Radio>
                                                                        <Radio value={0}>否</Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item> : ""}

                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={'btn_group'}>
                                                    {canEdit ? <Button type={'primary'}
                                                                       onClick={() => this.onHandevid(indicatorVo133.id, indicatorVo133.targetMark)}><AddSupportingMaterial/>添加佐证材料</Button> : ""}
                                                    {indicatorVo133 ? indicatorVo133.materialFlag && mediaFlag133 ?
                                                        <span className={'chakanzzcl'}
                                                              onClick={() => this.lookHandevid(indicatorVo133.id, indicatorVo133.targetMark)}>查看佐证材料></span> : '' : ''}
                                                </div>
                                            </div>
                                            <div className={'cont1'}>
                                                <div
                                                    className={'titleName'}>{indicatorVo134 ? indicatorVo134.targetMark : ''}{indicatorVo134 ? indicatorVo134.targetName : ''}</div>
                                                <div className={'cont1-cont'}>
                                                    <ul className={'tiaojianul'}>
                                                        <li>
                                                            <span>{indicatorVo134 ? indicatorVo134.conditionVo.conditionName : ''}</span>
                                                            {canEdit ?
                                                                <Radio.Group className={'radio-right'} value={yingyong}
                                                                             onChange={this.updateApplyFactor}>
                                                                    <Radio value={1}>是</Radio>
                                                                    <Radio value={0}>否</Radio>
                                                                </Radio.Group> : ""}

                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className={yingyong === 0 ? 'conteTable_on' : ""}>
                                                    {
                                                        yingyong === 0 ? (
                                                            <div className="taper_cont">
                                                                    <span className="span_time">
                                                                        <TaperedEnd/>
                                                                        <span>上锁</span>
                                                                    </span>
                                                            </div>
                                                        ) : ""
                                                    }
                                                    <Table columns={columns1} dataSource={dataSource1}
                                                           className={'table1 table2'}
                                                           bordered={true}
                                                           locale={{
                                                               emptyText: <>
                                                                   <div className="Empty-content">
                                                                       <div className="text">
                                                                           <img
                                                                               src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                           <span>暂无数据</span>
                                                                       </div>
                                                                   </div>
                                                               </>
                                                           }}
                                                           pagination={
                                                               {
                                                                   total: total,
                                                                   pageSize: pageSize,
                                                                   onChange: this.pageChange,
                                                                   onShowSizeChange: this.onShowSizeChange,
                                                                   pageSizeOptions: ['10', '20', '30', '40'],
                                                                   showSizeChanger: true,
                                                               }
                                                           }
                                                    />
                                                </div>
                                                {
                                                    canEdit ? (yingyong === 0 ? (
                                                        <div className={'btn_group btn_group_on'}>
                                                            <Button type={'primary'} disabled={true}><AddApplication/>添加应用</Button>
                                                        </div>
                                                    ) : (
                                                        <div className={'btn_group'}>
                                                            <Button type={'primary'}
                                                                    onClick={this.addApplication}><AddApplication/>添加应用</Button>
                                                        </div>
                                                    )) : ""
                                                }
                                            </div>
                                            <div className={'cont1'}>
                                                <div
                                                    className={'titleName'}>{indicatorVo135 ? indicatorVo135.targetMark : ''}{indicatorVo135 ? indicatorVo135.targetName : ''}</div>
                                                <div className={'cont1-cont'}>
                                                    <ul className={'tiaojianul'}>
                                                        <li>
                                                            <span>{indicatorVo135 ? indicatorVo135.list[0].conditionName : ''}</span>
                                                            {canEdit ? <Form.Item>
                                                                {getFieldDecorator('xuqiu', {
                                                                    initialValue: xuqiu,
                                                                })(
                                                                    <Radio.Group className={'radio-right'}
                                                                                 defaultValue={xuqiu}
                                                                                 onChange={e => this.updateCondition(e, indicatorVo135.list[0].id, indicatorVo135.id)}>
                                                                        <Radio value={1}>是</Radio>
                                                                        <Radio value={0}>否</Radio>
                                                                    </Radio.Group>
                                                                )}
                                                            </Form.Item> : ""}
                                                        </li>
                                                    </ul>
                                                </div>
                                                {canEdit ? <div className={'btn_group'}>
                                                    <Button type={'primary'}
                                                            onClick={() => this.onHandevid(indicatorVo135.id, indicatorVo135.targetMark)}><AddSupportingMaterial/>添加佐证材料</Button>
                                                    {indicatorVo135 ? indicatorVo135.materialFlag && mediaFlag135 ?
                                                        <span className={'chakanzzcl'}
                                                              onClick={() => this.lookHandevid(indicatorVo135.id, indicatorVo135.targetMark)}>查看佐证材料></span> : '' : ''}
                                                </div> : ""}

                                            </div>
                                        </Form>
                                        {canEdit ? <div className={'footer-btn'}>
                                            <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                            <Button type={'primary'} onClick={() => this.next()}>下一步</Button>
                                        </div> : ""}
                                        {/*添加应用*/}
                                        <Modal
                                            title={editAppFlag ? '修改应用' : '添加应用'}
                                            visible={showfzclModel1}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addzzclModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({showfzclModel1: false})}>取消</Button>
                                                    <Button type='primary' disabled={addInterflowDataFlag}
                                                            onClick={() => this.addMaterial(false)}>提交</Button>
                                                    {editAppFlag ? "" :
                                                        <Button type='primary' disabled={addInterflowDataFlag}
                                                                onClick={() => this.addMaterial(true)}>提交并继续添加</Button>}
                                                </div>
                                            }
                                        >
                                            <AddZzcl onRef={(ref) => this.AddZzclChild = ref} editAppFlag={editAppFlag}
                                                     editAppInfo={editAppInfo}
                                                     fileData={fileData}/>
                                        </Modal>
                                        {/*二次确认*/}
                                        <Modal
                                            title={'温馨提示'}
                                            visible={isindependent}
                                            width={430}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'wenxintishi'}
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({isindependent: false})}>取消</Button>
                                                    <Button type='primary' onClick={this.isindependentOk}>确定</Button>
                                                </div>
                                            }
                                        >
                                            <div className={'tipwrap'}>
                                                <WenxinTip/><span>选择否，将清空当前已填写的数据，请确认是否继续？</span>
                                            </div>
                                        </Modal>
                                        {/*添加佐证材料*/}
                                        <Modal
                                            title={'添加佐证材料'}
                                            visible={addEvidence}
                                            width={1000}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'Addevidence'}
                                            wrapClassName="Addevidence_warp"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({addEvidence: false})}>取消</Button>
                                                    <Button type='primary' onClick={() => this.addData()}>提交</Button>
                                                </div>
                                            }
                                        >
                                            <Addevidence onRef={(ref) => this.AddevidenceChild = ref}
                                                         materialTargetId={materialTargetId}
                                                         mediaFlagMark={mediaFlagMark} setMediaFlag={this.setMediaFlag}
                                                         setMaterialFlag={this.setMaterialFlag}/>
                                        </Modal>
                                        {/*查看佐证材料*/}
                                        <Modal
                                            title={'查看佐证材料'}
                                            visible={LookEvidence}
                                            width={800}
                                            centered={true}
                                            destroyOnClose={true}
                                            className={'Addevidence'}
                                            wrapClassName="Addevidence_warp"
                                            onCancel={this.handleCancel}
                                            footer={
                                                <div className='footer-btn' style={{display: 'none'}}>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({LookEvidence: false})}>取消</Button>
                                                    <Button type='primary' onClick={this.cailiaoTijiao}>提交</Button>
                                                </div>
                                            }
                                        >
                                            <Addevidencelook materialTargetId={materialTargetId}
                                                             mediaFlagMark={mediaFlagMark}
                                                             setMediaFlag={this.setMediaFlag}/>
                                        </Modal>
                                        {/*查看资料*/}
                                        <Drawer
                                            title={'查看资料'}
                                            visible={examine}
                                            onOk={this.handExamine}
                                            onClose={this.onCancel}
                                            destroyOnClose={true}
                                            closable={true}
                                            maskClosable={true}
                                            wrapClassName="wappop_up lookMaterial"
                                            footer={
                                                <div className='addModalBtn' style={{display: 'none'}}>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({examine: false})}>取 消</Button>
                                                    <Button type='primary'>确定</Button>
                                                </div>
                                            }
                                        >
                                            <LookCheck id={resourceId}/>
                                        </Drawer>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

PlatformAbility = Form.create()(PlatformAbility);
export default PlatformAbility;
