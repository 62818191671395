export const apiShixun = () => {
    if (process.env.NODE_ENV === "development") {
        return ""
    } else {
        return "/api/vb"
    }
    // return ""
}
export const apiFujian = () => {
    return "/api/fujian"
    // return ""
}

export const apiv1 = () => {
    return "/api/v1"
    // return ""
}

// export const apiRcenterl = () => {
//     return "/api/rcenter"
// }
