/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Form, message, Button, Modal} from 'antd';
import FormComponent from "./Form-component/index"
import '../Budget-total/index.scss';
import {deleteInvest, updateInvest, addInvest} from "../../../api/invest";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import SkipComponent from "./skip-component";
import ExpenditurePayComponent from "./expenditurePay-component";

class SchoolDevelopment extends Component {
    state = {
        itemNum: 2,
        incrementNum: 2,
        deleteList: [],
        formList: [],
        formObj: [
            {
                "formItem": "1",
                "subtaskName": "",
                "subtaskId": "",
                "investDetails": [
                    {
                        "id": "",
                        "type": 1,
                        "investMoney": "",
                        "investScale": ""
                    },
                    {
                        "id": "",
                        "type": 3,
                        "investMoney": "",
                        "investScale": ""
                    },
                    {
                        "id": "",
                        "type": 4,
                        "investMoney": "",
                        "investScale": ""
                    },
                ]
            },
        ],
        ifEdit: false,//是否是编辑状态
        ifComplete: true,//是否填写完
        dataList: this.props.dataList,
        saveUrl: this.props.saveUrl,//保存地址
        delUrl: this.props.delUrl,//删除地址
    };

    componentDidMount() {
        // console.log("budget:进入componentDidMount")
        // this.props.getSubItemList(this.props.seniorId)
        // const {dataList,ifComplete}=this.state;
        //    if(ifComplete){
        //        this.setState({
        //            formObj:dataList
        //        })
        //       }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.dataList !== this.props.dataList) {
            this.setState({
                dataList: this.props.dataList
            })
        }
        if (prevState.dataList !== this.state.dataList) {
            if (this.state.dataList && this.state.dataList.length !== 0) {
                // if (nextProps.dataList.length === 1){
                let formObj = this.state.dataList;
                this.setState({
                    formObj: formObj,
                    itemNum: formObj.length,
                    incrementNum: formObj[formObj.length - 1] ? formObj[formObj.length - 1].displayOrder : 1
                })
            } else {
                console.log("走了else")

                this.setState({
                    formObj: [
                        {
                            "formItem": "1",
                            "subtaskName": "",
                            "subtaskId": "",
                            "investDetails": [
                                {
                                    "id": "",
                                    "type": 1,
                                    "investMoney": "",
                                    "investScale": ""
                                },
                                {
                                    "id": "",
                                    "type": 3,
                                    "investMoney": "",
                                    "investScale": ""
                                },
                                {
                                    "id": "",
                                    "type": 4,
                                    "investMoney": "",
                                    "investScale": ""
                                },
                            ]
                        }],
                    itemNum: 2,
                    incrementNum: 2
                })
                // }
                // })
                console.log("budget:formObj重新赋空值")
                // this.props.getSubItemList(this.props.seniorId);

            }
        }

    }

    componentWillReceiveProps(nextProps, nextContext) {

    }

    refreshList = () => {
        this.props.getSubItemList(1);
    }

    // 删除(弃用)
    delFormItem = async (num, formItem, data) => {
        const {formObj, itemNum, seniorId, upseniorId} = this.state
        // let formListNew = formObj.filter(e => e.subtaskId !== data.subtaskId);
        console.log("删除里边第一个", num, formItem, data)
        delete formObj[num]
        console.log("删除里边第二个", data)
        if (itemNum > 1) {
            if (data.subtaskId) {
                // 真删
                Modal.confirm({
                    className: 'content-warp',
                    title: <div>
                        消息提醒
                    </div>,
                    centered: true,
                    content: "确定要删除吗?",
                    onOk: async () => {
                        await deleteInvest({id: data.subtaskId, seniorId: this.props.seniorId}).then(e => {
                            this.setState({
                                itemNum: itemNum - 1,
                                // formObj: formListNew
                                formObj
                            })
                        })
                    }
                })
            } else {
                this.setState({
                    itemNum: itemNum - 1,
                    // formObj: formListNew
                    formObj
                }, () => {
                })
            }
        } else {
            message.destroy();
            message.warning('最少填写一项');
        }
    }

    //编辑
    editForm = (index) => {
        // console.log("delete数组：：：：：",this.state.deleteList)
        // console.log("传入的参数“：：：：：",index)
        let {deleteList} = this.state;
        let filter = deleteList.filter(e => {
            return e === index;
        });
        if (!filter) {
            deleteList.push(index)
        }
        this.setState({
            ifEdit: true
        })
    }
    //保存
    save = (e) => {
        e.preventDefault();
        const {itemNum, formObj, saveUrl, delUrl} = this.state
        formObj.map((item, index) => {
            console.log("item.name", item.formItem)
            this['formAssignment' + item.formItem].props.form.validateFields((err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                    this.setState({
                        ifEdit: false
                    })
                }
            })
        })
    }
    //新增
    addFormItem = () => {
        const {formObj, itemNum, incrementNum} = this.state
        console.log("当前itemNUm", incrementNum)
        console.log("当前itemNUm:::::", parseInt(incrementNum))
        formObj.push(
            {
                "formItem": `${parseInt(incrementNum) + 1}`,
                "subtaskName": "",
                "subtaskId": "",
                "investDetails": [
                    {
                        "id": "",
                        "type": 1,
                        "investMoney": "",
                        "investScale": ""
                    },
                    {
                        "id": "",
                        "type": 2,
                        "investMoney": "",
                        "investScale": ""
                    },
                    {
                        "id": "",
                        "type": 3,
                        "investMoney": "",
                        "investScale": ""
                    },
                    {
                        "id": "",
                        "type": 4,
                        "investMoney": "",
                        "investScale": ""
                    },
                ]
            })
        this.setState({
            formObj: formObj,
            itemNum: parseInt(itemNum) + 1,
            incrementNum: parseInt(incrementNum) + 1,
            ifComplete: false
        })

    }

    // section render
    render() {
        const {ifEdit, formObj, ifComplete} = this.state;
        const formArr =
            formObj ? formObj.map((item, index) => {
                // console.log("budget:父组件props",item)
                // section 经费来源
                return (
                    <div>
                        <div className="section_title">
                            <h1 className="small-title" style={{fontSize: '16px'}}>2-5-1 经费来源</h1>
                        </div>

                        <FormComponent
                            key={item.formItem + this.props.index}
                            wrappedComponentRef={(form) => this['formAssignment' + item.formItem] = form}
                        />
                    </div>
                )
            }) : ''

        // section 经费支出
        const moneyExpenditure =
            <div>
                <div className="section_title" style={{marginTop: '50px'}}>
                    <h1 className="small-title" style={{fontSize: '16px'}}>2-5-2 经费支出</h1>
                </div>
                <ExpenditurePayComponent
                    getExpenditureList={this.props.getExpenditureList}
                    index={this.props.index}
                    taskId={this.props.taskId}
                    seniorId={this.props.seniorId}
                    upSeniorId={this.props.upSeniorId}
                >

                </ExpenditurePayComponent>
            </div>


        /*     const  formListShow=dataList.map((item,index)=>{
                 return(
                     <FormAssignment  name={item.formItem} formData={item} delFormItem={this.delFormItem}  key={item.formItem} num={index} wrappedComponentRef={(form) => this['formAssignment'+item.formItem] = form}/>
                 )
             })*/
        return (
            <div className="ren_wu_shu_2_5">
                <div className="top clearfix">
                    <div className="title-h3">
                        {/*<h3>{this.props.upSeniorId} {this.props.upSeniorName}</h3>*/}
                    </div>
                </div>
                <div className="content">
                    {/*<h3 className='small-title'>{this.props.upSeniorId} {this.props.upSeniorName}</h3>*/}
                    {formArr}
                    {moneyExpenditure}
                </div>

            </div>
        );
    }
}

SchoolDevelopment.defaultProps = {
    //获取列表的回调,让外部可以获取来判断是否为空
    getExpenditureList: (data) => {

    }
}
export default SchoolDevelopment;
