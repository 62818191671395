/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Card, Row, Col, Button, Form, Input, Select, DatePicker, Modal, Radio, InputNumber, Table, message} from 'antd';
// import { saveUpdateSchool,getSchoolProfile } from "@/api/doublehigh/project-school.js/";
import '../components/index.scss';
import Header from '../../../components/header'
import Footer from '../../../components/footer'
import StepTop from '../../components/StepTop';
import {ScoolIcon1, ScoolIcon2, TianJia} from "../../../components/iconSvg";
import moment from "moment";
import {chinaAllProvinceArr, chinaAllProvinceCityArr} from '../../../config/common';
import {
    addAndSaveLinkManInfoRequest,
    addSchoolInfo, deleteLinkManInfoRequest,
    getBasicCondition, getLinkManInfoRequest,
    getLinkManListRequest,
    getSchool,
    getSchoolInfos,
    updateSchoolInfo
} from '../../../api/school'
import {selectRegion} from '../../../api/sharing'
import Cookies from "js-cookie";
import {
    addAndSaveCourse,
    deleteProject,
    getPageCourseList,
    selectAllResourcesService, selectCourseInfoById,
    updateApplyResources
} from "../../../api/target";
import {updateProcessStatus} from "../../../api/effect";
import {getCanEdit, getTargetId} from "../../../until/cookie";

const monthFormat = 'YYYY-MM';
const {TextArea} = Input;
const {Option} = Select;
const {MonthPicker} = DatePicker;
const provinceData = chinaAllProvinceArr;
const cityData = chinaAllProvinceCityArr

function disabledDate(current) {
    return current && current > moment().endOf('day');
}

class Project_School_Modal extends Component {
    state = {
        // 进度
        processCode: '2.1',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        schoolId: '',

        pageSize: 10,
        pageNum: 1,
        // 联系人列表
        linkManData: {},
        currentEditLinkManId: undefined,
    };

    componentDidMount() {
        this.getLinkManListMethod()
    }

    // section 获取联系人列表信息
    getLinkManListMethod = () => {
        let {schoolInfo} = this.state
        let taskId = schoolInfo.taskId
        let schoolEduCode = schoolInfo.schoolEduCode

        let data = {
            taskId: taskId,
            schoolEduCode: schoolEduCode,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            userTyp: 2
        }
        getLinkManListRequest(data).then((res) => {
            this.setState({
                linkManData: res
            }, () => {
                const total = res?.total ? res.total : 0
                if (this.props.haveLinkManDataCallback) {
                    this.props.haveLinkManDataCallback(total > 0)
                }
            })
        })
    }

    // 添加提交
    addAndSaveLinkManMethod = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let {schoolInfo, currentEditLinkManId} = this.state
                let taskId = schoolInfo.taskId
                let schoolEduCode = schoolInfo.schoolEduCode

                let data = {
                    "taskId": taskId,
                    'schoolEduCode': schoolEduCode,
                    "id": currentEditLinkManId,
                    "userName": value.userName,
                    "userPost": value.userPost,
                    "workPhone": value.workPhone,
                    "userFax": value.userFax,
                    "phone": value.phone,
                    "email": value.email,
                    "baseUser": value.baseUser
                }
                addAndSaveLinkManInfoRequest(data).then(e => {
                    this.setState({showModal: false, currentEditLinkManId: ""})
                    this.getLinkManListMethod()
                    message.success(this.state.isEdit === false ? '添加成功' : '修改成功');
                })
            }
        })
    }

    edit = (id) => {
        this.setState({
            showModal: true,
            currentEditLinkManId: id,
            isEdit: true,
        })
        let data = {
            id: id
        }
        getLinkManInfoRequest(data).then(value => {
            // section 1
            this.props.form.setFieldsValue({
                "id": id,
                "userName": value.userName,
                "userPost": value.userPost,
                "workPhone": value.workPhone,
                "userFax": value.userFax,
                "phone": value.phone,
                "email": value.email,
                "baseUser": value.baseUser
            })
        })
    }

    //添加提交继续添加
    addContinue = (id) => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let {schoolInfo} = this.state
                let schoolEduCode = schoolInfo.schoolEduCode
                let taskId = schoolInfo.taskId

                let data = {
                    "taskId": taskId,
                    'schoolEduCode': schoolEduCode,
                    "userName": value.userName,
                    "userPost": value.userPost,
                    "workPhone": value.workPhone,
                    "userFax": value.userFax,
                    "phone": value.phone,
                    "email": value.email,
                    "baseUser": value.baseUser
                }
                addAndSaveLinkManInfoRequest(data).then(e => {
                    message.success('添加成功');
                    this.setState({
                        currentEditLinkManId: ""
                    })
                    this.props.form.resetFields();
                    this.getLinkManListMethod()
                })
            }
        })
    }

    handleReset = () => {
        this.props.form.resetFields();
    }

    handleProvinceChange = value => {
        let {regionList} = this.state;
        let cities = regionList.filter(e => e.code === value);
        let city = []
        if (cities.length > 0) {
            city = cities[0].regionTwoVoList
        }
        this.setState({
            cities: city,
            cityCode: '',
            provinceCode: value
        }, () => {
            this.props.form.setFieldsValue({"cityCode": ''})
            this.props.form.setFieldsValue({"provinceCode": value})
        });
    };
    onSecondCityChange = (value, key) => {
        console.log('111', key)
        this.setState({
            cityCode: value
        }, () => {
            this.props.form.setFieldsValue({"cityCode": value})
        });

    };

    organizerType = value => {
        this.setState({
            organizerType: value
        })
    }

    basicCondition = (item, confirmStatus) => {
        let {basicCondition} = this.state;
        basicCondition.forEach(e => {
            if (e.basicConditionId === item.basicConditionId) {
                return e.confirmStatus = confirmStatus.target.value
            }
        })
        this.setState({
            basicCondition
        })
    }

    // section Action
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.getLinkManListMethod()
        });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageNum: 1, pageSize}, this.getLinkManListMethod);
    }
    handleCancel = () => {
        this.setState({showModal: false});
    };

    //删除前二次确认
    deleteAsk = (e) => {
        let data = {
            id: e.id
        }
        deleteLinkManInfoRequest(data).then(e => {
            this.setState({
                pageNum: 1
            }, () => {
                message.success("删除成功")
                this.getLinkManListMethod()
            })
        })
    }

    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    // section render
    render() {

        const {
            organizerType,
        } = this.state;

        const {isCanEdit} = this.props

        const {linkManData, pageSize, pageNum, isEdit, showModal} = this.state
        const labelWidth = 140
        const linkManList = linkManData?.data ? linkManData.data : []
        const total = linkManData?.total ? linkManData.total : 0

        const {getFieldDecorator} = this.props.form;

        console.log('organizerType渲染的：：：', organizerType)

        // section columns
        const tableColumns = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{pageSize * (pageNum - 1) + index + 1}</span>
                }
            },
            {
                title: '姓名',
                dataIndex: 'userName',
                key: 'userName',
                align: 'center',
                width: 100
            },
            {
                title: '职务',
                dataIndex: 'userPost',
                key: 'userPost',
                align: 'center',
                width: 150
            },
            {
                title: '办公电话',
                dataIndex: 'workPhone',
                key: 'workPhone',
                align: 'center',
                width: 150
            },
            {
                title: '传真',
                dataIndex: 'userFax',
                key: 'userFax',
                align: 'center',
                width: 150
            },
            {
                title: '手机',
                dataIndex: 'phone',
                key: 'phone',
                align: 'center',
                width: 150
            },
            {
                title: '电子邮箱',
                dataIndex: 'email',
                key: 'email',
                align: 'center',
                width: 150
            },
            {
                title: '基地建设承担角色',
                dataIndex: 'baseUser',
                key: 'baseUser',
                align: 'center',
                width: 150
            },
        ]
        if (isCanEdit) tableColumns.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (text, record) => <div>
                    <span className={'cz'} onClick={() => {
                        this.edit(record.id)
                    }}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteAsk(record)}>删除</span>
            </div>

        })


        // section table
        const tableView =
            <Table columns={tableColumns}
                   dataSource={linkManList || []}
                   scroll={{x: 1100}}
                   className={'table1 table-custom-red-max-width'}
                   style={isCanEdit ? {width:'cale(100% - 65px)'} : {}}
                   bordered={true}
                   locale={{
                       emptyText: <>
                           <div className="Empty-content">
                               <div className="text">
                                   <img
                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                   <span>暂无数据</span>
                               </div>
                           </div>
                       </>
                   }}
                   pagination={
                       {
                           total: total,
                           current: pageNum,
                           pageSize: pageSize,
                           onChange: this.pageChange,
                           onShowSizeChange: this.onShowSizeChange,
                           pageSizeOptions: ['10', '20', '30', '40'],
                           showSizeChanger: true,
                           // position:'bottomRight'
                       }
                   }
            />

        // section Modal
        const projectModal = <Modal
            title={isEdit === false ? '添加' : '编辑'}
            visible={showModal}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            className={'addAddJlModal'}
            wrapClassName="wappop_up"
            footer={
                <div className='footer-btn'>
                    <Button className={'quxiao'}
                            onClick={() => this.handleCancel()}>取消</Button>
                    <Button type='primary'
                            onClick={() => this.addAndSaveLinkManMethod()}>提交</Button>
                    {isEdit === false ?
                        <Button type='primary'
                                onClick={() => this.addContinue()}>
                            提交并继续添加</Button> : ''}
                </div>
            }
        >
            <Form>
                <Form.Item label={'姓名'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('userName', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写姓名`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入姓名' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`userName`) ? this.props.form.getFieldValue(`userName`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>

                <Form.Item label={'职务'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('userPost', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写职务`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入职务' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`userPost`) ? this.props.form.getFieldValue(`userPost`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'办公电话'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('workPhone', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写办公电话`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入办公电话' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`workPhone`) ? this.props.form.getFieldValue(`workPhone`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'传真'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('userFax', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写传真`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入传真' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`userFax`) ? this.props.form.getFieldValue(`userFax`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'手机'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('phone', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写手机`,
                                },
                                {
                                    pattern: /^1[0-9]{10}/,
                                    message: '请输入正确的手机号'
                                },
                            ],
                        })(
                            <Input placeholder='请输入手机'
                                   maxLength={11}
                                   type={'tel'}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`phone`) ? this.props.form.getFieldValue(`phone`).length : 0}/11</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'电子邮箱'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('email', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写电子邮箱`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入电子邮箱' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`email`) ? this.props.form.getFieldValue(`email`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'基地建设承担角色'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('baseUser', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写基地建设承担角色`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入基地建设承担角色' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`baseUser`) ? this.props.form.getFieldValue(`baseUser`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </Modal>

        const header =
            <div>
                <h4 className="title-b"><ScoolIcon2/>3. 项目联系人信息</h4>
                <Button type={'primary'} className="ant-btn-primary"
                        onClick={() => this.setState({
                            showModal: true,
                            isEdit: false,
                        })}><TianJia/>添加</Button>
            </div>
        return (
            <div>
                {(isCanEdit ?
                        header
                        :
                        ""
                )}

                {tableView}
                {projectModal}
            </div>

        );
    }
}

const Project_School_Modal_Component = Form.create()(Project_School_Modal);
export default Project_School_Modal_Component;
