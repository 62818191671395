// 项目情况-平台情况
import React from "react";
import {Form, Input, message, Radio, Select, DatePicker, Button, Tooltip, Spin, InputNumber} from 'antd';
import "./index.scss";
import moment from "moment";
import {selectRegion } from '../../../../api/sharing'
import {uploadFile} from "../../../../api/uploadFile";
import {Upicon, Upicondele,Upiconwc} from "../../../../components/iconSvg";
const { Option } = Select;
class AddEnterprise extends React.Component {
    state = {
        province: '',
        secondCity: '',
        cities: [],
        regionList: []
    };

    componentDidMount() {
        let {data} = this.props;
        console.log('data', data)
        selectRegion().then(res =>{
            if(data){
                console.log('this.state.regionList 001', res)
                let upFile = {
                    originName: data ? data.resourceName : '',
                    fileId: data ? data.fileId  : '',
                    fileSuffix: data ? data.resourceSuffix : '',
                    fileSize: data ?  ( data.fileSize / 1024).toFixed(2) : ''
                }
                let province = res.filter(e => e.code === data.organProvince)
                console.log('province ===>>> 001', province)
                this.setState({
                    data: data,
                    file: data.fileId ? upFile : null,
                    regionList: res,
                    province: {key:data.organProvince, label: data.provinceName },
                    cities: province[0].regionTwoVoList,
                }, () =>{
                    console.log('{key:data.organProvince, label: data.provinceName } ', {key:data.organProvince, label: data.provinceName } )
                    this.props.form.setFieldsValue({
                        organName: data ? data.organName : '',
                        organProvince: {key:data.organProvince, label: data.provinceName },
                        organRegion: data ? {key:data.organRegion, label: data.regionName } : undefined,
                        organCourse: data ? data.organCourse : '',
                        usageCount: data ? data.usageCount : '',
                        fileId: data ? data.fileId : '',
                    })

                })
            } else {
                this.setState({
                    regionList: res,
                    province: res
                })
            }
        })

    }

    handleProvinceChange = (value, key) =>  {
        console.log('value', value)
        let {regionList} = this.state;
        let cities = regionList.filter(e => e.code === value.key);
        let city = []
        if (cities.length > 0) {
            console.log('cities', cities)
            city = cities[0].regionTwoVoList
        }
        this.setState({
            cities: city,
            secondCity: '',
            province: value
        },()=>{
            this.props.form.setFieldsValue({"organRegion" : ''})
            this.props.form.setFieldsValue({"organProvince" : value})
        });
    };

    onSecondCityChange = (value, key) => {
        console.log('value', value)
        this.setState({
            secondCity: value
        },()=>{
            this.props.form.setFieldsValue({"organRegion" : value})
        });

    };

    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    getState = () =>{
        return this.state.file
    }

    render() {
        const {getFieldDecorator }=this.props.form
        const {province, secondCity, cities, regionList} = this.state
        const {file} = this.state;
        console.log('cities', cities)
        console.log('regionList', regionList)
        console.log('province', province)

        return (
            <div className="AddEnterprise" >
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>企业名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('organName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入企业名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入企业名称' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`organName`) ? this.props.form.getFieldValue(`organName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>所在省份</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('organProvince', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择所在省份`,
                                    },
                                ],
                            })(
                                <Select placeholder={'请选择省份'}
                                        className="select-li"
                                        dropdownClassName='Date-main-select'
                                        onChange={this.handleProvinceChange}
                                        labelInValue= {true}
                                >
                                    {regionList.map(item => (
                                        <Option key={item.code} value={item.code}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>所在城市</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('organRegion', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择所在城市`,
                                    },
                                ],
                            })(
                                <Select placeholder={'请选择所在城市'} className="select-li" dropdownClassName='Date-main-select'
                                        onChange={this.onSecondCityChange}
                                        labelInValue= {true}
                                >
                                    {/*<Option disabled key={-1} value="">请选择市</Option>*/}
                                    {cities.map(item => (
                                        <Option key={item.code} value={item.code}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>课程名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('organCourse', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入虚拟仿真课程名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入虚拟仿真课程名称' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`organCourse`) ? this.props.form.getFieldValue(`organCourse`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>使用次数</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('usageCount', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入课程使用次数`,
                                    },
                                ],
                            })(
                                <InputNumber min={0} maxLength={9} placeholder='请输入课程使用次数' />
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item className="li_formup">
                        <span className={'label'}><span className='red'>*</span>佐证材料</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {/*上传中*/}
                                    {file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                </ul>
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
AddEnterprise = Form.create()(AddEnterprise);
export default AddEnterprise;