import { post } from '../../until/axios_instans';
// 新增建设任务子项
export function saveSubTask(data) {
    return post(window.apiShixun+'/api/v1/subtask/saveSubTask', data);
}
// 查询建设任务子项
export function selectSubTask(data) {
    return post(window.apiShixun+'/api/v1/subtask/selectSubTask', data);
}
// 修改建设任务子项
export function updateSubTask(data) {
    return post(window.apiShixun+'/api/v1/subtask/updateSubTask', data);
}
// 删除建设任务子项
export function deleteSubTask(data) {
    return post(window.apiShixun+'/api/v1/subtask/deleteSubTask', data);
}






