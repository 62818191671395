//todo 任务书汇报 填报需要配合时间类走,是否能预览的逻辑也需要确定
import React from "react";
import {Button, Form, message} from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import {Iconndhb, Iconwj, QyBackground, QyLogo} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {getTargetTimes, schoolIndex} from "../../api/index";
import RenderButton from '../../components/renderButton/index'
import Cookies from "js-cookie";
import {getCanEdit, getReportCanEdit} from "../../until/cookie";
import {isNotEmpty} from "../../until/isEmpty";
import moment from "moment";

// todo 需要时间来控制能否填报,预览则可能需要看是否填报完
export default Form.create()(
    withRouter(
        class MissionStatement extends React.Component {
            // section State
            state = {
                schoolInfo: Cookies.get("schoolInfo") ? JSON.parse(decodeURI(Cookies.get("schoolInfo"))) : {},
                schoolIndex: {},
                // 任务书是否能填报
                reportCanEdit: false,
                // 效能填报是否能填报
                canEdit: false,
                timesInfo: {}
            };

            componentDidMount() {
                // this.schoolIndex()
                // this.getTargetTimes()
                // this.getReportTimes()
            }

            getTargetTimes = () => {
                getTargetTimes(1).then(res => {
                    this.setState({
                        timesInfo: res
                    }, () => {
                        // 将是否过期存储到cookie
                        Cookies.set("expired", res.expired === 1)
                        this.setState({
                            canEdit: getCanEdit(),
                        })
                    })
                })
            }
            // 任务书时间
            getReportTimes = () => {
                getTargetTimes(2).then(res => {
                    this.setState({}, () => {
                        Cookies.set("reportExpired", res.expired === 1)
                        this.setState({
                            reportCanEdit: getReportCanEdit(),
                        })
                    })
                })
            }

            schoolIndex = () => {
                let {schoolInfo} = this.state;
                //todo 需要任务书的id,否则无法查看
                schoolIndex({schoolEduCode: schoolInfo.schoolEduCode}).then(res => {
                    if (res) {
                        this.setState({
                            schoolIndex: res

                        });
                        let param = {
                            taskId: res.taskId,
                            schoolEduCode: res.schoolEduCode,
                            provinceCode: res.provinceCode
                        };

                        Cookies.set('previewParam', param);
                        Cookies.set('schoolInfo', res)
                    }
                })
            }


            componentWillUnmount() {
                clearInterval(this.timer);
            }

            // 去任务书页面
            clickConstructionPlan = () => {
                this.props.history.push('/construction_plan')
            }
            proViewConstructionPlan = () => {
                const {schoolIndex} = this.state
                // Cookies.set('previewParam', param);

                if (schoolIndex != null && isNotEmpty(schoolIndex.taskId)) {
                    window.open(`#/construction_BasicInformation`)
                } else {
                    message.error("请先完成任务书填报")
                }


                // this.props.history.push('/construction_BasicInformation')
            }
            // 去效能填报
            toTarget = () => {
                // 入口会封死
                // this.props.history.push('/money_Perform')
                // this.props.history.push('/demo_Info')
                this.props.history.push({pathname: "/levelOne/5",})
            }
            toPreTarget = () => {
                // 入口会封死
                // this.props.history.push('/money_Perform')
                //   this.props.history.push('/demo_Info')

            }

            render() {
                const {schoolIndex, canEdit, reportCanEdit, timesInfo} = this.state;
                const formatString = "YYYY-MM-DD"
                const beginTime = timesInfo?.beginTime ? moment(timesInfo.beginTime).format(formatString) : ""
                const endTime = timesInfo?.endTime ? moment(timesInfo.endTime).format(formatString) : ""
                // console.log('schoolIndex', schoolIndex)
                console.log("======> ", timesInfo, beginTime, endTime)
                // alert(!reportCanEdit)
                return (
                    <div className="MissionStatement_main">
                        <Heder/>
                        <div className="demoContent">

                            <div onClick={this.toTarget}>
                                <img className={'demo-img'} src={require('../../assets/images/demo-content.png')}
                                     alt={''}/>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
