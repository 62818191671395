// 人才培养

import { post } from '../../until/axios_instans';

// 人才培养整页接口
export function cultivationTalentsList(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/cultivationTalentsList', data);
}
// 新增班级
export function addTrainClass(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/addTrainClass', data);
}
// 修改班级
export function updateTrainClass(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/updateTrainClass', data);
}
// 获取班级
export function getTrainClass(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/getTrainClass', data);
}
// 删除班级
export function deleteTrainClass(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/deleteTrainClass', data);
}
// 导入班级
export function importTrainClass(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/importTrainClass', data);
}
// 新增方案
export function addTrainPlan(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/addTrainPlan', data);
}
// 修改方案
export function updateTrainPlan(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/updateTrainPlan', data);
}
// 获取方案
export function getTrainPlan(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/getTrainPlan', data);
}
// 删除方案
export function deleteTrainPlan(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/deleteTrainPlan', data);
}
// 导入方案
export function importTrainPlan(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/importTrainPlan', data);
}

// 2.1.6 获取技能证书
export function getStudentCertificate(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/getStudentCertificate', data);
}

// 2.1.6 新增技能证书
export function addStudentCertificate(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/addStudentCertificate', data);
}
// 2.1.6 删除技能证书
export function deleteStudentCertificate(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/deleteStudentCertificate', data);
}
// 2.1.6 修改技能证书
export function updateStudentCertificate(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/updateStudentCertificate', data);
}

// 2.1.7 获取学生大赛
export function getMegagame(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/getMegagame', data);
}
// 2.1.7 新增学生大赛
export function addMegagame(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/addMegagame', data);
}
// 2.1.7 删除学生大赛
export function deleteMegagame(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/deleteMegagame', data);
}
// 2.1.7 修改学生大赛
export function updateMegagame(data) {
    return post(window.apiShixun+'/api/v1/cultivationTalents/updateMegagame', data);
}

 // 下载解决难题数据
 export function downloadBusTemplate(data) {
    return window.apiShixun + '/api/v1/import/downloadBusTemplate?templateNo='+ data;
}
 // 导入
 export function excelBusData(data) {
    return post(window.apiShixun + '/api/v1/import/excelBusData', data);
}

// // 2.1.2 新增班级
//  export function addTrainClass(data) {
//     return post(window.apiShixun + '/api/v1/cultivationTalents/addTrainClass', data);
// }
// // 2.1.2 删除班级
//  export function deleteTrainClass(data) {
//     return post(window.apiShixun + '/api/v1/cultivationTalents/deleteTrainClass', data);
// }
// // 2.1.2 修改班级
//  export function updateTrainClass(data) {
//     return post(window.apiShixun + '/api/v1/cultivationTalents/updateTrainClass', data);
// }