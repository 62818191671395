// 查看佐证材料
import React from "react";
import {withRouter} from 'react-router-dom'
import {Form, Table} from 'antd';
import "./index.scss";
import moment from "moment";
import Cookies from "js-cookie";
import FileLook from "../../../page/components/file_look";
import {BaseUrl} from "../../../config/common";

class FileListComponent extends React.Component {
    state = {
        pageNum: 1,//页数
        // 显示浏览控件
        showResource: false,
        resourceId: '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    };

    componentDidMount() {

    }

    delete = (index) => {

    }

    see = (record) => {
        let openUrl = ""
        if (record.fileId !== undefined) {
            openUrl = BaseUrl + record.fileId;
        } else {
            openUrl = BaseUrl + record.filePath + '/' + record.fileName;
        }
        this.props.history.push({pathname:"/file_Book", query :{ openUrl: openUrl}} )
    }
    getTrail = (fileName) => {
        if (fileName) {
            const strArr = fileName.split('.');
            return strArr[strArr.length - 1]
        }
        return ""
    }
    dismiss = () => {
        this.setState({
            showResource: false
        })
    }
    handlerDelete = (index) => {

    }

    render() {
        const {resourceList, showResource, resourceId} = this.state
        const {defaultList} = this.props

        const columns = [
            {
                title: '资源名称',
                dataIndex: 'originFn',
                key: 'originFn',
                width: 200,
            },
            {
                title: '格式',
                dataIndex: 'originFn',
                key: 'originFn',
                width: 100,
                render: (e) => <div>
                    {this.getTrail(e)}
                </div>
            },
            {
                title: '大小',
                dataIndex: 'fileSize',
                key: 'fileSize',
                width: 100,
                render: (e) => <div>
                    {e >= 1048576 ? (e / 1048576).toFixed(2) + "MB" : (e / 1024).toFixed(2) + "K"}
                </div>
            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 100,
                render: (e) => <div>
                    {moment(e).format('YYYY-MM-DD HH:mm')}
                </div>
            },
            {
                title: '操作',
                key: 'operation',
                width: 150,
                render: (text, record, index) => (
                    <div className='operating'>
                        <div className="li">
                            <span onClick={() => this.see(record)}>查看</span>
                            {this.props.editEnable ? <span onClick={() => this.props.onDelete(index)}>删除</span> : ""}
                        </div>
                    </div>
                )
            },
        ]
        return (
            <div className="Addevidence_cont">
                <span className="span" style={{paddingTop: '0'}}>共计 {defaultList.length ?? 0}个</span>
                <div className="table_bot">
                    <Table columns={columns} dataSource={defaultList} pagination={false}
                           locale={{
                               emptyText: <>
                                   <div className="Empty-content">
                                       <div className="text">
                                           <img
                                               src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div>
                               </>
                           }}
                    />
                </div>
                {/*查看资料*/}
                {/*<FileLook openUrl={}/>*/}
                <FileLook openUrl={this.state.openUrl} isShow={showResource} dismiss={this.dismiss}/>
            </div>

        );
    }
}

FileListComponent.defaultProps = {
    defaultList: [],
    editEnable: false,
    onDelete: (index) => {

    }
}
FileListComponent = withRouter( Form.create()(FileListComponent));
export default FileListComponent;
