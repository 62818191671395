import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

// 预期成效和保障 左边栏
@withRouter
class LeftExpectResult extends React.Component {
    state = {
        processCode: ''
    };
    menuClick = (processCode) => {
        let jumpPageRouter = "/construction_BasicInformation";
        if ("1" === processCode) {
            jumpPageRouter = "/construction_BasicInformation"
        } else if ("2" === processCode) {
            jumpPageRouter = "/project_Situation"
        } else if ("3" === processCode) {
            jumpPageRouter = "/construction_scheme"
        }
        this.props.history.push(jumpPageRouter)
    }


    render() {
        const {processCode} = this.props;
        return (
            <div className="table">
                <ul>
                    <li className={processCode === '1' ? 'active' : ''} onClick={() => this.menuClick('1')}> 预期成效</li>
                    <li className={processCode === '2' ? 'active' : ''} onClick={() => this.menuClick('2')}> 保障措施</li>
                    <li className={processCode === '3' ? 'active' : ''} onClick={() => this.menuClick('3')}> 特色创新</li>
                </ul>
            </div>
       );
    }

}

export default LeftExpectResult;