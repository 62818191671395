import React, { Component } from 'react'
import { Input, Select, Form, Tooltip, Spin, message, Radio, Cascader } from "antd";
import "./index.scss";
import { uploadFile } from "../../api/uploadFile";
import { Upicon, Upiconwc, Upicondele } from "../iconSvg";


class AddTeaStuMaterial extends React.Component {
    state = {
        value: 1,
        data: [],
        majorList: this.props.majorList,
        courseMajor: []
    }

    componentDidMount() {
        let { data } = this.props;
        // console.log('data', data)
        if (data) {
            let courseMajor = []
            courseMajor.push(data ? data.majorOne : '')
            courseMajor.push(data ? data.majorTwo : '')
            courseMajor.push(data ? data.courseMajor : '')
            // console.log('courseMajor', courseMajor)
            // let courseMajor = data?.courseMajor || '';
            let upFile = {
                originName: data ? data.resourceName : '',
                fileId: data ? data.fileId : '',
                fileSuffix: data ? data.resourceSuffix : '',
                fileSize: data ? (data.fileSize / 1024).toFixed(2) : ''
            }
            this.setState({
                file: data.fileId ? upFile : null,
                data: data,
                value: data ? Number(data.isBase) : ''
            })
            this.props.form.setFieldsValue({
                courseName: data ? data.courseName : '',
                courseMajor: courseMajor,
                // isBase: data ? Number(data.isBase) : '',
                // partakeStudent: data ? data.partakeStudent : '',
                partakeTeacher: data ? data.partakeTeacher : '',
                belongFaculty: data ? data.belongFaculty : '',
                // fileId: data ? data.fileId : '',
            })
        }
    }


    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName: list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let { file } = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    console.log('upFile', upFile)
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };


    handleCancel = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    onChange = () => {
        this.setState({
            addCertificateModal: false,
        })
    }
    onChangeSR = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({ fileId: null });
        this.setState({
            file: null
        })
    }

    getState = () => {
        return this.state.file
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { file, value, majorList } = this.state;
        const { Option } = Select;
        console.log('渲染的', file)
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };


        const formItemLayout = {
            labelCol: {
                xs: { span: 8 },
                sm: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 16 },
                sm: { span: 16 }
            },
        };
        return (
            <div className='AddTeaStuMaterial_cont'>
                <Form {...formItemLayout}>
                    <Form.Item label="参与开发教师姓名"  >
                        {
                            getFieldDecorator('partakeTeacher', {
                                rules: [
                                    {
                                        required: true,
                                        message: '参与开发教师姓名不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入参与开发教师姓名' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`partakeTeacher`) ? this.props.form.getFieldValue(`partakeTeacher`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="所属院系">
                        {
                            getFieldDecorator('belongFaculty', {
                                rules: [
                                    {
                                        required: true,
                                        message: '所属院系不能为空'
                                    },
                                ]
                            })(
                                // <Cascader options={majorList}/>
                                <Input placeholder='请输入所属院系' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`belongFaculty`) ? this.props.form.getFieldValue(`belongFaculty`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    {/* <Form.Item label="所属专业" >
                        {
                            getFieldDecorator('courseMajor', {
                                rules: [
                                    {
                                        required: true,
                                        message: '所属专业不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入所属专业'   />
                            )
                        }
                    </Form.Item> */}
                    <Form.Item label="所属专业">
                        {
                            getFieldDecorator('courseMajor', {
                                rules: [
                                    {
                                        required: true,
                                        message: '所属专业不能为空'
                                    },
                                ]
                            })(
                                <Cascader options={majorList} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="虚拟仿真实训资源名称" >
                        {
                            getFieldDecorator('courseName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '虚拟仿真实训资源名称不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入虚拟仿真实训资源名称' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`courseName`) ? this.props.form.getFieldValue(`courseName`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    {/* <Form.Item className={'li_formup input-wrap'} label={"佐证材料"}>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit"> */}
                    {/*上传中*/}
                    {/*{file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}*/}
                    {/* {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null} */}
                    {/*已完成*/}
                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                    {/* </ul>
                            </div>
                        )}
                    </Form.Item> */}
                </Form>
            </div>
        )
    }
}


AddTeaStuMaterial = Form.create()(AddTeaStuMaterial);
export default AddTeaStuMaterial;