// 添加特色创新
import React, {Component} from 'react';
import {Button, Form, Input, message, Tooltip, Spin} from 'antd';
import moment from "moment";
import {Deleticon, EdioIcon, UpIcon, Upiconwc, Upicondele} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import "../uploadFile/index.scss";
import {uploadFile} from '../../../../api/uploadFile';
import {isNotEmpty} from '../../../../until/isEmpty';

const {TextArea} = Input;

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

@withRouter
class UploadFileComponent extends Component {
    state = {
        name: '',
        // 上传后的图片信息 仅仅包含,id和name, 与服务器的数据一致
        fileModelList: [],
        item: this.props.item,

    };

    componentDidMount() {


    }

    UNSAFE_componentWillReceiveProps(nextProps, Any) {
        this.setState({
            fileModelList: nextProps.props_fileModelList,
        })
    }


    //section 删除
    deleteFileMethod(self, item) {
        console.log("=====删除")
        const {fileModelList} = this.state;
        const id = item.id
        if (isNotEmpty(id)) {
            let result = fileModelList.filter(data=>data.id !== id)
            this.setState({
                fileModelList: result
            }, () => {
                this.props.uploadResult(result)
            })
        }
    }

    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc/docx、xls/xlsx、ppt/pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        const {fileModelList, fileNumber} = this.state;
        if ((fileModelList.length + list.length + fileNumber) < 20) {
            message.error("上传文件最多20个")
            return;
        }
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        this.setState({
            file: list.length > 0 ? list[0] : null
        })
        let flag = false;
        // list.forEach((k) => {
        //     const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
        //     const fileNameLen = k.name.length; // 取到文件名长度
        //     const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
        //     if (!this.checkFileType(fileType)) {
        //         message.error("上传的资源格式不符")
        //         flag = true
        //         return;
        //     }
        //     let ymd = moment(new Date()).format('YYYY-MM-DD');
        //     ymd = ymd.replace(/-/g, '');
        //     let fileId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
        //     fileId = `zjyd-space-front-geren/${ymd}/${fileId}`
        //     k["fileId"] = fileId
        //     if (!tableData.find((v) => v.resourceName === k.name)) {
        //         tableData.push({
        //             fileId: fileId,
        //             resourceName: k.name,
        //             resourceFormat: fileType,
        //             resourceSize: (k.size / 1024).toFixed(2),
        //             operationTime: transTime(k.lastModifiedDate),
        //             createTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        //             plan: 0,
        //             lastModified: k.lastModified
        //         });
        //     }
        // });

        if (flag) {
            return;
        }
        this.setState({
            tableData: fileModelList,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
        this.setState({
            inputValue: ""
        })
    };

    //上传文件
    uploadFile = async (v) => {
        const {fileType} = this.state
        if (!v) return;
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {

                console.log("上传结果:", res)
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }

                    // console.log('res----------------', res)
                    this.getResult(res);

                    this.clearFile(upFile);
                    reslove(1);
                }
            })
        });
    };

    //上传完成
    clearFile = (file) => {
        if (!file) return;
        //小于100kb的文件不支持分片上传，等返回上传成功结果后直接调整进度未100%
        let {fileModelList} = this.state;
        // console.log(tableData);
        // console.log("888888*********")
        let item = fileModelList.find((v) => v.originName === file.name);
        // item.plan = 1;
        this.setState({fileModelList: fileModelList});
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        delete opt[file.name];
        localStorage.setItem("uploadList", JSON.stringify(opt));
        document.getElementById('file').value = null;
        // console.log("上传完成");
        console.log("tableData", this.state.fileModelList)
    };
    //上传完成获取返回结果过
    getResult = (val) => {
        console.log('res------------', val)
        let {fileModelList} = this.state;
        // console.log("tableData",tableData)
        // tableData.forEach(item => {
        //     if(item.originName === val.originFileName){
        //         item.originName = val.originFileName
        //         item.fileId = val.fpName
        //         item.fileSuffix = val.mediaType
        //         item.coursewareId = val.id
        //         item.fileSize = (val.fileSize / 1024).toFixed(2)
        //     }
        // })
        fileModelList.push({
            id: val.id,
            originFn: val.originFileName,
        })
        this.setState({fileModelList: fileModelList}, () => {
            console.log('上传后的数据：', this.state.fileModelList)

            if (undefined !== this.props.uploadResult) {
                this.props.uploadResult(fileModelList)
            }
        })
    };

    edit = () => {
        console.log("=====编辑")
        const edit = !this.state.edit
        this.state({
            edit: edit
        })
    }

    isEdit = () => {
        const {item} = this.state;
        if (item != null && isNotEmpty(item.id)) {
            return false
        } else {
            return true
        }
        return this.state.edit
    }


    render() {
        const {getFieldDecorator} = this.props.form
        const {name, item, fileModelList} = this.state;
        const {formData, ifComplete, ifEdit, num, subName, step} = this.props

        return (
            <div className="Comment-upload-addForm">

                <Form>
                    <div className="bottom_tit">
                        <div className="span_right">
                            <label htmlFor="file" className="inputlabelBox">
                                <span disabled={this.isEdit}><UpIcon/>添加附件</span>
                            </label>
                            <input
                                type="file"
                                accept="image"
                                onChange={this.multipartUpload}
                                name="myfile"
                                id="file"
                                style={{display: "none"}}
                            />

                            {
                                (fileModelList ? fileModelList : []).map(function (item) {

                                    return (item ?
                                        <Tooltip className="tooltip" placement="topLeft" title={item.originFn}>
                                            <li className="title_de">
                                                {item.id ? <Upiconwc/> : <Spin/>}
                                                <span className="tie_span">{item.originFn}</span>
                                                <i className="Upicona"
                                                   onClick={() => this.deleteFileMethod(this, item)}
                                                   key={item.id}
                                                  >
                                                    <Upicondele/>
                                                </i>
                                            </li>
                                        </Tooltip> : null)
                                }, this)

                            }
                        </div>

                    </div>
                </Form>
            </div>
        );
    }
}

const UploadFileComment = Form.create()(UploadFileComponent);
export default UploadFileComment;

