// 导航
import React, {Component} from 'react';
import './index.scss';
import '../../reset_style/index.less';
import {Button, Col, Form, Input, message, Modal, Row} from 'antd';
import {Logo, WenxinTip} from '../../components/iconSvg'
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import {updatePassword} from '../../api/login';
import RenderButton from '../../components/renderButton/index'
import md5 from "js-md5"
import {clearAllCookie} from "../../until/cookie";

@withRouter
class Header extends Component {
    state = {
        navName: "",
        ispassword: false,
        userInfo: Cookies.get("userInfo") ? JSON.parse(decodeURI(Cookies.get("userInfo"))) : {},
        saveStatus: true
    };

    componentDidMount() {
        this.setState({
            navName: this.props.navName,
        });
    }

    //确定退出登录
    handleLogout = () => {

        Modal.confirm({
            className: 'content_delete',
            title: '温馨提示',
            centered: true,
            content: <div className="bottom_dele">
                <WenxinTip/>确定要退出登录吗？
            </div>,
            cancelText: '取消',
            okText: '确定',
            onOk: async () => {
                this.removeCookies();
                this.props.history.replace("/login")

            }
        })
    };

    // todo 联系人
    handleContact = () => {
        Modal.info({
            className: 'content_delete',
            title: '联系我们',
            centered: true,
            content: <div id={'contact'}>
                <div>
                    北京云圈科技有限公司
                </div>
                <div>
                    联系电话：
                </div>
                {/*<div>*/}
                {/*    010-62514016 ；13011066375*/}
                {/*</div>*/}
                <div style={{marginTop: '10px'}}>
                    技术支持：
                </div>
                <div>
                    15010476015
                </div>
            </div>,
            footer: null
        })
    }

    removeCookies() {
        clearAllCookie()
    }

    toHomePage = () => {
        this.props.history.push("/index")
    }
    // 修改密码
    closeCts = () => {
        this.setState({ispassword: false})
    }
    handPassword = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (values.newPassword.toLowerCase() !== values.againPassword.toLowerCase()) {
                    message.destroy();
                    return message.error('新密码二次输入不一致');
                }
                let {userInfo} = this.state;
                if (userInfo) {
                    updatePassword(
                        {
                            id: userInfo.id,
                            oldPassword: md5(values.oldPassword.toLowerCase()),
                            newPassword: md5(values.newPassword.toLowerCase()),
                            againPassword: md5(values.againPassword.toLowerCase())
                        }
                    ).then(res => {
                        if (res) {
                            message.success('修改成功');
                            setTimeout(() => {
                                this.props.history.replace("/login")
                            }, 1000);
                        }
                    });
                }
            }
        })
    }
    toHomePagetwo = () => {
        this.props.history.push('/effectiveness_Allowed')
    }

    toIndex = () => {
        this.props.history.replace('/mission_statement')

        //
        //
        // let {userInfo} = this.state
        // if (userInfo.roleType === 1) {
        //     // this.props.history.replace('/index')
        //     this.props.history.replace('/effectiveness_Allowed')
        // } else if (userInfo.roleType === 2) {
        //     this.props.history.replace('/effectiveness_Allowed')
        // } else if (userInfo.roleType === 3) {
        //     this.props.history.replace('/mission_statement')
        // }
    }

    render() {
        const {ispassword, navName, userInfo} = this.state;
        const {getFieldDecorator} = this.props.form;
        return (
            <header className="header_vs">
                <div className="nav-top">
            <span className="logo" onClick={this.toIndex}>
                <Logo/>
            </span>
                    <div className="right_ul">
                        <div className="nav">
                            <div className="tohome">
                                <RenderButton id='A'>
                    {/*<span*/}
                    {/*    className={`common-header-item ${navName === "annualyears" || navName === "annualyearstwo" || navName === "index" ? "common-active" : ""}`}*/}
                    {/*    onClick={this.toHomePage}>*/}
                    {/*  任务书管理*/}
                    {/*</span>*/}
                                </RenderButton>
                                <RenderButton id='I'>
                  {/*<span*/}
                  {/*    className={`common-header-item ${navName === "effectiveness_Allowed" || navName === "effectiveness_Allowed" ? "common-active" : ""}`}*/}
                  {/*    onClick={this.toHomePagetwo}>*/}
                  {/*    效能填报管理*/}
                  {/*  </span>*/}
                                </RenderButton>
                            </div>
                        </div>
                        <div className="right">
                            {/*<Button className="antd-button"*/}
                            {/*        onClick={() => this.setState({ispassword: true})}>修改密码</Button>*/}
                            <span className="text">
                  {userInfo.portraitId ?
                      <span className="img"><img className='userImage' src={window.$$uploadRc(userInfo.portraitId)}
                                                 alt=""/></span> :
                      <span className="img"><img src={require('../../assets/images/touxiangmr.png')} alt={''}/></span>
                  }
                                {"银川市第一幼儿园"}
              </span>
                            {/*<span className="right-log" onClick={this.handleContact}>*/}
                            {/*   <span className="icon" style={{width: '0px', marginLeft: '15px'}}/>*/}
                            {/*    <i>联系我们</i>*/}
                            {/*</span>*/}
                            <span className="right-log" onClick={this.handleLogout}>
                 <span className="icon" style={{width: '0px', marginLeft: '15px'}}/>
                  <i>退出登录</i>
              </span>
                        </div>
                    </div>
                </div>
                <Modal
                    wrapClassName='Modal-change-password'
                    title={
                        <div>
                            修改密码
                        </div>
                    }
                    centered={true}
                    visible={ispassword}
                    okText="保存"
                    closable={false}
                    footer={
                        <div className='modal_btn_j'>
                            <Button onClick={() => this.closeCts()}>关闭</Button>
                            <Button type='primary' onClick={() => this.handPassword()}>确定</Button>
                        </div>
                    }>

                    <div className="contacts-form">
                        <Row>
                            <Form>
                                <Col span={24} className="col">
                                    <span>旧密码</span>
                                    <Form.Item>
                                        {getFieldDecorator('oldPassword', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写旧密码`,
                                                },
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input className="input" minLength={6} maxLength={10}/>)}
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="col-span">
                                    <span>新密码</span>
                                    <Form.Item>
                                        {getFieldDecorator('newPassword', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写新密码`,
                                                },
                                                {
                                                    validator: this.validatePassword
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input.Password className="input" minLength={6} maxLength={10}/>)}
                                    </Form.Item>
                                    {/* <span>6-10位英文字母和数字</span> */}
                                </Col>
                                <Col span={24} className="col-span">
                                    <span>新密码</span>
                                    <Form.Item>
                                        {getFieldDecorator('againPassword', {
                                            initialValue: '',
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写新密码`,
                                                },
                                                {
                                                    validator: this.validatePassword
                                                }
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(<Input.Password className="input" minLength={6} maxLength={10}/>)}
                                    </Form.Item>
                                    {/* <span>6-10位英文字母和数字</span> */}
                                </Col>
                            </Form>
                        </Row>
                    </div>
                </Modal>
            </header>
        );
    }
}

const HeaderForm = Form.create()(Header);
export default HeaderForm;
