// 效能填报左侧
import React, {Component} from 'react';
import {selectTarget, targetMenus, validData} from "@/api/target";
import {withRouter} from "react-router-dom";
import "./index.scss";
import Cookies from "js-cookie";
import clns from "classnames"
import {message} from 'antd';

import {refreshLsit} from './util'

@withRouter
class XnfxLeftEfficiencyside extends Component {
    state = {
        targetList: [],
        twoLevelTargetId: '',
        selectedMenuItem: null ,// 上次选中的项
        id:this.props.id
    };

    componentDidMount() {
        console.log('componentDidMount')
        if (this.props.onRef) {
            this.props.onRef(this);
        }


        let mainCont = document.getElementById('Fillside_warp')
        let mainContWith = mainCont && mainCont.offsetWidth
        let windowWidth = document.documentElement.clientWidth
        let leftMain = document.getElementById('leftcollect')
        if (leftMain) {
            leftMain.style.left = (windowWidth - mainContWith) / 2
        }

        console.log("mainContWith", (windowWidth - mainContWith) / 2)
        // 左侧跟随屏幕
        window.addEventListener('scroll', this.bindHandleScroll)
        // 左侧跟随屏幕
        // this.targetSynchro()
        this.targetMenus()

        this.setState({
            linkPath: this.props.location.pathname,
            id:this.props.id

        })
    }

    componentWillReceiveProps(nextProps) {
        let targetList = this.state.targetList;
     

        let id = nextProps.id;


        const flatten = (list) => {
            let result = [];
            list.forEach(item => {
                if (item.isLast) {
                    result.push(item);
                }
                if (item.children) {
                    result = result.concat(flatten(item.children))
                }
            })
            return result;
        }

        let selectedItem = flatten(targetList).find(item => item.id === id)
        targetList = refreshLsit(targetList, selectedItem)


        this.setState({
            targetList,
            selectedMenuItem: selectedItem
        })
    }

    targetMenus = (event) => {
        const {location} = this.props
        const id = this.props.id
        let data = {
            schoolEduCode: this.state.schoolEduCode,
        }

        let menusData = [{
            "id": 1,
            "pid": 0,
            "menuName": "幼儿园岗位工作分工",
            path: '',
            "children": [{
                "id": 3,
                "pid": 1,
                "target":"1.1",
                "menuName": "书记、园长工作流程",
                path: '/levelOne',

            },{
                "id": 4,
                "pid": 1,
                "target":"1.2",
                "menuName": "教学副园长工作流程",
                path: '/levelTwo',

            },{
                "id": 5,
                "pid": 1,
                "target":"1.3",
                "menuName": "后勤副园长工作流程",
                path: 'money_Perform',

            },{
                "id": 6,
                "pid": 1,
                "target":"1.4",
                "menuName": "分园架构图",
                path: 'money_Perform',

            },
                {
                    "id": 7,
                    "pid": 1,
                    "target":"1.5",
                    "menuName": "办公室岗位结构图",
                    path: 'money_Perform',

                },
                {
                    "id": 8,
                    "pid": 1,
                    "target":"1.6",
                    "menuName": "办公室主任工作流程",
                    path: 'money_Perform',

                },{
                    "target":"1.7",
                    "id": 9,
                    "pid": 1,
                    "menuName": "保教主管工作流程",
                    path: 'money_Perform',

                },{
                    "target":"1.8",
                    "id": 10,
                    "pid": 1,
                    "menuName": "卫生保健主管工作流程",
                    path: 'money_Perform',

                },{
                    "target":"1.9",
                    "id": 11,
                    "pid": 1,
                    "menuName": "膳食主管工作流程",
                    path: 'money_Perform',

                },{
                    "target":"1.10",
                    "id": 12,
                    "pid": 1,
                    "menuName": "信息中心工作流程",
                    path: 'money_Perform',

                },
                {
                    "target":"1.11",
                    "id": 13,
                    "pid": 1,
                    "menuName": "综治主管工作流程",
                    path: 'money_Perform',

                },
                {
                    "target":"1.12",
                    "id": 14,
                    "pid": 1,
                    "menuName": "出纳工作流程",
                    path: 'money_Perform',

                },
            ],
            "displayOrder": 1
        }, {
            "id": 2,
            "pid": 0,
            "menuName": "幼儿园专项工作流程",
            path: '',
            "children": [{
                "target":"2.1",
                "id": 30,
                "pid": 2,
                "menuName": "党务会议召开工作流程",
                path: 'platform_talenttraining',
            },
                {
                    "target":"2.2",
                    "id": 31,
                    "pid": 2,
                    "menuName": "党务文件落实工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.3",
                    "id": 32,
                    "pid": 2,
                    "menuName": "党员活动组织工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.4",
                    "id": 33,
                    "pid": 2,
                    "menuName": "发展党员工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.5",
                    "id": 34,
                    "pid": 2,
                    "menuName": "党组织关系转入工作流程",
                    path: 'platform_talenttraining',

                }, {
                    "target":"2.6",
                    "id": 35,
                    "pid": 2,
                    "menuName": "党组织关系转出工作流程",
                    path: 'platform_talenttraining',

                }, {
                    "target":"2.7",
                    "id": 36,
                    "pid": 2,
                    "menuName": "党费收缴工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.8",
                    "id": 37,
                    "pid": 2,
                    "menuName": "党组织换届工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.9",
                    "id": 38,
                    "pid": 2,
                    "menuName": "岗位竞聘流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.10",
                    "id": 39,
                    "pid": 2,
                    "menuName": "招生工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.11",
                    "id": 40,
                    "pid": 2,
                    "menuName": "教师节表彰流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.12",
                    "id": 41,
                    "pid": 2,
                    "menuName": "处理投诉流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.13",
                    "id": 42,
                    "pid": 2,
                    "menuName": "职称评审工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.14",
                    "id": 43,
                    "pid": 2,
                    "menuName": "事业单位出入境管理流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.15",
                    "id": 44,
                    "pid": 2,
                    "menuName": "考录录用手续办理工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.16",
                    "id": 45,
                    "pid": 2,
                    "menuName": "辞职手续办理工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.17",
                    "id": 46,
                    "pid": 2,
                    "menuName": "招聘人员退休手续办理流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.18",
                    "id": 47,
                    "pid": 2,
                    "menuName": "妇女休产假手续办理工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.19",
                    "id": 48,
                    "pid": 2,
                    "menuName": "住房档案建档和补贴核算流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.20",
                    "id": 49,
                    "pid": 2,
                    "menuName": "工伤速报及鉴定流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.21",
                    "id": 50,
                    "pid": 2,
                    "menuName": "年度考核报表流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.22",
                    "id": 51,
                    "pid": 2,
                    "menuName": "社保增减流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.23",
                    "id": 52,
                    "pid": 2,
                    "menuName": "退休手续办理工作流程",
                    path: 'platform_talenttraining',

                }, {
                    "target":"2.24",
                    "id": 53,
                    "pid": 2,
                    "menuName": "校方责任险办理工作流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.25",
                    "id": 54,
                    "pid": 2,
                    "menuName": "医保增减和异地报销流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.26",
                    "id": 55,
                    "pid": 2,
                    "menuName": "人事档案整理/分类/鉴别流程",
                    path: 'platform_talenttraining',

                },
                {
                    "target":"2.27",
                    "id": 56,
                    "pid": 2,
                    "menuName": "岗位聘任工作流程",
                    path: 'platform_talenttraining',

                },
            ],
            "displayOrder": 2
        }]

       

        let selectedItem = {}
        const renderMenueData = (arr, rank = 0, parentId = []) => {
            return arr.map(item => {
                item.rank = rank;
                item.parentIds = parentId.concat(item.pid);
                item.isLast = item.children && item.children.length > 0 ? false : true;
                item.locked = item.rank === 0;// 锁定 不可关闭

                if (item.children) {
                    item.children = renderMenueData(item.children, item.rank + 1, item.parentIds)
                }
                if (item.id === id) {
                    selectedItem = item;
                }
                return item;
            })
        }


        /***
         *  当前页 》 所属节点 》 。。。。》根节点
         *  点击当前页 所在节点 则 return
         *  否则 判断点击的页面节点是不是 和当前页一个
         *
         *
         */

        let targetList = renderMenueData(menusData);
        targetList = refreshLsit(targetList, selectedItem)
        console.log(targetList)

        this.setState({
            targetList,
            currentPage: selectedItem,
            selectedMenuItem: selectedItem
        })
    }
    selectTarget = () => {
        let {schoolInfo} = this.state
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            year: schoolInfo && schoolInfo.writeYear ? schoolInfo.writeYear : new Date().getFullYear()
        }
       
    }
    toTarget = (path, id) => {
        const lastTargetId = this.state.twoLevelTargetId
        if (lastTargetId) {
            validData(lastTargetId);
        }
        // todo 传上面的id给后台让后台进行校验
        Cookies.set('twoLevelTargetId', id);
        this.setState({
            twoLevelTargetId: id,
        })
        this.props.history.push(path);
    }
    // 左侧跟随屏幕
    bindHandleScroll = (event) => {
        let scrollTop = event.srcElement ? event.srcElement.documentElement.scrollTop : false || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)

        if (scrollTop > 160) {
            document.getElementById("leftcollect") && document.getElementById("leftcollect").setAttribute("style", "top:100px;position:fixed;width:234px;");
        } else {
            document.getElementById("leftcollect") && document.getElementById("leftcollect").setAttribute("style", "top:0;position:relative;");
        }
    }
    //返回上传File
    getTwoLevelTargetId = () => {
        return this.state.twoLevelTargetId
    };

    renderMenu = (list, rank = 0, actived) => {
        const {id } = this.props;
        return <ul data-level={rank} style={{display: actived ? 'block' : 'none'}}>

            {list.map(item => {
                if (item.children) {

                    return [<li className={clns("nav", {
                        "firstNav": item.rank == 0,
                        "lastNav": item.isLast,
                        "normalNav": item.rank != 0 && !item.isLast,
                        "actived": item.actived
                    })}
                                onClick={this.menuItemClick.bind(this, item)}>{item.menuName}</li>, this.renderMenu(item.children, item.rank + 1, item.actived || item.locked)]
                }
                return (<li className={clns("nav", {
                    "firstNav": item.rank == 0,
                    "lastNav": item.isLast,
                    "normalNav": item.rank != 0 && !item.isLast,
                    "current": item.isCurrent == true
                })} onClick={this.menuItemClick.bind(this, item)}> <h3 className={item.id === id ? 'active' : ''} style ={{fontSize:"12px"}}>{item.menuName}</h3></li>)
            })}
        </ul>

    }
    menuItemClick = item => {
        // console.log(item)

        // let arr1 = [item]
        // let arr2 = [{...item}]
        // console.log(arr1[0] == arr2[0])
        if (item.isLast) {
            // 页面刷新重新组装menuList的结构
            if (item.path) {
              //  this.props.history.push(item.path)
              console.log("======="+item.id)
               
              if(item.id===5){
                this.props.history.push({pathname:"/levelOne/"+item.id, state :{ id: item.id,}} )
              }else if(item.id===31){
                this.props.history.push({pathname:"/levelTwo/"+item.id, state :{ id: item.id}} )
              }else{
                this.props.history.push({pathname:"/demo_Info/:"+item.id, state :{ id: item.id}} )
              }
              
            } else {
                message.info('没有找到跳转路径')
            }
        } else if (!item.locked) {
            //不是第一级
            //不是页面刷新的则 清空当前选其他选项的选中状态
            let {targetList, id} = this.state
           if (item.id != id) {
                //  不是点击同一展开父级
                item.actived = !item.actived;
                item.showChild = !item.showChild;
                // 清空其他节点的状态

            }

            const hiddenOthers = (node, tree) => {

                return tree.map(item => {
                    console.log(item, node);
                    if (item.id == node.id || item.parentIds.includes(node.id)) {
                        // 当前节点或当前节点的子节点
                        console.log('当前节点或当前节点的子节点', item)
                    } else {
                        item.actived = false;
                        item.showChild = false;
                        if (item.children) {
                            item.children = hiddenOthers(node, item.children)
                        }
                    }
                    return item;
                })

            }


            targetList = hiddenOthers(item, targetList)
            this.setState({
                targetList
            })
        }


    }

    render() {
        const {id } = this.props;
        const {targetList} = this.state;
        console.log("========"+id)
        return (
            <div className="Double-LeftEfficiencyside menulist-wrapper">

                {
                    targetList ? this.renderMenu(targetList, 0, true) : ''
                }
                {false && targetList ? targetList.map((item, index) => (
                    <ul className="ul_h6" key={index}>
                        <div className="title_h3">
                            <h3 className={item.id === id ? 'active' : ''}>{item.targetName}</h3>
                        </div>
                        {item ? item.targetDetails.map((itemTwo, indexTwo) => (
                            <li key={indexTwo} className={itemTwo.id === id ? 'active' : ''}
                                onClick={() => this.toTarget(itemTwo.path, itemTwo.id)}>
                                {/*完成  类名active  当前位置 on  默认没有类名*/}
                                {itemTwo.id === id ? <i className="on"/> : itemTwo.status === 1 ?
                                    // 如果要有绿色,则右边的设置为active即可
                                    <i className=""/> : <i className=""/>}
                                <span>{itemTwo.targetName}</span>
                            </li>
                        )) : ""}
                    </ul>
                )) : ""}
            </div>
        );
    }
}

export default XnfxLeftEfficiencyside;

XnfxLeftEfficiencyside.defaultProps = {
    targetSynchroDone: () => {

    }
}
