import React, {Component} from 'react'
import {Form, Input, Button, Checkbox, message} from 'antd'
import {withRouter} from 'react-router-dom'
import './index.scss'
import FileLook from '../components/file_look'
import { BaseUrl,xdocUrl } from '../../config/common'
class FileBook extends Component {

    state = {
        isShow: true,
        openUrl: this.props.location.query.openUrl
    };
    dismiss=()=>{
        this.props.history.goBack()
    }
    render() {
        const {isShow,openUrl} = this.state
        return (
            <div>
                <FileLook openUrl={openUrl} isShow={isShow}   dismiss={this.dismiss}></FileLook>
            </div>
        )
    }
}


export default withRouter(Form.create()(FileBook))
