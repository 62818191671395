// 效能填报左侧
import React, {Component} from 'react';
import {selectTarget, targetSynchro, validData} from "../../../api/target";
import {withRouter} from "react-router-dom";
import "./index.scss";
import Cookies from "js-cookie";

@withRouter
class LeftEfficiencyside extends Component {
    state = {
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        targetList: [],
        linkPath: '',  //左侧高亮
        twoLevelTargetId: '',
    };

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        let mainCont = document.getElementById('Fillside_warp')
        let mainContWith = mainCont && mainCont.offsetWidth
        let windowWidth = document.documentElement.clientWidth
        let leftMain = document.getElementById('leftcollect')
        if (leftMain) {
            leftMain.style.left = (windowWidth - mainContWith) / 2
        }

        console.log("mainContWith", (windowWidth - mainContWith) / 2)
        // 左侧跟随屏幕
        window.addEventListener('scroll', this.bindHandleScroll)
        // 左侧跟随屏幕
        this.targetSynchro()

        this.setState({
            linkPath: this.props.location.pathname.substr(1)
        })
    }

    targetSynchro = (event) => {
        let data = {
            schoolEduCode: this.state.schoolEduCode,
        }
        targetSynchro(data).then((res) => {
            this.selectTarget()
            this.props.targetSynchroDone()
        });
    }
    selectTarget = () => {
        let {schoolInfo} = this.state
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            year: schoolInfo && schoolInfo.writeYear ? schoolInfo.writeYear : new Date().getFullYear()
        }
        selectTarget(data).then((res) => {
            const targetIdMap = {}
            // 二级id列表map
            const secondTargetListMap = {}
            if (res && res.length > 0) {
                // 第一层
                res.forEach(res => {
                    // 第二层,地址为键,id为值,存到cook
                    res.targetDetails.forEach(res => {
                        // 存1级id
                        targetIdMap[res.path] = res.id
                        // 存二级id列表
                        if (res.targetDetails) {
                            secondTargetListMap[res.path] = res.targetDetails.map(res => res.id);
                        }
                    })
                })
                Cookies.set("targetIdMap", targetIdMap);
                Cookies.set("secondTargetListMap", secondTargetListMap);

            }

            this.setState({
                targetList: res ? res : [],
                twoLevelTargetId: res ? res[0].targetDetails[0].id : '',
            });
        });
    }
    toTarget = (path, id) => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }

        Cookies.set('twoLevelTargetId', id);
        this.setState({
            linkPath: path,
            twoLevelTargetId: id,
        })
        // if (path === "international_Exchange") {
        //     this.props.history.push("self_Target")
        //     return;
        // }
        this.props.history.push(path);
    }
    // 左侧跟随屏幕
    bindHandleScroll = (event) => {
        let scrollTop = event.srcElement ? event.srcElement.documentElement.scrollTop : false || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)

        if (scrollTop > 160) {
            document.getElementById("leftcollect") && document.getElementById("leftcollect").setAttribute("style", "top:100px;position:fixed;");
        } else {
            document.getElementById("leftcollect") && document.getElementById("leftcollect").setAttribute("style", "top:0;position:relative;");
        }
    }
    //返回上传File
    getTwoLevelTargetId = () => {
        return this.state.twoLevelTargetId
    };

    render() {
        const {} = this.props;
        const {targetList, linkPath} = this.state;
        return (
            <div className="Double-LeftEfficiencyside">
                {targetList ? targetList.map((item, index) => (
                    <ul className="ul_h6" key={index}>
                        <div className="title_h3">
                            <h3>{item.targetName}</h3>
                        </div>
                        {item ? item.targetDetails.map((itemTwo, indexTwo) => (
                            <li key={indexTwo} className={itemTwo.path === linkPath ? 'active' : ''}
                                onClick={() => this.toTarget(itemTwo.path, itemTwo.id)}>
                                {/*完成  类名active  当前位置 on  默认没有类名*/}
                                {itemTwo.path === linkPath ? <i className="on"/> : itemTwo.status === 1 ?
                                    // 如果要有绿色,则右边的设置为active即可
                                    <i className=""/> : <i className=""/>}
                                <span className={itemTwo.path === linkPath ? 'active' : ''}>{itemTwo.targetName}</span>
                            </li>
                        )) : ""}
                    </ul>
                )) : ""}
            </div>
        );
    }
}

export default LeftEfficiencyside;

LeftEfficiencyside.defaultProps = {
    targetSynchroDone: () => {

    }
}
