import { post } from '../../until/axios_instans';

// 学校信息请求
export function schoolBasicDataPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/schoolBasicDataPreview', data);
}

export function projectSituationPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/projectSituationPreview', data);
}

export function constructionPlanPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/constructionPlanPreview', data);
}

export function subtaskPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/subtaskPreview', data);
}

export function investPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/investPreview', data);
}

export function effectPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/effectPreview', data);
}

export function taskFilePreview(data) {
    return post(window.apiShixun+'/api/v1/preview/taskFilePreview', data);
}






