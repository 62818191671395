/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-09-16 16:58:12
 * @LastEditors: nliu
 * @LastEditTime: 2021-09-16 18:11:02
 */
export  const refreshLsit = (list, selectedValues) => {
  return list.map(item => {
      if(item.id == selectedValues.id) {
          item.isCurrent = true;
          item.actived = true; 
      } else {
        item.isCurrent = false
        item.actived = false; 
      }
      if(item.children) {
          // item.actived = selectedValues.parentIds.includes(item.id);
          // item.showChild= selectedValues.parentIds.includes(item.id);
          // item.children = refreshLsit(item.children, selectedValues)
      }
      return item;
  })

}
