import React, {Component} from 'react'
import {Form, Input, Button, Checkbox, message, Row, Col} from 'antd'
import {withRouter} from 'react-router-dom'
import './index.scss'
class DemoRightContent extends Component {

    state = {
    };
    dismiss=()=>{
          
    }
    render() {
        const {id } = this.props;
        const targetImageKey = './img_id_' + id + '.png'
        const reqSvgs = require.context('../../../../assets/demo/flow_image/', true, /\.png$/);
        console.log(targetImageKey, reqSvgs)
        const image = reqSvgs(targetImageKey);

        let menusData = [{
            "id": 1,
            "pid": 0,
            "menuName": "幼儿园岗位工作分工",
             path: '',
            "children": [{
                "id": 3,
                "pid": 1,
                 "target":"1.1",
                "menuName": "书记、园长工作流程",
                 path: '/levelOne',
                
            },{
                "id": 4,
                "pid": 1,
                "target":"1.2",
                "menuName": "教学副园长工作流程",
                 path: '/levelTwo',
                
            },{
                "id": 5,
                "pid": 1,
                "target":"1.3",
                "menuName": "后勤副园长工作流程",
                 path: 'money_Perform',
                
            },{
                "id": 6,
                "pid": 1,
                "target":"1.4",
                "menuName": "分园架构图",
                 path: 'money_Perform',
                
            },
            {
                "id": 7,
                "pid": 1,
                "target":"1.5",
                "menuName": "办公室岗位结构图",
                 path: 'money_Perform',
                
            },
            {
                "id": 8,
                "pid": 1,
                "target":"1.6",
                "menuName": "办公室主任工作流程",
                 path: 'money_Perform',
                
            },{
                "target":"1.7",
                "id": 9,
                "pid": 1,
                "menuName": "保教主管工作流程",
                 path: 'money_Perform',
                
            },{
                "target":"1.8",
                "id": 10,
                "pid": 1,
                "menuName": "卫生保健主管工作流程",
                 path: 'money_Perform',
                
            },{
                "target":"1.9",
                "id": 11,
                "pid": 1,
                "menuName": "膳食主管工作流程",
                 path: 'money_Perform',
                
            },{
                "target":"1.10",
                "id": 12,
                "pid": 1,
                "menuName": "信息中心工作流程",
                 path: 'money_Perform',
                
            },
            {
                "target":"1.11",
                "id": 13,
                "pid": 1,
                "menuName": "综治主管工作流程",
                 path: 'money_Perform',
                
            },
            {
                "target":"1.12",
                "id": 14,
                "pid": 1,
                "menuName": "出纳工作流程",
                 path: 'money_Perform',
                
            },
            ],
            "displayOrder": 1
        }, {
            "id": 2,
            "pid": 0,
            "menuName": "幼儿园专项工作流程",
            path: '',
            "children": [{
                "target":"2.1",
                "id": 30,
                "pid": 2,
                "menuName": "党务会议召开工作流程",
                path: 'platform_talenttraining', 
             },
             {
                "target":"2.2",
                "id": 31,
                "pid": 2,
                "menuName": "党务文件落实工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.3",
                "id": 32,
                "pid": 2,
                "menuName": "党员活动组织工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.4",
                "id": 33,
                "pid": 2,
                "menuName": "发展党员工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.5",
                "id": 34,
                "pid": 2,
                "menuName": "党组织关系转入工作流程",
                 path: 'platform_talenttraining',
                
             }, {
                "target":"2.6",
                "id": 35,
                "pid": 2,
                "menuName": "党组织关系转出工作流程",
                 path: 'platform_talenttraining',
                
             }, {
                "target":"2.7",
                "id": 36,
                "pid": 2,
                "menuName": "党费收缴工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.8",
                "id": 37,
                "pid": 2,
                "menuName": "党组织换届工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.9",
                "id": 38,
                "pid": 2,
                "menuName": "岗位竞聘流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.10",
                "id": 39,
                "pid": 2,
                "menuName": "招生工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.11",
                "id": 40,
                "pid": 2,
                "menuName": "教师节表彰流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.12",
                "id": 41,
                "pid": 2,
                "menuName": "处理投诉流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.13",
                "id": 42,
                "pid": 2,
                "menuName": "职称评审工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.14",
                "id": 43,
                "pid": 2,
                "menuName": "事业单位出入境管理流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.15",
                "id": 44,
                "pid": 2,
                "menuName": "考录录用手续办理工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.16",
                "id": 45,
                "pid": 2,
                "menuName": "辞职手续办理工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.17",
                "id": 46,
                "pid": 2,
                "menuName": "招聘人员退休手续办理流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.18",
                "id": 47,
                "pid": 2,
                "menuName": "妇女休产假手续办理工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.19",
                "id": 48,
                "pid": 2,
                "menuName": "住房档案建档和补贴核算流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.20",
                "id": 49,
                "pid": 2,
                "menuName": "工伤速报及鉴定流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.21",
                "id": 50,
                "pid": 2,
                "menuName": "年度考核报表流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.22",
                "id": 51,
                "pid": 2,
                "menuName": "社保增减流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.23",
                "id": 52,
                "pid": 2,
                "menuName": "退休手续办理工作流程",
                 path: 'platform_talenttraining',
                
             }, {
                "target":"2.24",
                "id": 53,
                "pid": 2,
                "menuName": "校方责任险办理工作流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.25",
                "id": 54,
                "pid": 2,
                "menuName": "医保增减和异地报销流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.26",
                "id": 55,
                "pid": 2,
                "menuName": "人事档案整理/分类/鉴别流程",
                 path: 'platform_talenttraining',
                
             },
             {
                "target":"2.27",
                "id": 56,
                "pid": 2,
                "menuName": "岗位聘任工作流程",
                 path: 'platform_talenttraining',
                
             },
            ],
            "displayOrder": 2
        }]
        return (
            <div className="Demo_Right_Content">


               <div style={{paddingTop:"30px",color:"#2A283D",fontSize:"16px"}}>

                <span className="title" >
                    {id>=30?
                    menusData[1].children[id-30].target+"\t"+menusData[1].children[id-30].menuName
                    :
                    menusData[0].children[id-3].target+"\t"+menusData[0].children[id-3].menuName}
                </span>

               </div>


                <div className="img">
                    <img className={'demo-img'} src={image}
                         alt={''}/>
                </div>
            </div>
        )
    }
}


export default withRouter(Form.create()(DemoRightContent))