import React, { Component } from "react";
import { Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Radio, Row, Spin, Table, Tooltip } from 'antd';
import { Professionalicon } from "@@/iconSvg";
import ImportComponent from "@@/importComponent/ImportComponent";
import { getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType, getUserInfo } from "../../../../until/cookie";
// import {  newSituation, saveNewSituation, deleteNewSituation } from '@/api/constructionResults'
import {
  addTrainClass,
  deleteTrainClass,
  updateTrainClass,

  cultivationTalentsList
} from "@/api/cultivationTalents"
import Cookies from "js-cookie";
import Forms from './Forms';
import "./styles.scss";

export default class PlatTalent2_1_2 extends Component {
  state = {
    total: 0,
    pageSize: 0,
    pageNum: 1,
    data: [],
    modalVisible: false,
    schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    typeList: [
      { typeKey: 1, typeName: "订单班" },
      { typeKey: 2, typeName: "学徒制" },
    ],
    editObj: {},
    trainClassInfo: [],
    getTargetId2_1_2: getSecondTargetList("platform_talenttraining")[1], //2.1.6
  }

  componentDidMount() {
    console.log(this.state.schoolInfo, "this.state.schoolInfo")
    this.initGetList();
    // this.initCommonDic()
  }

  // 请求数据
  initGetList = async (pageNum = 1, pageSize = 10) => {
    /**
     * "pageNum": "", 当前页
     * "pageSize": "", 每页记录数
     * "schoolEduCode": "", 学校编码
     * "targetId": ""  指标ID
    */

    const params = {
      pageNum,
      pageSize,
      "schoolEduCode": this.state.schoolInfo.schoolEduCode,
      "targetId": this.props.getTargetId,

      id: getUserInfo().id,

    }

    const res = await cultivationTalentsList(params);

    if (res) {
      let targetTrainClassVo = res?.targetTrainClassVo;
      let trainClassVoList = targetTrainClassVo?.trainClassVoList;
      const { pageNum, pageSize, total, data } = trainClassVoList;
      const { apprenticeNum, orderNum, year } = targetTrainClassVo;
      data.map((element, i) => element.index = (pageNum - 1) * pageSize + (i + 1));
      this.setState({
        pageNum,
        pageSize,
        total,
        data,
        apprenticeNum,
        orderNum,
        year
      })
      console.log(data, "res2.1.2");
    }
  }

  // 通过分组名称获取字典信息
  initCommonDic = async () => {
    const typeList = await addTrainClass({ groupName: 'situationType' });

    if (typeList) this.setState({ typeList });
  }

  // 添加单条数据
  onInsertData = () => this.setState({ editObj: {} }, () => this.setState({ modalVisible: true }));

  // 修改单条数据
  onUpdateRecord = editObj => this.setState({ editObj }, () => this.setState({ modalVisible: true }));

  // 删除单条数据
  onDeleteRecord = async id => {
    console.log("id", id)
    const params = {
      id,
      "pageNum": 1,
      "pageSize": 10,
      "schoolEduCode": this.state.schoolInfo.schoolEduCode,
      "targetId": this.state.getTargetId2_1_2,
    }

    const res = await deleteTrainClass(params);

    if (res.code === 0) {
      message.success('删除成功');
      this.initGetList(1, 10);
    }
  };

  // 提交表单
  onSubmitForm = (loop) => {
    /**
      *  "id": "", 主键ID
      *  "situationName": "", 名称
      *  "description": "", 情况说明
      *  "situationType": 0, 情况类型
      *  "schoolEduCode": "", 学校编码
      *  "targetId": ""  指标ID
    */

    const params = {
      schoolEduCode: this.state.schoolInfo.schoolEduCode,
      targetId: this.state.getTargetId2_1_2,


      // "id": "",
      // "isVs": 0,
      // "className": "",
      // "classPeopleNumber": 0,
      // "classType": 0,

      "evidenceQo": {
        "fileId": "",
        "id": "",
        "resourceName": "",
        "resourceSize": "",
        "resourceSuffix": ""
      },
    }

    if (this.state.editObj?.id) {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          const values = { ...params, ...value, id: this.state.editObj?.id }

          console.log("上传数据", values)
          const res = await updateTrainClass(values);
          if (res.code === 0) {
            message.success('修改成功');
            this.setState({ modalVisible: false });
            this.initGetList(this.state.pageNum, 10);
          }
        }
      })
    } else {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          const values = { ...params, ...value }
          const res = await addTrainClass(values);
          if (res.code === 0) {
            message.success('新增成功');

            if (loop) {
              this.modalForm.props.form.resetFields()
            } else {
              this.setState({ modalVisible: false });
            }

            this.initGetList(1, this.state.pageSize);
          }
        }
      })
    }
  }

  cultivationTalentsList = () => {
    let data = {
      id: getUserInfo().id,
      schoolEduCode: this.state.schoolInfo.schoolEduCode,
      targetId: this.state.twoLevelTargetId,
      pageNum: this.state.pageNum,
      pageSize: this.state.pageSize,
      id: "3"
    }
    console.log("data", data)
    cultivationTalentsList(data).then(res => {
      console.log(res, "res");
      if (res) {
        this.setState({
          trainClassInfo: res.targetTrainClassVo,
          orderNum: res.targetTrainClassVo.orderNum,
          apprenticeNum: res.targetTrainClassVo.apprenticeNum,
          factorContent: res.targetTrainClassVo.factorContent,
          trainPlanInfo: res.targetTrainPlanVo,
          planFactorContent: Number(res.targetTrainPlanVo.factorContent),
          dataSource1: res.targetTrainClassVo.trainClassVoList.data,
          dataSource2: res.targetTrainPlanVo.trainPlanVoList.data,
          classTotal: res.targetTrainClassVo.trainClassVoList.total,
          planTotal: res.targetTrainPlanVo.trainPlanVoList.total,
          planBaifenbi: 0,
          classABaifenbi: 0,
          targetPersonPeriodVo: res?.targetPersonPeriodVo,
          studentCertificateVo: res?.studentCertificateVo,//2.1.6
          targetMegagameVo: res?.targetMegagameVo,//2.1.7
        })
      }

    })
  }
  // 切换页码
  onPageChange = (pageNum, pageSize) => {
    console.log("onPageChange", pageNum, pageSize)
    this.setState({ pageNum, pageSize }, () => { this.initGetList(pageNum, pageSize); });
  };

  // 切换页码
  onShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize }, () => { this.initGetList(1, pageSize); });
  };


  gaiBian = (value) => {
    this.setState({
      factorContent: Number(value)
    })
  }

  planGiaBian = (value) => {
    this.setState({
      planFactorContent: Number(value)
    })
  }


  render() {
    const { canEdit, targetId, } = this.props;
    const {
      apprenticeNum,
      orderNum,
      factorContent,
      trainClassInfo,
      pageNum,
      pageSize,
      total, data, modalVisible, editObj, typeList,
      getTargetId2_1_2,

    } = this.state
    let columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 60,
        // render: (_, __, index) => {
        //   return <span>item.</span>;
        // },
      },
      {
        title: "班级类型",
        dataIndex: "classType",
        key: "classType",
        align: "center",
        render: (text) => {
          return typeList.find(item => text == item.typeKey)?.typeName
        }

      },
      {
        title: "班级名称",
        dataIndex: "className",
        key: "className",
        align: "center",
      },
      {
        title: '虚拟仿真实训服务', dataIndex: 'hasSolve', key: 'hasSolve', align: 'center', width: 200,
        render: (isVs, record) => isVs === 0 ? <span>否</span> : <span>是</span>,
        dataIndex: "isVs",
        key: "isVs",
        align: "center",
      },
      {
        title: "班级人数",
        dataIndex: "classPeopleNumber",
        key: "classPeopleNumber",
        align: "center",
      }
    ];

    if (canEdit) {
      columns.push({
        title: '操作',
        align: 'center',
        width: 200,
        dataIndex: 'oper',
        key: 'oper',
        render: (_, record) => <div className='render-span vertical_j oper'>
          <div>
            <span className="cz" onClick={() => this.onUpdateRecord(record)}>编辑</span>
            <span className="cz" onClick={() => this.onDeleteRecord(record.id)}>删除</span>
          </div>
        </div>
      })
    }

    return (
      <div className={'cont1'}>
        <div
          className={'titleName'}>2.1.2 虚拟仿真实训服务订单班或现代学徒制班的学生数占比
        </div>
        <ul className={'tongjiul'}>
          <li>
            <span className={'span-auto'}>学生总数</span>
            <div className={'btn btn1'}>
              <div className={'btn'}>{orderNum ? orderNum : 0}</div>
              {/* <InputNumber min={0} className='input_bg' disabled={!canEdit}
                onChange={e => this.gaiBian(e)}
                onBlur={e => this.updateClassNum(e.target.value)}
                placeholder={'数值填充'}
                disabled={true}
                value={orderNum ? orderNum : 0} /> */}
            </div>
          </li>
          <li>
            <span className={'span-auto'}>虚拟仿真类学生数</span>
            <div className={'btn btn1 group'}>
              <div className={'btn'}>{apprenticeNum ? apprenticeNum : 0}</div>
              {/* <InputNumber min={0} className='input_bg' disabled={!canEdit}
                onChange={e => this.gaiBian(e)}
                onBlur={e => this.updateClassNum(e.target.value)}
                placeholder={'数值填充'}
                disabled={true}
                value={apprenticeNum ? apprenticeNum : 0} /> */}
            </div>
          </li>
          {/* <li>
            <div className={'lidiv'}>
              <span
                className={'span1 span2'}>基地覆盖{trainClassInfo.year}订单班数量</span>
              <div className={'btn'}>{orderNum ? orderNum : 0}</div>
            </div>
            <span className={'jia'}>+</span>
            <div className={'lidiv'}>
              <span
                className={'span1 span2'}>基地覆盖{trainClassInfo.year}学徒班数量</span>
              <div className={'btn'}>{apprenticeNum ? apprenticeNum : 0}</div>
            </div>
          </li> */}
          <li>
            <span className={'span'}>占比</span>
            <div
              className={(((apprenticeNum / orderNum) * 100).toFixed(2)) < 30 ? 'btn btn-bred' : 'btn'}>{orderNum && apprenticeNum !== 0 ? ((apprenticeNum / orderNum) * 100).toFixed(2) : 0}%
            </div>

            {
              ((apprenticeNum / orderNum) * 100).toFixed(2) < 30 && (
                <span className={'span span1'} style={{ color: '#d75746', marginLeft: "15px" }}>*该项指标需达30%</span>
              )
            }

          </li>
        </ul>


        <div className="platTalent2_1_2">

          <div className="insert_upload_button">
            {canEdit ? (
              <div className="conteBtnDiv" onClick={this.onInsertData}>
                <Professionalicon />
                <span className="conteBtnDivSpan">添加</span>
              </div>
            ) : (
              ""
            )}
            {
              canEdit ? <ImportComponent
                targetId={getTargetId2_1_2}
                editEnable={canEdit}
                targetMark="2.1.2"
                downloadName="虚拟仿真实训服务订单班或现代学徒制班的学生数占比"
                onImportSuccess={() => {
                  // todo 刷新此项即可
                  // alert("成功")
                  this.initGetList(1, 10);
                }}
              /> : ""
            }

          </div>

          <div >
            {/* style={{ marginBottom: "100px" }} */}
            <div className="conteTable">
              <Table
                columns={columns}
                dataSource={data}
                className={"table1"}
                bordered={true}
                locale={{
                  emptyText: (
                    <>
                      <div className="Empty-content">
                        <div className="text">
                          <img src={window.$$imgSrcLcl + "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"} />
                          <span>暂无数据</span>
                        </div>
                      </div>
                    </>
                  ),
                }}
                pagination={{
                  total: total,
                  pageSize: pageSize,
                  onChange: this.onPageChange,
                  onShowSizeChange: this.onShowSizeChange,
                  pageSizeOptions: ["10", "20", "30", "40"],
                  showSizeChanger: true,
                  current: pageNum
                }}
              />
            </div>
          </div>

          {
            modalVisible ?
              <Modal
                title={!editObj.id ? '添加' : '编辑'}
                visible={modalVisible}
                width={660}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                centered={true}
                onCancel={() => { }}
                className="Professional_con Modal214"
                wrapClassName="wappop_up"
                footer={
                  <div className='addModalBtn'>
                    <Button onClick={() => this.setState({ modalVisible: false })}>取 消</Button>
                    <Button onClick={this.onSubmitForm.bind(this, false)} type='primary'>提交</Button>
                    {
                      editObj.id ? '' : <Button onClick={this.onSubmitForm.bind(this, true)} type='primary'>提交并继续添加</Button>
                    }
                  </div>
                }
              >
                <Forms
                  record={this.props.editingTaskRecord}
                  wrappedComponentRef={form => { this.modalForm = form }}
                  typeList={typeList}
                  editObj={editObj}
                />
              </Modal> : null
          }

        </div>

      </div>
    );
  }
}
