import Cookies from "js-cookie";
const TokenKey = 'vsToken';

export function getToken() {
  return Cookies.get('vsToken');
}

export function getCookieToken() {
  if (Cookies.get('vsToken') && Cookies.get('vsToken') !== undefined && Cookies.get('vsToken') !== 'undefined') {
    return Cookies.get('vsToken');
  } else {
    return "";
  }
}

export function setToken(token) {
  Cookies.set('vsToken', token);
}

export function removeToken() {
  Cookies.remove('vsToken');
}