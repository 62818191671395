import LoadableComponent from "../until/LoadableComponent";
let moduleFile = require.context("../page", true, /\index.(jsx|js)$/);
let result = moduleFile.keys().reduce((prev, item) => {
  let str = item.split("/")[item.split("/").length - 2];
  let name = str[0].toLocaleUpperCase() + str.slice(1);
  prev = Object.assign({}, prev, {
    [name]: LoadableComponent(import("../page" + item.slice(1))),
  });
  return prev;
}, {});
// console.log(result)
export default [
  {
    name: "首页",
    path: "/",
    component: result.Index,
    exact: true,
  },
  {
    name: "登录",
    path: "/login",
    component: result.Login,
    exact: true,
  },
  {
    name: "中心首页",
    path: "/index",
    component: result.Index
  },
  {
    name: "省厅首页",
    path: "/mission_management",
    component: result.Mission_Management
  },
  {
    name: "学校首页",
    path: "/mission_statement",
    component: result.Mission_Statement
  },
  {
    name: "项目情况-平台情况",
    path: "/status_project",
    component: result.Status_Project
  },
  {
    name: "任务书封皮",
    path: "/construction_plan",
    component: result.Construction_Plan
  },
  {
    name: "学校基本情况",
    path: "/project_school",
    component: result.Project_School
  },
  {
    name: "建设方案综述-预览",
    path: "/construction_scheme",
    component: result.Construction_Scheme
  }    ,
  {
    name: "基础信息-预览",
    path: "/construction_BasicInformation",
    component: result.Construction_BasicInformation
  },
  {
    name: "建设项目总预算-预览",
    path: "/building_project",
    component: result.Building_Project
  },
  {
    name: "预期成效和保障-特色创新",
    path: "/characteristic_creation",
    component: result.Characteristic_Creation
  },
  {
    name: "项目情况-预览",
    path: "/project_Situation",
    component: result.Project_Situation
  },

  {
    name: "建设方案综述",
    path: "/xmxxjs_renwushu",
    component: result.Xmxxjs_Renwushu
  },
  {
    name: "建设任务和进度",
    path: "/school_jianshe",
    component: result.School_Jianshe
  },
  {
    name: "项目经费预算-人才培养模式改革",
    path: "/reform_personnel",
    component: result.Reform_Personnel
  },
  {
    name: "预期成效和保障-预览",
    path: "/planned_performance",
    component: result.Planned_Performance
  },
  {
    name: "盖章文件",
    path: "/seal_Pile",
    component: result.Seal_Pile
  },
  {
    name: "内容确认声明",
    path: "/content_validation",
    component: result.Content_Validation
  },
  {
    name: "建设任务和进度-预览",
    path: "/tasks_Schedule",
    component: result.Tasks_Schedule
  },
  {
    name: "文件上传",
    path: "/upload_Document",
    component: result.Upload_Document
  },
  {
    name: "Excel",
    path: "/Excel",
    component: result.Excel
  },
  {
    name: "平台互通",
    path: "/platform_Communication",
    component: result.Platform_Communication
  },
  {
    name: "平台互通",
    path: "/self_Target",
    component: result.Self_Target
  },
  {
    name: "基地建设指标——校本资源",
    path: "/platform_Schoolresources",
    component: result.Platform_Schoolresources
  },
  {
    name: "基地建设指标——平台能力（我们增补）",
    path: "/platform_Ability",
    component: result.Platform_Ability
  },
  {
    name: "建设成效",
    path: "/construction_Effective",
    component: result.Construction_Effective
  },
  {
    name: "基地建设指标——人才培养",
    path: "/platform_talenttraining",
    component: result.Platform_Talenttraining
  },
  {
    name: "教师发展",
    path: "/teacher_Development",
    component: result.Teacher_Development
  },
  {
    name: "基地建设指标-资金执行",
    path: "/money_Perform",
    component: result.Money_Perform
  },
  {
    name: "基地建设指标——平台服务",
    path: "/platform_service",
    component: result.Platform_Service
  },
  {
    name: "平台应用",
    path: "/application_Platform",
    component: result.Application_Platform
  },
  {
    name: "基地建设指标——国内推广",
    path: "/platform_Domesticmarketing",
    component: result.Platform_Domesticmarketing
  },
  {
    name: "基地建设指标——国际交流",
    path: "/international_Exchange",
    component: result.International_Exchange
  },
  {
    name: "基地建设指标——课程共享",
    path: "/Platform_coursesharing",
    component: result.Platform_Coursesharing
  },
  {
    name: "效能填报",
    path: "/effectiveness_Allowed",
    component: result.Effectiveness_Allowed
  },
  {
    name: "保障设施",
    path: "/security_Facilities",
    component: result.Security_Facilities
  },
  {
    name: "特色创新",
    path: "/features_Innovation",
    component: result.Features_Innovation
  },
    {
      name: "文件预览",
      path: "/file_Book",
      component: result.File_Book
    },
    {
      name: "文件预览",
      path: "/liuchengtu",
      component: result.Liuchengtu
    },
    {
      name: "文件预览",
      path: "/liuchengtu2",
      component: result.Liuchengtu2
    },
    {
      name: "",
      path: "/demo_Info",
      component: result.Demo_Info
    },
    {
      name: "",
      path: "/levelOne",
      component: result.Demo_LevelOne
    },
    {
      name: "",
      path: "/levelTwo",
      component: result.Demo_LevelTwo
    },
  
];
