// 效能填报-----校本资源页
import React from "react";
import {Form, Row, Col, Button, Input, Table, Modal, Radio, Select, message, DatePicker, Drawer} from 'antd';
import Heder from "../../components/header";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import {Addziyuan, Daoru, ExcelImport} from '../../components/iconSvg'
import AddShixunkc from './components/add_Shixunkc/index' //1.4.2
import AddShixunkcxs from './components/add_Shixunkcxs/index' //1.4.3
import AddFzsxcl from './components/add_Fzsxcl/index' //1.4.4
import FileLook from "../components/file_look";
import {BaseUrl} from "../../config/common";
import {
    selectTargetResource,
    saveTargetResourceReplace,
    selectTargetResourceReplace,
    deleteTargetResourceReplace,
    updateTargetResourceReplace,
    checkImportTargetResourceReplace,
    selectTargetResourceCourse,
    saveTargetResourceCourse,
    updateTargetResourceCourse,
    deleteTargetResourceCourse,
    deleteTargetResourceSubject,
    selectTargetResourceSubject,
    saveTargetResourceSubject,
    updateTargetResourceSubject,
    deleteTargetResourceBook,
    saveTargetResourceBook,
    updateTargetResourceBook,
    selectTargetResourceBook,
    checkImportTargetResourceCourse, checkImportTargetResourceSubject, checkImportTargetResourceBook,
} from "../../api/targetResource";
import {getSharingMajorList, downTemplate} from "../../api/sharing";
import {uploadFile} from '../../api/uploadFile';
import axios from 'axios';
import md5 from "js-md5";
import Cookies from "js-cookie";
import moment from "moment";
import Footer from "../../components/footer";
import LookCheck from "../components/look_Check";
import "./index.scss";
import {getCanEdit, getRoleType, getTargetId, RoleType} from "../../until/cookie";
import ImportComponent from "../../components/importComponent/ImportComponent";
import {isEmpty} from "../../until/isEmpty";
import {formatDate} from "../../until/date";
import {validData} from "../../api/target";

const {TextArea} = Input;


class PlatformSchoolresources extends React.Component {
    state = {
        pageNum: 1,//页数
        pageSize: 10,//条数
        total: 0,
        updateResourceNum: 0,  //更新资源数

        pageNumone: 1,//页数
        pageSizeone: 10,//条数
        totalTwo: 0,

        totalTwoVsNum: 0,//虚拟仿真实训课程总数

        pageNumtwo: 1,//页数
        pageSizetwo: 10,//条数
        totalThree: 0,
        totalThreeVsNum: 0, //虚实结合实训课程总数

        pageNumthree: 1,//页数
        pageSizethree: 10,//条数
        totalFour: 0,
        totalFourVsNum: 0, // 虚拟仿真实训资源的教材数

        dataSource1: [],

        dataSource2: [],

        dataSource3: [],

        courseStatus: true,
        courseSubject: true,
        courseBook: true,
        dataSource4: [],
        showXzzyModel: false,//新增1.4.1资源
        isXzzyModelUpdate: false,//更新1.4.1资源
        showAddsxkcModel: false, //新增1.4.2实训课程
        showAddsxkcxsModel: false, //新增1.4.3实训课程
        showAddfzsxclModel: false, //新增1.4.3添加仿真实训材料
        teacherNum: '',//参加各种培训的教师总数
        courseNum: '', //1.4.3实训课程总数
        courseNum1: '', //1.4.4实训课程总数
        targetResourceReplaceId: '',
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        // targetId: Cookies.get("twoLevelTargetId") ? Cookies.get("twoLevelTargetId") : '',
        targetId: getTargetId("platform_Schoolresources"),
        targetIdOne: '',
        targetIdTwo: '',
        targetIdFour: '',
        completionStatus: true,
        resourceReplaceVo141: [],
        resourceCourseVo142: [],
        resourceBookVo144: [],
        courseId: '',
        resourceCourseDetail: {},
        subjectStatus: true,
        subjectId: '',
        resourceSubjectDetail: {},
        bookStatus: true,
        bookId: '',
        resourceBookDetail: {},
        resourceNum: 0,
        majorList: [],
        evidenceId: null,
        examine: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.selectTargetResource()
        this.getSharingMajor()
        // todo 需要改为从后台获取,并计算
        this.props.form.setFieldsValue({
            resourceState: 1,
        })
    }

    selectTargetResource = () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetId
        }
        selectTargetResource(data).then((res) => {
            if (res) {
                this.setState({
                    resourceAll: res,
                    resourceReplaceVo141: res.resourceReplaceVo141 ? res.resourceReplaceVo141.selectTargetResourceReplaceVo.targetResourceReplaceVos.data : [],
                    total: res.resourceReplaceVo141 ? res.resourceReplaceVo141.selectTargetResourceReplaceVo.targetResourceReplaceVos.total : 0,
                    updateResourceNum: res.resourceReplaceVo141 ? res.resourceReplaceVo141.selectTargetResourceReplaceVo.targetResourceReplaceNum : 0,
                    targetIdOne: res.resourceReplaceVo141.id,

                    totalTwoVsNum: res.resourceCourseVo142.selectTargetResourceCourseVo.courseNum,
                    totalThreeVsNum: res.resourceCourseVo142.selectTargetResourceCourseVo.courseNum2,
                    resourceCourseVo142: res.resourceCourseVo142.selectTargetResourceCourseVo.targetResourceCourseVos.data,
                    totalTwo: res.resourceCourseVo142 ? res.resourceCourseVo142.selectTargetResourceCourseVo.targetResourceCourseVos.total : 0,
                    targetIdTwo: res.resourceCourseVo142.id,
                    totalFourVsNum: res.resourceBookVo144.selectTargetResourceBookVo.bookNum,
                    resourceBookVo144: res.resourceBookVo144.selectTargetResourceBookVo.targetResourceBookVos.data,
                    totalFour: res.resourceBookVo144 ? res.resourceBookVo144.selectTargetResourceBookVo.targetResourceBookVos.total : 0,
                    targetIdFour: res.resourceBookVo144.id,
                });
            }
        });
    }
    getSharingMajor = () => {
        getSharingMajorList({stageId: 2}).then((res) => {
            if (res) {
                this.setState({
                    majorList: res,
                });
            }
        });
    }
    // 上一步
    previous = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_Communication") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_Communication',
                    })
                    break;
                }
            }
        }
    }
    // 下一步
    next = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }



        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "teacher_Development") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/teacher_Development',
                    })
                    break;
                }
            }
        }
    }
    //section 1.3.1
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.selectTargetResourceReplace()
        });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageNum: 1, pageSize}, () => {
            this.selectTargetResourceReplace()
        });
    }
    /**
     *  资源的接口
     */
    selectTargetResourceReplace = () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdOne
        }
        selectTargetResourceReplace(data).then((res) => {
            if (res) {
                this.setState({
                    resourceReplaceVo141: res ? res.targetResourceReplaceVos.data : [],

                    total: res.targetResourceReplaceVos.total,
                    updateResourceNum: res.targetResourceReplaceNum
                });
            }
        });
    }
    //section 1.3.2
    //第二个表格分页切换
    pageChangeone = (pageNum) => {
        this.setState({pageNumone: pageNum}, () => {
            this.selectTargetResourceCourse()
        });
    }
    //第二个表格分页条数切换
    onShowSizeChangeone = (pageNum, pageSize) => {
        this.setState({pageNumone: 1, pageSizeone: pageSize}, () => {
            this.selectTargetResourceCourse()
        });
    }
    selectTargetResourceCourse = () => {
        let data = {
            pageNum: this.state.pageNumone,
            pageSize: this.state.pageSizeone,
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdTwo
        }
        selectTargetResourceCourse(data).then((res) => {
            if (res) {
                this.setState({
                    resourceCourseVo142: res ? res.targetResourceCourseVos.data : [],
                    totalTwo: res.targetResourceCourseVos.total,
                    totalThreeVsNum: res.courseNum2,
                    totalTwoVsNum: res.courseNum
                });
            }
        });
    }

    //section 1.3.3
    //第三个表格分页切换
    pageChangetwo = (pageNum) => {
        this.setState({pageNumtwo: pageNum}, () => {
            this.selectTargetResourceSubject()
        });
    }
    //第三个表格分页条数切换
    onShowSizeChangetwo = (pageNum, pageSize) => {
        this.setState({pageNumtwo: 1, pageSizetwo: pageSize}, () => {
            this.selectTargetResourceSubject()
        });
    }
    selectTargetResourceSubject = () => {
        let data = {
            pageNum: this.state.pageNumtwo,
            pageSize: this.state.pageSizetwo,
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdThree
        }
        selectTargetResourceSubject(data).then((res) => {
            if (res) {
                this.setState({
                    resourceSubjectVo143: res ? res.targetResourceSubjectVos.data : [],
                    totalThree: res.targetResourceSubjectVos.total,
                    resourceNum: res.resourceNum
                });
            }
        });
    }

    //section 1.3.4
    //第四个表格分页切换
    pageChangeThree = (pageNum) => {
        this.setState({pageNumthree: pageNum}, () => {

            this.selectTargetResourceBook()
        });
    }
    //第四个表格分页条数切换
    onShowSizeChangeThree = (pageNum, pageSize) => {
        this.setState({pageNumthree: 1, pageSizethree: pageSize}, () => {
            this.selectTargetResourceBook()
        });
    }
    selectTargetResourceBook = () => {
        let data = {
            pageNum: this.state.pageNumthree,
            pageSize: this.state.pageSizethree,
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdFour
        }
        selectTargetResourceBook(data).then((res) => {
            if (res) {
                this.setState({
                    resourceBookVo144: res ? res.targetResourceBookVos.data : [],
                    totalFour: res.targetResourceBookVos.total,
                    totalFourVsNum: res.bookNum

                });
            }
        });
    }
    // 查看资料
    handLook = (record) => {
        const url = BaseUrl + record.fileId
        this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})
    }
    handExamine = () => {
        this.setState({
            examine: true
        })
    }
    onCancel = () => {
        this.setState({
            examine: false
        })
    }
    //删除前二次确认
    deleteAsk = (record) => {
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdOne,
            id: record.id,
        }
        deleteTargetResourceReplace(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.pageChange(1)
            }
        })

    }
    deleteAskTwo = (record) => {
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdTwo,
            id: record.resourceCourseid,
        }
        deleteTargetResourceCourse(data).then(res => {
            if (res) {
                message.success("删除成功")
                //  this.selectTargetResourceCourse()
                this.pageChangeone(1)
            }
        })

    }
    //保存
    saveTargetResourceReplace = (e) => {
        const {completionStatus, targetResourceReplaceId} = this.state
        if (completionStatus) {
            this.setState({completionStatus: false})
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    if (targetResourceReplaceId) {
                        let param = {
                            targetResourceReplaceId: targetResourceReplaceId,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdOne,
                            resourceState: values.resourceState,
                            resourceName: values.resourceName,
                            resourceFormat: values.resourceFormat,
                            resourceTime: moment(formatDate(values.resourceTime)),
                            resourceExplain: values.resourceExplain,
                        }
                        updateTargetResourceReplace(param).then(res => {
                            message.success("修改成功")
                            this.setState({showXzzyModel: false, isXzzyModelUpdate: false,targetResourceReplaceId:""})
                            this.selectTargetResourceReplace();
                        })
                    } else {
                        let param = {
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdOne,
                            resourceState: values.resourceState,
                            resourceName: values.resourceName,
                            resourceFormat: values.resourceFormat,
                            resourceTime: moment(formatDate(values.resourceTime)),
                            resourceExplain: values.resourceExplain,
                        }
                        saveTargetResourceReplace(param).then(res => {
                            message.success("保存成功")
                            if (e === true) {
                                this.props.form.resetFields();
                            } else {
                                this.setState({showXzzyModel: false, isXzzyModelUpdate: false,targetResourceReplaceId:""})
                            }
                            this.pageChange(1)
                        })
                    }
                } else {
                    window.scrollTo({
                        top: 390,
                        behavior: 'smooth',
                    });
                }
            })
            setTimeout(() => {
                this.setState({completionStatus: true})
            }, 3000)
        }
    }

    updateTargetResourceReplace = (record) => {
        this.setState({
            targetResourceReplaceId: record.id,
            showXzzyModel: true,
            isXzzyModelUpdate: true
        }, () => {
            this.props.form.setFieldsValue({
                resourceState: record.resourceState,
                resourceName: record.resourceName,
                resourceFormat: record.resourceFormat,
                resourceTime: moment(record.resourceTime),
                resourceExplain: record.resourceExplain,
            })
        })
    }

    //保存
    saveTargetResourceCourse = (e) => {
        const {courseStatus, courseId} = this.state
        if (courseStatus) {
            this.setState({courseStatus: false})
            this.AddShixunkc.props.form.validateFields((err, values) => {
                //  let file = this.AddShixunkc.getFile() ? this.AddShixunkc.getFile() : null
                if (!err) {
                    if (courseId) {
                        let param = {
                            // fileId: file.fileId,
                            // resourceId: file.resourceId,
                            // resourceName: file.originName,
                            // resourceSize: file.fileSize,
                            // resourceSuffix: file.fileSuffix,
                            courseId: courseId,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdTwo,
                            courseName: values.courseName,
                            majorOne: values.courseMajor[0],
                            majorTwo: values.courseMajor[1],
                            courseMajor: values.courseMajor[2],
                            courseType: values.courseType,
                            coursePlatform: values.coursePlatform,
                            courseAddress: values.courseAddress,
                            courseExplain: values.courseExplain,
                        }
                        updateTargetResourceCourse(param).then(res => {
                            message.success("修改成功")
                            this.setState({showAddsxkcModel: false, resourceCourseDetail: {},courseId:""})
                            this.selectTargetResourceCourse();
                        })
                    } else {
                        let param = {
                            // fileId: file.fileId,
                            // resourceId: file.resourceId,
                            // resourceName: file.originName,
                            // resourceSize: file.fileSize,
                            // resourceSuffix: file.fileSuffix,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdTwo,
                            courseName: values.courseName,
                            majorOne: values.courseMajor[0],
                            majorTwo: values.courseMajor[1],
                            courseMajor: values.courseMajor[2],
                            courseType: values.courseType,
                            coursePlatform: values.coursePlatform,
                            courseAddress: values.courseAddress,
                            courseExplain: values.courseExplain,
                        }
                        saveTargetResourceCourse(param).then(res => {
                            message.success("保存成功")
                            if (e === true) {
                                this.AddShixunkc.props.form.resetFields();
                                this.AddShixunkc.deleteFile();
                            } else {
                                this.setState({showAddsxkcModel: false, resourceCourseDetail: {},courseId:""})
                            }
                            this.pageChangeone(1)
                        })
                    }
                } else {
                    window.scrollTo({
                        top: 390,
                        behavior: 'smooth',
                    });
                }
            })
            setTimeout(() => {
                this.setState({courseStatus: true})
            }, 3000)
        }
    }
    updateTargetResourceCourse = (record) => {
        let upFile = {
            originName: record.resourceName,
            fileId: record.fileId,
            fileSuffix: record.resourceSuffix,
            fileSize: (record.resourceSize / 1024).toFixed(2)
        }
        this.setState({
            courseId: record.resourceCourseid,
            resourceCourseDetail: record,
            showAddsxkcModel: true,
            fileOne: upFile
        })
    }
    //删除前二次确认
    deleteAskThree = (record) => {
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdThree,
            id: record.subjectId,
        }
        deleteTargetResourceSubject(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.selectTargetResourceSubject()
            }
        })

    }
    updateTargetResourceSubject = (record) => {
        let upFile = {
            originName: record.resourceName,
            fileId: record.fileId,
            fileSuffix: record.resourceSuffix,
            fileSize: (record.resourceSize / 1024).toFixed(2)
        }
        this.setState({
            subjectId: record.subjectId,
            resourceSubjectDetail: record,
            showAddsxkcxsModel: true,
            fileTwo: upFile,
        })
    }

    //保存
    saveTargetResourceSubject = (e) => {
        const {subjectStatus, subjectId} = this.state
        if (subjectStatus) {
            this.setState({subjectStatus: false})
            this.AddShixunkcxs.props.form.validateFields((err, values) => {
                let file = this.AddShixunkcxs.getFile() ? this.AddShixunkcxs.getFile() : null
                if (!err) {
                    if (subjectId) {
                        let param = {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                            subjectId: subjectId,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdThree,
                            subjectName: values.subjectName,
                            majorOne: values.subjectMajor[0],
                            majorTwo: values.subjectMajor[1],
                            subjectMajor: values.subjectMajor[2],
                            subjectType: values.subjectType,
                            isBase: values.isBase,
                        }
                        updateTargetResourceSubject(param).then(res => {
                            message.success("修改成功")
                            this.setState({showAddsxkcxsModel: false, resourceSubjectDetail: {}})
                            this.selectTargetResourceSubject();
                        })
                    } else {
                        let param = {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdThree,
                            subjectName: values.subjectName,
                            majorOne: values.subjectMajor[0],
                            majorTwo: values.subjectMajor[1],
                            subjectMajor: values.subjectMajor[2],
                            subjectType: values.subjectType,
                            isBase: values.isBase,
                        }
                        saveTargetResourceSubject(param).then(res => {
                            message.success("保存成功")
                            if (e === true) {
                                this.AddShixunkcxs.props.form.resetFields();
                                this.AddShixunkcxs.deleteFile();
                            } else {
                                this.setState({showAddsxkcxsModel: false, resourceSubjectDetail: {}})
                            }
                            //this.selectTargetResourceSubject();
                            this.pageChangeone(1)
                        })
                    }
                } else {
                    window.scrollTo({
                        top: 390,
                        behavior: 'smooth',
                    });
                }
            })
            setTimeout(() => {
                this.setState({subjectStatus: true})
            }, 3000)
        }
    }


    //删除前二次确认
    deleteAskFour = (record) => {
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.targetIdFour,
            id: record.bookId,
        }
        deleteTargetResourceBook(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.pageChangeThree(1)
            }
        })

    }
    updateTargetResourceBook = (record) => {
        let upFile = {
            originName: record.resourceName,
            fileId: record.fileId,
            fileSuffix: record.resourceSuffix,
            fileSize: (record.resourceSize / 1024).toFixed(2)
        }
        this.setState({
            bookId: record.bookId,
            resourceBookDetail: record,
            showAddfzsxclModel: true,
            fileThree: upFile
        })
    }

    //保存
    saveTargetResourceBook = (e) => {
        const {bookStatus, bookId} = this.state
        if (bookStatus) {
            this.setState({bookStatus: false})
            this.AddFzsxcl.props.form.validateFields((err, values) => {


                if (!err) {
                    let file = this.AddFzsxcl.getFile() ? this.AddFzsxcl.getFile() : null

                    if (!file || isEmpty(file.fileId)) {
                        message.info("文件上传中")
                        return
                    }
                    if (bookId) {
                        let param = {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                            bookId: bookId,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdFour,
                            subjectName: values.subjectName,
                            bookName: values.bookName,
                            majorOne: values.bookMajor[0],
                            majorTwo: values.bookMajor[1],
                            bookMajor: values.bookMajor[2],
                            bookType: values.bookType,
                            bookPress: values.bookPress,
                            bookYear: moment(formatDate(values.bookYear)).format('YYYY-MM'),
                            bookNumber: values.bookNumber,
                            isBase: values.isVirtualSimulation,
                        }
                        updateTargetResourceBook(param).then(res => {
                            message.success("修改成功")
                            this.setState({showAddfzsxclModel: false, resourceBookDetail: {}})
                            this.selectTargetResourceBook();
                        })
                    } else {
                        let param = {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                            schoolEduCode: this.state.schoolEduCode,
                            targetId: this.state.targetIdFour,
                            subjectName: values.subjectName,
                            bookName: values.bookName,
                            majorOne: values.bookMajor[0],
                            majorTwo: values.bookMajor[1],
                            bookMajor: values.bookMajor[2],
                            bookType: values.bookType,
                            bookPress: values.bookPress,
                            bookYear: moment(formatDate(values.bookYear)).format('YYYY-MM'),
                            bookNumber: values.bookNumber,
                            //  isBase: values.isBase,
                            isBase: values.isVirtualSimulation,
                        }
                        saveTargetResourceBook(param).then(res => {
                            message.success("保存成功")
                            if (e === true) {
                                this.AddFzsxcl.props.form.resetFields();
                                this.AddFzsxcl.deleteFile();
                            } else {
                                this.setState({showAddfzsxclModel: false, resourceBookDetail: {}})
                            }
                            this.pageChangeThree(1)
                        })
                    }
                } else {
                    window.scrollTo({
                        top: 390,
                        behavior: 'smooth',
                    });
                }
            })
            setTimeout(() => {
                this.setState({bookStatus: true})
            }, 3000)
        }
    }


    // 资源更新率 校验
    isCheckTip = (total, progress, scoreLine) => {

        return total === 0 || Math.round(progress / total * 100) < scoreLine
    }

    // 资源更新率 百分比
    calculatePercentage(total, progress) {

        return total !== 0 ? (Math.round(progress / total * 10000) / 100.00) + "%" : 0 + "%"
    }


    //  formatDate(time) {
    //     let date = new Date(time);
    //     date.setHours(date.getHours() +8);
    //     return moment(date).format("YYYY-MM-DD HH:mm:ss");
    //   }


    onPanelChange = (value, mode) => {
        console.log("====" + value)
        this.setState({
            value: value
        })


    }

    onDateChange = (date, formatString) => {
        console.log("====" + date)
        this.setState({
            value: date
        })
    }


    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {
            resourceNum,
            dataSource2,
            dataSource3,
            dataSource4,
            showXzzyModel,
            isXzzyModelUpdate,
            showAddsxkcModel,
            showAddsxkcxsModel,
            showAddfzsxclModel,
            pageNum,
            pageSize,
            total,
            totalone,
            pageNumone,
            pageSizeone,
            pageNumtwo,
            pageSizetwo,
            totalTwo,
            totalThree,
            totalFour,
            totalTwoVsNum,
            totalThreeVsNum,
            totalFourVsNum,
            pageNumthree,
            pageSizethree,
            isShow,
            openUrl, updateResourceNum
        } = this.state
        const {
            resourceReplaceVo141,
            resourceCourseVo142,
            resourceBookVo144,
            resourceAll,
            evidenceId,
            examine
        } = this.state
        const {
            resourceCourseDetail,
            resourceSubjectDetail,
            resourceBookDetail,
            targetResourceReplaceId,
            courseId,
            subjectId,
            bookId,
            majorList,
            value
        } = this.state
        console.log("resourceReplaceVo141", resourceNum)
        const canEdit = getCanEdit()
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNum - 1) * pageSize + (index + 1)}</span>
                }
            },
            {
                title: '资源名称',
                dataIndex: 'resourceName',
                key: 'resourceName',
                align: 'center',
                width: 130
            },
            {
                title: '资源格式/类型',
                dataIndex: 'resourceFormat',
                key: 'resourceFormat',
                align: 'center',
                width: 130,
                // render: (e) =>  <div>{e ===  ? '其他' : e === 1 ? 'VR实操资源' : e===2?'仿真实训资源':e===3?'仿真演示资源':""}</div>
            },
            {
                title: '上线/更新时间',
                dataIndex: 'resourceTime',
                key: 'resourceTime',
                align: 'center',
                width: 130,
                render: (e) => <div>{moment(e).format('YYYY-MM-DD HH:mm:ss')}</div>
            },
            {
                title: '说明',
                dataIndex: 'resourceExplain',
                key: 'resourceExplain',
                align: 'center',
                width: 130
            },


        ]
        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNumone - 1) * pageSizeone + (index + 1)}</span>
                }
            },
            {
                title: '课程名称',
                dataIndex: 'courseName',
                key: 'courseName',
                align: 'center',
                width: 100,
            },
            {
                title: '所属专业',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'center',
                width: 100,
            },
            {
                title: '课程类型',
                dataIndex: 'courseType',
                key: 'courseType',
                align: 'center',
                width: 100,
                render: (e) => <div>{e === 1 ? "纯虚拟" : e === 2 ? '虚实结合' : e === 3 ? '纯现实' : ""}</div>
            },
            {
                title: '展示平台',
                dataIndex: 'coursePlatform',
                key: 'coursePlatform',
                align: 'center',
                width: 100,
            },
            {
                title: '地址',
                dataIndex: 'courseAddress',
                key: 'courseAddress',
                align: 'center',
                width: 100,
            },
            {
                title: '说明',
                dataIndex: 'courseExplain',
                key: 'courseExplain',
                align: 'center',
                width: 100,
            },


        ]
        const columns4 = [
            {
                title: '序号',
                dataIndex: 'bookId',
                key: 'bookId',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNumthree - 1) * pageSizethree + (index + 1)}</span>
                }
            },
            {
                title: '课程名称',
                dataIndex: 'bookName',
                key: 'bookName',
                align: 'center',
                width: 100,
            },
            {
                title: '是否为虚拟仿真',
                dataIndex: 'isBase',
                key: 'isBase',
                align: 'center',
                width: 100,
                render: (e) => <div>{e === 0 ? "否" : e === 1 ? '是' : ""}</div>
            },
            {
                title: '所属专业',
                dataIndex: 'typeName',
                key: 'typeName',
                align: 'center',
                width: 100,
            },
            {
                title: '教材类型',
                dataIndex: 'bookType',
                key: 'bookType',
                align: 'center',
                width: 100,
                render: (e) => <div>{e === 1 ? "活页式" : e === 2 ? 'VR实训手册' : ""}</div>
            },
            {
                title: '出版社',
                dataIndex: 'bookPress',
                key: 'bookPress',
                align: 'center',
                width: 100,
            },
            {
                title: '出版年份',
                dataIndex: 'bookYear',
                key: 'bookYear',
                align: 'center',
                width: 100,
            },
            {
                title: 'ISBN',
                dataIndex: 'bookNumber',
                key: 'bookNumber',
                align: 'center',
                width: 100,
            },
            {
                title: '佐证材料',
                dataIndex: 'cailiao',
                key: 'cailiao',
                align: 'center',
                width: 100,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },


        ]

        if (canEdit) {
            columns1.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updateTargetResourceReplace(record)}>更新</span>
                    <span className={'cz'} onClick={() => this.deleteAsk(record)}>删除</span>
                </div>

            })
            columns2.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updateTargetResourceCourse(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deleteAskTwo(record)}>删除</span>
                </div>

            })

            columns4.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updateTargetResourceBook(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deleteAskFour(record)}>删除</span>
                </div>

            })
        }

        const disabledDay = current => {
            // 只能选择当前日期的两个月范围内
            return current && current > moment();

        }
        // section 添加资源
        const sourceUpdateModel =
            <Modal
                title={(isXzzyModelUpdate ? "更新" : "添加") + "资源"}
                visible={(showXzzyModel)}
                width={660}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                centered={true}
                className={'addziyuanModal'}
                wrapClassName="wappop_up"
                footer={
                    <div className='footer-btn'>
                        <Button className={'quxiao'} onClick={() => this.setState({
                            showXzzyModel: false,
                            isXzzyModelUpdate: false,
                            targetResourceReplaceId: ''
                        })}>取消</Button>
                        <Button type='primary'
                                onClick={() => this.saveTargetResourceReplace()}>提交</Button>
                        {
                            targetResourceReplaceId ?
                                ''
                                :
                                <Button type='primary'
                                        onClick={() => this.saveTargetResourceReplace(true)}>
                                    提交并继续添加
                                </Button>
                        }
                    </div>
                }
            >
                <Form.Item>
                    <span className={'label'}><span className='red'>*</span>资源名称：</span>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('resourceName', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写资源名称`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input disabled={isXzzyModelUpdate} placeholder='请输入内容' maxLength={30} className="input_in"
                                   suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`resourceName`) ? this.props.form.getFieldValue(`resourceName`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item>
                    <span className={'label'}><span className='red'>*</span>资源类型：</span>
                    {getFieldDecorator('resourceFormat', {
                        initialValue: undefined,
                        rules: [
                            {
                                required: true,
                                message: `请选择类型`,
                            },
                        ],
                    })(<Select placeholder={'请选择资源类型'} disabled={isXzzyModelUpdate}>
                        <option value={"VR实操资源"}>VR实操资源</option>
                        <option value={"仿真实训资源"}>仿真实训资源</option>
                        <option value={"仿真演示资源"}>仿真演示资源</option>
                        <option value={"其他"}>其他</option>
                    </Select>)}
                </Form.Item>
                <Form.Item>
                    <span className={'label'}><span className='red'>*</span>{isXzzyModelUpdate ? "更新" : "添加"}时间：</span>
                    {getFieldDecorator('resourceTime', {
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: `请选择上线/更新时间`,
                            },
                        ],
                    })(<DatePicker disabledDate={disabledDay} placeholder={'请选择上线/更新时间'}
                                   showTime/>)}
                </Form.Item>
                <Form.Item className="li_form">
                    <span className={'label'}><span className='red'>*</span>{isXzzyModelUpdate ? "更新" : "添加"}说明：</span>
                    <div className={'input-wrap textare-wrap'}>
                        {getFieldDecorator('resourceExplain', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择内容`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input.TextArea maxLength={30} placeholder={'请输入内容'}></Input.TextArea>
                        )}
                        <span className='textarea_len'>
                                                            {this.props.form.getFieldValue(`resourceExplain`) ? this.props.form.getFieldValue(`resourceExplain`).length : 0}/30
                                                    </span>
                    </div>
                </Form.Item>
                {/*</Form>*/}
            </Modal>


        return (
            <div>
                <div className="PlatformSchoolresources main-bg">
                    <Heder/>
                    <div className="Efficiency_header">
                        <div className="main_cont">
                            <EffTop/>
                        </div>
                    </div>
                    <div className="Fillside_bottom clearfix">
                        <div className="main_cont">
                            <div className="Fillside_warp" id='Fillside_warp'>
                                <Row type="flex" justify="space-between">
                                    <Col span={4} className="leftcollect" id="leftcollect">
                                        <div className="Double-left-table">
                                            <LeftEfficiencyside onRef={(ref) => {
                                                this.LeftEfficiencyside = ref;
                                            }}/>
                                        </div>
                                    </Col>
                                    <Col span={20} className="Task_right">
                                        <div className='content'>
                                            <div className={'cont1'}>
                                                {resourceAll ? <div
                                                    className={'titleName'}>1.3.1 虚拟仿真实训资源更新率</div> : ""}
                                                <div className={'tongji'}>
                                                    <div className={'group'}>
                                                        <label>资源总数量</label>
                                                        <div className={'wancz tongjidw tongjidw1'}>{total}</div>
                                                    </div>
                                                    <div className={'group'}>
                                                        <label>更新资源数量</label>
                                                        <div
                                                            className={'wancz tongjidw tongjidw1'}>{updateResourceNum}</div>
                                                    </div>
                                                    <div className={'group'}>
                                                        <label>资源更新率</label>

                                                        <div
                                                            className={this.isCheckTip(total, updateResourceNum, 50) ? 'wancz_tip tongjidw' : 'wancz tongjidw'}>{this.calculatePercentage(total, updateResourceNum)}</div>
                                                    </div>

                                                    {

                                                        this.isCheckTip(total, updateResourceNum, 50) ?
                                                            <div className={'group'}>
                                                                <label className={'span_red'}>*该项指标需高于50%</label>
                                                            </div> : ""
                                                    }

                                                </div>

                                                <div className={'group_tip'}>
                                                    <label
                                                        className={'span_red'}>*首次添加的资源默认状态为“未更新”，在操作栏内可完成资源状态的更新</label>

                                                </div>
                                                {canEdit ?
                                                    <div className={'btn_group'}>
                                                        <Button type={'primary'} onClick={() => this.setState({
                                                            showXzzyModel: true,
                                                            isXzzyModelUpdate: false,
                                                            courseId: ''
                                                        })}>
                                                            <Addziyuan/>添加资源</Button>

                                                        <ImportComponent
                                                            targetId={this.state.targetIdOne}
                                                            targetMark="1.3.1"
                                                            downloadName="虚拟仿真实训资源更新率"
                                                            onImportSuccess={() => {
                                                                this.pageChange(1)
                                                            }}
                                                        />
                                                    </div> : ""}
                                                <Table columns={columns1} dataSource={resourceReplaceVo141}
                                                       className={'table1'} bordered={true}
                                                       locale={{
                                                           emptyText: <>
                                                               <div className="Empty-content">
                                                                   <div className="text">
                                                                       <img
                                                                           src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                       <span>暂无数据</span>
                                                                   </div>
                                                               </div>
                                                           </>
                                                       }}
                                                       pagination={
                                                           {
                                                               current: this.state.pageNum,
                                                               total: total,
                                                               pageSize: pageSize,
                                                               onChange: this.pageChange,
                                                               onShowSizeChange: this.onShowSizeChange,
                                                               pageSizeOptions: ['10', '20', '30', '40'],
                                                               showSizeChanger: true,
                                                               // position:'bottomRight'
                                                           }
                                                       }
                                                />
                                            </div>
                                            <div className={'cont1 cont2'}>
                                                {resourceAll ? <div
                                                    className={'titleName'}>1.3.2 含有虚拟仿真实训资源的课程占比</div> : ""}
                                                <div className={'tongji'}>
                                                    <div className={'group'}>
                                                        <label>实训课程总数</label>

                                                        <div
                                                            className={'wancz tongjidw tongjidw1'}>{totalTwo}</div>

                                                    </div>
                                                    <div className={'group'}>
                                                        <label>虚拟仿真类课程数</label>
                                                        <div
                                                            className={'wancz tongjidw tongjidw1'}>{totalTwoVsNum}</div>
                                                    </div>
                                                    <div className={'group'}>
                                                        <label>占比 </label>
                                                        <div
                                                            className={this.isCheckTip(totalTwo, totalTwoVsNum, 40) ? 'wancz_tip tongjidw' : 'wancz tongjidw'}>{this.calculatePercentage(totalTwo, totalTwoVsNum)}</div>


                                                    </div>
                                                    <div className={'group'}>
                                                        {
                                                            this.isCheckTip(totalTwo, totalTwoVsNum, 40) ?
                                                                <div className="span_red">*该项指标需高于40%</div> : ""
                                                        }
                                                    </div>

                                                </div>
                                                {canEdit ?
                                                    <div className={'btn_group'}>
                                                        <Button type={'primary'}
                                                                onClick={() => this.setState({showAddsxkcModel: true,})}><Addziyuan/>添加实训课程</Button>

                                                        <ImportComponent
                                                            targetId={this.state.targetIdTwo}
                                                            downloadName="虚拟仿真实训资源的课程占比"
                                                            targetMark="1.3.2"
                                                            onImportSuccess={() => {
                                                                this.pageChangeone(1)
                                                            }}
                                                        />

                                                    </div>
                                                    : ""}
                                                <Table columns={columns2} dataSource={resourceCourseVo142}
                                                       className={'table1 table2'} bordered={true}
                                                       locale={{
                                                           emptyText: <>
                                                               <div className="Empty-content">
                                                                   <div className="text">
                                                                       <img
                                                                           src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                       <span>暂无数据</span>
                                                                   </div>
                                                               </div>
                                                           </>
                                                       }}
                                                       pagination={
                                                           {
                                                               current: this.state.pageNumone,
                                                               total: totalTwo,
                                                               pageSize: pageSizeone,
                                                               onChange: this.pageChangeone,
                                                               onShowSizeChange: this.onShowSizeChangeone,
                                                               pageSizeOptions: ['10', '20', '30', '40'],
                                                               showSizeChanger: true,
                                                               // position:'bottomRight'
                                                           }
                                                       }
                                                />
                                            </div>
                                            <div className={'cont1 cont2'}>
                                                {resourceAll ? <div
                                                    className={'titleName'}>1.3.3 含有虚实结合实训资源的课程占比</div> : ""}
                                                <div className={'tongji'}>
                                                    <div className={'group'}>
                                                        <label
                                                        >虚拟仿真类课程数</label>

                                                        <div
                                                            className={'wancz tongjidw tongjidw1'}>{totalTwoVsNum}</div>


                                                    </div>
                                                    <div className={'group'}>
                                                        <label>虚实结合类课程数</label>
                                                        <div
                                                            className={'wancz tongjidw tongjidw1'}>{totalThreeVsNum}</div>
                                                    </div>
                                                    <div className={'group'}>
                                                        <label>占比</label>
                                                        <div
                                                            className={this.isCheckTip(totalTwoVsNum, totalThreeVsNum, 20) ? 'wancz_tip tongjidw' : 'wancz tongjidw'}>{this.calculatePercentage(totalTwoVsNum, totalThreeVsNum)}</div>
                                                    </div>

                                                    {
                                                        this.isCheckTip(totalTwoVsNum, totalThreeVsNum, 20) ?
                                                            <div className={'group'}>
                                                                <label className="span_red">*该项指标需高于20%</label>

                                                            </div> : ""
                                                    }

                                                </div>
                                                <div className={'group_tip_two'}>

                                                    <label className="span_red">*计算结果来自1.3.2中表格数据</label>


                                                </div>

                                            </div>
                                            <div className={'cont1 cont2 cont4'}>
                                                {resourceAll ? <div
                                                    className={'titleName'}>1.3.4 含有虚拟仿真实训资源的教材占比</div> : ""}
                                                <div className={'tongji'}>
                                                    <div className={'group'}>
                                                        <label>教材总数</label>

                                                        <div className={'wancz tongjidw tongjidw1'}>{totalFour}</div>


                                                    </div>
                                                    <div className={'group '}>
                                                        <label
                                                        >虚拟仿真实训资源<br></br>的教材数 </label>
                                                        <div
                                                            className={'wancz tongjidw tongjidw1'}>{totalFourVsNum}</div>
                                                    </div>
                                                    <div className={'group '}>
                                                        <label>虚拟仿真实训教材<br></br>占比</label>
                                                        <div
                                                            className={this.isCheckTip(totalFour, totalFourVsNum, 20) ? 'wancz_tip tongjidw' : 'wancz tongjidw'}>{this.calculatePercentage(totalFour, totalFourVsNum)}</div>
                                                    </div>
                                                    {
                                                        this.isCheckTip(totalFour, totalFourVsNum, 20) ?
                                                            <div className={'group'}>
                                                                <label className="span_red">*该项指标需高于20%</label>

                                                            </div> : ""
                                                    }

                                                </div>

                                                {
                                                    canEdit ?
                                                        <div className={'btn_group'}>

                                                            <Button type={'primary'} onClick={() => this.setState({
                                                                showAddfzsxclModel: true,
                                                                bookId: ''
                                                            })}><Addziyuan/>添加仿真实训教材</Button>


                                                        </div> : ""}

                                                {<Table columns={columns4} dataSource={resourceBookVo144}
                                                        className={'table1 table2'} bordered={true}
                                                        locale={{
                                                            emptyText: <>
                                                                <div className="Empty-content">
                                                                    <div className="text">
                                                                        <img
                                                                            src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                        <span>暂无数据</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }}
                                                        pagination={
                                                            {
                                                                current: this.state.pageNumthree,
                                                                total: totalFour,
                                                                pageSize: pageSizethree,
                                                                onChange: this.pageChangeThree,
                                                                onShowSizeChange: this.onShowSizeChangeThree,
                                                                pageSizeOptions: ['10', '20', '30', '40'],
                                                                showSizeChanger: true,
                                                                // position:'bottomRight'
                                                            }
                                                        }
                                                />}

                                            </div>
                                            <div className={'footer-btn'}>
                                                <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                                <Button type={'primary'} onClick={() => this.next()}>下一步</Button>
                                            </div>
                                            {/*添加资源*/}
                                            {sourceUpdateModel}
                                            {/*新增1.4.2实训课程*/}
                                            <Modal
                                                title={'添加实训课程'}
                                                visible={showAddsxkcModel}
                                                width={660}
                                                destroyOnClose={true}
                                                closable={false}
                                                maskClosable={false}
                                                centered={true}
                                                className={'addziyuanModal addxzsxkcModal'}
                                                wrapClassName="wappop_up"
                                                footer={
                                                    <div className='footer-btn'>
                                                        <Button className={'quxiao'} onClick={() => this.setState({
                                                            showAddsxkcModel: false,
                                                            courseId: '',
                                                            resourceCourseDetail: {}
                                                        })}>取消</Button>
                                                        <Button type='primary'
                                                                onClick={this.saveTargetResourceCourse}>提交</Button>
                                                        {
                                                            courseId ? "" : <Button type='primary'
                                                                                    onClick={() => this.saveTargetResourceCourse(true)}>提交并继续添加</Button>
                                                        }
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <AddShixunkc wrappedComponentRef={form => {
                                                        this.AddShixunkc = form;
                                                    }} resourceCourseDetail={resourceCourseDetail}
                                                                 majorList={majorList}></AddShixunkc>
                                                </div>
                                            </Modal>
                                            {/*新增1.4.3实训课程*/}
                                            <Modal
                                                title={'添加实训课程'}
                                                visible={showAddsxkcxsModel}
                                                width={660}
                                                destroyOnClose={true}
                                                closable={false}
                                                maskClosable={false}
                                                centered={true}
                                                className={'addziyuanModal addxzsxkcModal addxzsxkcModal_sel'}
                                                wrapClassName="wappop_up"
                                                footer={
                                                    <div className='footer-btn'>
                                                        <Button className={'quxiao'} onClick={() => this.setState({
                                                            showAddsxkcxsModel: false,
                                                            subjectId: '',
                                                            resourceSubjectDetail: {}
                                                        })}>取消</Button>
                                                        <Button type='primary'
                                                                onClick={this.saveTargetResourceSubject}>提交</Button>
                                                        {
                                                            subjectId ? '' : <Button type='primary'
                                                                                     onClick={() => this.saveTargetResourceSubject(true)}>提交并继续添加</Button>
                                                        }
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <AddShixunkcxs wrappedComponentRef={form => {
                                                        this.AddShixunkcxs = form;
                                                    }} resourceSubjectDetail={resourceSubjectDetail}
                                                                   majorList={majorList}></AddShixunkcxs>
                                                </div>
                                            </Modal>
                                            {/*新增1.4.4添加仿真实训材料*/}
                                            <Modal
                                                title={'添加仿真实训教材'}
                                                visible={showAddfzsxclModel}
                                                width={660}
                                                destroyOnClose={true}
                                                closable={false}
                                                maskClosable={false}
                                                centered={true}
                                                className={'addziyuanModal addxzsxkcModal addxzsxkcModal_sel'}
                                                wrapClassName="wappop_up"
                                                footer={
                                                    <div className='footer-btn'>
                                                        <Button className={'quxiao'} onClick={() => this.setState({
                                                            showAddfzsxclModel: false,
                                                            bookId: '',
                                                            resourceBookDetail: {}
                                                        })}>取消</Button>
                                                        <Button type='primary'
                                                                onClick={this.saveTargetResourceBook}>提交</Button>
                                                        {
                                                            bookId ? '' : <Button type='primary'
                                                                                  onClick={() => this.saveTargetResourceBook(true)}>提交并继续添加</Button>
                                                        }
                                                    </div>
                                                }
                                            >
                                                <div>
                                                    <AddFzsxcl wrappedComponentRef={form => {
                                                        this.AddFzsxcl = form;
                                                    }} resourceBookDetail={resourceBookDetail}
                                                               majorList={majorList}></AddFzsxcl>
                                                </div>
                                            </Modal>
                                        </div>
                                        <Drawer
                                            title={'查看资料'}
                                            visible={examine}
                                            onOk={this.handExamine}
                                            onClose={this.onCancel}
                                            destroyOnClose={true}
                                            closable={true}
                                            maskClosable={true}
                                            wrapClassName="wappop_up lookMaterial"
                                            footer={
                                                <div className='addModalBtn' style={{display: 'none'}}>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({examine: false})}>取 消</Button>
                                                    <Button type='primary'>确定</Button>
                                                </div>
                                            }
                                        >
                                            <LookCheck id={evidenceId}/>
                                        </Drawer>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
                <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
            </div>
        );
    }
}

PlatformSchoolresources = Form.create()(PlatformSchoolresources);
export default PlatformSchoolresources;
