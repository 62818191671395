import React, {Component} from 'react'
import {Input, Select, Form, Cascader,Radio} from "antd";
import "./index.scss";


class Professional extends React.Component {
    state = {
        majorList:this.props.majorList,
    }

    componentDidMount() {
        let {data} = this.props;
        console.log('专业的 Data：：：', data)
        if(data){
            if(data.majorTwo){
            let courseMajor = []
            courseMajor.push(data ? data.majorOne : '')
            courseMajor.push(data ? data.majorTwo : '')
            courseMajor.push(data ? data.majorCode : '')
            this.setState({
                data: data
            }, () =>{
                this.props.form.setFieldsValue({
                    majorName: courseMajor,
                    majorCode: data.majorCode,
                    isBase:data.isBase
                })
            })
            }
            
        }
    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log('专业的 nextProps：：：', nextProps.data)
    //     if(nextProps.data !== this.state.data){
    //         let data = nextProps.data
    //         let courseMajor = []
    //         courseMajor.push(data.majorOne)
    //         courseMajor.push(data.majorTwo)
    //         courseMajor.push(data.courseMajor)
    //         this.setState({
    //             data: nextProps.data
    //         }, () =>{
    //             this.props.form.setFieldsValue({
    //                 majorName: courseMajor,
    //                 majorCode: data.majorCode,
    //             })
    //         })
    //     }
    // }


    handleCancel = () =>{
        this.setState({
            addCertificateModal:false,
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {majorList} = this.state
        return (
            <>
                <Form>
                    <Form.Item label="专业名称"  >
                        {
                            getFieldDecorator('majorName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '专业名称不能为空'
                                    },
                                ]
                            })(
                            <Cascader options={majorList}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item className="li_fo">
                        <span className={'label'} style={{width:'134px'}}><span className='red'>*</span>是否为虚拟仿真实训服务的专业：</span>
                        {getFieldDecorator('isBase', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '是否为虚拟仿真实训服务的专业!'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                </Form>
            </>
        )
    }
}


Professional = Form.create()(Professional);
export default Professional;