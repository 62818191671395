/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-08-25 15:08:57
 * @LastEditors: nliu
 * @LastEditTime: 2021-09-03 14:27:28
 */
export const contestLevelList = [
  {
    lable:'国家级',
    value:1
  }, {
    lable:'省部级',
    value:2
  }
]