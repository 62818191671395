import React from "react";
import {Col, Radio} from "antd";
import '../index.scss';
import VideoPlayer from "../../../components/Video";

const SelectItemView = (props) => {
    const {title, left, right, onChange, firstIndex} = props

    return (<Col span={24} className="li-col">
        <span className="span-left">{title}</span>
        <Radio.Group className="nav-tab" onChange={onChange} defaultValue={null}>
            <Radio.Button key={-1} value={null}>全部</Radio.Button>
            <Radio.Button key={0} value={firstIndex}>
                {left}
            </Radio.Button>
            <Radio.Button key={1} value={firstIndex + 1}>
                {right}
            </Radio.Button>
        </Radio.Group>
    </Col>)
}
SelectItemView.defaultProps = {
    firstIndex: 0,
}
export default SelectItemView
