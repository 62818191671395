// 查看佐证材料
import React from "react";
import {
    getEvidences,
    deleteEvidence
} from '../../../../api/platformAbility';
import {Button, DatePicker, Drawer, Form, Input, message, Modal, Radio, Table} from 'antd';
import "./index.scss";
import moment from "moment";
import LookCheck from "../../../components/look_Check";
class Lookevidence extends React.Component {
    state = {
        pageNum:1,//页数
        pageSize:10,//条数
        total:0,
        taskBookTable:[],
        examine: false,
        resourceId:'',
    };

    componentDidMount() {
        //查询佐证材料列表
        this.getMaterials()
    }
    //查询佐证材料列表
    getMaterials = () =>{
        const {materialTargetId} = this.props
        // console.log('materialTargetId',materialTargetId);return ;
        let data = {
            id: materialTargetId
        }
        getEvidences(data).then(e=>{
            if (e){
                this.setState({
                    taskBookTable: e
                })
            }
        })
    }
    //删除
    deleteTaskBook = (record) => {
        let data = {
            id: record.id
        }
        deleteEvidence(data).then(e=>{
            if (e){
                message.success("删除成功")
                console.log("this.state.taskBookTable.length",this.state.taskBookTable.length)
                if (this.state.taskBookTable.length <= 1){
                    this.props.setMediaFlag(this.props.mediaFlagMark)
                }
                //查询佐证材料列表
                this.getMaterials()

            }
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({ pageNum });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageSize });
    }
    //查看资源
    checkFile = (record) => {
        if (record.transStatus === 2){
            message.warning("该文件转码失败")
            return ;
        }
        if (record.transStatus === 3){
            message.warning("转码中")
            return ;
        }
        this.setState({
            resourceId: record.id
        },()=>{
            this.setState({
                examine: true,
            })
        })
    }
    onCancel = () =>{
        this.setState({
            examine:false
        })
    }
    render() {
        const {getFieldDecorator }=this.props.form
        const { taskBookTable,total,pageSize,examine,resourceId} = this.state
        const columns = [
            {
                title: '材料名称',
                dataIndex: 'resourceName',
                key: 'resourceName',
                width: 200,
            },
            {
                title: '格式',
                dataIndex: 'resourceSuffix',
                key: 'resourceSuffix',
                width: 100,
                render(text, record){
                    return(
                        <span>{record.transStatus === 1?record.transSuffix:text}</span>
                    )
                }
            },
            {
                title: '大小',
                dataIndex: 'resourceSize',
                key: 'resourceSize',
                width: 100,
                render(text, record){
                    return(
                        <span>{record.transStatus === 1?record.transSize>=1048576?(record.transSize / 1048576).toFixed(2) + "MB":(record.transSize / 1024).toFixed(2) + "K":
                            text>=1048576?(text / 1048576).toFixed(2) + "MB":(text / 1024).toFixed(2) + "K"}</span>
                    )
                }
            },
            {
                title: '上传时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 100,
                render: (e) =><div>{moment(e).format('YYYY-MM-DD HH:mm')}</div>
            },
            {
                title: '操作',
                key: 'operation',
                width: 150,
                render:(text, record) => (
                    <div className='operating'>
                        <div className="li">
                            <span onClick={()=>this.checkFile(record)}>查看</span>
                            <span onClick={() => this.deleteTaskBook(record)}>删除</span>
                        </div>
                    </div>
                )
            },
        ]
        return (
            <div className="Addevidence_cont" >
                    <span className="span" style={{paddingTop:'0'}}>共计{taskBookTable.length}个</span>
                    <div className="table_bot">
                        <Table columns={columns} dataSource={taskBookTable} pagination={false}
                               locale={{emptyText:<><div className="Empty-content">
                                       <div className="text">
                                           <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                           <span>暂无数据</span>
                                       </div>
                                   </div></>}}
                               // pagination={
                               //     {
                               //         total: total,
                               //         pageSize: pageSize,
                               //         onChange: this.pageChange,
                               //         onShowSizeChange: this.onShowSizeChange,
                               //         pageSizeOptions: ['10', '20', '30', '40'],
                               //         showSizeChanger: true,
                               //     }
                               // }
                        />
                    </div>
                {/*查看资料*/}
                <Drawer
                    title={'查看资料'}
                    visible={examine}
                    onOk={this.handExamine}
                    onClose={this.onCancel}
                    destroyOnClose={true}
                    closable={true}
                    maskClosable={true}
                    wrapClassName="lookMaterial"
                    footer={
                        <div className='addModalBtn' style={{display:'none'}}>
                            <Button className={'quxiao'} onClick={() => this.setState({examine: false})}>取 消</Button>
                            <Button type='primary'>确定</Button>
                        </div>
                    }
                >
                    <LookCheck id={resourceId}/>
                </Drawer>
            </div>
        );
    }
}
Lookevidence = Form.create()(Lookevidence);
export default Lookevidence;
