import React, {Component} from 'react'
import {Form, Input, Button, Checkbox, message, Row, Col} from 'antd'
import {withRouter} from 'react-router-dom'
import './index.scss'
import LeftComponent from '../demo_Info/components/Left_Efficiencyside'
import Heder from "../../components/header";

import  Demo1 from '../demo1'
class DemoInfo extends Component {

    state = {
        id: this.props.location.state!=null?this.props.location.state.id:5
    };
    dismiss=()=>{
        this.props.history.goBack()
    }
    render() {
        const id=this.props.location.state!=null?this.props.location.state.id:5
        console.log("id======="+id)
        return (
            <div className="Demo_LevelOne">
            
                <Heder/>

                <div className="content">
                <Row>
                    <Col span={4}>
                        <div>
                            <LeftComponent id={id}></LeftComponent>
                        </div>
                    </Col>
                    <Col span={20}>
                        
                        <div className="right_content">
                        <div  style={{paddingTop:"30px",color:"#2A283D",fontSize:"16px",marginLeft:"40px"}}>
                           <span className="title" >1.3 后勤副园长工作流程</span>
                        </div>
                            <Demo1></Demo1>
                        </div>
                    </Col>
                </Row>
                </div> 
            </div>
        )
    }
}


export default withRouter(Form.create()(DemoInfo))