// 任务书管理
import React from "react";
import {Button, Col, Form, message, Modal, Radio, Row, Table} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import {withRouter} from "react-router-dom";
import {selectRegion, taskBookTable} from '../../api/index';
import {resetPassword} from '../../api/login';
import {finalCheckTaskBook} from '../../api/taskBook';
import Cookies from "js-cookie";
import RenderButton from '../../components/renderButton/index'
import moment from "moment";
import SelectItemView from "./component/select_item_view";
import {isNotEmpty} from "../../until/isEmpty";


export default Form.create()(
    withRouter(
        class Index extends React.Component {
            state = {
                pageNum: 1,//页数
                pageSize: 10,//条数
                total: 0,
                taskBookTable: [],
                provinceList: [],
                isReporting: null,
                category: null,
                isFinalCheck: null,
                provinceCode: null,
            };

            componentDidMount() {
                const token = Cookies.get("vsToken");
                if (token) {
                    this.props.history.replace("mission_statement");
                } else {
                    this.props.history.replace("login")
                }
                return;

                let userInfo = Cookies.get('userInfo')

                if (isNotEmpty(userInfo)) {
                    const roleType = JSON.parse(userInfo).roleType
                    switch (roleType) {
                        case 1:
                            this.selectTaskBookTable();
                            this.selectRegion();
                            break
                        case 2:
                            this.selectTaskBookTable();
                            this.selectRegion();
                            break
                        case 3:
                            // 学校,直接到学校页面
                            this.props.history.replace("mission_statement")
                            break
                        default:
                            break
                    }
                } else {
                    this.props.history.replace("login")
                }

            }

            async selectRegion() {
                const region = await selectRegion();
                region.unshift({code: null, name: '全部'})
                this.setState({
                    provinceList: region
                });
            }

            async selectTaskBookTable() {
                let {pageNum, pageSize, isReporting, category, isFinalCheck, provinceCode} = this.state;
                const params = {
                    pageNum: pageNum,
                    pageSize: pageSize,
                    isReporting: isReporting,
                    category: category,
                    isFinalCheck: isFinalCheck,
                    provinceCode: provinceCode,
                    writeStatus: this.state.writeStatus,
                };
                const page = await taskBookTable(params);
                this.setState({
                    taskBookTable: page.data,
                    total: page.total
                });
            }

            componentWillUnmount() {
                clearInterval(this.timer);
            }

            isReportingChange = e => {
                this.setState({
                    pageNum: 1,
                    isReporting: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            catgoryChange = e => {
                this.setState({
                    pageNum: 1,
                    category: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            isWriteStatusChange = e => {
                this.setState({
                    pageNum: 1,
                    writeStatus: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            provinceChange = e => {
                this.setState({
                    pageNum: 1,
                    provinceCode: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            //分页切换
            pageChange = (pageNum) => {
                this.setState({pageNum}, () => this.selectTaskBookTable());
            }
            //分页条数切换
            onShowSizeChange = (pageNum, pageSize) => {
                this.setState({pageSize}, () => this.selectTaskBookTable());
            }
            lookTaskBook = (record) => {
                let param = {
                    taskId: record.taskId,
                    schoolEduCode: record.schoolEduCode,
                    provinceCode: record.provinceCode
                };
                let schoolInfo = {
                    taskId: record.taskId,
                    schoolEduCode: record.schoolEduCode,
                    schoolName: record.schoolName,
                    baseName: record.baseName,
                    provinceCode: record.provinceCode,
                    category: record.category,
                    writeYear: record.writeYear
                }
                Cookies.set('schoolInfo', schoolInfo);
                Cookies.set('previewParam', param);
                window.open(`#/construction_BasicInformation`)
            }
            updateTaskBook = (record) => {
                console.log('record', record)
                let schoolInfo = {
                    taskId: record.taskId,
                    schoolEduCode: record.schoolEduCode,
                    schoolName: record.schoolName
                }
                Cookies.set('schoolInfo', schoolInfo);
                this.props.history.push('/construction_plan')
            }
            writeTarget = (record) => {
                console.log('record', record)
                let schoolInfo = {
                    taskId: record.taskId,
                    schoolEduCode: record.schoolEduCode,
                    schoolName: record.schoolName,
                    baseName: record.baseName,
                    provinceCode: record.provinceCode,
                    category: record.category
                }
                Cookies.set('schoolInfo', schoolInfo);
                this.props.history.push('/money_Perform')
            }
            resetPassword = (record) => {
                Modal.confirm({
                    className: 'content-warp',
                    title: <div>
                        重置密码确认
                    </div>,
                    centered: true,
                    content: '您确认要将' + record.schoolName + '的登录密码重置为123456吗？',
                    onOk: async () => {
                        resetPassword(record.schoolEduCode).then((res) => {
                            if (res) {
                                message.success("重置成功")
                            }
                        }).catch((err) => {
                            console.log(err)
                        });
                    }
                })
            }
            updateFinalCheck = (record) => {
                Modal.confirm({
                    className: 'content-warp',
                    title: <div>
                        消息提醒
                    </div>,
                    centered: true,
                    content: '终验完成后，任务书修改按钮将关闭，请确认终验完成',
                    onOk: async () => {
                        finalCheckTaskBook({
                            taskId: record.taskId,
                            isFinalCheck: 1
                        }).then((res) => {
                            this.selectTaskBookTable();
                        });
                    }
                })
            }

            getManagerColumns() {
                // todo 学校代码列删除
                // todo 效能的,删除学校代码,审核时间,审核状态去除
                // todo 需要确认是否用isFinalCheck字段查询填报状态
                // todo 填报时间确认要不要
                return (<Row className="IndexHome-top">
                    <SelectItemView title="任务书填报状态" left="未完成" right="已完成" onChange={this.isWriteStatusChange}/>
                    <Col span={24} className="li-col">
                        <span className="span-left">院校所在省份</span>
                        <Radio.Group onChange={this.provinceChange} className="nav-tab" defaultValue={null}>
                            {
                                this.state.provinceList.map((item, index) => {
                                    return <Radio.Button key={index}
                                                         value={item.code}>{item.name}</Radio.Button>
                                })
                            }
                        </Radio.Group>
                    </Col>
                </Row>)
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {taskBookTable, total, pageSize, provinceList} = this.state;
                const columns = [
                    {
                        title: '序号',
                        dataIndex: 'key',
                        key: 'key',
                        align: 'center',
                        width: 80,
                        fixed: 'left',
                        render: (text, record, index) => `${index + 1}`
                    },
                    {
                        title: '学校名称',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        width: 210,
                        fixed: 'left',
                        render: (e) => e ? <span>{e}</span> : <span>--</span>
                    },

                    {
                        title: '所在省份',
                        dataIndex: 'province',
                        key: 'province',
                        width: 210,
                        render: (e) => e ? <span>{e}</span> : <span>--</span>
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'baseName',
                        key: 'baseName',
                        width: 210,
                        render: (e) => e ? <span>{e}</span> : <span>--</span>
                    },
                    {
                        title: '任务书填报状态',
                        dataIndex: 'writeStatus',
                        key: 'writeStatus',
                        width: 150,
                        render: (e) => <span>{e === 1 ? "已完成" : "未完成"}</span>
                    },
                    {
                        title: '填报完成时间',
                        // 到底是用writeTime还是updateTime
                        dataIndex: 'writeTime',
                        key: 'writeTime',
                        width: 210,
                        render: (e) => e ? <span>{moment(e).format('YYYY-MM-DD HH:mm:ss')}</span> : <span>--</span>
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        fixed: 'right',
                        width: 300,
                        render: (text, record) => (
                            <div className='operating'>
                                <div className="li fin-list">
                                    <RenderButton id='VB-01-A01-a_btnViewTaskBook'>
                                        <Button disabled={record.writeStatus !== 1}
                                                onClick={() => this.lookTaskBook(record)}>
                                            <span>查看</span>
                                        </Button>
                                    </RenderButton>
                                </div>
                            </div>
                        )
                    },
                ]


                return (
                    <div className="MissionManagement_main">
                        <Heder navName={'index'}/>
                        <div className="Management_table main_cont">
                            {this.getManagerColumns()}
                            <div className="home-list">
                                <div className="top-title">
                                    <h3><span>共{total}条任务书</span></h3>
                                </div>
                                <div className="bottom-table">
                                    <Table columns={columns} dataSource={taskBookTable} scroll={{x: 1600}}
                                           locale={{
                                               emptyText: <>
                                                   <div className="Empty-content">
                                                       <div className="text">
                                                           <img
                                                               src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                           <span>暂无数据</span>
                                                       </div>
                                                   </div>
                                               </>
                                           }}
                                           pagination={
                                               {
                                                   total: total,
                                                   pageSize: pageSize,
                                                   onChange: this.pageChange,
                                                   onShowSizeChange: this.onShowSizeChange,
                                                   pageSizeOptions: ['10', '20', '30', '40'],
                                                   showSizeChanger: true,
                                               }
                                           }
                                    />
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
