// 添加专业群
import React, {Component} from 'react';
import {Spin, Form, Input, message, Tooltip, Radio,Select} from 'antd';
import moment from "moment";
import {withRouter} from "react-router-dom";
import "./index.scss";
import {uploadFile} from "../../../api/uploadFile";
import {Upicon,Upiconwc,Upicondele} from "../../../components/iconSvg";
import {selectMajorList} from "../../../api/constructionResults";
import Cookies from "js-cookie";
const { Option } = Select;
const { TextArea } = Input;
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
class AddProfessionalgroup extends Component {
    state = {
        name:'',
        schoolEduCode:JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        targetId:this.props.targetId,
        effectMajorList:[]
    };

    componentDidMount() {
        this.selectMajorList()
        let {majorGroup} = this.props;
        if(majorGroup){
            let upFile = {
                originName: majorGroup ? majorGroup.resourceName : '',
                fileId: majorGroup ? majorGroup.fileId  : '',
                fileSuffix: majorGroup ? majorGroup.resourceSuffix : '',
                fileSize: majorGroup ?  ( majorGroup.fileSize / 1024).toFixed(2) : ''
            }
            this.setState({
                file: upFile,
                data: majorGroup,
            })
            console.log('data11111111111', majorGroup)
            this.props.form.setFieldsValue({
                majorGroupName: majorGroup.majorGroupName ? majorGroup.majorGroupName : '',
                isBase:majorGroup.isBase,
                effectMajorId:majorGroup.effectMajorIdList?majorGroup.effectMajorIdList:[],
                fileId: majorGroup.fileId ? majorGroup.fileId : '',
            })
        }
    }
    selectMajorList = () =>{
        selectMajorList({schoolEduCode:this.state.schoolEduCode,targetId:this.props.targetId}).then((res) => {
            if (res){
                this.setState({
                    effectMajorList:res,
                });
            }
        });
    }
    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    getState = () =>{
        return this.state.file
    }

    render() {
        const {getFieldDecorator }=this.props.form
        const {file,effectMajorList} = this.state;
        const {} = this.props
        return (
            <div className="Comment-AddProfessionalgroup">
                <Form>
                    <Form.Item label="专业群名称" className="li_foa">
                        {
                            getFieldDecorator('majorGroupName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '专业群名称'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入专业群名称' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`majorGroupName`) ? this.props.form.getFieldValue(`majorGroupName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item className='li_fo li_form li_foa'>
                        <span className={'label'}><span className='red'>*</span>专业名称：</span>
                        {getFieldDecorator('effectMajorId', {
                            initialValue: [],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择专业!'
                                },
                            ],
                        })(
                            <Select mode="multiple" placeholder="请选择专业">
                                {effectMajorList.map(item => (
                                    <Option key={item.id} value={item.id}>{item.majorName}</Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item className='li_fo li_foa'>
                        <span className={'label'}><span className='red'>*</span>是否依托基地开发：</span>
                        {getFieldDecorator('isBase', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否依托基地开发!'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item className="li_formup li_foa">
                        <span className={'label'}><span className='red'>*</span>佐证材料：</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {/*上传中*/}
                                    {file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                </ul>
                            </div>
                        )}
                    </Form.Item>

                </Form>
            </div>
        );
    }
}

const addProfessionalgroup = Form.create()(AddProfessionalgroup);
export default addProfessionalgroup;

