// 预期成效和保障-预览
import React from "react";
import {Form, Row, Col, Radio, Table, Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import {UpIcon} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {effectPreview} from "../../api/preview";
import Cookies from "js-cookie";
import FileLook from "../components/file_look";
import { BaseUrl } from "../../config/common";

export default Form.create()(
    withRouter(
        class BuildingProject extends React.Component {
            state = {
                previewParam: Cookies.get("previewParam") ? JSON.parse(decodeURI(Cookies.get("previewParam"))) : {},
                effect: [],
                openUrl:"",
                isShow:false
            }

            componentDidMount() {
                this.effectPreview();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
                console.log("..........")
            }

            effectPreview = () => {
                let {previewParam} = this.state;
                effectPreview({taskId: previewParam.taskId, schoolEduCode: previewParam.schoolEduCode}).then(res => {
                    if (res) {
                        this.setState({
                             effect: res

                        });
                    }
                })
            }
            // 左侧跟随屏幕
            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:60px;position:fixed;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }
            
            next=()=>{
                this.props.history.push("/seal_Pile")
            }

            lookFile=(itemFile)=>{
            
                const url=BaseUrl+itemFile.filePath+'/'+itemFile.fileName
                this.setState({
                    openUrl:url,
                    isShow:true
                })
            }
            /**
             *  隐藏预览的回调
            */
            dismiss = () =>{
                this.setState({
                    isShow:false
                })
            }

            render() {
                const {getFieldDecorator,} = this.props.form
                const {effect ,isShow,openUrl} = this.state;
                return (
                    <div>
                    <div className="planned_Performance" style={{minHeight: "1200px"}}>
                        <Heder/>
                        <div className="Task-preview clearfix">
                            <div className="main_cont" id="main_cont">
                                <div className="Task-bottom-preview">
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="preview_left" id="leftmain" style={{"top": '0px'}}>
                                            <LeftMeinlist processCode={'1-6'}/>
                                        </Col>
                                        <Col span={20} className="preview_right">
                                            <div className='content'>
                                                <div className='top-right'>
                                                    <h6 className='mission '>任务书预览页</h6>
                                                    <span><i><b>6</b>/7</i></span>
                                                </div>
                                                <div className='bottom'>
                                                    <h2>6. 预期成效和保障</h2>
                                                    {effect ? effect.map((item, index) => (
                                                        <div className='list'>
                                                            <h3>{item.seniorName}</h3>
                                                            <div className='title'>
                                                                {item ? item.effectVos.map((itemTwo, indexTwo) => (
                                                                    <div>
                                                                        <h4>{itemTwo.effectType === 1 ? '成效综述' : itemTwo.effectType === 2 ? "保障措施综述" : "特色创新综述"}</h4>
                                                                        <div className='contt'>
                                                                            <h4>{itemTwo.overview}</h4>
                                                                        </div>
                                                                        {itemTwo ? itemTwo.effectDetailPreviewVos.map((itemThree, indexThree) => (
                                                                            <div className='biaozhi'>
                                                                                <h4>{itemTwo.effectType === 1 ? '标志性成果' : itemTwo.effectType === 2 ? '保障措施' : '创新点'}{indexThree + 1} </h4>
                                                                                {/*<h4>{itemTwo.effectType === 1 ? '标志性成果' + indexThree : itemTwo.effectType === 2 ? '保障措施' + indexThree : '创新点' + indexThree+1} ：{itemThree.objectName}</h4>*/}
                                                                                <div className='outcome'>
                                                                                    <h4>{itemThree.objectContent} </h4>
                                                                                </div>

                                                                            {
                                                                                itemThree.files?itemThree.files.map((itemFile,itemIndex)=>(
                                                                                    <div className='attachment' onClick={this.lookFile.bind(this,itemFile)}>
                                                                                    <h4>附件：{itemFile.originFn}</h4>
                                                                                  <div className='chakan'>
                                                                                  <UpIcon/>
                                                                                  <span>查看</span>
                                                                                  </div>
   
                                                                                 </div>
                                                                                 ) ):""
                                                                            }    

                                                                            

                                                                        </div>
                                                                        )) : ""}
                                                                    </div>
                                                                )) : ""}
                                                            </div>
                                                        </div>
                                                    )) : ''}

                                                    {/*<div className='list'>*/}
                                                    {/*    <h3>保障措施</h3>*/}
                                                    {/*    <div className='title'>*/}
                                                    {/*        <h4>措施综述</h4>*/}
                                                    {/*        <div className='contt'>*/}
                                                    {/*            <h4>秉承“质量立校、特色兴校、品牌强校”的发展思路，着力培养“品德高尚、技艺精湛，踏实肯干、敢为人先，家国情怀、走向世界”的高素质技术技能人才，将学校建设成为服务湖北发展、助力中部崛起、代表中国高职、走上世界舞台的中国特色高水平现代化高职学校。到2023年，建成全国领先的综合性高职学校。 学校现代化治理、高质量人才培养、多样化教学模式、高水平双师队伍、高支撑信息化建设、高品质社会服务、高标准国际办学、高融合产教平台、高效能技术技能创新平台、高成效“1+X”试点、高水平专业群建设等走在全国前列。为服务发展国家战略、“一带一路”倡议和湖北省“一芯两带三区”区域与产业发展战略，为实现“中部崛起”提供高素质技术技能人才支撑，形成具有鲜明区域特色、国 </h4>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='biaozhi'>*/}
                                                    {/*            <h4> 保障措施1：xxxxxxxxxxxx</h4>*/}
                                                    {/*            <div className='outcome'>*/}
                                                    {/*                <h4>成果说明嘻嘻嘻嘻嘻嘻嘻嘻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻 </h4>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='biaozhi'>*/}
                                                    {/*            <h4>保障措施2：xxxxxxxxxxxx</h4>*/}
                                                    {/*            <div className='outcome'>*/}
                                                    {/*                <h4>成果说明嘻嘻嘻嘻嘻嘻嘻嘻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻 </h4>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='biaozhi'>*/}
                                                    {/*            <h4>保障措施3：xxxxxxxxxxxx</h4>*/}
                                                    {/*            <div className='outcome'>*/}
                                                    {/*                <h4>成果说明嘻嘻嘻嘻嘻嘻嘻嘻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻 </h4>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}


                                                    {/*    </div>*/}

                                                    {/*</div>*/}
                                                    {/*<div className='list'>*/}
                                                    {/*    <h3>特色创新</h3>*/}
                                                    {/*    <div className='title'>*/}
                                                    {/*        <h4>特色创新综述</h4>*/}
                                                    {/*        <div className='contt'>*/}
                                                    {/*            <h4>秉承“质量立校、特色兴校、品牌强校”的发展思路，着力培养“品德高尚、技艺精湛，踏实肯干、敢为人先，家国情怀、走向世界”的高素质技术技能人才，将学校建设成为服务湖北发展、助力中部崛起、代表中国高职、走上世界舞台的中国特色高水平现代化高职学校。到2023年，建成全国领先的综合性高职学校。 学校现代化治理、高质量人才培养、多样化教学模式、高水平双师队伍、高支撑信息化建设、高品质社会服务、高标准国际办学、高融合产教平台、高效能技术技能创新平台、高成效“1+X”试点、高水平专业群建设等走在全国前列。为服务发展国家战略、“一带一路”倡议和湖北省“一芯两带三区”区域与产业发展战略，为实现“中部崛起”提供高素质技术技能人才支撑，形成具有鲜明区域特色、国 </h4>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='biaozhi'>*/}
                                                    {/*            <h4> 创新点1：xxxxxxxxxxxx</h4>*/}
                                                    {/*            <div className='outcome'>*/}
                                                    {/*                <h4>成果说明嘻嘻嘻嘻嘻嘻嘻嘻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻 </h4>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='biaozhi'>*/}
                                                    {/*            <h4>创新点2：xxxxxxxxxxxx</h4>*/}
                                                    {/*            <div className='outcome'>*/}
                                                    {/*                <h4>成果说明嘻嘻嘻嘻嘻嘻嘻嘻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻 </h4>*/}
                                                    {/*            </div>*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className='biaozhi'>*/}
                                                    {/*            <h4>创新点3：xxxxxxxxxxxx</h4>*/}
                                                    {/*            <div className='outcome'>*/}
                                                    {/*                <h4>成果说明嘻嘻嘻嘻嘻嘻嘻嘻寻寻寻寻寻寻寻寻寻寻寻寻寻寻寻 </h4>*/}

                                                    {/*            </div>*/}
                                                    {/*            <div className='attachment'>*/}
                                                    {/*                <h4>附件：文件名称xxxxx.pdf</h4>*/}
                                                    {/*                <div className='chakan'>*/}
                                                    {/*                    <UpIcon/>*/}
                                                    {/*                    <span>查看</span>*/}
                                                    {/*                </div>*/}

                                                    {/*            </div>*/}
                                                    {/*        </div>*/}


                                                    {/*    </div>*/}

                                                    {/*</div>*/}
                                                    <div className="bottom-but">
                                                        <Button className="but-li" type="primary" onClick={this.next}>
                                                            下一页
                                                        </Button>
                                                    </div>


                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                    <FileLook  openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
                    </div>
                );
            }
        }
    )
);
