import { post } from '../../until/axios_instans';

// 获取保障、成效和特色的综述
export function getEffectReview(data) {
    return post(window.apiShixun+'/api/v1/effect/getEffectReview', data);
}
// 保存保障、成效和特色的综述
export function saveEffectReview(data) {
    return post(window.apiShixun+'/api/v1/effect/saveEffectReview', data);
}
// 修改保障、成效和特色的综述
export function updateEffectReview(data) {
    return post(window.apiShixun+'/api/v1/effect/updateEffectReview', data);
}
// 保存保障、成效和特色的综述详情
export function saveEffectReviewDetail(data) {
    return post(window.apiShixun+'/api/v1/effect/saveEffectReviewDetail', data);
}
// 修改保障、成效和特色的综述详情
export function updateEffectReviewDetail(data) {
    return post(window.apiShixun+'/api/v1/effect/updateEffectReviewDetail', data);
}
// 删除保障、成效和特色的综述详情
export function deleteEffectReviewDetail(data) {
    return post(window.apiShixun+'/api/v1/effect/deleteEffectReviewDetail', data);
}
export function effectPreview(data) {
    return post(window.apiShixun+'/api/v1/preview/effectPreview', data);
}
// 更新流程状态
export function updateProcessStatus(data) {
    return post(window.apiShixun+'/api/v1/taskprocess/updateProcessStatus', data);
}






