import React, { Component } from "react";
import { Input, Form } from "antd";

const FormItem = Form.Item;
class Forms extends React.Component {
  state = {
    file: null,
  };

  componentDidMount() {
    let { record } = this.props;

    if (record) {
      this.props.form.setFieldsValue({
        ...record,
      });
    } else {
      this.props.form.resetFields();
    }
  }

  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <FormItem label="实验室名称">
          {getFieldDecorator("taskName", {
            rules: [
              {
                required: true,
                message: "请填写任务名称",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度在校生计划实训学时">
          {getFieldDecorator("taskName1", {
            rules: [
              {
                required: true,
                message: "请填写年度在校生计划实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度计划外申请实训学时">
          {getFieldDecorator("taskName2", {
            rules: [
              {
                required: true,
                message: "请填写年度计划外申请实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度最大实训学时">
          {getFieldDecorator("taskName3", {
            rules: [
              {
                required: true,
                message: "请填写年度最大实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
