import {post, get} from '../../until/axios_instans';
// 共用接口

// 城市列表,onlyUsers是否只返回当前用户所在的省,默认false
export function selectRegion(onlyUsers = false) {
    return post(window.apiShixun + '/api/v1/region/selectRegion', {}, selectRegion);
}

//下载导入模板
export const downTemplate = (type) => {
    return window.apiShixun + '/api/v1/import/downloadBusTemplate?templateNo=' + type
}

// 获取专业下拉
export function getSharingMajor(data) {
    return post(window.apiShixun + '/api/v1/sharing/getSharingMajor', data);
}

// 获取专业下拉
export function getSharingMajorList(data) {
    return post(window.apiShixun + '/api/v1/sharing/getSharingMajorList', data);
}

// 下载导入模板
export const downExcelTemplateUrl = window.apiShixun + '/api/v1/sharing/downTemplate';

//新增学生课时
export function addPersonPeriod(data) {
    return post(window.apiShixun + '/api/v1/cultivationTalents/addPersonPeriod', data);
}
//获取学生课时
export function getPersonPeriod(data) {
    return post(window.apiShixun + '/api/v1/cultivationTalents/getPersonPeriod', data);
}
//删除学生课时
export function deletePersonPeriod(data) {
    return post(window.apiShixun + '/api/v1/cultivationTalents/deletePersonPeriod', data);
}
//修改学生课时
export function updatePersonPeriod(data) {
    return post(window.apiShixun + '/api/v1/cultivationTalents/updatePersonPeriod', data);
}
//获取专业
export function getMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/getMajor', data);
}

//删除专业
export function deleteMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/deleteMajor', data);
}
// 修改专业
export function updateMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/updateMajor', data);
}
// 修改专业
export function addMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/addMajor', data);
}


