import {post, get} from '../../until/axios_instans'

export const UploadFilePathname = '/api/v1/fujian/uploadFile'
export const FolderEnum = {US: 'us'}
export const uploadFile = data => post(UploadFilePathname, data)

// 添加佐证材料
export function addTargetFile(targetId, fileIds, schoolEduCode,) {
    return post(window.apiShixun + '/api/v1/target/file/addTargetFile', {targetId, fileIds, schoolEduCode});
}

export function selectTargetFile(targetId, schoolEduCode) {
    return get(window.apiShixun + '/api/v1/target/file/selectTargetFile', {targetId, schoolEduCode});
}

export function deleteTargetFile(targetId, id, schoolEduCode) {
    return post(window.apiShixun + '/api/v1/target/file/deleteTargetFile', {targetId, id, schoolEduCode});
}
