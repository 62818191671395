import React, { Component } from 'react'
import { Button } from "antd"
import "./index.scss"
import RectCmp from './components/Rect'
import { Item } from 'rc-menu';
export default class LiuChengTu2 extends Component {
  timer;
  state = {
    step: 0,
    displayData: [0],
    data: [
      {
        row: 1, dir: 1, data: [{
          id: '1', text: '接收上级部门党务文件并领会文件精神及细则', displayOrder: 1, dir: 'L'
        },
        {
          id: '2', text: '向党总支书记汇报并取得处理意见', displayOrder: 2, dir: 'L'
        },
        {
          id: '3', text: '行政会组织党员领导干部学习文件精神', displayOrder: 3, dir: 'L'
        },
        {
          id: '4', text: '将文件下发各支部，并对文件精神进行学习、落实', displayOrder: 4, dir: 'LD'
        },]
      },

      {
        row: 2, dir: -1, data: [{
          id: '5', text: '将文件落实情况记录并上报上级部门', displayOrder: 5, dir: 'R'
        },
        {
          id: '6', text: '将文件及相关材料存档', displayOrder: 6, dir: 'R'
        }]
      }
    ]
  }
  onClick = (v) => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null;
    }
    let { displayData } = this.state
    if (!displayData.includes(v)) {
      displayData.push(v)
    }
    this.setState({
      step: v,
      displayData
    })
  }

  onAutoPlay = () => {

    if (!this.timer) {
      this.timer = setInterval(() => {
        let { step, displayData } = this.state
        if (step > 6) {
          clearInterval(this.timer)
          this.timer = null;
          return;
        }
        step = step + 1;
        if (!displayData.includes(step)) {
          displayData.push(step)
        }
        this.setState({
          step,
          displayData
        })
      }, 2000)
    }
  }
  render() {
    let { step, data, displayData } = this.state
    let lineV = (arr) => {
      let lth = arr.length - 1;
      return arr[lth].height / 2;
    }
    let getArrawSize = params => {
      if (params == 'L' || params == 'R') {
        return { width: '68px', height: '10px' }
      }
      if (params == 'LD') {
        return { width: '10px', height: '103px' }
      }
      return null
    }

    let displayStatus = (params) => {
      return { display: step == params.displayOrder || displayData.includes(params.displayOrder) ? '' : 'none' }
    }

    let getImagePath = (params) => {
      if (params == 'L') {
        return `https://cdn.zjyjc.com/public_static/YouErYuan/arrow-left.png`
      }
      if (params == 'LD') {
        return `https://cdn.zjyjc.com/public_static/YouErYuan/arrow-down.png`
      }
      if (params == 'R') {
        return `https://cdn.zjyjc.com/public_static/YouErYuan/arrow-right.png`
      }
      return ''
    }

    // 将数据组成二维数组
    const rebuildData = (arr, condition = "LD") => {
      let newData = []
      while (arr.length > 0) {
        let index = arr.findIndex(s => s.dir === condition);
        if (index >= 0) {
          newData.push(arr.splice(0, index + 1))
        } else {
          newData.push(arr.splice(0, arr.length))
        }
      }
      return newData
    }

    let newData = rebuildData([...data])

    console.log(newData)


    let row1Data = data[0].data;
    let row2Data = data[1].data;

    return <>
      <div style={{ backgroundColor: '#ffffff', width: 877, margin: 'auto' }}>

        <Button onClick={this.onAutoPlay} >自动播放</Button>
        
        <div >

          <ul className="horizontal" style={{ display: "flex", alignItems: "center", justifyContent: 'flex-start' }}>
            <li>
              <RectCmp text={row1Data[0].text} displayOrder={row1Data[0].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[0].displayOrder} />
            </li>
            <li style={{ ...displayStatus(row1Data[0]) }}>
              <img src={getImagePath(row1Data[0].dir)} style={{ ...getArrawSize(row1Data[0].dir) }} />
            </li>
            <li style={{ ...displayStatus(row1Data[0]) }}>
              <RectCmp text={row1Data[1].text} displayOrder={row1Data[1].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[1].displayOrder} /></li>


            <li style={{ ...displayStatus(row1Data[1]) }}>
              <img src={getImagePath(row1Data[1].dir)} style={{ ...getArrawSize(row1Data[1].dir) }} />
            </li>
            <li style={{ ...displayStatus(row1Data[1]) }}>
              <RectCmp text={row1Data[2].text} displayOrder={row1Data[2].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[2].displayOrder} /></li>


            <li style={{ ...displayStatus(row1Data[2]) }}>
              <img src={getImagePath(row1Data[2].dir)} style={{ ...getArrawSize(row1Data[2].dir) }} />
            </li>
            <li style={{ ...displayStatus(row1Data[2]) }}>
              <RectCmp text={row1Data[3].text} displayOrder={row1Data[3].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[3].displayOrder} /></li>

          </ul>

          <ul style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}>
            <li style={{ ...displayStatus(row1Data[3]), paddingRight: '84px' }}>
              <img src={getImagePath(row1Data[3].dir)} style={{ ...getArrawSize(row1Data[3].dir) }} />
            </li>
          </ul>

          <ul className="horizontal" style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}>

            <li style={{ ...displayStatus(row2Data[0]) }}>
              <RectCmp text={row2Data[1].text} displayOrder={row2Data[1].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row2Data[1].displayOrder} /></li>

            <li style={{ ...displayStatus(row2Data[0]) }}>
              <img src={getImagePath('R')} style={{ ...getArrawSize('R') }} />
            </li>
            <li style={{ ...displayStatus(row1Data[3]) }}>
              <RectCmp text={row2Data[0].text} displayOrder={row2Data[0].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row2Data[0].displayOrder} />
            </li>


          </ul>

        </div>

      </div>
    </>
  }
}







