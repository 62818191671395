// 导航
import React, {Component} from 'react';
import './index.scss';
import '../../../reset_style/index.less';
import {Modal, Button, Row, Form, Col, Input, message, InputNumber, Table, Tooltip, Spin, Radio} from 'antd';
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import {updatePassword} from '../../../api/login';
import RenderButton from '../../../components/renderButton/index'
import md5 from "js-md5"
import {deleteTargetResourceCourse} from "../../../api/targetResource";
import {TianJia, Upicon, Upicondele, Upiconwc, WenxinTip} from "../../iconSvg";
import {getCanEdit, getTargetId} from "../../../until/cookie";
import {BaseUrl} from "../../../config/common";
import {
    addAndSaveCourse,
    addResources,
    deleteCourse,
    selectServiceResourcesById,
    getPageCourseList,
    updateServiceResources, getResourcesDataList, selectCourseInfoById, selectAllResourcesService
} from "../../../api/target";
import ImportComponent from "../../importComponent/ImportComponent";

@withRouter
class PlatformService_2_2_2 extends Component {
    state = {
        data_2_2_2: {},
        pageNum: 1,//页数
        pageSize: 10,//条数
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        currentEditCourseId: "",
        userInfo: Cookies.get("userInfo") ? JSON.parse(decodeURI(Cookies.get("userInfo"))) : {},
        saveStatus: true,
        // Modal的是否为云端的选项是否禁用
        isDisableModalIsCloudFictitiousSimulationChoose: false
    };

    componentDidMount() {
        this.setState({
            navName: this.props.navName,
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        const {data_2_2_2} = this.state
        if (undefined !== props.data_2_2_2?.id && props.data_2_2_2?.id !== data_2_2_2?.id) {
            this.setState({
                data_2_2_2: props.data_2_2_2
            })
        }
    }

    // section Action
    // 选择是否为虚拟仿真实训
    onModalChangeIsFictitiousSimulation = (e) => {
        let value = e.target.value
        console.log(value)
        if (value === "是") {
            this.setState({
                isDisableModalIsCloudFictitiousSimulationChoose: false
            })
        } else {
            this.setState({
                isDisableModalIsCloudFictitiousSimulationChoose: true
            }, () => {
                this.props.form.setFieldsValue({
                    'isCloudFictitiousSimulation': "否",
                })
            })
        }

    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.get_2_2_2_listData()
        });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageNum: 1, pageSize}, this.get_2_2_2_listData);
    }
    handleCancel = () => {
        this.setState({
            showAddJl: false,
            isDisableModalIsCloudFictitiousSimulationChoose: false
        });
    };

    //删除前二次确认
    deleteAsk = (e) => {
        let data = {
            id: e.id
        }
        deleteCourse(data).then(e => {
            this.setState({
                pageNum: 1
            }, () => {
                message.success("删除成功")
                this.get_2_2_2_listData()
            })
        })
    }

    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    // section Request
    // 获取类表数据
    get_2_2_2_listData = () => {
        let data = {
            targetId: this.state.data_2_2_2.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolEduCode
        }
        getPageCourseList(data).then((res) => {
            let data_2_2_2 = this.state.data_2_2_2
            data_2_2_2.pageData = res.pageData;
            data_2_2_2.socialTrainingNum = res?.socialTrainingNum ? res.socialTrainingNum : 0
            data_2_2_2.socialTrainingFictitiousNum = res?.socialTrainingFictitiousNum ? res.socialTrainingFictitiousNum : 0
            this.setState({
                data_2_2_2: data_2_2_2
            }, () => {
                this.getAllDicMethods()
            })
        })
    }

    // 获取全部数据, 更新2.2.3
    getAllDicMethods = () => {
        let data = {
            id: getTargetId("platform_service"),
        }
        selectAllResourcesService(data).then(e => {
            console.log('selectAllResources,', e)
            // 回调更新 2.2.3的内容
            this.props.dataUpdate_to_2_2_3(e.socialCourseCloudPeopleVo)
        })

    }

    // 添加提交
    add = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let {schoolEduCode, currentEditCourseId} = this.state
                let data = {
                    "courseName": value.courseName,
                    "courseNum": value.courseNum,
                    "id": currentEditCourseId,
                    "isCloudFictitiousSimulation": value.isCloudFictitiousSimulation,
                    "isFictitiousSimulation": value.isFictitiousSimulation,
                    "schoolEduCode": schoolEduCode,
                    "targetId": this.state.data_2_2_2.id,
                    "trainingTime": value.trainingTime
                }
                addAndSaveCourse(data).then(e => {
                    this.setState({showAddJl: false, currentEditCourseId: ""})
                    this.get_2_2_2_listData()
                    message.success(this.state.isEdit === false ? '添加成功' : '修改成功');
                })
            }
        })
    }

    edit = (id) => {
        this.setState({
            showAddJl: true,
            currentEditCourseId: id,
            isEdit: true,
        })
        let data = {
            id: id
        }
        selectCourseInfoById(data).then(e => {
            // section 1
            this.setState({
                isDisableModalIsCloudFictitiousSimulationChoose: e.isFictitiousSimulation === ' 否'
            }, () => {
                this.props.form.setFieldsValue({
                    'courseName': e.courseName,
                    'isFictitiousSimulation': e.isFictitiousSimulation,
                    'isCloudFictitiousSimulation': e.isCloudFictitiousSimulation,
                    'courseNum': e.courseNum,
                    'trainingTime': e.trainingTime,
                })
            })
        })
    }

    //添加提交继续添加
    addContinue = (id) => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let {schoolEduCode} = this.state

                let data = {
                    "courseName": value.courseName,
                    "courseNum": value.courseNum,
                    "isCloudFictitiousSimulation": value.isCloudFictitiousSimulation,
                    "isFictitiousSimulation": value.isFictitiousSimulation,
                    "schoolEduCode": schoolEduCode,
                    "targetId": this.state.data_2_2_2.id,
                    "trainingTime": value.trainingTime
                }
                addAndSaveCourse(data).then(e => {
                    message.success('添加成功');
                    this.setState({
                        currentEditCourseId: ""
                    })
                    this.props.form.resetFields();
                    this.get_2_2_2_listData()
                })
            }
        })
    }

    // section render
    render() {
        const canEdit = getCanEdit()

        const {getFieldDecorator} = this.props.form;

        const {schoolFactorId1} = this.props
        const {
            data_2_2_2,
            pageSize,
            pageNum,
            showAddJl,
            isEdit,
            isDisableModalIsCloudFictitiousSimulationChoose
        } = this.state
        const pageData = (data_2_2_2) ? data_2_2_2.pageData : {}
        const tableData = (pageData?.data) ? pageData.data : []

        const id = pageData?.id ? pageData.id : ""

        const socialTrainingNum = data_2_2_2?.socialTrainingNum ? Number(data_2_2_2.socialTrainingNum) : 0
        const socialTrainingFictitiousNum = data_2_2_2?.socialTrainingFictitiousNum ? Number(data_2_2_2.socialTrainingFictitiousNum) : 0
        const total = pageData?.total ? pageData.total : 0
        const scale = (((socialTrainingNum === 0) ? 0 : socialTrainingFictitiousNum / socialTrainingNum) * 100.00).toFixed(2)

        const labelWidth = 210

        // section columns2
        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{pageSize * (pageNum - 1) + index + 1}</span>
                }
            },
            {
                title: '课程名称',
                dataIndex: 'courseName',
                key: 'courseName',
                align: 'center',
                // width: 250
            },
            {
                title: '是否为虚拟仿真实训',
                dataIndex: 'isFictitiousSimulation',
                key: 'isFictitiousSimulation',
                align: 'center',
                width: 150
            },
            {
                title: '是否为云端虚拟仿真实训',
                dataIndex: 'isCloudFictitiousSimulation',
                key: 'isCloudFictitiousSimulation',
                align: 'center',
                width: 150
            },
            {
                title: '课程实训人数（社会人员）',
                dataIndex: 'courseNum',
                key: 'courseNum',
                align: 'center',
                width: 150
            },
            {
                title: '实训学时',
                dataIndex: 'trainingTime',
                key: 'trainingTime',
                align: 'center',
                width: 150
            },
        ]
        if (canEdit) columns2.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (text, record) => <div>
                    <span className={'cz'} onClick={() => {
                        this.edit(record.id)
                    }}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteAsk(record)}>删除</span>
            </div>

        })

        // section head
        const head = <div>
            <ul className={'platformService_2_2_2'}>
                <li>
                    <span className={'span1'}>总人时数</span>
                    <div
                        className={'btn'}>{socialTrainingNum}</div>
                </li>

                <li>
                    <span className={'span1'}>虚拟仿真类总人时数</span>
                    <div
                        className={'btn'}>{socialTrainingFictitiousNum}</div>
                </li>
                <li>
                    <span className={'span1'}>占比</span>
                    {/*<div className={'btn'}>{!(isFinite(this.state.personNum2*this.state.usePeriod2)/(this.state.studentNum2*this.state.resourcesPeriod2) && isNaN(this.state.personNum2*this.state.usePeriod2)/(this.state.studentNum2*this.state.resourcesPeriod2))?Math.floor(((this.state.personNum2*this.state.usePeriod2)/(this.state.studentNum2*this.state.resourcesPeriod2))*100):0}%</div>*/}
                    <div
                        className={'btn'}>{scale}%
                    </div>
                    <span className={'span6'} hidden={tableData.length <= 0 || scale >= 30}>*该项指标需达30%</span>

                </li>
            </ul>
            {canEdit ? <span className={'btn_group'}>
                <Button type={'primary'} className="icon" onClick={() => this.setState({
                    showAddJl: true,
                    isEdit: false,
                    file: ''
                }, () => {
                    this.props.form.resetFields()
                })}><TianJia/>添加</Button>
                {/*<Button type={'primary'}><Input id='importFile' type="file"*/}
                {/*                                accept="image"*/}
                {/*                                onClick={(event) => {*/}
                {/*                                    event.target.value = null*/}
                {/*                                }}*/}
                {/*                                onChange={(e) => this.importPlatformService(e, this.state.targetId2)}/><ExcelImport/>excel导入</Button>*/}
                {/*<Button type={'primary'}*/}
                {/*        onClick={() => this.dowLoadFile(this.state.targetMark2, this.state.targetName2)}><Daoru/>下载导入模版</Button>*/}
            </span> : ""}
            <ImportComponent
                editEnable={canEdit}
                targetId={this.state.data_2_2_2.id}
                targetMark="2.2.2"
                downloadName="社会人员参加虚拟仿真实训的人时占比"
                onImportSuccess={() => {
                    this.setState({pageNum: 1}, this.get_2_2_2_listData)
                }
                }
            />
        </div>

        // section table
        const table =
            <Table columns={columns2}
                   dataSource={tableData}
                   scroll={{x: 1100}}
                   className={'table1'}
                   bordered={true}
                   locale={{
                       emptyText: <>
                           <div className="Empty-content">
                               <div className="text">
                                   <img
                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                   <span>暂无数据</span>
                               </div>
                           </div>
                       </>
                   }}
                   pagination={
                       {
                           total: total,
                           pageSize: pageSize,
                           current: pageNum,
                           onChange: this.pageChange,
                           onShowSizeChange: this.onShowSizeChange,
                           pageSizeOptions: ['10', '20', '30', '40'],
                           showSizeChanger: true,
                           // position:'bottomRight'
                       }
                   }
            />

        // section Modal
        const modal = <Modal
            title={isEdit === false ? '添加' : '编辑'}
            visible={showAddJl}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            className={'addAddJlModal'}
            wrapClassName="wappop_up"
            footer={
                <div className='footer-btn'>
                    <Button className={'quxiao'}
                            onClick={() => this.handleCancel()}>取消</Button>
                    <Button type='primary'
                            onClick={() => this.add()}>提交</Button>
                    {isEdit === false ?
                        <Button type='primary'
                                onClick={() => this.addContinue()}>
                            提交并继续添加</Button> : ''}
                </div>
            }
        >
            <Form>
                <Form.Item label={'课程名称'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('courseName', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写课程名称`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入课程名称' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`courseName`) ? this.props.form.getFieldValue(`courseName`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item className={'li_form'} label={'是否为虚拟仿真实训'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('isFictitiousSimulation', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择`,
                                },
                            ],
                        })(
                            <Radio.Group onChange={this.onModalChangeIsFictitiousSimulation}>
                                <Radio value={"是"}>是</Radio>
                                <Radio value={"否"}>否</Radio>
                            </Radio.Group>
                        )}
                    </div>
                </Form.Item>
                <Form.Item className={'li_form'} label={'是否为云端虚拟仿真实训'}
                           labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('isCloudFictitiousSimulation', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择`,
                                },
                            ],
                        })(
                            <Radio.Group disabled={isDisableModalIsCloudFictitiousSimulationChoose}>
                                <Radio value={"是"}>是</Radio>
                                <Radio value={"否"}>否</Radio>
                            </Radio.Group>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'课程实训人数（社会人员）'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('courseNum', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请输入实训人数`,
                                },
                                {
                                    pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                    message: `请输入0或正整数`,
                                }
                            ],
                        })(
                            <InputNumber style={{width: '100%'}} placeholder='请输入实训人数' maxLength={30}
                                         type={'number'}
                                         suffix={
                                             <span className='len'>
                                             {this.props.form.getFieldValue(`courseNum`) ? this.props.form.getFieldValue(`courseNum`).length : 0}/30
                                             </span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'实训学时'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('trainingTime', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请输入实训学时`,
                                },
                                {
                                    pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                    message: `请输入0或正整数`,
                                }
                            ],
                        })(
                            <InputNumber style={{width: '100%'}} placeholder='请输入实训学时' maxLength={30}
                                         type={'number'}
                                         suffix={<span
                                             className='len'>{this.props.form.getFieldValue(`trainingTime`) ? this.props.form.getFieldValue(`trainingTime`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>

            </Form>
        </Modal>

        return (
            <div className={'cont1'}>
                <div className={'titleName'}>2.2.2 社会人员参加虚拟仿真实训的人时占比</div>
                {head}

                {table}
                {modal}
            </div>
        );
    }
}

const PlatformServiceComponent_2_2_2 = Form.create()(PlatformService_2_2_2);
export default PlatformServiceComponent_2_2_2;
