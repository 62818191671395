// 项目情况-人才培养
import React from "react";
import {Professionalicon} from "@@/iconSvg";
import {Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Radio, Row, Spin, Table, Tooltip} from 'antd';
import Heder from "../../components/header";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import UploaderComponent from "../../components/uploader_component";
import {
    AddBaseCoverageClasses,
    PersonnelCultivatingProgram,
    Upicon,
    Upicondele,
    Upiconwc,
    Daoru,
    ExcelImport,
    TianJia
} from '../../components/iconSvg'
import AddPersonnel from "./components/add_Personnel/index";
import Aalenttraining_2_1_1 from "./components/talenttraining2_1_1/index";
import Aalenttraining_2_1_4 from "./components/talenttraining2_1_4/index";

import {
    addTrainClass,
    addTrainPlan,
    cultivationTalentsList,
    deleteTrainClass,
    deleteTrainPlan,
    getTrainClass,
    getTrainPlan,
    importTrainClass,
    importTrainPlan,
    updateTrainClass,
    updateTrainPlan
} from "../../api/cultivationTalents"
import Cookies from "js-cookie";
import {uploadFile} from "../../api/uploadFile";
import Footer from "../../components/footer";
import axios from "axios";
import {downTemplate, getSharingMajorList} from "../../api/sharing";
import md5 from "js-md5";
import "./index.scss";
import {updateFactor} from "../../api/factor";
import LookCheck from "../components/look_Check";
import {getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType, getUserInfo} from "../../until/cookie";
import FileLook from "../components/file_look";
import {BaseUrl} from "../../config/common";

// import Talenttraining2_1_6 from './components/talenttraining2_1_6/index.jsx'
import PlatTalent2_1_2 from './components/platTalent2_1_2/index.jsx'
import PlatTalent2_1_6 from './components/platTalent2_1_6/index.jsx'
import PlatTalent2_1_7 from './components/platTalent2_1_7/index.jsx'
import PlatTalent2_1_8 from './components/platTalent2_1_8/index.jsx'
import {validData} from "../../api/target";

const {TextArea} = Input;

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

class PlatformTalenttraining extends React.Component {
    state = {

        dataSource2: [],
        showAddClass: false, //添加班级
        showPersonnel: false, //添加人才培养方案
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        // twoLevelTargetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
        twoLevelTargetId: getTargetId("platform_talenttraining"),
        trainClassInfo: [],
        trainPlanInfo: [],
        classId: '',
        planId: '',
        class: [],
        plan: [],
        targetPersonPeriodVo: [],//2.1.4

        studentCertificateVo: {},//2.1.6
        targetMegagameVo: {},//2.1.7
        getTargetId2_1_6: getSecondTargetList("platform_talenttraining")[5], //2.1.6
        getTargetId2_1_7: getSecondTargetList("platform_talenttraining")[6], //2.1.7
        getTargetId2_1_8: getSecondTargetList("platform_talenttraining")[7], //2.1.8
        personPeriodNum: 0,//2.1.5
        taskPageNum: 1,//页数
        taskPageSize: 10,//条数
    };

    componentDidMount() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        this.cultivationTalentsList()
        this.getSharingMajor();
        console.log("getTargetId2_1_6", this.state.getTargetId2_1_6)
        console.log("getTargetId2_1_7", this.state.getTargetId2_1_7)
    }

    getSharingMajor = () => {
        getSharingMajorList({stageId: 2}).then((res) => {
            if (res) {
                this.setState({
                    majorList: res,
                });
            }
        });
    }

    cultivationTalentsList = (freshPersonPeriodNum) => {
        let data = {
            id: getUserInfo().id,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.twoLevelTargetId,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }
        console.log("data", data)
        cultivationTalentsList(data).then(res => {
            console.log(res, "res");
            if (res) {
                if (freshPersonPeriodNum) {
                    // 只跟新2.1.5
                    this.setState({personPeriodNum: res.targetPersonPeriodNumVo?.personPeriodNum})//2.1.5
                    return false;
                }
                this.setState({
                    trainClassInfo: res.targetTrainClassVo,
                    orderNum: res.targetTrainClassVo.orderNum,
                    apprenticeNum: res.targetTrainClassVo.apprenticeNum,
                    factorContent: res.targetTrainClassVo.factorContent || 0,
                    trainPlanInfo: res.targetTrainPlanVo,
                    planFactorContent: Number(res.targetTrainPlanVo?.factorContent) || 0,
                    dataSource1: res.targetTrainClassVo.trainClassVoList.data,
                    taskPageNum: res.targetTrainClassVo.trainClassVoList.pageNum,//页数
                    taskPageSize: res.targetTrainClassVo.trainClassVoList.pageSize,//条数
                    dataSource2: res.targetTrainPlanVo?.trainPlanVoList.data,
                    classTotal: res.targetTrainClassVo.trainClassVoList.total,
                    planTotal: res.targetTrainPlanVo?.trainPlanVoList.total,
                    planBaifenbi: 0,
                    classABaifenbi: 0,
                    targetPersonPeriodVo: res?.targetPersonPeriodVo,
                    studentCertificateVo: res?.studentCertificateVo,//2.1.6
                    targetMegagameVo: res?.targetMegagameVo,//2.1.7
                    personPeriodNum: res.targetPersonPeriodNumVo?.personPeriodNum//2.1.5
                })
            }

        })
    }

    // 查看资料
    handLook = (record) => {
        const url = BaseUrl + record.fileId
        this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})
    }
    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    onCancel = () => {
        this.setState({
            examine: false
        })
    }

    handExamine = () => {
        this.setState({
            examine: true
        })
    }

    updateClass = (record) => {
        console.log('record', record)
        if (record) {
            let upFile = {
                originName: record ? record.resourceName : '',
                fileId: record ? record.fileId : '',
                fileSuffix: record ? record.resourceSuffix : '',
                fileSize: record ? (record.fileSize / 1024).toFixed(2) : ''
            }
            console.log('upFile', upFile)
            this.setState({
                showAddClass: true,
                classId: record.id,
                file: record.fileId ? upFile : null
            }, () => {
                this.props.form.setFieldsValue({
                    classType: record.classType,
                    className: record.className,
                    classPeopleNumber: record.classPeopleNumber,
                    fileId: record.fileId,
                })
            })
        }
    }

    updatePlan = (record) => {
        this.setState({
            showPersonnel: true,
            planId: record.id,
            plan: record
        })
    }

    /**
     *  班级数据   第一个table
     * */
    getTrainClass = () => {
        let data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.trainClassInfo.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize
        }
        getTrainClass(data).then(res => {
            if (res) {
                this.setState({
                    dataSource1: res.trainClassVoPageData.data,
                    classTotal: res.trainClassVoPageData.total,
                    factorContent: res.factorContent,
                    orderNum: res.orderNum,
                    apprenticeNum: res.apprenticeNum,
                })
            }
        })
    }
    /**
     *  方案   第二个table
     * */
    getTrainPlan = () => {
        let data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.trainPlanInfo.id,
            pageNum: this.state.taskPageNum,
            pageSize: this.state.pageSizeone
        }
        getTrainPlan(data).then(res => {
            if (res) {
                this.setState({
                    dataSource2: res.trainPlanVoPageData.data,
                    taskPageNum: res.trainPlanVoPageData.pageNum,//页数
                    taskPageSize: res.trainPlanVoPageData.pageSize,//条数
                    planTotal: res.trainPlanVoPageData.total,
                    planFactorContent: res.factorContent
                })
            }
        })
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //添加班级提交
    addclassTijiao = () => {
        let {file} = this.state
        console.log('提交前的文件信息：：：', file)
        if (this.state.classId) {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.classId,
                        className: value.className,
                        classPeopleNumber: value.classPeopleNumber,
                        classType: value.classType,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainClassInfo.id
                    }
                    updateTrainClass(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                file: null,
                                showAddClass: false
                            }, () => {
                                this.props.form.resetFields();
                                this.deleteFile()
                                this.getTrainClass()
                            })
                        }
                    })
                }
            })
        } else {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        className: value.className,
                        classPeopleNumber: value.classPeopleNumber,
                        classType: value.classType,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainClassInfo.id
                    }
                    addTrainClass(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                file: null,
                                showAddClass: false
                            }, () => {
                                this.props.form.resetFields();
                                this.deleteFile()
                                this.getTrainClass()
                            })
                        }
                    })
                }
            })
        }
    }
    //添加班级提交并继续
    addclassTijiaoAndAdd = () => {
        let {file} = this.state
        console.log('提交前的文件信息：：：', file)
        if (this.state.classId) {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.classId,
                        className: value.className,
                        classPeopleNumber: value.classPeopleNumber,
                        classType: value.classType,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainClassInfo.id
                    }
                    updateTrainClass(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                // showAddClass: false
                                file: null,
                                class: null
                            }, () => {
                                this.props.form.resetFields();
                                this.deleteFile()
                                this.getTrainClass()
                            })
                        }
                    })
                }
            })
        } else {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        className: value.className,
                        classPeopleNumber: value.classPeopleNumber,
                        classType: value.classType,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainClassInfo.id
                    }
                    addTrainClass(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                // showAddClass: false
                                file: null,
                                class: null
                            }, () => {
                                this.props.form.resetFields();
                                this.deleteFile()
                                this.getTrainClass()
                            })
                        }
                    })
                }
            })
        }
    }


    //添加班级提交继续添加
    addclassTijiaoAdd = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {} = value
                this.props.form.resetFields();
            }
        })
    }

    //添加方案提交
    addPlanTijiao = () => {
        let file = this.AddPersonnel.getState()
        if (this.state.planId) {
            this.AddPersonnel.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.planId,
                        planName: value.planName,
                        majorOne: value.planMajor[0],
                        majorTwo: value.planMajor[1],
                        planMajor: value.planMajor[2],
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainPlanInfo.id
                    }
                    updateTrainPlan(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                file: null,
                                showPersonnel: false,
                                // taskPageNum:1
                            }, () => {
                                this.AddPersonnel.props.form.resetFields();
                                this.AddPersonnel.deleteFile()
                                this.getTrainPlan()
                            })
                        }
                    })
                }
            })
        } else {
            this.AddPersonnel.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        planName: value.planName,
                        majorOne: value.planMajor[0],
                        majorTwo: value.planMajor[1],
                        planMajor: value.planMajor[2],
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainPlanInfo.id
                    }
                    addTrainPlan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                file: null,
                                showPersonnel: false,
                                taskPageNum: 1
                            }, () => {
                                this.AddPersonnel.props.form.resetFields();
                                this.AddPersonnel.deleteFile()
                                this.getTrainPlan()
                            })
                        }
                    })
                }
            })
        }
    }
    //添加方案提交并添加
    addPlanTijiaoAndAdd = () => {
        let file = this.AddPersonnel.getState()
        if (this.state.planId) {
            this.AddPersonnel.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.planId,
                        planName: value.planName,
                        majorOne: value.planMajor[0],
                        majorTwo: value.planMajor[1],
                        planMajor: value.planMajor[2],
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainPlanInfo.id
                    }
                    updateTrainPlan(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                file: null,
                                // showPersonnel: false
                                plan: null
                            }, () => {
                                this.AddPersonnel.props.form.resetFields()
                                this.AddPersonnel.deleteFile()
                                this.getTrainPlan()
                            })
                        }
                    })
                }
            })
        } else {
            this.AddPersonnel.props.form.validateFields((err, value) => {
                if (!err) {
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        planName: value.planName,
                        majorOne: value.planMajor[0],
                        majorTwo: value.planMajor[1],
                        planMajor: value.planMajor[2],
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.trainPlanInfo.id
                    }
                    addTrainPlan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                file: null,
                                // showPersonnel: false
                                plan: null
                            }, () => {
                                this.AddPersonnel.props.form.resetFields()
                                this.AddPersonnel.deleteFile()
                                this.getTrainPlan()
                            })
                        }
                    })
                }
            })
        }
    }

    // 上一步
    previous = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }



        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "construction_Effective") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/construction_Effective',
                    })
                    break;
                }
            }
        }
    }
    // 下一步
    next = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_service") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_service',
                    })
                    break;
                }
            }
        }
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, this.getTrainClass);
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageSize, pageNum: 1}, this.getTrainClass);

    }
    //第二个表格分页切换
    pageChangeone = (pageNum) => {
        this.setState({taskPageNum: pageNum}, this.getTrainPlan);

    }
    //第二个表格分页条数切换
    onShowSizeChangeone = (pageNum, pageSize) => {
        this.setState({pageSizeone: pageSize, taskPageNum: 1}, this.getTrainPlan);
    }
    //删除前二次确认
    deleteClass = (record) => {
        let data = {
            id: record.id,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.trainClassInfo.id
        }
        deleteTrainClass(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.getTrainClass()
            }
        })
    }

    deletePlan = (record) => {
        let data = {
            id: record.id,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.trainPlanInfo.id
        }
        deleteTrainPlan(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.setState({
                    taskPageNum: 1
                }, () => {
                    this.getTrainPlan()
                })
            }
        })
    }

    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    // 导入文件
    importTrainClass = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('targetId', this.state.trainClassInfo.id)
        fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
        importTrainClass(fromData).then(res => {
            message.success('导入成功')
            this.getTrainClass()
        })
    }
    // 导入文件
    importTrainPlan = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('targetId', this.state.trainPlanInfo.id)
        fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
        importTrainPlan(fromData).then(res => {
            message.success('导入成功')
            this.getTrainPlan()
        })
    }

    //下载文件
    dowLoadFile = (targetMark, targetName) => {
        console.log("targetMark", targetMark)
        let now = new Date().getTime();
        axios.get(downTemplate(targetMark), {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${targetName}.xls`);
            link.click();
        })
    }

    updateClassNum = (value) => {
        let data = {
            id: this.state.trainClassInfo.factorId,
            factorContent: Number(value)
        }
        updateFactor(data).then(res => {
            if (res) {
                // message.success("操作成功")
                let {trainClassInfo} = this.state
            }
        })
    }

    updatePlanNum = (value) => {
        console.log('value', value)
        let data = {
            id: this.state.trainPlanInfo.factorId,
            factorContent: Number(value)
        }
        updateFactor(data).then(res => {
            if (res) {
                // message.success("操作成功")

            }
        })
    }

    gaiBian = (value) => {
        this.setState({
            factorContent: Number(value)
        })
    }

    planGiaBian = (value) => {
        console.log('11111111111', value)
        console.log('11111111planTotal111', this.state.planTotal)
        if (value < this.state.planTotal) {
            // message.error('输入值错误')
            this.setState({
                planFactorContent: Number(this.state.planTotal)
            })
            return false;
        }
        this.setState({
            planFactorContent: Number(value)
        })
    }


    render() {
        const {getFieldDecorator} = this.props.form
        const {
            apprenticeNum,
            orderNum,
            planFactorContent,
            factorContent,
            classABaifenbi,
            planBaifenbi,
            classTotal,
            planTotal,
            planId,
            plan,
            trainClassInfo,
            trainPlanInfo,
            dataSource1,
            dataSource2,
            showAddClass,
            showPersonnel,
            pageNum,
            pageSize,
            total,
            totalone,
            pageNumone,
            taskPageNum,
            pageSizeone,
            isShow,
            openUrl,

            studentCertificateVo,
            targetMegagameVo,

        } = this.state
        const {resourceId, examine, file, classId, majorList} = this.state;
        const canEdit = getCanEdit()
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 60,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{index + 1}</span>
                }
            },
            {
                title: '班级类型',
                dataIndex: 'classType',
                key: 'classType',
                align: 'center',
                width: 150,
                render: (text, record, index) => {
                    return <span>{text === 1 ? "订单班" : text === 2 ? "学徒制" : ''}</span>
                }
            },
            {
                title: '班级名称',
                dataIndex: 'className',
                key: 'className',
                align: 'center',
                width: 60
            },
            {
                title: '班级人数',
                dataIndex: 'classPeopleNumber',
                key: 'classPeopleNumber',
                align: 'center',
                width: 150
            },
            {
                title: '支撑材料',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 100,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },

        ];

        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 60,
                render: (text, record, index) => {
                    // return <span className={'xuhao'}>{index + 1}</span>
                    return <span
                        className={'xuhao'}>{this.state.taskPageSize * (this.state.taskPageNum - 1) + index + 1}</span>
                }
            },
            {
                title: '人才方案名称',
                dataIndex: 'planName',
                key: 'planName',
                align: 'center',
                width: 150
            },
            {
                title: '所属专业',
                dataIndex: 'planMajorName',
                key: 'planMajorName',
                align: 'center',
                width: 150
            },
            {
                title: '佐证材料',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 100,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },

        ];
        if (canEdit) {
            columns1.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 100,
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updateClass(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deleteClass(record)}>删除</span>
                </div>

            })
            columns2.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 150,
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updatePlan(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deletePlan(record)}>删除</span>
                </div>

            })
        }
        return (
            <div className="PlatformTalenttraining main-bg">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id='Fillside_warp'>
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect">
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside onRef={(ref) => {
                                            this.LeftEfficiencyside = ref;
                                        }}/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right">
                                    <div className='content'>
                                        {/* 2.1.1 虚拟仿真实训服务专业的占比 */}
                                        {/* <Aalenttraining_2_1_1
                                            canEdit={canEdit}
                                            planFactorContent={planFactorContent}
                                            planTotal={planTotal}
                                            updatePlanNum={this.updatePlanNum}
                                            planGiaBian={this.planGiaBian}
                                            dataSource1={dataSource1}
                                            classTotal={classTotal}
                                            pageSize={pageSize}
                                            pageChange={this.pageChange}
                                            onShowSizeChange={this.onShowSizeChange}
                                        /> */}
                                        <div className="cont1">
                                            <div
                                                className={'titleName'}>2.1.1 虚拟仿真实训服务专业的占比
                                            </div>
                                            <Aalenttraining_2_1_1
                                                canEdit={canEdit}
                                                getTargetId=''
                                            />
                                        </div>

                                        {/* <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.1.1 虚拟仿真实训服务专业的占比
                                            </div>
                                            <ul className={'tongjiul'}>
                                                <li>
                                                    <span className={'span1'}>学校订单班或现代学徒制班总数量</span>
                                                    <div className={'btn btn1'}>
                                                        <InputNumber min={0} className='input_bg' disabled={!canEdit}
                                                                     onChange={e => this.gaiBian(e)}
                                                                     onBlur={e => this.updateClassNum(e.target.value)}
                                                                     placeholder={'数值填充'}
                                                                     value={factorContent ? factorContent : 0}/>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={'lidiv'}>
                                                        <span
                                                            className={'span1 span2'}>基地覆盖{trainClassInfo.year}订单班数量</span>
                                                        <div className={'btn'}>{orderNum ? orderNum : 0}</div>
                                                    </div>
                                                    <span className={'jia'}>+</span>
                                                    <div className={'lidiv'}>
                                                        <span
                                                            className={'span1 span2'}>基地覆盖{trainClassInfo.year}学徒班数量</span>
                                                        <div className={'btn'}>{apprenticeNum ? apprenticeNum : 0}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={'span1 span2'}>占比</span>
                                                    <div
                                                        className={'btn'}>{factorContent && (orderNum + apprenticeNum) !== 0 ? (((orderNum + apprenticeNum) / factorContent) * 100).toFixed(2) : 0}%
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ?
                                                    <Button type={'primary'} onClick={() => this.setState({
                                                        showAddClass: true,
                                                        classId: '',
                                                        file: ''
                                                    })}>
                                                        <AddBaseCoverageClasses/>添加基地覆盖班级</Button> : ""}
                                                <Button type="primary">
                                                    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}
                                                           onChange={this.importTrainClass}/><ExcelImport/>Excel导入</Button>
                                                <Button type={'primary'} onClick={() => this.dowLoadFile(trainClassInfo.targetMark, trainClassInfo.targetName)}><Daoru/>下载导入模版</Button>
                                            </div>
                                            <Table columns={columns1} dataSource={dataSource1} className={'table1'}
                                                   bordered={true}
                                                   locale={{
                                                       emptyText: <>
                                                           <div className="Empty-content">
                                                               <div className="text">
                                                                   <img
                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div>
                                                       </>
                                                   }}
                                                   pagination={
                                                       {
                                                           total: classTotal,
                                                           pageSize: pageSize,
                                                           onChange: this.pageChange,
                                                           onShowSizeChange: this.onShowSizeChange,
                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                           showSizeChanger: true,
                                                           // position:'bottomRight'
                                                       }
                                                   }
                                            />
                                        </div> */}
                                        {/* <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.1.2 虚拟仿真实训服务订单班或现代学徒制班的学生数占比
                                            </div>
                                            <ul className={'tongjiul'}>
                                                <li>
                                                    <span className={'span1'}>学校订单班或现代学徒制班总数量</span>
                                                    <div className={'btn btn1'}>
                                                        <InputNumber min={0} className='input_bg' disabled={!canEdit}
                                                            onChange={e => this.gaiBian(e)}
                                                            onBlur={e => this.updateClassNum(e.target.value)}
                                                            placeholder={'数值填充'}
                                                            value={factorContent ? factorContent : 0} />
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={'lidiv'}>
                                                        <span
                                                            className={'span1 span2'}>基地覆盖{trainClassInfo.year}订单班数量</span>
                                                        <div className={'btn'}>{orderNum ? orderNum : 0}</div>
                                                    </div>
                                                    <span className={'jia'}>+</span>
                                                    <div className={'lidiv'}>
                                                        <span
                                                            className={'span1 span2'}>基地覆盖{trainClassInfo.year}学徒班数量</span>
                                                        <div className={'btn'}>{apprenticeNum ? apprenticeNum : 0}</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={'span1 span2'}>占比</span>
                                                    <div
                                                        className={'btn'}>{factorContent && (orderNum + apprenticeNum) !== 0 ? (((orderNum + apprenticeNum) / factorContent) * 100).toFixed(2) : 0}%
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ?
                                                    <Button type={'primary'} onClick={() => this.setState({
                                                        showAddClass: true,
                                                        classId: '',
                                                        file: ''
                                                    })}>
                                                        <AddBaseCoverageClasses/>添加基地覆盖班级</Button> : ""}
                                                {canEdit ? (
                                                    <div className="conteBtnDiv" onClick={this.onInsertData}>
                                                        <Professionalicon />
                                                        <span className="conteBtnDivSpan">添加班级</span>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <Button type="primary">
                                                    <Input id='importFile' type="file" accept="image" onClick={(event) => { event.target.value = null }}
                                                        onChange={this.importTrainClass} /><ExcelImport />Excel导入</Button>
                                                <Button type={'primary'} onClick={() => this.dowLoadFile(trainClassInfo.targetMark, trainClassInfo.targetName)}><Daoru />下载导入模版</Button>
                                            </div>
                                            <Table columns={columns1} dataSource={dataSource1} className={'table1'}
                                                bordered={true}
                                                locale={{
                                                    emptyText: <>
                                                        <div className="Empty-content">
                                                            <div className="text">
                                                                <img
                                                                    src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'} />
                                                                <span>暂无数据</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                }}
                                                pagination={
                                                    {
                                                        total: classTotal,
                                                        pageSize: pageSize,
                                                        onChange: this.pageChange,
                                                        onShowSizeChange: this.onShowSizeChange,
                                                        pageSizeOptions: ['10', '20', '30', '40'],
                                                        showSizeChanger: true,
                                                    }
                                                }
                                            />
                                        </div> */}


                                        <PlatTalent2_1_2
                                            // canEdit={canEdit}
                                            // dataSource2={dataSource2}
                                            // trainPlanInfo={trainPlanInfo}
                                            // studentCertificateVo={studentCertificateVo}

                                            canEdit={canEdit}
                                            getTargetId={this.state.twoLevelTargetId}

                                        />


                                        <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.1.3 结合虚拟仿真实训优势优化完善的人才培养方案占比
                                            </div>
                                            <ul className={'tongjiul1 tongjiul1new'} style={{marginBottom: "20px"}}>
                                                <li>
                                                    <span className={'span'}>人才培养方案总数</span>
                                                    <div className={'btn btn1'}>
                                                        <InputNumber min={0} className='input_bg' disabled={!canEdit}
                                                                     onBlur={e => this.updatePlanNum(e.target.value)}
                                                                     onChange={e => this.planGiaBian(e)}
                                                                     value={planFactorContent ? planFactorContent > planTotal ? planFactorContent : planTotal : planTotal}
                                                                     placeholder={'数值填充'} type="number"/>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={'span span1'}>结合虚拟仿真的方案
                                                        {/* {trainPlanInfo?.year} */}
                                                    </span>
                                                    <div className={'btn'}>{planTotal ? planTotal : 0}</div>
                                                </li>
                                                {/* <li>
                                                    <span className={'span span2'}>统计单位</span>
                                                    <div className={'btn btn1'}>
                                                        <div>个</div>
                                                    </div>
                                                </li> */}
                                                <li>
                                                    <span className={'span span3'}>占比</span>
                                                    <div className={'btn'}
                                                         style={
                                                             (parseInt(planFactorContent) > parseInt(planTotal)) && (parseInt(((planTotal / planFactorContent) * 100).toFixed(2)) < 30 || ((planTotal / planFactorContent) * 100).toFixed(2) == Infinity) ?
                                                                 {borderColor: '#d75746'} : {borderColor: '#d9d9d9'}}
                                                    >
                                                        {planTotal !== 0 ?
                                                            parseInt(planFactorContent) > parseInt(planTotal) ? ((planTotal / planFactorContent) * 100).toFixed(2)
                                                                : ((planTotal / planTotal) * 100).toFixed(2) : 0
                                                        }%
                                                        {/* className={'btn'}>{planFactorContent !== 0 ? ((planTotal / planFactorContent) * 100).toFixed(2) : 0}% */}
                                                    </div>
                                                    {
                                                        (parseInt(planFactorContent) > parseInt(planTotal) && (parseInt(((planTotal / planFactorContent) * 100).toFixed(2)) < 30 || ((planTotal / planFactorContent) * 100).toFixed(2) == Infinity)) && (
                                                            <span style={{
                                                                color: '#d75746',
                                                                marginLeft: '15px'
                                                            }}>*该项指标需达30%</span>
                                                        )
                                                    }

                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ? <Button type={'primary'} className={'conteBtnDivSpan'}
                                                                   style={{minWidth: "80px"}}
                                                                   onClick={() => this.setState({
                                                                       showPersonnel: true,
                                                                       planId: '',
                                                                       plan: ''
                                                                   })}><Professionalicon/>添加</Button>
                                                    : ""} {/* <Button type="primary">
                                                    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}
                                                           onChange={this.importTrainPlan}/><ExcelImport/>Excel导入</Button> */}
                                                {/* <Button type={'primary'} onClick={() => this.dowLoadFile(trainPlanInfo.targetMark, trainPlanInfo.targetName)}><Daoru/>下载导入模版</Button> */}
                                            </div>
                                            <div className={'btn_group'} style={{marginTop: '20px'}}>
                                                {canEdit && (
                                                    <span style={{color: '#d75746'}}>*仅添加结合虚拟仿真实训的人才培养方案</span>
                                                )}
                                            </div>
                                            <Table columns={columns2} dataSource={dataSource2} className={'table1'}
                                                   bordered={true}
                                                   locale={{
                                                       emptyText: <>
                                                           <div className="Empty-content">
                                                               <div className="text">
                                                                   <img
                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div>
                                                       </>
                                                   }}
                                                   pagination={
                                                       {
                                                           total: planTotal,
                                                           pageSize: this.state.taskPageSize,
                                                           current: this.state.taskPageNum,
                                                           onChange: this.pageChangeone,
                                                           onShowSizeChange: this.onShowSizeChangeone,
                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                           showSizeChanger: true,
                                                           // position:'bottomRight'
                                                       }
                                                   }
                                            />
                                        </div>
                                        <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.1.4 在校生参加虚拟仿真实训的人时占比
                                            </div>
                                            {
                                                Object.keys(this.state.targetPersonPeriodVo)?.length > 0 && (
                                                    <Aalenttraining_2_1_4
                                                        canEdit={canEdit}
                                                        getTargetId={this.state.targetPersonPeriodVo.id}
                                                        schoolEduCode={this.state.schoolInfo.schoolEduCode}
                                                        datas={this.state.targetPersonPeriodVo}
                                                        personPeriodNum={this.state.personPeriodNum}
                                                        cultivationTalentsList={this.cultivationTalentsList}
                                                    />
                                                )
                                            }

                                        </div>

                                        {/* <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.1.4 在校生参加虚拟仿真实训的人时占比
                                            </div>
                                            <ul className={'tongjiul1'}>
                                                <li>
                                                    <span className={'span'}>全校人才培养方案总数量</span>
                                                    <div className={'btn btn1'}>
                                                        <InputNumber min={0} className='input_bg' disabled={!canEdit}
                                                                     onBlur={e => this.updatePlanNum(e.target.value)}
                                                                     onChange={e => this.planGiaBian(e)}
                                                                     value={planFactorContent ? planFactorContent : 0}
                                                                     placeholder={'数值填充'}/>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span
                                                        className={'span span1'}>依托基地虚实结合优化的人才培养方案数量{trainPlanInfo.year}</span>
                                                    <div className={'btn'}>{planTotal ? planTotal : 0}</div>
                                                </li>
                                                <li>
                                                    <span className={'span span2'}>统计单位</span>
                                                    <div className={'btn btn1'}>
                                                        <div>个</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={'span span3'}>占比</span>
                                                    <div
                                                        className={'btn'}>{planFactorContent !== 0 ? ((planTotal / planFactorContent) * 100).toFixed(2) : 0}%
                                                    </div>
                                                </li>
                                            </ul>



                                            <div className={'btn_group'}>
                                                {canEdit ? <Button type={'primary'} className={'btn209'}
                                                                   onClick={() => this.setState({
                                                                       showPersonnel: true,
                                                                       planId: '',
                                                                       plan: ''
                                                                   })}><PersonnelCultivatingProgram/>添加依托基地的人才培养方案</Button>
                                                    : ""}
                                                     <Button type="primary">
                                                    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}
                                                           onChange={this.importTrainPlan}/><ExcelImport/>Excel导入</Button>
                                                <Button type={'primary'} onClick={() => this.dowLoadFile(trainPlanInfo.targetMark, trainPlanInfo.targetName)}><Daoru/>下载导入模版</Button>
                                            </div>
                                            <Table columns={columns2} dataSource={dataSource2} className={'table1'}
                                                   bordered={true}
                                                   locale={{
                                                       emptyText: <>
                                                           <div className="Empty-content">
                                                               <div className="text">
                                                                   <img
                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div>
                                                       </>
                                                   }}
                                                   pagination={
                                                       {
                                                           total: planTotal,
                                                           pageSize: pageSizeone,
                                                           onChange: this.pageChangeone,
                                                           onShowSizeChange: this.onShowSizeChangeone,
                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                           showSizeChanger: true,
                                                           // position:'bottomRight'
                                                       }
                                                   }
                                            />
                                        </div> */}

                                        {/* <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.1.5 在校生（本校学生）云端虚拟仿真实训总人时
                                            </div>
                                            <ul className={'tongjiul1'}>
                                                <li>
                                                    <span className={'span'}>全校人才培养方案总数量</span>
                                                    <div className={'btn btn1'}>
                                                        <InputNumber min={0} className='input_bg' disabled={!canEdit}
                                                            onBlur={e => this.updatePlanNum(e.target.value)}
                                                            onChange={e => this.planGiaBian(e)}
                                                            value={planFactorContent ? planFactorContent : 0}
                                                            placeholder={'数值填充'} />
                                                    </div>
                                                </li>
                                                <li>
                                                    <span
                                                        className={'span span1'}>依托基地虚实结合优化的人才培养方案数量{trainPlanInfo.year}</span>
                                                    <div className={'btn'}>{planTotal ? planTotal : 0}</div>
                                                </li>
                                                <li>
                                                    <span className={'span span2'}>统计单位</span>
                                                    <div className={'btn btn1'}>
                                                        <div>个</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className={'span span3'}>占比</span>
                                                    <div
                                                        className={'btn'}>{planFactorContent !== 0 ? ((planTotal / planFactorContent) * 100).toFixed(2) : 0}%
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ? <Button type={'primary'} className={'btn209'}
                                                    onClick={() => this.setState({
                                                        showPersonnel: true,
                                                        planId: '',
                                                        plan: ''
                                                    })}><PersonnelCultivatingProgram />添加依托基地的人才培养方案</Button>
                                                    : ""}
                                                    <Button type="primary">
                                                    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}
                                                           onChange={this.importTrainPlan}/><ExcelImport/>Excel导入</Button>
                                                <Button type={'primary'} onClick={() => this.dowLoadFile(trainPlanInfo.targetMark, trainPlanInfo.targetName)}><Daoru/>下载导入模版</Button>
                                            </div>
                                            <Table columns={columns2} dataSource={dataSource2} className={'table1'}
                                                bordered={true}
                                                locale={{
                                                    emptyText: <>
                                                        <div className="Empty-content">
                                                            <div className="text">
                                                                <img
                                                                    src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'} />
                                                                <span>暂无数据</span>
                                                            </div>
                                                        </div>
                                                    </>
                                                }}
                                                pagination={
                                                    {
                                                        total: planTotal,
                                                        pageSize: pageSizeone,
                                                        onChange: this.pageChangeone,
                                                        onShowSizeChange: this.onShowSizeChangeone,
                                                        pageSizeOptions: ['10', '20', '30', '40'],
                                                        showSizeChanger: true,
                                                        position:'bottomRight'
                                                    }
                                                }
                                            />
                                        </div> */}
                                        {/* 2.1.6 */}
                                        {/* <Talenttraining2_1_6
                                            dataSource2={dataSource2}
                                            trainPlanInfo={trainPlanInfo}
                                        /> */}
                                        <PlatTalent2_1_6
                                            // canEdit={canEdit}
                                            // dataSource2={dataSource2}
                                            // trainPlanInfo={trainPlanInfo}
                                            studentCertificateVo={studentCertificateVo}

                                            canEdit={canEdit}
                                            getTargetId={this.state.getTargetId2_1_6}

                                        />
                                        <PlatTalent2_1_7
                                            // canEdit={canEdit}
                                            // dataSource2={dataSource2}
                                            // trainPlanInfo={trainPlanInfo}
                                            targetMegagameVo={targetMegagameVo}

                                            canEdit={canEdit}
                                            getTargetId={this.state.getTargetId2_1_7}
                                        />

                                        {/* <Talenttraining2_1_7
                                         dataSource2={dataSource2}
                                            trainPlanInfo={trainPlanInfo}
                                        /> */}
                                        <PlatTalent2_1_8
                                            canEdit={canEdit}
                                            getTargetId={this.state.getTargetId2_1_8}
                                        />


                                        {canEdit ? <div className={'footer-btn'}>

                                            <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                            <Button type={'primary'} onClick={() => this.next()}>下一步</Button>
                                        </div> : ""}

                                        {/*添加班级*/}
                                        <Modal
                                            title={classId ? "编辑班级" : "添加班级"}
                                            visible={showAddClass}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({showAddClass: false})}>取消</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.addclassTijiao()}>提交</Button>
                                                    {classId ? '' :
                                                        <Button type='primary'
                                                                onClick={() => this.addclassTijiaoAndAdd()}>提交并继续添加</Button>
                                                    }
                                                </div>
                                            }
                                        >
                                            <Form>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>班级类型</span>
                                                    {getFieldDecorator('classType', {
                                                        initialValue: 1,
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: '请选择班级类型!'
                                                            },
                                                        ],
                                                    })(<Radio.Group>
                                                        <Radio value={1}>订单班</Radio>
                                                        <Radio value={2}>学徒班</Radio>
                                                    </Radio.Group>)}
                                                </Form.Item>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>班级名称</span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('className', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请填写班级名称`,
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: "文本过长，最多30字"
                                                                },
                                                            ],
                                                        })(
                                                            <Input placeholder='请输入班级名称' maxLength={30}
                                                                   suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`className`) ? this.props.form.getFieldValue(`className`).length : 0}/30</span>}/>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>班级人数</span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('classPeopleNumber', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请输入班级人数`,
                                                                }
                                                            ],
                                                        })(
                                                            <InputNumber min={0} className="input"
                                                                         placeholder={'请输入班级人数'}/>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className="li_formup">
                                                    <span className={'label'}><span className='red'>*</span>佐证材料</span>
                                                    {getFieldDecorator('fileId', {
                                                        initialValue: '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请选择佐证材料`,
                                                            },
                                                        ],
                                                    })(
                                                        <div className="up_button">
                                                            <div className="span_right">
                                                                <label htmlFor="file" className="inputlabelBox">
                                                                    <span><Upicon/>上传资料</span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    accept="image"
                                                                    onChange={this.multipartUpload}
                                                                    name="myfile"
                                                                    id="file"
                                                                    style={{display: "none"}}
                                                                    onClick={(event) => {
                                                                        event.target.value = null
                                                                    }}
                                                                />
                                                                <span className={'tip'}>
                                                                    支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                                                </span>
                                                            </div>
                                                            <ul className="bottom_tit">
                                                                {/*上传中*/}
                                                                {file ? <Tooltip placement="topLeft"
                                                                                 title={file.originName}>
                                                                    <li className="title_de">{file.fileId ?
                                                                        <Upiconwc/> : <Spin/>}<span
                                                                        className="tie_span">{file.originName}</span><i
                                                                        className="Upicona"
                                                                        onClick={this.deleteFile}><Upicondele/></i></li>
                                                                </Tooltip> : null}
                                                                {/*已完成*/}
                                                                {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Form>
                                        </Modal>
                                        {/*添加人才培养方案*/}
                                        <Modal
                                            title={planId ? '编辑人才培养方案' : '添加人才培养方案'}
                                            visible={showPersonnel}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal addrcpycont'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({showPersonnel: false})}>取消</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.addPlanTijiao()}>提交</Button>
                                                    {planId ? '' :
                                                        <Button type='primary'
                                                                onClick={() => this.addPlanTijiaoAndAdd()}>提交并继续添加</Button>
                                                    }
                                                </div>
                                            }
                                        >
                                            <AddPersonnel majorList={majorList} data={plan}
                                                          wrappedComponentRef={form => {
                                                              this.AddPersonnel = form;
                                                          }}></AddPersonnel>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
                <Footer/>
            </div>
        );
    }
}

PlatformTalenttraining = Form.create()(PlatformTalenttraining);
export default PlatformTalenttraining;
