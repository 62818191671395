// 国内推广
import React from 'react'
import {
    Form,
    Row,
    Col,
    Card,
    Button,
    Input,
    Table,
    Modal,
    Radio,
    Select,
    message,
    Tooltip,
    Spin,
    Drawer,
    Cascader,
} from 'antd'
import Heder from '../../components/header'
import EffTop from '../components/EffectivenessTop'
import LeftEfficiencyside from '../components/Left_Efficiencyside'
import {
    AddActivitie,
    Addziyuan,
    AddAwards,
    AddPlatformInformatiom,
    Daoru,
    ExcelImport,
    Upicon,
    Upicondele,
    Upiconwc,
    TaperedEnd,
    WenxinTip,
} from '../../components/iconSvg'
import AddActivities from './components/add_Activities' //添加活动
import AddAward from './components/add_Award' //添加奖项
import AddPlatforminformatiom from './components/add_Platforminformatiom' //添加平台信息
import moment from 'moment'
import {
    selectDomesticMarketing,
    selectTrade,
    deleteTrade,
    updateTrade,
    addTrade,
    tradeDetail,
    selectResources,
    deleteResources,
    updateResources,
    addResources,
    resourcesDetail,
    updateResourcesCondition,
    selectCompetition,
    deleteCompetition,
    updateCompetition,
    addCompetition,
    competitionDetail,
    selectActivity,
    deleteActivity,
    updateActivity,
    addActivity,
    activityDetail,
    checkImportTargetExtensionTrade,
    checkImportTargetExtensionActivity,
    checkImportTargetExtensionCompetition,
    checkImportTargetExtensionResources,
    getSharingIndustryList
} from '../../api/domesticMarketing'
import Cookies from 'js-cookie'
import axios from 'axios'
import md5 from 'js-md5'
import {uploadFile} from '../../api/uploadFile'
import {downTemplate} from '../../api/sharing'
import Footer from '../../components/footer'
import LookCheck from '../components/look_Check'
import './index.scss'
import {saveConditionStatus, validData} from '../../api/target'
import {
    getCanEdit,
    getRoleType,
    getSecondTargetList,
    getTargetId,
    RoleType,
} from '../../until/cookie'
import FileLook from '../components/file_look'
import {BaseUrl} from '../../config/common'
import {isEmpty} from '../../until/isEmpty'
import {formatDate} from '../../until/date'

const {TextArea} = Input

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, '-') +
        ' ' +
        new Date(time).toLocaleTimeString().slice(2)
    )
}

class PlatformDomesticmmarketing extends React.Component {
    state = {
        dataSource1: [],
        showfzclModel1: false, //新增佐证材料1,2,3,4
        cailiaoType: 1, //材料类型
        neirongType: 1, //内容类型
        pageNum: 1, //页数
        pageSize: 10, //条数
        total: 0,
        pageNumone: 1, //页数
        pageSizeone: 10, //条数
        totalone: 0,
        pageNumtwo: 1, //页数
        pageSizetwo: 10, //条数
        totaltwo: 0,
        pageNumthree: 1, //页数
        pageSizethree: 10, //条数
        totalthree: 0,
        dataSource2: [],
        dataSource3: [],
        dataSource4: [],
        showtipModal: true, //添加人才培养方案
        showAddResource: false, //添加资源弹窗
        showAddActives: false, //添加活动弹窗
        showAddaward: false, //添加奖项弹窗
        showPlatformInformatiom: false, //添加平台信息
        // twoLevelTargetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
        twoLevelTargetId: getTargetId('platform_Domesticmarketing'),
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo'))
            ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode
            : '',
        domesticMarketingList: {},
        tradeDataFlag: true,
        activityDataFlag: true,
        competitionDataFlag: true,
        resourcesDataFlag: true,
        buttonFlag: true,
        evidenceId: null,
        examine: false,
        isindependent: false, //二次确认
        majorList: [],
        estateFieldName: "",
        estateFieldId: ""
    }
    // 查看资料
    handLook = (record) => {
        const url = BaseUrl + record.fileId
        this.props.history.push({pathname: '/file_Book', query: {openUrl: url}})
    }
    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false,
        })
    }
    handExamine = () => {
        this.setState({
            examine: true,
        })
    }
    onCancel = () => {
        this.setState({
            examine: false,
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.selectDomesticMarketing()
        this.getSharingIndustryList()
    }

    getSharingIndustryList = () => {
        getSharingIndustryList({stageId: 2}).then((res) => {
            if (res) {
                this.setState({
                    majorList: res,
                });
            }
        });
    }

    selectDomesticMarketing = () => {
        let {schoolEduCode, twoLevelTargetId} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: twoLevelTargetId,
            pageNum: 1,
            pageSize: 20,
        }
        selectDomesticMarketing(data).then((res) => {
            if (res) {
                this.setState({
                    domesticMarketingList: res,
                    dataSource1: res.indicatorVo311 ? res.indicatorVo311.list.data : [],
                    dataSource2: res.indicatorVo312 ? res.indicatorVo312.list.data : [],
                    dataSource3: res.indicatorVo313 ? res.indicatorVo313.list.data : [],
                    dataSource4: res.indicatorVo314 ? res.indicatorVo314.list.data : [],
                    value: res.indicatorVo314
                        ? res.indicatorVo314.conditionVo.confirmStatus
                        : null,
                    total: res.indicatorVo311 ? res.indicatorVo311.list.total : 0,
                    totalone: res.indicatorVo312 ? res.indicatorVo312.list.total : 0,
                    totaltwo: res.indicatorVo313 ? res.indicatorVo313.list.total : 0,
                    totalthree: res.indicatorVo314 ? res.indicatorVo314.list.total : 0,
                })
            }
        })
    }

    deleteTrade = (item) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        deleteTrade({
            id: item.id,
            targetId: domesticMarketingList.indicatorVo311.id,
            schoolEduCode: schoolEduCode,
        }).then((data) => {
            this.mesSuccess('删除成功')
            this.pageChange(1)
        })
    }
    updateTrade = (item) => {
        console.log('item', item)
        let upFile = {
            originName: item.resourceName,
            fileId: item.fileId,
            fileSuffix: item.resourceSuffix,
            fileSize: (item.fileSize / 1024).toFixed(2),
        }
        this.setState(
            {
                buttonFlag: false,
                showAddResource: true,
                file: item.fileId ? upFile : null,
                id: item.id,
                estateFieldName: item.estateFieldName
            },
            () => {
                this.props.form.setFieldsValue({
                    isVsType: item.vsType,
                    estateName: item.estateName,
                    estateFieldId: item.estateFieldId.split(','),
                    fileId: item.fileId,
                })
            }
        )
    }
    saveAndUpdateTrade = (flag) => {
        let {
            file,
            domesticMarketingList,
            schoolEduCode,
            tradeDataFlag,
            id,
        } = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('file', file)
                console.log('values', values)

                if (!file || isEmpty(file.fileId)) {
                    message.info('文件上传中')
                    return
                }

                let {estateFieldName} = this.state
                this.setState({
                    tradeDataFlag: false,
                })

                if (id) {
                    //修改
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                        },
                        estateName: values.estateName,  // 	产业名称
                        estateFieldId: values.estateFieldId[0] + "," + values.estateFieldId[1] + "," + values.estateFieldId[2] + "," + values.estateFieldId[3],  //领域
                        estateFieldName: estateFieldName, // 领域名称
                        vsType: values.isVsType,  // 	虚拟仿真类型
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo311.id,
                        id: id,
                    }
                    updateTrade(data).then((e) => {
                        if (e) {
                            message.success('修改成功')
                            this.setState(
                                {
                                    showAddResource: flag,
                                    tradeDataFlag: true,
                                    file: null,
                                },
                                () => {
                                    this.props.form.resetFields()
                                    this.selectTrade()
                                }
                            )
                        }
                    })
                } else {
                    //新增
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                        },
                        estateName: values.estateName,  // 	名称
                        estateFieldId: values.estateFieldId[0] + "," + values.estateFieldId[1] + "," + values.estateFieldId[2] + "," + values.estateFieldId[3],  //产业领域
                        estateFieldName: estateFieldName, // 产业名称
                        vsType: values.isVsType,  // 	虚拟仿真类型
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo311.id,
                    }
                    addTrade(data).then((e) => {
                        if (e) {
                            message.success('添加成功')
                            this.props.form.resetFields()
                            this.setState(
                                {
                                    showAddResource: flag,
                                    tradeDataFlag: true,
                                    file: null,
                                },
                                () => {
                                    this.props.form.resetFields()
                                    this.pageChange(1)
                                }
                            )
                        }
                    })
                }
            }
        })
    }

    deleteActivity = (item) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        deleteActivity({
            id: item.id,
            targetId: domesticMarketingList.indicatorVo312.id,
            schoolEduCode: schoolEduCode,
        }).then((data) => {
            this.mesSuccess('删除成功')
            // this.selectActivity();
            // 刷新第一页数据
            this.pageChangeone(1)
        })
    }
    updateActivity = (item) => {
        console.log('item', item)
        this.setState({
            buttonFlag: false,
            showAddActives: true,
            id: item.id,
            activity: item,
        })
    }
    saveAndUpdateActivity = (flag) => {
        let {
            domesticMarketingList,
            schoolEduCode,
            activityDataFlag,
            id,
        } = this.state
        let file = this.activityForm.getFile() ? this.activityForm.getFile() : null
        this.activityForm.props.form.validateFields((err, values) => {
            if (!err) {
                console.log('file', file)
                console.log('values', values)

                if (!file || isEmpty(file.fileId)) {
                    message.info('文件上传中')
                    return
                }
                this.setState({
                    activityDataFlag: false,
                })
                if (id) {
                    //修改
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                        },
                        activityName: values.activityName,
                        activityType: values.activityType,
                        activityTime: moment(formatDate(values.activityTime)),
                        activityAddress: values.activityAddress,
                        peopleNumber: values.peopleNumber,
                        organizers: values.organizers,
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo312.id,
                        id: id,
                    }
                    updateActivity(data).then((e) => {
                        if (e) {
                            message.success('修改成功')
                            this.setState(
                                {
                                    showAddActives: flag,
                                    activityDataFlag: true,
                                    activity: null,
                                    file: null,
                                },
                                () => {
                                    this.activityForm.props.form.resetFields()
                                    this.selectActivity()
                                }
                            )
                        }
                    })
                } else {
                    //新增
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                        },
                        activityName: values.activityName,
                        activityType: values.activityType,
                        activityTime: moment(formatDate(values.activityTime)),
                        activityAddress: values.activityAddress,
                        peopleNumber: values.peopleNumber,
                        organizers: values.organizers,
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo312.id,
                    }
                    addActivity(data).then((e) => {
                        if (e) {
                            message.success('添加成功')
                            this.setState(
                                {
                                    showAddActives: flag,
                                    activityDataFlag: true,
                                    activity: null,
                                    file: null,
                                },
                                () => {
                                    this.activityForm.props.form.resetFields()
                                    this.activityForm.deleteFile()
                                    this.pageChangeone(1)
                                }
                            )
                        }
                    })
                }
            }
        })
    }

    deleteCompetition = (item) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        deleteCompetition({
            id: item.id,
            targetId: domesticMarketingList.indicatorVo313.id,
            schoolEduCode: schoolEduCode,
        }).then((data) => {
            this.mesSuccess('删除成功')
            //  this.selectCompetition();
            // 刷新第一页数据
            this.pageChangetwo(1)
        })
    }
    updateCompetition = (item) => {
        console.log('item', item)
        this.setState({
            buttonFlag: false,
            showAddaward: true,
            id: item.id,
            competition: item,
        })
    }
    saveAndUpdateCompetition = (flag) => {
        let {
            domesticMarketingList,
            schoolEduCode,
            competitionDataFlag,
            id,
        } = this.state
        let file = this.competitionForm.getFile()
            ? this.competitionForm.getFile()
            : null
        this.competitionForm.props.form.validateFields((err, values) => {
            if (!err && competitionDataFlag) {
                console.log('file', file)
                console.log('values', values)
                this.setState({
                    competitionDataFlag: false,
                })
                if (!file || isEmpty(file.fileId)) {
                    message.info('文件上传中')
                    return
                }
                if (id) {
                    //修改
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                        },
                        competitionName: values.competitionName,
                        competitionType: values.competitionType,
                        competitionTime: moment(formatDate(values.competitionTime)),
                        organizers: values.organizers,
                        awardee: values.awardee,
                        prizeAwarded: values.prizeAwarded,
                        awardName: values.awardName,
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo313.id,
                        id: id,
                    }
                    updateCompetition(data).then((e) => {
                        if (e) {
                            message.success('修改成功')
                            this.setState(
                                {
                                    showAddaward: flag,
                                    competitionDataFlag: true,
                                    competition: null,
                                    file: null,
                                },
                                () => {
                                    this.competitionForm.props.form.resetFields()
                                    this.selectCompetition()
                                }
                            )
                        }
                    })
                } else {
                    //新增
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix,
                        },
                        competitionName: values.competitionName,
                        competitionType: values.competitionType,
                        competitionTime: moment(formatDate(values.competitionTime)),
                        organizers: values.organizers,
                        awardee: values.awardee,
                        prizeAwarded: values.prizeAwarded,
                        awardName: values.awardName,
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo313.id,
                    }
                    addCompetition(data).then((e) => {
                        if (e) {
                            message.success('添加成功')
                            this.setState(
                                {
                                    showAddaward: flag,
                                    competitionDataFlag: true,
                                    competition: null,
                                    file: null,
                                },
                                () => {
                                    this.competitionForm.props.form.resetFields()
                                    this.competitionForm.deleteFile()
                                    this.pageChangetwo(1)
                                }
                            )
                        }
                    })
                }
            }
        })
    }
    /**
     * 获取资源的接口分页------（第四个table）
     */
    selectResources = () => {
        let {
            schoolEduCode,
            domesticMarketingList,
            pageNumthree,
            pageSizethree,
        } = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: domesticMarketingList.indicatorVo314.id,
            pageNum: pageNumthree,
            pageSize: pageSizethree,
        }
        selectResources(data).then((res) => {
            if (res) {
                this.setState({
                    dataSource4: res.data,
                    totalthree: res.total,
                })
            }
        })
    }
    deleteResources = (item) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        deleteResources({
            id: item.id,
            targetId: domesticMarketingList.indicatorVo314.id,
            schoolEduCode: schoolEduCode,
        }).then((data) => {
            this.mesSuccess('删除成功')
            this.selectResources()
        })
    }
    updateResources = (item) => {
        console.log('item', item)
        this.setState({
            buttonFlag: false,
            showPlatformInformatiom: true,
            id: item.id,
            resources: item,
        })
    }
    saveAndUpdateResources = (flag) => {
        let {
            domesticMarketingList,
            schoolEduCode,
            resourcesDataFlag,
            id,
        } = this.state
        this.resourcesForm.props.form.validateFields((err, values) => {
            if (!err && resourcesDataFlag) {
                console.log('values', values)
                this.setState({
                    resourcesDataFlag: false,
                })
                if (id) {
                    //修改
                    let data = {
                        resourcesName: values.resourcesName,
                        sharingMode: values.sharingMode,
                        platformAddress: values.platformAddress,
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo314.id,
                        id: id,
                    }
                    updateResources(data).then((e) => {
                        if (e) {
                            message.success('修改成功')
                            this.setState(
                                {
                                    showPlatformInformatiom: flag,
                                    resourcesDataFlag: true,
                                    resources: null,
                                    file: null,
                                },
                                () => {
                                    this.resourcesForm.props.form.resetFields()
                                    this.selectResources()
                                }
                            )
                        }
                    })
                } else {
                    //新增
                    let data = {
                        resourcesName: values.resourcesName,
                        sharingMode: values.sharingMode,
                        platformAddress: values.platformAddress,
                        schoolEduCode: schoolEduCode,
                        targetId: domesticMarketingList.indicatorVo314.id,
                    }
                    addResources(data).then((e) => {
                        if (e) {
                            message.success('添加成功')
                            this.setState(
                                {
                                    showPlatformInformatiom: flag,
                                    resourcesDataFlag: true,
                                    resources: null,
                                    file: null,
                                },
                                () => {
                                    this.resourcesForm.props.form.resetFields()
                                    this.selectResources()
                                }
                            )
                        }
                    })
                }
            }
        })
    }
    basicCondition = (e) => {
        let {domesticMarketingList, dataSource4} = this.state
        let data = {
            id: domesticMarketingList.indicatorVo314.conditionVo.id,
            confirmStatus: e.target.value,
            targetId: domesticMarketingList.indicatorVo314.id,
        }
        if (e.target.value === 0 && dataSource4.length > 0) {
            this.setState({
                conditionValue: e.target.value,
                isindependent: true,
            })
        } else {
            updateResourcesCondition(data).then((res) => {
                if (res) {
                    this.setState({
                        value: e.target.value,
                    })
                }
            })
        }
    }
    //点否二次确认
    isindependentOk = () => {
        let {domesticMarketingList, conditionValue} = this.state
        let data = {
            id: domesticMarketingList.indicatorVo314.conditionVo.id,
            confirmStatus: conditionValue,
            targetId: domesticMarketingList.indicatorVo314.id,
        }
        updateResourcesCondition(data).then((res) => {
            if (res) {
                this.setState(
                    {
                        value: conditionValue,
                        isindependent: false,
                    },
                    () => {
                        this.selectResources()
                    }
                )
            }
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files]
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile,
        })
        let thisFileName = list[0].name
            .substring(0, list[0].name.lastIndexOf('.'))
            .trim()
        if (thisFileName === '') {
            message.error('文件名不能为空')
            return
        }
        let flag = false
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen) // 截
            if (!this.checkFileType(fileType)) {
                message.error('上传的资源格式不符')
                flag = true
            }
        })
        //类型不符停止进行
        if (flag) {
            document.getElementById('file').value = ''
            return
        }
        let f = 0
        let fn = async (a) => {
            f++
            let da = await this.uploadFile(list[a])
            if (da === 1 && f < list.length) {
                return fn(f)
            }
        }
        fn(f)
    }
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null,
                })
                return this.mesWarning('文件大于20MB')
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then((res) => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2),
                    }
                    this.setState({
                        file: upFile,
                    })
                    reslove(1)
                }
            })
        })
    }
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType =
            'pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif'
        return checkType.includes(type)
    }
    // 上一步
    previous = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === 'Platform_coursesharing') {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId)
                    this.props.history.push({
                        pathname: '/Platform_coursesharing',
                    })
                    break
                }
            }
        }
    }
    // 下一步
    next = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === 'international_Exchange') {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId)
                    this.props.history.push({
                        pathname: '/international_Exchange',
                    })
                    break
                }
            }
        }
    }
    // section 2.4.1
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, this.selectTrade)
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageSize, pageNum}, this.selectTrade)
    }
    /**
     *  获取行业产业-------分页  （第一个table 的接口）
     */
    selectTrade = () => {
        let {schoolEduCode, domesticMarketingList, pageNum, pageSize} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: domesticMarketingList.indicatorVo311.id,
            pageNum: pageNum,
            pageSize: pageSize,
        }
        selectTrade(data).then((res) => {
            // alert(res.total)
            if (res) {
                this.setState({
                    dataSource1: res.data,
                    total: res.total,
                })
            }
        })
    }

    // section 2.4.2
    //第二个表格分页切换
    pageChangeone = (pageNum) => {
        this.setState({pageNumone: pageNum}, this.selectActivity)
    }
    //第二个表格分页条数切换
    onShowSizeChangeone = (pageNum, pageSize) => {
        this.setState({pageSizeone: pageSize, pageNumone: 1}, this.selectActivity)
    }
    /**
     *  举办活动的接------（第二table）
     */
    selectActivity = () => {
        let {
            schoolEduCode,
            domesticMarketingList,
            pageNumone,
            pageSizeone,
        } = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: domesticMarketingList.indicatorVo312.id,
            pageNum: pageNumone,
            pageSize: pageSizeone,
        }
        selectActivity(data).then((res) => {
            if (res) {
                this.setState({
                    dataSource2: res.data,
                    totalone: res.total,
                })
            }
        })
    }
    // section 2.4.3
    //第三个表格分页切换
    pageChangetwo = (pageNum) => {
        this.setState({pageNumtwo: pageNum}, this.selectCompetition)
    }
    //第三个表格分页条数切换
    onShowSizeChangetwo = (pageNum, pageSize) => {
        this.setState(
            {pageSizetwo: pageSize, pageNumtwo: 1},
            this.selectCompetition
        )
    }

    /**
     *  获取大赛奖项的接口  分页  （第三table）
     * **/
    selectCompetition = () => {
        let {
            schoolEduCode,
            domesticMarketingList,
            pageNumtwo,
            pageSizetwo,
        } = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: domesticMarketingList.indicatorVo313.id,
            pageNum: pageNumtwo,
            pageSize: pageSizetwo,
        }
        selectCompetition(data).then((res) => {
            if (res) {
                this.setState({
                    dataSource3: res.data,
                    totaltwo: res.total,
                })
            }
        })
    }
    //第四个表格分页切换
    pageChangethree = (pageNum) => {
        this.setState({pageNumthree: pageNum}, this.selectResources())
    }
    //第四个表格分页条数切换
    onShowSizeChangethree = (pageNum, pageSize) => {
        this.setState(
            {pageSizethree: pageSize, pageNumthree: 1},
            this.selectResources()
        )
    }
    //下载文件
    dowLoadFile = (targetMark, targetName) => {
        let now = new Date().getTime()
        axios
            .get(downTemplate(targetMark), {
                headers: {
                    'content-type': 'application/json; charset=utf-8',
                    // token: getToken(),
                    'z-ts': now,
                    'z-sign': md5(now + ''),
                },
                responseType: 'blob', // --设置请求数据格式
            })
            .then((res) => {
                const url = window.URL.createObjectURL(new Blob([res.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', `${targetName}.xls`)
                link.click()
            })
    }
    // 导入文件
    importFileTrade = async (e) => {
        console.log('e', e)
        let {domesticMarketingList, schoolEduCode} = this.state
        let list = [...e.target.files]
        let thisFileName = list[0].name
            .substring(0, list[0].name.lastIndexOf('.'))
            .trim()
        if (thisFileName === '') {
            message.error('文件名不能为空')
            return
        }
        // let flag = false;
        let checkType = 'xls、xlsx'
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen) // 截
            if (!checkType.includes(fileType)) {
                message.error('上传的资源格式不符')
                // flag = true
            } else {
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('schoolEduCode', schoolEduCode)
                fromData.append('targetId', domesticMarketingList.indicatorVo311.id)
                checkImportTargetExtensionTrade(fromData).then((res) => {
                    message.success('导入成功')
                    this.selectTrade()
                })
            }
        })
    }

    // 导入文件
    importFileActivity = async (e) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        let list = [...e.target.files]
        let thisFileName = list[0].name
            .substring(0, list[0].name.lastIndexOf('.'))
            .trim()
        if (thisFileName === '') {
            message.error('文件名不能为空')
            return
        }
        let flag = false
        let checkType = 'xls、xlsx'
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen) // 截
            if (!checkType.includes(fileType)) {
                message.error('上传的资源格式不符')
                flag = true
            }
        })
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('targetId', domesticMarketingList.indicatorVo312.id)
        checkImportTargetExtensionActivity(fromData).then((res) => {
            message.success('导入成功')
            this.selectActivity()
        })
    }

    // 导入文件
    importFileCompetition = async (e) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        let list = [...e.target.files]
        let thisFileName = list[0].name
            .substring(0, list[0].name.lastIndexOf('.'))
            .trim()
        if (thisFileName === '') {
            message.error('文件名不能为空')
            return
        }
        let flag = false
        let checkType = 'xls、xlsx'
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen) // 截
            if (!checkType.includes(fileType)) {
                message.error('上传的资源格式不符')
                flag = true
            }
        })
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('targetId', domesticMarketingList.indicatorVo313.id)
        checkImportTargetExtensionCompetition(fromData).then((res) => {
            message.success('导入成功')
            this.selectCompetition()
        })
    }

    // 导入文件
    importFileResources = async (e) => {
        let {domesticMarketingList, schoolEduCode} = this.state
        let list = [...e.target.files]
        let thisFileName = list[0].name
            .substring(0, list[0].name.lastIndexOf('.'))
            .trim()
        if (thisFileName === '') {
            message.error('文件名不能为空')
            return
        }
        let flag = false
        let checkType = 'xls、xlsx'
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.') // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen) // 截
            if (!checkType.includes(fileType)) {
                message.error('上传的资源格式不符')
                flag = true
            }
        })
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('targetId', domesticMarketingList.indicatorVo314.id)
        checkImportTargetExtensionResources(fromData).then((res) => {
            message.success('导入成功')
            this.selectResources()
        })
    }
    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null})
        this.setState({
            file: null,
        })
    }

    getColumns1Data(canEdit) {
        const {pageNum, pageSize} = this.state
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 60,
                render: (text, record, index) => {
                    return (
                        <span className={'xuhao'}>
              {(pageNum - 1) * pageSize + (index + 1)}
            </span>
                    )
                },
            },
            {
                title: '虚拟仿真类型',
                dataIndex: 'vsType',
                key: 'vsType',
                align: 'center',
                width: 100,
                render: (e) => (
                    <div>{e === 1 ? '产品' : e === 2 ? '资源' : '标准'}</div>
                ),
            },
            {
                title: '产业领域',
                dataIndex: 'estateFieldName',
                key: 'estateFieldName',
                align: 'center',
                width: 100,
            },
            {
                title: '名称',
                dataIndex: 'estateName',
                key: 'estateName',
                align: 'center',
                width: 150,
            },
            {
                title: '佐证材料',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 100,
                render: (text, record) =>
                    record.fileId ? (
                        <span className={'chakan'} onClick={() => this.handLook(record)}>
              查看
            </span>
                    ) : (
                        <span>未上传</span>
                    ),
            },
        ]
        if (canEdit)
            columns1.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 100,
                render: (text, record) => (
                    <div className={'caozuo'}>
            <span className={'cz'} onClick={() => this.updateTrade(record)}>
              编辑
            </span>
                        <span className={'cz'} onClick={() => this.deleteTrade(record)}>
              删除
            </span>
                    </div>
                ),
            })
        return columns1
    }

    getColumns2Data(canEdit) {
        const {pageNumone, pageSizeone} = this.state
        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return (
                        <span className={'xuhao'}>
              {(pageNumone - 1) * pageSizeone + (index + 1)}
            </span>
                    )
                },
            },
            {
                title: '活动类型',
                dataIndex: 'activityType',
                key: 'activityType',
                align: 'center',
                width: 150,
                render: (e) => (
                    <div>{e === 1 ? '研讨会' : e === 2 ? '师资培训' : '行业交流'}</div>
                ),
            },
            {
                title: '活动名称',
                dataIndex: 'activityName',
                key: 'activityName',
                align: 'center',
                width: 150,
            },
            {
                title: '活动时间',
                dataIndex: 'activityTime',
                key: 'activityTime',
                align: 'center',
                width: 150,
                render: (e) => <div>{moment(e).format('YYYY-MM-DD HH:mm')}</div>,
            },
            {
                title: '活动地点',
                dataIndex: 'activityAddress',
                key: 'activityAddress',
                align: 'center',
                width: 150,
            },
            {
                title: '参会人数',
                dataIndex: 'peopleNumber',
                key: 'peopleNumber',
                align: 'center',
                width: 150,
            },
            {
                title: '举办方',
                dataIndex: 'organizers',
                key: 'organizers',
                align: 'center',
                width: 150,
            },
            {
                title: '活动成果',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 150,
                render: (text, record) =>
                    record.fileId ? (
                        <span className={'chakan'} onClick={() => this.handLook(record)}>
              查看
            </span>
                    ) : (
                        <span>未上传</span>
                    ),
            },
        ]
        if (canEdit)
            columns2.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                fixed: 'right',
                render: (text, record) => (
                    <div className={'caozuo'}>
            <span className={'cz'} onClick={() => this.updateActivity(record)}>
              编辑
            </span>
                        <span className={'cz'} onClick={() => this.deleteActivity(record)}>
              删除
            </span>
                    </div>
                ),
            })
        return columns2
    }

    getColumns3Data(canEdit) {
        const {pageNumtwo, pageSizetwo} = this.state
        const columns3 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return (
                        <span className={'xuhao'}>
              {(pageNumtwo - 1) * pageSizetwo + (index + 1)}
            </span>
                    )
                },
            },
            {
                title: '大赛类型',
                dataIndex: 'competitionType',
                key: 'competitionType',
                align: 'center',
                width: 150,
                render: (e) => (
                    <div>{e === 1 ? '国家级' : e === 2 ? '省级' : '市级'}</div>
                ),
            },
            {
                title: '大赛名称',
                dataIndex: 'competitionName',
                key: 'competitionName',
                align: 'center',
                width: 150,
            },
            {
                title: '赛事时间',
                dataIndex: 'competitionTime',
                key: 'competitionTime',
                align: 'center',
                width: 150,
                render: (e) => <div>{moment(e).format('YYYY-MM-DD HH:mm')}</div>,
            },
            {
                title: '举办方',
                dataIndex: 'organizers',
                key: 'organizers',
                align: 'center',
                width: 150,
            },
            {
                title: '获奖人',
                dataIndex: 'awardee',
                key: 'awardee',
                align: 'center',
                width: 150,
            },
            {
                title: '获奖名次',
                dataIndex: 'prizeAwarded',
                key: 'prizeAwarded',
                align: 'center',
                width: 150,
            },
            {
                title: '奖项名称',
                dataIndex: 'awardName',
                key: 'awardName',
                align: 'center',
                width: 150,
            },
            {
                title: '活动成果',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 150,
                render: (text, record) =>
                    record.fileId ? (
                        <span className={'chakan'} onClick={() => this.handLook(record)}>
              查看
            </span>
                    ) : (
                        <span>未上传</span>
                    ),
            },
        ]
        if (canEdit)
            columns3.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                fixed: 'right',
                render: (text, record) => (
                    <div className={'caozuo'}>
            <span
                className={'cz'}
                onClick={() => this.updateCompetition(record)}
            >
              编辑
            </span>
                        <span
                            className={'cz'}
                            onClick={() => this.deleteCompetition(record)}
                        >
              删除
            </span>
                    </div>
                ),
            })
        return columns3
    }

    getColumns4Data(canEdit) {
        const {pageNumthree, pageSizethree} = this.state
        const columns4 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 60,
                render: (text, record, index) => {
                    return (
                        <span className={'xuhao'}>
              {(pageNumthree - 1) * pageSizethree + (index + 1)}
            </span>
                    )
                },
            },
            {
                title: '资源名称',
                dataIndex: 'resourcesName',
                key: 'resourcesName',
                align: 'center',
                width: 100,
            },
            {
                title: '共享模式',
                dataIndex: 'sharingMode',
                key: 'sharingMode',
                align: 'center',
                width: 100,
                render: (e) => (
                    <div>{e === 1 ? '校-校' : e === 2 ? '校-省' : '省-全国'}</div>
                ),
            },
            {
                title: '平台地址',
                dataIndex: 'platformAddress',
                key: 'platformAddress',
                align: 'center',
                width: 150,
            },
        ]
        if (canEdit)
            columns4.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 100,
                render: (text, record) => (
                    <div className={'caozuo'}>
            <span className={'cz'} onClick={() => this.updateResources(record)}>
              编辑
            </span>
                        <span className={'cz'} onClick={() => this.deleteResources(record)}>
              删除
            </span>
                    </div>
                ),
            })
        return columns4
    }

    onSelectedChange = (value, selectedOptions) => {
        console.log("========" + value)
        console.log("========" + selectedOptions)

        if (selectedOptions != null && selectedOptions.length > 0) {
            let length = selectedOptions.length - 1
            console.log("=======" + selectedOptions[length].label)
            console.log("=======" + selectedOptions[length].value)

            const label = selectedOptions[length].label

            this.setState({
                estateFieldName: label
            })
        }
    }

    // section Render
    render() {
        const canEdit = getCanEdit()
        const columns1 = this.getColumns1Data(canEdit)
        const columns2 = this.getColumns2Data(canEdit)
        const columns3 = this.getColumns3Data(canEdit)
        const columns4 = this.getColumns4Data(canEdit)
        const {getFieldDecorator} = this.props.form
        const {
            domesticMarketingList,
            dataSource1,
            dataSource2,
            dataSource3,
            dataSource4,
            showAddResource,
            showAddActives,
            examine,
            evidenceId,
            showAddaward,
            showPlatformInformatiom,
            pageSize,
            total,
            totalone,
            pageSizeone,
            pageSizetwo,
            totaltwo,
            totalthree,
            pageSizethree,
            activity,
            competition,
            resources,
            buttonFlag,
            file,
            value,
            isindependent,
            isShow,
            openUrl,
            majorList,
        } = this.state
        return (
            <div className="PlatformDomesticmmarketing Fillside_warp">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id="Fillside_warp">
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect">
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside
                                            onRef={(ref) => {
                                                this.LeftEfficiencyside = ref
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right">
                                    <div className="content">
                                        <div className={'cont1'}>
                                            {domesticMarketingList.indicatorVo311 ? (
                                                <div className={'titleName'}>
                                                    2.4.1 输出行业企业所需的虚拟仿真实训产品、资源和标准数
                                                </div>
                                            ) : null}
                                            <div className={'tongjigroup'}>
                                                <span className={'gongji span'}>总数量</span>
                                                <div className={'tjnum'}>{total}</div>
                                            </div>
                                            <div className={'btn_group'}>
                                                {canEdit ? (
                                                    <Button
                                                        type={'primary'}
                                                        onClick={() =>
                                                            this.setState({
                                                                showAddResource: true,
                                                                id: null,
                                                                buttonFlag: true,
                                                                file: null,
                                                            })
                                                        }
                                                    >
                                                        <Addziyuan/>
                                                        添加
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <Table
                                                columns={columns1}
                                                dataSource={dataSource1}
                                                className={'table1'}
                                                bordered={true}
                                                locale={{
                                                    emptyText: (
                                                        <>
                                                            <div className="Empty-content">
                                                                <div className="text">
                                                                    <img
                                                                        src={
                                                                            window.$$imgSrcLcl +
                                                                            'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'
                                                                        }
                                                                    />
                                                                    <span>暂无数据</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                }}
                                                pagination={{
                                                    current: this.state.pageNum,
                                                    total: total,
                                                    pageSize: pageSize,
                                                    onChange: this.pageChange,
                                                    onShowSizeChange: this.onShowSizeChange,
                                                    pageSizeOptions: ['10', '20', '30', '40'],
                                                    showSizeChanger: true,
                                                    // position:'bottomRight'
                                                }}
                                            />
                                        </div>
                                        <div className={'cont1'}>
                                            {domesticMarketingList.indicatorVo312 ? (
                                                <div className={'titleName'}>
                                                    2.4.2 举办虚拟仿真实训教育研讨会、师资培训会等
                                                </div>
                                            ) : null}
                                            <div className={'tongjigroup'}>
                                                <span className={'gongji span'}>数量</span>
                                                <div className={'tjnum'}>{totalone}</div>
                                            </div>
                                            <div className={'btn_group'}>
                                                {canEdit ? (
                                                    <Button
                                                        type={'primary'}
                                                        onClick={() =>
                                                            this.setState({
                                                                showAddActives: true,
                                                                id: null,
                                                                buttonFlag: true,
                                                            })
                                                        }
                                                    >
                                                        <AddActivitie/>
                                                        添加
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            <Table
                                                columns={columns2}
                                                dataSource={dataSource2}
                                                className={'table1'}
                                                id={'table242'}
                                                bordered={true}
                                                scroll={{x: 1300}}
                                                locale={{
                                                    emptyText: (
                                                        <>
                                                            <div className="Empty-content">
                                                                <div className="text">
                                                                    <img
                                                                        src={
                                                                            window.$$imgSrcLcl +
                                                                            'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'
                                                                        }
                                                                    />
                                                                    <span>暂无数据</span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ),
                                                }}
                                                pagination={{
                                                    current: this.state.pageNumone,
                                                    total: totalone,
                                                    pageSize: pageSizeone,
                                                    onChange: this.pageChangeone,
                                                    onShowSizeChange: this.onShowSizeChangeone,
                                                    pageSizeOptions: ['10', '20', '30', '40'],
                                                    showSizeChanger: true,
                                                    // position:'bottomRight'
                                                }}
                                            />
                                        </div>

                                        {canEdit ? (
                                            <div className={'footer-btn'}>
                                                <Button
                                                    className={'syb'}
                                                    onClick={() => this.previous()}
                                                >
                                                    上一步
                                                </Button>
                                                <Button type={'primary'} onClick={() => this.next()}>
                                                    下一步
                                                </Button>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {/*添加资源*/}
                                        <Modal
                                            title={'添加'}
                                            visible={showAddResource}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className="footer-btn">
                                                    <Button
                                                        className={'quxiao'}
                                                        onClick={() =>
                                                            this.setState({showAddResource: false})
                                                        }
                                                    >
                                                        取消
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => this.saveAndUpdateTrade(false)}
                                                    >
                                                        提交
                                                    </Button>
                                                    {buttonFlag ? (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => this.saveAndUpdateTrade(true)}
                                                        >
                                                            提交并继续添加
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            }
                                        >
                                            <Form>
                                                <Form.Item>
                          <span className={'label'}>
                            <span className="red">*</span>虚拟仿真类型
                          </span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('isVsType', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: '请选择虚拟仿真的类型',
                                                                },
                                                            ],
                                                        })(
                                                            <Radio.Group>
                                                                <Radio value={1}>产品</Radio>
                                                                <Radio value={2}>资源</Radio>
                                                                <Radio value={3}>标准</Radio>
                                                            </Radio.Group>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                          <span className={'label'}>
                            <span className="red">*</span>名称
                          </span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('estateName', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请填写名称`,
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: '文本过长，最多30字',
                                                                },
                                                            ],
                                                        })(
                                                            <Input
                                                                placeholder="请输入产业名称"
                                                                maxLength={30}
                                                                suffix={
                                                                    <span className="len">
                                    {this.props.form.getFieldValue(`estateName`) ? this.props.form.getFieldValue(`estateName`).length : 0}/30
                                  </span>
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className="li_casc_area">
                          <span className={'label'}>
                            <span className="red">*</span>产业领域
                          </span>
                                                    {getFieldDecorator('estateFieldId', {
                                                        initialValue: '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请选择所属产业领域`,
                                                            },
                                                        ],
                                                    })(
                                                        <Cascader
                                                            onChange={this.onSelectedChange}
                                                            placeholder={'请选择产业领域'}
                                                            options={majorList}
                                                        />
                                                    )}
                                                </Form.Item>
                                                <Form.Item className="li_formup">
                          <span className={'label'}>
                            <span className="red">*</span>佐证材料
                          </span>
                                                    {getFieldDecorator('fileId', {
                                                        initialValue: '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请选择佐证材料`,
                                                            },
                                                        ],
                                                    })(
                                                        <div className="up_button">
                                                            <div className="span_right">
                                                                <label htmlFor="file" className="inputlabelBox">
                                  <span>
                                    <Upicon/>
                                    上传资料
                                  </span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    accept="image"
                                                                    onChange={this.multipartUpload}
                                                                    onClick={(event) => {
                                                                        event.target.value = null
                                                                    }}
                                                                    name="myfile"
                                                                    id="file"
                                                                    style={{display: 'none'}}
                                                                />
                                                                <span className={'tip'}>
                                  支持：pdf、doc/docx、xls/xlsx
                                  <br/>
                                  ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                </span>
                                                            </div>
                                                            <ul className="bottom_tit">
                                                                {/*上传中*/}
                                                                {file ? (
                                                                    <Tooltip
                                                                        placement="topLeft"
                                                                        title={file.originName}
                                                                    >
                                                                        <li className="title_de">
                                                                            {file.fileId ? <Upiconwc/> : <Spin/>}
                                                                            <span className="tie_span">
                                        {file.originName}
                                      </span>
                                                                            <i
                                                                                className="Upicona"
                                                                                onClick={this.deleteFile}
                                                                            >
                                                                                <Upicondele/>
                                                                            </i>
                                                                        </li>
                                                                    </Tooltip>
                                                                ) : null}
                                                                {/*已完成*/}
                                                                {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Form>
                                        </Modal>
                                        {/*添加活动*/}
                                        <Modal
                                            title={'添加'}
                                            visible={showAddActives}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className="footer-btn">
                                                    <Button
                                                        className={'quxiao'}
                                                        onClick={() =>
                                                            this.setState({
                                                                showAddActives: false,
                                                                activity: null,
                                                            })
                                                        }
                                                    >
                                                        取消
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => this.saveAndUpdateActivity(false)}
                                                    >
                                                        提交
                                                    </Button>
                                                    {buttonFlag ? (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => this.saveAndUpdateActivity(true)}
                                                        >
                                                            提交并继续添加
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            }
                                        >
                                            <AddActivities
                                                wrappedComponentRef={(form) => {
                                                    this.activityForm = form
                                                }}
                                                data={activity}
                                            />
                                        </Modal>
                                        {/*添加奖项*/}
                                        <Modal
                                            title={'添加奖项'}
                                            visible={showAddaward}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className="footer-btn">
                                                    <Button
                                                        className={'quxiao'}
                                                        onClick={() =>
                                                            this.setState({
                                                                showAddaward: false,
                                                                competition: null,
                                                            })
                                                        }
                                                    >
                                                        取消
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => this.saveAndUpdateCompetition(false)}
                                                    >
                                                        提交
                                                    </Button>
                                                    {buttonFlag ? (
                                                        <Button
                                                            type="primary"
                                                            onClick={() =>
                                                                this.saveAndUpdateCompetition(true)
                                                            }
                                                        >
                                                            提交并继续添加
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            }
                                        >
                                            <AddAward
                                                wrappedComponentRef={(form) => {
                                                    this.competitionForm = form
                                                }}
                                                data={competition}
                                            />
                                        </Modal>
                                        {/*添加平台信息*/}
                                        <Modal
                                            title={'添加平台信息'}
                                            visible={showPlatformInformatiom}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className="footer-btn">
                                                    <Button
                                                        className={'quxiao'}
                                                        onClick={() =>
                                                            this.setState({
                                                                showPlatformInformatiom: false,
                                                                resources: null,
                                                            })
                                                        }
                                                    >
                                                        取消
                                                    </Button>
                                                    <Button
                                                        type="primary"
                                                        onClick={() => this.saveAndUpdateResources(false)}
                                                    >
                                                        提交
                                                    </Button>
                                                    {buttonFlag ? (
                                                        <Button
                                                            type="primary"
                                                            onClick={() => this.saveAndUpdateResources(true)}
                                                        >
                                                            提交并继续添加
                                                        </Button>
                                                    ) : null}
                                                </div>
                                            }
                                        >
                                            <AddPlatforminformatiom
                                                wrappedComponentRef={(form) => {
                                                    this.resourcesForm = form
                                                }}
                                                data={resources}
                                            />
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                {/*查看资料*/}
                <FileLook
                    openUrl={openUrl}
                    isShow={isShow}
                    dismiss={this.dismiss}
                ></FileLook>
                {/*二次确认*/}
                <Modal
                    title={'温馨提示'}
                    visible={isindependent}
                    width={430}
                    destroyOnClose={true}
                    closable={false}
                    maskClosable={false}
                    centered={true}
                    className={'wenxintishi'}
                    footer={
                        <div className="footer-btn">
                            <Button
                                className={'quxiao'}
                                onClick={() => this.setState({isindependent: false})}
                            >
                                取消
                            </Button>
                            <Button type="primary" onClick={this.isindependentOk}>
                                确定
                            </Button>
                        </div>
                    }
                >
                    <div className={'tipwrap'}>
                        <WenxinTip/>
                        <span>选择否，将清空当前已填写的数据，请确认是否继续？</span>
                    </div>
                </Modal>
                <Footer/>
            </div>
        )
    }
}

PlatformDomesticmmarketing = Form.create()(PlatformDomesticmmarketing)
export default PlatformDomesticmmarketing
