// 项目情况-平台情况
import React from "react";
import {Form, Input, message, Spin, Select, Tooltip, Cascader} from 'antd';
import "./index.scss";
import { uploadFile } from '../../../../api/uploadFile';
import moment from "moment";
import {Upicon,Upiconwc,Upicondele} from "../../../../components/iconSvg";
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
class AddPersonnel extends React.Component {
    state = {
        data: [],
        majorList:this.props.majorList,
    };

    componentDidMount() {
        let {data} = this.props;
        if(data){
            let courseMajor = []
            courseMajor.push(data ? data.majorOne : '')
            courseMajor.push(data ? data.majorTwo : '')
            courseMajor.push(data ? data.planMajor : '')
            console.log('componentDidMount   courseMajor', courseMajor)
            let upFile = {
                originName: data ? data.resourceName : '',
                fileId: data ? data.fileId  : '',
                fileSuffix: data ? data.resourceSuffix : '',
                fileSize: data ?  ( data.fileSize / 1024).toFixed(2) : ''
            }
            this.setState({
                file: upFile,
                data: data
            })
            this.props.form.setFieldsValue({
                planName: data ? data.planName : '',
                planMajor: courseMajor,
                fileId: data ? data.fileId : '',
            })

        }
    }

    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    getState = () => {
        return this.state.file
    }

    render() {
        const {getFieldDecorator }=this.props.form
        const {file} = this.state;
        const {majorList} = this.state
        return (
            <div className="AddPersonnel" >
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>方案名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('planName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写方案名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请填写方案名称' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`planName`) ? this.props.form.getFieldValue(`planName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item  className="input_liform">
                        <span className={'label'}><span className='red'>*</span>所属专业</span>
                        {
                            getFieldDecorator('planMajor', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择所属专业'
                                    },
                                ]
                            })(
                                <Cascader options={majorList} style={{width:"280px"}}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item className="li_formup" >
                        <span className={'label'}><span className='red'>*</span>佐证材料</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {/*上传中*/}
                                    {file && file.fileId !== null ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                </ul>
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
AddPersonnel = Form.create()(AddPersonnel);
export default AddPersonnel;