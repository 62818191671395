/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-08-24 17:09:30
 * @LastEditors: nliu
 * @LastEditTime: 2021-09-03 11:25:13
 */
// 教师发展

import { post } from '../../until/axios_instans';
// 教师发展整页数据查询接口
export function teacherList(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/teacherList', data);
}
// 新增培训
export function addPartakeTrain(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/addPartakeTrain', data);
}
// 修改培训
export function updatePartakeTrain(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/updatePartakeTrain', data);
}
// 获取培训
export function getPartakeTrain(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/getPartakeTrain', data);
}
// 删除培训
export function deletePartakeTrain(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/deletePartakeTrain', data);
}
// 新增师生参与占比
export function addPartakeCourse(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/addPartakeCourse', data);
}
// 修改师生参与占比
export function updatePartakeCourse(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/updatePartakeCourse', data);
}
// 获取师生参与占比
export function getPartakeCourse(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/getPartakeCourse', data);
}
// 删除师生参与占比
export function deletePartakeCourse(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/deletePartakeCourse', data);
}
// 新增专业带头人
export function addPartakeLeader(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/addPartakeLeader', data);
}
// 修改专业带头人
export function updatePartakeLeader(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/updatePartakeLeader', data);
}
// 获取专业带头人
export function getPartakeLeader(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/getPartakeLeader', data);
}
// 删除专业带头人
export function deletePartakeLeader(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/deletePartakeLeader', data);
}
// 导入师生参与占比
export function importPartakeCourse(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/importPartakeCourse', data);
}
// 导入培训
export function importPartakeTrain(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/importPartakeTrain', data);
}
// 导入专业带头人
export function importPartakeLeader(data) {
    return post(window.apiShixun+'/api/v1/teacherDevelopment/importPartakeLeader', data);
}

// 部分刷新课题数据
/**
 * 
 * @param {*} data 
 * @returns 
 * {
	"id": "",
	"pageNum": 1,
	"pageSize": 10,
	"schoolEduCode": "",
	"targetId": ""
}
 */
export function getPartakeProject(data) {
    return post(window.apiShixun+ "/api/v1/teacherDevelopment/getPartakeProject", data)
}

export function editPartakeProject(data) {
    if(data.id) {
        return post(window.apiShixun+ "/api/v1/teacherDevelopment/updatePartakeProject", data)    
    }
    return post(window.apiShixun+ "/api/v1/teacherDevelopment/addPartakeProject", data)
}


export function deletePartakeProject(data) {
    return post(window.apiShixun+ "/api/v1/teacherDevelopment/deletePartakeProject", data)
}

/**
 * 1.4.5 大赛
 * @param {*} data 
 * @returns 
 * {
	"id": "",
	"pageNum": 1,
	"pageSize": 10,
	"schoolEduCode": "",
	"targetId": ""
}
 */
export function getVirtualSimulation(data) {
    return post(window.apiShixun+ "/api/v1/teacherDevelopment/getVirtualSimulation", data)
}

export function saveOrUpdateVirtualSimulation(data) {
    return post(window.apiShixun+ "/api/v1/teacherDevelopment/saveOrUpdateVirtualSimulation", data)
}


export function deleteVirtualSimulation(data) {
    return post(window.apiShixun+ "/api/v1/teacherDevelopment/deleteVirtualSimulation", data)
}



