// 导航
import React, {Component} from 'react';
import './index.scss';
import '../../../reset_style/index.less';
import {Button, Form, Input, InputNumber, message, Modal, Radio, Table} from 'antd';
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import {TianJia} from "../../iconSvg";
import {getCanEdit, getTargetId} from "../../../until/cookie";
import ImportComponent from "../../importComponent/ImportComponent";
import {
    addAndSaveProject,
    deleteProject,
    getPageProjectList,
    selectAllResourcesService,
    selectProjectInfoById,
} from "../../../api/target";

@withRouter
class PlatformService_2_2_4 extends Component {
    state = {
        data_2_2_4: {},
        // 页面数据

        pageNum: 1,//页数
        pageSize: 10,//条数
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        currentEditProjectId: "",
    };

    componentDidMount() {
        this.setState({
            navName: this.props.navName,
        });
    }


    UNSAFE_componentWillReceiveProps(props) {
        const {data_2_2_4} = this.state
        if (undefined !== props.data_2_2_4?.id && props.data_2_2_4?.id !== data_2_2_4?.id) {
            this.setState({
                data_2_2_4: props.data_2_2_4
            })
        }
    }

    // section Action
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.get_2_2_4_listData()
        });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageNum: 1, pageSize}, this.get_2_2_4_listData);
    }
    handleCancel = () => {
        this.setState({showAddJl: false});
    };

    //删除前二次确认
    deleteAsk = (e) => {
        let data = {
            id: e.id
        }
        deleteProject(data).then(e => {
            this.setState({
                pageNum: 1
            }, () => {
                message.success("删除成功")
                this.get_2_2_4_listData()
            })
        })
    }

    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: '', resourceName: ''});
        this.setState({
            file: null
        })
    }

    // section Request
    // 获取类表数据
    get_2_2_4_listData = () => {
        let data = {
            targetId: this.state.data_2_2_4.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolEduCode
        }
        getPageProjectList(data).then((res) => {
            let data_2_2_4 = this.state.data_2_2_4
            data_2_2_4.pageData = res.pageData;
            data_2_2_4.fictitiousSimulationServiceNum = res?.fictitiousSimulationServiceNum ? Number(res.fictitiousSimulationServiceNum) : 0
            data_2_2_4.vocationalSkillsNum = res?.vocationalSkillsNum ? Number(res.vocationalSkillsNum) : 0
            this.setState({
                data_2_2_4: data_2_2_4
            }, () => {
                this.getAllDicMethods()
            })
        })
    }

    // 获取全部数据, 更新2.2.5
    getAllDicMethods = () => {
        let data = {
            id: getTargetId("platform_service"),
        }
        selectAllResourcesService(data).then(e => {
            console.log('selectAllResources,', e)
            // 回调更新 2.2.3的内容
            let haveData = (e?.socialProfessionalSkillVo?.pageData?.data ? e?.socialProfessionalSkillVo?.pageData?.data.length : 0) > 0

            this.props.dataUpdate_to_2_2_5(e.socialProfessionalSkillNumVo, haveData)
        })

    }

    // 添加提交
    addAndSaveProjectRequest = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let {schoolEduCode, currentEditProjectId, data_2_2_4} = this.state
                let data = {
                    "examinationAdoptNum": value.examinationAdoptNum,
                    "examinationNum": value.examinationNum,
                    "id": currentEditProjectId,
                    "isFictitiousSimulationService": value.isFictitiousSimulationService,
                    "schoolEduCode": schoolEduCode,
                    "targetId": data_2_2_4.id,
                    "vocationalSkillsName": value.vocationalSkillsName,
                }
                addAndSaveProject(data).then(e => {
                    this.setState({showAddJl: false, currentEditProjectId: ""})
                    this.get_2_2_4_listData()
                    message.success(this.state.isEdit === false ? '添加成功' : '修改成功');
                })
            }
        })
    }

    edit = (item) => {
        let id = item.id
        this.setState({
            showAddJl: true,
            currentEditProjectId: id,
            isEdit: true,
        })
        let data = {
            id: id
        }
        selectProjectInfoById(data).then(e => {

            // section 1
            this.props.form.setFieldsValue({
                'vocationalSkillsName': e.vocationalSkillsName,
                'isFictitiousSimulationService': e.isFictitiousSimulationService,
                'examinationNum': e.examinationNum,
                'examinationAdoptNum': e.examinationAdoptNum,
            })
        })
    }

    //添加提交继续添加
    addContinue = (id) => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let {schoolEduCode} = this.state

                let data = {
                    "examinationAdoptNum": value.examinationAdoptNum,
                    "examinationNum": value.examinationNum,
                    "isFictitiousSimulationService": value.isFictitiousSimulationService,
                    "schoolEduCode": schoolEduCode,
                    "targetId": this.state.data_2_2_4.id,
                    "vocationalSkillsName": value.vocationalSkillsName,
                }
                addAndSaveProject(data).then(e => {
                    message.success('添加成功');
                    this.setState({
                        currentEditProjectId: ""
                    })
                    this.props.form.resetFields();
                    this.get_2_2_4_listData()
                })
            }
        })
    }

    render() {
        const canEdit = getCanEdit()

        const {
            data_2_2_4,
            totalone,
            pageSizeone,
            file,
            showAddJl,
            pageSize,
            pageNum,
            isEdit,
        } = this.state
        const {getFieldDecorator} = this.props.form
        const pageData = (data_2_2_4) ? data_2_2_4.pageData : {}
        const tableData = (pageData?.data) ? pageData.data : []

        // 安全判断, 取消
        // const fictitiousSimulationServiceNum = data_2_2_4?.fictitiousSimulationServiceNum ? data_2_2_4.fictitiousSimulationServiceNum : 0
        // const vocationalSkillsNum = data_2_2_4?.vocationalSkillsNum ? data_2_2_4.vocationalSkillsNum : 0
        // const showVocationalSkillsNum = Math.min(fictitiousSimulationServiceNum, vocationalSkillsNum)

        const showVocationalSkillsNum = data_2_2_4?.vocationalSkillsNum ? Number(data_2_2_4.vocationalSkillsNum) : 0
        const fictitiousSimulationServiceNum = data_2_2_4?.fictitiousSimulationServiceNum ? Number(data_2_2_4.fictitiousSimulationServiceNum) : 0

        const numTotal = (((showVocationalSkillsNum === 0) ? 0 : fictitiousSimulationServiceNum / showVocationalSkillsNum) * 100).toFixed(2)
        const total = pageData?.total ? pageData.total : 0

        const labelWidth = 200


        // section columns2
        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{pageSize * (pageNum - 1) + index + 1}</span>
                }
            },
            {
                title: '职业技能等级鉴定项目名称',
                dataIndex: 'vocationalSkillsName',
                key: 'vocationalSkillsName',
                align: 'center',
                // width: 250
            },
            {
                title: '是否为虚拟仿真实训服务',
                dataIndex: 'isFictitiousSimulationService',
                key: 'isFictitiousSimulationService',
                align: 'center',
                width: 150
            },
            {
                title: '考试人数',
                dataIndex: 'examinationNum',
                key: 'examinationNum',
                align: 'center',
                width: 150
            },
            {
                title: '考试通过人数',
                dataIndex: 'examinationAdoptNum',
                key: 'examinationAdoptNum',
                align: 'center',
                width: 150
            },
        ]
        if (canEdit) columns2.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            fixed: 'right',
            width: 200,
            render: (text, record) => <div>
                    <span className={'cz'} onClick={() => {
                        this.edit(record)
                    }}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteAsk(record)}>删除</span>
            </div>

        })

        // section head
        const head = <div>
            <ul className={'platformService_2_2_4'}>
                <li>
                    <span className={'span1'}>项目总数</span>
                    <div
                        className={'btn'}>{showVocationalSkillsNum}</div>
                </li>

                <li>
                    <span className={'span1'}>虚拟仿真类项目数</span>
                    <div
                        className={'btn'}>{fictitiousSimulationServiceNum}</div>
                </li>
                <li>
                    <span className={'span1'}>占比</span>
                    <div
                        className={'btn'}>{numTotal}%
                    </div>
                    <span className={'span6'} hidden={tableData.length <= 0 || numTotal >= 30}>*该项指标需达30%</span>

                </li>
            </ul>
            {canEdit ? <span className={'btn_group'}>
                <Button type={'primary'} className="icon" onClick={() => this.setState({
                    showAddJl: true,
                    isEdit: false,
                })}><TianJia/>添加</Button>
                {/*<Button type={'primary'}><Input id='importFile' type="file"*/}
                {/*                                accept="image"*/}
                {/*                                onClick={(event) => {*/}
                {/*                                    event.target.value = null*/}
                {/*                                }}*/}
                {/*                                onChange={(e) => this.importPlatformService(e, this.state.targetId2)}/><ExcelImport/>excel导入</Button>*/}
                {/*<Button type={'primary'}*/}
                {/*        onClick={() => this.dowLoadFile(this.state.targetMark2, this.state.targetName2)}><Daoru/>下载导入模版</Button>*/}
            </span> : ""}
            <ImportComponent
                editEnable={canEdit}
                targetId={this.state.data_2_2_4.id}
                targetMark="2.2.4"
                downloadName="虚拟仿真实训服务职业技能等级鉴定项目占比"
                onImportSuccess={() => {
                    this.setState({pageNum: 1}, this.get_2_2_4_listData)
                }}
            />
        </div>

        // section table
        const table = <Table columns={columns2}
                             dataSource={tableData}
                             className={'table1'}
                             scroll={{x: 1000}}
                             bordered={true}
                             locale={{
                                 emptyText: <>
                                     <div className="Empty-content">
                                         <div className="text">
                                             <img
                                                 src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                             <span>暂无数据</span>
                                         </div>
                                     </div>
                                 </>
                             }}
                             pagination={
                                 {
                                     total: total,
                                     pageSize: pageSize,
                                     onChange: this.pageChange,
                                     current: pageNum,
                                     onShowSizeChange: this.onShowSizeChange,
                                     pageSizeOptions: ['10', '20', '30', '40'],
                                     showSizeChanger: true,
                                     // position:'bottomRight'
                                 }
                             }
        />

        // section Modal
        const modal = <Modal
            title={this.state.isEdit === false ? '添加' : '编辑'}
            visible={showAddJl}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            className={'addAddJlModal'}
            wrapClassName="wappop_up"
            footer={
                <div className='footer-btn'>
                    <Button className={'quxiao'}
                            onClick={() => this.handleCancel()}>取消</Button>
                    <Button type='primary'
                            onClick={() => this.addAndSaveProjectRequest()}>提交</Button>
                    {this.state.isEdit == false ? <Button type='primary'
                                                          onClick={() => this.addContinue()}>提交并继续添加</Button> : ''}
                </div>
            }
        >
            <Form>
                <Form.Item label={'鉴定项目名称'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('vocationalSkillsName', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写项目名称`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入项目名称' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`vocationalSkillsName`) ? this.props.form.getFieldValue(`vocationalSkillsName`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'是否为虚拟仿真实训服务'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('isFictitiousSimulationService', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择`,
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={"是"}>是</Radio>
                                <Radio value={"否"}>否</Radio>
                            </Radio.Group>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'考试人数'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('examinationNum', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请输入考试人数`,
                                },
                            ],
                        })(
                            <InputNumber style={{width: '100%'}}
                                         placeholder='请输入考试人数'
                                         maxLength={30}
                                         min={this.props.form.getFieldValue(`examinationAdoptNum`)}
                                         suffix={
                                             <span className='len'>
                                             {this.props.form.getFieldValue(`examinationNum`) ? this.props.form.getFieldValue(`examinationNum`).length : 0}/30
                                             </span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'考试通过人数'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('examinationAdoptNum', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请输入考试通过人数`,
                                },
                            ],
                        })(
                            <InputNumber style={{width: '100%'}}
                                         placeholder='请输入人数'
                                         maxLength={30}
                                         max={this.props.form.getFieldValue(`examinationNum`)}
                                         suffix={
                                             <span className='len'>
                                             {this.props.form.getFieldValue(`examinationAdoptNum`) ? this.props.form.getFieldValue(`examinationAdoptNum`).length : 0}/30
                                             </span>}/>
                        )}
                    </div>
                </Form.Item>
            </Form>
        </Modal>

        return (
            <div className={'cont1'}>
                <div className={'titleName'}>2.2.4 虚拟仿真实训服务职业技能等级鉴定项目占比</div>
                {head}

                {table}
                {modal}
            </div>
        );
    }
}

const PlatformServiceComponent_2_2_4 = Form.create()(PlatformService_2_2_4);
export default PlatformServiceComponent_2_2_4;
