import React, {Component} from 'react'
import {Input, Form, Button, message} from "antd";
import moment from "moment";

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

class Pesourcename extends React.Component {
    state = {}
//上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        const {tableData, fileNumber} = this.state;
        if ((tableData.length + list.length + fileNumber) > 20) {
            message.error("备课包资源数量不能超过20")
            return;
        }
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        this.setState({
            file: list[0]
        })
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
                return;
            }
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            let fileId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            fileId = `zjyd-space-front-geren/${ymd}/${fileId}`
            k["fileId"] = fileId
            if (!tableData.find((v) => v.resourceName === k.name)) {
                tableData.push({
                    fileId: fileId,
                    resourceName: k.name,
                    resourceFormat: fileType,
                    resourceSize: (k.size / 1024).toFixed(2),
                    operationTime: transTime(k.lastModifiedDate),
                    createTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    plan: 0,
                    lastModified: k.lastModified
                });
            }
        });
        if (flag) {
            return;
        }
        this.setState({
            tableData: tableData,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
        this.setState({
            inputValue: ""
        })
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc/docx、xls/xlsx 、ppt/pptx、txt、jpg、jpeg、png"
        // let checkType = "mp3、wav、wmv、doc/docx、xls/xlsx、ppt/pptx、pdf、txt、mp4、avi、rm/rmvb、mov、wmv、swf、flv、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    handleCancel = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;

        return (
            <>
                <Form>
                    <Form.Item label="资源名称">
                        {
                            getFieldDecorator('platformName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '平台名称不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`planName`) ? this.props.form.getFieldValue(`planName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="上架平台">
                        {
                            getFieldDecorator('platformURL', {
                                rules: [
                                    {
                                        required: true,
                                        message: '平台地址不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`planName`) ? this.props.form.getFieldValue(`planName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="网址链接">
                        {
                            getFieldDecorator('platformURL', {
                                rules: [
                                    {
                                        required: true,
                                        message: '平台地址不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`planName`) ? this.props.form.getFieldValue(`planName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item className="li_form">
                        <span className={'label'}><span className='red'>*</span>佐证材料</span>
                        {getFieldDecorator('cailiao', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="span_right">
                                <label htmlFor="file" className="inputlabelBox">
                                    <span>上传资料</span>
                                </label>
                                <input
                                    type="file"
                                    accept="image"
                                    onChange={this.multipartUpload}
                                    name="myfile"
                                    id="file"
                                    style={{display: "none"}}
                                />
                                <span className={'len'}>0/1</span>
                                <Button className='MaBtn'>查看</Button>
                                <Button className='MaBtn'>删除</Button>
                            </div>
                        )}
                        <span className={'tip'}>
                                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                                    </span>
                    </Form.Item>
                </Form>
            </>
        )
    }
}


Pesourcename = Form.create()(Pesourcename);
export default Pesourcename;
