import React, {Component} from 'react'
import {Form, Input, Button, Checkbox, message, Row, Col} from 'antd'
import {withRouter} from 'react-router-dom'
import './index.scss'
import LeftComponent from '../demo_Info/components/Left_Efficiencyside'
import Heder from "../../components/header";
import Right_Content from './components/Right_Content'
import  Demo1 from '../demo1'
class DemoInfo extends Component {

    state = {
        id: this.props.location.state!=null?this.props.location.state.id:4
    };
    dismiss=()=>{
        this.props.history.goBack()
    }
    render() {
        const id=this.props.location.state!=null?this.props.location.state.id:4
        const title=this.props.location.state!=null?this.props.location.state.title:""
        console.log("this.props=======%o", this.props)
        console.log("title======="+title)
        return (
            <div >
            
            <div>
               <Heder/>
            </div>
            


                <div className="Demo_Info">
                <div className="content">
                <Row>
                    <Col span={4}>
                        <div>
                            <LeftComponent id={id}></LeftComponent>
                        </div>
                    </Col>
                    <Col span={20}>
                        <div className="right_content">
                            <Right_Content id={id}></Right_Content>
                        </div>
                    </Col>
                </Row>
                </div>
                </div>
               
               

                
                 
                
            </div>
        )
    }
}


export default withRouter(Form.create()(DemoInfo))