import React, { Fragment } from 'react'
import { Checkbox, Form, Input, Select, Radio, DatePicker } from 'antd'
import moment from "moment";
import { range } from '@/until/util'
class SimpleForm extends React.Component {
  componentDidMount() {
    this.props.onRef && this.props.onRef(this)
    let { record, columns } = this.props
    let formValues = {}
    if (record) {
      columns.forEach(item => {
        if (item.type === "datetime") {
          formValues[item.dataIndex] = moment(record[item.dataIndex])
        } else {
          formValues[item.dataIndex] = record[item.dataIndex]
        }
      })
      this.props.form.setFieldsValue(formValues)
    }

  }
  render() {
    const { columns, form } = this.props
    const { getFieldDecorator } = form

    const disabledDateTime = (date) => {
      let today = moment();
      if (date.isSameOrBefore(today, 'minutes')) {
        if (date.isSame(today, 'minutes')) {
          let hour = date.hour();
          let minute = date.minute();
          return {
            disabledHours: () => range(hour, 24),
            disabledMinutes: () => range(minute, 60)
          };
        }
        return true
      }
      return false

    }


    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 18 },
        sm: { span: 18 }
      },
    };


    const renderFormItem = (item) => {
      let options = {}
      if (item.type == 'datetime') {

        if (item.format) {
          options.format = item.format;
        } else {
          options.format = "YYYY-MM-DD HH:mm"
        }
        return <DatePicker disabledDate={currentDate => !currentDate.isSameOrBefore(moment().locale('zh'))} showTime={{ format: 'HH:mm' }} disabledTime={disabledDateTime} placeholder={`请选择${item.title}`}  {...options} />
      }
      if (item.type == 'select') {
        return <Select placeholder={`请选择${item.title}`}>
          {
            item.data.map(ditem => (<Select.Option value={ditem.value}>{ditem.lable}</Select.Option>))
          }
        </Select>
      }
      if (item.type == 'checkbox') {
        return <Radio.Group placeholder={`请选择${item.title}`}>
          {
            item.data.map(ditem => (<Radio value={ditem.value}>{ditem.lable}</Radio>))
          }
        </Radio.Group>
      }
      if (item.maxLength) {

      }
      if (item.maxLength) {
        options.maxLength = item.maxLength;
        options.suffix = <span className='len' style={{ paddingLeft: 30 }}>
          {this.props.form.getFieldValue(`${item.dataIndex}`) ? this.props.form.getFieldValue(`${item.dataIndex}`).length : 0}/30</span>
      }
      return <Input style={{ width: '100%' }} placeholder={`请输入${item.title}`} {...options} />
    }

    return (<Form {...formItemLayout} style={{ width: '100%' }}>

      {columns.filter(item => item.form == true).map(item => (<Form.Item key={item.dataIndex} label={item.title} style={{ display: 'flex' }}>
        {getFieldDecorator(item.dataIndex, {
          initialValue: item.initialValue,
          rules: [
            {
              required: true,
              message: `请输入${item.title}`,
            }
          ],
        })(renderFormItem(item))
        }
      </Form.Item>))}
    </Form>)
  }
}

export default Form.create()(SimpleForm);