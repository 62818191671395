import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import MedalTable from '../TableCmp/MedalTable'
import TableCmp from '../TableCmp';
import { Row, Col, Modal, Button, Form, Input, message } from 'antd'
import ImportComponent from "@/components/importComponent/ImportComponent";
import UploaderComponent from "@/components/uploader_component";
import {
  TeacherDevelopmentUpload,
  Daoru
} from "@/components/iconSvg";
import './index.scss'
import { contestLevelList } from '../../tableMetaData'
import SimpleForm from '../SimpleForm';

import { getVirtualSimulation, saveOrUpdateVirtualSimulation, deleteVirtualSimulation } from '../../../../api/teacherDevelopment'
import moment from 'moment'


export default class ItemCmp extends Component {
  state = {
    selectedRecord: null,
    visible: false,
    data: {},
    submitting: false,
    loading: false,
  }

  saveData = (values, isClear) => {
    values.targetId = this.props.data.id;
    values.schoolEduCode = this.props.schoolEduCode;
    values.contestTime = values.contestTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
    values.id = this.state.selectedRecord?.id;
    saveOrUpdateVirtualSimulation(values).then(res => {
      if (res) {
       
        if (isClear) {
          //继续添加
          message.success('添加成功') 
          this.wrapperFormRef.props.form.resetFields();
          this.getVirtualSimulation()
        } else {
          // 关闭弹窗
          this.setState({
            selectedRecord: null,
            visible: false
          }, () => {
            if(values.id) {
              message.success('修改成功') 
            } else {
              message.success('添加成功') 
            }
            this.getVirtualSimulation()
          })
        }
      }
    })
  }

  deleteData = (id) => {
    let { data: oldData } = this.state
    let { schoolEduCode } = this.props

    let data = {
      schoolEduCode,
      targetId: oldData.id,
      id
    }
    deleteVirtualSimulation(data).then(res => {
      if (res) {
        message.success("删除成功")
        this.getVirtualSimulation({ pageNum: 1, pageSize: oldData.listVO.pageSize })
      }
    })
  }

  getVirtualSimulation = (params = {}) => {
    this.setState({ loading: true })
    let targetId = this.props.data.id,
      schoolEduCode = this.props.schoolEduCode;
    let { data } = this.state
    getVirtualSimulation({ targetId, schoolEduCode, pageNum: params.pageNum || data?.listVO?.pageNum, pageSize: params.pageSize || data?.listVO?.pageSize }).then(res => {
      this.setState({
        data: {
          ...this.state.data,
          listVO: res?.targetVsContestVoList || {}
        },
        loading: false
      })
    })
  }

  handleOk = () => {

  }
  handleCancel = () => {
    this.setState({
      selectedRecord: null,
      visible: false
    })
  }
  onCommit = () => {
    const { form } = this.wrapperFormRef.props
    form.validateFields((errors, values) => {
      if (errors) {
        return false;
      }
      console.log(values)
      this.saveData(values)
    })
  }
  onCommitLoop = () => {
    const { form } = this.wrapperFormRef.props
    form.validateFields((errors, values) => {
      if (errors) {
        return false;
      }
      console.log(values)
      this.saveData(values, true)
    })
  }
  openAddModal = () => {
    this.setState({
      visible: true
    })
  }
  onEditRecord = (record) => {
    this.setState({
      selectedRecord: record,
      visible: true
    })
  }
  onDeleteRecord = record => {
    this.deleteData(record.id)
  }
  changePage = (page, pageSize) => {
    this.getVirtualSimulation({ pageNum: page, pageSize })
  }

  onShowSizeChange = (page, size) => {
    this.getVirtualSimulation({ pageNum: 1, pageSize: size })
  }

  componentDidMount() {
    this.setState({
      data: this.props.data
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    console.log('UNSAFE_componentWillReceiveProps', nextProps, this.state.data.id)
    if (!this.state.data.id) {
      //当数据没有初始化的时候
      this.setState({
        data: nextProps.data
      })
    }
  }

  render() {

    // console.log(moment().format('YYYY-MM-DD HH:mm:ss') , moment().locale('zh').format('YYYY-MM-DDTHH:mm:ssZ'))

    console.log(moment().diff(moment('2021-9-4'), 'minutes'))

    let { selectedRecord, visible, data, loading } = this.state
    let { canEdit, children, buttons, addText, modalTitle, columns = [] } = this.props
    let pageSize = data?.listVO?.pageSize || 10;
    let pageNum = data?.listVO?.pageNum || 1;

    columns = [
      {
        title: '序号',
        dataIndex: 'id',
        width:80,
        align:'center',
        key: 'rowIndex',
        render: (text, record, index) => <span>{(pageNum - 1) * pageSize + (index + 1)}</span>,

      },
      {
        title: '大赛名称',
        dataIndex: 'contestName',
        render: (text, record) => <span>{text}</span>,
        form: true,
        maxLength: 30
      },
      {
        title: '大赛时间',
        dataIndex: 'contestTime',
        render: (text, record) => <span>{moment(text).utcOffset(+8).format('YYYY-MM-DD HH:mm')}</span>,
        form: true,
        type: 'datetime'
      },
      {
        title: '获奖等级',
        dataIndex: 'contestLevel',
        render: (text, record) => <span>{record?.contestLevelName}</span>,
        form: true,
        data: contestLevelList,
        type: 'checkbox',
        initialValue: null
      },
      {
        title: '获奖教师姓名',
        dataIndex: 'teacherName',
        render: (text, record) => <span>{text}</span>,
        form: true,
        maxLength: 30
      },


    ]

    if (canEdit) {
      columns.push({
        title: '操作',
        dataIndex: 'id',
        width:220,
        align:'center',
        render: (text, record) => <div className='render-span vertical_j oper'>
          <div>
            <span className='cz' onClick={this.onEditRecord.bind(this, record)}>编辑 </span>
            <span className='cz' onClick={this.onDeleteRecord.bind(this, record)}>删除</span>
          </div>
        </div>
      })
    }



    return (<div className="medal-item-container">
      <div className="title_top title_topB title">
        <h4>{data?.targetMark || '0.0.0'} {data?.targetName || '无题'}</h4>
      </div>

      <div className="conte">

        <div className='conteCompPercent'>
          <Row>
            <Col span={8}>
              <span
                className='CCPA CCPC clearFixedWidth'>{data?.factorName || '无'}</span>
              <div
                className='conteInput_right'>{data?.listVO?.total || 0}
              </div>
              {/*<Input className='conteInputA' placeholder="80" />*/}
            </Col>
          </Row>
        </div>
        {canEdit ? <div className="button_bottom">
          <div className="conteBtnDiv clearMarginTop"
            onClick={this.openAddModal}>
            <TeacherDevelopmentUpload /><span
              className='conteBtnDivSpan'>{addText}</span>
          </div>
          <div className="imprAnddowngroup" style={{ backgroundColor: 'transparent', margin: 0 }}>
            <ImportComponent
              targetId={data?.id}
              targetMark={data?.targetMark}
              downloadName={data?.targetName}
              onImportSuccess={() => {
                // todo 刷新此项即可
                // alert("成功")
                this.getVirtualSimulation({ pageNum: 1 })
              }}
            />
          </div>
          {/* <div className='conteBtnDiv' onClick={() => this.props.dowLoadFile(data?.targetMark, data?.targetName)}><Daoru/><span className='conteBtnDivSpan'>下载模版</span></div> */}

        </div> : ""}
        <div className={`conteTable ${canEdit ? '' : 'cannotEdit'}`}>
          <TableCmp data={data?.listVO?.data || []}
            columns={columns} pageSize={data?.listVO?.pageSize}
            pageNum={data?.listVO?.pageNum} total={data?.listVO?.total || data?.listVO?.cmTotal}
            changePage={this.changePage}
            pageSizeChange={this.onShowSizeChange} />


          <div className='addSupportingMaterials' style={{ marginTop: data?.listVO?.data?.length > 0 ? '-66px' : '-5px' }}>
            <UploaderComponent
              editEnable={canEdit}
              uploadTitle="添加佐证材料"
              targetId={data?.id}
            />
          </div>

          {/* <MedalTable data={data?.listVO || {}} columns={columns} /> */}
        </div>
        {children}
        {buttons}
      </div>
      {
        visible && canEdit ? <Modal title={`${selectedRecord ? '编辑' : '添加'}${modalTitle}`}
          visible={visible}
          destroyOnClose={true}
          onCancel={this.handleCancel}
          width={768}
          wrapClassName="wappop_up"
          className="add_supporting add_supporting-jsfz"
          footer={
            <div className='addModalBtn'>
              <Button className='quxiao' onClick={() => this.setState({
                visible: false,
                record: null
              })}>取 消</Button>
              <Button onClick={this.onCommit} type='primary'>提交</Button>
              {selectedRecord ? '' :
                <Button onClick={this.onCommitLoop} type='primary'>提交并继续添加</Button>
              }
            </div>
          }
        >
          <div className='AddTeacherMaterial'>
            <SimpleForm columns={columns.filter(item => item.form == true)} onRef={el => this.wrapperFormRef = el} record={selectedRecord} />
          </div>
        </Modal> : null
      }
    </div >)
  }
}

ItemCmp.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.object,
  buttons: PropTypes.any,
  addText: PropTypes.any,
  onAddClick: PropTypes.func,
  modalTitle: PropTypes.any,
  getApi: PropTypes.any
}

// PropTypes.shape({
//   courseResourceNum: PropTypes.number,
//   factorContent: PropTypes.string,
//   factorId: PropTypes.string,
//   factorName: PropTypes.string,
//   id: PropTypes.string,
//   listVO: PropTypes.object,
//   targetMark: PropTypes.string,
//   targetName: PropTypes.string,
//   year: PropTypes.number,
//   researchTotal: PropTypes.number
// })