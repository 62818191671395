import React, { Component } from "react";
import { Input, Form, Select,Radio } from "antd";

const FormItem = Form.Item;
const { Option } = Select;

class Forms extends React.Component {
  state = {
    file: null,
  };

  componentDidMount() {
    const { editObj } = this.props;

    if (Object.keys(editObj || {}).length !== 0) {

      this.props.form.setFieldsValue({
        className: editObj?.className + '',
        isVs: editObj?.isVs ,
        classType: editObj?.classType ,
        classPeopleNumber: editObj?.classPeopleNumber + '',
        // ...editObj,
      });


      // this.props.form.setFieldsValue({
      //   ...editObj,
      //   situationType: editObj.situationType + ''
      // });
    } else {
      this.props.form.resetFields();
    }
  }

  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };
  validateNoChinese = (_, value, callback) => {
    const reg = /^\d+$|^\d*\.\d+$/g;
    const startValue = value?.split('.')
    const regEmpty = /^\s*$/g;
    if(!value){
      callback('');
    } else if (value && !reg.test(value) || !startValue[0]) {
      callback('只能输入数字');
    } else if(value && regEmpty.test(value)) {
      callback('名称不能为空');
    } else {
      callback();
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 10 },
    };

    return (
      <Form {...layout}>
        <FormItem label="班级名称">
          {getFieldDecorator("className", {
            rules: [
              {
                required: true,
                message: "请填写班级名称",
              },
            ],
          })(<Input placeholder="请输入班级名称"  maxLength={30} suffix={<span className='len'>
          {this.props.form.getFieldValue(`className`) ? this.props.form.getFieldValue(`className`).length : 0}/30</span>}  />)}
        </FormItem>

        <FormItem label="是否虚拟仿真实训服务" style={{display:'flex'}}>
          {getFieldDecorator('isVs', {
            
            rules: [
              {
                required: true,
                message: '请选择是否虚拟仿真实训服务'
              },
            ],
          })(<Radio.Group>
            <Radio value={1}>是</Radio>
            <Radio value={0}>否</Radio>
          </Radio.Group>)}
        </FormItem>

        <FormItem label="班级类型">
          {getFieldDecorator("classType", {
            rules: [
              {
                required: true,
                message: "请选择班级类型",
              },
            ],
          })(
            <Select
              placeholder={'请选择班级类型'}
              dropdownMatchSelectWidth={true}
              onChange={this.props.childrenTypeChange}
              initialValue={this.state.typeValue}
            >
              {
                this.props.typeList.map((item) => (
                  <Option key={item.typeKey} value={item.typeKey}>
                    {item.typeName}
                  </Option>
                ))
              }
            </Select>
          )}
        </FormItem>
        <FormItem label="班级人数">
          {getFieldDecorator("classPeopleNumber", {
            rules: [
              {
                required: true,
                message: "请选择班级人数",
              },
              {
                validator: this.validateNoChinese
              }
            ],
          })(<Input  placeholder="请输入班级人数"/>)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
