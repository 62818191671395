// 建设方案描述-预览
import React from "react";
import { Form,Row, Col,Radio,Table,Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import {UpIcon} from '@/components/iconSvg'
import { withRouter } from "react-router-dom";
import {selectTaskFile} from "../../api/taskBook";
import Cookies from "js-cookie";
import { xdocUrlHttps,xdocUrl, BaseUrl } from "../../config/common";

export default Form.create()(
  withRouter(
    class BuildingProject extends React.Component {
      state = {
        schoolEduCode:JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        openUrl:""
      }
        componentDidMount() {
           window.scrollTo({
               top: 0,
               behavior: 'smooth',
           });
           window.addEventListener('scroll', this.bindHandleScroll)
           this.getFileList()

       }
        componentWillUnmount() {
           
        }

        getFileList=()=>{
            let data ={
                schoolEduCode:this.state.schoolEduCode,
                taskId:this.state.taskId
            }

            selectTaskFile(data).then((res) => {
                if (res) {
                    for (let i = 0; i <res.length; i++) {
                        if (res[i].fileType === 3){
                            let upFile = {
                                taskId:this.state.taskId,
                                schoolEduCode:this.state.schoolEduCode,
                                fileType:res[i].fileType,
                                resourceName:res[i].resourceName,
                                fileId:res[i].resourceName,
                                resourceSuffix:res[i].resourceSuffix,
                                resourceId:res[i].resourceId,
                                resourceSize:res[i].resourceSize
                            }

                            let url=BaseUrl+res[i].filePath+"/"+res[i].fileName
                            this.setState({
                                openUrl:url
                            })
                        }
                    }
                }
            })
        }

        // 左侧跟随屏幕
        bindHandleScroll=(event)=> {
            let ctx=this;
            let clientHeight = document.documentElement.clientHeight; //可视区域高度
            let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
            if (scrollTop > 200) {
                document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:60px;position:fixed;height: ${clientHeight-240}px`);
            } else {
                document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight-240}px`);
            }
        }
      render() {
          const {getFieldDecorator, }=this.props.form
          const {columns,data,list,datalist,middle,middle_data,openUrl} = this.state;
        return (
            <div className="seal_Pile" style={{minHeight:"1200px"}}>
                <Heder/>
                <div className="Task-preview clearfix">
                    <div className="main_cont" id="main_cont">
                        <div className="Task-bottom-preview">
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="preview_left" id="leftmain" style={{"top":'0px'}}>
                                    <LeftMeinlist processCode={'1-7'}/>
                                </Col>
                                <Col span={20} className="preview_right">
                                    <div className='content'>
                                        <div className='top-right'>
                                            <h6 className='mission '>任务书预览页</h6>
                                            <span><i><b>7</b>/7</i></span>
                                        </div>
                                        <div className='bottom'>
                                            <h2>7. 盖章文件</h2>
                                            <div className='list'>
                                                <div className='bottom-b'>
                                                    <span>院校建设方案</span>
                                                </div>
                                                {/* <div className='bottom-c'>
                                                    <span>市级推荐意见</span>
                                                </div>
                                                <div className='bottom-c'>
                                                    <span>汇总申报总表</span>
                                                </div> */}
                                        </div>

                                            {openUrl? <div className='neirong-renwushu' >
                                              <iframe className="preview" title="iframe" src={openUrl.includes('https') ? (xdocUrlHttps + openUrl) : openUrl.includes('http') ? (xdocUrl + openUrl) : (xdocUrl + openUrl)} width="100%" frameBorder='0'/>
                                            </div>:""}
                                           

{/*
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary" >
                                                下一页
                                            </Button>
                                        </div>*/}




                                    </div>
                                        </div>

                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
      }
    }
  )
);
