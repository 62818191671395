// 项目情况-平台情况
import React from "react";
import {Form, Input, Radio} from 'antd';
import "./index.scss";
import moment from "../add_Award";
class AddPlatforminformatiom extends React.Component {
    state = {
    };

    componentDidMount() {
        let {data} = this.props;
        console.log('data', data)
        this.props.form.setFieldsValue({
            resourcesName: data? data.resourcesName : null,
            sharingMode: data? data.sharingMode : null,
            platformAddress: data? data.platformAddress : null
        })
    }
    render() {
        const {getFieldDecorator }=this.props.form
        return (
            <div className="AddPlatforminformatiom" >
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>资源名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('resourcesName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入资源名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入资源名称' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`resourcesName`) ? this.props.form.getFieldValue(`resourcesName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>共享模式</span>
                        {getFieldDecorator('sharingMode', {
                            initialValue: null,
                            rules: [
                                {
                                    required: true,
                                    message: `请选择共享模式`,
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={1}>校-校</Radio>
                                <Radio value={2}>校-省</Radio>
                                <Radio value={3}>省-全国</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>平台地址</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('platformAddress', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入平台地址`,
                                    },
                                    {
                                        max: 50,
                                        message: "文本过长，最多50字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入平台地址' maxLength={30} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`platformAddress`) ? this.props.form.getFieldValue(`platformAddress`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}
AddPlatforminformatiom = Form.create()(AddPlatforminformatiom);
export default AddPlatforminformatiom;