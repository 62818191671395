// 项目情况-平台情况
import React from "react";
import {Form, Row, Col, Radio, Card, Button} from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import StepTop from "../components/StepTop";
import Leftmien from "../components/Left_mien";
import JibenXinxi from '../components/jiben_Xinxi'
import XiangmutdQk from '../components/xiangmutd_Qk'
import {
    QyLogo,
    QyBackground,
    Iconrws,
    Iconrwsxg,
    Iconrwsyl,
    Iconwj,
    Iconsjzb,
    Iconndhb,
    Iconscbg,
    Icontbjsal,
    Iconfrlxra,
    Iconjxbg
} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";

export default Form.create()(
    withRouter(
        // 项目建设-项目情况
        class StatusProject extends React.Component {

            state = {
                processCode: '2.2',

                // leftCatalog: [
                //     {
                //         id: 0,
                //         seniorName: '基本信息',
                //         isSelf: 0
                //     },
                //     {
                //         id: 1,
                //         seniorName: '项目团队情况',
                //         isSelf: 0
                //     }
                // ],
                rightDq: 0,//右侧当前是什么
            };

            componentDidMount() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
            }

            componentWillUnmount() {

            }

            qiehuancd = (e) => {
                this.setState({rightDq: e})
            }
            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {rightDq, processCode} = this.state;
                return (
                    <div className="StatusProject_main">
                        <Heder/>
                        <div className="Task-top">
                            <div className="main_cont">
                                <Card>
                                    <Row>
                                        <Col span={24} className="col-li">
                                            <StepTop parentCode={"2"}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>
                        <div className="Task-top" style={{marginTop:"16px"}}>
                            <Col className="main_cont">
                                {/*<JibenXinxi qiehuancd={this.qiehuancd}/>*/}
                                <XiangmutdQk qiehuancd={this.qiehuancd} processCode={processCode}/>
                            </Col>
                        </div>
                        {/*<div className="Task-bottom clearfix">*/}
                        {/*    <div className="main_cont" style={{width: "100%"}}>*/}
                        {/*        <div className="Task-bottom-main" id="Task-bottom-main">*/}
                        {/*            <Row type="flex" justify="space-between">*/}

                        {/*                <Col span={20} className="Task_right">*/}
                        {/*                    <JibenXinxi qiehuancd={this.qiehuancd}/>*/}
                        {/*                    <XiangmutdQk qiehuancd={this.qiehuancd}/>*/}
                        {/*                </Col>*/}
                        {/*            </Row>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
