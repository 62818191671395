/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Button, Col, Form, Input, InputNumber, message, Modal, Radio, Row, Table} from 'antd';
import './index.scss';
import {addInvest, deleteInvest, updateInvest} from "../../../../api/invest";
import Cookies from "js-cookie";
import moment from "moment";
import RenderButton from "../../../../components/renderButton";
import Heder from "../../../../components/header";
import Footer from "../../../../components/footer";
import {
    addAndUpdateExpenditureRequest,
    addExpenditure,
    deleteExpenditure,
    getExpenditureList
} from "../../../../api/expenditure";
import {isNotEmpty} from "../../../../until/isEmpty";
import {TianJia} from "../../../../components/iconSvg";

const {TextArea} = Input;
const baseYear = 2020
const baseYearHeader = 'year'
const baseYearFooter = 'Money'

class ExpenditurePay extends Component {
    state = {
        formData: this.props.formData,

        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",

        totalMoney: 0,

        expenditureList: [],
        isShowAddMode: false,

        currentEditExpenditure: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    componentDidMount() {
        this.getExpenditureListMethods();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    // section 获取经费支出列表
    getExpenditureListMethods() {
        let data = {
            taskId: this.state.taskId,
            schoolEduCode: this.state.schoolEduCode,
        }
        getExpenditureList(data).then((res) => {

            let result = res ? res : []
            let allMoney = 0
            // 计算小计
            result.forEach((item) => {
                let sub =
                    item[baseYearHeader + 1 + baseYearFooter] +
                    item[baseYearHeader + 2 + baseYearFooter] +
                    item[baseYearHeader + 3 + baseYearFooter]
                item["subtotal"] = sub

                allMoney += sub
            })
            console.log("经费支出列表", result)

            const expenditureList = result ? result : []
            this.setState({
                totalMoney: allMoney,
                expenditureList
            }, () => {
                this.props.getExpenditureList(expenditureList)
            });
        });
    }

    // section 获取新的支出模型
    getNewExperience = () => {
        return {
            'name': "",
            'year1': "",
            "year2": "",
            "year3": ""
        }
    }

    // section 显示添/修弹框
    showModeInfo = (item) => {
        let isEdit = undefined !== item
        let expenditure = item
        if (!isEdit) {
            expenditure = this.getNewExperience()
        }
        console.log("isEdit", isEdit)
        console.log("当前", expenditure)

        if (isEdit) {
            this.props.form.setFieldsValue({
                id: item.id,
                projectName: expenditure.projectName,
                payExplain: expenditure.payExplain,
                year1: expenditure.year1Money,
                year2: expenditure.year2Money,
                year3: expenditure.year3Money,
            })
        } else {
            this.props.form.setFieldsValue({
                id: "",
                projectName: expenditure.projectName,
                payExplain: expenditure.payExplain,
                year1: expenditure.year1Money,
                year2: expenditure.year2Money,
                year3: expenditure.year3Money,
            })
        }


        this.setState({
            isShowAddMode: true,
            currentEditExpenditure: expenditure
        })
    }

    // section 增/改 请求
    addAndUpdateExpenditureRequestMethod = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                let data = {
                    "projectName": value.projectName,
                    "schoolEduCode": this.state.schoolEduCode,
                    "taskId": this.state.taskId,
                    'payExplain': value.payExplain,
                    "year1": baseYear + 1,
                    "year1Money": value[baseYearHeader + 1 + baseYearFooter],
                    "year2": baseYear + 2,
                    "year2Money": value[baseYearHeader + 2 + baseYearFooter],
                    "year3": baseYear + 3,
                    "year3Money": value[baseYearHeader + 3 + baseYearFooter]
                }

                let isEdit = isNotEmpty(value["id"])
                if (isEdit) {
                    data['id'] = value.id
                }

                addAndUpdateExpenditureRequest(data).then(res => {
                    message.success((isEdit ? "修改" : "新增") + "成功");

                    if (res) {
                        this.setState({
                            isShowAddMode: false,
                        }, () => {
                            this.getExpenditureListMethods()
                        })
                    }
                }, () => {
                    message.error((isEdit ? "修改" : "新增") + "失败, 请重试!");
                    this.setState({
                        isShowAddMode: false,
                    }, () => {
                        this.getExpenditureListMethods()
                    })
                })

            }
        })
    }

    validateInvestMoney = (rule, value, callback) => {
        /* let regex = /^[1-9]\d*|0$/;*/
        let regex = /^(([0-9]+)|([0-9]+\.[0-9]{0,2}))$/
        if (value) {
            if (typeof value != 'number') {
                callback("请填写格式正确的数字")
            } else if (parseInt(value) > 999999999) {
                callback('金额最高为999999999万元')
            } else if (parseInt(value) < 0) {
                callback('不能为负数')
            } else if (value && !regex.test(value)) {
                callback('只能输入两位小数')
            }
        }
        callback();
    };

    // section 删除
    deleteExpenditure = (item) => {
        console.log("删除")

        let data = {
            "id": item.id
        }

        deleteExpenditure(data).then(res => {
            message.success("删除成功")

            if (res) {
                this.getExpenditureListMethods()
            }
        }, () => {
            message.error("删除失败, 请重试!")
        })
    }


    // section render
    render() {
        const {getFieldDecorator} = this.props.form;
        const {isPreview} = this.props;
        const {
            expenditureList,
            totalMoney,
            pageSize,
            isShowAddMode,
            currentEditExpenditure
        } = this.state;

        const formItemLayout = {
            // labelCol: {
            //     xs: {span: 18},
            //     sm: {span: 8},
            // },
            // wrapperCol: {
            //     xs: {span: 18},
            //     sm: {span: 16},
            // },
        };

        // section 表格的规则
        const columns = [
            {
                title: '支出项目',
                dataIndex: 'projectName',
                key: 'projectName',
                align: 'center',
                width: 200,
                fixed: 'left',
            },

            {
                title: '支出说明',
                dataIndex: 'payExplain',
                key: 'payExplain',
                align: 'center',
                width: 200,
            },

            {
                title: baseYear + 1,
                dataIndex: baseYearHeader + 1 + baseYearFooter,
                key: baseYearHeader + 1 + baseYearFooter,
                align: 'center',
            },
            {
                title: baseYear + 2,
                dataIndex: baseYearHeader + 2 + baseYearFooter,
                key: baseYearHeader + 2 + baseYearFooter,
                align: 'center',
            },
            {
                title: baseYear + 3,
                dataIndex: baseYearHeader + 3 + baseYearFooter,
                key: baseYearHeader + 3 + baseYearFooter,
                align: 'center',
            },

            {
                title: '小计',
                dataIndex: "subtotal",
                align: 'center',
                key: 'subtotal',
            },
            {
                title: '操作',
                key: 'operation',
                fixed: 'right',
                width: 300,
                render: (text, record) => (
                    <div className='operating'>
                        <div className={'cycaozuo'}>
                            <span onClick={() => this.showModeInfo(record)}>编辑</span>
                            <span onClick={() => this.deleteExpenditure(record)}>删除</span>
                        </div>
                    </div>


                )
            },
        ]

        const columnsPreview = [
            {
                title: '支出项目',
                dataIndex: 'projectName',
                key: 'projectName',
                align: 'center',
                width: 200,
                fixed: 'left',
            },
            {
                title: '支出说明',
                dataIndex: 'payExplain',
                key: 'payExplain',
                align: 'center',
                width: 200,
            },

            {
                title: baseYear + 1,
                dataIndex: baseYearHeader + 1 + baseYearFooter,
                key: baseYearHeader + 1 + baseYearFooter,
                align: 'center',
            },
            {
                title: baseYear + 2,
                dataIndex: baseYearHeader + 2 + baseYearFooter,
                key: baseYearHeader + 2 + baseYearFooter,
                align: 'center',
            },
            {
                title: baseYear + 3,
                dataIndex: baseYearHeader + 3 + baseYearFooter,
                key: baseYearHeader + 3 + baseYearFooter,
                align: 'center',
            },

            {
                title: '小计',
                dataIndex: "subtotal",
                align: 'center',
                key: 'subtotal',
            },
        ]

        return (
            <div className="expenditurePay_content">
                <div className="expenditurePay_table ">

                    <div className="expenditurePay-list">
                        <div className="expenditurePay-bottom-table">

                            <div className="add-button" hidden={isPreview === true}>
                                <Button type={'primary'} className="ant-btn-primary"
                                        onClick={this.showModeInfo.bind(this, undefined)}><TianJia/>新增支出</Button>
                                {/*<Button className="but-li"*/}
                                {/*        onClick={this.showModeInfo.bind(this, undefined)} type="primary" htmlType="submit">*/}
                                {/*    新增支出*/}
                                {/*</Button>*/}
                            </div>

                            <Table columns={(isPreview === true) ? columnsPreview : columns}
                                   dataSource={expenditureList}
                                   bordered
                                   hasPage={false}
                                // scroll={{x: 1600}}
                                   locale={{
                                       emptyText: <>
                                           <div className="Empty-content">
                                               <div className="text">
                                                   <img
                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                   <span>暂无数据</span>
                                               </div>
                                           </div>
                                       </>
                                   }}
                                   pagination={
                                       {
                                           hideOnSinglePage: true
                                       }
                                   }
                            />

                        </div>
                        <Row>
                            <Col>
                                <ul className="total-box">
                                    <li><span className="circle"/></li>
                                    <li>小计</li>
                                    <li>|</li>
                                    {/*<li>金额（万元）：<span>{priceTotal.toFixed(2)}</span></li>*/}
                                    <li>金额（万元）：<span>{totalMoney}</span></li>

                                </ul>

                            </Col>
                        </Row>
                    </div>

                </div>

                <Modal
                    destroyOnClose
                    title={(isNotEmpty(currentEditExpenditure.id) ? "修改" : "新增") + "经费支出"}
                    visible={isShowAddMode}
                    onOk={this.addAndUpdateExpenditureRequestMethod}
                    onCancel={() => {
                        this.setState({isShowAddMode: false})
                    }}
                    wrapClassName="add-expenditurePay"
                    centere={true}
                >
                    <Form className="model-price-form-box">
                        <Form.Item>
                            {
                                getFieldDecorator("id", {
                                    initialValue: currentEditExpenditure.id,
                                })
                            }
                        </Form.Item>

                        <Form.Item label={'支出项目'} key={'projectName'}>

                            {
                                getFieldDecorator("projectName", {
                                    initialValue: currentEditExpenditure.projectName,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入内容",
                                        }
                                    ],
                                })
                                (
                                    < TextArea
                                        className="modal-textarea-style"
                                        maxLength={200}
                                        onChange={() => {
                                            // this.handeInputBlue().then(this.handleFormChange)
                                        }}/>
                                )
                            }
                        </Form.Item>
                        <Form.Item label={'支出说明'} key={'payExplain'}>

                            {
                                getFieldDecorator("payExplain", {
                                    initialValue: currentEditExpenditure.payExplain,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入内容",
                                        }
                                    ],
                                })
                                (
                                    < TextArea
                                        className="modal-textarea-style"
                                        maxLength={200}
                                        onChange={() => {
                                            // this.handeInputBlue().then(this.handleFormChange)
                                        }}/>
                                )
                            }
                        </Form.Item>
                        <div> 支出金额 (万元) :</div>

                        <Form.Item label={baseYear + 1} key={baseYear + 1}>
                            {
                                getFieldDecorator('year1Money', {
                                    initialValue: currentEditExpenditure.year1Money,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入内容",
                                        },
                                        {
                                            validator: this.validateInvestMoney
                                        }
                                    ],
                                })
                                (
                                    <InputNumber
                                        className="modal-input-number-style"
                                        min={0}
                                        onChange={() => {
                                            // this.handeInputBlue().then(this.handleFormChange)
                                        }}/>
                                )
                            }
                        </Form.Item>

                        <Form.Item label={baseYear + 2} key={baseYear + 2}>
                            {
                                getFieldDecorator('year2Money', {
                                    initialValue: currentEditExpenditure.year2Money,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入内容",
                                        },
                                        {
                                            validator: this.validateInvestMoney
                                        }
                                    ],
                                })
                                (
                                    <InputNumber
                                        className="modal-input-number-style"
                                        min={0}
                                        onChange={() => {
                                            // this.handeInputBlue().then(this.handleFormChange)
                                        }}/>
                                )
                            }
                        </Form.Item>

                        <Form.Item label={baseYear + 3} key={baseYear + 3}>
                            {
                                getFieldDecorator('year3Money', {
                                    initialValue: currentEditExpenditure.year3Money,
                                    rules: [
                                        {
                                            required: true,
                                            message: "请输入内容",
                                        },
                                        {
                                            validator: this.validateInvestMoney
                                        }
                                    ],
                                })
                                (
                                    <InputNumber
                                        className="modal-input-number-style"
                                        min={0}
                                        onChange={() => {
                                            // this.handeInputBlue().then(this.handleFormChange)
                                        }}/>
                                )
                            }
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

ExpenditurePay.defaultProps = {
    //获取列表的回调,让外部可以获取来判断是否为空
    getExpenditureList: (data) => {

    }
}
const ExpenditurePayComponent = Form.create()(ExpenditurePay);
export default ExpenditurePayComponent;

