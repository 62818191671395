// 项目情况-平台情况
import React from "react";
import {Form, Input, message, Radio, Select, Button, Cascader, Tooltip, Spin} from 'antd';
import "./index.scss";
import { uploadFile } from '../../../../api/uploadFile';
import moment from "moment";
import {Upicon, Upicondele, Upiconwc} from "../../../../components/iconSvg";
const { TextArea } = Input;
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
class AddShixunkc extends React.Component {
    state = {
        tableData: [],
        resourceCourseDetail:this.props.resourceCourseDetail,
        majorList:this.props.majorList,
        provinceValue: '',
        provinceIndex: 0,
        cityValue: '',
        cityIndex: 0,
        countyValue: '',
        countyIndex: 0,
        courseMajor:[],
    };

    componentDidMount() {
        console.log("file",this.props.resourceCourseDetail)
        let upFile = {
            originName:this.props.resourceCourseDetail.resourceName,
            fileId:this.props.resourceCourseDetail.fileId,
            fileSuffix:this.props.resourceCourseDetail.resourceSuffix,
            fileSize:(this.props.resourceCourseDetail.resourceSize / 1024).toFixed(2)
        }
        let courseMajor = []
        if(JSON.stringify(this.props.resourceCourseDetail)!=="{}"){
            courseMajor.push(this.props.resourceCourseDetail.majorOne)
            courseMajor.push(this.props.resourceCourseDetail.majorTwo)
            courseMajor.push(this.props.resourceCourseDetail.courseMajor)
        }
        this.setState({
            courseMajor:courseMajor,
            file: this.props.resourceCourseDetail.fileId ? upFile : null,
        },()=>{
            this.props.form.setFieldsValue({
                courseName: this.props.resourceCourseDetail.courseName,
                courseMajor: courseMajor,
                courseType: this.props.resourceCourseDetail.courseType,
                coursePlatform: this.props.resourceCourseDetail.coursePlatform,
                courseAddress: this.props.resourceCourseDetail.courseAddress,
                courseExplain: this.props.resourceCourseDetail.courseExplain,
                fileId:this.props.resourceCourseDetail.fileId
            })
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
   //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }


    render() {
        const {getFieldDecorator }=this.props.form
        const {majorList,majorListTwo, majorListThree,file}=this.state
        return (
            <div className="AddShixunkc" >
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>课程类型</span>
                        {getFieldDecorator('courseType', {
                            initialValue: 1,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择课程类型!'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>纯虚拟</Radio>
                            <Radio value={2}>虚实结合</Radio>
                            <Radio value={3}>纯现实</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>课程名称</span>
                        <div className={'course_name-wrap'}>
                            {getFieldDecorator('courseName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写课程名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入内容' maxLength={30} className="input_in" suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`courseName`) ? this.props.form.getFieldValue(`courseName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item className="li_casc_area">
                        <span className={'label'}><span className='red'>*</span>所属专业</span>
                        {getFieldDecorator('courseMajor', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择所属专业`,
                                },
                            ],
                        })( <Cascader placeholder={"请选择专业"}
                                options={majorList}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>展示平台</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('coursePlatform', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写展示平台`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入内容' maxLength={30} className="input_in" suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`coursePlatform`) ? this.props.form.getFieldValue(`coursePlatform`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>访问地址</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('courseAddress', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写访问地址`,
                                    },
                                    {
                                        max: 200,
                                        message: "文本过长，最多200字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入内容' maxLength={200} className="input_in" suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`courseAddress`) ? this.props.form.getFieldValue(`courseAddress`).length : 0}/200</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>说明</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('courseExplain', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写说明`,
                                    },
                                    {
                                        max: 200,
                                        message: "文本过长，最多200字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入内容' maxLength={200} className="input_in" suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`courseExplain`) ? this.props.form.getFieldValue(`courseExplain`).length : 0}/200</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    {/* <Form.Item className="li_formup">
                        <span className={'label'}><span className='red'>*</span>佐证材料</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onClick={(event)=> { event.target.value = null }}
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}
                                </ul>
                            </div>
                        )}

                    </Form.Item> */}
                </Form>
            </div>
        );
    }
}
AddShixunkc = Form.create()(AddShixunkc);
export default AddShixunkc;