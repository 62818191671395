/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-09-02 11:32:34
 * @LastEditors: nliu
 * @LastEditTime: 2021-09-04 19:36:36
 */
import 'core-js/es'
import './config/polyfill'
// ie 11
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import "antd/dist/antd.css";
import "./reset_style/index.less";
import "@/components/global_components/confirm/index.css";
import { apiShixun,apiFujian } from "./config/apiPrefix";
import "@/components/global_components/confirm/index.js";
import store from './store'
import { createHashHistory } from 'history';
import { Provider } from 'react-redux'
import { getImageOssBaseurl } from "./api/Baseurl";

window.apiShixun = apiShixun(); //实训api前缀
window.apiFujian = apiFujian(); //附件api前缀

function create(src) {
    return function (path) {
        if(!path)return;
        if (path[0] === '/') {
            return src + path.slice(1);
        } else {
            return src + path;
        }
    }
}
const history = createHashHistory();
ReactDOM.render(
  <Provider store={store}>
      <App />
  </Provider>
  ,
  document.getElementById('root')
);
getImageOssBaseurl({ type: '100007' }).then(res => {
    if (undefined !== res) {
        const {
            'static-img':staticImg,
            'front-img': frontImg,
            'office-public-resource': officePublicResource,
            'public-resource': publicResource,
        } = res.config
        window.$$setImgSrc = create(staticImg);
        window.$$ImageOssBaseurl = frontImg;
        window.$$img = create(frontImg);
        window.$$uploadRc = create(publicResource);
        window.$$serverImageUrl = create(publicResource);
        window.$$uploadDoc = create(officePublicResource);
        window.$$imgSrc = create(publicResource);
        window.$$imgSrcLcl = publicResource;
        window.$$createUploadFilePath = create(publicResource);
    }
    ReactDOM.render(
        <Provider store={store}>
            <App/>
        </Provider>
        ,
        document.getElementById('root')
    );
})

serviceWorker.unregister();

