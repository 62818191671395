// 课程共享
import React from "react";
import {
    Form,
    Row,
    Col,
    Card,
    Button,
    Input,
    Table,
    Modal,
    Radio,
    Select,
    message,
    Tooltip,
    Spin,
    InputNumber,
    Drawer
} from 'antd';
import Heder from "../../components/header";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import {
    TianJia,
    Addschool,
    Addenterprise,
    Daoru,
    ExcelImport,
    Upicon,
    Upicondele,
    Upiconwc, TeacherDevelopmentUpload
} from '../../components/iconSvg'
import AddSchool from "./components/add_School";//添加学校
import AddEnterprise from './components/add_Enterprise'//添加企业
import moment from "moment";
import {
    courseSharingList,
    addSharingOrgan,
    updateSharingOrgan,
    getSharingOrganCourse,
    getSharingOrganEnterprise,
    deleteSharingOrgan,
    importSharingOrgan,
    addSharingResources,
    updateSharingResources,
    getSharingResources,
    deleteSharingResources,
    importSharingResources,
    addNum,
    updateNum,
    getNum
} from "../../api/courseSharing"
import Cookies from "js-cookie";
import {uploadFile} from "../../api/uploadFile";
import Footer from "../../components/footer";
import {importTrainPlan} from "../../api/cultivationTalents";
import axios from "axios";
import {downTemplate} from "../../api/sharing";
import md5 from "js-md5";
import "./index.scss";
import {updateFactor} from "../../api/factor";
import AddWashable from "./components/add_Washable/index";
import Addevidencelook from "./components/add_Washable/look_evidence";
import {addStudentNum, getStudentNum, updateStudentNum} from "../../api/constructionResults";
import {updateMajorNum, validData} from "../../api/target";
import LookCheck from "../components/look_Check";
import {getCanEdit, getRoleType, getTargetId, RoleType} from "../../until/cookie";
import FileLook from "../components/file_look";
import {BaseUrl} from "../../config/common";
import ImportComponent from "../../components/importComponent/ImportComponent";
import { isEmpty } from "../../until/isEmpty";

const {TextArea} = Input;

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

class PlatformCoursesharing extends React.Component {
    // section State
    state = {
        addEvidence: false,  //添加佐证材料
        LookEvidence: false,  //查看资料
        pageNum: 1,//页数
        pageSize: 10,//条数
        total: 0,
        pageNumone: 1,//页数
        pageSizeone: 10,//条数
        totalone: 0,
        pageNumtwo: 1,//页数
        pageSizetwo: 10,//条数
        totaltwo: 0,
        dataSource1: [],
        dataSource2: [],
        dataSource3: [],
        showfzclModel1: false,//新增佐证材料1,2,3,4
        showAddResource: false, //添加资源弹窗
        showSchoolModal: false,//添加学校
        addEnterprise: false,//添加企业
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        // twoLevelTargetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
        twoLevelTargetId: getTargetId("Platform_coursesharing"),
        columnsAData: [],
        columnsBData: [],
        columnsCData: [],
        columnsDData: [],
        sharingResourcesId: '',
        sharingOrganCourseId: '',
        sharingOrganEnterpriseId: '',
        sharingResources: [],
        sharingOrganCourse: [],
        sharingOrganEnterprise: null,
        importType: ''
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        this.courseSharingList()
    }

    courseSharingList = () => {
        let data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.twoLevelTargetId
        }
        courseSharingList(data).then(res => {
            if (res) {
                this.setState({
                    columnsAData: res.targetSharingResourcesVo,
                    columnsBData: res.schoolCourseSharingVo,
                    columnsCData: res.enterpriseCourseSharingVo,
                    courseFactorContent: res.enterpriseCourseSharingVo ? res.enterpriseCourseSharingVo.cooperationNumber : 0,
                    columnsDData: res.majorStudentNumVo,
                    factorContent: res.majorStudentNumVo ? res.majorStudentNumVo.factorContent : 0,
                    dataSource1: res.targetSharingResourcesVo.sharingResourcesVoList.data,
                    dataSource2: res.schoolCourseSharingVo.schoolOrganList.data,
                    dataSource3: res.enterpriseCourseSharingVo.enterpriseOrganList.data,
                    showList: res.majorStudentNumVo.resourceVoList.data,
                    columnsATotal: res.targetSharingResourcesVo.sharingResourcesVoList.total,
                    columnsBTotal: res.schoolCourseSharingVo.schoolOrganList.total,
                    columnsCTotal: res.enterpriseCourseSharingVo.enterpriseOrganList.total,
                    columnsDTotal: res.majorStudentNumVo.resourceVoList.total,
                })
            }

        })
    }

    // 查看资料
    handLook = (record) => {
        const url = BaseUrl + record.fileId
        this.props.history.push({pathname:"/file_Book", query :{ openUrl: url}} )
    }
    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }
    onCancel = () => {
        this.setState({
            examine: false
        })
    }

    handExamine = () => {
        this.setState({
            examine: true
        })
    }

    updateSharingResources = (record) => {
        let upFile = {
            originName: record.resourceName,
            fileId: record.fileId,
            fileSuffix: record.resourceSuffix,
            fileSize: (record.fileSize / 1024).toFixed(2)
        }
        this.setState({
            sharingResourcesId: record.id,
            showAddResource: true,
            file: upFile,
        }, () => {
            this.props.form.setFieldsValue({
                resourcesName: record.resourcesName,
                sharingPlatform: record.sharingPlatform,
                websiteLink: record.websiteLink,
                sharingNumber: record.sharingNumber,
                fileId: record.fileId,
            })
        })
    }

    updateSharingOrganCourse = (record) => {
        this.setState({
            sharingOrganCourseId: record.id,
            showSchoolModal: true,
            sharingOrganCourse: record,
        })
    }

    updateSharingOrganEnterprise = (record) => {
        this.setState({
            sharingOrganEnterpriseId: record.id,
            addEnterprise: true,
            sharingOrganEnterprise: record,
        })
    }
    /***
     * 虚拟仿真实训课程开放共享率  分页

     */
    getSharingResources = () => {
        const {pageNum ,pageSize}=this.state
        let data = {
            pageNum:pageNum,
            pageSize:pageSize,
            id: "",
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsAData.id
        }
        getSharingResources(data).then(res => {
            if (res) {
                this.setState({
                    dataSource1: res.data,
                    columnsATotal: res.total
                })
            }
        })
    }

    /**
     * 获取共享机构-课程
     */
    getSharingOrganCourse = () => {
        const {pageNumone ,pageSizeone}=this.state
        let data = {
            pageNum:pageNumone,
            pageSize:pageSizeone,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsBData.id
        }
        getSharingOrganCourse(data).then(res => {
            console.log('columns2', res)
            if (res) {
                this.setState({
                    dataSource2: res.data,
                    columnsBTotal: res.total
                })
            }
        })
    }

    /**
     * 获取共享机构-企业
     */
    getSharingOrganEnterprise = () => {
        const {pageSizetwo ,pageNumtwo}=this.state
        let data = {
            pageNum:pageNumtwo,
            pageSize:pageSizetwo,
            id: "",
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsCData.id
        }
        getSharingOrganEnterprise(data).then(res => {
            if (res) {
                this.setState({
                    dataSource3: res.data,
                    columnsCTotal: res.total
                })
            }
        })
    }

    deleteSharingResources = (record) => {
        let data = {
            id: record.id,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsAData.id
        }
        deleteSharingResources(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.pageChange(1)
            }
        })
    }

    deleteSharingOrganCourse = (record) => {
        let data = {
            id: record.id,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsBData.id
        }
        deleteSharingOrgan(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.pageChangeone(1)
            }
        })
    }

    deleteSharingOrganEnterprise = (record) => {
        let data = {
            id: record.id,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsCData.id
        }
        deleteSharingOrgan(data).then(res => {
            if (res) {
                message.success("删除成功")
                this.pageChangetwo(1)
            }
        })
    }

    columnsATiJiao = () => {
        let {file} = this.state
        if (this.state.sharingResourcesId) {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.sharingResourcesId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsAData.id,
                        resourcesName: value.resourcesName,
                        sharingPlatform: value.sharingPlatform,
                        websiteLink: value.websiteLink,
                        sharingNumber: value.sharingNumber,
                    }
                    updateSharingResources(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                showAddResource: false
                            }, () => {
                                this.props.form.resetFields()
                                this.deleteFile()
                                this.getSharingResources()
                            })
                        }

                    })
                }
            })
        } else {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsAData.id,
                        resourcesName: value.resourcesName,
                        sharingPlatform: value.sharingPlatform,
                        websiteLink: value.websiteLink,
                        sharingNumber: value.sharingNumber,
                    }
                    addSharingResources(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                showAddResource: false
                            }, () => {
                                this.props.form.resetFields()
                                this.deleteFile()
                                this.pageChange(1)
                            })
                        }

                    })
                }
            })
        }
    }
    columnsATiJiaoAndAdd = () => {
        let {file} = this.state
        if (this.state.sharingResourcesId) {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.sharingResourcesId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsAData.id,
                        resourcesName: value.resourcesName,
                        sharingPlatform: value.sharingPlatform,
                        websiteLink: value.websiteLink,
                        sharingNumber: value.sharingNumber,
                    }
                    updateSharingResources(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                // showAddResource: false
                            }, () => {
                                this.props.form.resetFields()
                                this.deleteFile()
                                this.getSharingResources()
                            })
                        }

                    })
                }
            })
        } else {
            this.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsAData.id,
                        resourcesName: value.resourcesName,
                        sharingPlatform: value.sharingPlatform,
                        websiteLink: value.websiteLink,
                        sharingNumber: value.sharingNumber,
                    }
                    addSharingResources(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                // showAddResource: false
                            }, () => {
                                this.props.form.resetFields()
                                this.deleteFile()
                                this.pageChange(1)
                            })
                        }

                    })
                }
            })
        }
    }

    columnsBTiJiao = () => {
        let file = this.AddSchool.getState()
        console.log('file ==== >>>> ', file)
        if (this.state.sharingOrganCourseId) {
            this.AddSchool.props.form.validateFields((err, value) => {
                console.log('修改的value 001 ======>>>>>> ', value)
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.sharingOrganCourseId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsBData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 1,
                    }
                    updateSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                showSchoolModal: false
                            }, () => {
                                this.AddSchool.props.form.resetFields()
                                this.AddSchool.deleteFile()
                                this.getSharingOrganCourse()
                            })
                        }

                    })
                }
            })
        } else {
            this.AddSchool.props.form.validateFields((err, value) => {
                console.log('新增的 value 001 ======>>>>>> ', value)
                if (!err) {
                    console.log('9090')
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsBData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 1,
                    }
                    console.log('data新增入参：', data)
                    addSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                showSchoolModal: false
                            }, () => {
                                this.AddSchool.props.form.resetFields()
                                this.AddSchool.deleteFile()
                                this.pageChangeone(1)
                            })
                        }

                    })
                }
            })
        }
    }
    columnsBTiJiaoAndAdd = () => {
        console.log('22')
        let file = this.AddSchool.getState()
        console.log('file ==== >>>> ', file)
        if (this.state.sharingOrganCourseId) {
            this.AddSchool.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.sharingOrganCourseId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsBData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 1,
                    }
                    updateSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("修改成功")
                            this.setState({
                                // showSchoolModal: false
                                sharingOrganCourse: null
                            }, () => {
                                this.AddSchool.props.form.resetFields()
                                this.AddSchool.deleteFile()
                                this.getSharingOrganCourse()
                            })
                        }

                    })
                }
            })
        } else {
            this.AddSchool.props.form.validateFields((err, value) => {
                console.log('value ======>>>>>> ', value)
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    console.log('9090')
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsBData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 1,
                    }
                    console.log('data新增入参：', data)
                    addSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                // showSchoolModal: false
                                sharingOrganCourse: ''
                            }, () => {
                                this.AddSchool.props.form.resetFields()
                                this.AddSchool.deleteFile()

                                this.pageChangeone(1)

                            })
                        }

                    })
                }
            })
        }
    }

    columnsCTiJiao = () => {
        let file = this.AddEnterprise.getState()
        if (this.state.sharingOrganEnterpriseId) {
            this.AddEnterprise.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.sharingOrganEnterpriseId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsCData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 2,
                    }
                    updateSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                addEnterprise: false
                            }, () => {
                                this.getSharingOrganEnterprise()
                            })
                        }

                    })
                }
            })
        } else {
            this.AddEnterprise.props.form.validateFields((err, value) => {
                if (!err) {

                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsCData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 2,
                    }
                    addSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                addEnterprise: false
                            }, () => {
                               this.pageChangetwo(1)
                            })
                        }

                    })
                }
            })
        }
    }
    columnsCTiJiaoAndAdd = () => {
        let file = this.AddEnterprise.getState()
        if (this.state.sharingOrganEnterpriseId) {
            this.AddEnterprise.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        id: this.state.sharingOrganEnterpriseId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsCData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 2,
                    }
                    updateSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                // addEnterprise: false
                                sharingOrganEnterprise: ''
                            }, () => {
                                this.AddEnterprise.props.form.resetFields()
                                this.AddEnterprise.deleteFile()
                                this.getSharingOrganEnterprise()
                            })
                        }

                    })
                }
            })
        } else {
            this.AddEnterprise.props.form.validateFields((err, value) => {
                if (!err) {
                    if(!file||isEmpty(file.fileId)){
                        message.info("文件上传中")
                        return
                    }
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.columnsCData.id,
                        organName: value.organName,
                        organProvince: value.organProvince.key,
                        organRegion: value.organRegion.key,
                        provinceName: value.organProvince.label,
                        regionName: value.organRegion.label,
                        organCourse: value.organCourse,
                        usageCount: value.usageCount,
                        organType: 2,
                    }
                    addSharingOrgan(data).then(res => {
                        if (res) {
                            message.success("新增成功")
                            this.setState({
                                // addEnterprise: false
                                sharingOrganEnterprise: ''
                            }, () => {
                                this.AddEnterprise.props.form.resetFields()
                                this.AddEnterprise.deleteFile()
                                this.pageChangetwo(1)
                            })
                        }

                    })
                }
            })
        }
    }


    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //添加资源
    addresourceTijiao = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {} = value
                this.setState({showXzzyModel: false})
            }
        })
    }
    //添加资源提交继续添加
    addresourceTijiaoAdd = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {} = value
                this.props.form.resetFields();
            }
        })
    }
    // 上一页
    previous = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_service") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_service',
                    })
                    break;
                }
            }
        }
    }
    // 下一页
    next = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_Domesticmarketing") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_Domesticmarketing',
                    })
                    break;
                }
            }
        }
    }

    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.getSharingResources()
        });

    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageSize, pageNum}, () => {
            this.getSharingResources()
        });

    }
    //第二个表格分页切换
    pageChangeone = (pageNum) => {
        this.setState({pageNumone: pageNum}, () => {
            this.getSharingOrganCourse()
        });

    }
    //第二个表格分页条数切换
    onShowSizeChangeone = (pageNum, pageSize) => {
        this.setState({pageSizeone: pageSize, pageNumone: pageNum}, () => {
            this.getSharingOrganCourse()
        });


    }
    //第三个表格分页切换
    pageChangetwo = (pageNum) => {
        this.setState({pageNumtwo: pageNum}, () => {
            this.getSharingOrganEnterprise()
        });

    }
    //第三个表格分页条数切换
    onShowSizeChangetwo = (pageNum, pageSize) => {
        this.setState({pageSizetwo: pageSize, pageNumtwo: pageNum}, () => {
            this.getSharingOrganEnterprise()
        });

    }
    //删除前二次确认
    deleteAsk = () => {
        Modal.confirm({
            className: 'content-warp',
            title: '温馨提示',
            centered: true,
            content: "确定要移除吗?",
            onOk: async () => {
                message.success("删除成功")
            }
        })
        // this.setState({deletePlanModal:true})
    }

    // 导入文件
    importSharingResources = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('targetId', this.state.columnsAData.id)
        fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
        importSharingResources(fromData).then(res => {
            message.success('导入成功')
            this.getSharingResources()
        })
    }

    courseImportType = () => {
        this.setState({
            importType: 1
        })
    }

    qiyeImportType = () => {
        this.setState({
            importType: 1
        })
    }

    // 导入文件
    importSharingOrganCourse = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('targetId', this.state.columnsBData.id)
        fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
        fromData.append('organType', 1)
        importSharingOrgan(fromData).then(res => {
            message.success('导入成功')
            this.getSharingOrganCourse()
        })
    }
    // 导入文件
    importSharingOrganEnterprise = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('targetId', this.state.columnsCData.id)
        fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
        fromData.append('organType', 2)
        importSharingOrgan(fromData).then(res => {
            message.success('导入成功')
            this.getSharingOrganEnterprise()
        })
    }

    //下载文件
    dowLoadFile = (targetMark, targetName) => {
        console.log("targetMark", targetMark)
        let now = new Date().getTime();
        axios.get(downTemplate(targetMark), {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${targetName}.xls`);
            link.click();
        })
    }

    // 校验网址格式
    validatehttp = (rule, value, callback) => {
        let pattern = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
        if (value && !pattern.test(value)) {
            callback('请输入正确网址链接')
        } else {
            callback();
        }
    }

    number = (value) => {
        console.log('value', value)
        let data = {
            id: this.state.trainPlanInfo.factorId,
            factorContent: Number(value)
        }
        updateFactor(data).then(res => {
            if (res) {
                message.success("操作成功")
            }
        })
    }

    gaiBianNum = (value) => {
        this.setState({
            courseFactorContent: Number(value)
        })
    }

    zongrenshu = (value) => {
        let data = {
            id: this.state.columnsDData.factorId,
            factorContent: Number(value)
        }
        updateFactor(data).then(res => {
            if (res) {
                // message.success("操作成功")
            }
        })
    }

    // 添加佐证材料
    handAttachment = () => {
        this.setState({
            addEvidence: true,
        })
    }
    // 查看佐证材料
    lookHandevid = () => {
        this.setState({
            LookEvidence: true
        })
    }
    // 关闭
    handleCancel = () => {
        this.setState({LookEvidence: false});
    };
    /**
     *  共享总人数
     */
    getNum = () => {
        let data = {
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.columnsDData.id,
        }
        getNum(data).then(res => {
            console.log('res 009', res)
            if (res) {
                this.setState({
                    studentNumInfoList: res.resourceVoList.data,
                    showList: res.resourceVoList.data,
                    factorContent: res.factorContent
                })
            }
        })
    }

    fileAdd = () => {
        let file = this.Addevidence.getState()
        let cailiaoType = this.Addevidence.cailiaoType()
        console.log('cailiaoType', cailiaoType)
        if (file.length === 0) {
            message.warning("请上传文件")
            return false;
        }
        if (cailiaoType !== 1) {
            message.warning("请先勾选我承诺上传的资料真实可信")
            return false;
        }
        console.log('file', file)
        if (file.length > 0) {
            if (this.state.columnsDData.resourceVoList.length > 0) {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsDData.id,
                    evidenceQoList: file
                }
                console.log('文件的入参修改：：：', data)
                updateNum(data).then(res => {
                    if (res) {
                        message.success("修改成功")
                        this.setState({
                            addEvidence: false
                        }, () => {
                            this.getNum()
                        })
                    }

                })
            } else {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsDData.id,
                    evidenceQoList: file
                }
                console.log('文件的入参新增：：：', data)
                addNum(data).then(res => {
                    if (res) {
                        message.success("新增成功")
                        this.setState({
                            addEvidence: false
                        }, () => {
                            this.getNum()
                        })
                    }
                })
            }
        }
    }

    gaiban = (value) => {
        this.setState({
            factorContent: Number(value)
        })
    }

    cooperationNumber = (value) => {
        let data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            cooperationNumber: Number(value)
        }
        updateMajorNum(data).then(res => {
            if (res) {
                // message.success("操作成功")
            }
        })
    }


    // section Render
    render() {
        const canEdit = getCanEdit()
        // 课程共享占比
        const {getFieldDecorator} = this.props.form
        const {
            resourceId,
            examine,
            courseFactorContent,
            factorContent,
            showList,
            addEvidence,
            sharingOrganEnterpriseId,
            sharingOrganCourseId,
            sharingResourcesId,
            columnsATotal,
            columnsBTotal,
            columnsCTotal,
            columnsDTotal,
            columnsAData,
            columnsBData,
            columnsCData,
            columnsDData,
            dataSource1,
            dataSource2,
            dataSource3,
            showAddResource,
            showSchoolModal,
            addEnterprise,
            LookEvidence,
            pageNum,
            pageSize,
            total,
            totalone,
            pageNumone,
            pageSizeone,
            pageNumtwo,
            pageSizetwo,
            totaltwo,
            sharingResources,
            sharingOrganCourse,
            sharingOrganEnterprise, isShow,
            openUrl
        } = this.state
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNum- 1) *pageSize+ (index + 1)}</span>
                }
            },
            {
                title: '虚拟仿真实训资源名称',
                dataIndex: 'resourcesName',
                key: 'resourcesName',
                align: 'center',
                width: 180
            },
            {
                title: '共享平台',
                dataIndex: 'sharingPlatform',
                key: 'sharingPlatform',
                align: 'center',
                width: 180
            },
            {
                title: '链接地址',
                dataIndex: 'websiteLink',
                key: 'websiteLink',
                align: 'center',
                width: 180
            },
            {
                title: '共享次数',
                dataIndex: 'sharingNumber',
                key: 'sharingNumber',
                align: 'center',
                width: 180,
            },
            {
                title: '佐证材料', dataIndex: 'fileId', key: 'fileId', align: 'center', width: 180,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },

        ]
        if (canEdit === true) columns1.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            fixed: 'right',
            width: 220,
            render: (text, record) => <div className={'caozuo'}>
                <span className={'cz'} onClick={() => this.updateSharingResources(record)}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteSharingResources(record)}>删除</span>
            </div>

        })

        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNumone- 1) *pageSizeone+ (index + 1)}</span>
                }
            },
            {
                title: '学校名称',
                dataIndex: 'organName',
                key: 'organName',
                align: 'center',
                width: 180
            },
            {
                title: '所在省市',
                dataIndex: 'provinceName',
                key: 'provinceName',
                align: 'center',
                width: 180
            },
            {
                title: '所在城市',
                dataIndex: 'regionName',
                key: 'regionName',
                align: 'center',
                width: 180
            },
            {
                title: '虚拟仿真课程名称',
                dataIndex: 'organCourse',
                key: 'organCourse',
                width: 180
            },
            {
                title: '使用次数',
                dataIndex: 'usageCount',
                key: 'usageCount',
                align: 'center',
                width: 180
            },
            {
                title: '佐证材料', dataIndex: 'fileId', key: 'fileId', align: 'center', width: 180,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            }
        ]

        if (canEdit) columns2.push(
            {
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                fixed: 'right',
                render: (text, record) => <div className={'caozuo'}>
                    <span className={'cz'} onClick={() => this.updateSharingOrganCourse(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deleteSharingOrganCourse(record)}>删除</span>
                </div>

            },)
        const columns3 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNumtwo- 1) *pageSizetwo+ (index + 1)}</span>
                }
            },
            {
                title: '企业名称',
                dataIndex: 'organName',
                key: 'organName',
                align: 'center',
                width: 180
            },
            {
                title: '所在省市',
                dataIndex: 'provinceName',
                key: 'provinceName',
                align: 'center',
                width: 180
            },
            {
                title: '所在城市',
                dataIndex: 'regionName',
                key: 'regionName',
                align: 'center',
                width: 180
            },
            {
                title: '虚拟仿真课程名称',
                dataIndex: 'organCourse',
                key: 'organCourse',
                align: 'center',
                width: 180
            },
            {
                title: '使用次数',
                dataIndex: 'usageCount',
                key: 'usageCount',
                align: 'center',
                width: 180
            },
            {
                title: '佐证材料', dataIndex: 'fileId', key: 'fileId', align: 'center', width: 180,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },
        ]
        if (canEdit) columns3.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            width: 200,
            fixed: 'right',
            render: (text, record) => <div className={'caozuo'}>
                <span className={'cz'} onClick={() => this.updateSharingOrganEnterprise(record)}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteSharingOrganEnterprise(record)}>删除</span>
            </div>

        })

        console.log('showList', showList)
        const {file} = this.state;
        return (
            <div className="PlatformCoursesharing Fillside_warp">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id='Fillside_warp'>
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect">
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside onRef={(ref) => {
                                            this.LeftEfficiencyside = ref;
                                        }}/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right">
                                    <div className='content'>
                                        <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.3.1 虚拟仿真实训课程开放共享率
                                            </div>
                                            <ul className={'tongjigroup'}>

                                               <li>
                                                    <span>虚拟仿真课程总数量</span>
                                                    <div
                                                        className={'btn'}>{columnsAData.courseNum ? columnsAData.courseNum : 0}</div>
                                                </li>
                                                <li>
                                                    <span>共享数量</span>
                                                    <div className={'btn'}>{columnsATotal ? columnsATotal : 0}</div>
                                                </li>

                                                <li>
                                                    <span>占比</span>
                                                    <div
                                                        className={'btn'}>{columnsAData.courseNum && columnsATotal !== 0 ? ((columnsATotal / columnsAData.courseNum) * 100).toFixed(2) : 0}%
                                                    </div>
                                                </li>
                                            </ul>
                                            {canEdit ?
                                            <div className={'btn_group'}>
                                                 <Button type={'primary'} onClick={() => this.setState({
                                                    showAddResource: true,
                                                    sharingResourcesId: '',
                                                })}><TianJia/>添加</Button>


                                            </div>: ''}
                                            <Table columns={columns1} dataSource={dataSource1} className={'table1'}
                                                   bordered={true}
                                                   scroll={{x: 1200}}
                                                   locale={{
                                                       emptyText: <>
                                                           <div className="Empty-content">
                                                               <div className="text">
                                                                   <img
                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div>
                                                       </>
                                                   }}
                                                   pagination={
                                                       {
                                                        current:this.state.pageNum,
                                                           total: columnsATotal,
                                                           pageSize: pageSize,
                                                           onChange: this.pageChange,
                                                           onShowSizeChange: this.onShowSizeChange,
                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                           showSizeChanger: true,
                                                           // position:'bottomRight'
                                                       }
                                                   }
                                            />
                                        </div>
                                        <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.3.2 虚拟仿真实训课程共享学校数
                                            </div>
                                            <ul className={'tongjigroup'}>
                                                <li>
                                                    <span>学校数</span>
                                                    <div className={'btn'}>{columnsBTotal ? columnsBTotal : 0}</div>
                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ? <Button type={'primary'} onClick={() => this.setState({
                                                    showSchoolModal: true,
                                                    sharingOrganCourseId: '',
                                                    sharingOrganCourse: ''
                                                })}><Addschool/>添加学校</Button> : ""}

                                            </div>
                                            <Table columns={columns2} dataSource={dataSource2} className={'table1'}
                                                   bordered={true}
                                                   scroll={{x: 1300}}
                                                   locale={{
                                                       emptyText: <>
                                                           <div className="Empty-content">
                                                               <div className="text">
                                                                   <img
                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div>
                                                       </>
                                                   }}
                                                   pagination={
                                                       {
                                                        current:this.state.pageNumone,
                                                           total: columnsBTotal,
                                                           pageSize: pageSizeone,
                                                           onChange: this.pageChangeone,
                                                           onShowSizeChange: this.onShowSizeChangeone,
                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                           showSizeChanger: true,
                                                           // position:'bottomRight'
                                                       }
                                                   }
                                            />
                                        </div>
                                        <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.3.3 虚拟仿真实训课程共享企业数
                                            </div>
                                            <ul className={'tongjigroup tongjigroup_ul'}>
                                                <li>
                                                    <span>共享本基地虚拟仿真课程的企业数量</span>
                                                    <div className={'btn'}>{columnsCTotal ? columnsCTotal : 0}</div>
                                                </li>
                                                <li>
                                                    <span>本校校企合作总数量</span>
                                                    <InputNumber min={0} className='input_bg'
                                                                 disabled={!canEdit}
                                                                 style={{width:"100px"}}
                                                                 maxLength={9}
                                                                 onChange={e => this.gaiBianNum(e)}
                                                                 onBlur={e => this.cooperationNumber(e.target.value)}
                                                                 placeholder={'数值填充'}
                                                                 value={courseFactorContent ? courseFactorContent : 0}/>
                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ? <Button type={'primary'} onClick={() => this.setState({
                                                    addEnterprise: true,
                                                    sharingOrganEnterprise: null,
                                                    file: null,
                                                    sharingOrganEnterpriseId: ''
                                                })}><Addenterprise/>添加企业</Button> : ""}

                                            </div>
                                            <Table columns={columns3} dataSource={dataSource3} className={'table1'}
                                                   bordered={true}
                                                   scroll={{x: 1300}}
                                                   locale={{
                                                       emptyText: <>
                                                           <div className="Empty-content">
                                                               <div className="text">
                                                                   <img
                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                   <span>暂无数据</span>
                                                               </div>
                                                           </div>
                                                       </>
                                                   }}
                                                   pagination={
                                                       {
                                                           current:this.state.pageNumtwo,
                                                           total: columnsCTotal,
                                                           pageSize: pageSizetwo,
                                                           onChange: this.pageChangetwo,
                                                           onShowSizeChange: this.onShowSizeChangetwo,
                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                           showSizeChanger: true,
                                                           // position:'bottomRight'
                                                       }
                                                   }
                                            />
                                        </div>
                                        <div className={'cont1'}>
                                            <div
                                                className={'titleName'}>2.3.4 虚拟仿真实训课程使用总人数
                                            </div>
                                            <ul className={'tongjigroup'}>
                                                <li style={{width: '100%'}}>
                                                    <span>总人数</span>
                                                    <InputNumber min={0} value={factorContent ? factorContent : 0}
                                                                 disabled={!canEdit}
                                                                 maxLength={9}
                                                                 onChange={e => this.gaiban(e)}
                                                                 className='conteInput li_rightinput'
                                                                 onBlur={e => this.zongrenshu(e.target.value)}
                                                                 placeholder="数值填充"
                                                                 style={{width:"100px"}}/>
                                                </li>
                                            </ul>
                                            <div className={'btn_group'}>
                                                {canEdit ? <Button type={'primary'}
                                                                   onClick={this.handAttachment}><TeacherDevelopmentUpload/>添加附件</Button> : ""}
                                                {showList && showList.length > 0 ?
                                                    <span className={'chakanzzcl'}
                                                          onClick={() => this.lookHandevid()}>查看佐证材料</span> : null
                                                }

                                            </div>
                                        </div>
                                        {canEdit ? <div className={'footer-btn'}>
                                            <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                            <Button type={'primary'} onClick={() => this.next()}>下一步</Button>
                                        </div> : ""}

                                        {/*添加资源*/}
                                        <Modal
                                            title={sharingResourcesId ? '编辑资源' : '添加资源'}
                                            visible={showAddResource}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({showAddResource: false})}>取消</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.columnsATiJiao()}>提交</Button>
                                                    {sharingResourcesId ? '' :
                                                        <Button type='primary'
                                                                onClick={() => this.columnsATiJiaoAndAdd()}>提交并继续添加</Button>
                                                    }
                                                </div>
                                            }
                                        >
                                            <Form>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>资源名称</span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('resourcesName', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请输入虚拟仿真实训资源名称`,
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: "文本过长，最多30字"
                                                                },
                                                            ],
                                                        })(
                                                            <Input placeholder='请输入虚拟仿真实训资源名称' maxLength={30}
                                                                   suffix={<span className='len'>
                                                            {this.props.form.getFieldValue(`resourcesName`) ? this.props.form.getFieldValue(`resourcesName`).length : 0}/30</span>}/>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>共享平台</span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('sharingPlatform', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请输入共享平台名称`,
                                                                },
                                                                {
                                                                    max: 30,
                                                                    message: "文本过长，最多30字"
                                                                },
                                                            ],
                                                        })(
                                                            <Input placeholder='请输入共享平台名称' maxLength={30}
                                                                   suffix={<span className='len'>
                                                            {this.props.form.getFieldValue(`sharingPlatform`) ? this.props.form.getFieldValue(`sharingPlatform`).length : 0}/30</span>}/>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>网址链接</span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('websiteLink', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请输入网址链接`,
                                                                },
                                                                // {
                                                                //     max: 30,
                                                                //     message: "文本过长，最多30字"
                                                                // },
                                                                {
                                                                    validator: this.validatehttp
                                                                }
                                                            ],
                                                        })(
                                                            <Input placeholder='请输入网址链接'/>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item>
                                                    <span className={'label'}><span className='red'>*</span>共享次数</span>
                                                    <div className={'input-wrap'}>
                                                        {getFieldDecorator('sharingNumber', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请输入共享次数`,
                                                                },
                                                            ],
                                                        })(
                                                            <InputNumber min={0} maxLength={9} placeholder='请输入资源已经共享的次数'/>
                                                        )}
                                                    </div>
                                                </Form.Item>
                                                <Form.Item className="li_formup">
                                                    <span className={'label'}><span className='red'>*</span>佐证材料</span>
                                                    {getFieldDecorator('fileId', {
                                                        initialValue: '',
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `请选择佐证材料`,
                                                            },
                                                        ],
                                                    })(
                                                        <div className="up_button">
                                                            <div className="span_right">
                                                                <label htmlFor="file" className="inputlabelBox">
                                                                    <span><Upicon/>上传资料</span>
                                                                </label>
                                                                <input
                                                                    type="file"
                                                                    accept="image"
                                                                    onChange={this.multipartUpload}
                                                                    name="myfile"
                                                                    id="file"
                                                                    style={{display: "none"}}
                                                                    onClick={(event) => {
                                                                        event.target.value = null
                                                                    }}
                                                                />
                                                                <span className={'tip'}>
                                                                    支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                                                </span>
                                                            </div>
                                                            <ul className="bottom_tit">
                                                                {/*上传中*/}
                                                                {file ? <Tooltip placement="topLeft"
                                                                                 title={file.originName}>
                                                                    <li className="title_de">{file.fileId ?
                                                                        <Upiconwc/> : <Spin/>}<span
                                                                        className="tie_span">{file.originName}</span><i
                                                                        className="Upicona"
                                                                        onClick={this.deleteFile}><Upicondele/></i></li>
                                                                </Tooltip> : null}
                                                                {/*已完成*/}
                                                                {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                                            </ul>
                                                        </div>
                                                    )}
                                                </Form.Item>
                                            </Form>
                                        </Modal>
                                        {/*添加学校*/}
                                        <Modal
                                            title={sharingOrganCourseId ? '编辑学校' : '添加学校'}
                                            visible={showSchoolModal}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({showSchoolModal: false})}>取消</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.columnsBTiJiao()}>提交</Button>
                                                    {sharingOrganCourse ? '' :
                                                        <Button type='primary'
                                                                onClick={() => this.columnsBTiJiaoAndAdd()}>提交并继续添加</Button>
                                                    }
                                                </div>
                                            }
                                        >
                                            <AddSchool data={sharingOrganCourse} wrappedComponentRef={form => {
                                                this.AddSchool = form;
                                            }}/>
                                        </Modal>
                                        {/*添加企业*/}
                                        <Modal
                                            title={sharingOrganEnterpriseId ? '编辑企业' : '添加企业'}
                                            visible={addEnterprise}
                                            width={660}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'addClassModal'}
                                            wrapClassName="wappop_up"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({addEnterprise: false})}>取消</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.columnsCTiJiao()}>提交</Button>
                                                    {sharingOrganEnterprise ? '' :
                                                        <Button type='primary'
                                                                onClick={() => this.columnsCTiJiaoAndAdd()}>提交并继续添加</Button>
                                                    }
                                                </div>
                                            }
                                        >
                                            <AddEnterprise data={sharingOrganEnterprise} wrappedComponentRef={form => {
                                                this.AddEnterprise = form;
                                            }}></AddEnterprise>
                                        </Modal>
                                        {/*添加附件*/}
                                        <Modal
                                            title={'添加附件'}
                                            visible={addEvidence}
                                            width={800}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'Addevidence'}
                                            wrapClassName="Addevidence_warp"
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({addEvidence: false})}>取消</Button>
                                                    <Button type='primary' onClick={() => this.fileAdd()}>提交</Button>
                                                </div>
                                            }
                                        >
                                            <AddWashable onRef={(ref) => this.Addevidence = ref}
                                                         targetId={this.state.addTargetId}/>
                                        </Modal>
                                        {/*查看佐证材料*/}
                                        <Modal
                                            title={'查看佐证材料'}
                                            visible={LookEvidence}
                                            width={800}
                                            centered={true}
                                            destroyOnClose={true}
                                            className={'Addevidence'}
                                            wrapClassName="Addevidence_warp"
                                            onCancel={this.handleCancel}
                                            footer={
                                                <div className='footer-btn' style={{display: 'none'}}>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({LookEvidence: false})}>取消</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.cailiaoTijiao()}>提交</Button>
                                                </div>
                                            }
                                        >
                                            <Addevidencelook
                                                editEnable={canEdit}
                                                columnsDData={columnsDData} showList={showList}
                                                getNum={this.getNum} targetId={this.state.addTargetId}/>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
                <Footer/>
            </div>
        );
    }
}

PlatformCoursesharing = Form.create()(PlatformCoursesharing);
export default PlatformCoursesharing;
