// 预览任务书左侧
import React, {Component} from 'react';
import "./index.scss";
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
@withRouter
class Leftmien extends Component {
    state = {
      processCode: '',
      schoolInfo:JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : ""
    };
    componentDidMount() {
        let mainCont = document.getElementById('main_cont')
        let mainContWith=  mainCont&&mainCont.offsetWidth
        let windowWidth=document.documentElement.clientWidth
        let leftMain=document.getElementById('leftmain')
        if(leftMain){
            leftMain.style.left= ( windowWidth-mainContWith)/2
        }
        // 左侧跟随屏幕
        window.addEventListener('scroll', this.bindHandleScroll)
        // 左侧跟随屏幕
    }
  menuClick = (processCode) => {
    console.log("processName",processCode)
    // 根据 processCode 判端跳转到的位置
    // 默认如果没有对应code的话，跳转到预览第一页situationcontent
    let jumpPageRouter = "/construction_BasicInformation";
    if ("1-1" === processCode) {
      jumpPageRouter = "/construction_BasicInformation"
    } else if ("1-2" === processCode) {
      jumpPageRouter = "/project_Situation"
    } else if ("1-3" === processCode) {
      jumpPageRouter = "/construction_scheme"
    } else if ("1-4" === processCode) {
      jumpPageRouter = "/tasks_Schedule"
    } else if ("1-5" === processCode) {
      jumpPageRouter = "/building_project"
    } else if ("1-6" === processCode) {
      jumpPageRouter = "/planned_performance"
    } else if ("1-7" === processCode) {
      jumpPageRouter = "/seal_Pile"
    }
    this.props.history.push(jumpPageRouter)
  }
    // 左侧跟随屏幕
    bindHandleScroll = (event) => {
        let scrollTop =event.srcElement ? event.srcElement.documentElement.scrollTop : false|| window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)

        if (scrollTop > 160) {
            document.getElementById("leftmain") && document.getElementById("leftmain").setAttribute("style", "top:100px;position:fixed;");
        } else {
            document.getElementById("leftmain") && document.getElementById("leftmain").setAttribute("style", "top:0;position:relative;");
        }
    }
    render() {
        const {processCode} = this.props;
        const {schoolInfo}=this.state;
        return (
            <div className="Double-LeftMein">
                <div className="title-h5">
                <h5>{schoolInfo.schoolName}-任务书</h5>
                </div>
                <div className="table">
                    <ul>
                      <h6>基地建设任务书</h6>
                      <li className={processCode === '1-1' ? 'active' : ''} onClick={() => this.menuClick('1-1')}>1. 基础信息</li>
                      <li className={processCode === '1-2' ? 'active' : ''} onClick={() => this.menuClick('1-2')}>2. 项目情况</li>
                      <li className={processCode === '1-3' ? 'active' : ''} onClick={() => this.menuClick('1-3')}>3. 建设方案综述</li>
                      <li className={processCode === '1-4' ? 'active' : ''} onClick={() => this.menuClick('1-4')}>4. 建设任务和进度</li>
                      <li className={processCode === '1-5' ? 'active' : ''} onClick={() => this.menuClick('1-5')}>5. 建设项目总预算</li>
                      <li className={processCode === '1-6' ? 'active' : ''} onClick={() => this.menuClick('1-6')}>6. 预期成效和保障</li>
                      <li className={processCode === '1-7' ? 'active' : ''} onClick={() => this.menuClick('1-7')}>7. 盖章文件</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Leftmien;
