import React, { Component } from "react";
import { Input, Form ,Radio,InputNumber} from "antd";

const FormItem = Form.Item;
class Forms extends React.Component {
  state = {
    file: null,
    disabled:false
  };

  componentDidMount() {
    let { editObj } = this.props;

    if (Object.keys(editObj || {}).length !== 0) {

      this.props.form.setFieldsValue({
        ...editObj,
        situationType: editObj.situationType + ''
      });
    } else {
      this.props.form.resetFields();
    }
  }
  onChange =(e)=>{
    let value = e.target.value;
    let name = e.target.name;
    if ( name == 'radio1' && value == 0 ) {
      this.setState({
        disabled:true
      })
      this.props.form.setFieldsValue({
        isCloudVs:0
      });
    } else if (name == 'radio1' && value == 1){
      this.setState({
        disabled:false
      })
    }
    console.log('value',value)
    console.log('name',name)
  }
  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 18 },
        sm: { span: 16 }
      },
    };

    return (
      <Form {...formItemLayout}>
        <FormItem label="课程名称" style={{display:'flex'}}>
          {getFieldDecorator("courseName", {
            rules: [
              {
                required: true,
                message: "请填写课程名称",
              },
            ],
          })(<Input placeholder='请输入课题名称' maxLength={30} suffix={<span className='len'>
          {this.props.form.getFieldValue(`courseName`) ? this.props.form.getFieldValue(`courseName`).length : 0}/30</span>} />)}
        </FormItem>
        <FormItem label="是否为虚拟仿真实训" style={{display:'flex'}}>
          {getFieldDecorator("isVs", {
            rules: [
              {
                required: true,
                message: "请填写是否为虚拟仿真实训",
              },
            ],
          })(
            <Radio.Group onChange={(e)=>this.onChange(e)} name="radio1">
              <Radio value={1}>是</Radio>
              <Radio value={0} >否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="是否为云端虚拟仿真实训" style={{display:'flex'}}>
          {getFieldDecorator("isCloudVs", {
            rules: [
              {
                required: true,
                message: "请填写年是否为云端虚拟仿真实训",
              },
            ],
          })(
            <Radio.Group onChange={(e)=>this.onChange(e)} name="radio2">
              <Radio value={1} disabled={this.state.disabled}>是</Radio>
              <Radio value={0} >否</Radio>
            </Radio.Group>
          )}
        </FormItem>
        <FormItem label="课程实训人数（本校学生）" style={{display:'flex'}}>
          {getFieldDecorator("localTrainNum", {
            rules: [
              {
                required: true,
                message: "课程实训人数（本校学生）",
              },
            ],
          })(<InputNumber  placeholder='请输入课程实训人数（本校学生）' min={0} type="number" style={{width:'100%'}}/>)}
        </FormItem>
        <FormItem label="课程实训人数(国内共享院校学生)" style={{display:'flex'}}>
          {getFieldDecorator("interTrainNum", {
            rules: [
              {
                required: true,
                message: "请填写课程实训人数(国内共享院校学生)",
              },
            ],
          })(<InputNumber  placeholder='请输入课程实训人数(国内共享院校学生)' min={0}   type="number" style={{width:'100%'}}/>)}
        </FormItem>
        <FormItem label="实训学时"  style={{display:'flex'}}>
          {getFieldDecorator("trainPeriod", {
            rules: [
              {
                required: true,
                message: "实训学时",
              },
            ],
          })(<InputNumber placeholder='请输入实训学时' min={0}  type="number" style={{width:'100%'}}/>)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
