// 1.1.2资金预算到账率
import React from "react";
import {Col, Form, Row, Table, Input, Button, InputNumber, Modal, Radio, message} from 'antd';
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {saveTargetFundInvest} from "../../../../api/targetResource";
import "../../index.scss";
import {getRoleType, RoleType} from "../../../../until/cookie";

const {TextArea} = Input

export default Form.create()(
    withRouter(
        class Implementationof extends React.Component {
            state = {
                schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
                targetId: this.props.targetIdThree,
                saveStatus: true,
                selectTargetFundInvestVo: this.props.moneyPerformThreeVo113,
                moneyPerformThreeVo113: this.props.moneyPerformThreeVo113,
            };

            componentDidMount() {
            }

            //保存
            save = (e) => {
                const {saveStatus} = this.state
                if (saveStatus) {
                    this.setState({saveStatus: false})
                    e.preventDefault();
                    this.props.form.validateFields((err, values) => {
                        let parmes = []
                        if (!err) {
                            this.state.selectTargetFundInvestVo.map((item, index) => {
                                item.targetFundInvestVoList.map((todo, k) => {
                                    let data = {
                                        "displayOrder": k,
                                        "investMoney": values[`investMoney${item.type}${k}`],
                                        "spendingMoney": values[`spendingMoney${item.type}${k}`],
                                        "spendingEnforced": values[`spendingEnforced${item.type}${k}`],
                                        "type": item.type,
                                        "year": 2020 + index,
                                    }
                                    parmes.push(data)
                                })

                            })
                            // let investInYearQos = []
                            saveTargetFundInvest({
                                "targetId": this.state.targetId,
                                "schoolEduCode": this.state.schoolEduCode, targetFundInvestQoList: parmes
                            }).then(res => {
                                message.success("保存成功")
                            })
                        } else {
                            window.scrollTo({
                                top: 390,
                                behavior: 'smooth',
                            });
                        }
                    })
                    setTimeout(() => {
                        this.setState({saveStatus: true})
                    }, 3000)
                }
            }
            handleTotalOneInt = () => {
                let total = 0
                const value1 = this.state.moneyPerformThreeVo113[0].targetFundInvestVoList[0].receivedMoney ?? 0
                const value2 = this.state.moneyPerformThreeVo113[1].targetFundInvestVoList[0].receivedMoney ?? 0
                const value3 = this.state.moneyPerformThreeVo113[2].targetFundInvestVoList[0].receivedMoney ?? 0
                total = total + value1 + value2 + value3
                return total
            }
            //合计
            handleTotalOne = () => {
                let total = 0

                // let value1 = this.props.form.getFieldValue(`investMoney10`) ? parseInt(this.props.form.getFieldValue(`investMoney10`)) : 0
                // let value2 = this.props.form.getFieldValue(`investMoney20`) ? parseInt(this.props.form.getFieldValue(`investMoney20`)) : 0
                // let value3 = this.props.form.getFieldValue(`investMoney30`) ? parseInt(this.props.form.getFieldValue(`investMoney30`)) : 0
                // total = total + value1 + value2 + value3


                const value1 = this.state.moneyPerformThreeVo113[0].targetFundInvestVoList[0].receivedMoney ?? 0
                const value2 = this.state.moneyPerformThreeVo113[1].targetFundInvestVoList[0].receivedMoney ?? 0
                const value3 = this.state.moneyPerformThreeVo113[2].targetFundInvestVoList[0].receivedMoney ?? 0
                total = total + value1 + value2 + value3


                return total.toLocaleString()
            }
            handleTotalTwoInt = () => {
                var total = 0
                let value1 = this.props.form.getFieldValue(`spendingMoney10`) ? parseInt(this.props.form.getFieldValue(`spendingMoney10`)) : 0
                let value2 = this.props.form.getFieldValue(`spendingMoney20`) ? parseInt(this.props.form.getFieldValue(`spendingMoney20`)) : 0
                let value3 = this.props.form.getFieldValue(`spendingMoney30`) ? parseInt(this.props.form.getFieldValue(`spendingMoney30`)) : 0
                total = total + value1 + value2 + value3
                return total
            }
            //合计
            handleTotalTwo = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`spendingMoney10`) ? parseInt(this.props.form.getFieldValue(`spendingMoney10`)) : 0
                let value2 = this.props.form.getFieldValue(`spendingMoney20`) ? parseInt(this.props.form.getFieldValue(`spendingMoney20`)) : 0
                let value3 = this.props.form.getFieldValue(`spendingMoney30`) ? parseInt(this.props.form.getFieldValue(`spendingMoney30`)) : 0
                total = total + value1 + value2 + value3
                return total.toLocaleString()
            }
            //合计
            handleTotalThreeInt = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`spendingEnforced10`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced10`)) : 0
                let value2 = this.props.form.getFieldValue(`spendingEnforced20`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced20`)) : 0
                let value3 = this.props.form.getFieldValue(`spendingEnforced30`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced30`)) : 0
                total = total + value1 + value2 + value3
                const value = this.handleTotalOneInt()
                if (value === 0 || value === '0') {
                    return 0
                }
                return (this.handleTotalTwoInt() * 100 / value)
            }
            //合计
            handleTotalThree = () => {
                return this.handleTotalThreeInt().toFixed(2)
            }
            //合计
            handleTotalFour = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`investMoney11`) ? parseInt(this.props.form.getFieldValue(`investMoney11`)) : 0
                let value2 = this.props.form.getFieldValue(`investMoney21`) ? parseInt(this.props.form.getFieldValue(`investMoney21`)) : 0
                let value3 = this.props.form.getFieldValue(`investMoney31`) ? parseInt(this.props.form.getFieldValue(`investMoney31`)) : 0
                total = total + value1 + value2 + value3
                // return total.toLocaleString()
                return "-"
            }
            //合计
            handleTotalFive = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`spendingMoney11`)
                let value2 = this.props.form.getFieldValue(`spendingMoney21`)
                let value3 = this.props.form.getFieldValue(`spendingMoney31`)
                if (!value1 || !value2 || !value3) {
                    return "-"
                }
                total = total + value1 + value2 + value3;
                return total.toLocaleString()
            }
            //合计
            handleTotalsix = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`spendingEnforced11`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced11`)) : 0
                let value2 = this.props.form.getFieldValue(`spendingEnforced21`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced21`)) : 0
                let value3 = this.props.form.getFieldValue(`spendingEnforced31`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced31`)) : 0
                total = total + value1 + value2 + value3
                // return total.toLocaleString()
                return "-"
            }
            //合计
            handleTotalseven = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`investMoney12`) ? parseInt(this.props.form.getFieldValue(`investMoney12`)) : 0
                let value2 = this.props.form.getFieldValue(`investMoney22`) ? parseInt(this.props.form.getFieldValue(`investMoney22`)) : 0
                let value3 = this.props.form.getFieldValue(`investMoney32`) ? parseInt(this.props.form.getFieldValue(`investMoney32`)) : 0
                total = total + value1 + value2 + value3
                // return total.toLocaleString()
                return "-"
            }
            //合计
            handleTotaleight = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`spendingMoney12`) ? parseInt(this.props.form.getFieldValue(`spendingMoney12`)) : 0
                let value2 = this.props.form.getFieldValue(`spendingMoney22`) ? parseInt(this.props.form.getFieldValue(`spendingMoney22`)) : 0
                let value3 = this.props.form.getFieldValue(`spendingMoney32`) ? parseInt(this.props.form.getFieldValue(`spendingMoney32`)) : 0
                total = total + value1 + value2 + value3
                // return total.toLocaleString()
                return "-"
            }
            //合计
            handleTotalnine = () => {
                let total = 0

                let value1 = this.props.form.getFieldValue(`spendingEnforced12`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced12`)) : 0
                let value2 = this.props.form.getFieldValue(`spendingEnforced22`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced22`)) : 0
                let value3 = this.props.form.getFieldValue(`spendingEnforced32`) ? parseInt(this.props.form.getFieldValue(`spendingEnforced32`)) : 0
                total = total + value1 + value2 + value3
                // return total.toLocaleString()
                return "-"
            }

            // section Render
            render() {
                const roleType = getRoleType()
                const canEdit = roleType === RoleType.school
                const {getFieldDecorator} = this.props.form
                const {selectTargetFundInvestVo, moneyPerformThreeVo113} = this.state;
                return (
                    <div className="Implementationof">
                        <div className="bottom_table">
                            <div className="mactable_cont">
                                <ul>
                                    <li className="fira">
                                        <h5>来源渠道</h5>
                                    </li>
                                    <li>
                                        <span className="span_title">2021实际到账资金（元）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">2021实际支出资金（元）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">预算支出执行率（%）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">2022实际到账资金（元）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">2022实际支出资金（元）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">预算支出执行率（%）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">2023实际到账资金（元）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">2023实际支出资金（元）</span>
                                    </li>
                                    <li>
                                        <span className="span_title">预算支出执行率（%）</span>
                                    </li>
                                </ul>
                                {moneyPerformThreeVo113 ? moneyPerformThreeVo113.map((item, index) => (
                                    <ul>
                                        <li className="fira">
                                            <h5>{item.type === 1 ? "政府" : item.type === 2 ? "学校" : item.type === 3 ? "行业企业" : ""}</h5>
                                        </li>
                                        {item ? item.targetFundInvestVoList.map((itemTwo, indexTwo) => {
                                            const percentValue = itemTwo.receivedMoney ? (this.props.form.getFieldValue(`spendingMoney${item.type}${indexTwo}`) / itemTwo.receivedMoney * 100).toFixed(2) : ''
                                            return (
                                                <div>
                                                    <li>
                                                        <Form.Item className="ro-cont">
                                                            {getFieldDecorator(`investMoney${item.type}${indexTwo}`, {
                                                                initialValue: itemTwo.receivedMoney ? itemTwo.receivedMoney : null,
                                                                rules: [
                                                                    {
                                                                        required: indexTwo <= 0,
                                                                        pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                                                        message: `请输入0或正整数(元)`,
                                                                    },
                                                                ],
                                                            })
                                                            (<InputNumber
                                                                formatter={value => {
                                                                    return itemTwo.receivedMoney ? itemTwo.receivedMoney : null
                                                                }}
                                                                min={0} max={999999999} disabled={true}/>)
                                                            }
                                                        </Form.Item>
                                                    </li>
                                                    <li>
                                                        <Form.Item className="ro-cont">
                                                            {getFieldDecorator(`spendingMoney${item.type}${indexTwo}`, {
                                                                initialValue: itemTwo.spendingMoney ? itemTwo.spendingMoney : null,
                                                                rules: [
                                                                    {
                                                                        required: indexTwo > 0 ? false : true,
                                                                        pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                                                        message: `请输入0或正整数(元)`,
                                                                    },
                                                                ],
                                                            })
                                                            (<InputNumber min={0} max={999999999}
                                                                          disabled={canEdit ? (indexTwo > 0) : true}/>)
                                                            }
                                                        </Form.Item>
                                                    </li>
                                                    <li>
                                                        <Form.Item className="ro-cont">
                                                            {getFieldDecorator(`spendingEnforced${item.type}${indexTwo}`, {
                                                                initialValue: percentValue,
                                                                rules: [
                                                                    {
                                                                        required: false,
                                                                        // pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                        message: `请输入0或正整数(元)`,
                                                                    },
                                                                ],
                                                            })
                                                            (<InputNumber
                                                                formatter={value => percentValue ? percentValue + "%" : null}
                                                                min={0} max={999999999} disabled={true}/>)
                                                            }
                                                        </Form.Item>
                                                    </li>
                                                </div>
                                            )
                                        }) : ""}
                                    </ul>
                                )) : ""}
                                <ul>
                                    <li className="fira">
                                        <h5>总金额合计</h5>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalOne()}</span>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalTwo()}</span>
                                    </li>
                                    <li className="last-li">
                                        {/*<span>{this.handleTotalThree()}</span>*/}
                                        <Form.Item className="ro-cont"
                                                   validateStatus={this.handleTotalThreeInt() > 90 ? 'success' : 'error'}
                                                   help={this.handleTotalThreeInt() > 90 ? "" : "*该项指标需高于90%"}>
                                            {getFieldDecorator('expenditure', {
                                                initialValue: this.handleTotalThree(),
                                            })
                                            (<InputNumber formatter={(value) => value + "%"}
                                                          value={this.handleTotalThree()} min={0} max={999999999}
                                                          disabled={true}/>)
                                            }
                                        </Form.Item>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalFour()}</span>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalFive()}</span>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalsix()}</span>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalseven()}</span>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotaleight()}</span>
                                    </li>
                                    <li className="last-li">
                                        <span>{this.handleTotalnine()}</span>
                                    </li>
                                    {/*<li>*/}
                                    {/*    <Form.Item className="ro-cont">*/}
                                    {/*        {getFieldDecorator(`investInMoney`, {*/}
                                    {/*            initialValue:'',*/}
                                    {/*            rules: [*/}
                                    {/*                {*/}
                                    {/*                    required: true,*/}
                                    {/*                    pattern:new RegExp(/^[0-9]\d*$/,"g"),*/}
                                    {/*                    message: `请输入0或正整数(元)`,*/}
                                    {/*                },*/}
                                    {/*            ],*/}
                                    {/*        })*/}
                                    {/*        (<InputNumber min={0} max={999999999}/>)*/}
                                    {/*        }*/}
                                    {/*    </Form.Item>*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
                        </div>
                        {canEdit ? <div className="bottom_but">
                            {/*<Button>重置</Button>*/}
                            <Button onClick={this.save} type="primary">保存</Button>
                        </div> : ""}

                    </div>
                );
            }
        }
    )
);
