import React, { Component } from "react";
import { Input, Form } from "antd";

const FormItem = Form.Item;

class Forms extends React.Component {
  state = {
    file: null,
  };

  componentDidMount() {
    const { editObj } = this.props;

    if (Object.keys(editObj || {}).length !== 0) {
      // 因为使用了自定义表单验证， 所以不能出现多余的字段，并且改变一下原数据 加一个空字符串

      this.props.form.setFieldsValue({
        laboratoryName: editObj?.laboratoryName + '',
        yearSchoolPeriod: editObj?.yearSchoolPeriod + '',
        yearSocialPeriod: editObj?.yearSocialPeriod + '',
        yearApplyPeriod: editObj?.yearApplyPeriod + '',
        yearMaxPeriod: editObj?.yearMaxPeriod + '',
      });
    } else {
      this.props.form.resetFields();
    }
  }

  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };

  validateNoChinese = (_, value, callback) => {
    // // 只能输入数字和一位小数点。
    // const reg = /^\d+$|^\d*\.\d+$/g;
    // // 判断小数点有没有在第一位
    // const startValue = value?.split('.')

    // 只能输入正整数
    const reg = /^[0-9]*[1-9][0-9]*$/g;

    if(!value){
      // callback('请填写年度在校生计划实训学时');
    } else if (value && !reg.test(value)) {
      callback('只能输入正整数');
    } else if (value && value.length > 10) {
      callback('字数不能超过10个');
    } else {
      callback();
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 10 },
    };

    return (
      <Form {...layout}>
        <FormItem label="实训室名称">
          {getFieldDecorator("laboratoryName", {
            rules: [
              {
                required: true,
                message: "请填写实训室名称",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度在校生计划实训学时">
          {getFieldDecorator("yearSchoolPeriod", {
            rules: [
              {
                validator: this.validateNoChinese
              },
              {
                required: true,
                message: "请填写年度在校生计划实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度社会人员计划实训学时">
          {getFieldDecorator("yearSocialPeriod", {
            rules: [
              {
                validator: this.validateNoChinese
              },
              {
                required: true,
                message: "请填写年度社会人员计划实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度计划外申请实训学时">
          {getFieldDecorator("yearApplyPeriod", {
            rules: [
              {
                validator: this.validateNoChinese
              },
              {
                required: true,
                message: "请填写年度计划外申请实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="年度最大实训学时">
          {getFieldDecorator("yearMaxPeriod", {
            rules: [
              {
                validator: this.validateNoChinese
              },
              {
                required: true,
                message: "年度最大实训学时",
              },
            ],
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
