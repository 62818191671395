// 导航
import React, {Component} from 'react';
import './index.scss';
import '../../../reset_style/index.less';
import {Button, Form, Input, InputNumber, message, Modal, Spin, Table, Tooltip} from 'antd';
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
// import {TianJia} from "../../iconSvg";
import {getCanEdit} from "../../../until/cookie";
import {TianJia, Upicon, Upicondele, Upiconwc} from "../../iconSvg";
import moment from "moment";
import {uploadFile} from "../../../api/uploadFile";
import {
    addResources,
    deleteServiceResources,
    getResourcesDataList,
    selectServiceResourcesById,
    updateFactor,
    updateServiceResources,
} from '../../../api/target'
import {BaseUrl} from "../../../config/common";
import FileLook from "../../../page/components/file_look";
import {isEmpty} from "../../../until/isEmpty";


function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

@withRouter
class PlatformService_2_2_1_input extends Component {
    state = {
        data_2_2_1: {},
    };

    componentDidMount() {
    }


    UNSAFE_componentWillReceiveProps(props) {
        const {data_2_2_1} = this.state
        if (props.data_2_2_1.id !== data_2_2_1.id) {
            this.setState({
                data_2_2_1: props.data_2_2_1
            }, (value) => {
                this.props.form.resetFields()
                this.props.form.setFieldsValue({
                    vocationalTrainingNum: props.data_2_2_1.vocationalTrainingNum
                })
            })
        }
    }

    changeValue = (e) => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                this.props.changeValue(e)
            }
        })
    }

    saveFactor1 = (factorContent) => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                this.props.updateVocationalTrainingNum(value.vocationalTrainingNum)
            }
        })
    }


    // section render
    render() {
        const canEdit = getCanEdit()

        const {schoolFactorId1} = this.props
        const {
            data_2_2_1,
            pageSize,
            pageNum,
            file,
            showAddJl,
            isShow,
            openUrl,
            isEdit
        } = this.state
        const pageData = (data_2_2_1) ? data_2_2_1.pageData : {}
        // const id = pageData?.id ? pageData.id : ""

        const vocationalTrainingNum = data_2_2_1?.vocationalTrainingNum ? Number(data_2_2_1.vocationalTrainingNum) : 0
        const vocationalTrainingPlanNum = data_2_2_1?.vocationalTrainingPlanNum ? Number(data_2_2_1.vocationalTrainingPlanNum) : 0
        const scale = (((undefined === vocationalTrainingNum || vocationalTrainingNum === 0) ? 0 : vocationalTrainingPlanNum / vocationalTrainingNum) * 100.00).toFixed(2)
        const total = pageData?.total ? pageData.total : 0

        const labelWidth = 110
        const {getFieldDecorator} = this.props.form;

        console.log("======>",vocationalTrainingNum, vocationalTrainingPlanNum)


        return (
            <div>
                <ul className={'platformService_2_2_1'}
                    style={{marginTop: '0'}}>
                <li>
                    <Form.Item labelCol={{style: {width: labelWidth}}}>

                        <span className={'span1 span2'}>培训方案总数</span>
                        <div className={'btn btn1'}>
                            <div className={'input-wrap'}>
                                {getFieldDecorator('vocationalTrainingNum', {
                                    rules: [
                                        {
                                            pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                            message: `请输入0或正整数`,
                                        }
                                    ],
                                })(
                                    <InputNumber
                                        className={"inputNumber"}
                                        min={vocationalTrainingPlanNum} max={99999999}
                                        onChange={e => {
                                            this.changeValue(e)
                                        }}
                                        // onKeyUp="value=value.replace(/[^\d]/g,'')"
                                        placeholder={'方案数'}
                                        onBlur={() => this.saveFactor1()}/>
                                )}
                            </div>
                        </div>
                    </Form.Item>
                </li>

            </ul>
        </div>
    );
}
}

const PlatformServiceComponent_2_2_1_input = Form.create()(PlatformService_2_2_1_input);
export default PlatformServiceComponent_2_2_1_input;
