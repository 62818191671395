import React from 'react'
import { Modal, Form, Select, Radio, Input , DatePicker, Button} from 'antd'
const { Item: FormItem } = Form
const { Group: RadioGroup } = Radio
const {Option:SelectOption} = Select
const {TextArea} = Input
const {RangePicker} = DatePicker
export default class ModalCmp extends React.Component {
  state = {}
  onClose = () => {
    this.props.closeModal();
  }
  render() {
    let { title, canClose, formItemData, buttons, show } = this.props
    return (
      <Modal visible={show}  closable={canClose} title={title} maskClosable={!canClose} footer={buttons.map(item => (<>{
            buttons.map(btnitem => (<Button type={btnitem.type || 'default'} onClick={btnitem.onClick&&btnitem.onClick.bind(this)}>{btnitem.lable}</Button>))
      }</>))}>
          <Form>
            {
              formItemData.map(item => (<FormItem label={item.lable} required={item.required} hasFeedback>
                {
                  () => {
                    if(item.type = "radio") {
                      return <RadioGroup>
                         {
                           item.data.map(subitem => (<Radio value={subitem.value}>{subitem.lable}</Radio>))
                         }
                      </RadioGroup>
                    } else if(item.type = "select") {
                      return <Select>
                         {
                           item.data.map(subitem => (<SelectOption value={subitem.value}>{subitem.lable}</SelectOption>))
                         }
                      </Select>
                    } else if(item.type = "text") {
                      return <Input />
                    } else if(item.type = "textarea") {
                      return <TextArea></TextArea>
                    } else if(item.type = "date") {
                      return <DatePicker></DatePicker>
                    } else if(item.type = "daterange") {
                      return <RangePicker></RangePicker>
                    }
                    return <span></span>
                  }
                }
              </FormItem>))
            }
          </Form>
      </Modal>
    )
  }
}

ModalCmp.defaultProps = {
  show:false,
  title: '',
  canClose: true,
  formItemData: [],
  buttons: []
}