import React, { Component } from "react";
import { Input, Form, DatePicker } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
const FormItem = Form.Item;
class Forms extends React.Component {
  state = {
    file: null,
  };

  componentDidMount() {
    let { record } = this.props;

    record.megagameTime = moment(record.megagameTime)
    // if (Object.keys(record || {}).length !== 0){
    if (record?.id) {
      this.props.form.setFieldsValue({
        megagameName: record?.megagameName + '',
        megagameTime: record?.megagameTime,
        studentName: record?.studentName + '',
        gradePrize: record?.gradePrize + '',
      });
    } else {
      this.props.form.resetFields();
    }

  }

  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };
  validateNoChinese = (_, value, callback) => {
    const reg = /^\d+$|^\d*\.\d+$/g;
    const startValue = value?.split('.')
    const regEmpty = /^\s*$/g;
    if (!value) {
      callback('');
    } else if (value && regEmpty.test(value)) {
      callback('名称不能为空');
    } else if (value && !reg.test(value) || !startValue[0]) {
      callback('只能输入数字');
    } else {
      callback();
    }
    callback();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <FormItem label="大赛名称">
          {getFieldDecorator("megagameName", {
            rules: [
              {
                required: true,
                message: "请填写大赛名称",
              },
            ],
          })(<Input placeholder="请输入大赛名称" maxLength={30} suffix={<span className='len'>
            {this.props.form.getFieldValue(`megagameName`) ? this.props.form.getFieldValue(`megagameName`).length : 0}/30</span>} />)}
        </FormItem>
        <FormItem label="大赛时间">
          {getFieldDecorator("megagameTime", {
            rules: [
              {
                required: true,
                message: "请填写大赛时间",
              },
            ],
          })(<DatePicker placeholder={'请选择上线/更新时间'} disabledDate={currentDate => !currentDate.isSameOrBefore(moment().locale('zh'))} format="YYYY-MM-DD HH:mm"
            showTime />)}
        </FormItem>
        <FormItem label="获奖学生姓名">
          {getFieldDecorator("studentName", {
            rules: [
              {
                required: true,
                message: "请填写获奖学生姓名",
              },
            ],
          })(<Input placeholder="请输入获奖学生姓名" maxLength={30} suffix={<span className='len'>
            {this.props.form.getFieldValue(`studentName`) ? this.props.form.getFieldValue(`studentName`).length : 0}/30</span>} />)}
        </FormItem>
        <FormItem label="获奖等级">
          {getFieldDecorator("gradePrize", {
            rules: [
              {
                required: true,
                message: "请填写获奖等级",
              },
              {
                validator: this.validateNoChinese
              }
            ],
          })(<Input placeholder="请填写获奖等级" />)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
