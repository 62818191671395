import React, {Component} from 'react'
import {Input,Form} from "antd";

class AddPlatforMsg extends React.Component {
    state = {
        platformName:'',
        platformProject:'',
        platformURL:''
    }

    componentDidMount() {
        this.props.onRef(this)
        const {editPlanFormInfo} = this.props
        this.setState({
            targetId:this.props.targetId,
        })
        this.setState({
            platformName: editPlanFormInfo.platformName,
            platformProject: editPlanFormInfo.platformBuild,
            platformURL: editPlanFormInfo.platformAddress
        })
        if (this.props.editPlantFormFlag === false){
            this.props.form.setFieldsValue({
                platformName:'',
                platformProject:'',
                platformURL:''
            })
        }
    }

    handleCancel = () =>{
        this.setState({
            addCertificateModal:false,
        })
    }

    clearForm = () =>{
        this.setState({
            platformName:'',
            platformProject:'',
            platformURL:''
        })
        this.props.form.resetFields();
    }


    render() {
        const {platformName,platformProject,platformURL} = this.state
        const {getFieldDecorator} = this.props.form;
        return (
            <div className='AddPlatforMsg'>
                <Form>
                    <Form.Item label="平台名称" >
                        {
                            getFieldDecorator('platformName', {
                                initialValue: platformName,
                                rules: [
                                    {
                                        required: true,
                                        message: '平台名称不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容' maxLength={30} className="input_in" suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`platformName`) ? this.props.form.getFieldValue(`platformName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="平台建设方" >
                        {
                            getFieldDecorator('platformProject', {
                                initialValue: platformProject,
                                rules: [
                                    {
                                        required: true,
                                        message: '平台建设方不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容'  maxLength={30} className="input_in" suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`platformProject`) ? this.props.form.getFieldValue(`platformProject`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="平台地址" >
                        {
                            getFieldDecorator('platformURL', {
                                initialValue: platformURL,
                                rules: [
                                    {
                                        required: true,
                                        message: '平台地址不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入内容'  maxLength={30} className="input_in" suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`platformURL`) ? this.props.form.getFieldValue(`platformURL`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                </Form>
            </div>
        )
    }
}


AddPlatforMsg = Form.create()(AddPlatforMsg);
export default AddPlatforMsg;
