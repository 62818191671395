// 项目情况-平台情况
import React from "react";
import {Form, Row, Col, Radio, Card, Button, Input, message} from 'antd';
import "./index.scss";
import {
    QyLogo,
    QyBackground,
    Iconrws,
    Iconrwsxg,
    Iconrwsyl,
    Iconwj,
    Iconsjzb,
    Iconndhb,
    Iconscbg,
    Icontbjsal,
    Iconfrlxra,
    Iconjxbg
} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {getTaskBookInfo, updateTaskBook, updateTaskBookInfo} from "../../../api/taskBook"
import Cookies from "js-cookie";

export default Form.create()(
    withRouter(
        class JibenXinxi extends React.Component {
            state = {
                taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : ""
            };

            componentDidMount() {
                this.getTaskBookInfo()
            }

            getTaskBookInfo = () => {
                let data = {
                    taskId: this.state.taskId
                }
                getTaskBookInfo(data).then(res => {
                    if (res) {
                        this.props.form.setFieldsValue({
                            baseName: res.baseName,
                            baseArea: res.baseArea,
                            baseEquipmentMoney: res.baseEquipmentMoney,
                            baseWorkstation: res.baseWorkstation,
                        })
                    }
                })
            }

            // 基本信息的保存
            saveBaseInfoRequest = () => {
                this.props.form.validateFields((err, value) => {
                    if (!err) {
                        let data = {
                            id: this.state.taskId,
                            baseName: value.baseName,
                            baseArea: value.baseArea,
                            baseEquipmentMoney: value.baseEquipmentMoney,
                            baseWorkstation: value.baseWorkstation
                        }
                        updateTaskBookInfo(data).then(res => {
                            message.success("保存成功")
                            if (res) {
                                this.props.qiehuancd(1)
                            }
                        })
                    }
                })
            }


            preStep = () => {
                this.props.history.push({
                    pathname: '/project_school',
                })
            }

            nextStep = () => {
                this.props.form.validateFields((err, value) => {
                    if (!err) {
                        let data = {
                            id: this.state.taskId,
                            baseName: value.baseName,
                            baseArea: value.baseArea,
                            baseEquipmentMoney: value.baseEquipmentMoney,
                            baseWorkstation: value.baseWorkstation
                        }
                        updateTaskBookInfo(data).then(res => {
                            if (res) {
                                this.props.qiehuancd(1)
                            }
                        })
                    }
                })
            }
            // section 重置 基本信息
            resetForm = () => {
                // console.log("重置");

                this.setState({
                    baseName: "",
                    baseArea: 0,
                    baseEquipmentMoney: 0,
                    baseWorkstation: 0
                }, () => {
                    this.props.form.resetFields();
                })
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {} = this.state;
                return (
                    <div className="jiben_Xinxi">
                        <h3 className={'small-title'}
                            style={{fontSize:'16px'}}>
                            2-2-1 基本信息
                        </h3>
                        <Form>
                            <div>
                                <Form.Item className="Item-li Item-li1" label={`基地名称`}>
                                    {getFieldDecorator('baseName', {
                                        rules: [
                                            {
                                                required: true,
                                                message: `请填写基地名称`,
                                            },
                                            {
                                                max: 50,
                                                message: "文本过长，最多50字"
                                            },
                                        ],
                                    })(<Input className="input" maxLength={50}/>)}
                                </Form.Item>
                                <Form.Item className="Item-li Item-li2 Item-li3" label={`总使用面积（㎡）`}>
                                    {getFieldDecorator('baseArea', {
                                        rules: [
                                            {
                                                required: true,
                                                message: `总使用面积`,
                                            },
                                            {
                                                message: '请输入整数值',
                                                pattern: /(^[1-9]\d*$)/,
                                            }
                                        ],
                                    })(<Input className="input" placeholder={'请输入整数值'}/>)}
                                </Form.Item>
                                <Form.Item className="Item-li Item-li2 Item-li4" label={`教学仪器设备总值（万元）`}>
                                    {getFieldDecorator('baseEquipmentMoney', {
                                        rules: [
                                            {
                                                required: true,
                                                message: `教学仪器设备总值`,
                                            },
                                            {
                                                message: '请输入整数值',
                                                pattern: /(^[1-9]\d*$)/,
                                            }
                                        ],
                                    })(<Input className="input"/>)}
                                </Form.Item>
                                <Form.Item className="Item-li Item-li2 Item-li5" label={`工位数`}>
                                    {getFieldDecorator('baseWorkstation', {
                                        rules: [
                                            {
                                                required: true,
                                                message: `工位数`,
                                            },
                                            {
                                                message: '请输入整数值',
                                                pattern: /(^[1-9]\d*$)/,
                                            }
                                        ],
                                    })(<Input className="input"/>)}
                                </Form.Item>
                            </div>
                        </Form>

                        <div className="bottom-but">
                            <Button className="but-li"
                                // disabled={ifComplete === true && ifEdit === false}
                                    onClick={() => this.resetForm()}>
                                重置
                            </Button>

                            <Button className="but-li"
                                    onClick={this.saveBaseInfoRequest} type="primary" htmlType="submit">
                                保存
                            </Button>
                        </div>
                    </div>
                );
            }
        }
    )
);
