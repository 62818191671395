// 导航
import React, {Component} from 'react';
import './index.scss';
import {Button, Form, Input, InputNumber, message, Modal, Spin, Table, Tooltip} from 'antd';
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
// import {TianJia} from "../../iconSvg";
import {getCanEdit} from "../../../until/cookie";
import {TianJia, Upicon, Upicondele, Upiconwc} from "../../iconSvg";
import moment from "moment";
import {uploadFile} from "../../../api/uploadFile";
import {
    addResources,
    deleteServiceResources,
    getResourcesDataList,
    selectServiceResourcesById,
    updateFactor,
    updateServiceResources,
} from '../../../api/target'
import {BaseUrl} from "../../../config/common";
import FileLook from "../../../page/components/file_look";
import {isEmpty} from "../../../until/isEmpty";
import PlatformServiceComponent_2_2_1_input from "./index_input";

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

@withRouter
class PlatformService_2_2_1 extends Component {
    state = {
        data_2_2_1: {},
        pageNum: 1,//页数
        pageSize: 10,//条数
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        currentEditRecourseId: "",
        // 新增的文件表的id,最后给到了文件的id,字段上其实是有歧义的,但是逻辑没问题
        currentEditEvidenceId: "",

        isEdit: false,
        isLoadingFile: false,

        userInfo: Cookies.get("userInfo") ? JSON.parse(decodeURI(Cookies.get("userInfo"))) : {},
        saveStatus: true
    };

    componentDidMount() {
        this.setState({
            navName: this.props.navName,
        });
    }


    UNSAFE_componentWillReceiveProps(props) {
        const {data_2_2_1} = this.state
        if (props.data_2_2_1.id !== data_2_2_1.id) {
            this.setState({
                data_2_2_1: props.data_2_2_1
            })
        }
    }


    // section Action
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.get_2_2_1_listData()
        });
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageNum: 1, pageSize}, this.get_2_2_1_listData);
    }

    handleCancel = () => {
        this.setState({showAddJl: false});
    };

    onchangePersonNum1 = (value) => {
        const {data_2_2_1} = this.state
        const vocationalTrainingPlanNum = data_2_2_1?.vocationalTrainingPlanNum ? data_2_2_1.vocationalTrainingPlanNum : 0

        let newText = parseInt(value)
        if (undefined === newText || newText === "" || typeof newText !== "number") {
            newText = 0
        } else if (newText < vocationalTrainingPlanNum) {
            newText = vocationalTrainingPlanNum
        }
        console.log(newText)
        data_2_2_1.vocationalTrainingNum = newText
        this.setState({data_2_2_1: data_2_2_1})
    }

    // 查看资料
    handLook = (record) => {
        const url = BaseUrl + record.fileId

        this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})


    }
    //删除前二次确认
    deleteAsk = (id) => {
        let data = {
            id: id
        }
        deleteServiceResources(data).then(e => {
            this.setState({
                pageNum: 1
            }, () => {
                message.success("删除成功")
                this.get_2_2_1_listData()
            })
        })
    }

    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }

    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: '', resourceName: ''});
        this.setState({
            file: null,
        }, () => {
            this.props.form.setFieldsValue({'cailiao': ''})
        })
    }

    // section Request
    // 获取类表数据
    get_2_2_1_listData = () => {

        let data = {
            id: this.state.data_2_2_1.id,
            pageNum: this.state.pageNum,
            pageSize: this.state.pageSize,
        }
        getResourcesDataList(data).then((res) => {
            let data_2_2_1 = this.state.data_2_2_1
            data_2_2_1.pageData = res.pageData;
            data_2_2_1.vocationalTrainingNum = res.vocationalTrainingNum
            data_2_2_1.vocationalTrainingPlanNum = res.vocationalTrainingPlanNum
            this.setState({
                data_2_2_1: data_2_2_1
            })
        })

    }

    // 添加提交
    addAndEdit = () => {

        this.props.form.validateFields((err, value) => {

            // 单条文件的内容
            const evidenceQo = {
                fileId: this.state.file.fileId,
                resourceName: this.state.file.originName,
                resourceSize: this.state.file.fileSize,
                resourceSuffix: this.state.file.fileSuffix
            }
            // 编辑状态下才传表的id
            if (this.state.isEdit === true) {
                evidenceQo.id = this.state.currentEditEvidenceId
            }
            if (!err) {
                const {file} = this.state
                if (!file || isEmpty(file.fileId)) {
                    message.info("文件上传中")
                    return
                }

                let {schoolEduCode} = this.state;
                let targetId = this.state.data_2_2_1.id;
                let id = this.state.currentEditRecourseId;
                let data = {
                    schoolEduCode: schoolEduCode,
                    targetId: targetId,
                    resourcesName: value.name,
                    theProfessional: value.theProfessional,
                    id: id,
                    evidenceQo
                };
                if (this.state.isEdit === false) {
                    addResources(data).then(e => {
                        this.setState(
                            {pageNum: 1, showAddJl: false, currentEditRecourseId: ""}, () => {
                                this.get_2_2_1_listData()
                                message.success('添加成功');
                            })
                    })
                } else {
                    updateServiceResources(data).then(e => {
                        this.setState({showAddJl: false, currentEditRecourseId: ""})
                        this.get_2_2_1_listData()
                        message.success('修改成功');
                    })
                }
            }
        })
    }

    edit = (record) => {
        const id = record.id
        this.setState({
            showAddJl: true,
            isEdit: true,
            currentEditRecourseId: id,
            currentEditEvidenceId: record.evidenceId
        })

        let data = {
            id: id
        }
        // 编辑又刷新是干嘛了?
        selectServiceResourcesById(data).then(e => {
            if (e.fileId) {
                let data = {
                    resourceId: e.resourceId,
                    originName: e.resourceName,
                    fileId: e.fileId,
                    id: e.id
                }
                this.setState({
                    file: data
                })
            } else {
                this.setState({
                    file: ''
                })
            }
            this.props.form.setFieldsValue({
                'name': e.name,
                'theProfessional': e.theProfessional,
                'cailiao': this.state.file
            })
        })
    }

    //添加提交继续添加
    addContinue = (id) => {

        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {file} = this.state
                if (!file || isEmpty(file.fileId)) {
                    message.info("文件上传中")
                    return
                }

                let {schoolEduCode} = this.state
                let targetId = this.state.data_2_2_1.id
                let id = this.state.currentEditRecourseId
                let data = {
                    schoolEduCode: schoolEduCode,
                    targetId: targetId,
                    resourcesName: value.name,
                    theProfessional: value.theProfessional,
                    id: id,
                    evidenceQo: {
                        fileId: this.state.file.fileId,
                        resourceName: this.state.file.originName,
                        resourceSize: this.state.file.fileSize,
                        resourceSuffix: this.state.file.fileSuffix,
                        id: this.state.file.id
                    }
                }
                addResources(data).then(e => {
                    message.success('添加成功');
                    this.props.form.resetFields();
                    this.props.form.setFieldsValue({'cailiao': ''})
                    this.setState({
                        file: '',
                        currentEditRecourseId: ""
                    })
                    this.get_2_2_1_listData()
                })
            }
        })
    }

    // section 上传
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        // if ((tableData.length+list.length+fileNumber) > 20){
        //     message.error("备课包资源数量不能超过20")
        //     return;
        // }
        let tableData = this.state.tableData || []
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                this.setState({
                    file: upFile
                })
                flag = true
                return;
            }
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            let fileId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            fileId = `zjyd-space-front-geren/${ymd}/${fileId}`
            k["fileId"] = fileId
            if (!tableData.find((v) => v.resourceName === k.name)) {
                tableData.push({
                    // fileId: fileId,
                    resourceName: k.name,
                    resourceFormat: fileType,
                    resourceSize: (k.size / 1024).toFixed(2),
                    operationTime: transTime(k.lastModifiedDate),
                    createTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    plan: 0,
                    lastModified: k.lastModified
                });
            }
        });
        if (flag) {
            return;
        }
        this.setState({
            tableData: tableData,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
        this.setState({
            inputValue: ""
        })
    };
    //上传文件
    uploadFile = async (v) => {
        const {fileType} = this.state
        if (!v) return;
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        id: res.id,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    // console.log('res----------------', res)
                    this.clearFile(v);
                    this.getResult(res);
                    reslove(1);
                }
            })
        });
    };
    //上传完成获取返回结果过
    getResult = (val) => {
        // console.log('res------------',val)
        let {tableData} = this.state;
        // console.log("tableData",tableData)
        tableData.forEach(item => {
            if (item.originName === val.originFileName) {
                item.originName = val.originFileName
                item.fileId = val.fpName
                item.fileSuffix = val.mediaType
                item.coursewareId = val.id
                item.fileSize = (val.fileSize / 1024).toFixed(2)
            }
        })
        this.setState({tableData}, () => {
            console.log('上传后的数据：', this.state.tableData)
        })
    };
    //上传完成
    clearFile = (file) => {
        if (!this.state.showAddJl) {
            this.setState({
                file: ""
            })
            return;
        }
        if (!file) return;
        //小于100kb的文件不支持分片上传，等返回上传成功结果后直接调整进度未100%
        let {tableData} = this.state;
        // console.log(tableData);
        // console.log("888888*********")
        let item = tableData.find((v) => v.originName === file.name);
        // item.plan = 1;
        this.setState({tableData});
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        delete opt[file.name];
        localStorage.setItem("uploadList", JSON.stringify(opt));
        document.getElementById('file').value = null;
        // console.log("上传完成");
        // console.log("tableData", this.state.tableData)
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "mp3、wav、wmv、doc/docx、xls/xlsx、ppt/pptx、pdf、txt、mp4、avi、rm/rmvb、mov、wmv、swf、flv、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    saveFactor1 = (factorContent) => {
        let id = this.state.data_2_2_1?.factorId

        if (isEmpty(factorContent)) {
            message.error('职业培训方案数不能为空');
            return
        }
        let data = {
            id: id,
            factorContent: factorContent
        }
        updateFactor(data).then(e => {
            if (e) {
            }
        })
    }

    // section render
    render() {
        const canEdit = getCanEdit()

        const {schoolFactorId1} = this.props
        const {
            data_2_2_1,
            pageSize,
            pageNum,
            file,
            showAddJl,
            isShow,
            openUrl,
            isEdit
        } = this.state
        const pageData = (data_2_2_1) ? data_2_2_1.pageData : {}
        const tableData = (pageData?.data) ? pageData.data : []

        // const id = pageData?.id ? pageData.id : ""

        // 虚拟仿真类方案数
        const vocationalTrainingPlanNum = data_2_2_1?.vocationalTrainingPlanNum ? Number(data_2_2_1.vocationalTrainingPlanNum) : 0
        // 培训方案总数(输入的地方, 如果是空, 则为vocationalTrainingPlanNum)
        const vocationalTrainingNum = data_2_2_1?.vocationalTrainingNum ? Number(data_2_2_1.vocationalTrainingNum) : vocationalTrainingPlanNum
        const scale = (((undefined === vocationalTrainingNum || vocationalTrainingNum === 0) ? 0 : vocationalTrainingPlanNum / vocationalTrainingNum) * 100.00).toFixed(2)
        const total = pageData?.total ? pageData.total : 0

        const labelWidth = 110
        const {getFieldDecorator} = this.props.form;
        // section head
        const inputSection =
            <div>
                <ul className={'platformService_2_2_1'}>
                    <li>
                        <PlatformServiceComponent_2_2_1_input
                            data_2_2_1={data_2_2_1}
                            changeValue={this.onchangePersonNum1}
                            updateVocationalTrainingNum={this.saveFactor1}
                        />
                    </li>
                    <li>
                        <span className={'span1 span2'}>虚拟仿真类方案数</span>
                        <div className={'btn'}>{vocationalTrainingPlanNum}</div>
                    </li>

                    <li>
                        <span className={'span1 span2'}>占比</span>
                        <div
                            className={'btn'}>
                            {scale}%
                        </div>
                        <span className={'span6'} hidden={tableData.length <=0 || scale >= 30}>*该项指标需达30%</span>
                    </li>
                </ul>
                {canEdit ?

                    <span className={'btn_group'}>

                        <div style={{color: '#D75746', marginBottom:'8px'}}>*仅添加虚拟仿真实训优势优化完善的职业培训方案</div>

                    <Button type={'primary'} className="icon" onClick={() => this.setState({
                        showAddJl: true,
                        isEdit: false,
                        file: ''
                    })}><TianJia/>添加</Button>
                        {/*<Button type={'primary'}><Input id='importFile' type="file"*/}
                        {/*                                accept="image"*/}
                        {/*                                onClick={(event) => {*/}
                        {/*                                    event.target.value = null*/}
                        {/*                                }}*/}
                        {/*                                onChange={(e) => this.importPlatformService(e, this.state.targetId1)}/><ExcelImport/>excel导入</Button>*/}
                        {/*<Button type={'primary'}*/}
                        {/*        onClick={() => this.dowLoadFile(this.state.targetMark1, this.state.targetName1)}><Daoru/>下载导入模版</Button>*/}
                </span> : ""}

            </div>
        // section table columns1
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{pageSize * (pageNum - 1) + index + 1}</span>
                }
            },
            {
                title: '人才方案名称',
                dataIndex: 'name',
                key: 'name',
                align: 'center',
                width: 150
            },
            {
                title: '所属专业',
                dataIndex: 'theProfessional',
                key: 'theProfessional',
                align: 'center',
                width: 150
            },
            {
                title: '作证材料',
                dataIndex: 'resourceName',
                key: 'resourceName',
                align: 'center',
                width: 150,
                render: (text, record) => record.evidenceId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            }
        ]
        if (canEdit) columns1.push({
            title: '操作',
            dataIndex: '',
            key: '',
            align: 'center',
            width: 200,
            render: (text, record) => <div>
                    <span className={'cz'} onClick={() => {
                        this.edit(record)
                    }}>编辑</span>
                <span className={'cz'} onClick={() => this.deleteAsk(record.id)}>删除</span>
            </div>

        })

        // {
        //     "id": "fd81707ed70f4b3ab361bc9d03af21d4",
        //     "name": "12121",
        //     "theProfessional": "",
        //     "resourcesPeriod": 111,
        //     "useNumber": 121,
        //     "evidenceId": "fe17019848728908ae5f03c03e51601e",
        //     "transStatus": null,
        //     "fileId": "vb/oth/image/20210902/d1898cdab4ef4183a57e0fdf1b42a7bb.jpg"
        // }

        // section table
        const table = <Table columns={columns1}
                             dataSource={tableData}
                             className={'table1'}
                             bordered={true}
                             locale={{
                                 emptyText: <>
                                     <div className="Empty-content">
                                         <div className="text">
                                             <img
                                                 src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                             <span>暂无数据</span>
                                         </div>
                                     </div>
                                 </>
                             }}
                             pagination={
                                 {
                                     total: total,
                                     pageSize: pageSize,
                                     current: pageNum,
                                     onChange: this.pageChange,
                                     onShowSizeChange: this.onShowSizeChange,
                                     pageSizeOptions: ['10', '20', '30', '40'],
                                     showSizeChanger: true,
                                     // position:'bottomRight'
                                 }
                             }
        />

        // section 添/修 Modal
        const modal = <Modal
            title={isEdit === false ? '添加' : '编辑'}
            visible={showAddJl}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            className={'addAddJlModal'}
            wrapClassName="wappop_up"
            footer={
                <div className='footer-btn'>
                    <Button className={'quxiao'}
                            onClick={() => this.handleCancel()}>取消</Button>
                    <Button type='primary'
                            onClick={() => this.addAndEdit()}>提交</Button>
                    {isEdit === false ? <Button type='primary'
                                                onClick={() => this.addContinue()}>提交并继续添加</Button> : ''}
                </div>
            }
        >
            <Form>
                <Form.Item label={'人才方案名称'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('name', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写人才方案名称`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入人才方案名称' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`name`) ? this.props.form.getFieldValue(`name`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item label={'所属专业'} labelCol={{style: {width: labelWidth}}}>
                    <div className={'input-wrap-have-count-limit input-wrap'}>
                        {getFieldDecorator('theProfessional', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写所属专业`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入所属专业' maxLength={30}
                                   suffix={<span
                                       className='len'>{this.props.form.getFieldValue(`theProfessional`) ? this.props.form.getFieldValue(`theProfessional`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>

                <Form.Item className="li_formup" label={'佐证材料'} labelCol={{style: {width: labelWidth}}}>
                    {getFieldDecorator('cailiao', {
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: `请选择佐证材料`,
                            },
                        ],
                    })(
                        <div className="up_button">
                            <ul>
                                <li>
                                    <div className="span_right">
                                        <label htmlFor="file" className="inputlabelBox">
                                            <span><Upicon/>上传资料</span>
                                        </label>
                                        <input
                                            type="file"
                                            accept="image"
                                            onChange={this.multipartUpload}
                                            name="myfile"
                                            id="file"
                                            style={{display: "none"}}
                                        />
                                        <span className={'tip'}>支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式</span>
                                    </div>
                                </li>
                                <li>
                                    <div className="bottom_tit">
                                        {file ? <Tooltip placement="topLeft"
                                                         title={file.originName}>
                                            <li className="title_de">{file.fileId ?
                                                <Upiconwc/> : <Spin/>}<span
                                                className="tie_span">{file.originName}</span><i
                                                className="Upicona"
                                                onClick={this.deleteFile}><Upicondele/></i></li>
                                        </Tooltip> : null}
                                        {/*上传中*/}
                                        {/*{file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}*/}
                                        {/*已完成*/}
                                        {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    )}
                </Form.Item>
            </Form>
        </Modal>
        return (
            <div className={'cont1'}>
                <div className={'titleName'}>2.2.1 结合虚拟仿真实训优势优化完善的职业培训方案占比</div>
                {inputSection}
                {table}
                {modal}

                {/*查看资料*/}
                <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}/>

            </div>
        );
    }
}

const PlatformServiceComponent_2_2_1 = Form.create()(PlatformService_2_2_1);
export default PlatformServiceComponent_2_2_1;
