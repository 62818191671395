// 导航
import React, {Component} from 'react';
import './index.scss';
import '../../../reset_style/index.less';
import {Modal, Button, Row, Form, Col, Input, message, InputNumber, Table, Tooltip, Spin, Select} from 'antd';
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import {updatePassword} from '../../../api/login';
import RenderButton from '../../../components/renderButton/index'
import md5 from "js-md5"
import {deleteTargetResourceCourse} from "../../../api/targetResource";
// import {TianJia} from "../../iconSvg";
import {getCanEdit} from "../../../until/cookie";
import {Logo, WenxinTip, TianJia, Upicon, Upiconwc, Upicondele} from "../../iconSvg";
import moment from "moment";
import {uploadFile} from "../../../api/uploadFile";
import {
    addResources,
    checkPlatformService,
    deleteServiceResources,
    importPlatformService,
    saveSatisfaction,
    selectAllResourcesService,
    selectSatisfaction,
    selectServiceResourcesById,
    updateFactor,
    updateServiceResources
} from '../../../api/target'

@withRouter
class PlatformService_2_2_5 extends Component {
    state = {

        navName: "",
        ispassword: false,
        userInfo: Cookies.get("userInfo") ? JSON.parse(decodeURI(Cookies.get("userInfo"))) : {},
        saveStatus: true
    };

    componentDidMount() {
        this.setState({
            navName: this.props.navName,
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        const {data_2_2_5} = this.state
        if (undefined !== props.data_2_2_5?.id) {
            this.setState({
                data_2_2_5: props.data_2_2_5
            })
        }
    }

    // section Action
    handleCancel = () => {
        this.setState({showAddJl: false});
    };

    onchangePersonNum1 = (e) => {
        this.setState({studentNum1: e})
    }

    render() {
        const canEdit = getCanEdit()

        const {
            data_2_2_5
        } = this.state

        const {data_2_2_4_have_data} = this.props

        const examinationNum = data_2_2_5?.examinationNum ? Number(data_2_2_5.examinationNum) : 0
        const examinationAdoptNum = data_2_2_5?.examinationAdoptNum ? Number(data_2_2_5.examinationAdoptNum) : 0
        const scale = (((undefined === examinationNum || examinationNum === 0 ) ? 0 : (examinationAdoptNum / examinationNum)) * 100).toFixed(2)
        // section head
        const inputSection =
            <div>
                <ul className={'platformService_2_2_1'}>
                    <li>
                        <span className={'span1 span2'}>考试人数</span>
                        <div className={'btn'}>{examinationNum}</div>
                    </li>
                    <li>
                        <span className={'span1 span2'}>考试通过人数</span>
                        <div className={'btn'}>{examinationAdoptNum}</div>
                    </li>
                    <li>
                        <span className={'span1 span2'}>占比</span>
                        <div className={'btn'}>{scale}%</div>
                        <span className={'span6'} hidden={!data_2_2_4_have_data || scale >= 30}>*该项指标需达30%</span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <div style={{color:'rgba(215, 87, 71, 1)'}}>*计算结果来自2.2.4中表格数据</div>
                    </li>

                </ul>
            </div>

        return (
            <div className={'cont1'}>
                <div className={'titleName'}>2.2.5 虚拟仿真实训服务职业技能等级鉴定考试通过人数占比</div>
                {inputSection}
            </div>
        );
    }
}

const PlatformServiceComponent_2_2_5 = Form.create()(PlatformService_2_2_5);
export default PlatformServiceComponent_2_2_5;
