import { post } from '../../until/axios_instans';
let url = window.apiShixun + '/api/v1/domesticMarketing/';
// 获取国内推广总数据
export function selectDomesticMarketing(data) {
    return post(url+'selectDomesticMarketing', data);
}
// 获取行业产业
export function selectTrade(data) {
    return post(url+'selectTrade', data);
}
// 删除行业产业
export function deleteTrade(data) {
    return post(url+'deleteTrade', data);
}
// 修改行业产业
export function updateTrade(data) {
    return post(url+'updateTrade', data);
}
// 新增行业产业
export function addTrade(data) {
    return post(url+'addTrade', data);
}
// 获取行业产业详情
export function tradeDetail(data) {
    return post(url+'tradeDetail', data);
}
// 获取资源
export function selectResources(data) {
    return post(url+'selectResources', data);
}
// 删除资源
export function deleteResources(data) {
    return post(url+'deleteResources', data);
}
// 修改资源
export function updateResources(data) {
    return post(url+'updateResources', data);
}
// 新增资源
export function addResources(data) {
    return post(url+'addResources', data);
}
// 获取资源详情
export function resourcesDetail(data) {
    return post(url+'resourcesDetail', data);
}
// 修改资源条件
export function updateResourcesCondition(data) {
    return post(url+'updateResourcesCondition', data);
}
// 获取大赛
export function selectCompetition(data) {
    return post(url+'selectCompetition', data);
}
// 删除大赛
export function deleteCompetition(data) {
    return post(url+'deleteCompetition', data);
}
// 修改大赛
export function updateCompetition(data) {
    return post(url+'updateCompetition', data);
}
// 新增大赛
export function addCompetition(data) {
    return post(url+'addCompetition', data);
}
// 获取大赛详情
export function competitionDetail(data) {
    return post(url+'competitionDetail', data);
}
// 获取活动
export function selectActivity(data) {
    return post(url+'selectActivity', data);
}
// 删除活动
export function deleteActivity(data) {
    return post(url+'deleteActivity', data);
}
// 修改活动
export function updateActivity(data) {
    return post(url+'updateActivity', data);
}
// 新增活动
export function addActivity(data) {
    return post(url+'addActivity', data);
}
// 获取活动详情
export function activityDetail(data) {
    return post(url+'activityDetail', data);
}
// 导入-指标-国内推广-行业产业
export function checkImportTargetExtensionTrade(data) {
    return post(url+'checkImportTargetExtensionTrade', data);
}
// 导入-指标-国内推广-活动
export function checkImportTargetExtensionActivity(data) {
    return post(url+'checkImportTargetExtensionActivity', data);
}
// 导入-指标-国内推广-大赛
export function checkImportTargetExtensionCompetition(data) {
    return post(url+'checkImportTargetExtensionCompetition', data);
}
// 导入-指标-国内推广-资源
export function checkImportTargetExtensionResources(data) {
    return post(url+'checkImportTargetExtensionResources', data);
}

// 获取专业下拉
export function getSharingIndustryList(data) {
    return post(window.apiShixun + '/api/v1/sharing/getSharingIndustryList', data);
}

