import {post} from '../../until/axios_instans';

// 保存学校基本情况
export function addSchoolInfo(data) {
    return post(window.apiShixun + '/api/v1/school/addSchoolInfo', data);
}

// 查询学校基本条件
export function getBasicCondition(data) {
    return post(window.apiShixun + '/api/v1/school/getBasicCondition', data);
}

// 根据code获取学校信息
export function getSchool(schoolEduCode) {
    return post(window.apiShixun + '/api/v1/school/getSchool', {schoolEduCode});
}

// 获取学校基本情况
export function getSchoolInfos(data) {
    return post(window.apiShixun + '/api/v1/school/getSchoolInfos', data);
}

// 修改学校基本情况
export function updateSchoolInfo(data) {
    return post(window.apiShixun + '/api/v1/school/updateSchoolInfo', data);
}

// ==== 项目联系人
// 查询项目联系人信息列表
export function getLinkManListRequest(data) {
    return post(window.apiShixun + '/api/v1/school/selectProjectContactList', data);
}

// 查询项目联系人信息详情
export function getLinkManInfoRequest(data) {
    return post(window.apiShixun + '/api/v1/school/selectProjectContactById', data);
}

// 添加/编辑项目联系人信息
export function addAndSaveLinkManInfoRequest(data) {
    return post(window.apiShixun + '/api/v1/school/addAndUpdateProjectContact', data);
}

// 删除项目联系人信息
export function deleteLinkManInfoRequest(data) {
    return post(window.apiShixun + '/api/v1/school/deleteProjectContact', data);
}

// ====


// 获取建设方案综述
export function getConstructionPlan(data) {
    return post(window.apiShixun + '/api/v1/constructionPlan/getConstructionPlan', data);
}

// 保存建设方案综述
export function addConstructionPlan(data) {
    return post(window.apiShixun + '/api/v1/constructionPlan/addConstructionPlan', data);
}

// 修改建设方案综述
export function updateConstructionPlan(data) {
    return post(window.apiShixun + '/api/v1/constructionPlan/updateConstructionPlan', data);
}

// 获取项目团队-项目负责人
export function getProjectHeadBasic(data) {
    return post(window.apiShixun + '/api/v1/contactUser/getProjectHeadBasic', data);
}

// 获取团队成员
export function getProjectMember(data) {
    return post(window.apiShixun + '/api/v1/projectMember/getProjectMember', data);
}

// 删除团队成员
export function deleteProjectMember(data) {
    return post(window.apiShixun + '/api/v1/projectMember/deleteProjectMember', data);
}

// 保存团队成员
export function addProjectMember(data) {
    return post(window.apiShixun + '/api/v1/projectMember/addProjectMember', data);
}

// 修改团队成员
export function updateProjectMember(data) {
    return post(window.apiShixun + '/api/v1/projectMember/updateProjectMember', data);
}

// 职务下拉
export function getTechnicalPosition(data) {
    return post(window.apiShixun + '/api/v1/technicalPosition/getTechnicalPosition', data);
}

// 修改项目团队-项目负责人
export function updateProjectHeadBasic(data) {
    return post(window.apiShixun + '/api/v1/contactUser/updateProjectHeadBasic', data);
}






