// 项目情况-平台情况
import React from "react";
import {Button, Col, Form, Input, message, Modal, Row} from 'antd';
import {withRouter} from "react-router-dom";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import Cookies from 'js-cookie';
import "./index.scss";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import {getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType} from "../../until/cookie";
import {changeSelfTarget, getSelfTarget, saveSatisfaction, targetComplete, validData} from "../../api/target";
import UploaderComponent from "../../components/uploader_component";
import {WenxinTip} from "../../components/iconSvg";

const {TextArea} = Input;
export default Form.create()(
    withRouter(
        class SelfTarget extends React.Component {
            state = {
                targetId: getTargetId("self_Target"),
                currentId: getSecondTargetList("self_Target")[0],
                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
                featureSelfDataVo: {
                    "id": "",
                    "selfData": "",
                    "selfId": "",
                    "targetMark": "",
                    "targetName": ""
                },

                // 未完成的个数
                targetNoCompleteList: [],
                showTipModal: false
            };

            componentDidMount() {
                window.scrollTo(0, 0);

                this.getSelfTargetRequest()
            }

            // 上一步
            previous = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }

                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "international_Exchange") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/international_Exchange',
                            })
                            break;
                        }
                    }
                }
            }

            // section 获取内容
            getSelfTargetRequest = () => {
                let targetId = getSecondTargetList("self_Target")[0]
                let data = {
                    "pageNum": 1,
                    "pageSize": 10,
                    "schoolEduCode": this.state.schoolEduCode,
                    "targetId": getTargetId("self_Target")
                }

                getSelfTarget(data).then(result => {
                    console.log("====> 请求后的数据", result)
                    this.setState({
                        featureSelfDataVo: result.featureSelfDataVo
                    }, () => {
                        let objc = {
                            selfTargetData: result?.featureSelfDataVo?.selfData ?? ""
                        }
                        this.props.form.setFieldsValue(objc)
                    })
                })
            }

            // section 提交(等待接口)
            uploadInfo = async () => {
                message.success("提交成功")

                // 添加/更新 自选指标
                let data = {}

                this.targetAllComplete()
            }

            // section 指标完成填报
            targetAllComplete = () => {

                validData(this.state.targetId).then(() => {
                    let {schoolEduCode} = this.state;
                    targetComplete({schoolEduCode: schoolEduCode, year: new Date().getFullYear()}).then(res => {
                        console.log('res', res)
                        if (res.length > 0) {
                            this.setState({
                                targetNoCompleteList: res,
                                showTipModal: true
                            })
                        } else {
                            // this.props.history.push({
                            //     pathname: '/mission_statement',
                            // })
                            message.success("提交成功", 1, () => {
                                this.props.history.push({
                                    pathname: '/mission_statement',
                                })
                            })
                        }
                    })
                })


            }

            // section 新增/修改
            updateSelfTargetRequest = () => {
                this.props.form.validateFields((err, value) => {
                    let {schoolEduCode, featureSelfDataVo} = this.state

                    if (err || undefined === schoolEduCode || undefined === featureSelfDataVo) {
                        return
                    }

                    let data = {
                        "schoolEduCode": schoolEduCode,
                        "selfData": value.selfTargetData,
                        "selfId": featureSelfDataVo.selfId ?? "",
                        "targetId": getSecondTargetList("self_Target")[0]
                    }

                    console.log("增加/删除 的请求参数", data)
                    changeSelfTarget(data).then(res => {
                        // message.success("提交成功")
                        this.getSelfTargetRequest()

                    }, (error) => {
                        message.error("修改失败")
                    })
                })

            }
            handlerUploadFiles = (files) => {
                if (undefined === this.state.featureSelfDataVo) {
                    return
                }

                //获取原有信息
                const featureSelfDataVo = this.state.featureSelfDataVo
                //获取原有信息
                featureSelfDataVo.resourceVos = (featureSelfDataVo.resourceVos || []).concat((files || []))

                // 保存文件id列表并请求后台告知
                this.setState({featureSelfDataVo})
            }
            handlerDeleteFile = (index) => {
                //获取原有信息
                const featureSelfDataVo = this.state.featureSelfDataVo
                if (undefined === featureSelfDataVo ||
                    undefined === featureSelfDataVo.resourceVos ||
                    featureSelfDataVo.resourceVos.length <= 1) {
                    return
                }

                // 删除下表下的元素
                featureSelfDataVo.resourceVos.splice(index, 1)

                // 保存文件id列表并请求后台告知
                this.setState({featureSelfDataVo})
            }
            // 更新满意度
            saveSatisfactionInfo = (value) => {
                if (this.state.satisfactionInfo) {
                    const params = this.state.satisfactionInfo
                    params["schoolEduCode"] = this.state.schoolEduCode
                    saveSatisfaction(params).then(res => {
                    })
                }
            }

            saveText = () => {
                this.props.form.validateFields((err, value) => {
                    if (!err) {
                        this.updateSelfTargetRequest()
                    }
                })
            }


            confirmReport = async () => {
                this.props.history.push({
                    pathname: '/mission_statement',
                })
            }


            // section Render
            render() {
                const canEdit = getCanEdit()
                const {getFieldDecorator} = this.props.form
                const {showTipModal, targetNoCompleteList} = this.state

                const uploadNeedData = (data) => {
                    let needArray = []
                    let uploadData = data || []
                    uploadData.map(item => {
                        needArray.push(
                            {
                                "id": item.resourceId,
                                "originFn": item.resourceName,
                                "fileId": item.fileId,
                                "fileName": item.resourceId + '.' + item.resourceSuffix,
                                "fileSize": item.resourceSize,
                                "createTime": item.createTime
                            })
                    })
                    return needArray
                }

                return (
                    <div className="SelfTarget_main Fillside_warp">
                        <Heder/>
                        <div className="Efficiency_header">
                            <div className="main_cont">
                                <EffTop/>
                            </div>
                        </div>
                        <div className="Fillside_bottom clearfix">
                            <div className="main_cont">
                                <div className="Fillside_warp" id='Fillside_warp'>
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="leftcollect" id="leftcollect">
                                            <div className="Double-left-table">
                                                <LeftEfficiencyside onRef={(ref) => {
                                                    this.LeftEfficiencyside = ref;
                                                }}/>
                                            </div>
                                        </Col>
                                        <Col span={20} className="Task_right">
                                            <div className="right_ReformPersonnel">
                                                <div className="title_top">
                                                    <h4>3.1.1 自选指标</h4>
                                                </div>
                                                <div
                                                    className="des">由院校结合区域特色、行业特色、学校特色和专业特色等，总结提炼基地建设运行中独创性的、有显著成效和示范推广意义的经验或成果
                                                </div>
                                                <Form.Item>
                                                    <div className={'textare-wrap'}>
                                                        {getFieldDecorator('selfTargetData', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "请填写自选指标内容"
                                                                }
                                                            ],
                                                        })(<TextArea disabled={!canEdit}
                                                                     className="textare"
                                                                     maxLength={2000}
                                                                     onBlur={this.saveText}>
                                                        < /TextArea>)}
                                                        <span className={'uploader'}>
                                                                  <UploaderComponent
                                                                      editEnable={canEdit}
                                                                      uploadTitle="添加佐证材料"
                                                                      targetId={this.state.currentId}
                                                                  />
                                                            </span>
                                                        <div className='textarea_len'>

                                                            {this.props.form.getFieldValue(`selfTargetData`) ?
                                                                this.props.form.getFieldValue(`selfTargetData`).length : 0}/2000
                                                        </div>
                                                    </div>

                                                </Form.Item>
                                                {/*todo 添加材料可否不做*/}
                                                {canEdit ? <div className="bottom-but">
                                                    <Button className="but-li"
                                                            onClick={() => this.previous()}>
                                                        上一页
                                                    </Button>

                                                    <Button className="but-li"
                                                            type="primary"
                                                            htmlType="submit"
                                                            onClick={() => this.targetAllComplete()
                                                            }>
                                                        提交
                                                    </Button>
                                                </div> : ""}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {/*顶部star*/}
                        <Footer/>
                        <Modal
                            title={'温馨提示'}
                            visible={showTipModal}
                            width={430}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            className={'wenxintishi'}
                            footer={
                                <div className='footer-btn'>
                                    <Button className={'quxiao'}
                                            onClick={() => this.setState({showTipModal: false})}>继续填写</Button>
                                    <Button hidden={targetNoCompleteList.length !== 0} type='primary'
                                            onClick={() => this.confirmReport()}>确定</Button>
                                </div>
                            }
                        >
                            <div className={'tipwrap'}>
                                <WenxinTip/><span>{targetNoCompleteList.length === 1 ? targetNoCompleteList[0] + '指标添加内容不完整!' :
                                targetNoCompleteList.length > 1 ? (targetNoCompleteList.join(', ') + '指标添加内容不完整!') : '指标已全部填完，是否继续？'}</span>
                            </div>
                        </Modal>
                    </div>
                )
            }
        }
    )
)
