// 项目情况-平台情况
import React from 'react';
import {Form, Row, Col, Card, Button} from 'antd';
import './index.scss';
import Heder from '../../components/header';
import Footer from '../../components/footer';
import StepTop from '../components/StepTop';
import Leftmien from '../components/Left_mien';
import SchoolJiansherws from '../components/school_Jiansherws'
import {selectSubTask} from "../../api/subTask";
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {addConstructionPlan, updateConstructionPlan} from "../../api/school";
import {updateProcessStatus} from "../../api/effect";

export default Form.create()(
    withRouter(
        class SchoolJian extends React.Component {
            state = {
                processCode: '2.4',
                leftCatalog: [],
                seniorId: "",//右侧当前是什么
                taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
                ifComplete: false,
                selectSeniorName: ""
            };

            componentDidMount() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
            }

            componentWillUnmount() {

            }

            leftSelect = (seniorId) => {
                console.log("seniorId", seniorId)
                this.setState({seniorId: seniorId}, () => {
                    this.getCurrentSelectSubTaskMethod(seniorId)
                })
            }

            // section 获取当前选中的任务数组
            getCurrentSelectSubTaskMethod = (seniorId) => {
                // if (undefined === seniorId) {
                //     this.fetchListData([])
                // }
                // let data = {
                //     taskId: this.state.taskId,
                //     schoolEduCode: this.state.schoolEduCode,
                //     seniorId: seniorId
                // }
                // selectSubTask(data).then((res) => {
                //     this.fetchListData(res)
                // });


            }

            // section 处理数据
            fetchListData(res) {
                console.log("res1111111111", res)
                let ifComplete = false
                if (res && res.length > 0) {
                    ifComplete = true
                }
                this.setState({
                    subTaskList: res ? res : [],
                    ifComplete
                });
            }

            selectSeniorNameMethod = (selectSeniorName) => {
                this.state.selectSeniorName = selectSeniorName
            }

            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }

            preStep = () => {
                // 上一步
                console.log(" 上一步")
                this.props.history.push({
                    pathname: '/xmxxjs_renwushu',
                })
            }
            nextStep = () => {
                console.log("下一步")

                // 更新进度
                let progressData = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    taskId: this.state.schoolInfo.taskId,
                    processCode: this.state.processCode
                }

                updateProcessStatus(progressData).then(res => {
                    this.props.history.push({
                        pathname: '/reform_personnel',
                    })
                })
            }



            render() {
                const {getFieldDecorator} = this.props.form
                const {processCode, subTaskList, ifComplete, seniorId, selectSeniorName, schoolEduCode, taskId} = this.state;

                return (
                    <div className="StatusProject_main">
                        <Heder/>
                        <div className="Task-top">
                            <div className="main_cont">
                                <Card>
                                    <Row>
                                        <Col span={24} className="col-li">
                                            <StepTop parentCode={"2"}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>
                        <div className="Task-bottom clearfix">
                            <div className="main_cont">
                                <div className="Task-bottom-main" id="Task-bottom-main">
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="Task_left" id="left-top">
                                            <div className="Double-left-table">
                                                <Leftmien
                                                    upseniorId={this.state.processCode}
                                                    isShow={true}
                                                    selectSeniorName={this.selectSeniorNameMethod}
                                                    qiehuancd={this.leftSelect}/>
                                            </div>
                                        </Col>
                                        <Col span={20} className="Task_right">
                                            <SchoolJiansherws
                                                processCode={processCode}
                                                taskId={taskId}
                                                seniorId={seniorId}
                                                schoolEduCode={schoolEduCode}
                                                selectSeniorName={selectSeniorName}
                                                preSelectCallBack={this.preStep}
                                                nextSelectCallBack={this.nextStep}
                                                ifComplete={ifComplete}
                                                subTaskList={subTaskList}>
                                            </SchoolJiansherws>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
