import React, {Component} from 'react'
import {Input, Select, Form, Cascader, Radio, Tooltip, Spin, message} from "antd";
import "./index.scss";
import {Upicon, Upicondele, Upiconwc} from "../iconSvg";
import {uploadFile} from "../../api/uploadFile";

const {TextArea} = Input;


class BuildAddTask extends React.Component {
    // section State
    state = {
        file: null
        // majorList: this.props.majorList,
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        id: res.id,
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        url: res.url,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };

    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    componentDidMount() {
        let {record} = this.props;
        console.log(record);
        if (record) {
            this.props.form.setFieldsValue({
                ...record
            });
            const fileNameindex = record.files[0]?.originFn.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = record.files[0]?.originFn.length; // 取到文件名长度
            const fileType = record.files[0]?.originFn.substring(fileNameindex + 1, fileNameLen); // 截
            let upFile = {
                id: record.files[0]?.id,
                originName: record.files[0]?.originFn,
                fileId: record.files[0]?.filePath + "/" + record.files[0]?.fileName,
                fileSuffix: fileType,
                // resourceId: res.id,
                // url: res.url,
                // fileSize: (res.fileSize / 1024).toFixed(2)
            }
            this.setState({
                file: upFile
            }, () => {
                this.props.form.setFieldsValue({
                    // classType: record.classType,
                    // className: record.className,
                    // classPeopleNumber: record.classPeopleNumber,
                    fileId: record.files[0]?.filePath + "/" + record.files[0]?.fileName,
                })
            })
        } else {
            this.props.form.resetFields()
        }

    }

    // componentWillReceiveProps(nextProps, nextContext) {
    //     console.log('专业的 nextProps：：：', nextProps.data)
    //     if(nextProps.data !== this.state.data){
    //         let data = nextProps.data
    //         let courseMajor = []
    //         courseMajor.push(data.majorOne)
    //         courseMajor.push(data.majorTwo)
    //         courseMajor.push(data.courseMajor)
    //         this.setState({
    //             data: nextProps.data
    //         }, () =>{
    //             this.props.form.setFieldsValue({
    //                 majorName: courseMajor,
    //                 majorCode: data.majorCode,
    //             })
    //         })
    //     }
    // }


    handleCancel = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {file} = this.state
        return (
            <>
                <Form>
                    <Form.Item label="完成任务名称">
                        {
                            getFieldDecorator('taskName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写任务名称'
                                    },
                                ]
                            })(
                                // <Cascader options={majorList}/>
                                // <TextArea/>
                                <Input maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`taskName`) ? this.props.form.getFieldValue(`taskName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item className="li_fo">
                        <span className={'label'}><span className='red'>*</span>是否完成：</span>
                        {getFieldDecorator('hasComplete', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否完成'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item label="情况说明" className="li_sm">
                        {
                            getFieldDecorator('description', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请填写情况说明'
                                    },
                                ]
                            })(
                                // <Cascader options={majorList}/>
                                <TextArea maxLength={100} style={{height: '150px'}}/>
                            )
                        }
                        <p className="font-num">{this.props.form.getFieldValue(`description`) ? this.props.form.getFieldValue(`description`).length : 0}/100</p>
                    </Form.Item>
                    <Form.Item className="li_formup">
                        <span className={'label'}><span className='red'>*</span>佐证材料:</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file" className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{display: "none"}}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                    />
                                    <span className={'tip'}>
                                                                    支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                                                </span>
                                </div>
                                <ul className="bottom_tit">
                                    {/*上传中*/}
                                    {file ? <Tooltip placement="topLeft"
                                                     title={file.originName}>
                                        <li className="title_de">{file.fileId ?
                                            <Upiconwc/> : <Spin/>}<span
                                            className="tie_span">{file.originName}</span><i
                                            className="Upicona"
                                            onClick={this.deleteFile}><Upicondele/></i></li>
                                    </Tooltip> : null}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                </ul>
                            </div>
                        )}
                    </Form.Item>

                </Form>
            </>
        )
    }
}


BuildAddTask = Form.create()(BuildAddTask);
BuildAddTask.defaultProps = {
    record: null
}
export default BuildAddTask;
