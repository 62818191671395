import React, { Component } from 'react'
import { Button } from "antd"
import "./index.scss"
import  Rect from './components/Rect';
export default class LiuChengTu extends Component {
  timer;
  state = {
    step: -1,
    displayData: [],
    root: {
      id: '999', text:"后勤副园长", path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2.png', width: 84, height: 32, displayOrder: 0,
      activePath: 'https://cdn.zjyjc.com/public_static/YouErYuan/pre.png'
    },
    data1: [
      {
        id: '1', text:'晨间工作', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-1.png', width: 72, height: 32, wrapperWidth: 252, displayOrder: 1
        , activePath: 'https://cdn.zjyjc.com/public_static/YouErYuan/pre-1.png'
      },
      {
        id: '27',text:'研究工作', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-27.png', width: 72, height: 32, wrapperWidth: 102, displayOrder: 5
        , activePath: 'https://cdn.zjyjc.com/public_static/YouErYuan/pre-27.png'
      },
      {
        id: '28',text:'分管工作', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-28.png', width: 72, height: 32, wrapperWidth: 464 + 58, displayOrder: 7
        , activePath: 'https://cdn.zjyjc.com/public_static/YouErYuan/pre-28.png'
      }
    ],

    data2: [
      {
        id: '2',text:'巡视全园安全', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-2.png', width: 72, height: 52, wrapperWidth: 72, displayOrder: 2
      },
      {
        id: '3', text:'巡视接待家长、小朋友',path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-3.png', width: 72, height: 72, wrapperWidth: 96, displayOrder: 3
      },
      {
        id: '4',text:'进班指导 （开餐、卫 生保健、安 全工作）各 岗位人员 开展工作', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-4.png', width: 84, height: 132, wrapperWidth: 84, displayOrder: 4
      },
    ],
    data3: [
      {
        id: '5',text:'配合园长 开展各项 工作', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-5.png', width: 72, height: 72, wrapperWidth: 252, displayOrder: 6
      },

    ],

    data0: [
      {
        id: '6',text:'财务', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-6.png', width: 48, height: 32, wrapperWidth: 48 + 34, contentHeight: 192 + 36, displayOrder: 8
      },
      {
        id: '23', text:'卫生保健、安全',path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-23.png', width: 108, height: 32, wrapperWidth: 108 + 26, contentHeight: 202 + 26, displayOrder: 12
      },
      {
        id: '24',text:'膳食', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-24.png', width: 48, height: 32, wrapperWidth: 48 + 34, contentHeight: 144 + 26, displayOrder: 16
      },
      {
        id: '25', text:'综治、绿化',path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-25.png', width: 84, height: 32, wrapperWidth: 84 + 34, contentHeight: 358 + 26, displayOrder: 19
      },
      {
        id: '26',text:'工会', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-26.png', width: 48, height: 32, wrapperWidth: 48 + 58, contentHeight: 292 + 36, displayOrder: 25
      }
    ],
    data: [
      [{
        id: '7',text:'财务票据审核监管',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-7.png', width: 72, height: 52, displayOrder: 9
      },
      {
        id: '11',text:'监督全园经费开支',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-11.png', width: 72, height: 52, displayOrder: 10
      },
      {
        id: '21',text:'监督幼儿各类收费收缴情况',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-21.png', width: 72, height: 72, displayOrder: 11
      }],

      [{
        id: '8',text:'检查指导保健医生开展工作',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-8.png', width: 72, height: 72, displayOrder: 13
      },
      {
        id: '12',text:'检查指导保洁工作',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-12.png', width: 72, height: 52, displayOrder: 14
      },
      {
        id: '22',text:'保安工作督导检查',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-22.png', width: 72, height: 52, displayOrder: 15
      }],

      [{
        id: '9',text:'定期抽查厨房各项工作',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-9.png', width: 72, height: 72, displayOrder: 17
      },
      {
        id: '13',text:'关注幼儿营养膳食',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-13.png', width: 72, height: 52, displayOrder: 18
      }],
      [{
        id: '10',text:'监督日常维修管理',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-10.png', width: 72, height: 52, displayOrder: 20
      },
      {
        id: '14',text:'加强日常安全巡查督导',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-14.png', width: 72, height: 72, displayOrder: 21
      },
      {
        id: '15',text:'监管各类物资',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-15.png', width: 72, height: 52, displayOrder: 22
      },
      {
        id: '16',text:'组织实施各类安全工作及演练',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-16.png', width: 72, height: 92, displayOrder: 23
      },
      {
        id: '17', text:'全园绿化美化', path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-17.png', width: 72, height: 52, displayOrder: 24
      }],
      [{
        id: '18',text:'安排日常各类慰问和活动组织',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-18.png', width: 72, height: 92, displayOrder: 26
      },
      {
        id: '19',text:'安排每年一次职工体检和大病医疗保险',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-19.png', width: 72, height: 112, displayOrder: 27
      },
      {
        id: '20',text:'配合上级工会开展工作',  path: 'https://cdn.zjyjc.com/public_static/YouErYuan/font2-20.png', width: 72, height: 72, displayOrder: 28
      }],

    ]
  }
  onClick = (v) => {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null;
    }
    let { displayData } = this.state
    if (!displayData.includes(v)) {
      displayData.push(v)
    }
    this.setState({
      step: v,
      displayData
    })
  }

  onAutoPlay = () => {
 
      if (!this.timer) {
        this.timer = setInterval(() => {
          let { step, displayData } = this.state
          if (step > 28) {
            clearInterval(this.timer)
            this.timer = null;
            return;
          }
          step = step + 1;
          if (!displayData.includes(step)) {
            displayData.push(step)
          }
          this.setState({
            step,
            displayData
          })
        }, 2000)
      }
  }
  render() {
    let { step, data, data0, data1, data2, data3, root, displayData } = this.state
    let lineV = (arr) => {
      let lth = arr.length - 1;
      return arr[lth].height / 2;
    }
    let transformX = width => {
      return { transform: 'translateX(' + width / 2 + 'px)' }
    }

    let displayStatus = (params) => {
      return { display: step == params.displayOrder || displayData.includes(params.displayOrder) ? '' : 'none' }
    }

    let getImagePath = (params) => {
      let prefix = params.displayOrder == step ? 'pre' : 'font2';
      let id = params.id;
      if (id == '999') {
        return `https://cdn.zjyjc.com/public_static/YouErYuan/${prefix}.png`
      }
      return `https://cdn.zjyjc.com/public_static/YouErYuan/${prefix}-${id}.png`
    }
    return <>
      <div style={{ backgroundColor: '#ffffff', width: 877, margin: 'auto' }}>

        <Button onClick={this.onAutoPlay}>自动播放</Button>

        <ul className="root">
          <li>

            <Rect text={root.text} width={root.width} height={root.height}  status={root.displayOrder == step } onClick={this.onClick.bind(this, root.displayOrder)}/>
            {/* <img src={getImagePath(root)} style={{ width: `${root.width}px`, height: `${root.height}px` }} onClick={this.onClick.bind(this, root.displayOrder)} /> */}
            <ul style={displayStatus(root)}>
              <li>
                <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v-32.png" />
              </li>
              <li style={{ textAlign: 'left', paddingLeft: '120px' }}>
                <div style={{ fontSize: 0, width: '464px', height: '1px', backgroundImage: 'url("https://cdn.zjyjc.com/public_static/YouErYuan/line-h-467.png")', backgroundPosition: '4px 0', backgroundRepeat: "no-repeat" }}></div>
              </li>
              <li>
                <ul className='horizontal' >
                  {
                    data1.map((item, index) => (<><li style={{ width: `${item.wrapperWidth}px`, textAlign: 'center' }}>
                      {
                        index === data1.length - 1 ?
                          <div style={{ display: 'inline-block', width: `${item.width}px` }, transformX(item.width * -1)}>
                            <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v.png" />
                            <div style={{ fontSize: 0 }}>
                            <Rect  text={item.text} width={item.width} height={item.height} status={item.displayOrder == step } onClick={this.onClick.bind(this, item.displayOrder)}/>
                              {/* <img src={getImagePath(item)} style={{ width: `${item.width}px`, height: `${item.height}px` }} onClick={this.onClick.bind(this, item.displayOrder)} /> */}
                            </div>
                          </div> : <div style={{ display: 'inline-block', width: `${item.width}px` }}>
                            <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v.png" />
                            <div style={{ fontSize: 0 }}>
                            <Rect text={item.text} width={item.width} height={item.height} status={item.displayOrder == step }  onClick={this.onClick.bind(this, item.displayOrder)}/>
                              {/* <img src={getImagePath(item)} style={{ width: `${item.width}px`, height: `${item.height}px` }} onClick={this.onClick.bind(this, item.displayOrder)} /> */}
                            </div>
                          </div>
                      }

                      {/* 三级目录 */}
                      {
                        index === 0 ? <ul style={{ width: '100%' }, displayStatus(item)} >
                          <li>
                            <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v.png" />
                          </li>
                          <li style={{ textAlign: 'left', paddingLeft: '36px' }}>
                            <div style={{ fontSize: 0, width: '171px', height: '1px', backgroundImage: 'url("https://cdn.zjyjc.com/public_static/YouErYuan/line-h-171.png")', backgroundPosition: '4px 0', backgroundRepeat: "no-repeat" }}></div>
                          </li>
                          <li>
                            <ul className="horizontal">
                              {
                                data2.map(item => (<li key={item.id} style={{ width: `${item.wrapperWidth}px` }} >
                                  <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v.png" />
                                  <div>
                                  <Rect text={item.text} width={item.width} height={item.height}  status={item.displayOrder == step } onClick={this.onClick.bind(this, item.displayOrder)}/>
                                    {/* <img src={getImagePath(item)} style={{ width: `${item.width}px`, height: `${item.height}px` }} onClick={this.onClick.bind(this, item.displayOrder)} /> */}
                                  </div>

                                </li>))
                              }

                            </ul>
                          </li>
                        </ul> : index === 1 ? <ul style={{ width: '100%' }, displayStatus(item)}>
                          <li>
                            <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v-36.png" />
                          </li>
                          <li>
                            <ul>
                              {
                                data3.map(item => (<li key={item.id}>
                                  <Rect text={item.text} width={item.width} height={item.height} status={item.displayOrder == step }  onClick={this.onClick.bind(this, item.displayOrder)}/>
                                  {/* <img src={getImagePath(item)} style={{ width: `${item.width}px`, height: `${item.height}px` }} onClick={this.onClick.bind(this, item.displayOrder)} /> */}
                                </li>))
                              }

                            </ul>

                          </li>
                        </ul> : <ul style={{ width: '100%' }, displayStatus(item)}>
                          <li>
                            <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v.png" style={transformX(item.width * -1)} />
                          </li>
                          <li style={{ textAlign: 'left', paddingLeft: '24px' }}>
                            <div style={{ fontSize: 0, width: '416px', height: '1px', backgroundImage: 'url("https://cdn.zjyjc.com/public_static/YouErYuan/line-h-416.png")', backgroundPosition: '4px 0', backgroundRepeat: "no-repeat" }}></div>
                          </li>
                          <li>
                            <ul className="horizontal">
                              {
                                data0.map((item, index) => (<><li key={item.id} style={{ width: `${item.wrapperWidth}px`, textAlign: 'left' }} >
                                  <div style={{ fontSize: 0, paddingLeft: `${item.width / 2}px` }}>
                                    <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-v.png" />
                                  </div>
                                  <div>
                                  <Rect text={item.text} width={item.width} height={item.height} status={item.displayOrder == step }  onClick={this.onClick.bind(this, item.displayOrder)}/>
                                    {/* <img src={getImagePath(item)} style={{ width: `${item.width}px`, height: `${item.height}px` }} onClick={this.onClick.bind(this, item.displayOrder)} /> */}
                                  </div>
                                  <ul className="vertical" style={{ height: `${item.contentHeight}px`, position: "relative", ...transformX(item.width), ...displayStatus(item) }}>
                                    <li style={{ width: "1px", height: `${item.contentHeight - lineV(data[index])}px`, fontSize: 0, position: 'absolute' }} className="line-v"></li>
                                    <li style={{ fontSize: 0, height: '40px' }}></li>
                                    {
                                      data[index].map((sitem, sindex) => (<li style={{ textAlign: "left", height: `${sitem.height}px`, display:'flex', alignItems:'center', ...sindex == data[index].length - 1 ? null : { marginBottom: '6px' }}}>
                                        <img src="https://cdn.zjyjc.com/public_static/YouErYuan/line-h.png" />
                                        <Rect text={sitem.text} width={sitem.width} height={sitem.height} status={sitem.displayOrder == step }  onClick={this.onClick.bind(this, sitem.displayOrder)}/>
                                        {/* <img src={getImagePath(sitem)} style={{ width: `${sitem.width}px`, height: `${sitem.height}px` }} onClick={this.onClick.bind(this, sitem.displayOrder)} /> */}
                                      </li>))
                                    }

                                  </ul>
                                </li>

                                </>))
                              }


                            </ul>
                          </li>
                        </ul>
                      }


                    </li>

                    </>))
                  }
                </ul>

              </li>
            </ul>
          </li>
        </ul>
      </div>
    </>
  }
}







