//todo 预览任务书,需要传值taskId才对
import React from "react";
import {Form, Row, Col, Radio, Table, Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import {withRouter} from "react-router-dom";
import {LegalPerson, Contact, EmployeesSeatNum} from '../../components/iconSvg'
import {schoolBasicDataPreview} from "../../api/preview";
import moment from 'moment';
import Cookies from "js-cookie";
import {getLinkManListRequest} from "../../api/school";
import Project_School_Modal_Component from "../project_School/components";

export default Form.create()(
    withRouter(
        class ConstructionBasicInformation extends React.Component {
            state = {
                radioValue: 1,
                schoolEduCode: null,
                taskId: null,
                provinceCode: null,
                taskBookInfoVo: null,
                schoolBasisInfoVo: {},
                schoolBasicConditionVos: [],
                previewParam: Cookies.get("previewParam") ? JSON.parse(decodeURI(Cookies.get("previewParam"))) : {},

            };

            componentDidMount() {
                let {previewParam} = this.state;
                this.setState({
                    taskId: previewParam.taskId,
                    schoolEduCode: previewParam.schoolEduCode,
                    provinceCode: previewParam.provinceCode
                }, () => {
                    this.schoolBasicDataPreview();
                })
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
                console.log("..........")

            }

            schoolBasicDataPreview = () => {
                let {taskId, schoolEduCode, provinceCode} = this.state;
                schoolBasicDataPreview({
                    taskId: taskId,
                    schoolEduCode: schoolEduCode,
                    provinceCode: provinceCode
                }).then(res => {
                    if (res) {
                        this.setState({
                            taskBookInfoVo: res?.taskBookInfoVo || {},
                            schoolBasisInfoVo: res?.schoolBasisInfoVo || {},
                            schoolBasicConditionVos: res?.schoolBasicConditionVos || {}
                        });
                    }
                })
            }

            // 左侧跟随屏幕
            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:60px;position:fixed;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }
            // 预览第二页
            secondPreviewPage = () => {
                this.props.history.push('/project_Situation')
            }

            getOrganizerTypeName = (type) => {
                if (type === 1) {
                    return "高等职业学校"
                } else if (type === 2) {
                    return "中等职业学校"
                } else if (type === 3) {
                    return "本科层次职业学校"
                }
                return ""
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {taskBookInfoVo, schoolBasisInfoVo, schoolBasicConditionVos} = this.state;

                return (
                    <div className="ConstructionBasicInformation_main" style={{minHeight: "1200px"}}>
                        <Heder/>
                        <div className="Task-preview clearfix">
                            <div className="main_cont" id="main_cont">
                                <div className="Task-bottom-preview">
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="preview_left" id="leftmain">
                                            <LeftMeinlist processCode={'1-1'}/>
                                        </Col>
                                        <Col span={20} className="preview_right">
                                            <div className="content">
                                                <div className="top-right top-rightG">
                                                    <h6>任务书预览页</h6>
                                                    <span><i><b>1</b>/7</i></span>
                                                </div>
                                                <div className="step-top-common">
                                                    <div className='topTitleI'/>
                                                    <h3>虚拟仿真实训基地建设任务书</h3></div>
                                                <div className="title-cent">
                                                     <span className="icon-img">
                                                    </span>

                                                    {taskBookInfoVo ? <ul>
                                                        <li className="li">
                                                            <h3>基地名称：</h3>
                                                            <span>{taskBookInfoVo?.baseName}</span>
                                                        </li>
                                                        <li className="li">
                                                            <h3>申报学校：</h3>
                                                            <span>{taskBookInfoVo?.hostSchool}</span>
                                                        </li>
                                                        <li className="li">
                                                            <h3>合作单位：</h3>
                                                            <span>{taskBookInfoVo?.cooperationUnit}</span>
                                                        </li>
                                                        <li className="li">
                                                            <h3>基地负责人：</h3>
                                                            <span>{taskBookInfoVo?.basePerson}</span>
                                                        </li>
                                                        <li className="li">
                                                            <h3>联系方式：</h3>
                                                            <span>{taskBookInfoVo?.contactMode}</span>
                                                        </li>
                                                        <li className="li">
                                                            <h3>填报日期：</h3>
                                                            <span>{taskBookInfoVo?.fillingDate === null ? '' : moment(taskBookInfoVo?.fillingDate).format('YYYY-MM-DD')}</span>
                                                        </li>
                                                    </ul> : ""}

                                                </div>
                                                <div className="bottom">
                                                    <h2>1. 学校基本概况</h2>
                                                    <div className="list">
                                                        <h3>1-1 申报学校概况</h3>
                                                        <ul className="ul">
                                                            <li className="li">
                                                                <h4>申报学校：<span>{schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.schoolName : ''}</span>
                                                                </h4>
                                                                <h4>所在地区：<span>{schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.province : ''}
                                                                    {schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.city : ''}</span>
                                                                </h4>
                                                                <h4>建校时间：<span>{schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.createSchoolTime : ''}</span>
                                                                </h4>

                                                            </li>
                                                            <li className="li">

                                                                <h4>院校性质：<span>{!schoolBasisInfoVo.schoolDetailVo ? '' : schoolBasisInfoVo.schoolDetailVo.schoolNature === 1 ? '公办' : schoolBasisInfoVo.schoolDetailVo.schoolNature === 2 ? '民办' : '合作办学'}</span>
                                                                </h4>
                                                                <h4>培育单位类型：<span>{!schoolBasisInfoVo.schoolDetailVo ? '' : this.getOrganizerTypeName(schoolBasisInfoVo.schoolDetailVo.organizerType)}</span>
                                                                </h4>
                                                                <h4>邮编：<span>{schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.postCode : ''}</span>
                                                                </h4>
                                                            </li>
                                                            <li className="li">
                                                                <Row className='listRow'>
                                                                    <Col className='listRowCol' span={8}>
                                                                        <h4>学校网址：<span>{schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.schoolWebsite : ''}</span>
                                                                        </h4></Col>
                                                                    <Col className='listRowCol' span={16}>
                                                                        <h4>通讯地址：<span>{schoolBasisInfoVo.schoolDetailVo ? schoolBasisInfoVo.schoolDetailVo.address : ''}</span>
                                                                        </h4></Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                        <LegalPerson/>
                                                        <h5>法人代表信息</h5>
                                                        <ul className="ul">
                                                            <li className="li">
                                                                <h4>姓名：<span>{schoolBasisInfoVo.legalPersonContactVo ? schoolBasisInfoVo.legalPersonContactVo.userName : ''}</span>
                                                                </h4>
                                                                <h4>职务：<span>{schoolBasisInfoVo.legalPersonContactVo ? schoolBasisInfoVo.legalPersonContactVo.userPost : ''}</span>
                                                                </h4>
                                                                <h4>办公电话：<span>{schoolBasisInfoVo.legalPersonContactVo ? schoolBasisInfoVo.legalPersonContactVo.workPhone : ''}</span>
                                                                </h4>
                                                            </li>
                                                            <li className="li">
                                                                <h4>传真：<span>{schoolBasisInfoVo.legalPersonContactVo ? schoolBasisInfoVo.legalPersonContactVo.userFax : ''}</span>
                                                                </h4>
                                                                <h4>手机：<span>{schoolBasisInfoVo.legalPersonContactVo ? schoolBasisInfoVo.legalPersonContactVo.phone : ''}</span>
                                                                </h4>
                                                                <h4>电子邮箱：<span>{schoolBasisInfoVo.legalPersonContactVo ? schoolBasisInfoVo.legalPersonContactVo.email : ''}</span>
                                                                </h4>
                                                            </li>
                                                        </ul>
                                                        <Contact/>
                                                        <h5>项目联系人信息</h5>

                                                        <Col span={24} className="col-list-table clearfix">
                                                            <div className="form-list">
                                                                <Project_School_Modal_Component
                                                                    haveLinkManDataCallback={this.haveLinkManDataCallback}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </div>
                                                </div>
                                                <div className="bottom-but">
                                                    <Button className="but-li" type="primary"
                                                            onClick={() => this.secondPreviewPage()}>
                                                        下一页
                                                    </Button>
                                                </div>
                                            </div>

                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
