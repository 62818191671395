// 查看资料
import React, {Component} from 'react';
import {getEvidence} from '../../../api/platformAbility';
import { withRouter } from "react-router-dom";
import "./index.scss";

@withRouter
class LookCheck extends Component {
    state = {
        id: '',
        transId:''
    };
    componentDidMount() {
        //获取佐证材料信息
        this.getEvidence()
    }
    getEvidence = () => {
        const {id} = this.props
        let data = {
            id: id
        }
        getEvidence(data).then(e=>{
            if (e){
                if (e.transId){
                    this.setState({
                        mediaType: e.transSuffix
                    })
                }else {
                    this.setState({
                        mediaType: e.resourceSuffix
                    })
                }
                this.setState({
                    transId: e.transId,
                    fileId: e.fileId,
                    resourceName: e.resourceName
                })
            }
        })
    }
    onCancel = () =>{
        this.setState({
            examine:false
        })
    }
    render() {
        const {transId,mediaType,fileId,resourceName} = this.state;
        return (
            <div className="Comment-LookCheck">
                <div className="wrap-center preview-box">
                    {transId? <div className="left_previ">
                        { mediaType === 'xls' || mediaType === 'xlsx' || mediaType === 'txt'?
                            <iframe style={{width:"100%",height:'calc(100vh - 114px)'}}
                                    src={'https://office.chinaeducloud.com/?ssl=1&furl=https://www-testa.cvei.cn/api/gongyong/api/v1/sdk/gongYong/media/downloadFile/'+transId+'/'+resourceName}></iframe> :
                            mediaType === 'pdf' ?
                                <iframe style={{width:"100%",height:'calc(100vh - 114px)'}}
                                        src={'https://office.chinaeducloud.com/?ssl=1&furl=https://www-testa.cvei.cn/api/gongyong/api/v1/sdk/gongYong/media/downloadFile/'+transId+'/'+resourceName+'.'+mediaType}></iframe> :
                                mediaType === 'jpeg' || mediaType === 'jpg' || mediaType === 'png' || mediaType === 'bmp' || mediaType === 'gif'?
                                    <div style={{height: "100%"}}>
                                        <img src={window.$$uploadRc(fileId)}/>
                                    </div> :
                                    <div className="pic-air">
                                        <div>
                                            <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                            <span>暂无数据</span>
                                        </div>
                                    </div>

                        }
                    </div>:<div className="left_previ" style={{"background":"#ffffff"}}>
                        { mediaType === 'xls' || mediaType === 'xlsx' || mediaType === 'txt'?
                            <iframe style={{width:"100%",height:'calc(100vh - 114px)'}}
                                    src={'https://office.chinaeducloud.com/?ssl=1&furl=https://www-testa.cvei.cn/api/gongyong/api/v1/sdk/gongYong/media/downloadFile/'+fileId+'/'+resourceName}></iframe> :
                            mediaType === 'pdf' ?
                                <iframe style={{width:"100%",height:'calc(100vh - 114px)'}}
                                        src={'https://office.chinaeducloud.com/?ssl=1&furl=https://www-testa.cvei.cn/api/gongyong/api/v1/sdk/gongYong/media/downloadFile/'+fileId+'/'+resourceName+'.'+mediaType}></iframe> :
                                mediaType === 'jpeg' || mediaType === 'jpg' || mediaType === 'png' || mediaType === 'bmp' || mediaType === 'gif' ?
                                    <div style={{height: "100%"}}>
                                        <div style={{height: "100%"}}>
                                            <img src={window.$$uploadRc(fileId)}/>
                                        </div>
                                    </div> :
                                    <div className="pic-air">
                                        <div>
                                            <img src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                            <span>暂无数据</span>
                                        </div>
                                    </div>

                        }
                    </div>}
                </div>
            </div>

        );
    }
}

export default LookCheck;
