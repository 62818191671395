// 建设方案描述-预览
import React from "react";
import {Form, Row, Col, Radio, Table, Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import {withRouter} from "react-router-dom";
import {investPreview} from "../../api/preview";
import Cookies from "js-cookie";
import SchoolDevelopment from "../components/Budget-total";
import ExpenditurePayComponent from "../components/Budget-total/expenditurePay-component";
import FormComponent from "../components/Budget-total/Form-component";

export default Form.create()(
    withRouter(
        class BuildingProject extends React.Component {
            state = {
                data: [],

                datalist: [],
                previewParam: Cookies.get("previewParam") ? JSON.parse(decodeURI(Cookies.get("previewParam"))) : {},
                investList: []
            }


            componentDidMount() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
                console.log("..........")
                this.investPreview();
            }

            investPreview = () => {
                let {previewParam} = this.state;
                console.log("previewParam", previewParam)
                investPreview({taskId: previewParam.taskId, schoolEduCode: previewParam.schoolEduCode}).then(res => {
                    console.log("res", res)
                    if (res) {
                        this.setState({
                            investList: res
                        });
                    }
                })
            }
            // 左侧跟随屏幕
            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:60px;position:fixed;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }
            secondPreviewPage = () => {
                this.props.history.push('/planned_performance')
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {columns, data, list, datalist, investList} = this.state;
                const formArr =

                    <div>
                        <div className="section_title">
                            <h3>5-1 经费来源</h3>
                        </div>
                        <FormComponent
                            isPreview ={true}
                        />
                    </div>

                // section 经费支出
                const moneyExpenditure =
                    <div>
                        <div className="section_title">
                            <h3>5-2 经费支出</h3>
                        </div>
                        <ExpenditurePayComponent
                            index={this.props.index}
                            taskId={this.props.taskId}
                            seniorId={this.props.seniorId}
                            upSeniorId={this.props.upSeniorId}
                            isPreview ={true}
                        >
                        </ExpenditurePayComponent>
                    </div>


                return (
                    <div className="building_Project" style={{minHeight: "1200px"}}>
                        <Heder/>
                        <div className="Task-preview clearfix">
                            <div className="main_cont" id="main_cont">
                                <div className="Task-bottom-preview">
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="preview_left" id="leftmain" style={{"top": '0px'}}>
                                            <LeftMeinlist processCode={'1-5'}/>
                                        </Col>
                                        <Col span={20} className="preview_right">
                                            <div className='content'>
                                                <div className='top-right'>
                                                    <h6 className='mission '>任务书预览页</h6>
                                                    <span><i><b>5</b>/7</i></span>
                                                </div>
                                                <div className='bottom'>
                                                    <h2>5. 建设项目总预算</h2>
                                                    <div className='list'>
                                                        {formArr}
                                                    </div>
                                                    <div className="list">
                                                        {moneyExpenditure}
                                                    </div>
                                                </div>
                                                <div className="bottom-but">
                                                    <Button className="but-li" type="primary"
                                                            onClick={() => this.secondPreviewPage()}>
                                                        下一页
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
