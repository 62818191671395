import {post} from '../../until/axios_instans';

/// 查询首页列表信息
export function taskBookTable(data) {
    return post(window.apiShixun + '/api/v1/taskBook/taskBookTable', data);
}

// 查询学校信息
export function schoolIndex(data) {
    return post(window.apiShixun + '/api/v1/school/schoolIndex', data);
}

export function selectRegion(data) {
    return post(window.apiShixun + '/api/v1/region/selectRegion', data);
}

// 获取填报时间,taskType=1为效能填报,taskType=2
export function getTargetTimes(taskType) {
    return post(window.apiShixun + '/api/v1/target/reportingTime', {taskType});
}






