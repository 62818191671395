import { post } from '../../until/axios_instans';
let url = window.apiShixun+"/api/v1/platformEach/";
// 获取平台互通信息
export function getInterflow(data) {
    return post(url+"getInterflow", data);
}
// 新增共享平台信息
export function addInterflowPlatform(data) {
    return post(url+"addInterflowPlatform", data);
}
// 编辑共享平台信息
export function updateInterflowPlatform(data) {
    return post(url+"updateInterflowPlatform", data);
}
// 删除共享平台信息
export function deleteInterflowPlatform(data) {
    return post(url+"deleteInterflowPlatform", data);
}
// 修改1.2.1指标要素
export function updateInterflowPlatformFactor(data) {
    return post(url+"updateInterflowPlatformFactor", data);
}
// 获取共享平台信息列表
export function getInterflowPlatforms(data) {
    return post(url+"getInterflowPlatforms", data);
}
// 新增数据互通信息
export function addInterflowData(data) {
    return post(url+"addInterflowData", data);
}
// 删除数据互通信息
export function deleteInterflowData(data) {
    return post(url+"deleteInterflowData", data);
}
// 修改数据互通信息
export function updateInterflowData(data) {
    return post(url+"updateInterflowData", data);
}
// 获取数据互通信息列表
export function getInterflowDataList(data) {
    return post(url+"getInterflowDataList", data);
}
// 获取数据互通信息
export function getInterflowData(data) {
    return post(url+"getInterflowData", data);
}
// 导入excel，1.2.1列表
export function importPlatform(data) {
    return post(url+"importPlatform", data);
}
// 导入excel，1.2.2列表
export function importData(data) {
    return post(url+"importData", data);
}
