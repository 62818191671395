//  国际交流

import React from "react";
import {
    Form,
    Row,
    Col,
    Card,
    Button,
    Input,
    Table,
    Modal,
    Radio,
    Select,
    message,
    DatePicker,
    Spin,
    Tooltip,
    Drawer
} from 'antd';
import Heder from "../../components/header";
import AddCommunicatee from "../../components/addcommunicatee";
import {
    Courseicon,
    Daoru,
    TianJia,
    PersonnelCultivatingProgram,
    WenxinTip,
    Upicon,
    Upiconwc,
    Upicondele,
    ExcelImport
} from '../../components/iconSvg'
import moment from "moment";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import {
    selectInternationalCommunication,
    selectPersonnel,
    deletePersonnel,
    updatePersonnel,
    addPersonnel,
    personnelDetail,
    selectResources,
    deleteResources,
    updateResources,
    addResources,
    resourcesDetail,
    checkImportPersonnel,
    checkImportResources
} from '../../api/internationalCommunication';
import {targetComplete, validData} from '../../api/target';
import {uploadFile} from '../../api/uploadFile';
import Cookies from "js-cookie";
import axios from "axios";
import {downTemplate} from "../../api/sharing";
import md5 from "js-md5";
import Footer from "../../components/footer";
import "./index.scss";
import LookCheck from "../components/look_Check";
import {getCanEdit, getRoleType, getTargetId, RoleType} from "../../until/cookie"
import FileLook from "../components/file_look";
import {BaseUrl} from "../../config/common";
import {isEmpty} from "../../until/isEmpty";
import {disabledDate, formatDate} from "../../until/date";

const {TextArea} = Input;

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

class InternationalExchange extends React.Component {
    state = {
        // twoLevelTargetId:Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
        twoLevelTargetId: getTargetId("international_Exchange"),
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        internationalCommunicationList: {},
        targetCompleteList: [],

        dataSource1: [],
        showfzclModel1: false,//新增佐证材料1,2,3,4
        cailiaoType: 1, //材料类型
        neirongType: 1,//内容类型

        dataSource2: [],
        showAddClass: false, //添加班级
        showPersonnel: false, //添加人才培养方案
        dataSource4: [],
        showtipModal: false, //添加人才培养方案
        showAddResource: false, //添加资源弹窗
        showAddActives: false,//添加活动弹窗
        showAddaward: false,//添加奖项弹窗
        showPlatformInformatiom: false,//添加平台信息
        pageNum: 1,//页数
        pageSize: 10,//条数
        total: 0,
        pageNumone: 1,//页数
        pageSizeone: 10,//条数
        totalone: 0,
        resourcesDataFlag: true,
        personnelDataFlag: true,
        // true新增,false编辑
        buttonFlag: true,
        evidenceId: null,
        examine: false,
        personnelTotalNum: 0,
    };
    // 查看资料
    handLook = (record) => {

        const url = BaseUrl + record.fileId
        this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})
    }
    handExamine = () => {
        this.setState({
            examine: true
        })
    }
    onCancel = () => {
        this.setState({
            examine: false
        })
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.selectInternationalCommunication()
    }

    /**
     * 全部的数据
     */
    selectInternationalCommunication = () => {
        let {schoolEduCode, twoLevelTargetId} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: twoLevelTargetId,
            pageNum: 1,
            pageSize: 20
        }
        console.log(twoLevelTargetId)
        console.log(twoLevelTargetId)
        console.log(twoLevelTargetId)
        console.log(twoLevelTargetId)
        console.log(twoLevelTargetId)
        selectInternationalCommunication(data).then((res) => {
            if (res) {
                this.setState({
                    internationalCommunicationList: res,
                    dataSource1: res.indicatorVo321.list.data,
                    dataSource2: res.indicatorVo322.list.data,
                    total: res.indicatorVo321.list.total,
                    personnelTotalNum: res.indicatorVo322.personnelTotalNum,
                    totalone: res.indicatorVo322.total,
                });
            }
        });
    }
    /**
     *  资源数量-------分页
     */
    selectResources = () => {
        let {schoolEduCode, internationalCommunicationList, pageNum, pageSize} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: internationalCommunicationList.indicatorVo321.id,
            pageNum: pageNum,
            pageSize: pageSize
        }
        selectResources(data).then((res) => {
            if (res) {
                this.setState({
                    dataSource1: res.data,
                    total: res.total
                });
            }
        });
    }
    deleteResources = (item) => {
        let {internationalCommunicationList, schoolEduCode} = this.state;
        deleteResources({
            id: item.id,
            targetId: internationalCommunicationList.indicatorVo321.id,
            schoolEduCode: schoolEduCode
        }).then((data) => {
            this.setState({
                pageNum: 1,
            }, () => {
                this.mesSuccess("删除成功");
                this.selectResources();
            })
        });
    }
    updateResources = (item) => {
        console.log('item', item)
        let upFile = {
            originName: item.resourceName,
            fileId: item.fileId,
            fileSuffix: item.resourceSuffix,
            fileSize: (item.fileSize / 1024).toFixed(2)
        }
        console.log('upFile', upFile)
        this.setState({
            showAddClass: true,
            file: item.fileId ? upFile : null,
            id: item.id,
            buttonFlag: false
        }, () => {
            this.props.form.setFieldsValue({
                resourcesName: item.resourcesName,
                resourcesType: item.resourcesType,
                resourcesDesc: item.resourcesDesc,
                resourcesBelonger: item.resourcesBelonger,
                outputPlatform: item.outputPlatform,
                website: item.website,
                outputTime: moment(item.outputTime),
                fileId: item.fileId
            })
        })
    }
    saveAndUpdateResources = (flag) => {
        let {file, internationalCommunicationList, schoolEduCode, resourcesDataFlag, id} = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err && resourcesDataFlag) {
                console.log('file', file)
                console.log('values', values)
                this.setState({
                    resourcesDataFlag: false
                })
                if (!file || isEmpty(file.fileId)) {
                    return this.mesWarning('文件上传中');
                }
                if (id) {
                    //修改
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        resourcesName: values.resourcesName,
                        resourcesType: values.resourcesType,
                        resourcesDesc: values.resourcesDesc,
                        resourcesBelonger: values.resourcesBelonger,
                        outputPlatform: values.outputPlatform,
                        website: values.website,
                        outputTime: moment(formatDate(values.outputTime)),
                        schoolEduCode: schoolEduCode,
                        targetId: internationalCommunicationList.indicatorVo321.id,
                        id: id
                    }
                    updateResources(data).then(e => {
                        if (e) {
                            message.success("修改成功")
                            this.setState({
                                showAddClass: flag,
                                resourcesDataFlag: true,
                                file: null
                            }, () => {
                                this.props.form.resetFields();
                                this.selectResources()
                            })
                        }
                    })
                } else {
                    //新增
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        resourcesName: values.resourcesName,
                        resourcesType: values.resourcesType,
                        resourcesDesc: values.resourcesDesc,
                        resourcesBelonger: values.resourcesBelonger,
                        outputPlatform: values.outputPlatform,
                        website: values.website,
                        outputTime: moment(formatDate(values.outputTime)),
                        schoolEduCode: schoolEduCode,
                        targetId: internationalCommunicationList.indicatorVo321.id
                    }
                    addResources(data).then(e => {
                        if (e) {
                            message.success("添加成功")
                            this.setState({
                                showAddClass: flag,
                                resourcesDataFlag: true,
                                file: null,
                                pageNum: 1
                            }, () => {
                                this.props.form.resetFields();
                                this.selectResources()
                            })
                        }
                    })
                }
            }
        })
    }
    /**
     * 国际人才交流------分页
     */
    selectPersonnel = () => {
        let {schoolEduCode, internationalCommunicationList, pageNumone, pageSizeone} = this.state
        let data = {
            schoolEduCode: schoolEduCode,
            targetId: internationalCommunicationList.indicatorVo322.id,
            pageNum: pageNumone,
            pageSize: pageSizeone
        }
        selectPersonnel(data).then((res) => {
            if (res) {
                this.setState({
                    dataSource2: res.list.data,
                    totalone: res.list.total,
                    personnelTotalNum: res.personnelTotalNum,
                });
            }
        });
    }
    deletePersonnel = (item) => {
        let {internationalCommunicationList, schoolEduCode} = this.state;
        deletePersonnel({
            id: item.id,
            targetId: internationalCommunicationList.indicatorVo322.id,
            schoolEduCode: schoolEduCode
        }).then((data) => {
            this.setState({
                pageNumone: 1
            }, () => {
                this.mesSuccess("删除成功");
                this.selectPersonnel();
            })
        });
    }
    updatePersonnel = (item) => {
        console.log('item', item)
        this.setState({
            showPersonnel: true,
            id: item.id,
            personnel: item,
            buttonFlag: false
        })
    }
    saveAndUpdatePersonnel = (flag) => {
        let {internationalCommunicationList, schoolEduCode, personnelDataFlag, id} = this.state;
        let file = this.personnelForm.getFile() ? this.personnelForm.getFile() : null
        this.personnelForm.props.form.validateFields((err, values) => {
            if (!err && personnelDataFlag) {
                console.log('file', file)
                console.log('values', values)
                this.setState({
                    personnelDataFlag: false
                })
                if (!file || isEmpty(file.fileId)) {
                    return this.mesWarning('文件上传中');
                }
                if (id) {
                    //修改
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        personnelName: values.personnelName,
                        personnelTitle: values.personnelTitle,
                        exchangeContent: values.exchangeContent,
                        personnelNum: values.personnelNum,
                        exchangeTime: moment(formatDate(values.exchangeTime)),
                        schoolEduCode: schoolEduCode,
                        targetId: internationalCommunicationList.indicatorVo322.id,
                        id: id
                    }
                    updatePersonnel(data).then(e => {
                        if (e) {
                            message.success("修改成功")
                            this.setState({
                                showPersonnel: flag,
                                personnelDataFlag: true,
                                personnel: null,
                                file: null
                            }, () => {
                                this.personnelForm.props.form.resetFields();
                                this.selectPersonnel()
                            })
                        }
                    })
                } else {
                    //新增
                    let data = {
                        evidenceQo: {
                            fileId: file.fileId,
                            resourceId: file.resourceId,
                            resourceName: file.originName,
                            resourceSize: file.fileSize,
                            resourceSuffix: file.fileSuffix
                        },
                        personnelNum: values.personnelNum,
                        personnelName: values.personnelName,
                        personnelTitle: values.personnelTitle,
                        exchangeContent: values.exchangeContent,
                        exchangeTime: moment(formatDate(values.exchangeTime)),
                        schoolEduCode: schoolEduCode,
                        targetId: internationalCommunicationList.indicatorVo322.id
                    }
                    addPersonnel(data).then(e => {
                        if (e) {
                            message.success("添加成功")
                            this.setState({
                                showPersonnel: flag,
                                personnelDataFlag: true,
                                personnel: null,
                                file: null,
                                pageNumone: 1,
                            }, () => {
                                this.personnelForm.props.form.resetFields();
                                this.personnelForm.deleteFile();
                                this.selectPersonnel()
                            })
                        }
                    })
                }
            }
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null,
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //添加班级提交
    addclassTijiao = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {} = value
                this.setState({showXzzyModel: false})
            }
        })
    }
    //添加班级提交继续添加
    addclassTijiaoAdd = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {
                const {} = value
                this.props.form.resetFields();
            }
        })
    }
    // 上一步
    previous = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }

        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "platform_Domesticmarketing") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/platform_Domesticmarketing',
                    })
                    break;
                }
            }
        }
    }
    // 下一步
    next = async () => {
        const lastTargetId = Cookies.get("twoLevelTargetId")
        if (lastTargetId) {
            validData(lastTargetId);
        }


        let targetList = this.LeftEfficiencyside.state.targetList
        let targetId = ''
        for (let i = 0; i < targetList.length; i++) {
            for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                if (targetList[i].targetDetails[j].path === "self_Target") {
                    targetId = targetList[i].targetDetails[j].id
                    Cookies.set('twoLevelTargetId', targetId);
                    this.props.history.push({
                        pathname: '/self_Target',
                    })
                    break;
                }
            }
        }
        // let {schoolEduCode} = this.state;
        // targetComplete({schoolEduCode: schoolEduCode, year: new Date().getFullYear()}).then(res => {
        //     console.log('res', res)
        //     if (res.length > 0) {
        //         this.setState({
        //             targetCompleteList: res,
        //             showtipModal: true
        //         })
        //     } else {
        //         this.props.history.push({
        //             pathname: '/mission_statement',
        //         })
        //     }
        // })
    }
    confirmReport = async () => {
        this.props.history.push({
            pathname: '/mission_statement',
        })
    }
    //分页切换
    pageChange = (pageNum) => {
        this.setState({pageNum}, () => {
            this.selectResources()
        });

    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({pageNum: 1, pageSize}, () => {
            this.selectResources()
        });


    }
    //第二个表格分页切换
    pageChangeone = (pageNum) => {
        this.setState({pageNumone: pageNum}, () => {
            this.selectPersonnel()
        });

    }
    //第二个表格分页条数切换
    onShowSizeChangeone = (pageNum, pageSize) => {
        this.setState({pageSizeone: pageSize, pageNumone: 1}, () => {
            this.selectPersonnel()
        });

    }
    //下载文件
    dowLoadFile = (targetMark, targetName) => {
        let now = new Date().getTime();
        axios.get(downTemplate(targetMark), {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${targetName}.xls`);
            link.click();
        })
    }

    /**
     *  隐藏预览的回调
     */
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }
    // 导入文件
    importFileResources = async (e) => {
        let {internationalCommunicationList, schoolEduCode} = this.state;
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('targetId', internationalCommunicationList.indicatorVo321.id)
        checkImportResources(fromData).then(res => {
            message.success('导入成功')
            this.selectResources()
        })
    }

    // 导入文件
    importFilePersonnel = async (e) => {
        let {internationalCommunicationList, schoolEduCode} = this.state;
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('targetId', internationalCommunicationList.indicatorVo322.id)
        checkImportPersonnel(fromData).then(res => {
            message.success('导入成功')
            this.selectPersonnel()
        })
    }
    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    // 校验网址格式
    validatehttp = (rule, value, callback) => {
        let pattern = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
        if (value && !pattern.test(value)) {
            callback('请输入正确网址链接')
        } else {
            callback();
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {
            dataSource1,
            dataSource2,
            showAddClass,
            showPersonnel,
            showtipModal,
            evidenceId,
            examine,
            pageNum,
            pageSize,
            total,
            totalone,
            personnelTotalNum,
            pageNumone,
            pageSizeone,
            personnel,
            internationalCommunicationList,
            file,
            buttonFlag,
            targetCompleteList
            ,
            isShow,
            openUrl
        } = this.state
        const canEdit = getCanEdit()
        const columns1 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNum - 1) * pageSize + (index + 1)}</span>
                }
            },
            {
                title: '资源名称',
                dataIndex: 'resourcesName',
                key: 'resourcesName',
                align: 'center',
                width: 150
            },
            {
                title: '资源类型',
                dataIndex: 'resourcesType',
                key: 'resourcesType',
                align: 'center',
                width: 150,
                render: (e) => <div>{e === 1 ? '桌面虚拟' : e === 2 ? '沉浸式' : e === 3 ? '增强现实' : '分布式'}</div>
            },
            {
                title: '资源描述',
                dataIndex: 'resourcesDesc',
                key: 'resourcesDesc',
                align: 'center',
                width: 150
            },
            {
                title: '资源归属人',
                dataIndex: 'resourcesBelonger',
                key: 'resourcesBelonger',
                align: 'center',
                width: 150
            },
            {
                title: '输出平台',
                dataIndex: 'outputPlatform',
                key: 'outputPlatform',
                align: 'center',
                width: 150
            },
            {
                title: '网址',
                dataIndex: 'website',
                key: 'website',
                align: 'center',
                width: 200
            },
            {
                title: '输出时间',
                dataIndex: 'outputTime',
                key: 'outputTime',
                align: 'center',
                width: 200,
                render: (e) => <div>{moment(e).format('YYYY-MM-DD HH:mm')}</div>
            },
            {
                title: '成果',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 100,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },

        ]
        if (canEdit) {
            columns1.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                fixed: 'right',
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updateResources(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deleteResources(record)}>删除</span>
                </div>

            })
        }
        const columns2 = [
            {
                title: '序号',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 80,
                render: (text, record, index) => {
                    return <span className={'xuhao'}>{(pageNumone - 1) * pageSizeone + (index + 1)}</span>
                }
            },
            {
                title: '交流对象',
                dataIndex: 'personnelName',
                key: 'personnelName',
                align: 'center',
                width: 190
            },
            {
                title: '头衔',
                dataIndex: 'personnelTitle',
                key: 'personnelTitle',
                align: 'center',
                width: 180
            },
            {
                title: '交流人数',
                dataIndex: 'personnelNum',
                key: 'personnelNum',
                align: 'center',
                width: 180
            },
            {
                title: '交流内容',
                dataIndex: 'exchangeContent',
                key: 'exchangeContent',
                align: 'center',
                width: 180
            },
            {
                title: '交流时间',
                dataIndex: 'exchangeTime',
                key: 'exchangeTime',
                align: 'center',
                width: 180,
                render: (e) => <div>{moment(e).format('YYYY-MM-DD HH:mm')}</div>
            },
            {
                title: '成果',
                dataIndex: 'fileId',
                key: 'fileId',
                align: 'center',
                width: 180,
                render: (text, record) => record.fileId ?
                    <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> : <span>未上传</span>
            },

        ]
        if (canEdit) {
            columns2.push({
                title: '操作',
                dataIndex: '',
                key: '',
                align: 'center',
                width: 200,
                fixed: 'right',
                render: (text, record) => <div>
                    <span className={'cz'} onClick={() => this.updatePersonnel(record)}>编辑</span>
                    <span className={'cz'} onClick={() => this.deletePersonnel(record)}>删除</span>
                </div>
            },)
        }

        return (
            <div className="InternationalExchange">
                <Heder/>
                <div className="Efficiency_header">
                    <div className="main_cont">
                        <EffTop/>
                    </div>
                </div>
                <div className="Fillside_bottom clearfix">
                    <div className="main_cont">
                        <div className="Fillside_warp" id='Fillside_warp'>
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="leftcollect" id="leftcollect">
                                    <div className="Double-left-table">
                                        <LeftEfficiencyside onRef={(ref) => {
                                            this.LeftEfficiencyside = ref;
                                        }}/>
                                    </div>
                                </Col>
                                <Col span={20} className="Task_right">
                                    <div className="Exchange_cont">
                                        <div className='content'>
                                            <div className={'cont1'}>
                                                {internationalCommunicationList.indicatorVo321 ?
                                                    <div className={'titleName'}>2.5.1 向国外输出虚拟仿真实训课程数</div> : null}
                                                <ul className={'tongjiul1'}>
                                                    <li>
                                                        <span className={'span'}>课程数</span>
                                                        <div className={'btn btn1'}>
                                                            <Input disabled value={total}/>
                                                        </div>
                                                    </li>

                                                </ul>
                                                <div className={'btn_group'}>
                                                    {
                                                        canEdit ? <Button type={'primary'}
                                                                          onClick={() => this.setState({
                                                                              showAddClass: true,
                                                                              id: null,
                                                                              buttonFlag: true,
                                                                              file: null
                                                                          })}><Courseicon/>添加课程</Button> : ""
                                                    }

                                                    {/* <Button type={'primary'}><Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }} onChange={this.importFileResources}/><ExcelImport/>excel导入</Button> */}
                                                    {/* {internationalCommunicationList.indicatorVo321? <Button type={'primary'} className="icon" onClick={() => this.dowLoadFile(internationalCommunicationList.indicatorVo321.targetMark, internationalCommunicationList.indicatorVo321.targetName)}><Daoru/>下载导入模版</Button> : null} */}
                                                </div>
                                                <Table columns={columns1} dataSource={dataSource1} className={'table1'}
                                                       bordered={true}
                                                       scroll={{x: 1500}}
                                                       locale={{
                                                           emptyText: <>
                                                               <div className="Empty-content">
                                                                   <div className="text">
                                                                       <img
                                                                           src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                       <span>暂无数据</span>
                                                                   </div>
                                                               </div>
                                                           </>
                                                       }}
                                                       pagination={
                                                           {
                                                               total: total,
                                                               current: pageNum,
                                                               pageSize: pageSize,
                                                               onChange: this.pageChange,
                                                               onShowSizeChange: this.onShowSizeChange,
                                                               pageSizeOptions: ['10', '20', '30', '40'],
                                                               showSizeChanger: true,
                                                               // position:'bottomRight'
                                                           }
                                                       }
                                                />
                                            </div>
                                            <div className={'cont1'}>
                                                {internationalCommunicationList.indicatorVo322 ?
                                                    <div className={'titleName'}>2.5.2 国际人才交流人次</div> : null}
                                                <ul className={'tongjiul1'}>
                                                    <li>
                                                        <span className={'span'}>人次</span>
                                                        <div className={'btn btn1'}>
                                                            <Input disabled value={personnelTotalNum}/>
                                                        </div>
                                                    </li>

                                                </ul>
                                                <div className={'btn_group'}>
                                                    {canEdit ? <Button type={'primary'}
                                                                       onClick={() => this.setState({
                                                                           showPersonnel: true,
                                                                           id: null,
                                                                           buttonFlag: true
                                                                       })}><TianJia/>添加交流项目</Button> : ""}
                                                    {/* <Button type={'primary'}><Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }} onChange={this.importFilePersonnel}/><ExcelImport/>excel导入</Button> */}
                                                    {/* {internationalCommunicationList.indicatorVo322? <Button type={'primary'} className="icon" onClick={() => this.dowLoadFile(internationalCommunicationList.indicatorVo322.targetMark, internationalCommunicationList.indicatorVo322.targetName)}><Daoru/>下载导入模版</Button> : null} */}
                                                </div>
                                                <Table columns={columns2} dataSource={dataSource2} className={'table1'}
                                                       bordered={true}
                                                       scroll={{x: 1100}}
                                                       locale={{
                                                           emptyText: <>
                                                               <div className="Empty-content">
                                                                   <div className="text">
                                                                       <img
                                                                           src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                       <span>暂无数据</span>
                                                                   </div>
                                                               </div>
                                                           </>
                                                       }}
                                                       pagination={
                                                           {
                                                               total: totalone,
                                                               current: pageNumone,
                                                               pageSize: pageSizeone,
                                                               onChange: this.pageChangeone,
                                                               onShowSizeChange: this.onShowSizeChangeone,
                                                               pageSizeOptions: ['10', '20', '30', '40'],
                                                               showSizeChanger: true,
                                                               // position:'bottomRight'
                                                           }
                                                       }
                                                />
                                            </div>
                                            {/*添加课程*/}
                                            <Modal
                                                title={this.state.buttonFlag ? "添加课程" : "编辑课程"}
                                                visible={showAddClass}
                                                width={660}
                                                destroyOnClose={true}
                                                closable={false}
                                                maskClosable={false}
                                                centered={true}
                                                className={'addTheCourseModal_internal'}
                                                wrapClassName="wappop_up"
                                                footer={
                                                    <div className='footer-btn'>
                                                        <Button className={'quxiao'}
                                                                onClick={() => this.setState({showAddClass: false})}>取消</Button>
                                                        <Button type='primary'
                                                                onClick={() => this.saveAndUpdateResources(false)}>提交</Button>
                                                        {buttonFlag ? <Button type='primary'
                                                                              onClick={() => this.saveAndUpdateResources(true)}>提交并继续添加</Button> : null}
                                                    </div>
                                                }
                                            >
                                                <Form>
                                                    <Form.Item>
                                                        <span className={'label'}><span
                                                            className='red'>*</span>资源名称</span>
                                                        <div className={'input-wrap'}>
                                                            {getFieldDecorator('resourcesName', {
                                                                initialValue: '',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: `请填写资源名称`,
                                                                    },
                                                                    {
                                                                        max: 30,
                                                                        message: "文本过长，最多30字"
                                                                    },
                                                                ],
                                                            })(
                                                                <Input placeholder='请输入资源名称' maxLength={30}
                                                                       suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`resourcesName`) ? this.props.form.getFieldValue(`resourcesName`).length : 0}/30</span>}/>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={'label'}><span
                                                            className='red'>*</span>资源类型</span>
                                                        {getFieldDecorator('resourcesType', {
                                                            initialValue: null,
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: '请选择资源类型'
                                                                },
                                                            ],
                                                        })(<Radio.Group>
                                                            <Radio value={1}>桌面虚拟</Radio>
                                                            <Radio value={2}>沉浸式</Radio>
                                                            <Radio value={3}>增强现实</Radio>
                                                            <Radio value={4}>分布式</Radio>
                                                        </Radio.Group>)}
                                                    </Form.Item>
                                                    <Form.Item className="li_form">
                                                        <span className={'label'}><span
                                                            className='red'>*</span>资源描述</span>
                                                        <div className={'input-wrap'}>
                                                            {getFieldDecorator('resourcesDesc', {
                                                                initialValue: '',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: `请填写资源描述`,
                                                                    },
                                                                    {
                                                                        max: 100,
                                                                        message: "文本过长，最多100字"
                                                                    },
                                                                ],
                                                            })(
                                                                <TextArea className="TextArea-pic" rows={3}
                                                                          placeholder={'请填写资源描述'}/>
                                                            )}
                                                            <span className='textarea_len'>
                                                            {this.props.form.getFieldValue(`resourcesDesc`) ? this.props.form.getFieldValue(`resourcesDesc`).length : 0}/100
                                                        </span>
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={'label'}><span
                                                            className='red'>*</span>归属人</span>
                                                        <div className={'input-wrap'}>
                                                            {getFieldDecorator('resourcesBelonger', {
                                                                initialValue: '',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: `请填写归属人`,
                                                                    },
                                                                    {
                                                                        max: 30,
                                                                        message: "文本过长，最多30字"
                                                                    },
                                                                ],
                                                            })(
                                                                <Input placeholder='请输入归属人' maxLength={30}
                                                                       suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`resourcesBelonger`) ? this.props.form.getFieldValue(`resourcesBelonger`).length : 0}/30</span>}/>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={'label'}><span
                                                            className='red'>*</span>输出平台</span>
                                                        <div className={'input-wrap'}>
                                                            {getFieldDecorator('outputPlatform', {
                                                                initialValue: '',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: `请填写输出平台`,
                                                                    },
                                                                    {
                                                                        max: 30,
                                                                        message: "文本过长，最多30字"
                                                                    },
                                                                ],
                                                            })(
                                                                <Input placeholder='请填写输出平台' maxLength={30}
                                                                       suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`outputPlatform`) ? this.props.form.getFieldValue(`outputPlatform`).length : 0}/30</span>}/>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={'label'}><span
                                                            className='red'>*</span>网址地址</span>
                                                        <div className={'input-wrap'}>
                                                            {getFieldDecorator('website', {
                                                                initialValue: '',
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: `请输入网址`,
                                                                    },
                                                                    {
                                                                        max: 50,
                                                                        message: "文本过长，最多50字"
                                                                    },
                                                                    {
                                                                        validator: this.validatehttp
                                                                    }
                                                                ],
                                                            })(
                                                                <Input placeholder='请输入网址' maxLength={30}
                                                                       suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`website`) ? this.props.form.getFieldValue(`website`).length : 0}/30</span>}/>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item>
                                                        <span className={'label'}><span
                                                            className='red'>*</span>输出时间</span>
                                                        <div className={'input-wrap'}>
                                                            {getFieldDecorator('outputTime', {
                                                                initialValue: undefined,
                                                                rules: [
                                                                    {
                                                                        required: true,
                                                                        message: `请输入输出时间`,
                                                                    }
                                                                ],
                                                            })(
                                                                <DatePicker placeholder={'请选择输出时间'}
                                                                            disabledDate={disabledDate}
                                                                            format="YYYY-MM-DD HH:mm"
                                                                            showTime={{format: 'HH:mm'}}/>
                                                            )}
                                                        </div>
                                                    </Form.Item>
                                                    <Form.Item className="li_formup">
                                                        <span className={'label'}><span
                                                            className='red'>*</span>佐证材料</span>
                                                        {getFieldDecorator('fileId', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: `请选择佐证材料`,
                                                                },
                                                            ],
                                                        })(
                                                            <div className="up_button">
                                                                <div className="span_right">
                                                                    <label htmlFor="file" className="inputlabelBox">
                                                                        <span><Upicon/>上传资料</span>
                                                                    </label>
                                                                    <input
                                                                        type="file"
                                                                        accept="image"
                                                                        onChange={this.multipartUpload}
                                                                        onClick={(event) => {
                                                                            event.target.value = null
                                                                        }}
                                                                        name="myfile"
                                                                        id="file"
                                                                        style={{display: "none"}}
                                                                    />
                                                                    <span className={'tip'}>
                                                                    支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                                                </span>
                                                                </div>
                                                                <ul className="bottom_tit">
                                                                    {/*上传中*/}
                                                                    {file ? <Tooltip placement="topLeft"
                                                                                     title={file.originName}>
                                                                        <li className="title_de">{file.fileId ?
                                                                            <Upiconwc/> : <Spin/>}<span
                                                                            className="tie_span">{file.originName}</span><i
                                                                            className="Upicona"
                                                                            onClick={this.deleteFile}><Upicondele/></i>
                                                                        </li>
                                                                    </Tooltip> : null}
                                                                    {/*已完成*/}
                                                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                                                </ul>
                                                                {/*<div className="bottom_tit">*/}
                                                                {/*    {file?<span>{file.originName}</span>:null}*/}
                                                                {/*    <span className={'len'}>{this.props.form.getFieldValue(`fileId`) ? 1 : 0}/1</span>*/}
                                                                {/*    <Button className='MaBtn'>查看</Button>*/}
                                                                {/*    <Button className='MaBtn' onClick={this.deleteFile}>删除</Button>*/}
                                                                {/*</div>*/}
                                                            </div>
                                                        )}
                                                    </Form.Item>

                                                </Form>
                                            </Modal>
                                            {/*添加人交流对象*/}
                                            <Modal
                                                title={this.state.buttonFlag ? "添加交流项目" : "编辑交流项目"}
                                                visible={showPersonnel}
                                                width={660}
                                                destroyOnClose={true}
                                                closable={false}
                                                maskClosable={false}
                                                centered={true}
                                                className={'addClassModal_internal'}
                                                wrapClassName="wappop_up"
                                                footer={
                                                    <div className='footer-btn'>
                                                        <Button className={'quxiao'} onClick={() => this.setState({
                                                            showPersonnel: false,
                                                            personnel: null
                                                        })}>取消</Button>
                                                        <Button type='primary'
                                                                onClick={() => this.saveAndUpdatePersonnel(false)}>提交</Button>
                                                        {buttonFlag ? <Button type='primary'
                                                                              onClick={() => this.saveAndUpdatePersonnel(true)}>提交并继续添加</Button> : null}
                                                    </div>
                                                }
                                            >
                                                <AddCommunicatee wrappedComponentRef={form => {
                                                    this.personnelForm = form
                                                }} data={personnel}/>
                                            </Modal>
                                        </div>
                                        {
                                            canEdit ? <div className={'footer-btn'}>
                                                <Button className={'syb'} onClick={() => this.previous()}>上一步</Button>
                                                <Button type={'primary'} onClick={() => this.next()}>下一步</Button>
                                            </div> : ""
                                        }

                                        <Modal
                                            title={'温馨提示'}
                                            visible={showtipModal}
                                            width={430}
                                            destroyOnClose={true}
                                            closable={false}
                                            maskClosable={false}
                                            centered={true}
                                            className={'wenxintishi'}
                                            footer={
                                                <div className='footer-btn'>
                                                    <Button className={'quxiao'}
                                                            onClick={() => this.setState({showtipModal: false})}>我再想想</Button>
                                                    <Button type='primary'
                                                            onClick={() => this.confirmReport()}>确定</Button>
                                                </div>
                                            }
                                        >
                                            <div className={'tipwrap'}>
                                                <WenxinTip/><span>{targetCompleteList.length === 1 ? targetCompleteList[0] + '指标添加内容不完整，是否继续?' :
                                                targetCompleteList.length > 1 ? '系统检查后发现您有多个二级指标没有填写，是否继续？' : '指标已全部填完，是否继续？'}</span>
                                            </div>
                                        </Modal>
                                    </div>
                                </Col>
                            </Row>

                        </div>

                    </div>


                </div>
                {/*查看资料*/}
                <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
                <Footer/>
            </div>
        );
    }
}

InternationalExchange = Form.create()(InternationalExchange);
export default InternationalExchange;
