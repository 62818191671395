import {post, get} from '../../until/axios_instans';
import Cookies from "js-cookie";
// 指标

// 保存学校基本情况
export function targetSynchro(data) {
    return post(window.apiShixun + '/api/v1/target/targetSynchro', data);
}

// 左边侧边栏信息
export function selectTarget(data) {
    return post(window.apiShixun + '/api/v1/target/selectTarget', data);
}

export function validData(targetId) {
    const schoolEduCode = JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "";
    return get(window.apiShixun + '/api/v1/valid/data', {targetId, schoolEduCode});
}

// 平台服务
export function selectAllResourcesService(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectAllResourcesService', data);
}

// 平台服务查询满意度
export function selectSatisfaction(schoolEduCode, targetId) {
    return post(window.apiShixun + '/api/v1/platformServices/selectSatisfaction', {
        schoolEduCode,
        targetId,
    });
}

// 平台服务更新满意度
export function saveSatisfaction(data) {
    return post(window.apiShixun + '/api/v1/platformServices/saveSatisfaction', data)
}

// 平台列表查询
export function getResourcesDataList(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectResourcesByTargetId', data);
}

// 平台服务检查
export function checkPlatformService(data) {
    return post(window.apiShixun + '/api/v1/platformServices/checkPlatformService', data);
}

// 平台服务新增
export function addResources(data) {
    return post(window.apiShixun + '/api/v1/platformServices/addResources', data);
}

// 平台服务删除
export function deleteServiceResources(data) {
    return post(window.apiShixun + '/api/v1/platformServices/deleteServiceResources', data);
}

// 平台服务编辑
export function updateServiceResources(data) {
    return post(window.apiShixun + '/api/v1/platformServices/updateServiceResources', data);
}

// 平台服务资源详情
export function selectServiceResourcesById(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectServiceResourcesById', data);
}

// 平台服务导入
export function importPlatformService(data) {
    return post(window.apiShixun + '/api/v1/platformServices/importPlatformService', data);
}

// 平台应用
export function selectAllPlatformApplication(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/selectAllPlatformApplication', data);
}

// 平台应用导入
export function importPlatformApplication(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/importPlatformApplication', data);
}

// 平台应用添加资源
export function addApplyResources(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/addApplyResources', data);
}

// 平台应用编辑
export function updateApplyResources(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/updateApplyResources', data);
}

// 平台应用删除
export function deleteApplyResources(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/deleteApplyResources', data);
}

// 平台应用资源详情
export function selectApplyResourcesById(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/selectApplyResourcesById', data);
}

// 平台应用格局指标id查询佐证材料
export function selectEvidenceByTargetId(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/selectEvidenceByTargetId', data);
}

// 添加佐证材料
export function addEvidence(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/addEvidence', data);
}

// 删除佐证材料
export function deleteEvidence(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/deleteEvidence', data);
}

// 平台应用修改专业数量
export function updateMajorNum(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/updateMajorNum', data);
}

// 平台应用资源条件修改
export function saveConditionStatus(data) {
    return post(window.apiShixun + '/api/v1/platformApplication/saveConditionStatus', data);
}

// 平台课程获取分页列表
export function getPageCourseList(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectCourseByTargetId', data);
}

// 平台课程新增/修改
export function addAndSaveCourse(data) {
    return post(window.apiShixun + '/api/v1/platformServices/addAndSaveCourse', data);
}

// 平台获取某个课程详情
export function selectCourseInfoById(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectCourseById', data);
}

// 平台课程删除
export function deleteCourse(data) {
    return post(window.apiShixun + '/api/v1/platformServices/deleteCourse', data);
}

// 平台项目(2.2.4)获取分页列表
export function getPageProjectList(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectProjectByTargetId', data);
}

// 平台项目(2.2.4)新增/修改
export function addAndSaveProject(data) {
    return post(window.apiShixun + '/api/v1/platformServices/addAndSaveProject', data);
}

// 平台项目(2.2.4)获取某个项目详情
export function selectProjectInfoById(data) {
    return post(window.apiShixun + '/api/v1/platformServices/selectProjectById', data);
}

// 平台项目(2.2.4)删除
export function deleteProject(data) {
    return post(window.apiShixun + '/api/v1/platformServices/deleteProject', data);
}


// 修改要素
export function updateFactor(data) {
    return post(window.apiShixun + '/api/v1/factor/updateFactor', data);
}

// 新增/修改 自选指标
export function changeSelfTarget(data) {
    return post(window.apiShixun + '/api/v1/target/feature/addOrUpdateFeatureSelf', data);
}

// 查询自选指标
export function getSelfTarget(data) {
    return post(window.apiShixun + '/api/v1/target/feature/selectFeatureSelf', data);
}

// 指标完成填报
export function targetComplete(data) {
    return post(window.apiShixun + '/api/v1/target/targetComplete', data);
}

// 效能指标管理
export function targetManage(data) {
    return post(window.apiShixun + '/api/v1/target/targetManage', data);
}

// 获取填报时间,taskType=1为效能填报
export function getTargetTimes(taskType) {
    return post(window.apiShixun + '/api/v1/target/reportingTime', {taskType});
}

/**
 * 修改填报时间
 * @param beginTime 开始时间,moment
 * @param endTime 结束时间,moment
 * @returns {Promise | Promise<unknown>}
 */
export function modifyReportingTime(beginTime, endTime, rtTimeId) {
    const params = {}
    if (beginTime) {
        params["beginTime"] = beginTime
    }
    if (endTime) {
        params["endTime"] = endTime
    }
    params["rtTimeId"] = rtTimeId
    return post(window.apiShixun + '/api/v1/target/modifyReportingTime', params);
}




