import React, { Component } from 'react'
import { Input, Select, Form, Tooltip, Spin, message, DatePicker, InputNumber, Radio } from "antd";
import "./index.scss";
import { uploadFile } from '../../api/uploadFile';
import moment from "moment";
import { Upicon, Upiconwc, Upicondele } from "../iconSvg";
import { range } from '@/until/util'

class AddTeacherProject extends React.Component {
    state = {
    }

    componentDidMount() {
        this.props.wrappedComponentRef && this.props.wrappedComponentRef(this)
        let { data } = this.props;
        console.log('data', data)
        if (data) {
            let upFile = {
                originName: data ? data.resourceName : '',
                fileId: data ? data.fileId : '',
                fileSuffix: data ? data.resourceSuffix : '',
                fileSize: data ? (data.fileSize / 1024).toFixed(2) : ''
            }
            this.setState({
                file: upFile
            })

            console.log(moment(data.projectStartTime).utcOffset(+8).format('YYYY-MM-DD HH:mm:ss'))
            this.props.form.setFieldsValue({
                id: data?.id || '',
                projectName: data ? data.partakeNumber : '',
                // isResearch: data?.isResearch || 0,
                projectEndTime: data ? moment(data.projectEndTime).utcOffset(+8) : '',
                projectLevel: data?.projectLevel || null,
                projectName: data?.projectName || '',
                projectPresenter: data?.projectPresenter || '',
                projectSource: data?.projectSource || '',
                projectStartTime: data ? moment(data.projectStartTime).utcOffset(+8) : '',
                fileId: data ? data.fileId : ''
            })
        }
    }


    handleCancel = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    onChange = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName: list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        console.log('vvvvvvvvvv', v)
        let { file } = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    getState = () => {
        return this.state.file
    }

    // 校验网址格式
    validatehttp = (rule, value, callback) => {
        let pattern = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
        if (value && !pattern.test(value)) {
            callback('请输入正确培训地址')
        } else {
            callback();
        }
    }

    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({ fileId: null });
        this.setState({
            file: null
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { trainTime, file } = this.state;
        console.log('vvvv file', file)
        const { Option } = Select;


        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 16 },
        }

        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        const disabledDateStartTime = currentDate => {
            let endTimeValue = this.props.form.getFieldValue('projectEndTime')
            let today = endTimeValue ? moment(endTimeValue).isSameOrBefore(moment(), 'days') ? moment(endTimeValue) : moment() : moment()
            return !currentDate.isSameOrBefore(today, 'days')
        };

        const disabledDateTimeStartTime = (date) => {
            let endTimeValue = this.props.form.getFieldValue('projectEndTime')
            let today = endTimeValue ? moment(endTimeValue).isSameOrBefore(moment(), 'days') ? moment(endTimeValue) : moment() : moment();
            if (date.isSameOrBefore(today, 'minutes')) {
                if (date.isSame(today, 'minutes')) {
                    let hour = date.hour();
                    let minute = date.minute();
                    return {
                        disabledHours: () => range(hour, 24),
                        disabledMinutes: () => range(minute, 60)
                    };
                }
                return true
            }
            return false

        }

        const disabledDateEndTime = currentDate => {
            let startTimeValue = this.props.form.getFieldValue('projectStartTime')
            let startDate = startTimeValue ? moment(startTimeValue) : moment()
            return currentDate.isSameOrBefore(startDate, 'days') && !currentDate.isSame(startDate, 'days')
        };

        const disabledDateTimeEndTime = (date) => {
            if (disabledDateEndTime(date)) {
                return false;
            }
            let startTimeValue = this.props.form.getFieldValue('projectStartTime')
            if (startTimeValue) {
                let startDate = moment(startTimeValue);
                if (date.isSame(startDate, 'minutes')) {
                    let hour = date.hour();
                    let minute = date.minute();
                    return {
                        disabledHours: () => range(0, hour),
                        disabledMinutes: () => range(0, minute)
                    };
                }
            }
            return true;
        }


        return (
            <div className='AddTeacherMaterial'>
                <Form {...formItemLayout}>
                    <Form.Item style={{display:'flex'}}>
                        {
                            getFieldDecorator('id')(
                                <Input hidden />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="课题名称"  style={{display:'flex'}}>
                        {
                            getFieldDecorator('projectName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '课题名称不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入课题名称' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`projectName`) ? this.props.form.getFieldValue(`projectName`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="课题级别" style={{ display: 'flex' }}>
                        {
                            getFieldDecorator('projectLevel', {
                                initialValue: null,
                                rules: [
                                    {
                                        required: true,
                                        message: '课题级别不能为空'
                                    },
                                ],
                                // initialValue: trainTime ? moment(trainTime).format("YYYY-MM-DD HH:MM") : ''
                            })(
                                <Radio.Group>
                                    <Radio value={1}>国家级</Radio>
                                    <Radio value={2}>省部级</Radio>
                                </Radio.Group>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="课题来源"  style={{display:'flex'}}>
                        {
                            getFieldDecorator('projectSource', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '课题来源不能为空'
                                    },
                                    // {
                                    //     validator:this.validatehttp
                                    // }
                                ]
                            })(
                                <Input placeholder='请输入课题来源' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`projectSource`) ? this.props.form.getFieldValue(`projectSource`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    {/* <Form.Item label="虚拟仿真实训" style={{display:'flex'}}>
                        {
                            getFieldDecorator('isResearch', {
                                initialValue: 1,
                                rules: [
                                    {
                                        required: true,
                                        message: '虚拟仿真实训'
                                    }
                                ]
                            })(
                                <Radio.Group>
                                    <Radio value={1}>是</Radio>
                                    <Radio value={0}>否</Radio>
                                </Radio.Group>
                            )
                        }
                    </Form.Item> */}
                    <Form.Item label="课题负责人" style={{display:'flex'}} >
                        {
                            getFieldDecorator('projectPresenter', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '课题负责人不能为空'
                                    },
                                    // {
                                    //     validator:this.validatehttp
                                    // }
                                ]
                            })(
                                <Input placeholder='请输入课题负责人' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`projectPresenter`) ? this.props.form.getFieldValue(`projectPresenter`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="	立项日期"
                        className='projectStartTime' style={{display:'flex'}}>
                        {
                            getFieldDecorator('projectStartTime', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '立项日期不能为空'
                                    },
                                ],
                                // initialValue: trainTime ? moment(trainTime).format("YYYY-MM-DD HH:MM") : ''
                            })(
                                <DatePicker disabledDate={disabledDateStartTime} disabledTime={disabledDateTimeStartTime} placeholder={'立项日期级别'} format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="结题日期"
                        className='projectEndTime' style={{display:'flex'}}>
                        {
                            getFieldDecorator('projectEndTime', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '结题日期不能为空'
                                    },
                                ],
                                // initialValue: trainTime ? moment(trainTime).format("YYYY-MM-DD HH:MM") : ''
                            })(
                                <DatePicker disabledDate={disabledDateEndTime} disabledTime={disabledDateTimeEndTime} placeholder={'请选结题日期'} format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} />
                            )
                        }
                    </Form.Item>

                </Form>
            </div>
        )
    }
}


AddTeacherProject = Form.create()(AddTeacherProject);
export default AddTeacherProject;