import React, { Component } from "react";
import { Input, Form } from "antd";

const FormItem = Form.Item;
class Forms extends React.Component {
  state = {
    file: null,
  };

  componentDidMount() {
    
    let { record } = this.props;
    console.log("record",record,)
    if (Object.keys(record || {}).length !== 0){
      console.log(1)
      this.props.form.setFieldsValue({
        // ...record,
        certificateName: record?.certificateName + '' ||"",
        studentNum: record?.studentNum + '' ||"",
      });
      
    } else {
      console.log(2)
      this.props.form.resetFields();
    }
  }

  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };
  validateNoChinese = (_, value, callback) => {
    const reg = /^\d+$|^\d*\.\d+$/g;
    const startValue = value?.split('.')
    const regEmpty = /^\s*$/g;
    
    if(!value){
      callback('');
    }else  if (value && !reg.test(value) || !startValue[0]) {
      callback('只能输入数字');
    } else if(value && regEmpty.test(value)) {
      callback('名称不能为空');
    } else {
      callback();
    }
    callback();
  }
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <FormItem label="证书名称">
          {getFieldDecorator("certificateName", {
            rules: [
              {
                required: true,
                message: "请填写证书名称",
              },
            ],
          })(<Input placeholder="请输入证书名称"  maxLength={30} suffix={<span className='len'>
          {this.props.form.getFieldValue(`certificateName`) ? this.props.form.getFieldValue(`certificateName`).length : 0}/30</span>}  />)}
        </FormItem>
        <FormItem label="考取学生数">
          {getFieldDecorator("studentNum", {
            rules: [
              {
                required: true,
                message: "请选择考取学生数",
              },
              {
                validator: this.validateNoChinese
              }
            ],
          })(<Input type="number" placeholder="请输入考取学生数" min={0} style={{paddingRight:'5px'}}/>)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
