import { post } from '../../until/axios_instans';
// 获取左侧流程List
export function selectSenior(data) {
    return post(window.apiShixun+'/api/v1/senior/selectSenior', data);
}
//新增上级流程
export function addSenior(data) {
    return post(window.apiShixun+'/api/v1/senior/addSenior', data);
}
//删除上级流程
export function deleteSenior(data) {
    return post(window.apiShixun+'/api/v1/senior/deleteSenior', data);
}
//修改上级流程
export function updateSenior(data) {
    return post(window.apiShixun+'/api/v1/senior/updateSenior', data);
}






