// 任务书汇报
import React from "react";
import {Form, Row, Col, Radio, Table} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import {Mimacz, Querwc} from '../../components/iconSvg'
import {withRouter} from "react-router-dom";

export default Form.create()(
    withRouter(
        class MissionManagement extends React.Component {
            state = {
                total: 0,
                TaskBookTableDetail: [
                    {id: "1"}
                ],

            };

            componentDidMount() {

            }

            componentWillUnmount() {
                clearInterval(this.timer);
            }

            isReportingChange = e => {
                this.setState({
                    pageNum: 1,
                    isReporting: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            updateApplyChange = e => {
                this.setState({
                    pageNum: 1,
                    isUpdateApply: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            catgoryChange = e => {
                this.setState({
                    pageNum: 1,
                    catgory: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            isFinalCheckChange = e => {
                this.setState({
                    pageNum: 1,
                    isFinalCheck: e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }
            provinceChange = e => {
                this.setState({
                    pageNum: 1,
                    province: e.target.value === '全部' ? '' : e.target.value,
                }, () => {
                    this.selectTaskBookTable();
                })
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {TaskBookTableDetail, total, pageSize} = this.state;
                const columns = [
                    {
                        title: '序号',
                        dataIndex: 'key',
                        key: 'key',
                        align: 'center',
                        width: 60,
                        fixed: 'left',
                        render: (text, record, index) => `${index + 1}`
                    },
                    {
                        title: '学校名称',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        width: 180,
                        fixed: 'left',
                    },
                    {
                        title: '学校代码',
                        dataIndex: 'schoolEduCode',
                        key: 'schoolEduCode',
                        width: 120,
                    },
                    {
                        title: '所在省份',
                        dataIndex: 'isReporting',
                        key: 'isReporting',
                        width: 210,
                        render: (e) => <div>{e === 1 ? "已完成" : "未完成"}</div>
                    },
                    {
                        title: '项目名称',
                        dataIndex: 'reportTime',
                        key: 'reportTime',
                        width: 210,
                    },
                    {
                        title: '任务书填报状态',
                        dataIndex: 'isFinalCheck',
                        key: 'isFinalCheck',
                        width: 150,
                        render: (e) => <div>{e === 1 ? "已完成" : "未完成"}</div>
                    },
                    {
                        title: '效能指标填报状态',
                        dataIndex: 'finalCheckTime',
                        key: 'finalCheckTime',
                        width: 210,
                    },
                    {
                        title: '操作',
                        key: 'operation',
                        fixed: 'right',
                        width: 400,
                        render: (text, record) => (
                            <div className='operating' style={{paddingRight: '60px'}}>
                                <div className="li">
                                    <span onClick={() => this.lookTaskBook(record)}>查看任务书</span>
                                    <span onClick={() => this.handleBoox(record)}>查看盖章任务书附件</span>
                                </div>
                                <div className="li li-on">
                                    <span onClick={() => this.updateTaskBook(record)}>修改任务书</span>
                                    <span onClick={() => this.handleCaseFile(record)}>查看盖章建设方案附件</span>
                                </div>
                                <div className="fin-list">
                                    {/*<div className="final-but final-but-active" onClick={() => this.updateFinalCheck(record)}>*/}
                                    {/*    <Querwc/>终验确认*/}
                                    {/*</div>*/}
                                    <div className="final-but final-but-ona">
                                        <Querwc/>终验完成
                                    </div>
                                    {/*<div className="final-but final-but-on" >*/}
                                    {/*    <Querwc/>终验确认*/}
                                    {/*</div>*/}
                                    <div className="final-but final-but-active" style={{marginTop: "5px"}}
                                         onClick={() => this.resetPassword(record)}>
                                        <Mimacz/>重置密码
                                    </div>
                                </div>
                            </div>
                        )
                    },
                ]
                return (
                    <div className="MissionManagement_main">
                        <Heder/>
                        <div className="Management_table main_cont">
                            <Row className="IndexHome-top">
                                <Col span={24} className="li-col">
                                    <span className="span-left">学校校验状态</span>
                                    <Radio.Group className="nav-tab" onChange={this.isReportingChange}
                                                 defaultValue={null}>
                                        <Radio.Button key={-1} value={null}>全部</Radio.Button>
                                        <Radio.Button key={0} value={0}>
                                            未完成
                                        </Radio.Button>
                                        <Radio.Button key={1} value={1}>
                                            已完成
                                        </Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col span={24} className="li-col">
                                    <span className="span-left">绩效指标修改</span>
                                    <Radio.Group className="nav-tab" onChange={this.updateApplyChange}
                                                 defaultValue={null}>
                                        <Radio.Button key={-1} value={null}>全部</Radio.Button>
                                        <Radio.Button key={1} value={1}>
                                            有修改申请
                                        </Radio.Button>
                                        <Radio.Button key={2} value={2}>
                                            无修改申请
                                        </Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col span={24} className="li-col">
                                    <span className="span-left">学校上报类型</span>
                                    <Radio.Group className="nav-tab" onChange={this.catgoryChange} defaultValue={null}>
                                        <Radio.Button key={-1} value={null}>全部</Radio.Button>
                                        <Radio.Button key={0} value={1}>
                                            高水平高职学校
                                        </Radio.Button>
                                        <Radio.Button key={1} value={2}>
                                            高水平专业群
                                        </Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col span={24} className="li-col">
                                    <span className="span-left">最终校验状态</span>
                                    <Radio.Group className="nav-tab" onChange={this.isFinalCheckChange}
                                                 defaultValue={null}>
                                        <Radio.Button key={-1} value={null}>全部</Radio.Button>
                                        <Radio.Button key={0} value={0}>
                                            未完成
                                        </Radio.Button>
                                        <Radio.Button key={1} value={1}>
                                            已完成
                                        </Radio.Button>
                                    </Radio.Group>
                                </Col>
                                <Col span={24} className="li-col">
                                    <span className="span-left">院校所在省份</span>
                                    <Radio.Group onChange={this.provinceChange} className="nav-tab" defaultValue={'全部'}>
                                        <Radio.Button key='11111' value='11111'>11111</Radio.Button>
                                    </Radio.Group>
                                </Col>
                            </Row>
                            <div className="home-list">
                                <div className="top-title">
                                    <h3><span>共{total}条任务书</span></h3>
                                </div>
                                <div className="bottom-table">
                                    <Table columns={columns} dataSource={TaskBookTableDetail} scroll={{x: 1600}}
                                           locale={{
                                               emptyText: <>
                                                   <div className="Empty-content">
                                                       <div className="text">
                                                           <img
                                                               src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                           <span>暂无数据</span>
                                                       </div>
                                                   </div>
                                               </>
                                           }}
                                           pagination={
                                               {
                                                   total: total,
                                                   pageSize: pageSize,
                                                   onChange: this.pageChange,
                                                   onShowSizeChange: this.onShowSizeChange,
                                                   pageSizeOptions: ['10', '20', '30', '40'],
                                                   showSizeChanger: true,
                                               }
                                           }
                                    />
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
