import React, { Component } from 'react'
import { Input, Select, Form, Tooltip, Spin, message, DatePicker, InputNumber, Radio } from "antd";
import "./index.scss";
import { uploadFile } from '../../api/uploadFile';
import moment from "moment";
import { Upicon, Upiconwc, Upicondele } from "../iconSvg";
import { range } from '@/until/util';

class AddTeacherMaterial extends React.Component {
    state = {
    }

    componentDidMount() {
        let { data } = this.props;
        console.log('data', data)
        if (data) {
            let upFile = {
                originName: data ? data.resourceName : '',
                fileId: data ? data.fileId : '',
                fileSuffix: data ? data.resourceSuffix : '',
                fileSize: data ? (data.fileSize / 1024).toFixed(2) : ''
            }
            this.setState({
                file: upFile
            })
            this.props.form.setFieldsValue({
                partakeNumber: data ? data.partakeNumber : '',
                isVirtualSimulation: data && data.isVirtualSimulation ? 1 : 0,
                trainAddress: data ? data.trainAddress : '',
                trainName: data ? data.trainName : '',
                fileId: data ? data.fileId : '',
                trainTime: data ? moment(data.trainTime).utcOffset(+8) : ''
            })
        }
    }


    handleCancel = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    onChange = () => {
        this.setState({
            addCertificateModal: false,
        })
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName: list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        console.log('vvvvvvvvvv', v)
        let { file } = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    getState = () => {
        return this.state.file
    }

    // 校验网址格式
    validatehttp = (rule, value, callback) => {
        let pattern = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
        if (value && !pattern.test(value)) {
            callback('请输入正确培训地址')
        } else {
            callback();
        }
    }

    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({ fileId: null });
        this.setState({
            file: null
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { trainTime, file } = this.state;
        console.log('vvvv file', file)
        const { Option } = Select;

        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };

        const disabledDateTime = (date) => {
            let today = moment();
            if (date.isSameOrBefore(today, 'minutes')) {
                if (date.isSame(today, 'minutes')) {
                    let hour = date.hour();
                    let minute = date.minute();
                    return {
                        disabledHours: () => range(hour, 24),
                        disabledMinutes: () => range(minute, 60)
                    };
                }
                return true
            }
            return false

        }

        const formItemLayout = {
            labelCol: {
                xs: { span: 6 },
                sm: { span: 6 }
            },
            wrapperCol: {
                xs: { span: 18 },
                sm: { span: 18 }
            },
        };

        return (
            <div className='AddTeacherMaterial'>
                <Form {...formItemLayout}>
                    {/* <div style={{ marginBottom: '20px', width: '100%', textAlign: 'left' }}>教师参加虚拟仿真实训教学专题培训的信息</div> */}
                    <Form.Item label="培训项目" >
                        {
                            getFieldDecorator('trainName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '培训项目不能为空'
                                    },
                                ]
                            })(

                                <Input placeholder='请输入培训项目名称' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`trainName`) ? this.props.form.getFieldValue(`trainName`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="是否为虚拟仿真" style={{ display: 'flex' }}>
                        {getFieldDecorator('isVirtualSimulation', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否为虚拟仿真'
                                },
                            ],
                        })(<Radio.Group >
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item label="培训时间"
                        className='addTeacherDatePicker'>
                        {
                            getFieldDecorator('trainTime', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: '培训时间不能为空'
                                    },
                                ],
                                // initialValue: trainTime ? moment(trainTime).format("YYYY-MM-DD HH:MM") : ''
                            })(
                                <DatePicker placeholder={'请选择培训时间'}
                                    disabledDate={currentDate => !currentDate.isSameOrBefore(moment().locale('zh'))}
                                    disabledTime={disabledDateTime}
                                    format="YYYY-MM-DD HH:mm" showTime={{ format: 'HH:mm' }} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="培训地点" >
                        {
                            getFieldDecorator('trainAddress', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: '培训地点不能为空'
                                    },
                                    // {
                                    //     validator:this.validatehttp
                                    // }
                                ]
                            })(
                                <Input placeholder='请输入培训地点' maxLength={30} suffix={<span className='len'>
                                    {this.props.form.getFieldValue(`trainAddress`) ? this.props.form.getFieldValue(`trainAddress`).length : 0}/30</span>} />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="参与人数" >
                        {
                            getFieldDecorator('partakeNumber', {
                                initialValue: null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入参与人数'
                                    },
                                ]
                            })(
                                <InputNumber min={0} placeholder='请输入正整数' style={{ width: 330 }} />
                            )
                        }
                    </Form.Item>
                    {/* <Form.Item  className={'li_formup input-wrap'} label={"佐证材料"}>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit"> */}
                    {/*上传中*/}
                    {/*{file && file.fileId ? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}*/}
                    {/* {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null} */}
                    {/*已完成*/}
                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                    {/* </ul>
                            </div>
                        )}
                    </Form.Item> */}
                </Form>
            </div>
        )
    }
}


AddTeacherMaterial = Form.create()(AddTeacherMaterial);
export default AddTeacherMaterial;