/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Form, Input, Button, Row, Col} from 'antd';
import {Deleticon, EdioIcon} from '../../../../components/iconSvg'
import './index.scss';

const {TextArea} = Input;

class Assignment extends Component {
    state = {
        formData: this.props.formData,
        sysType: '',
        saveStatus: true,
    };

    componentDidMount() {
    }

    componentWillReceiveProps(newProps) {
        // this.setState({formData: newProps.formData})
    }

    render() {
        const {getFieldDecorator, getFieldsError, getFieldError, isFieldTouched} = this.props.form;
        const {formData} = this.props
        // console.log("formData", formData)
        // console.log("formData1111", this.props.num)
        return (
            <div className="form-assignment">
                <div className={'border-bottom'}/>
                <div className={'caozuo-btn'}>
                    {/*<span className={'shanchu'} onClick={()=>{this.props.delFormItem(formData.formItem)}}><Deleticon/><p>删除</p></span>*/}
                    <span className={'shanchu'}
                          hidden={(formData.isHiddenDelete === true)}
                          onClick={() => {
                              this.props.delFormItem(formData)
                          }}><Deleticon/><p>删除</p></span>
                    <span className={'shanchu'} onClick={() => {
                        this.props.edit(formData)
                    }}><EdioIcon/><p>编辑</p></span>

                </div>

                <div className={'jianshe_card'}>

                    <Row>
                        <Col>
                            <div className={'jianshe_info_box'}>

                                <div className={'jianshe_title'}>{`建设任务-子项${this.props.num + 1} : `}</div>
                                <div className="jianshe_des_box">
                                    <div className={'jianshe_des'}>
                                        {formData ? formData.subtaskName : ""}
                                    </div>
                                </div>
                            </div>

                        </Col>
                    </Row>

                    {formData.saveSubTaskYearVoList ? formData.saveSubTaskYearVoList.map((item, index) => {
                        return (
                            <Row>
                                <Col>
                                    <div className={'jianshe_info_box'}>

                                        <div className={'jianshe_title'}>{item.yearName + ' : '}</div>
                                        <div className="jianshe_des_box" >
                                            <div className={'jianshe_des'}>
                                                {item.itemDescription}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        )
                    }) : ""}

                </div>
                {/*<div className="bottom-but">*/}
                {/*    <Button className="but-li"*/}
                {/*            htmlType="submit" onClick={() => this.props.resetOne(this.props.num)}*/}
                {/*            disabled={formData.ifComplete === true && formData.ifEdit === false}>*/}
                {/*        重置*/}
                {/*    </Button>*/}
                {/*    <Button className="but-li"*/}
                {/*            type="primary"*/}
                {/*            htmlType="submit"*/}
                {/*            onClick={() => this.props.saveOne(this.props.formData.formItem, this.props.num)}*/}
                {/*            disabled>*/}
                {/*        保存*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </div>
        );
    }
}

const FormAssignment = Form.create(
    /*   {
           onValuesChange(e, values) {
               console.log("$$$$$$$",e,"%%%%%%",values);
           }
       }*/
)(Assignment);
export default FormAssignment;
