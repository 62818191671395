import Cookies from 'js-cookie';
import {domainUrl} from '../config/secret';

const key = 'Main_Info';

export const setCookieToken = (value, time) => {
    Cookies.set(key, value, {path: '/', expires: time, domain: domainUrl});
}

export const removeCookieToken = () => {
    Cookies.remove(key, {path: '/', domain: domainUrl});
}

export const RoleType = {
    manager: 1,
    province: 2,
    school: 3,
}
export const getRoleType = () => {
    return JSON.parse(Cookies.get('userInfo')).roleType
}
export const getSchoolInfo = () => {
    return JSON.parse(Cookies.get('schoolInfo')).roleType
}

// 获取1级targetId
export const getTargetId = (path) => {
    const targetIdMap = (Cookies.get('targetIdMap'));
    if (targetIdMap !== undefined) {
        return JSON.parse(targetIdMap)[path];
    }
    return ""
}
// 获取2级id列表
export const getSecondTargetList = (path) => {
    const secondTargetListMap = (Cookies.get('secondTargetListMap'));
    if (secondTargetListMap !== undefined) {
        return JSON.parse(secondTargetListMap)[path];
    }
    return []
}

export const getUserInfo = () => {
    const userInfo = Cookies.get('userInfo')
    if (userInfo !== undefined) {
        return JSON.parse(Cookies.get('userInfo'));
    }
    return {}
}

// 获取看是否能编辑效能填报,通过角色和日期判断,只有角色类型是学校才能编辑,并且日期要没有失效
export const getCanEdit = () => {
    const expired = Cookies.get("expired")
    const roleType = getRoleType()
    return roleType === RoleType.school && expired === 'false';

}

// 任务书是否能填报,没过期,是学校
export const getReportCanEdit = () => {
    const expired = Cookies.get("reportExpired")
    const roleType = getRoleType()
    return roleType === RoleType.school && expired === 'false';

}

export function clearAllCookie() {
    Cookies.remove('vsToken');
    Cookies.remove('schoolInfo');
    Cookies.remove('userInfo');
    Cookies.remove('menuList');
    Cookies.remove('buttonPerms');
    Cookies.remove('previewParam');
    Cookies.remove('targetIdMap');
    Cookies.remove('expired');
    Cookies.remove('secondTargetListMap');
    Cookies.remove('twoLevelTargetId');
    // var keys = document.cookie.match(/[^ =;]+(?==)/g)
    // if (keys) {
    //     for (var i = keys.length; i--;) {
    //         document.cookie = keys[i] + '=0;path=' / ';expires=' + new Date(0).toUTCString()
    //         document.cookie = keys[i] + '=0;path=/;domain=.nihao.com;expires=' + new Date(0).toUTCString() // 清除当前域名下的，例如 .nihao.com
    //         document.cookie = keys[i] + '=0;path=/;domain=.nihao.com;expires=' + new Date(0).toUTCString() // 清除一级域名下的或指定的，例如 .nihao.com
    //     }
    // }
}
