
import { getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType } from "../../../until/cookie";
import React, { Component } from 'react';
import { Button, Col, Form, message, Modal, Radio, Row, Select, Table,Input } from 'antd';
import { Professionalicon, TeacherDevelopmentUpload, ExcelImport,Daoru } from "../../../components/iconSvg";
import UploaderComponent from "../../../components/uploader_component";
import {
    saveEffectTask,
    effectTasks,
    deleteEffectTask,
    importMajor,
    selectTargetQuestion,
    saveTargetQuestion,
    selectSolveDifficulty,
    saveSolveDifficulty,
    deleteSolveDifficulty,
    checkImportTargetResourceReplace,
    downloadBusTemplate,
    excelBusData
} from "../../../api/constructionResults"
import FileLook from "../../components/file_look";
import BuildAddTask from "../../../components/build_add_task";
import AddConstruction from "../../../components/build_add_construction"
import { BaseUrl } from "../../../config/common";
import Cookies from "js-cookie";
import axios from "axios";
import {downTemplate, getSharingMajorList} from "../../../api/sharing";
import md5 from "js-md5";

export default class threetable extends Component {
    state = {
        total1_5_3:0,
        complete1_5_3: 0,
        dataSource1_5_3: [],
        schoolEduCode: "4132013114",
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        targetId: this.props.targetId,
        majorInfo:[],
        taskPageNum: 1,//页数
        taskPageSize: 10,//条数
        datazuo1_5_3: {
            answer: null,
            id: "",
            targetId: "",
            files: [],
            title: ""
        },
        partakeTrainId:''
        // dataSource1_5_4.files
    }
    componentDidMount() {
        // console.log(this.props.inputconnect.evaluationList);
        this.effectTasks3()
        this.selectTargetQuestion1_5_3()
        // console.log(this.props.majorInfo);
        this.setState({
            majorInfo: this.props.majorInfo,
            // getFlag: this.props.inputconnect.getFlag,
            // errorReason: this.props.inputconnect.errorReason,
            // importanceDegree: this.props.inputconnect.importanceDegree,
            // remarkInfo: this.props.inputconnect.remarkInfo,
            // errorTestId: this.props.inputconnect.errorTestId,
            // evaluationList: this.props.inputconnect.evaluationList,
            // testid: this.props.inputconnect.testid
        });
    }
    componentWillUpdate() {
        // console.log(this.props.majorInfo);
        if(this.state.targetId != this.props.targetId){
            // console.log(this.props.targetId);
            this.setState({
                targetId:this.props.targetId,
                datazuo1_5_3: {
                    answer: this.state.datazuo1_5_3.answer,
                    id: this.state.datazuo1_5_3.id,
                    targetId: this.props.targetId,
                    files: this.state.datazuo1_5_3.files,
                    title: this.state.datazuo1_5_3.title
                },
                majorInfo:this.props.majorInfo
            })
        }
    //     // console.log(this.props.inputconnect.evaluationList);
    //     // console.log(222222222222222222);
    //     // console.log(this.props.inputconnect.errorTestId);
    //     // console.log(this.state.errorTestId);
    //     // if (this.state.testid != this.props.inputconnect.testid) {
    //     //     let idarr = [];
    //     //     for (var i = 0; i < this.props.inputconnect.evaluationList.length; i++) {
    //     //         idarr.push(this.props.inputconnect.evaluationList[i].id);
    //     //         if (this.props.inputconnect.evaluationList[i].id == this.props.inputconnect.testid) {
    //     //             this.setState({
    //     //                 getFlag: this.props.inputconnect.evaluationList[i][4] ?? this.props.inputconnect.getFlag,
    //     //                 errorTestId: this.props.inputconnect.errorTestId,
    //     //                 difficulty: this.props.inputconnect.difficulty,
    //     //                 errorReason: this.props.inputconnect.evaluationList[i][1] || this.props.inputconnect.errorReason,
    //     //                 importanceDegree: this.props.inputconnect.evaluationList[i][2] || this.props.inputconnect.importanceDegree,
    //     //                 remarkInfo: this.props.inputconnect.evaluationList[i][3] || this.props.inputconnect.remarkInfo,
    //     //                 evaluationList: this.props.inputconnect.evaluationList,
    //     //                 testid: this.props.inputconnect.testid
    //     //             });

    //     //         }
    //     //     }
    //     //     console.log(idarr.indexOf(this.props.inputconnect.testid));
    //     //     if (idarr.indexOf(this.props.inputconnect.testid) == -1) {
    //     //         this.setState({
    //     //             errorTestId: this.props.inputconnect.errorTestId,
    //     //             difficulty: this.props.inputconnect.difficulty,
    //     //             getFlag: this.props.inputconnect.getFlag,
    //     //             errorReason: this.props.inputconnect.errorReason,
    //     //             importanceDegree: this.props.inputconnect.importanceDegree,
    //     //             remarkInfo: this.props.inputconnect.remarkInfo,
    //     //             evaluationList: this.props.inputconnect.evaluationList,
    //     //             testid: this.props.inputconnect.testid
    //     //         }, () => {
    //     //         });
    //     //     }
    //     //     // document.getElementById("topic").value=this.state.remarkInfo
    //     // }
    }
    //添加第三个表弹窗
    addTaskMsg3 = () => {
        this.setState({
            addTaskMsgModal: true,
            editingTaskRecord: null,
        })
    }
    // 获取第三个表数据
    effectTasks3 = () => {
        const data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.targetId,
            pageNum: this.state.taskPageNum,
            pageSize: this.state.taskPageSize,
        }
        selectSolveDifficulty(data).then(res => {
            // console.log(res);
            if(res) {
                this.setState({
                    dataSource1_5_3: res?.data,
                    total1_5_3: res?.total,
                    complete1_5_3: res?.cmTotal ?? 0,
                    majorInfo: this.props.majorInfo,
                },()=>{
                    // console.log(this.state.dataSource1_5_3);
                })
            }
        })
    };

    columnsTaskTiJiaoAndAdd = () => {

        this.addTaskForm.props.form.validateFields((err, value) => {
            if (err) {
                return;
            }
            if(value.description==undefined||value.hasSolve==undefined||value.measures==undefined||value.description?.replace(/\s*/g,"").length==0||value.hasSolve.length==0||value.measures?.replace(/\s*/g,"").length==0){

            }else{
                const data = {
                measures: value.measures,
                fileIdList: [],
                hasComplete: value.hasComplete,
                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                targetId: this.state.targetId,
                taskName: value.taskName,
                description: value.description,
                hasSolve:value.hasSolve
            };
            if(this.state.partakeTrainId!=''){
                data.id=this.state.partakeTrainId
            }
            // console.log(data);
            saveSolveDifficulty(data).then(r => {
                this.setState({
                    partakeTrainId:'',
                    taskPageNum:1
                },()=>{
                    this.addTaskForm.props.form.resetFields()
                    // this.addTaskForm.deleteFile();
                    this.effectTasks3()
                })
                message.success("添加成功")
            })
            }
            
        })
        
    }

    columnsTaskTiJiao = () => {

        this.addTaskForm.props.form.validateFields((err, value) => {
            if (err) {
                return;
            }
            if(value.description==undefined||value.hasSolve==undefined||value.measures==undefined||value.description?.replace(/\s*/g,"").length==0||value.hasSolve.length==0||value.measures?.replace(/\s*/g,"").length==0){

            }else{
                const data = {
                measures: value.measures,
                fileIdList: [],
                hasComplete: value.hasComplete,
                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                targetId: this.state.targetId,
                taskName: value.taskName,
                description: value.description,
                hasSolve:value.hasSolve
            };
            if(this.state.partakeTrainId!=''){
                data.id=this.state.partakeTrainId
            }
            // console.log(data);
            saveSolveDifficulty(data).then(r => {
                if(this.state.partakeTrainId==''){
                    this.setState({
                        addTaskMsgModal: false,
                        partakeTrainId:'',
                        taskPageNum:1
                    }, () => {
                        this.effectTasks3()
                        message.success("添加成功")
                    })
                }else{
                    this.setState({
                        addTaskMsgModal: false,
                        partakeTrainId:''
                    }, () => {
                        this.effectTasks3()
                        message.success("修改成功")
                    })
                }
            })
            }
            
        })
    }
    //分页条数切换
    onShowSizeChange = (pageNum, pageSize) => {
        this.setState({ pageNum: 1, pageSize }, this.getMajor);
    }
    pageChangTask = (pageNum) => {
        this.setState({
            taskPageNum: pageNum
        }, this.effectTasks3)
    }
    onShowSizeChangeTask = (pageNum, pageSize) => {
        this.setState({
            taskPageSize: pageSize,
            taskPageNum: pageNum
        }, this.effectTasks3)
    }
    // 查看资料
    handLook = (record,index) => {
        // console.log(record);
        const url = BaseUrl + record.files[index]?.filePath
        // console.log(index);
        this.props.history.push({pathname:"/file_Book", query :{ openUrl: url}} )
    }
    dismiss = () => {
        this.setState({
            isShow: false
        })
    }
    deleteTask = (record) => {
        const data = {
            id: record.id
        };
        // console.log(record);
        deleteSolveDifficulty(data).then(() => {
            message.success("删除成功")
            this.setState({
                taskPageNum:1
            }, () => {
                this.effectTasks3()
            })
            // if ((this.state.total1_5_3 - 1) % this.state.taskPageSize == 0) {
            //     this.setState({
            //         taskPageNum:1
            //     }, () => {
            //         this.effectTasks3()
            //     })
            // }else{
            //     this.effectTasks3()
            // }
        })
        // let data = {
        //     id: record.id,
        //     schoolEduCode: this.state.schoolInfo.schoolEduCode,
        //     targetId: this.state.majorInfo.id
        // }
        // deleteMajor(data).then(res => {
        //     if (res) {
        //         message.success("删除成功")
        //         this.getMajor()
        //     }
        // })

    }
    updateTask = (record) => {
        // console.log(record);
        this.setState({
            addTaskMsgModal: true,
            partakeTrainId: record.id,
            editingTaskRecord: record
        })
    }

    // section 1.5.3
    selectTargetQuestion1_5_3 = () => {
        const data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            targetId: this.state.targetId,
        }
        selectTargetQuestion(data).then(datazuo1_5_3 => {
            this.setState({
                datazuo1_5_3
            })
        })
    }
    onGetFiles1_5_4 = (files) => {
        // //获取原有信息
        const params = this.state.datazuo1_5_3
        // 文件列表
        const finalFiles = params.files ?? []
        // 添加插入的文件列表
        finalFiles.splice(0, 0, ...files)
        // 获取文件id
        params.fileIdList = finalFiles.map(file => file.id)
        // 文件存本地
        params.files = finalFiles
        params.targetId = this.state.targetId
        // 学校编码
        params.schoolEduCode = this.state.schoolInfo.schoolEduCode
        // 保存文件id列表并请求后台告知
        this.setState({ datazuo1_5_3: params }, () => {
            if (this.state.datazuo1_5_3) {
                saveTargetQuestion(params).then(res => {
                    message.success("添加成功")
                    this.selectTargetQuestion1_5_3();
                })
            }
        })
    }
    onDelete1_5_4 = (index) => {
        //获取原有信息
        const params = this.state.datazuo1_5_3
        // 文件列表
        const finalFiles = params.files ?? []
        // 删除下表下的元素
        finalFiles.splice(index, 1)
        // 获取文件id
        params.fileIdList = finalFiles.map(file => file.id)
        params.files = finalFiles
        // 保存文件id列表并请求后台告知
        this.setState({ datazuo1_5_3: params }, () => {
            if (this.state.datazuo1_5_3) {
                saveTargetQuestion(params).then(res => {
                    message.success("删除成功")
                    this.selectTargetQuestion1_5_3();
                })
            }
        })
    }
    //下载文件
    dowLoadFile = (targetMark, targetName) => {
        let now = new Date().getTime();
        axios.get(downloadBusTemplate(targetMark), {
            headers: {
                'content-type': 'application/json; charset=utf-8',
                // token: getToken(),
                
                "z-ts": now,
                "z-sign": md5(now + "")
            },
            responseType: 'blob', // --设置请求数据格式
        }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${targetName}.xls`);
            link.click();
        })
    }
    // 这里根据传进来的数据来导入文件,例如外部id等
    importFileResources = async (e) => {
        let list = [...e.target.files];
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        let checkType = "xls、xlsx";
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!checkType.includes(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            return
        }
        const fromData = new FormData()
        fromData.append('file', list[0])
        fromData.append('targetId', this.props.targetId)
        const schoolEduCode = JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : ""
        fromData.append('schoolEduCode', schoolEduCode)
        fromData.append('templateNo', '1.5.3')
        // todo 此接口需要替换成统一的接口
        excelBusData(fromData).then(res => {
            console.log(111);
            message.success('导入成功')
            this.setState({
                taskPageNum:1
            }, () => {
                this.effectTasks3()
            })
            // 之后给回调到外部重新刷新当前选项即可(暂时简单的刷新,不做本地局部添加数据处理)
            // this.selectTargetResourceReplace()
        })
    }
    render() {
        const { difficulty, getFlag, errorReason, importanceDegree, remarkInfo,total1_5_3,complete1_5_3,dataSource1_5_3 ,majorInfo,openUrl,isShow,partakeTrainId } = this.state;
        const canEdit = getCanEdit()
        // 第三个表展示
        const columnsTask3 = [
            {
                title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                render: (text, record, index) => {
                    return <span>{this.state.taskPageSize*(this.state.taskPageNum-1)+index + 1}</span>
                }
            },
            { title: '实训中痛点难点描述', dataIndex: 'description', key: 'description', align: 'center', width: 200 },
            {
                title: '是否通过虚拟仿真实训有效解决', dataIndex: 'hasSolve', key: 'hasSolve', align: 'center', width: 200,
                render: (text, record) => text === 0 ? <span>否</span> : <span>是</span>
            },
            { title: '解决措施说明', dataIndex: 'measures', key: 'measures', align: 'center', width: 200 },
            // {
            //     title: '查看任务材料', dataIndex: 'check', key: 'check', align: 'center', width: 200,
            //     render: (text, record,index) => record.files ?
            //         <span className={'chakan'} onClick={() => this.handLook(record,index)}>查看</span> :
            //         <span>未上传</span>
            // },
        ];
        if (canEdit) {
            // todo 需要对接口后完成
            columnsTask3.push({
                title: '操作', align: 'center',
                width: 200,
                dataIndex: 'oper',
                key: 'oper',
                render: (text, record) => <div className='render-span vertical_j oper'>
                    <div>
                        <span className="cz" onClick={() => this.updateTask(record)}>编辑</span>
                        <span className="cz" onClick={() => this.deleteTask(record)}>删除</span>
                    </div>
                </div>
            })
        }
        return (
            <div className="conte " style={{ marginBottom: '50px' }}>
                <div>
                    <div className="conteCompPercent">
                        <span>实训中痛点难点总数量</span>
                        <div
                            className="span_input">{total1_5_3}</div>
                        {/*todo 需要根据后台数据走*/}
                        <span>虚拟仿真解决痛点难点数量</span>
                        <div
                            className="span_input"> {complete1_5_3} </div>
                        <span style={{ marginRight: '0px' }}>占比</span>
                        <div
                            className={`span_input ${((complete1_5_3 / total1_5_3) * 100).toFixed(2) < 80?'conteInput_right_warning_border':''}`}    
                            > {total1_5_3==0?"0.00":((complete1_5_3 / total1_5_3) * 100).toFixed(2)} %
                        </div>
                        {
                            ((complete1_5_3 / total1_5_3) * 100).toFixed(2) < 80 ?
                                <span style={{ marginRight: '-20px', color: '#D75746' }}>*该项指标需达到80%</span>
                                :
                                ''
                        }
                    </div>
                    <div className="button_bottom">
                        {
                            canEdit ? <div className='conteBtnDiv'
                                onClick={this.addTaskMsg3}>
                                <Professionalicon /><span
                                    className='conteBtnDivSpan'>添加痛点难点</span>
                            </div> : ""
                        }
                        {
                            canEdit ? <div>
                                <div className='conteBtnDiv'>
                            <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}
                                onChange={this.importFileResources}/><ExcelImport/><span className='conteBtnDivSpan'>Excel导入</span>
                        </div>
                         <div className='conteBtnDiv' onClick={() => this.dowLoadFile('1.5.3', '虚拟仿真实训解决“三高三难”痛点和难点情况')}><Daoru/><span className='conteBtnDivSpan'>下载导入模版</span></div>
                            </div> : ""
                        }
                    </div>
                    <div className='conteTable'  style={{ marginBottom: '20px' }}>
                        <Table columns={columnsTask3}
                            dataSource={this.state.dataSource1_5_3}
                            bordered={true}
                            locale={{
                                emptyText: <>
                                    <div className="Empty-content">
                                        <div className="text">
                                            <img
                                                src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'} />
                                            <span>暂无数据</span>
                                        </div>
                                    </div>
                                </>
                            }}
                            pagination={
                                {
                                    total: this.state.total1_5_3,
                                    pageSize: this.state.taskPageSize,
                                    current: this.state.taskPageNum,
                                    onChange: this.pageChangTask,
                                    onShowSizeChange: this.onShowSizeChangeTask,
                                    pageSizeOptions: ['10', '20', '30', '40'],
                                    showSizeChanger: true,
                                    // position:'bottomRight'
                                }
                            }
                        />
                    </div>
                    <UploaderComponent
                    editEnable={canEdit}
                    targetId={this.state.targetId}
                        uploadTitle='添加佐证材料'
                        checkTitle='查看佐证材料'
                        // fileList={this.state.datazuo1_5_3.files}
                        // onDelete={this.onDelete1_5_4}
                        // onGetFiles={this.onGetFiles1_5_4}
                    />
                </div>
                {/* 添加专业*/}
                <Modal
                            title={partakeTrainId==''?'添加痛点难点':'修改痛点难点'}
                            visible={this.state.addTaskMsgModal}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            onCancel={this.handleCancel}
                            className="Professional_con"
                            wrapClassName="wappop_up"
                            footer={
                                <div className='addModalBtn'>
                                    <Button onClick={() => this.setState({ addTaskMsgModal: false })}>取 消</Button>
                                    <Button onClick={this.columnsTaskTiJiao} type='primary'>提交</Button>
                                    {partakeTrainId=='' ?  
                                    <Button onClick={this.columnsTaskTiJiaoAndAdd} type='primary'>提交并添加</Button>
                                    :
                                        ''
                                    }
                                </div>
                            }
                        >
                            <AddConstruction record={this.state.editingTaskRecord} wrappedComponentRef={form => {
                                this.addTaskForm = form;
                            }} />
                        </Modal>
                        <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
            </div>
        );
    }
}

