// 建设方案描述-预览
import React from "react";
import {Form, Row, Col, Radio, Table, Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import {TotalAera, Gross, EmployeesSeatNum} from '../../components/iconSvg'
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {projectSituationPreview, schoolBasicDataPreview} from "../../api/preview";

export default Form.create()(
    withRouter(
        class Project_Situation extends React.Component {
            state = {
                radioValue: 1,
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'index',
                        key: 'index',
                        align: 'center',
                        width: 80,
                        render: (text, record, index) => {
                            return <span>{index + 1}</span>
                        }
                    },
                    {
                        title: '姓名',
                        dataIndex: 'memberName',
                        key: 'memberName',
                        align: 'center',
                        width: 100
                    },
                    {
                        title: '所在单位',
                        dataIndex: 'memberUnit',
                        key: 'memberUnit',
                        align: 'center',
                        // width: 300
                    },
                    {
                        title: '专业技术职务',
                        dataIndex: 'twoLevelPositionName',
                        key: 'twoLevelPositionName',
                        align: 'center',
                        // width: 200
                    },
                    {
                        title: '行政职务',
                        dataIndex: 'administrativePosition',
                        key: 'administrativePosition',
                        align: 'center',
                        // width: 200
                    },
                    {
                        title: '承担任务',
                        dataIndex: 'bearTask',
                        key: 'bearTask',
                        align: 'center',
                        // width: 200
                    },
                    {
                        title: '人员来源',
                        dataIndex: 'personnelConstitute',
                        key: 'personnelConstitute',
                        align: 'center',
                        width: 200,
                        render: (text, record, index) => {
                            // return
                            if (text === 1) {
                                return "无"
                            } else if (text === 2) {
                                return "学校人员"
                            } else if (text === 3) {
                                return "企业人员"
                            } else {
                                return ""
                            }
                        }
                    }
                ],
                groupPeopleList: [],
                previewParam: Cookies.get("previewParam") ? JSON.parse(decodeURI(Cookies.get("previewParam"))) : {},
                baseVo: {},
                projectHeadBasicVo: {},
                projectMemberVo: {},
                projectNum: 0,
                schoolNum: 0,
                enterpriseNum: 0,
                enterpriseTechnologyNum: 0
            };

            componentDidMount() {
                this.projectSituationPreview();
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
            }

            projectSituationPreview = () => {
                let {previewParam} = this.state;
                projectSituationPreview({
                    taskId: previewParam.taskId,
                    schoolEduCode: previewParam.schoolEduCode
                }).then(res => {
                    if (res) {
                        this.setState({
                            baseVo: res.baseVo,
                            projectHeadBasicVo: res.projectHeadBasicVo,
                            groupPeopleList: (res.projectMemberVo.isMainMemberVos || []).concat(res.projectMemberVo.notMainMemberVos || []),
                            projectNum: res.projectMemberVo.projectNum,
                            schoolNum: res.projectMemberVo.schoolNum,
                            enterpriseNum: res.projectMemberVo.enterpriseNum,
                            enterpriseTechnologyNum: res.projectMemberVo.enterpriseTechnologyNum,
                            projectMemberVo: res.projectMemberVo
                        });
                    }
                })
            }
            // 左侧跟随屏幕
            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:60px;position:fixed;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }
            secondPreviewPage = () => {
                this.props.history.push('/construction_scheme')
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {
                    baseVo,
                    projectHeadBasicVo,
                    columns,
                    groupPeopleList,
                    projectNum,
                    schoolNum,
                    enterpriseNum,
                    enterpriseTechnologyNum
                } = this.state;
                return (
                    <div className="Project_Situation_main" style={{minHeight: "1200px"}}>
                        <Heder/>
                        <div className="Task-preview clearfix">
                            <div className="main_cont" id="main_cont">
                                <div className="Task-bottom-preview">
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="preview_left" id="leftmain" style={{"top": '0px'}}>
                                            <LeftMeinlist processCode={'1-2'}/>
                                        </Col>
                                        <Col span={20} className="preview_right">
                                            <div className="content">
                                                <div className="top-right top-rightG">
                                                    <h6>任务书预览页</h6>
                                                    <span><i><b>2</b>/7</i></span>
                                                </div>
                                                <div className="step-top-common">
                                                    <div className='topTitleI'></div>
                                                    <h3>虚拟仿真实训基地建设项目情况</h3></div>

                                                <div className="bottom">
                                                    <h2>2. 项目团队情况</h2>
                                                    <div className="list listTop">
                                                        <h3>2-1 项目负责人基本信息</h3>
                                                        <ul>
                                                            <li>
                                                                <h4>姓名：<span>{projectHeadBasicVo.userName}</span></h4>
                                                                <h4>性别：<span>{!projectHeadBasicVo.sex ? '' : projectHeadBasicVo.sex === 1 ? '男' : projectHeadBasicVo.sex === 2 ? '女' : '未知'}</span>
                                                                </h4>
                                                                <h4>出生年月：<span>{projectHeadBasicVo.birthday}</span></h4>
                                                            </li>
                                                            <li>
                                                                <h4>学历：<span>{projectHeadBasicVo.education}</span></h4>
                                                                <h4>学位：<span>{projectHeadBasicVo.academicDegree ? (projectHeadBasicVo.academicDegree === 1 ? '学士' : projectHeadBasicVo.academicDegree === 2 ? '硕士' : projectHeadBasicVo.academicDegree === 3 ? '博士' : '其他') : ""}</span>
                                                                </h4>
                                                                <h4>办公电话：<span>{projectHeadBasicVo.workPhone}</span>
                                                                </h4>
                                                            </li>
                                                            <li>
                                                                <h4>联系方式：<span>{projectHeadBasicVo.contactMode}</span>
                                                                </h4>
                                                                <h4>行政职务：<span>{projectHeadBasicVo.userPost}</span></h4>
                                                                <h4>专业技术职务：<span>{projectHeadBasicVo.professionalTechnology}</span>
                                                                </h4>
                                                            </li>
                                                            <li>
                                                                <Row className='listRow'>
                                                                    <Col className='listRowCol' span={8}>
                                                                        <h4>院系：<span>{projectHeadBasicVo.department}</span>
                                                                        </h4></Col>
                                                                    <Col className='listRowCol' span={16}>
                                                                        <h4>电子邮箱：<span>{projectHeadBasicVo.email}</span>
                                                                        </h4></Col>
                                                                </Row>
                                                            </li>
                                                        </ul>
                                                        <h3>2-2 项目负责人代表性成就</h3>
                                                        <div className='informationBasic'>
                                                            <p>{projectHeadBasicVo.userAchievement}</p>
                                                        </div>
                                                        {/* <h3>2-3 项目团队情况</h3>
                                                        <div className='informationBasic'>
                                                            <p>{projectHeadBasicVo.projectTeam}</p>
                                                        </div> */}
                                                        <h3>2-3 团队成员</h3>
                                                        <div className='membersTable'>
                                                            {/*<p>项目团队成员</p>*/}
                                                            <Table columns={columns} dataSource={groupPeopleList}
                                                                   pagination={false}/>
                                                            <div className='totalNumber'>
                                                                <div className='totalNumberDiv1'>
                                                                    <span className='totalTitleColor0'
                                                                          style={{marginRight: '15px'}}>合计</span>
                                                                    <span className='totalTitleColor0'>项目团队总数（人）</span>
                                                                    <span
                                                                        className='totalTitleColor1'>{projectNum}</span>
                                                                    <span
                                                                        className='totalTitleColor0 totalTitleWidth'>|</span>
                                                                    <span className='totalTitleColor0'>学校人员数量（人）</span>
                                                                    <span
                                                                        className='totalTitleColor1'>{schoolNum}</span>
                                                                    <span
                                                                        className='totalTitleColor0 totalTitleWidth'>|</span>
                                                                    <span className='totalTitleColor0'>企业人员数量（人）</span>
                                                                    <span
                                                                        className='totalTitleColor1'>{enterpriseNum}</span>
                                                                </div>
                                                                {/* <div className='totalNumberDiv1'>
                                                                    <span className='totalTitleColor0'>企业人员中，信息技术支持人员数量（人）</span>
                                                                    <span className='totalTitleColor1'>{enterpriseTechnologyNum}</span>
                                                                </div> */}
                                                            </div>
                                                        </div>

                                                        <h3>2-4 团队成员成果说明</h3>
                                                        <div className='informationBasic'>
                                                            <p>{projectHeadBasicVo.teamDescription}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bottom-but">
                                                    <Button className="but-li" type="primary"
                                                            onClick={() => this.secondPreviewPage()}>
                                                        下一页
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
