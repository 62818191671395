import {Component} from 'react';
import './index.css';

const iconRc = {
    'success': 'https://zjyddev.oss-cn-beijing.aliyuncs.com/zjyd-front-img/success_icon.png',
    'error': 'https://zjyddev.oss-cn-beijing.aliyuncs.com/zjyd-front-img/error_icon.png',
    'warning': 'https://zjyddev.oss-cn-beijing.aliyuncs.com/zjyd-front-img/warning_icon.png'
}

function selDom(el) {
    return document.querySelector(el);
}

function createHint(options) {
    let {title = '标题', content = '内容', isCancel = false, icon, cancelText = '取消', okText = '确定',} = options;
    let div = document.createElement('div');
    div.className = 'hint_box_mask';
    div.innerHTML = `<div class="hint_content">
    <div class='title'>
    ${title}
    </div>
    <div class="content">
        <div>
            <span><img src=${icon} alt='' /></span>
            <p>${content}</p>
        </div>
    </div>
    <div class="footer">
    ${isCancel ?
        `<span class='cancel btn'>${cancelText}</span><span class='i_see btn'>${okText}</span>` :
        `<span class='i_see btn'>${okText}</span>`
    }
    </div>
</div>`;
    return div;
}

function createMessage(options) {
    console.log("options:::::::::", options)
    let {icon, content = 'content'} = options;
    let div = document.createElement('div');
    div.className = 'my_message_box';
    div.innerHTML = `<span><img src=${icon} alt='' /></span><p>${content}</p>`;
    return div;
}

/**
 *
 * @param {*} options
 * title 标题
 * content 内容
 * type 提示类型
 * cancelText 取消按钮的文字
 * okText 确定按钮的文字
 * isCancel 是否需求取消按钮
 * cancel 点击取消执行的函数
 * sure 点击确定执行的回调函数
 */
function hint(options = {}) {
    let {type = 'success', cancel, sure,} = options;

    function remove() {
        document.body.removeChild(document.querySelector('.hint_box_mask'));
    }

    let icon = iconRc[type];
    /*  if (window.conDiv) {
          document.body.appendChild(window.conDiv);
      } else {
          document.body.appendChild(createHint({ icon, ...options }));
          window.conDiv = createHint({ icon, ...options });
      }*/
    document.body.appendChild(createHint({icon, ...options}));
    window.conDiv = createHint({icon, ...options});
    let canEl = selDom('.hint_box_mask .cancel');
    let surEl = selDom('.hint_box_mask .i_see');
    canEl && (canEl.onclick = () => {
        cancel && cancel();
        remove();
    })
    surEl && (surEl.onclick = () => {
        sure && sure();
        remove();
    })
}

Component.prototype.hint = hint;

/**
 *
 * @param {object} params
 * type 类型
 * content 内容
 * duration 多少毫秒后消失
 */
function message({type, content, duration = 2500}) {
    let el = selDom('.my_message_box');
    if (el) {
        return;
    }
    let icon = iconRc[type];
    document.body.appendChild(createMessage({icon, content}));
    window.mesDiv = createMessage({icon, content});
    let t = setTimeout(() => {
        document.body.removeChild(selDom('.my_message_box'));
        clearTimeout(t)
    }, duration);
}

Component.prototype.mesSuccess = (content, duration) => message({type: 'success', content, duration});
Component.prototype.mesError = (content, duration) => message({type: 'error', content, duration});
Component.prototype.mesWarning = (content, duration) => message({type: 'warning', content, duration});

function  mesError(content, duration){
    return message({type: 'error', content, duration});
}
function  mesWarning(content, duration){
    return message({type: 'warning', content, duration});
}

export {
    hint,
    message,
    mesError,
    mesWarning
}