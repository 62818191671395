// 添加特色创新
import React, {Component} from 'react';
import {Button, Form, Input, message,Tooltip,Spin } from 'antd';
import moment from "moment";
import {Deleticon, EdioIcon, UpIcon,Upiconwc,Upicondele} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import "./index.scss";
import { uploadFile } from '../../../api/uploadFile';
import { isNotEmpty } from '../../../until/isEmpty';

const {TextArea} = Input;

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

@withRouter
class AddForm extends Component {
    state = {
        name: '',
        tableData:this.props.tableData,
        candit:false,
        item:this.props.item,
        fileList:[]
    };

    componentDidMount() {
        // const {step,item} = this.state
        // if(step===0){
        //     this.props.form.setFieldsValue({
        //         'name0': item.objectName,
        //         'assignmentName0': item.objectContent,
        //     })
        // }else if(step===1){
        //     this.props.form.setFieldsValue({
        //         'name1': item.objectName,
        //         'assignmentName1':item.objectContent,
        //     })
        // }else{
        //     this.props.form.setFieldsValue({
        //         'name2': item.objectName,
        //         'assignmentName2': item.objectContent,
        //     })
        // }
    }

    

    //删除文件
    deleteFile = (index) =>{
        let {tableData} = this.props;
        tableData.splice(index,1)
        this.setState({tableData:tableData}) 


    }

    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc/docx、xls/xlsx、ppt/pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };

    // 输入框剩余字数联动
    totalGoalTextLength = (e) => {
        this.setState({name: e.target.value})
    }
    //上传方法
    multipartUpload = async (e) => {
        console.log("====================上传文件")
        let list = [...e.target.files];
        const {tableData} = this.state;
        if ((tableData.length + list.length) >20) {
            message.error("备课包资源数量不能超过20")
            return;
        }
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
                return;
            }
        });
        if (flag) {
            message.error("上传的资源格式不符")
            return;
        }
        this.uploadFile(list[0])
       
    };
    resetEffect = () => {
        console.log("============resetEffect")
        let {item,tableData}=this.props
        item.objectContent=""
        item.objectName=""
        item.files=null
        tableData=tableData.splice(0,tableData.length)
        this.setState({
            item:item,tableData:tableData
        })
            
    }
    componentWillReceiveProps(nextProps, nextContext) {

        let {tableData,num} = nextProps;
        const nextStep = nextProps.step;
        const step = this.props.step
        console.log("测试step============" + step)
        console.log("测试nextStep============" + nextStep)
        console.log("测试============" + tableData.length)
        console.log("测试state tableData============" + this.state.tableData.length)

        if(step!=nextStep||tableData.length>0||isNotEmpty(this.props.item.id)){
            this.setState({tableData:tableData})
        }
       
       
    }
    /**
     * 保存综述详情 接口
     * */ 
    saveEffectReview=()=>{
        this.props.form.validateFields((err, value) => {
            if (!err) {
                var name=""
                var content=""
                const {step}=this.props
                if(step===0){
                    name=value.name0
                    content= value.assignmentName0
                }else if(step===1){
                    name=value.name1
                    content= value.assignmentName1
                }else{
                    name=value.name2
                    content= value.assignmentName2
                }
                const {item} = this.props;
                this.props.saveEffectReviewDetail(this.props.form,name,content,this.state.tableData,item.id)
            }

        })
      
    }

       //上传文件
       uploadFile = async (v) => {
        const {fileType} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {

            if (v.size > 20971520) {
                return this.mesWarning('文件不能大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    this.getResult(res);
                    reslove(1);
                }
            })
        });
    };

     //上传完成获取返回结果过
     getResult = (val) => {
        let {num} = this.props;
        let {tableData}=this.state
        tableData.push({
            id: val.id,
            originFn: val.originFileName,
            resourceSize: val.fileSize,
            resourceSuffix: val.mediaType
        })
        this.setState({tableData:tableData})
        // 解决文件重复上传，触发失效的办法
        document.getElementById("file_"+num).value = ''
    };
    

    del=()=>{
    
        const {item} = this.props;
        const id=item.id
        this.setState({tableData:[]})
        this.props.form.resetFields()
        if(isNotEmpty(id)){
            this.props.delFormItem(id)
         
        }
        
       

    }
    edit=()=>{
        const edit=!this.state.edit
        this.state({
            edit:edit
        })
    }

    isEdit=()=>{
        const {item} = this.props;
        if(item!=null&&isNotEmpty(item.id)){
             return true   
        }else {
            return true
        }
        return this.state.edit
    }



    getFileTooltip=()=>{
        const {tableData}=this.state
        return  tableData?tableData.map((item, index) => {
           return (<Tooltip  key={index}  placement="topLeft" title={item.originFn}><li className="title_de">{item.id? <Upiconwc />: <Spin />}<span className="tie_span">{item.originFn}</span><i className="Upicona" onClick={this.deleteFile.bind(this,index)}><Upicondele/></i> </li></Tooltip>) 
        }) : ''  
    }


    render() {
        let {fileList} = this.state;
    
        const {getFieldDecorator} = this.props.form
        const {name,file} = this.state;
        const {formData, ifComplete, ifEdit, num, subName, step,item,tableData} = this.props
        const labelWidth = 110
        return (
            <div className="Comment-AddForm">
                <div className="AddForm_top">
                    <span className="span_icon" onClick={this.del}><Deleticon/>删除 </span>
                    {/* <span className="span_icon" onClick={this.edit}><EdioIcon />编辑</span> */}
                </div>
                <Form>
                    <Form.Item label={`${formData.label1}${this.props.num + 1}`} onFocus={this.doFocus}
                               labelCol={{style: {width: labelWidth}}}
                               onBlur={this.doBlur} disabled={this.isEdit}>
                        {getFieldDecorator('name' + step, {
                            initialValue:  item.objectName,
                            rules: [
                                {
                                    required: true,
                                    message: `请填写${formData.label1}${this.props.num + 1}`,
                                },
                                {
                                    min: 2,
                                    message: "文本最少2字"
                                },
                                {
                                    max: 100,
                                    message: "文本最多100字"
                                },
                            ],
                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                        })(<Input  maxLength={100} className="input" onChange={(e) => this.totalGoalTextLength(e)}
                                  placeholder="请输入" suffix={
                            <span>{this.props.form.getFieldValue(`name` + step) ? this.props.form.getFieldValue(`name` + step).length : 0}/100</span>}/>)}
                    </Form.Item>
                    <Form.Item label={`${formData.labelDes}`} labelCol={{style: {width: labelWidth}}} onFocus={this.doFocus} onBlur={this.doBlur} disabled={this.isEdit}>
                        {getFieldDecorator('assignmentName' + step, {
                             initialValue:  item.objectContent,
                            rules: [
                                {
                                    required: true,
                                    message: `请填写详细说明`,
                                },
                                {
                                    min: 2,
                                    message: "文本最少2字"
                                },
                                {
                                    max: 1000,
                                    message: "文本最多1000字"
                                },
                            ],
                        })(<TextArea maxLength={1000} className="TextArea-pic"    rows={3}/>)}
                        <p className="font-num">{this.props.form.getFieldValue(`assignmentName` + step) ? this.props.form.getFieldValue(`assignmentName` + step).length : 0}/1000</p>
                    </Form.Item>
                    <div className="li">
                        <div className="li_top">
                            <div className="span_right">
                                <label htmlFor={"file_"+num} className="inputlabelBox">
                                    <span disabled={this.isEdit} ><UpIcon/>添加附件</span>
                                </label>
                                <input
                                    type="file"
                                    onChange={this.multipartUpload}
                                    name={"myfile"+num}
                                    id={"file_"+num}
                                    style={{display: "none"}}
                                />
                               <div>{tableData?this.getFileTooltip():""}</div>
                            </div>


                            
                            
                        </div>
                    </div>
                </Form>
                <div className="bottom-but">
                    {/* <Button className="but-li" onClick={this.resetEffect}
                            htmlType="submit">
                        重置
                    </Button> */}
                    <Button className="but-li"
                            type="primary"
                            htmlType="submit" onClick={this.saveEffectReview}>
                        保存
                    </Button>
                </div>
            </div>
        );
    }
}

const FormComponent = Form.create()(AddForm);
export default FormComponent;