import { post } from '../../until/axios_instans';
let url = window.apiShixun+"/api/v1/platformAbility/";
// 获取平台能力信息
export function getAbility(data) {
    return post(url+"getAbility", data);
}
// 修改指标条件
export function updateCondition(data) {
    return post(url+"updateCondition", data);
}
// 添加佐证材料
export function addEvidence(data) {
    return post(url+"addEvidence", data);
}
// 添加数据互通
export function addApply(data) {
    return post(url+"addApply", data);
}
// 修改数据互通
export function updateApply(data) {
    return post(url+"updateApply", data);
}
// 删除数据互通
export function deleteApply(data) {
    return post(url+"deleteApply", data);
}
// 获取数据互通列表
export function getApplies(data) {
    return post(url+"getApplies", data);
}
// 查看佐证材料列表
export function getEvidences(data) {
    return post(url+"getEvidences", data);
}
// 获取佐证材料信息
export function getEvidence(data) {
    return post(url+"getEvidence", data);
}
// 删除佐证材料
export function deleteEvidence(data) {
    return post(url+"deleteEvidence", data);
}
// 导入excel，1.3.4列表
export function importApply(data) {
    return post(url+"importApply", data);
}
// 修改1.3.4指标要素
export function updateApplyFactor(data) {
    return post(url+"updateApplyFactor", data);
}

