// 建设方案描述-预览
import React from "react";
import { Form,Row, Col,Radio,Table,Button} from 'antd';
import "./index.scss";
import '@/reset_style/index.less';
import Heder from "../../components/header";
import Footer from "../../components/footer";
import LeftMeinlist from "../components/Left_preview";
import { withRouter } from "react-router-dom";
import {constructionPlanPreview} from "../../api/preview";
import Cookies from "js-cookie";

export default Form.create()(
  withRouter(
    class ConstructionScheme extends React.Component {
      state = {
        previewParam: Cookies.get("previewParam")?JSON.parse(decodeURI(Cookies.get("previewParam"))):{},
        constructionPlan: {}
      };
      componentDidMount() {
        this.constructionPlanPreview();
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        window.addEventListener('scroll', this.bindHandleScroll)
      }
      constructionPlanPreview = () => {
        let {previewParam} = this.state;
        constructionPlanPreview({taskId: previewParam.taskId, schoolEduCode: previewParam.schoolEduCode}).then(res => {
          if (res) {
            this.setState({
              constructionPlan: res
            });
          }
        })
      }
      // 左侧跟随屏幕
      bindHandleScroll=(event)=> {
        let ctx=this;
        let clientHeight = document.documentElement.clientHeight; //可视区域高度
        let scrollTop  = document.documentElement.scrollTop;  //滚动条滚动高度
        if (scrollTop > 200) {
          document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:60px;position:fixed;height: ${clientHeight-240}px`);
        } else {
          document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight-240}px`);
        }
      }
      secondPreviewPage = () => {
        this.props.history.push('/tasks_Schedule')
      }
      render() {
          const {getFieldDecorator }=this.props.form
          const {constructionPlan} = this.state;
        return (
            <div className="construction_Scheme" style={{minHeight:"1200px"}}>
                <Heder/>
                <div className="Task-preview clearfix">
                    <div className="main_cont" id="main_cont">
                        <div className="Task-bottom-preview">
                            <Row type="flex" justify="space-between">
                                <Col span={4} className="preview_left" id="leftmain" style={{"top":'0px'}}>
                                    <LeftMeinlist processCode={'1-3'}/>
                                </Col>
                                <Col span={20} className="preview_right">
                                    <div className='content'>
                                        <div className='top-right'>
                                            <h6 className='mission '>任务书预览页</h6>
                                            <span><i><b>3</b>/7</i></span>
                                        </div>
                                        <div className='bottom'>
                                            <h2>3. 建设方案综述</h2>
                                            <div className='list'>
                                                <h3>1 建设基础</h3>
                                                <div className='cont'>
                                                    <h4>{constructionPlan.constructionBasics}</h4>
                                                </div>
                                            </div>
                                            <div className='list'>
                                                <h3>2 建设思路和建设目标</h3>
                                                <div className='cont'>
                                                    <h4>{constructionPlan.constructionObjectives}</h4>
                                                </div>
                                            </div>
                                            <div className='list'>
                                                <h3>3 建设内容</h3>
                                                <div className='list'>
                                                    <h3>3-1 条件建设</h3>
                                                    <div className='cont'>
                                                        <h4>{constructionPlan.constructionConditions}</h4>
                                                    </div>
                                                </div>

                                                <div className='list'>
                                                    <h3>3-2 资源建设</h3>
                                                    <div className='cont'>
                                                        <h4>{constructionPlan.constructionResources}</h4>
                                                    </div>
                                                </div>

                                                <div className='list'>
                                                    <h3>3-3 团队建设</h3>
                                                    <div className='cont'>
                                                        <h4>{constructionPlan.constructionTeam}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bottom-but">
                                            <Button className="but-li" type="primary"  onClick={()=> this.secondPreviewPage()}>
                                                下一页
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
      }
    }
  )
);
