// 项目团队情况新增、编辑弹窗
import React from "react";
import {Form, Radio, Input, Select} from 'antd';
import "./index.scss";
import {withRouter} from "react-router-dom";
import {getTechnicalPosition, addProjectMember} from "../../../../api/school";
import Cookies from "js-cookie";
import {selectRegion} from "../../../../api/sharing";
import AddTeacherMaterial from "../../../../components/addTeacherMaterial";

const monthFormat = 'YYYY-MM-DD';
const {Option} = Select;

class XmtdAddmodal extends React.Component {
    state = {
        oneLevelList: [],
        twoLevelList: [],
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        groupPeopleList: [],
        saveUpdate: '',
        xzcyModal: true,
    };

    componentDidMount() {
        this.props.onRef(this)
        let fromDetail = this.props.fromDetail

        getTechnicalPosition().then(res => {
            this.setState({
                groupPeopleList: this.props.groupPeopleList,
                saveUpdate: this.props.saveUpdate,
                oneLevelList: res,
            })

            if (fromDetail) {
                console.log('fromDetail', fromDetail)
                this.props.form.setFieldsValue({
                    id: fromDetail.id,
                    administrativePosition: fromDetail.administrativePosition,
                    bearTask: fromDetail.bearTask,
                    memberName: fromDetail.memberName,
                    memberUnit: fromDetail.memberUnit,
                    personnelConstitute: fromDetail.personnelConstitute,
                    unitNature: fromDetail.unitNature,
                    oneLevelPosition: fromDetail.oneLevelPosition,
                    twoLevelPosition: fromDetail.twoLevelPosition,
                })
                let oneLevels = res.filter(e => e.id === fromDetail.oneLevelPosition)
                console.log('oneLevels', oneLevels)
                if (oneLevels && 0 < oneLevels.length) {
                    let oneLevel = oneLevels[0]

                    this.setState({
                        twoLevelList: oneLevel.technicalPositionTwoVoList,
                    })
                }
            }
        })
    }

    getTechnicalPosition = () => {
        getTechnicalPosition().then(res => {
            if (res) {
                this.setState({
                    oneLevelList: res
                })
            }
        })
    }

    selectOneLevelMethod = value => {
        let {oneLevelList} = this.state;
        let twoList = oneLevelList.filter(e => e.id === value);
        let twoLevelList = []
        let twoLevelPosition = ""
        if (twoList.length > 0) {
            twoLevelList = twoList[0].technicalPositionTwoVoList
            twoLevelPosition = (twoLevelList && twoLevelList.length > 0) ? twoLevelList[0].id : ""
        }
        this.setState({
            twoLevelList: twoLevelList,
            oneLevelPosition: value,
            twoLevelPosition: twoLevelPosition
        }, () => {
            this.props.form.setFieldsValue({"twoLevelPosition": ''})
            this.props.form.setFieldsValue({"oneLevelPosition": value})
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {
            twoLevelList,
            oneLevelList,
        } = this.state;
        return (
            <div className="xmtd_Addmodal">
                <Form>
                    {/*<Form.Item className="Item-li" label={`类型`}>*/}
                    {/*    {getFieldDecorator('isMain', {*/}
                    {/*        rules: [*/}
                    {/*            {*/}
                    {/*                required: true,*/}
                    {/*                message: `请选择类型`,*/}
                    {/*            },*/}
                    {/*        ],*/}
                    {/*    })(<Radio.Group>*/}
                    {/*        <Radio value={1}>主要成员</Radio>*/}
                    {/*        <Radio value={0}>其他成员</Radio>*/}
                    {/*    </Radio.Group>)}*/}
                    {/*</Form.Item>*/}
                    <Form.Item>
                        {getFieldDecorator('id', {})}
                    </Form.Item>
                    <Form.Item className="Item-li" label={`姓名`}>
                        {getFieldDecorator('memberName', {
                            rules: [
                                {
                                    required: true,
                                    message: `请输入姓名`,
                                },
                            ],
                        })(<Input className="input"/>)}
                    </Form.Item>
                    <Form.Item className="Item-li" label={`所在单位`}>
                        {getFieldDecorator('memberUnit', {
                            rules: [
                                {
                                    required: true,
                                    message: `请填写所在单位`,
                                },
                            ],
                        })(<Input className="input"/>)}
                    </Form.Item>
                    <Form.Item className="Item-li" label={`单位性质`}>
                        {getFieldDecorator('unitNature', {
                            rules: [
                                {
                                    required: true,
                                    message: `请选择单位性质`,
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>学校</Radio>
                            <Radio value={2}>企业</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <div style={{display: 'flex'}}>
                        <Form.Item className="Item-li" label={`专业技术职务`}>
                            {getFieldDecorator('oneLevelPosition', {
                                initialValue: undefined,
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择专业技术职务一级`,
                                    },
                                ],
                            })(
                                <Select className="select-li" dropdownClassName='Date-main-select'
                                        onChange={this.selectOneLevelMethod}
                                        placeholder={"请选择专业技术职务"}
                                >
                                    {oneLevelList.map(item => (
                                        <Option key={item.id} value={item.id}>{item.name}</Option>
                                    ))}
                                </Select>
                            )}

                        </Form.Item>
                        <Form.Item className="Item-li" label={``} labelCol={{width:0}} style={{marginLeft:'-190px'}}>

                            {getFieldDecorator('twoLevelPosition', {
                                initialValue: "",
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择专业技术职务二级`,
                                    },
                                ],
                            })(<Select className="select-li" dropdownClassName='Date-main-select'
                                       onChange={this.onSecondCityChange}
                            >
                                {twoLevelList.map(item => (
                                    <Option key={item.id} value={item.id}>{item.name}</Option>
                                ))}
                            </Select>)}
                        </Form.Item>
                        {/*<Form.Item className="Item-li" style={{marginLeft:'95px'}} >*/}

                        {/*</Form.Item>*/}
                    </div>
                    <Form.Item className="Item-li" label={`行政职务`}>
                        {getFieldDecorator('administrativePosition', {
                            rules: [
                                {
                                    required: true,
                                    message: `请输选择行政职务`,
                                },
                            ],
                        })(<Input className="input" maxLength={20}/>)}
                        <span
                            className='textarea_len'>{this.props.form.getFieldValue(`administrativePosition`) ? this.props.form.getFieldValue(`administrativePosition`).length : 0}/20</span>
                    </Form.Item>
                    <Form.Item className="Item-li" label={`承担任务`}>
                        {getFieldDecorator('bearTask', {
                            rules: [
                                {
                                    required: true,
                                    message: `请选择承担任务`,
                                },
                            ],
                        })(<Input className="input" maxLength={20}/>)}
                        <span
                            className='textarea_len'>{this.props.form.getFieldValue(`bearTask`) ? this.props.form.getFieldValue(`bearTask`).length : 0}/20</span>
                    </Form.Item>
                    <Form.Item className="Item-li" label={`人员来源`}>
                        {getFieldDecorator('personnelConstitute', {
                            rules: [
                                {
                                    required: true,
                                    message: `请选择人员来源`,
                                },
                            ],
                        })(<Select className="select-li" dropdownClassName='Date-main-select'
                        >
                            <option key={1} value={1}>无</option>
                            <option key={2} value={2}>学校人员</option>
                            <option key={3} value={3}>企业人员</option>

                        </Select>)}
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

XmtdAddmodal = Form.create()(XmtdAddmodal);
export default XmtdAddmodal;
