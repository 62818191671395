/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Button, Col, Form, Input, InputNumber, message, Row} from 'antd';
import './index.scss';
import {
    addExpenditureSource,
    addInvest,
    getExpenditureSource,
    updateExpenditureSource,
    updateInvest
} from "../../../../api/invest";
import Cookies from "js-cookie";
import {isNotEmpty} from "../../../../until/isEmpty";

const {TextArea} = Input;

class FormCom extends Component {

    state = {
        priceTotal: 0,
        ratioNum: 0,
        ifEdit: false,
        ifComplete: true,

        sysType: '',
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",

        expenditureSourceList: [
            {
                "type": 1,
                "investMoney": 0,
                "investScale": 0
            },
            {
                "type": 2,
                "investMoney": 0,
                "investScale": 0
            },
            {
                "type": 3,
                "investMoney": 0,
                "investScale": 0
            },
            {
                "type": 4,
                "investMoney": 0,
                "investScale": 0
            },
        ],

    };

    componentDidMount() {
        let priceTotal = 0;
        let ratioNum = 0;

        this.state.expenditureSourceList.map(item => {
            priceTotal = this.accAdd(priceTotal, item.investMoney ?? 0);
            ratioNum = this.accAdd(ratioNum, item.investScale ?? 0);
        })

        this.setState({
            ifEdit: false,
            ifComplete: true,
            priceTotal: priceTotal,
            ratioNum: ratioNum
        })

        this.OSnow()

        this.getExpenditureSourceInfoRequest()
        // console.log("form:本次的componentDidMount结束：：：")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.flag !== this.state.flag) {
            console.log("componentDidUpdate")

            this.updateSum()
        }
    }

    updateSum = () => {
        let priceTotal = 0;
        let ratioNum = 0;
        this.state.expenditureSourceList.map(item => {
            priceTotal = this.accAdd(priceTotal, item.investMoney)
            ratioNum = this.accAdd(ratioNum, item.investScale);
        })
        this.setState({
            ifEdit: false,
            ifComplete: true,
            priceTotal: priceTotal,
            ratioNum: ratioNum
        }, () => {
            this.updateRatio()
        })
    }

    accAdd = (arg1, arg2) => {
        var r1, r2, m;
        try {
            r1 = arg1.toString().split(".")[1].length
        } catch (e) {
            r1 = 0
        }
        try {
            r2 = arg2.toString().split(".")[1].length
        } catch (e) {
            r2 = 0
        }
        m = Math.pow(10, Math.max(r1, r2))
        return (arg1 * m + arg2 * m) / m
    }

    // section 处理百分比
    updateRatio = () => {
        let {expenditureSourceList, priceTotal} = this.state
        for (const index in expenditureSourceList) {
            let item = expenditureSourceList[index]

            if (priceTotal === 0) {
                item.investScale = 0.00
            } else {
                let radio = (item.investMoney / priceTotal) * 100.0
                item.investScale = radio.toFixed(2)
            }
        }

        this.setState({expenditureSourceList})
    }

    OSnow = () => {
        var agent = navigator.userAgent.toLowerCase();
        var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
            this.setState({
                sysType: 1
            })
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
            this.setState({
                sysType: 1
            })
        }
        if (isMac) {
            this.setState({
                sysType: 2
            })
        }
    }
    validateServiceName = (rule, value, callback) => {
        let regex = /(^\s+)|(\s+$)|\s+/g;
        let type = regex.test(value)
        if (type) {
            callback("禁止输入空格");
        } else {
            callback();
        }
    }

    // section 重置
    resetForm = () => {
        // console.log("重置");
        this.state.expenditureSourceList.map((item, index) => {
            item.investMoney = '0';
            item.investScale = '0';
        })
        this.setState({
            priceTotal: 0,
            ratioNum: 0
        }, () => {
            this.props.form.resetFields();
        })
    }

    handleFormChange = (value, index) => {
        let {expenditureSourceList} = this.state
        if (typeof value === 'number') {
            expenditureSourceList[index].investMoney = value
        } else {
            expenditureSourceList[index].investMoney = 0
        }

        let total = 0
        expenditureSourceList.forEach(item => {
            total += item.investMoney
        })


        this.setState({
            priceTotal: total,
            expenditureSourceList
        }, () => {
            this.updateRatio()
        })
    }

    // section 获取经费来源信息
    getExpenditureSourceInfoRequest() {
        let data = {
            "schoolEduCode": this.state.schoolEduCode,
            "taskId": this.state.taskId
        }
        getExpenditureSource(data).then(result => {
            console.log("经费来源信息", result)
            let data = (result?.length > 0) ? result : this.state.expenditureSourceList
            data = data.filter(item => item.type !== 2)

            this.setState({
                expenditureSourceList: data
            }, () => {
                let objc = {}
                this.state.expenditureSourceList.map((item, index) => {
                    objc[`investMoney${index + 1}`] = item.investMoney
                    objc[`investScale${index + 1}`] = item.investScale
                })
                this.props.form.setFieldsValue(objc)

                this.updateSum()
            })
        })
    }

    // section 保存/更新信息
    addAndUpdateExpenditureSourceInfoMethod = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                message.error("输入异常, 请检查!")
                return
            }

            let sourceList = this.state.expenditureSourceList
            sourceList.forEach((item, index) => {
                // item["investMoney"] = values[`investMoney${index + 1}`]
                // item["investScale"] = values[`investScale${index + 1}`]
            })

            let data = {
                "investDetails": sourceList,
                "schoolEduCode": this.state.schoolEduCode,
                "taskId": this.state.taskId
            }

            if (this.state.expenditureSourceList
                && this.state.expenditureSourceList.length > 0
                && isNotEmpty(this.state.expenditureSourceList[0].id)) {

                updateExpenditureSource(data).then(result => {
                    message.success("保存成功")
                    this.getExpenditureSourceInfoRequest()
                })
            } else {

                addExpenditureSource(data).then(result => {
                    message.success("保存成功")
                    this.getExpenditureSourceInfoRequest()
                })
            }
        })
    }

    limitDecimals = value => {
        const reg = /^(\-)*(\d+)\.(\d).*(\d).*$/;
        if (typeof value === 'string') {
            return !isNaN(Number(value)) ? value.replace(reg, '$1$2.$3$4') : '';
        } else if (typeof value === 'number') {
            return !isNaN(value) ? String(value).replace(reg, '$1$2.$3$4') : '';
        } else {
            return '';
        }
    };

    validateInvestMoney = (rule, value, callback) => {
        /* let regex = /^[1-9]\d*|0$/;*/
        let regex = /^(([0-9]+)|([0-9]+\.[0-9]{0,2}))$/
        if (value) {
            if (typeof value !== 'number') {
                callback("请填写格式正确的数字")
            } else if (parseInt(value) > 999999999) {
                callback('金额最高为999999999万元')
            } else if (parseInt(value) < 0) {
                callback('不能为负数')
            } else if (value && !regex.test(value)) {
                callback('只能输入两位小数')
            }
        }
        callback();
    };

    async handeInputBlue() {

    }

    async handleInputBlueKey() {

    }

    // 获取焦点
    doFocus = (e) => {
        // 外层 div.input-box 变色
        e.target.parentNode.style.cssText = 'border-color:rgba(84, 139, 59, 0.7);box-shadow:0px 0px 4px rgba(84, 139, 59, 0.6)'
    };

    // 失去焦点
    doBlur = (e) => {
        e.target.parentNode.style.cssText = 'box-shadow:none'
        // 文本域变色
        // e.target.style.backgroundColor = 'rgba(35,35,35,0.2)';
    };


    getInvestDetailTitle = (type) => {
        switch (type) {
            case 1:
                return "各级财政投入经费"
            case 2:
                return "举办方投入经费"
            case 3:
                return "行业企业支持经费"
            case 4:
                return "学校自筹经费"
        }
        return ""
    }

    ratioNumErrorHitString = (ratioNum) => {
        // if (ratioNum < 100) return "注意: 您的项目预算总比例未达到100%，请确认所填信息准确无误"
        // else if (ratioNum > 100) return "注意: 您的项目预算总比例已超出100%，请确认所填信息准确无误"
        // else return ""
        return ""
    }

    // section render
    render() {
        const {getFieldDecorator} = this.props.form;
        const {isPreview} = this.props;
        const {ratioNum, priceTotal, ifEdit, expenditureSourceList} = this.state;

        const formItemLayout = {
            /*  labelCol: {
                  xs: { span: 18 },
                  sm: { span: 8 },
              },
              wrapperCol: {
                  xs: { span: 18 },
                  sm: { span: 16 },
              },*/
        };


        return (
            <div className="reform_personnel_2_5_1">
                <Form {...formItemLayout} >
                    {expenditureSourceList ? expenditureSourceList.map((item, index) => {
                            return (
                                <Row className="form-box" key={index}>
                                    <Col span={5} className="name-height">
                                        <Form.Item>
                                            {getFieldDecorator(`id${index + 1}`, {
                                                initialValue: item.id ? item.id : ''
                                            })(<span/>)}
                                        </Form.Item>
                                        <Form.Item>
                                            {getFieldDecorator(`investName${index + 1}`, {
                                                initialValue: this.getInvestDetailTitle(item.type)
                                            })(
                                                <span>{this.getInvestDetailTitle(item.type)}</span>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={9} className="price-form-box">
                                        <Form.Item label="金额（万元）">
                                            {getFieldDecorator(`investMoney${index + 1}`, {
                                                initialValue: item.investMoney ? item.investMoney : item.investMoney === 0 ? 0 : '0',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请填写内容",
                                                    },
                                                    {
                                                        validator: this.validateInvestMoney
                                                    }
                                                ],
                                            })(<InputNumber
                                                disabled={isPreview === true}
                                                min={0}
                                                max={999999999}
                                                maxLength={13}
                                                // formatter={value => Number(value)}
                                                // parser={this.limitDecimals}
                                                onChange={value => {
                                                    this.handleFormChange(value, index)
                                                }}/>)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={9} className="price-form-box ">
                                        <span className='money-scale-number'>比例（%）: {item.investScale}%</span>
                                    </Col>
                                </Row>
                            )
                        }
                    ) : ''}
                    <Row>
                        <Col>
                            <ul className="total-box">
                                <li><span className="circle"/></li>
                                <li>小计</li>
                                <li>|</li>
                                <li>金额（万元）：<span>{priceTotal.toFixed(2)}</span></li>
                                <li>
                                    <span>{this.ratioNumErrorHitString(ratioNum)}</span>
                                </li>
                            </ul>

                        </Col>

                    </Row>
                </Form>
                <div className="bottom-but" hidden={isPreview === true}>
                    <Button className="but-li"
                        // disabled={ifComplete === true && ifEdit === false}
                            onClick={() => this.resetForm()}>
                        重置
                    </Button>

                    <Button className="but-li"
                            onClick={this.addAndUpdateExpenditureSourceInfoMethod} type="primary" htmlType="submit">
                        保存
                    </Button>
                </div>
            </div>
        );
    }
}

const FormComponent = Form.create()(FormCom);
export default FormComponent;
