// 项目情况-平台情况
import React from "react";
import {Form, Row, Col, Radio, Card, Button, Input} from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import {withRouter} from "react-router-dom";
import StepTop from "../components/StepTop";
import {Wendangdr} from '../../components/iconSvg/index'
import {getConstructionPlan, addConstructionPlan, updateConstructionPlan} from '../../api/school'
import Cookies from "js-cookie";
import {updateProcessStatus} from "../../api/effect";

const {TextArea} = Input;
export default Form.create()(
    withRouter(
        class XmxxjsRenwushu extends React.Component {
            state = {
                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                userInfo: JSON.parse(Cookies.get('userInfo')) ? JSON.parse(Cookies.get('userInfo')) : "",
                processCode: '2.3',

                // 建设方案的id
                constructionId: '',
            };

            componentDidMount() {
                this.getConstructionPlan()
            }

            getConstructionPlan = () => {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    taskId: this.state.schoolInfo.taskId,
                }
                getConstructionPlan(data).then(res => {
                    console.log('res', res)
                    if (res) {
                        this.setState({
                            constructionId: res.id,
                        })
                        this.props.form.setFieldsValue({
                            constructionBasics: res.constructionBasics,
                            constructionObjectives: res.constructionObjectives,
                            constructionContent: res.constructionContent,
                            constructionConditions: res.constructionConditions,
                            constructionResources: res.constructionResources,
                            constructionTeam: res.constructionTeam,
                        })

                    }
                })
            }

            preStep = () => {
                this.props.history.push({
                    pathname: '/status_project',
                })
            }
            nextStep = () => {
                this.props.form.validateFields((err, value) => {
                    if (!err) {
                        if (this.state.constructionId) {
                            let update = {
                                id: this.state.constructionId,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                taskId: this.state.schoolInfo.taskId,
                                constructionBasics: value.constructionBasics,
                                constructionObjectives: value.constructionObjectives,
                                constructionContent: value.constructionContent,
                                createUserId: this.state.userInfo.createUserId,

                                constructionConditions: value.constructionConditions,
                                constructionResources: value.constructionResources,
                                constructionTeam: value.constructionTeam,
                            }
                            updateConstructionPlan(update).then(res => {
                                if (res) {
                                    this.props.history.push({
                                        pathname: '/school_jianshe',
                                    })
                                }

                            })
                        } else {
                            let save = {
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                taskId: this.state.schoolInfo.taskId,
                                constructionBasics: value.constructionBasics,
                                constructionObjectives: value.constructionObjectives,
                                constructionContent: value.constructionContent,
                                createUserId: this.state.userInfo.createUserId,
                                constructionConditions: value.constructionConditions,
                                constructionResources: value.constructionResources,
                                constructionTeam: value.constructionTeam,
                            }
                            addConstructionPlan(save).then(res => {
                                if (res) {

                                    // 更新进度
                                    let progressData = {
                                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                        taskId: this.state.schoolInfo.taskId,
                                        processCode: this.state.processCode
                                    }

                                    updateProcessStatus(progressData).then(res => {
                                        this.props.history.push({
                                            pathname: '/school_jianshe',
                                        })
                                    })
                                }
                            })
                        }
                    }
                })
                // this.props.history.push({
                //     pathname: '/status_project',
                // })
            }


            render() {
                const {getFieldDecorator} = this.props.form
                const {} = this.state
                const formItemLayout =
                    {
                        labelCol: {
                            span: 3
                        },
                        wrapperCol: {
                            span: 21
                        }
                    }

                return (
                    <div className="XmxxjsRenwushu">
                        <Heder/>
                        <div className="Task-top">
                            <div className="main-cont main_cont">
                                <Card>
                                    <Row>
                                        <Col span={24} className="col-li">
                                            <StepTop setCode={this.setCode} parentCode={"2"}/>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>
                        <div className={"main_cont XmxxjsRenwushuCont"}>
                            <div className="title-top">
                                <h4 className='title_lcl_change'>项目学校建设任务书</h4>
                            </div>
                            <div className={'content'}>
                                <h3 className='small-title'>2-3 建设方案综述</h3>
                                {/*<a className={'wendangdr'}><Wendangdr/>文档导入</a>*/}
                                <div className={'cont-ms'}>
                                    <Form layout={'horizontal'}>
                                        {/*<div className={'smalltitle'}><span className={"must-hit"}>*</span>*/}
                                        {/*    1. 建设基础</div>*/}
                                        <Form.Item className={'textAreaView'} label={'1. 建设基础'}>
                                            {getFieldDecorator('constructionBasics', {
                                                initialValue: '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请输入基础建设的相关内容"
                                                    },
                                                    {
                                                        max: 3000,
                                                        message: "文本过长，最多3000字"
                                                    }
                                                ],
                                            })(<TextArea className="renwushu_textArea" maxLength={3000}
                                                         placeholder={'请简述与虚拟仿真实训基地建设相关的建设基础，包括但不限于办学条件、校园信息化水平、实训教学发展水平、虚拟仿真实训建设现状'}>
                                            </TextArea>)}
                                            {/*<div>*/}
                                            <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`constructionBasics`) ? this.props.form.getFieldValue(`constructionBasics`).length : 0}/3000
                                    </span>
                                            {/*</div>*/}
                                        </Form.Item>
                                        <Form.Item label={'2. 建设思路和建设目标'}>
                                            {getFieldDecorator('constructionObjectives', {
                                                initialValue: '',
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: "请输入建设思路和建设目标的相关内容"
                                                    },
                                                    {
                                                        max: 3000,
                                                        message: "文本过长，最多3000字"
                                                    }
                                                ],
                                            })(<TextArea style={{height: '200px'}} className="renwushu_textArea"
                                                         maxLength={3000}
                                                         placeholder={'建设思路和建设目标3000字以内'}>
                                            </TextArea>)}
                                            <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`constructionObjectives`) ? this.props.form.getFieldValue(`constructionObjectives`).length : 0}/3000
                                    </span>
                                        </Form.Item>
                                        <h1 style={{fontSize: '15px', paddingBottom: '30px', marginBottom: '30px', borderBottom: '1px solid #F3F3F3'}}>
                                            <span className='hit-red'>*</span>
                                            3. 建设内容
                                        </h1>
                                        <Form layout={'horizontal'}  {...formItemLayout}>

                                            <ul className={'jianshe_3'}>
                                                <li style={{marginBottom: "50px" }}>
                                                    <Form.Item className={''} label={'3-1 条件建设'}>
                                                        {getFieldDecorator('constructionConditions', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "请输入建设内容的相关内容"
                                                                },
                                                                {
                                                                    max: 3000,
                                                                    message: "文本过长，最多3000字"
                                                                }
                                                            ],
                                                        })(<TextArea style={{height: '200px'}}
                                                                     className="renwushu_textArea"
                                                                     maxLength={3000}
                                                                     placeholder={'条件建设包括虚拟仿真实训场地、设备、平台、环境建设等'}>
                                                        </TextArea>)}
                                                        <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`constructionConditions`) ? this.props.form.getFieldValue(`constructionConditions`).length : 0}/3000
                                    </span>
                                                    </Form.Item>
                                                </li>

                                                <li style={{marginBottom: "50px" }}>
                                                    <Form.Item label={'3-2 资源建设'}>
                                                        {getFieldDecorator('constructionResources', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "请输入建设内容的相关内容"
                                                                },
                                                                {
                                                                    max: 3000,
                                                                    message: "文本过长，最多3000字"
                                                                }
                                                            ],
                                                        })(<TextArea style={{height: '200px'}}
                                                                     className="renwushu_textArea"
                                                                     maxLength={3000}
                                                                     placeholder={'资源价内税包括资源内容、服务专业（群）、资源共享等'}>
                                                        </TextArea>)}
                                                        <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`constructionResources`) ? this.props.form.getFieldValue(`constructionResources`).length : 0}/3000
                                    </span>
                                                    </Form.Item>
                                                </li>

                                                <li>
                                                    <Form.Item label={'3-3 团队建设'}>
                                                        {getFieldDecorator('constructionTeam', {
                                                            initialValue: '',
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "请输入建设内容的相关内容"
                                                                },
                                                                {
                                                                    max: 3000,
                                                                    message: "文本过长，最多3000字"
                                                                }
                                                            ],
                                                        })(<TextArea style={{height: '200px'}}
                                                                     className="renwushu_textArea"
                                                                     maxLength={3000}
                                                                     placeholder={'团队建设包括教学团队、双师型队伍建设、教师发展与培训等'}>
                                                        </TextArea>)}
                                                        <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`constructionTeam`) ? this.props.form.getFieldValue(`constructionTeam`).length : 0}/3000
                                    </span>
                                                    </Form.Item>
                                                </li>
                                            </ul>
                                        </Form>
                                    </Form>
                                </div>

                            </div>
                            <div className={'border-bottom'}/>
                            <div className={'btn-group'}>
                                <Button className={'up-btn'} onClick={() => this.preStep()}>上一步</Button>
                                <Button className={'next-btn'} onClick={() => this.nextStep()}>保存并进入下一步</Button>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
