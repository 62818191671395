// 建设成效-平台情况
import React from "react";
import {Button, Col, Form, message, Modal, Radio, Row, Select, Table} from 'antd';
import {withRouter} from "react-router-dom";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import Professional from "../../components/addprofessional";
import AddProfessionalgroup from "../components/addProfessional_group";
import AddAttachment from "../components/add_Attachment";
import Lookexaminematerial from "./Look_ examinematerial/look_evidence";
import {Professionalicon, TeacherDevelopmentUpload} from "../../components/iconSvg";
import Threetable from "./threetable/index"
import {isEmpty} from "../../until/isEmpty";
import {
    addMajor,
    addMajorGroup,
    addStudentNum,
    constructionEffectiveList, deleteEffectTask,
    deleteMajor,
    deleteMajorGroup,
    effectTasks,
    getMajor,
    getMajorGroup,
    getStudentNum,
    importMajor,
    importMajorGroup,
    saveEffectTask, saveTargetQuestion, selectTargetQuestion,
    updateMajor,
    updateMajorGroup,
    updateStudentNum
} from "../../api/constructionResults"
import Cookies from "js-cookie";
import {saveSatisfaction, updateMajorNum, validData} from "../../api/target"
import axios from "axios";
import {downTemplate, getSharingMajorList} from "../../api/sharing";
import md5 from "js-md5";
import "./index.scss";
import {updateFactor} from "../../api/factor";
import {getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType} from "../../until/cookie";
import BuildAddTask from "../../components/build_add_task";
import FileLook from "../components/file_look";
import {BaseUrl} from "../../config/common";
import UploaderComponent from "../../components/uploader_component";
import Effective_1_5_4 from "./Effective_1_5_4";
import Effective_1_5_5 from "./Effective_1_5_5";

export default Form.create()(
    withRouter(
        class ConstructionEffective extends React.Component {
            // section State
            state = {
                addPlatforMsgModal: false,//添加平台信息Modal
                addCertificateModal: false,//新增证明材料Modal\
                addAttachment: false,  //添加附件
                value: 1,
                dataList: [],
                // 编辑中的任务record
                editingTaskRecord: null,
                // 当前任务完成列表的页数
                taskPageNum: 1,
                taskPageSize: 10,
                pageNum: 1,//页数
                pageSize: 10,//条数
                total: 0,
                pageNumone: 1,//页数
                pageSizeone: 10,//条数
                totalone: 0,
                pageNumtwo: 1,//页数
                pageSizetwo: 10,//条数
                totaltwo: 0,

                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                // twoLevelTargetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
                twoLevelTargetId: getTargetId("construction_Effective"),
                // 1.5.1的targetId
                getTargetId1_5_1: getSecondTargetList("construction_Effective")[0],
                getTargetId1_5_2: getSecondTargetList("construction_Effective")[1],
                getTargetId1_5_3: getSecondTargetList("construction_Effective")[2],
                getTargetId1_5_4: getSecondTargetList("construction_Effective")[2],
                getTargetId1_5_5: getSecondTargetList("construction_Effective")[3], // 1.5.4 虚拟仿真实训体现行业企业
                getTargetId1_5_6: getSecondTargetList("construction_Effective")[4], // 1.5.5 虚拟仿真实训基地年度平均利用率

                dataSource1_5_2: {
                    answer: null,
                    id: "",
                    targetId: "",
                    files: [],
                    title: ""
                },
                dataSource1_5_4: {
                    answer: null,
                    id: "",
                    targetId: "",
                    files: [],
                    title: ""
                },
                dataSource1_5_5: {
                    answer: null,
                    id: "",
                    targetId: "",
                    files: [],
                    title: ""
                },


                total1_5_1: 0,
                complete1_5_1: 0,
                dataSource1_5_1: [],
                total1_5_2: 0,
                complete1_5_2: 0,

                columnsADataSource: [],
                columnsBDataSource: [],
                majorInfo: [],
                studentNumInfo: [],
                majorId: '',
                majorGroupId: '',
                major: [],
                majorGroup: [],
                majorNum: "",
                majorGroupNum: "",
                LookEvidence: false,  //查看资料
            };

            componentDidMount() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                this.constructionEffectiveList()
                this.getSharingMajor()
                this.effectTasks()
                this.getMajor()
                this.selectTargetQuestion1_5_3()
                this.selectTargetQuestion1_5_4()
                this.selectTargetQuestion1_5_5()
            }

            constructionEffectiveList = () => {
                let data = {
                    id: "",
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.twoLevelTargetId,
                    // pageNum: this.state.pageNum,
                    // pageSize: this.state.pageSize
                }
                constructionEffectiveList(data).then(res => {
                    if (res) {
                        // alert(res.effectMajorVo.majorVoList.total)
                        this.setState({
                            // majorAdnGroupNum: res.effectMajorVo.majorNumber + res.effectMajorVo.majorGroupNumber,
                            majorInfo: res.effectMajorVo,
                            studentNumInfo: res.majorStudentNumVo,
                            studentNumber: res.majorStudentNumVo.studentNumber,
                            studentNumInfoList: res.majorStudentNumVo.resourceVoList,
                            showList: res.majorStudentNumVo.resourceVoList.data,
                            countNum: res.majorStudentNumVo.factorContent,
                            // columnsADataSource: res.effectMajorVo.majorVoList.data,
                            // total1_5_2: res.effectMajorVo.majorVoList.total,
                            // complete1_5_2: res.effectMajorVo.majorBaseNum,
                            columnsBDataSource: res.effectMajorVo.majorGroupVoList.data,
                            columnsATotal: res.effectMajorVo.majorVoList.total,
                            columnsBTotal: res.effectMajorVo.majorGroupVoList.total,
                            columnsABaifenbi: 0,
                            // majorAndMajorGroupTotal: res.effectMajorVo.majorNumber + res.effectMajorVo.majorGroupNumber,
                            majorNum: res.effectMajorVo.majorNumber,
                            majorGroupNum: res.effectMajorVo.majorGroupNumber,
                            majorCount: res.effectMajorVo.majorCount,
                            majorGroupCount: res.effectMajorVo.majorGroupCount,
                        })
                    }
                })
            }
            // 获取成效任务
            effectTasks = () => {
                const data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: getSecondTargetList("construction_Effective")[0],
                    pageNum: this.state.taskPageNum,
                    pageSize: this.state.taskPageSize
                }
                effectTasks(data).then(res => {
                    this.setState({
                        dataSource1_5_1: res?.data,
                        total1_5_1: res?.total,
                        complete1_5_1: res?.cmTotal ?? 0
                    })
                })

            };
            // section 1.5.3
            selectTargetQuestion1_5_3 = () => {
                const data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_2,
                }
                selectTargetQuestion(data).then(dataSource1_5_2 => {
                    this.setState({
                        dataSource1_5_2
                    })
                })
            }
            onChange1_5_2 = (event) => {
                const value = event.target.value
                const params = this.state.dataSource1_5_2
                params.answer = value
                params.schoolEduCode = this.state.schoolInfo.schoolEduCode
                params.targetId = this.state.getTargetId1_5_2
                params.fileIdList = (this.state.dataSource1_5_2.files ?? []).map(res => res.id)
                saveTargetQuestion(params).then(res => {
                    this.setState({
                        dataSource1_5_2: params
                    })
                })
            }

            onGetFiles1_5_2 = (files) => {
                // //获取原有信息
                const params = this.state.dataSource1_5_2
                // 文件列表
                const finalFiles = params.files ?? []
                // 添加插入的文件列表
                finalFiles.splice(0, 0, ...files)
                // 获取文件id
                params.fileIdList = finalFiles.map(file => file.id)
                // 文件存本地
                params.files = finalFiles
                params.targetId = this.state.getTargetId1_5_2
                // 学校编码
                params.schoolEduCode = this.state.schoolEduCode
                // 保存文件id列表并请求后台告知
                this.setState({dataSource1_5_2: params}, () => {
                    if (this.state.dataSource1_5_2) {
                        saveTargetQuestion(params).then(res => {
                            message.success("添加成功")
                            this.selectTargetQuestion1_5_3();
                        })
                    }
                })
            }
            onDelete1_5_2 = (index) => {
                //获取原有信息
                const params = this.state.dataSource1_5_2
                // 文件列表
                const finalFiles = params.files ?? []
                // 删除下表下的元素
                finalFiles.splice(index, 1)
                // 获取文件id
                params.fileIdList = finalFiles.map(file => file.id)
                params.files = finalFiles
                // 保存文件id列表并请求后台告知
                this.setState({dataSource1_5_2: params}, () => {
                    if (this.state.dataSource1_5_2) {
                        saveTargetQuestion(params).then(res => {
                            message.success("删除成功")
                            this.selectTargetQuestion1_5_3();
                        })
                    }
                })
            }


            // section 1.5.4
            selectTargetQuestion1_5_4 = () => {
                const data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_4,
                }
                selectTargetQuestion(data).then(dataSource1_5_4 => {
                    this.setState({
                        dataSource1_5_4
                    })
                })
            }
            onChange1_5_4 = (event) => {
                const value = event.target.value
                const params = this.state.dataSource1_5_4
                params.answer = value
                params.schoolEduCode = this.state.schoolInfo.schoolEduCode
                params.targetId = this.state.getTargetId1_5_4
                params.fileIdList = (this.state.dataSource1_5_4.files ?? []).map(res => res.id)
                saveTargetQuestion(params).then(res => {
                    this.setState({
                        dataSource1_5_4: params
                    })
                })
            }

            onGetFiles1_5_4 = (files) => {
                // //获取原有信息
                const params = this.state.dataSource1_5_4
                // 文件列表
                const finalFiles = params.files ?? []
                // 添加插入的文件列表
                finalFiles.splice(0, 0, ...files)
                // 获取文件id
                params.fileIdList = finalFiles.map(file => file.id)
                // 文件存本地
                params.files = finalFiles
                params.targetId = this.state.getTargetId1_5_4
                // 学校编码
                params.schoolEduCode = this.state.schoolEduCode
                // 保存文件id列表并请求后台告知
                this.setState({dataSource1_5_4: params}, () => {
                    if (this.state.dataSource1_5_4) {
                        saveTargetQuestion(params).then(res => {
                            message.success("添加成功")
                            this.selectTargetQuestion1_5_4();
                        })
                    }
                })
            }
            onDelete1_5_4 = (index) => {
                //获取原有信息
                const params = this.state.dataSource1_5_4
                // 文件列表
                const finalFiles = params.files ?? []
                // 删除下表下的元素
                finalFiles.splice(index, 1)
                // 获取文件id
                params.fileIdList = finalFiles.map(file => file.id)
                params.files = finalFiles
                // 保存文件id列表并请求后台告知
                this.setState({dataSource1_5_4: params}, () => {
                    if (this.state.dataSource1_5_4) {
                        saveTargetQuestion(params).then(res => {
                            message.success("删除成功")
                            this.selectTargetQuestion1_5_4();
                        })
                    }
                })
            }


            // section 1.5.5
            selectTargetQuestion1_5_5 = () => {
                const data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_5,
                }
                selectTargetQuestion(data).then(dataSource1_5_5 => {
                    this.setState({
                        dataSource1_5_5
                    })
                })
            }
            onChange1_5_5 = (event) => {
                const value = event.target.value
                const params = this.state.dataSource1_5_5
                params.answer = value
                params.schoolEduCode = this.state.schoolInfo.schoolEduCode
                params.targetId = this.state.getTargetId1_5_5
                params.fileIdList = (this.state.dataSource1_5_5.files ?? []).map(res => res.id)
                saveTargetQuestion(params).then(res => {
                    this.setState({
                        dataSource1_5_5: params
                    })
                })
            }

            onGetFiles1_5_5 = (files) => {
                // //获取原有信息
                const params = this.state.dataSource1_5_5
                // 文件列表
                const finalFiles = params.files ?? []
                // 添加插入的文件列表
                finalFiles.splice(0, 0, ...files)
                // 获取文件id
                params.fileIdList = finalFiles.map(file => file.id)
                // 文件存本地
                params.files = finalFiles
                params.targetId = this.state.getTargetId1_5_5
                // 学校编码
                params.schoolEduCode = this.state.schoolEduCode
                // 保存文件id列表并请求后台告知
                this.setState({dataSource1_5_5: params}, () => {
                    if (this.state.dataSource1_5_5) {
                        saveTargetQuestion(params).then(res => {
                            message.success("添加成功")
                            this.selectTargetQuestion1_5_5();
                        })
                    }
                })
            }
            onDelete1_5_5 = (index) => {
                //获取原有信息
                const params = this.state.dataSource1_5_5
                // 文件列表
                const finalFiles = params.files ?? []
                // 删除下表下的元素
                finalFiles.splice(index, 1)
                // 获取文件id
                params.fileIdList = finalFiles.map(file => file.id)
                params.files = finalFiles
                // 保存文件id列表并请求后台告知
                this.setState({dataSource1_5_5: params}, () => {
                    if (this.state.dataSource1_5_5) {
                        saveTargetQuestion(params).then(res => {
                            message.success("删除成功")
                            this.selectTargetQuestion1_5_5();
                        })
                    }
                })
            }


            // 查看资料
            handLook = (record, index) => {
                const url = BaseUrl + record.files[0]?.filePath + "/" + record.files[0]?.fileName
                this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})
            }
            /**
             *  隐藏预览的回调
             */
            dismiss = () => {
                this.setState({
                    isShow: false
                })
            }

            onCancel = () => {
                this.setState({
                    examine: false
                })
            }

            handExamine = () => {
                this.setState({
                    examine: true
                })
            }

            updateMajor = (record) => {
                this.setState({
                    addPlatforMsgModal: true,
                    majorId: record.id,
                    major: record
                })
            }

            updateTask = (editingTaskRecord) => {
                this.setState({
                    addTaskMsgModal: true,
                    editingTaskRecord
                })
            }

            deleteTask = (record) => {
                deleteEffectTask(record.id).then(() => {
                    message.success("删除成功")
                    this.setState({
                        taskPageNum: 1
                    }, () => {
                        this.effectTasks()
                    })
                    // if ((this.state.total1_5_1 - 1) % this.state.taskPageSize == 0) {
                    //     this.setState({
                    //         taskPageNum: 1
                    //     }, () => {
                    //         this.effectTasks()
                    //     })
                    // }else{
                    //     this.effectTasks()
                    // }
                })
                // let data = {
                //     id: record.id,
                //     schoolEduCode: this.state.schoolInfo.schoolEduCode,
                //     targetId: this.state.majorInfo.id
                // }
                // deleteMajor(data).then(res => {
                //     if (res) {
                //         message.success("删除成功")
                //         this.getMajor()
                //     }
                // })

            }

            updateMajorGroup = (record) => {
                this.setState({
                    addCertificateModal: true,
                    majorGroupId: record.majorGroupId,
                    majorGroup: record
                })
            }

            deleteMajor = (record) => {
                let data = {
                    id: record.id,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_2
                }
                deleteMajor(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getMajor()
                    }
                })

            }

            deleteMajorGroup = (record) => {
                let data = {
                    id: record.id,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_2
                }
                deleteMajorGroup(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getMajorGroup()
                    }
                })
            }

            getMajor = () => {
                let data = {
                    id: "",
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_2,
                    pageNum: this.state.pageNum,
                    pageSize: this.state.pageSize
                }
                getMajor(data).then(res => {
                    // alert(res.majorVoPageData.total)
                    if (res) {
                        this.setState({
                            columnsADataSource: res.majorVoPageData.data,
                            total1_5_2: res.majorVoPageData.total,
                            complete1_5_2: res.majorBaseNum
                        })
                    }
                })
            }

            getMajorGroup = () => {
                let data = {
                    id: "",
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.getTargetId1_5_2
                }
                getMajorGroup(data).then(res => {
                    if (res) {
                        this.setState({
                            columnsBDataSource: res.majorGroupVoPageData.data,
                            majorGroupCount: res.majorGroupBaseNum
                        })
                    }

                })
            }

            columnsATiJiao = () => {
                if (this.state.majorId) {
                    this.Professional.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                id: this.state.majorId,
                                isBase: value.isBase,
                                majorOne: value.majorName[0],
                                majorTwo: value.majorName[1],
                                majorCode: value.majorName[2],
                                majorName: '',
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            updateMajor(data).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        addPlatforMsgModal: false,
                                    }, () => {
                                        this.getMajor()
                                    })
                                }
                            })
                        }
                    })
                } else {
                    this.Professional.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                isBase: value.isBase,
                                majorOne: value.majorName[0],
                                majorTwo: value.majorName[1],
                                majorCode: value.majorName[2],
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            addMajor(data).then(res => {
                                if (res) {
                                    message.success("新增成功")
                                    this.setState({
                                        addPlatforMsgModal: false
                                    }, () => {
                                        this.getMajor()
                                    })
                                }
                            })
                        }
                    })
                }
            }
            columnsTaskTiJiao = () => {
                this.addTaskForm.props.form.validateFields((err, value) => {
                    if (err) {
                        return;
                    }
                    let file = this.addTaskForm.getFile() ? this.addTaskForm.getFile() : null
                    if (!file || isEmpty(file.fileId)) {
                        message.info("文件上传中")
                        return
                    }
                    const fileId = this.addTaskForm.state.file.id
                    const fileIdList = [fileId]
                    const data = {
                        description: value.description,
                        fileIdList: fileIdList,
                        hasComplete: value.hasComplete,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.getTargetId1_5_1,
                        taskName: value.taskName,
                        // id:this.state.editingTaskRecord.id||''
                    };
                    if (this.state.editingTaskRecord) {
                        data.id = this.state.editingTaskRecord.id
                    }
                    saveEffectTask(data).then(r => {
                        if (this.state.editingTaskRecord?.id) {
                            this.setState({
                                addTaskMsgModal: false,
                            }, () => {
                                this.effectTasks()
                                message.success("修改成功")
                            })
                        } else {
                            this.setState({
                                taskPageNum: 1,
                                addTaskMsgModal: false,
                            }, () => {
                                this.effectTasks()
                                message.success("添加成功")
                            })
                        }
                    })
                })
            }
            columnsTaskTiJiaoAndAdd = () => {
                this.addTaskForm.props.form.validateFields((err, value) => {
                    if (err) {
                        return;
                    }
                    let file = this.addTaskForm.getFile() ? this.addTaskForm.getFile() : null
                    if (!file || isEmpty(file.fileId)) {
                        message.info("文件上传中")
                        return
                    }
                    const fileId = this.addTaskForm.state.file.id
                    const fileIdList = [fileId]
                    const data = {
                        description: value.description,
                        fileIdList: fileIdList,
                        hasComplete: value.hasComplete,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        targetId: this.state.getTargetId1_5_1,
                        taskName: value.taskName,
                        // id:this.state.editingTaskRecord.id||''
                    };
                    if (this.state.editingTaskRecord) {
                        data.id = this.state.editingTaskRecord.id
                    }
                    saveEffectTask(data).then(r => {

                        this.setState({
                            editingTaskRecord: null,
                            addTaskMsgModal: true,
                            taskPageNum: 1,
                        }, () => {
                            this.addTaskForm.props.form.resetFields()
                            this.addTaskForm.deleteFile();
                            this.effectTasks()
                        })
                        message.success("添加成功")
                    })
                })
            }

            columnsATiJiaoAndAdd = () => {
                if (this.state.majorId) {
                    this.Professional.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                id: this.state.majorId,
                                majorCode: value.majorCode,
                                majorName: value.majorName,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            updateMajor(data).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        // addPlatforMsgModal: false,
                                        major: ''
                                    }, () => {
                                        this.Professional.props.form.resetFields()
                                        this.getMajor()
                                    })
                                }
                            })
                        }
                    })
                } else {
                    this.Professional.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                majorCode: value.majorCode,
                                majorName: value.majorName,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            addMajor(data).then(res => {
                                if (res) {
                                    message.success("新增成功")
                                    this.setState({
                                        addPlatforMsgModal: false,
                                        major: ''
                                    }, () => {
                                        this.Professional.props.form.resetFields()
                                        this.getMajor()
                                    })
                                }
                            })
                        }
                    })
                }
            }

            columnsBTiJiao = () => {
                let file = this.addProfessionalgroup.getState()
                if (this.state.majorGroupId) {
                    this.addProfessionalgroup.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                evidenceQo: {
                                    fileId: file.fileId,
                                    resourceId: file.resourceId,
                                    resourceName: file.originName,
                                    resourceSize: file.fileSize,
                                    resourceSuffix: file.fileSuffix
                                },
                                id: this.state.majorGroupId,
                                effectMajorId: value.effectMajorId,
                                majorGroupName: value.majorGroupName,
                                isBase: value.isBase,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.majorId.id
                            }
                            updateMajorGroup(data).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        addCertificateModal: false,
                                        majorGroup: {},
                                        majorGroupId: ''
                                    }, () => {
                                        this.addProfessionalgroup.props.form.resetFields()
                                        this.addProfessionalgroup.deleteFile()
                                        this.getMajorGroup()
                                    })
                                }
                            })
                        }
                    })
                } else {
                    this.addProfessionalgroup.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                evidenceQo: {
                                    fileId: file.fileId,
                                    resourceId: file.resourceId,
                                    resourceName: file.originName,
                                    resourceSize: file.fileSize,
                                    resourceSuffix: file.fileSuffix
                                },
                                effectMajorId: value.effectMajorId,
                                majorGroupName: value.majorGroupName,
                                isBase: value.isBase,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            addMajorGroup(data).then(res => {
                                if (res) {
                                    message.success("新增成功")
                                    this.setState({
                                        addCertificateModal: false,
                                        majorGroup: {},
                                        majorGroupId: ''
                                    }, () => {
                                        this.addProfessionalgroup.props.form.resetFields()
                                        this.addProfessionalgroup.deleteFile()
                                        this.getMajorGroup()
                                    })
                                }
                            })
                        }
                    })
                }
            }
            columnsBTiJiaoAndAdd = () => {
                let file = this.addProfessionalgroup.getState()
                if (this.state.majorGroupId) {
                    this.addProfessionalgroup.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                evidenceQo: {
                                    fileId: file.fileId,
                                    resourceId: file.resourceId,
                                    resourceName: file.originName,
                                    resourceSize: file.fileSize,
                                    resourceSuffix: file.fileSuffix
                                },
                                id: this.state.majorGroupId,
                                effectMajorId: value.effectMajorId,
                                majorGroupName: value.majorGroupName,
                                isBase: value.isBase,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            updateMajorGroup(data).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        // addCertificateModal: false
                                        majorGroup: {},
                                        majorGroupId: ''
                                    }, () => {
                                        this.addProfessionalgroup.props.form.resetFields()
                                        this.addProfessionalgroup.deleteFile()
                                        this.getMajorGroup()
                                    })
                                }
                            })
                        }
                    })
                } else {
                    this.addProfessionalgroup.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                evidenceQo: {
                                    fileId: file.fileId,
                                    resourceId: file.resourceId,
                                    resourceName: file.originName,
                                    resourceSize: file.fileSize,
                                    resourceSuffix: file.fileSuffix
                                },
                                effectMajorId: value.effectMajorId,
                                majorGroupName: value.majorGroupName,
                                isBase: value.isBase,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.getTargetId1_5_2
                            }
                            addMajorGroup(data).then(res => {
                                if (res) {
                                    message.success("新增成功")
                                    this.setState({
                                        // addCertificateModal: false
                                        majorGroup: {},
                                        majorGroupId: ''
                                    }, () => {
                                        this.addProfessionalgroup.props.form.resetFields()
                                        this.addProfessionalgroup.deleteFile()
                                        this.getMajorGroup()
                                    })
                                }
                            })
                        }
                    })
                }
            }

            addPlatforMsg = () => {
                this.setState({
                    addPlatforMsgModal: true,
                    major: '',
                    majorId: ''
                })
            }
            //添加任务的弹窗
            addTaskMsg = () => {
                this.setState({
                    addTaskMsgModal: true,
                    editingTaskRecord: null,
                })
            }
            addCertificate = () => {
                this.setState({
                    addCertificateModal: true,
                    majorGroupId: '',
                    majorGroup: ''
                })
            }
            handleCancel = () => {
                this.setState({
                    addCertificateModal: false,
                    addTaskMsgModal: false
                })
            }
            handleCancelone = () => {
                this.setState({
                    LookEvidence: false
                })
            }

            // 上一步
            previous = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }


                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "teacher_Development") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/teacher_Development',
                            })
                            break;
                        }
                    }
                }
            }
            // 下一步
            next = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }


                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "platform_talenttraining") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/platform_talenttraining',
                            })
                            break;
                        }
                    }
                }
            }
            //分页切换
            pageChange = (pageNum) => {
                this.setState({pageNum}, this.getMajor);
            }
            //分页条数切换
            onShowSizeChange = (pageNum, pageSize) => {
                this.setState({pageNum: 1, pageSize}, this.getMajor);
            }
            pageChangTask = (pageNum) => {
                this.setState({
                    taskPageNum: pageNum
                }, this.effectTasks)
            }
            onShowSizeChangeTask = (pageNum, pageSize) => {
                this.setState({
                    taskPageSize: pageSize,
                    taskPageNum: 1
                }, this.effectTasks)

                // this.setState({pageSizeone: pageSize}, () => {
                //     this.getMajorGroup()
                // });
            }
            //第二个表格分页切换
            //第二个表格分页条数切换,1.5.2
            pageChangeone = (pageNum) => {
                this.setState({pageNumone: pageNum}, () => {
                    this.getMajorGroup()
                });
            }
            //第二个表格分页条数切换,1.5.2
            onShowSizeChangeone = (pageNum, pageSize) => {
                this.setState({pageNumone: 1, pageSizeone: pageSize}, () => {
                    this.getMajorGroup()
                });
            }
            //第三个表格分页切换
            pageChangetwo = (pageNum) => {
                this.setState({pageNumtwo: pageNum});
            }
            //第三个表格分页条数切换
            onShowSizeChangetwo = (pageNum, pageSize) => {
                this.setState({pageSizetwo: pageSize});
            }
            // 添加附件
            handAttachment = () => {
                this.setState({
                    addAttachment: true,
                    studentNumInfoList: ''
                })
            }
            handleAttach = () => {
                this.setState({
                    addAttachment: false,
                })
            }
            lookHandevid = () => {
                this.setState({
                    LookEvidence: true,
                })
            }

            // 导入文件
            importMajor = async (e) => {
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('targetId', this.state.getTargetId1_5_2)
                fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
                importMajor(fromData).then(res => {
                    message.success('导入成功')
                    this.getMajor()
                })
            }

            // 导入文件
            importMajorGroup = async (e) => {
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('targetId', this.state.getTargetId1_5_2)
                fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
                importMajorGroup(fromData).then(res => {
                    message.success('导入成功')
                    this.getMajorGroup()
                })
            }

            //下载文件
            dowLoadFile = (targetMark, targetName) => {
                let now = new Date().getTime();
                axios.get(downTemplate(targetMark), {
                    headers: {
                        'content-type': 'application/json; charset=utf-8',
                        // token: getToken(),
                        "z-ts": now,
                        "z-sign": md5(now + "")
                    },
                    responseType: 'blob', // --设置请求数据格式
                }).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${targetName}.xls`);
                    link.click();
                })
            }

            getStudentNum = () => {
                let data = {
                    pageNum: this.state.pageNum,
                    pageSize: this.state.pageSize,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.studentNumInfo.id,
                }
                getStudentNum(data).then(res => {
                    if (res) {
                        this.setState({
                            studentNumInfoList: res.resourceVoList.data,
                            showList: res.resourceVoList.data
                        })
                    }
                })
            }

            columnsCTiJiao = () => {
                let file = this.AddevidenceChild.getState()
                let cailiaoType = this.AddevidenceChild.cailiaoType()
                if (file.length === 0) {
                    message.warning("请上传文件")
                    return false;
                }
                if (cailiaoType !== 1) {
                    message.warning("请先勾选我承诺上传的资料真实可信")
                    return false;
                }
                if (file.length > 0) {
                    if (this.state.studentNumInfo.resourceVoList.length > 0) {
                        let data = {
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            targetId: this.state.studentNumInfo.id,
                            evidenceQoList: file
                        }
                        updateStudentNum(data).then(res => {
                            if (res) {
                                message.success("修改成功")
                                this.setState({
                                    addAttachment: false
                                }, () => {
                                    this.getStudentNum()
                                })
                            }

                        })
                    } else {
                        let data = {
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            targetId: this.state.studentNumInfo.id,
                            evidenceQoList: file
                        }
                        addStudentNum(data).then(res => {
                            if (res) {
                                message.success("新增成功")
                                this.setState({
                                    addAttachment: false
                                }, () => {
                                    this.getStudentNum()
                                })
                            }
                        })
                    }
                }

            }

            getSharingMajor = () => {
                getSharingMajorList({stageId: 2}).then((res) => {
                    if (res) {
                        this.setState({
                            majorList: res,
                        });
                    }
                });
            }
            //提交佐证材料
            addData = () => {
                this.AddevidenceChild.addData()
                // setTimeout(function (){
                //     that.setState({
                //         addEvidence: false
                //     })
                // },1000)
            }

            updateMajorNum = (value) => {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    majorNum: Number(value),
                }
                updateMajorNum(data).then(res => {
                    if (res) {
                        // message.success("操作成功")
                    }
                })
            }

            updateMajorGroupNum = (value) => {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    majorGroupNum: Number(value),
                }
                updateMajorNum(data).then(res => {
                    if (res) {
                        // message.success("操作成功")
                    }
                })
            }

            studentNum = (value) => {
                let data = {
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    studentNumber: Number(value)
                }
                updateMajorNum(data).then(res => {
                    if (res) {
                        // message.success("操作成功")
                    }
                })
            }

            updateFactor = (value) => {
                let data = {
                    id: this.state.studentNumInfo.factorId,
                    factorContent: Number(value)
                }
                updateFactor(data).then(res => {
                    if (res) {
                        // message.success("操作成功")
                    }
                })
            }

            countNum = (value) => {
                this.setState({
                    countNum: Number(value)
                })
            }

            gaibianMajorNum = (value) => {
                this.setState({
                    majorNum: Number(value)
                })
            }

            gaibianMajorGroupNum = (value) => {
                this.setState({
                    majorGroupNum: Number(value)
                })
            }

            updateStudentNumber = (value) => {
                this.setState({
                    studentNumber: Number(value)
                })
            }

            render() {
                const {
                    studentNumber,
                    resourceId,
                    examine,
                    countNum,
                    // 数量
                    majorCount,
                    majorGroupCount,
                    showList,
                    fileList,
                    majorNum,
                    majorGroupNum,
                    majorAndMajorGroupTotal,
                    columnsABaifenbi,
                    columnsATotal,
                    columnsBTotal,
                    columnsADataSource,
                    columnsBDataSource,
                    majorList,
                    major,
                    majorGroup,
                    majorInfo,
                    studentNumInfo,
                    dataList,
                    columns,
                    dataSource,
                    addPlatforMsgModal,
                    majorGroupId,
                    addCertificateModal,
                    majorId,
                    studentNumInfoList,
                    pageNum,
                    pageSize,
                    total,
                    totalone,
                    pageNumone,
                    pageSizeone,
                    pageNumtwo,
                    pageSizetwo,
                    totaltwo,
                    addAttachment,
                    materialTargetId,
                    LookEvidence,
                    isShow,
                    openUrl
                } = this.state;
                const {Option} = Select;
                const {getFieldDecorator} = this.props.form;
                const props = {
                    name: 'file',
                    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                    headers: {
                        authorization: 'authorization-text',
                    },
                    onChange(info) {
                        if (info.file.status !== 'uploading') {
                        }
                        if (info.file.status === 'done') {
                            message.success(`${info.file.name} file uploaded successfully`);
                        } else if (info.file.status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    },
                };
                const canEdit = getCanEdit()
                const columnsTask = [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                        render: (text, record, index) => {
                            return <span>{this.state.taskPageSize * (this.state.taskPageNum - 1) + index + 1}</span>
                        }
                    },
                    {title: '完成任务名称', dataIndex: 'taskName', key: 'taskName', align: 'center', width: 200},
                    {
                        title: '是否完成', dataIndex: 'hasComplete', key: 'hasComplete', align: 'center', width: 200,
                        render: (text, record) => text === 0 ? <span>否</span> : <span>是</span>
                    },
                    {title: '情况说明', dataIndex: 'description', key: 'description', align: 'center', width: 200},
                    {
                        title: '查看任务材料', dataIndex: 'check', key: 'check', align: 'center', width: 200,
                        render: (text, record, index) => record.files ?
                            <span className={'chakan'} onClick={() => this.handLook(record, index)}>查看</span> :
                            <span>未上传</span>
                    },


                ];
                const columnsAPersent = [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                        render: (text, record, index) => {
                            return <span>{index + 1}</span>
                        }
                    },
                    {title: '专业代码', dataIndex: 'majorCode', key: 'majorCode', align: 'center', width: 200},
                    {title: '专业名称', dataIndex: 'majorName', key: 'majorName', align: 'center', width: 200},
                    {
                        title: '是否为虚拟仿真实训资源服务的专业', dataIndex: 'isBase', key: 'isBase', align: 'center', width: 200,
                        render: (text, record) => text === 0 ? <span>否</span> : <span>是</span>
                    }

                ];
                const columnsBPersent = [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                        render: (text, record, index) => {
                            return <span>{index + 1}</span>
                        }
                    },
                    {title: '专业群名称', dataIndex: 'majorGroupName', key: 'majorGroupName', align: 'center', width: 200},
                    {
                        title: '包含专业', dataIndex: 'majorName', key: 'majorName', align: 'center', width: 200,
                        render: (text, record) => record.majorName ? record.majorName.join(",") : ""
                    },
                    {
                        title: '是否基地服务', dataIndex: 'isBase', key: 'isBase', align: 'center', width: 200,
                        render: (text, record) => text === 0 ? <span>否</span> : <span>是</span>
                    },
                    {
                        title: '支撑材料', dataIndex: 'fileId', key: 'fileId', align: 'center', width: 200,
                        render: (text, record) => record.fileId ?
                            <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> :
                            <span>未上传</span>
                    }

                ];

                if (canEdit) {
                    // todo 需要对接口后完成
                    columnsTask.push({
                        title: '操作', align: 'center',
                        width: 200,
                        dataIndex: 'oper',
                        key: 'oper',
                        render: (text, record) => <div className='render-span vertical_j oper'>
                            <div>
                                <span className="cz" onClick={() => this.updateTask(record)}>编辑</span>
                                <span className="cz" onClick={() => this.deleteTask(record)}>删除</span>
                            </div>
                        </div>
                    })
                    columnsAPersent.push({
                        title: '操作', align: 'center',
                        width: 200,
                        dataIndex: 'oper',
                        key: 'oper',
                        render: (text, record) => <div className='render-span vertical_j oper'>
                            <div>
                                <span className="cz" onClick={() => this.updateMajor(record)}>编辑</span>
                                <span className="cz" onClick={() => this.deleteMajor(record)}>删除</span>
                            </div>
                        </div>
                    })
                    columnsBPersent.push({
                        title: '操作', align: 'center',
                        width: 200,
                        dataIndex: 'oper',
                        key: 'oper',
                        render: (text, record) => <div className='render-span vertical_j oper'>
                            <div>
                                <span className="cz" onClick={() => this.updateMajorGroup(record)}>编辑</span>
                                <span className="cz" onClick={() => this.deleteMajorGroup(record)}>删除</span>

                            </div>
                        </div>
                    })
                }


                return (
                    <div className="ConstructionEffective main-bg">
                        <Heder/>
                        <div className="Efficiency_header">
                            <div className="main_cont">
                                <EffTop/>
                            </div>
                        </div>
                        <div className="Fillside_bottom clearfix">
                            <div className="main_cont">
                                <div className="Fillside_warp" id='Fillside_warp'>
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="leftcollect" id="leftcollect">
                                            <div className="Double-left-table">
                                                <LeftEfficiencyside onRef={(ref) => {
                                                    this.LeftEfficiencyside = ref;
                                                }}/>
                                            </div>
                                        </Col>
                                        <Col span={20} className="Task_right">
                                            <div className="right_ReformPersonnel">
                                                {/*1.6.1*/}
                                                <div className="title_top">
                                                    <h4>1.5.1 任务终期完成度</h4>
                                                </div>
                                                <div className="conte">
                                                    <div className='conteCompPercent'>
                                                        <span>任务总数</span>
                                                        <div
                                                            className="span_input">{this.state.total1_5_1}</div>
                                                        {/*todo 需要根据后台数据走*/}
                                                        <span>完成任务数</span>
                                                        <div
                                                            className="span_input"> {this.state.complete1_5_1} </div>
                                                        <span style={{marginRight: '0px'}}>任务终期完成度</span>
                                                        <div
                                                            className={`span_input ${((this.state.complete1_5_1 / this.state.total1_5_1) * 100).toFixed(2) < 100 ? 'conteInput_right_warning_border' : ''}`}
                                                        > {this.state.total1_5_1 == 0 ? "0.00" : ((this.state.complete1_5_1 / this.state.total1_5_1) * 100).toFixed(2)} %
                                                        </div>
                                                        {
                                                            ((this.state.complete1_5_1 / this.state.total1_5_1) * 100).toFixed(2) < 100 ?
                                                                <span style={{
                                                                    marginLeft: '-20px',
                                                                    color: '#D75746'
                                                                }}>*该项指标需达到100%</span>
                                                                :
                                                                ''
                                                        }
                                                    </div>
                                                    <div className="button_bottom">
                                                        {
                                                            canEdit ? <div className='conteBtnDiv'
                                                                           onClick={this.addTaskMsg}>
                                                                <Professionalicon/><span
                                                                className='conteBtnDivSpan'>添加任务</span>
                                                            </div> : ""
                                                        }
                                                        {/* <div className='conteBtnDiv'>
                                                            <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}
                                                                   onChange={this.importMajor}/><ExcelImport/><span className='conteBtnDivSpan'>Excel导入</span></div>
                                                        <div className='conteBtnDiv' onClick={() => this.dowLoadFile(majorInfo.targetMark, majorInfo.targetName)}><Daoru/><span className='conteBtnDivSpan'>下载导入模版</span></div> */}
                                                    </div>
                                                </div>
                                                <div className="conte" style={{marginBottom: '50px'}}>
                                                    <div className='conteTable'>
                                                        <Table columns={columnsTask}
                                                               dataSource={this.state.dataSource1_5_1}
                                                               bordered={true}
                                                               locale={{
                                                                   emptyText: <>
                                                                       <div className="Empty-content">
                                                                           <div className="text">
                                                                               <img
                                                                                   src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                               <span>暂无数据</span>
                                                                           </div>
                                                                       </div>
                                                                   </>
                                                               }}
                                                               pagination={
                                                                   {
                                                                       total: this.state.total1_5_1,
                                                                       pageSize: this.state.taskPageSize,
                                                                       current: this.state.taskPageNum,
                                                                       onChange: this.pageChangTask,
                                                                       onShowSizeChange: this.onShowSizeChangeTask,
                                                                       pageSizeOptions: ['10', '20', '30', '40'],
                                                                       showSizeChanger: true,
                                                                       // position:'bottomRight'
                                                                   }
                                                               }
                                                        />

                                                    </div>

                                                </div>
                                                {/*1.6.1 end*/}


                                                {/*1.6.2*/}
                                                {/*1.5.2 删除*/}
                                                {/*<div className="title_top">*/}
                                                {/*    <h4>1.5.2 虚拟仿真实训服务专业的占比</h4>*/}
                                                {/*</div>*/}
                                                {/*<div className="conte">*/}
                                                {/*    <div className='conteCompPercent'>*/}
                                                {/*        <span>学校专业数量</span>*/}
                                                {/*        <div className="span_input">{this.state.total1_5_2}</div>*/}
                                                {/*        <span>虚拟仿真实训服务专业数量</span>*/}
                                                {/*        <div*/}
                                                {/*            className="span_input"> {this.state.complete1_5_2} </div>*/}
                                                {/*        <span style={{marginRight: '17px'}}>占比</span>*/}
                                                {/*        <div*/}
                                                {/*            className="span_input"> {(this.state.complete1_5_2 * 100 / this.state.total1_5_2).toFixed(2)} %*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="button_bottom">*/}
                                                {/*        {*/}
                                                {/*            canEdit ? <div className='conteBtnDiv'*/}
                                                {/*                           onClick={this.addPlatforMsg}>*/}
                                                {/*                <Professionalicon/><span*/}
                                                {/*                className='conteBtnDivSpan'>添加专业</span>*/}
                                                {/*            </div> : ""*/}
                                                {/*        }*/}
                                                {/*        /!* <div className='conteBtnDiv'>*/}
                                                {/*            <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}*/}
                                                {/*                   onChange={this.importMajor}/><ExcelImport/><span className='conteBtnDivSpan'>Excel导入</span></div>*/}
                                                {/*        <div className='conteBtnDiv' onClick={() => this.dowLoadFile(majorInfo.targetMark, majorInfo.targetName)}><Daoru/><span className='conteBtnDivSpan'>下载导入模版</span></div> *!/*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*<div className="conte " style={{marginBottom: '100px'}}>*/}
                                                {/*    <div className='conteTable'>*/}
                                                {/*        <Table columns={columnsAPersent} dataSource={columnsADataSource}*/}
                                                {/*               bordered={true}*/}
                                                {/*               locale={{*/}
                                                {/*                   emptyText: <>*/}
                                                {/*                       <div className="Empty-content">*/}
                                                {/*                           <div className="text">*/}
                                                {/*                               <img*/}
                                                {/*                                   src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>*/}
                                                {/*                               <span>暂无数据</span>*/}
                                                {/*                           </div>*/}
                                                {/*                       </div>*/}
                                                {/*                   </>*/}
                                                {/*               }}*/}
                                                {/*               pagination={*/}
                                                {/*                   {*/}
                                                {/*                       total: this.state.total1_5_2,*/}
                                                {/*                       pageSize: this.state.pageSize,*/}
                                                {/*                       onChange: this.pageChange,*/}
                                                {/*                       onShowSizeChange: this.onShowSizeChange,*/}
                                                {/*                       pageSizeOptions: ['10', '20', '30', '40'],*/}
                                                {/*                       showSizeChanger: true,*/}
                                                {/*                       // position:'bottomRight'*/}
                                                {/*                   }*/}
                                                {/*               }*/}
                                                {/*        />*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                {/*1.6.2 end*/}
                                                <div className="title_top title_topB">
                                                    {/*<h4>{studentNumInfo.targetMark} {studentNumInfo.targetName}</h4>*/}
                                                    <h4>1.5.2 激励及开放共享机制创新</h4>
                                                </div>
                                                <div className="conte " style={{marginBottom: '50px'}}>
                                                    <div>
                                                        <div className="conteCompPercent">
                                                            <span>是否编制有支持基地持续建设和运行的激励制度和开放共享制度？</span>
                                                            <Radio.Group disabled={!canEdit}
                                                                         onChange={this.onChange1_5_2}
                                                                         value={this.state.dataSource1_5_2?.answer}
                                                            >
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                        <UploaderComponent
                                                            editEnable={canEdit}
                                                            uploadTitle='添加佐证材料'
                                                            checkTitle='查看佐证材料'
                                                            targetId={this.state.getTargetId1_5_2}
                                                            // fileList={this.state.dataSource1_5_2?.files}
                                                            // onDelete={this.onDelete1_5_2}
                                                            // onGetFiles={this.onGetFiles1_5_2}
                                                        />
                                                    </div>
                                                </div>

                                                <div>
                                                    {/* <div className="title_top title_topB"> */}
                                                    {/*<h4>{studentNumInfo.targetMark} {studentNumInfo.targetName}</h4>*/}
                                                    {/* <h4>1.5.3 虚拟仿真实训解决“三高三难”痛点和难点情况</h4>
                                                    </div>
                                                    <div> */}
                                                    <div className="title_top title_topB">
                                                        {/*<h4>{studentNumInfo.targetMark} {studentNumInfo.targetName}</h4>*/}
                                                        <h4>1.5.3 虚拟仿真实训解决“三高三难”痛点和难点情况</h4>
                                                    </div>
                                                    {
                                                        majorInfo == '' || majorInfo == undefined ?
                                                            ''
                                                            :
                                                            <Threetable majorInfo={majorInfo}
                                                                        targetId={this.state.getTargetId1_5_3}></Threetable>
                                                    }

                                                    {/* <div className="conteCompPercent">
                                                            <span>虚拟仿真实训是否有效解决了“三高三难”痛点和难点？</span>
                                                            <Radio.Group disabled={!canEdit}
                                                                         onChange={this.onChange1_5_4}
                                                                         value={this.state.dataSource1_5_4.answer}
                                                            >
                                                                <Radio value={1}>是</Radio>
                                                                <Radio value={0}>否</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                        <UploaderComponent
                                                            editEnable={canEdit}
                                                            uploadTitle='添加佐证材料'
                                                            checkTitle='查看佐证材料'
                                                            fileList={this.state.dataSource1_5_4.files}
                                                            onDelete={this.onDelete1_5_4}
                                                            onGetFiles={this.onGetFiles1_5_4}
                                                        /> */}

                                                    {/* </div> */}
                                                </div>
                                                <div className="title_top title_topB">
                                                    {/*<h4>{studentNumInfo.targetMark} {studentNumInfo.targetName}</h4>*/}
                                                    <h4>1.5.4 虚拟仿真实训体现行业企业新理念、新技术、新工艺、新规范、新标准的情况</h4>
                                                </div>

                                                <div className="conte" style={{marginBottom: '50px'}}>

                                                    <Effective_1_5_4
                                                        canEdit={canEdit}
                                                        getTargetId={this.state.getTargetId1_5_5}
                                                    />
                                                </div>

                                                <div className="title_top title_topB">
                                                    {/*<h4>{studentNumInfo.targetMark} {studentNumInfo.targetName}</h4>*/}
                                                    <h4>1.5.5 虚拟仿真实训基地年度平均利用率</h4>
                                                </div>
                                                <div className="conte" style={{marginBottom: '60px'}}>


                                                    <Effective_1_5_5
                                                        canEdit={canEdit}
                                                        getTargetId={this.state.getTargetId1_5_6}
                                                    />
                                                </div>


                                                <div className="bottom_cont">
                                                    {/*<div className="bottom_more">*/}
                                                    {/*    <span className="more_open" onClick={this.addFormItem}><AddIcon/>添加任务子项</span>*/}
                                                    {/*</div>*/}
                                                    {
                                                        canEdit ? <div className={'footer-btn'}>
                                                            <Button className={'syb'}
                                                                    onClick={() => this.previous()}>上一步</Button>
                                                            <Button type={'primary'}
                                                                    onClick={() => this.next()}>下一步</Button>
                                                        </div> : ""
                                                    }

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        {/* 添加专业*/}
                        <Modal
                            title={majorId ? "编辑专业" : "添加专业"}
                            visible={addPlatforMsgModal}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            onCancel={this.handleCancel}
                            className="Professional_con"
                            wrapClassName="wappop_up"
                            footer={
                                <div className='addModalBtn'>
                                    <Button onClick={() => this.setState({addPlatforMsgModal: false})}>取 消</Button>
                                    <Button onClick={this.columnsATiJiao} type='primary'>提交</Button>
                                    {majorInfo ? '' :
                                        <Button onClick={this.columnsATiJiaoAndAdd} type='primary'>提交并添加</Button>
                                    }
                                </div>
                            }
                        >
                            <Professional majorList={majorList} data={major} wrappedComponentRef={form => {
                                this.Professional = form;
                            }}/>
                        </Modal>
                        {/*todo 添加任务*/}
                        <Modal
                            title={this.state.editingTaskRecord?.id ? '编辑任务' : '添加任务'}
                            visible={this.state.addTaskMsgModal}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            onCancel={this.handleCancel}
                            className="Professional_con"
                            wrapClassName="wappop_up"
                            footer={
                                <div className='addModalBtn'>
                                    <Button onClick={() => this.setState({addTaskMsgModal: false})}>取 消</Button>
                                    <Button onClick={this.columnsTaskTiJiao} type='primary'>提交</Button>
                                    {this.state.editingTaskRecord?.id ? '' :
                                        <Button onClick={this.columnsTaskTiJiaoAndAdd} type='primary'>提交并添加</Button>
                                    }
                                </div>
                            }
                        >
                            <BuildAddTask record={this.state.editingTaskRecord} wrappedComponentRef={form => {
                                this.addTaskForm = form;
                            }}/>
                        </Modal>
                        {/*添加任务*/}
                        {/* 添加专业群*/}
                        <Modal
                            title={majorGroupId ? "编辑专业群" : "添加专业群"}
                            visible={addCertificateModal}
                            width={660}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            className="Professional_con"
                            wrapClassName="wappop_up"
                            footer={
                                <div className='addModalBtn'>
                                    <Button onClick={() => this.setState({
                                        addCertificateModal: false,
                                        majorGroup: {},
                                        majorGroupId: ''
                                    })}>取 消</Button>
                                    <Button onClick={this.columnsBTiJiao} type='primary'>提交</Button>
                                    {
                                        majorGroupId ? '' :
                                            <Button onClick={this.columnsBTiJiaoAndAdd} type='primary'>提交并添加</Button>
                                    }
                                </div>
                            }
                        >
                            <AddProfessionalgroup
                                targetId={this.state.getTargetId1_5_2 ? this.state.getTargetId1_5_2 : ''}
                                majorGroup={majorGroup}
                                wrappedComponentRef={form => {
                                    this.addProfessionalgroup = form;
                                }}/>
                        </Modal>
                        {/*添加附件*/}
                        <Modal
                            title={'添加附件'}
                            visible={addAttachment}
                            width={1000}
                            destroyOnClose={true}
                            closable={false}
                            maskClosable={false}
                            centered={true}
                            className={'Addevidence'}
                            wrapClassName="Addevidence_warp"
                            footer={
                                <div className='addModalBtn'>
                                    <Button onClick={() => this.setState({addAttachment: false})}>取 消</Button>
                                    <Button onClick={this.columnsCTiJiao} type='primary'>提交</Button>
                                    {/*<Button onClick={this.null} type='primary'>提交并添加</Button>*/}
                                </div>
                            }
                        >
                            <AddAttachment studentNumInfo={studentNumInfo} studentNumInfoList={studentNumInfoList}
                                           onRef={(ref) => this.AddevidenceChild = ref}
                                           materialTargetId={materialTargetId}/>
                        </Modal>
                        {/*查看佐证材料*/}
                        <Modal
                            title={'查看佐证材料'}
                            visible={LookEvidence}
                            width={800}
                            centered={true}
                            destroyOnClose={true}
                            className={'Addevidence'}
                            wrapClassName="Addevidence_warp"
                            onCancel={this.handleCancelone}
                            footer={
                                <div className='footer-btn' style={{display: 'none'}}>
                                    <Button className={'quxiao'}
                                            onClick={() => this.setState({LookEvidence: false})}>取消</Button>
                                    <Button type='primary' onClick={() => this.cailiaoTijiao()}>提交</Button>
                                </div>
                            }
                        >
                            <Lookexaminematerial studentNumInfo={studentNumInfo} showList={showList}
                                                 onRef={(ref) => this.Lookexaminematerial = ref}
                                                 targetId={studentNumInfo.id} getStudentNum={this.getStudentNum}/>
                        </Modal>

                        <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>
                        {/*顶部star*/}
                        <Footer/>
                    </div>
                )
            }
        }
    )
)
