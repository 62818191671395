import { get, post } from "../../until/axios_instans";

export const getOssToken = () => get("/api/gongyong/api/v1/oss/ossToken");

//预览图获取
export const getbase64Img = (data) =>
  post(
    window.apiPrefix + "/api/v1/rcenter/common/defaultCover/getbase64Img",
    data
  );

export const uploadFile = data => {
  return post(' /api/v1/fujian/uploadFile', data);
}
