import React, {Component} from 'react'
import {Input, Spin,Form,Tooltip,Upload,message } from "antd";
import "./index.scss";
import {uploadFile} from "../../api/uploadFile";
import {Upicon,Upiconwc,Upicondele} from "../iconSvg";


class AddTeacherLeader extends React.Component {
    state = {
        value: 1,
    }

    componentDidMount() {
        let {data} = this.props;
        if(data){
            let upFile = {
                originName: data ? data.resourceName : '',
                fileId: data ? data.fileId  : '',
                fileSuffix: data ? data.resourceSuffix : '',
                fileSize: data ?  ( data.fileSize / 1024).toFixed(2) : ''
            }
            this.setState({
                data: data,
                file: data.fileId ? upFile : null,
            })
            this.props.form.setFieldsValue({
                applyMajor: data ? data.applyMajor : '',
                leaderContact: data ? data.leaderContact : '',
                leaderDesc: data ? data.leaderDesc : '',
                leaderName: data ? data.leaderName : '',
                fileId: data ? data.fileId : '',
            })
        }
    }

    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };


    handleCancel = () =>{
        this.setState({
            addCertificateModal:false,
        })
    }

    onChange  = () =>{
        this.setState({
            addCertificateModal:false,
        })
    }
    onChangeSR = e => {
        console.log('radio checked', e.target.value);
        this.setState({
            value: e.target.value,
        });
    };

    getState = () =>{
        return this.state.file
    }

    // 校验手机号格式
    validatePhone =  (rule, value, callback) => {
        let pattern =  /^1[3|4|5|7|8][0-9]\d{8}$/;
        if (value && !pattern.test(value)){
            callback('请输入正确的手机号，如：13988886666')
        } else {
            callback();
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const { TextArea } = Input;
        const {file} = this.state;
        const props = {
            name: 'file',
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            headers: {
                authorization: 'authorization-text',
            },
            onChange(info) {
                if (info.file.status !== 'uploading') {
                    console.log(info.file, info.fileList);
                }
                if (info.file.status === 'done') {
                    message.success(`${info.file.name} file uploaded successfully`);
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        };



        return (
            <div className='AddTeacherLeader_cont'>
                <Form>
                    <Form.Item label="带头人姓名" >
                        {
                            getFieldDecorator('leaderName', {
                                rules: [
                                    {
                                        required: true,
                                        message: '带头人姓名不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入带头人姓名' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`leaderName`) ? this.props.form.getFieldValue(`leaderName`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>
                    <Form.Item label="联系方式" >
                        {
                            getFieldDecorator('leaderContact', {
                                rules: [
                                    {
                                        required: true,
                                        message: '联系方式不能为空'
                                    },
                                    {
                                        validator:this.validatePhone
                                    }
                                ]
                            })(
                                <Input placeholder='请输入联系方式' />
                            )
                        }
                    </Form.Item>
                    <Form.Item label="专业名称"  >
                        {
                            getFieldDecorator('applyMajor', {
                                rules: [
                                    {
                                        required: true,
                                        message: '专业名称不能为空'
                                    },
                                ]
                            })(
                                <Input placeholder='请输入专业名称' maxLength={30} suffix={<span className='len'>
                                                {this.props.form.getFieldValue(`applyMajor`) ? this.props.form.getFieldValue(`applyMajor`).length : 0}/30</span>}/>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="个人简介"  className='personalProfile'>
                        {
                            getFieldDecorator('leaderDesc', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入个人简介'
                                    },
                                ]
                            })(
                                <TextArea placeholder='请输入带头人个人简介'  maxLength='100' rows={4}/>
                            )}
                        <p className="font-num">{this.props.form.getFieldValue(`leaderDesc`)?this.props.form.getFieldValue(`leaderDesc`).length:0}/100</p>

                    </Form.Item>
                    {/* <Form.Item className={'li_formup input-wrap'} label={"佐证材料"}>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file"  className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{ display: "none" }}
                                        onClick={(event)=> { event.target.value = null }}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit"> */}
                                    {/*上传中*/}
                                    {/*{file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}*/}
                                    {/* {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null} */}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                {/* </ul>
                            </div>
                        )}
                    </Form.Item> */}
                </Form>
            </div>
        )
    }
}


AddTeacherLeader = Form.create()(AddTeacherLeader);
export default AddTeacherLeader;