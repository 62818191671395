// 项目经费预算-人才培养模式改革
import React from "react";
import {Button, Card, Col, Form, message, Row} from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import StepTop from "../components/StepTop";
import SchoolDevelopment from "../components/Budget-total";
import Cookies from "js-cookie";
import {updateProcessStatus} from "../../api/effect";

export default Form.create()(
    class ReformPersonnel extends React.Component {
        state = {
            processCode: '2.5',
            rightDq: "",//右侧当前是什么
            // 任务id
            taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
            // 当前学校的信息
            schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
            /// 学校的code
            schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
            index: 0,
            dataList: [],
            selectSeniorName: "",
            expenditureList: []
        };

        componentDidMount() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            window.addEventListener('scroll', this.bindHandleScroll)
        }

        componentWillUnmount() {
            clearInterval(this.timer);
        }

        bindHandleScroll = (event) => {
            let ctx = this;
            let clientHeight = document.documentElement.clientHeight; //可视区域高度
            let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
            if (scrollTop > 200) {
                document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
            } else {
                document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
            }
        }

        preStep = () => {
            // 上一步
            console.log(" 上一步")
            this.props.history.push({
                pathname: '/school_jianshe',
            })
        }
        nextStep = () => {
            console.log("下一步")
            if (this.state.expenditureList.length === 0) {
                message.error("请填写经费支出")
                return;
            }
            // 更新进度
            let progressData = {
                schoolEduCode: this.state.schoolEduCode,
                taskId: this.state.taskId,
                processCode: this.state.processCode
            };

            updateProcessStatus(progressData).then(res => {
                this.props.history.push({
                    pathname: '/characteristic_creation',
                })
            })
        }

        render() {
            const {getFieldDecorator} = this.props.form
            const {dataList} = this.state;
            return (
                <div className="ReformPersonnel main-bg">
                    {/*顶部star*/}
                    <Heder/>
                    <div className="Task-top main_cont">
                        <div className="main-cont">
                            <Card>
                                <Row>
                                    <Col span={24} className="col-li">
                                        <StepTop setCode={this.setCode} parentCode={"2"}/>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    </div>
                    <div className="Task-bottom clearfix">
                        <div className="main_cont">
                            <div className="Task-bottom-main">
                                <Row type="flex" justify="space-between">
                                    <div className="right_ReformPersonnel">

                                        <div className="conte">
                                            {/*<AddPersonne/>*/}
                                            <SchoolDevelopment
                                                // 保存了列表文件,主要用于判断是否填写了新增支出
                                                getExpenditureList={(expenditureList) => {
                                                    this.setState({expenditureList})
                                                }}
                                                index={this.state.index}
                                                getSubItemList={this.selectSubTask}
                                                upSeniorId={"2.5"}
                                                seniorId={this.state.rightDq}
                                                taskId={this.state.taskId}
                                                saveUrl={"保存地址"} delUrl={"删除地址"}
                                                dataList={dataList}/>
                                        </div>
                                        <div className="bottom_cont">
                                            {/*<div className="bottom_more">*/}
                                            {/*    <span className="more_open" onClick={this.addFormItem}><AddIcon/>添加任务子项</span>*/}
                                            {/*</div>*/}
                                            <div className="bottom_antd_reform_personal">
                                                <Button className="but-li"
                                                        htmlType="submit"
                                                        onClick={this.preStep}>
                                                    上一步
                                                </Button>
                                                <Button className="but-li"
                                                        htmlType="submit"
                                                        onClick={this.nextStep}>
                                                    下一步
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    {/*顶部star*/}
                    <Footer/>
                </div>
            );
        }
    }
);
