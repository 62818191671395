import React, { Component } from "react";
import { Table, Modal, Button, message } from "antd";
import { Professionalicon } from "@@/iconSvg";
import ImportComponent from "@@/importComponent/ImportComponent";
import { getCommonDic, newSituation, saveNewSituation, deleteNewSituation } from '@/api/constructionResults'
import Cookies from "js-cookie";
import Forms from './Forms';
import "./styles.scss";

export default class Effective_1_5_4 extends Component {
  state = {
    total: 0,
    pageSize: 0,
    pageNum: 1,
    data: [],
    modalVisible: false,
    schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    typeList: [],
    editObj: {},
  }

  componentDidMount() {
    console.log(this.state.schoolInfo,"this.state.schoolInfo")
    this.initGetList();
    this.initCommonDic()
  } 

  // 请求数据
  initGetList = async (pageNum = 1, pageSize = 10) => {
    /**
     * "pageNum": "", 当前页
     * "pageSize": "", 每页记录数
     * "schoolEduCode": "", 学校编码
     * "targetId": ""  指标ID
    */

    const params = {
      pageNum,
      pageSize,
      "schoolEduCode": this.state.schoolInfo.schoolEduCode,
	    "targetId": this.props.getTargetId
    }

    const res = await newSituation(params);

    if(res) {
      const { pageNum, pageSize, total, data } = res;
      
      data.map((element, i) => element.index = (pageNum - 1) * pageSize + (i + 1));

      this.setState({ 
        pageNum,
        pageSize,
        total,
        data,
      })
    }
  }

  // 通过分组名称获取字典信息
  initCommonDic = async () => {
    const typeList = await getCommonDic({groupName: 'situationType'});

    if(typeList) this.setState({ typeList });
  }

  // 添加单条数据
  onInsertData = () => this.setState({ editObj: {} }, () => this.setState({ modalVisible: true }));

  // 修改单条数据
  onUpdateRecord = editObj => this.setState({ editObj }, () => this.setState({ modalVisible: true }));

  // 删除单条数据
  onDeleteRecord = async id => {
    const params = {
      id,
      "pageNum": 1,
      "pageSize": 10
    }

    const res = await deleteNewSituation(params);

    if(res === 1) {
      message.success('删除成功');

      this.initGetList(1, this.state.pageSize);
    }
  };

  // 提交表单
  onSubmitForm = (isContinue = false) => {
    /**
      *  "id": "", 主键ID
      *  "situationName": "", 名称
      *  "description": "", 情况说明
      *  "situationType": 0, 情况类型
      *  "schoolEduCode": "", 学校编码
      *  "targetId": ""  指标ID
    */

    const params = {
      schoolEduCode: this.state.schoolInfo.schoolEduCode,
      targetId: this.props.getTargetId,
    }
    
    if (this.state.editObj?.id) {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          const values = {...params, ...value, id: this.state.editObj?.id}
          const res = await saveNewSituation(values);
          if(res === 1) {
            message.success('修改成功');
            this.setState({ modalVisible: false });
            this.initGetList(this.state.pageNum, this.state.pageSize);
          } 
        }
      })
    } else {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          const values = {...params, ...value}
          const res = await saveNewSituation(values);
          if(res === 1) {
            message.success('新增成功');
            this.initGetList(1);
            console.log(isContinue)
            isContinue ? this.modalForm.props.form.resetFields() : this.setState({ modalVisible: false });
          }
        }
      })
    }
  }

  // 切换页码
  onPageChange = (pageNum, pageSize) => {
    this.setState({ pageNum, pageSize }, () => { this.initGetList(pageNum, pageSize); });
  };

  // 切换页码
  onShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize }, () => { this.initGetList(1, pageSize); });
  };
  
  render() {
    const { total, pageSize, pageNum, data, modalVisible, editObj, typeList } = this.state;
    const { canEdit, getTargetId, } = this.props;

    let columns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        align: "center",
        width: 80
      },
      {
        title: "名称",
        dataIndex: "situationName",
        key: "situationName",
        align: "center",
      },
      {
        title: "类型",
        dataIndex: "situationType",
        key: "situationType",
        align: "center",
        render: (text) => {
          return typeList.find(item => text == item.dicKey)?.dicValue
        }
      },
      {
        title: "说明",
        dataIndex: "description",
        key: "description",
        align: "center",
      }
    ];

    if (canEdit) { 
      columns.push({
        title: '操作', 
        align: 'center',
        width: 200,
        dataIndex: 'oper',
        key: 'oper',
        render: (_, record) => <div className='render-span vertical_j oper'>
          <div>
            <span className="cz" onClick={() => this.onUpdateRecord(record)}>编辑</span>
            <span className="cz" onClick={() => this.onDeleteRecord(record.id)}>删除</span>
          </div>
        </div>
      })
    }

    return (
      <div className="effective_1_5_4_wrap">
        <div className="conteCompPercent">
          <span>总计</span>
          <div className="span_input">{total}</div>
        </div>

        <div className="insert_upload_button">
          {canEdit ? (
            <div className="conteBtnDiv" onClick={this.onInsertData}>
              <Professionalicon />
              <span className="conteBtnDivSpan">添加</span>
            </div>
          ) : (
            ""
          )}

          <ImportComponent
            editEnable={canEdit}
            targetId={getTargetId}
            targetMark="1.5.4"
            downloadName="虚拟仿真实训体现行业企业新理念、新技术、新工艺、新规范、新标准的情况"
            onImportSuccess={res => {
              if(res === 1) {
                this.initGetList(1);
              }
            }}
          />
        </div>

        <div style={{ marginBottom: "100px" }}>
          <div className="conteTable">
            <Table
              columns={columns}
              dataSource={data}
              bordered={true}
              locale={{
                emptyText: (
                  <>
                    <div className="Empty-content">
                      <div className="text">
                        <img src={ window.$$imgSrcLcl + "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg" } />
                        <span>暂无数据</span>
                      </div>
                    </div>
                  </>
                ),
              }}
              pagination={{
                total: total,
                pageSize: pageSize,
                current: pageNum,
                onChange: this.onPageChange,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
        
        {
          modalVisible ?
          <Modal
            title={!editObj.id ? '添加' : '编辑'}
            visible={modalVisible}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            onCancel={() => {}}
            className="effective_1_5_4_modal"
            footer={
              <div className='addModalBtn'>
                <Button onClick={() => this.setState({modalVisible: false})}>取 消</Button>
                <Button onClick={()=>this.onSubmitForm(false)} type='primary'>提交</Button>
                {!editObj?.id && <Button onClick={()=>this.onSubmitForm(true)} type='primary'>提交并继续添加</Button>}
              </div>
            }
          >
            <Forms 
              record={this.props.editingTaskRecord} 
              wrappedComponentRef={form => { this.modalForm = form }}
              typeList={typeList}
              editObj={editObj}
            />
          </Modal> : null
        }
        
      </div>
    );
  }
}
