import { post } from '../../until/axios_instans';
let url = window.apiShixun + '/api/v1/internationalCommunication/';
// 获取国际交流总数据
export function selectInternationalCommunication(data) {
    return post(url+'selectInternationalCommunication', data);
}
// 获取人才
export function selectPersonnel(data) {
    return post(url+'selectPersonnel', data);
}
// 删除人才
export function deletePersonnel(data) {
    return post(url+'deletePersonnel', data);
}
// 修改人才
export function updatePersonnel(data) {
    return post(url+'updatePersonnel', data);
}
// 新增人才
export function addPersonnel(data) {
    return post(url+'addPersonnel', data);
}
// 获取人才详情
export function personnelDetail(data) {
    return post(url+'personnelDetail', data);
}
// 获取资源
export function selectResources(data) {
    return post(url+'selectResources', data);
}
// 删除资源
export function deleteResources(data) {
    return post(url+'deleteResources', data);
}
// 修改资源
export function updateResources(data) {
    return post(url+'updateResources', data);
}
// 新增资源
export function addResources(data) {
    return post(url+'addResources', data);
}
// 获取资源详情
export function resourcesDetail(data) {
    return post(url+'resourcesDetail', data);
}
// 导入-人才
export function checkImportPersonnel(data) {
    return post(url+'checkImportPersonnel', data);
}
// 导入-资源
export function checkImportResources(data) {
    return post(url+'checkImportResources', data);
}

