import React, { Component } from "react";
import { Input, Form, Select } from "antd";

const FormItem = Form.Item;
const { Option } = Select;

class Forms extends React.Component {
  state = {
    file: null,
  };

  componentDidMount() {
    const { editObj } = this.props;

    if (Object.keys(editObj || {}).length !== 0) {

      this.props.form.setFieldsValue({
        ...editObj,
        situationType: editObj.situationType + ''
      });
    } else {
      this.props.form.resetFields();
    }
  }

  handleCancel = () => {
    this.setState({
      addCertificateModal: false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 10 },
    };

    return (
      <Form {...layout}>
        <FormItem label="名称">
          {getFieldDecorator("situationName", {
            rules: [
              {
                required: true,
                message: "请填写名称",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem label="类型">
          {getFieldDecorator("situationType", {
            rules: [
              {
                required: true,
                message: "请填写类型",
              },
            ],
          })(
            <Select
              placeholder={''}
              dropdownMatchSelectWidth={true}
              onChange={this.props.childrenTypeChange}
              value={this.state.typeValue}
            >
              {
                this.props.typeList.map((item) => (
                  <Option key={item.dicKey} value={item.dicKey}>
                    {item.dicValue}
                  </Option>
                ))
              }
            </Select>
          )}
        </FormItem>
        <FormItem label="说明">
          {getFieldDecorator("description", {
            rules: [
              {
                required: true,
                message: "请填写说明",
              },
            ],
          })(<Input />)}
        </FormItem>
      </Form>
    );
  }
}

Forms = Form.create()(Forms);
Forms.defaultProps = {
  record: null,
};
export default Forms;
