import React, { Component } from "react";
import Videojs from "video.js";
import "videojs-contrib-hls";
import {Button, Modal} from 'antd';
import "video.js/dist/video-js.css";
import "./index.scss";
window.videojs = Videojs;
class VideoPlayer extends Component {
    state={
        url:'',//视频地址
        currentTime:0,//当前播放时间
        durationTime:0,//视频总时长
        setTimeFlag:true,//是否设置初始化时间
        permitPlay:true,//是否允许操作播放暂停等操作
        muted:true,//静音
        dpiType:'b',//分辨率类型 b是标清 c是高清 g是超清
        dpiList:[
            {name:'标清',value:'b'},
            {name:'高清',value:'c'},
            {name:'超清',value:'g'}
        ],
        maxTime:0,//观看最长时间
        isCut:false,//是否是切换清晰度
        src:''
    }
    // 初始化内容
    UNSAFE_componentWillReceiveProps(nextprops) {
        try {
            const { src,permitPlay,...options  } = nextprops;
            console.log("src",src,this.props.src)
            if(src&&src != this.state.src){

                this.initVideo(src);
                this.setState({
                    src
                })
            }
            if(permitPlay!=undefined&&permitPlay!=this.state.permitPlay){
                let toolBar= document.getElementsByClassName("vjs-control-bar")[0];
                if(permitPlay){
                    //播放
                    this.player.play()
                    toolBar.style.display='flex'
                }else{
                    //停止播放
                    this.player.pause()
                    toolBar.style.display='none'
                }
                this.setState({
                    permitPlay:permitPlay
                })
            }
        } catch (error) {
            console.log(error);
        }
    }
    componentWillUnmount() {
        // 销毁播放器
        if (this.player) {
            this.player.dispose();
        }
    }
    _checkFn(fn){
        return (typeof fn=="function"?fn:function(){});
    }
    initVideo(src) {
        const {autoplay,controls,preload,width,height,videoPlayTime,checkDpi,permitExpress}=this.props
        const {dpiType,dpiList}=this.state
        let _=this
        let onPlay = _._checkFn(_.props.onPlay),
            onEnded = _._checkFn(_.props.onEnded),
            onPause = _._checkFn(_.props.onPause);
        this.player = Videojs(this.videoNode, {
            autoplay: autoplay,  //自动播放
            language: 'zh-CN',
            controls: controls,  //控制条
            preload: preload,  //自动加载
            errorDisplay: true,  //错误展示
            width: width,  //宽
            height: height,  //高
            muted:false,//静音
            textTrackDisplay: false,
            playbackRates: [0.5,1,1.5,2],//倍速播放
            userActions: {
                hotkeys: true  //是否支持热键
            },

            sources: [{
                src: src,
                type: 'application/x-mpegURL'
            }]
        },function () {
            if(checkDpi){
                let modelSte=''
                for (let i=0;i<dpiList.length;i++){
                    let cur=dpiList[i]
                    modelSte=modelSte+`<li class="api-li" data-type=${cur.value}>${cur.name}</li>`
                }
                let ele=document.getElementsByClassName('vjs-control-bar')[0]
                let str=`<div class="model_cont">
                            <div id="dpi">
                                <div class="scree-dpi"> 
                                      <span id="curEle">标清</span> 
                                </div>
                                <ul class="model-dpi" id="modelDpi">${modelSte}</ul>
                             </div>
                          </div>`
                var li1 = document.createElement('div');
                li1.innerHTML=str
                ele.appendChild(li1)
                let dpiEle=document.getElementById('dpi')
                let modelDpiEle=document.getElementById('modelDpi')
                dpiEle.onmouseover = ()=>{
                    modelDpiEle.style.display='block'
                }
                dpiEle.onmouseout  = ()=>{
                    modelDpiEle.style.display='none'
                }
                let apiLi=document.getElementsByClassName('api-li')
                //添加点击事件
                for(let i=0; i<apiLi.length; i++){
                    let cur=apiLi[i]
                    cur.onclick=function () {
                        let dataType=cur.getAttribute('data-type')
                        let curEle=document.getElementById('curEle')
                        curEle.innerText=dataType==='g'?'超清':dataType==='c'?'高清':'标清'
                        _.setState({
                            dpiType:dataType,
                            isCut:true
                        },()=>{
                            let strIndex=src.lastIndexOf("_")
                            let str=src.substring(strIndex,strIndex+2)
                            let videoSrc=src.split(str).join(`_${dataType}`)
                            console.log("videoSrc",videoSrc)
                            _.player.src(videoSrc);
                            _.player.load();
                            //设置播放器从curtime开始播放
                            _.player.currentTime(_.state.currentTime);
                        })
                    }
                }
            }
            this.on('play', ()=> {//开始播放
                if(_.state.permitPlay){
                    let durationTime=this.duration()
                    onPlay&&onPlay(durationTime)
                    videoPlayTime&&videoPlayTime>0&&_.state.setTimeFlag&& this.currentTime(videoPlayTime)//设置播放时间
                    _.setState({
                        setTimeFlag:false
                    })
                }else{
                    this.pause()//暂停
                    this.currentTime(_.state.currentTime)
                }
            });
            this.on('pause',()=> {//暂停
                onPause&&onPause()
            });
            //播放结束
            this.on('ended', ()=> {
                onEnded&&onEnded()
            });
            //进度条改变
            this.on('timeupdate', ()=> {
                //获取当前时间
                if(_.state.currentTime>0&&!_.state.isCut&&Math.abs(parseFloat(this.currentTime())-parseFloat(_.state.currentTime))>2){//如果是快进或者快退
                    //permitExpress:true:允许快进拖动
                    this.currentTime(!permitExpress?this.currentTime()<_.state.maxTime?this.currentTime():_.state.maxTime:this.currentTime())
                    _.setState({
                        currentTime:this.currentTime()
                    })
                }else{
                    _.setState({
                        currentTime:this.currentTime(),
                        isCut:false
                    },()=>{
                        if(_.state.currentTime>_.state.maxTime){
                            _.setState({
                                maxTime:this.currentTime()
                            })
                        }
                    })
                }
            });
        });
    }
    render() {
        const {checkDpi}=this.props
        // 默认的props
        return (
            <div
                className={`${checkDpi?'custom-video-warpper':''}`}
                style={{
                    display: this.props.src ? "block" : "none"
                }}
            >
                <video ref={ node => this.videoNode = node }  className="video-js" >
                </video>
            </div>
        );
    }
}
VideoPlayer.defaultProps = {
    autoplay:true,
    controls:true,
    preload: 'auto',
    width:800,
    height: 'auto',
    checkDpi:true,
    permitExpress:false,
    permitPlay:true,
}
export default VideoPlayer;
//父组件
class Preview extends Component {
    state={
        videoUrl:'',
        permitPlay:true
    }
    componentDidMount() {
        this.getVideoUrl()
    }
    componentWillUnmount() {
        clearInterval(this.studyTimer)
    }

    getVideoUrl = async () => {
        let videoUrl =
            "http://zjyddev.oss-cn-beijing.aliyuncs.com/zjyd-front-img/zjyd-space-front-geren/20230102/6733a028d4c34b24a760f9c7de199104_b.m3u8";
        this.setState({ videoUrl });
    };
    //开始播放
    onPlay=()=>{
        console.log("开始播放")
        clearInterval(this.studyTimer)
        this.studyTimer=setInterval(()=>{
            let currentTime=Math.floor(this.childPlay.state.currentTime)
            if(currentTime===210){
                this.setState({
                    modelAnswer:true,
                    permitPlay:false
                })
            }
            this.setState({
                studyTime:this.state.studyTime+1
            },()=>{
            })
        },1000)
    }
    onPause=()=>{
        clearInterval(this.studyTimer)
        console.log("暂停")
    }
    onEnded=()=>{
        console.log("播放结束")
    }
    handleOk = e => {
        console.log(e);
        this.setState({
            modelAnswer: false,
            permitPlay:true
        });
    };
   render() {
       const {videoUrl,permitPlay,modelAnswer}=this.state
       return(
           <div>
              <VideoPlayer onPlay={this.onPlay} onPause={this.onPause} width={700} onEnded={this.onEnded} checkDpi={true}  permitExpress={true} permitPlay={permitPlay } videoPlayTime={'200'}  videoId={'videoId1'}  src={videoUrl}  ref={(e)=>{this.childPlay=e}}  />
               <Modal
                   title="答题"
                   visible={modelAnswer}
                   footer={[
                       <Button key="submit" type="primary" onClick={this.handleOk}>
                           提交
                       </Button>,          ]}
               >
                   <div>
                       <textarea></textarea>
                   </div>
               </Modal>
           </div>
       )
   }

}
export const VideoPreview = Preview
