import React, {Component} from 'react'
import {connect} from 'react-redux'
import {Button, Form, Input, message} from 'antd'
import {withRouter} from 'react-router-dom'
import {Dllogo, Password, Phone} from '@/components/iconSvg'
import {LOGIN_SUBMITFN} from '../../store/OBS/login/action'
import Cookies from "js-cookie";
import './index.scss'

const FormItem = Form.Item

class Login extends Component {
    componentDidMount() {
        // let pathName= window.location.href
        // var regExp=/#(\S*)/;
        // pathName= pathName.replace(regExp,"")
        // console.log("pathName",pathName)
        // localStorage.setItem("innerWebsite",pathName)
    }

    removeCookies() {
        Cookies.remove('vsToken');
        Cookies.remove('schoolInfo');
        Cookies.remove('userInfo');
        Cookies.remove('menuList');
        Cookies.remove('buttonPerms');
    }

    handleSubmit = e => {
        //登录
        this.props.form.validateFields((err, values) => {
            this.removeCookies();
            if (!err) {
                const userName = values.userName
                const password = values.password
                if (userName === "admin" && password === "123456") {
                    Cookies.set('vsToken', "token");
                    message.success("登录成功")
                    this.props.history.replace('/mission_statement')
                } else {
                    message.error("密码错误");
                }
                // let data = {userName, password}
                // userLogin(data).then(res => {
                //     console.log('res', res)
                //     if (res) {
                //         let info = {token: res.token, schoolInfo: res.schoolInfo, userInfo: res.userInfo}
                //         Cookies.set('vsToken', res.token);
                //         Cookies.set('schoolInfo', res.schoolInfo);
                //         Cookies.set('userInfo', res.userInfo);
                //         Cookies.set('buttonPerms', res.buttonPerms);
                //         Cookies.set('menuList', res.menuList);
                //         this.resetUserInfo(res.userInfo, res.schoolInfo, values)
                //     }
                // })
            }
        });
    }

    resetUserInfo(res, schoolInfo, values) {
        const userInfo = res;
        console.log('userInfo', userInfo)
        if (userInfo) {
            this.props.setUserInfo(Object.assign({}, userInfo))
            if (userInfo.roleType === 1) {
                // this.props.history.replace('/index')
                this.props.history.replace('/effectiveness_Allowed')
            } else if (userInfo.roleType === 2) {
                this.props.history.replace('/effectiveness_Allowed')
            } else if (userInfo.roleType === 3) {
                if (schoolInfo === null) {
                    return message.error("您没有登录该系统的权限，请联系管理员")
                } else {
                    this.props.history.replace('/mission_statement')
                }
            } else {
                message.error("您没有登录该系统的权限，请联系管理员");
            }
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form
        return (
            <div className="login_content">
                <div className="login_warp">
                    <div className="right_login">
                        <h2>欢迎登录平台</h2>
                        <div className="right_cont">
                            {/*todo 图片需要替换掉*/}
                            <div className="title_icon"><Dllogo/></div>
                            <Form>
                                <Form.Item className="Item-li">
                                    {
                                        getFieldDecorator('userName', {
                                            initialValue: "",
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "输入手机号",
                                                },
                                            ],
                                            getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                        })(
                                            <Input className="input" prefix={<Phone/>} minLength={2}
                                                   placeholder='输入手机号'/>
                                        )
                                    }
                                </Form.Item>
                                <Form.Item className="Item-li Item-li_last" name="password">
                                    {
                                        getFieldDecorator('password', {
                                            initialValue: "",
                                            rules: [
                                                {
                                                    required: true,
                                                    message: "请输入密码",
                                                },
                                            ]
                                        })(
                                            <Input className="input" type="password" prefix={<Password/>} minLength={2}
                                                   placeholder='请输入密码'/>
                                        )
                                    }
                                </Form.Item>
                                {/*<span className="span_right">忘记密码?</span>*/}
                            </Form>
                            <div className="bottom_botton">
                                <Button type="primary" onClick={() => this.handleSubmit()}>登 录</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({LOGIN}) => {
    return {
        userFlag: LOGIN.userFlag,
        uname: LOGIN.uname,
        pwd: LOGIN.pwd
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserInfo(data) {
            dispatch(LOGIN_SUBMITFN(data))
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Form.create()(Login)))
