// 项目情况-平台情况
import React from "react";
import {Form, Button, Modal, message, Input} from 'antd';
import "./index.scss";
import {withRouter} from "react-router-dom";
import {Addrenwu, WenxinTip} from '../../../components/iconSvg'
import {saveSubTask, deleteSubTask, updateSubTask, selectSubTask} from "../../../api/subTask";
import FormAssignment from './From-assignment/index'
import Cookies from "js-cookie";
import printj from "printj";
import {isNotEmpty} from "../../../until/isEmpty";
import {selectSenior} from "../../../api/senior";
import TextArea from "antd/es/input/TextArea";

const getNewSubTask = () => {
    return (
        {
            formItem: 0,
            saveSubTaskYearVoList: [
                {
                    yearName: '2021年',
                    statYear: 2021
                },
                {
                    yearName: '2022年',
                    statYear: 2022
                },
                {
                    yearName: '2023年',
                    statYear: 2023
                },
            ]
        }
    )
}

export default Form.create()(
    class SchoolJiansherws extends React.Component {
        state = {
            taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
            schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
            schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
            // 显示建设任务
            isShowHit: false,
            showHitMessage: "",

            // 显示添加/编辑model
            showModal: false,

            // 选择的任务
            chooseJiansheTask: undefined,

            taskList: [],
            seniorId: undefined,
        };

        componentDidMount() {
            this.getCurrentSelectSubTaskMethod()
        }

        componentWillUnmount() {

        }

        UNSAFE_componentWillReceiveProps(props) {
            const {seniorId} = this.state
            if (props.seniorId !== seniorId) {
                this.setState({
                    seniorId: props.seniorId
                }, () => {
                    this.getCurrentSelectSubTaskMethod()
                })
            }
        }

        // section request
        // section 获取当前选中的任务数组
        getCurrentSelectSubTaskMethod = () => {
            let data = {
                taskId: this.props.taskId,
                schoolEduCode: this.props.schoolEduCode,
                seniorId: this.state.seniorId
            }
            selectSubTask(data).then((res) => {
                this.setState({
                    taskList: res
                })
            });
        }

        // 校验
        checkMethods = () => {
            if (undefined === this.props.seniorId) {
                this.setState({
                    isShowHit: true,
                    showHitMessage: "请先添加建设任务"
                })
                return false
            }

            return true
        }

        // section 校验是不是所有的建设任务都有数据
        checkAllTaskFinished = (callBack) => {


            let {taskId, schoolEduCode} = this.state
            let {processCode} = this.props
            if (isNotEmpty(processCode)) {
                let data = {
                    taskId: taskId,
                    schoolEduCode: schoolEduCode,
                    upseniorId: processCode
                }
                console.log(data)
                selectSenior(data).then((res) => {
                    let result = res || []
                    let noFinishList = result.filter(item => item.status === 1) || []
                    if (callBack) {
                        callBack(noFinishList)
                    }
                }, () => {
                    message.error("校验失败")
                });

            }

        }

        //新增
        addFormItem = () => {
            if (!this.checkMethods()) {
                return
            }

            const {taskList} = this.state
            this.setState({
                showModal: true,
            }, () => {
                // console.log("formObj", this.state.formObj)
            })

        }

        edit = (item) => {
            let chooseJiansheTask = item
            this.setState({
                chooseJiansheTask: chooseJiansheTask,
                showModal: true
            }, () => {
                let saveSubTaskYearVoList = chooseJiansheTask.saveSubTaskYearVoList
                this.props.form.setFieldsValue({
                    subTaskName: chooseJiansheTask.subtaskName,
                    year2021: saveSubTaskYearVoList[0].itemDescription,
                    year2022: saveSubTaskYearVoList[1].itemDescription,
                    year2023: saveSubTaskYearVoList[2].itemDescription
                })
            })
        }

        //删除
        delFormItem = (item) => {
            const {taskList} = this.state

            // 真删
            Modal.confirm({
                className: 'content-warp',
                title: <div>
                    消息提醒
                </div>,
                centered: true,
                content: "确定要删除吗?",
                onOk: () => {
                    let data = {
                        id: item.subtaskId,
                        seniorId: this.props.seniorId
                    }
                    deleteSubTask(data).then(e => {
                        message.success("删除成功")
                        this.getCurrentSelectSubTaskMethod()
                    })
                }
            })
        }
        // 重置单个
        resetOne = () => {
            this.props.form.setFieldsValue({
                subTaskName: "",
                year2021: "",
                year2022: "",
                year2023: "",
            })
        }


        addAndSaveLinkManMethod = () => {
            const {chooseJiansheTask} = this.state
            this.props.form.validateFields((err, value) => {
                if (!err) {

                    if (undefined === chooseJiansheTask) {
                        let saveSubTaskYearQoList = [
                            {
                                "itemDescription": value.year2021,
                                "statYear": '2021',
                                "yearName": '2021年'
                            },

                            {
                                "itemDescription": value.year2022,
                                "statYear": '2022',
                                "yearName": '2022年'
                            },

                            {
                                "itemDescription": value.year2023,
                                "statYear": '2023',
                                "yearName": '2023年'
                            },

                        ]


                        let data = {
                            taskId: this.state.taskId,
                            schoolEduCode: this.state.schoolEduCode,
                            seniorId: this.props.seniorId,
                            subTaskName: value.subTaskName,
                            saveSubTaskYearQoList: saveSubTaskYearQoList,
                        }

                        saveSubTask(data).then((res) => {
                            message.success("添加成功")
                            this.setState({
                                showModal: false,
                                chooseJiansheTask: undefined
                            }, () => {
                                this.getCurrentSelectSubTaskMethod()
                            })
                        })
                    } else {

                        let saveSubTaskYearVoList = chooseJiansheTask.saveSubTaskYearVoList

                        for (const index in saveSubTaskYearVoList) {
                            let item = saveSubTaskYearVoList[index]
                            if (item.statYear === 2021) {
                                item.itemDescription = value.year2021
                            }

                            if (item.statYear === 2022) {
                                item.itemDescription = value.year2022
                            }

                            if (item.statYear === 2023) {
                                item.itemDescription = value.year2023
                            }
                        }

                        updateSubTask({
                            id: chooseJiansheTask.subtaskId,
                            subtaskName: value.subTaskName,
                            saveSubTaskYearQo: saveSubTaskYearVoList
                        }).then(e => {
                            message.success("修改成功")
                            this.setState({
                                showModal: false,
                                chooseJiansheTask: undefined
                            }, () => {
                                this.getCurrentSelectSubTaskMethod()
                            })
                        })
                    }
                }
            })
        }

        handleCancel = () => {
            this.setState({
                showModal: false,
                chooseJiansheTask: undefined
            });
        };


        preStep = () => {
            this.props.preSelectCallBack()
        }

        // section Next
        nextStep = () => {

            if ((this.state.taskList || []).length === 0) {
                this.setState({
                    isShowHit: true,
                    showHitMessage: '请先添加建设子任务内容'
                })
                return false
            }

            this.checkAllTaskFinished((noFinishList) => {
                if (noFinishList.length === 0) {
                    this.props.nextSelectCallBack()
                } else {
                    let hitString = noFinishList.map(data => data.seniorName).join(',')
                    this.setState({
                        isShowHit: true,
                        showHitMessage: "“" + hitString + "”" + '未添加建设子任务内容, 请填写'
                    })
                }
            })

        }

        hideModel = () => {
            this.setState({
                isShowHit: false,
                showHitMessage: ""
            })
        }

        render() {
            const {getFieldDecorator} = this.props.form
            const {taskList, isShowHit, showHitMessage, chooseJiansheTask, showModal} = this.state;
            const index = taskList.indexOf(chooseJiansheTask)

            const isEditing = undefined === chooseJiansheTask
            console.log("taskList", taskList)

            const formItemLayout = {
                // labelCol: {
                //     xs: { span: 6 },
                //     sm: { span: 6 }
                // },
                // wrapperCol: {
                //     xs: { span: 28 },
                //     sm: { span: 28 }
                // },
            };
            // section Modal
            const sub_jian_she_modal = <Modal
                title={(isEditing) ? '添加' : '编辑'}
                visible={showModal}
                width={700}
                destroyOnClose={true}
                closable={false}
                maskClosable={false}
                centered={true}
                className={'addModal_jianshe'}
                wrapClassName="wappop_up"
                footer={
                    <div className='footer-btn'>
                        <Button className={'quxiao'}
                                onClick={() => this.handleCancel()}>取消</Button>
                        <Button type='primary'
                                onClick={() => this.addAndSaveLinkManMethod()}>提交</Button>
                        {/*{isEdit === false ?*/}
                        {/*    <Button type='primary'*/}
                        {/*            onClick={() => this.addContinue()}>*/}
                        {/*        提交并继续添加</Button> : ''}*/}
                    </div>
                }
            >
                <div>
                    <Form>
                        <Form.Item label={`建设任务-子项` + (index + 1 )} >
                            {getFieldDecorator('subTaskName', {
                                initialValue: chooseJiansheTask?.subTaskName,
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写建设任务-子项`,
                                    },
                                    {
                                        min: 2,
                                        message: "文本最少2字"
                                    },
                                    {
                                        max: 100,
                                        message: "文本最多100字"
                                    },
                                ],
                                // getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<TextArea className="TextArea-pic"
                                         rows={2}
                                         maxLength={100}/>
                            )}
                            <div className="number-count">{this.props.form.getFieldValue(`subTaskName`) ? this.props.form.getFieldValue(`subTaskName`).length : 0}/100</div>
                        </Form.Item>
                        <Form.Item label={'2021年'}>
                            {getFieldDecorator(`year2021`, {
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写2021年建设任务`,
                                    },
                                    {
                                        min: 2,
                                        message: "文本最少2字"
                                    },
                                    {
                                        max: 1000,
                                        message: "文本最多1000字"
                                    },
                                ],
                                // getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<TextArea className="TextArea-pic" rows={6}
                                         maxLength={1000}
                            />)}
                            <div className="number-count">{this.props.form.getFieldValue(`year2021`) ? this.props.form.getFieldValue(`year2021`).length : 0}/1000</div>
                        </Form.Item>

                        <Form.Item label={'2022年'}>
                            {getFieldDecorator(`year2022`, {
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写2022年建设任务`,
                                    },
                                    {
                                        min: 2,
                                        message: "文本最少2字"
                                    },
                                    {
                                        max: 1000,
                                        message: "文本最多1000字"
                                    },
                                ],
                                // getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<TextArea className="TextArea-pic" rows={6}
                                         maxLength={1000}
                            />)}
                            <div className="number-count">{this.props.form.getFieldValue(`year2022`) ? this.props.form.getFieldValue(`year2022`).length : 0}/1000</div>
                        </Form.Item>

                        <Form.Item label={'2023年'}>
                            {getFieldDecorator(`year2023`, {
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写2021年建设任务`,
                                    },
                                    {
                                        min: 2,
                                        message: "文本最少2字"
                                    },
                                    {
                                        max: 1000,
                                        message: "文本最多1000字"
                                    },
                                ],
                                // getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                            })(<TextArea className="TextArea-pic" rows={6}
                                         maxLength={1000}
                            />)}
                            <div className="number-count">{this.props.form.getFieldValue(`year2023`) ? this.props.form.getFieldValue(`year2023`).length : 0}/1000</div>
                        </Form.Item>

                    </Form>
                </div>
            </Modal>


            const formArr =
                (taskList || []).length !== 0 ? taskList.map((item, index) => {
                        return (
                            <div>
                                <FormAssignment formData={item}
                                                num={index}
                                                delFormItem={this.delFormItem}
                                                edit={this.edit}
                                />
                            </div>
                        )
                    })
                    :

                    <div className={'no_data_view_base'}>
                        <div className="no_data_view">
                            <div className="img">
                                <img
                                    src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                <div style={{width: '100%', textAlign: 'center'}}>暂无数据</div>
                            </div>
                        </div>
                    </div>
            return (
                <div className='school_jiansherws'>
                    <h1 className={'small-title'} style={{fontSize: '16px'}}>2-4-1 建设任务和进度</h1>
                    <h2 className={'h2_title'}
                        hidden={undefined === this.props.selectSeniorName}>{this.props.selectSeniorName}</h2>

                    <div className={'addrenwu'} onClick={() => this.addFormItem()}><Addrenwu/>添加任务子项</div>
                    {formArr}
                    <div className={'border-bottom'}/>
                    <div className={'btn-group'}>
                        <Button onClick={this.preStep}>上一步</Button>
                        <Button onClick={this.nextStep}>下一步</Button>
                    </div>

                    {sub_jian_she_modal}
                    <Modal
                        title={'温馨提示'}
                        visible={isShowHit}
                        width={430}
                        destroyOnClose={true}
                        closable={false}
                        maskClosable={false}
                        centered={true}
                        className={'wenxintishi'}
                        footer={
                            <div className='footer-btn'>
                                <Button type='primary'
                                        onClick={this.hideModel}>我知道了</Button>
                            </div>
                        }
                    >
                        <div className={'tipwrap'}>
                            <WenxinTip/><span>{showHitMessage}</span>
                        </div>
                    </Modal>

                </div>
            );
        }
    }
);
