import {post} from '../../until/axios_instans';

// 用户单点登录接口
export function userLogin(data) {
    const trail = `/api/v1/login/sys/login?userName=${data.userName}&password=${data.password}`
    const head = window.apiShixun
    return post(head + trail, data);
}

export function resetPassword(schoolEduCode) {
    return post(window.apiShixun + '/api/v1/user/resetPassword', {schoolEduCode});
}

export function updatePassword(data) {
    return post(window.apiShixun + '/api/v1/user/updatePassword', data);
}
