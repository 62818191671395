// 填写任务书左侧
import React, {Component} from 'react';
import {Button, Modal, Input, message} from 'antd';
import {selectSenior, addSenior, updateSenior, deleteSenior} from "../../../api/senior";
import {IconLeft, FillIcon, EmptyIcon} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import "./index.scss";
import {isNotEmpty} from '../../../until/isEmpty';

@withRouter
class Leftmien extends Component {
    state = {
        upseniorId: this.props.upseniorId,
        isSave: true,
        leftCatalog: this.props.leftCatalog,
        isShow: this.props.isShow,
        targetName: '',
        ifempty: false,
        targetNameNum: 0,
        addMenuVisible: false,
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        menuType: false,
        seniorId: '',
        stepNum: 0,  //当前选中值
    };

    componentDidMount() {
        this.selectSenior();
        let mainCont = document.getElementById('Task-bottom-main')
        let mainContWith = mainCont && mainCont.offsetWidth
        let windowWidth = document.documentElement.clientWidth
        let leftMain = document.getElementById('left-top')
        if (leftMain) {
            leftMain.style.left = (windowWidth - mainContWith) / 2
        }

        console.log("mainContWith", (windowWidth - mainContWith) / 2)
        // 左侧跟随屏幕
        window.addEventListener('scroll', this.bindHandleScroll)
        // 左侧跟随屏幕
    }

    componentWillReceiveProps(nextProps, nextContext) {

        const nextStep = nextProps.stepNum;
        const {stepNum} = this.props
        console.log("左边栏=======" + nextStep)
        console.log("左边栏============" + stepNum)
        if (stepNum !== nextStep) {
            var item = this.state.leftCatalog[nextStep]
            this.selectSubTaskMethod(item, nextStep)
        }
    }


    selectSenior() {
        let data = {
            taskId: this.state.taskId,
            schoolEduCode: this.state.schoolEduCode,
            upseniorId: this.props.upseniorId
        }

        console.log("====> selectSenior", data)

        if (isNotEmpty(this.state.upseniorId)) {
            selectSenior(data).then((res) => {
                let result = res || []
                console.log("selectSenior.res", result)

                this.setState({
                    leftCatalog: result
                }, () => {
                    if (result.length === 0) {
                        this.selectSubTaskMethod(undefined, 0)
                    } else {
                        this.selectSubTaskMethod(result[0], 0)
                    }
                });
            });
        }
    }

    selectSubTaskMethod (item, index) {
        if (undefined === item) {
            if (this.props.qiehuancd) {
                this.props.qiehuancd(undefined)
            }
            if (this.props.selectSubTask) {
                this.props.selectSubTask(undefined)
            }
            if (this.props.selectSeniorName) {
                this.props.selectSeniorName(undefined)
            }
            return
        }

        let id = item.id
        if ("3" === this.state.upseniorId) {
            if (this.props.switchColumn) {
                this.props.switchColumn(index)
            }
        }
        if (this.props.qiehuancd) {
            this.props.qiehuancd(id)
        }
        if (this.props.selectSubTask) {
            this.props.selectSubTask(id)
        }
        if (this.props.selectSeniorName) {
            this.props.selectSeniorName(item.seniorName)
        }
        this.setState({
            stepNum: index
        })
    }

    // selectSubTaskMethod = (item, index) => {
    //     let id = item.id
    //     if ("3" === this.state.upseniorId) {
    //         this.props.switchColumn(index)
    //     }
    //     this.props.qiehuancd(id)
    //     this.props.selectSubTask(id)
    //     if (undefined !== this.props.selectSeniorName) {
    //         this.props.selectSeniorName(item.seniorName)
    //     }
    //     this.setState({
    //         stepNum: index
    //     })
    // }
    addRenWu = () => {
        this.setState({
            targetName: '',
            targetNameNum: 0,
            addMenuVisible: true,
            menuType: "add",
        })
    }
    updateMenu = (type, item) => {
        console.log("item", item)
        this.setState({
            addMenuVisible: true,
            menuType: type,
            targetName: item.seniorName,
            targetNameNum: item.seniorName.length,
            seniorId: item.id,
        })
    }
    handleTargetSave = () => {
        const {targetName, menuType, taskId, upseniorId, seniorId, schoolEduCode} = this.state
        if (targetName.length > 0 && targetName.trim() !== null && targetName.trim() !== '') {
            if (menuType === 'add') {
                console.log("11111111111111111111111111111111", targetName)
                const params = {
                    seniorName: targetName.trim(),
                    taskId: taskId,
                    upseniorId: upseniorId,
                    schoolEduCode: schoolEduCode
                }
                addSenior(params).then((res) => {
                    this.setState({
                        addMenuVisible: false
                    }, () => {
                        message.success("保存成功");
                        this.selectSenior()
                    })
                })
                //这里是新增保存
            } else {
                const params = {
                    id: seniorId,
                    seniorName: targetName.trim(),
                    upseniorId: upseniorId,
                    taskId: taskId,
                    schoolEduCode: schoolEduCode
                }
                updateSenior(params).then((res) => {
                    this.setState({
                        addMenuVisible: false
                    }, () => {
                        message.success("修改成功");
                        this.selectSenior();
                    })
                })
            }
        } else {
            this.setState({
                ifempty: true
            })
        }
    }
    deleteMenu = (id) => {
        //删除id请求接口
        var _ = this
        Modal.confirm({
            className: 'content-warp',
            title: <div>
                消息提醒
            </div>,
            centered: true,
            content: (
                <div>
                    确认删除该指标吗?
                </div>
            ),
            onOk() {
                _.delThirdLevelSenior(id)
            },
            onCancel() {
                _.handleTargetCancel()
            },

        })
        // this.hint({title:'提示信息',type:'warning',content:'确认删除该指标',isCancel:true,cancel:this.handleTargetCancel,sure:()=>{this.delThirdLevelSenior(id)}})

    }
    //取消
    handleTargetCancel = () => {
        this.setState({
            addMenuVisible: false
        })
    }
    //删除确认
    delThirdLevelSenior = (id) => {
        deleteSenior({
            id: id,
            schoolEduCode: this.state.schoolEduCode,
            taskId: this.state.taskId,
            upseniorId: this.state.upseniorId,
            type: 1
        }).then((res) => {
            message.success(`删除成功`);
            this.selectSenior()
            this.setState({
                addMenuVisible: false
            })
        })
    }
    //监听指标名称
    changeTargetName = (e) => {
        this.setState({
            targetName: e.target.value,
            ifempty: false,
            targetNameNum: e.target.value.length
        })
    }
    // 左侧跟随屏幕
    bindHandleScroll = (event) => {
        let scrollTop = event.srcElement ? event.srcElement.documentElement.scrollTop : false || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)

        if (scrollTop > 160) {
            document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", "top:100px;position:fixed;");
        } else {
            document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", "top:0;position:relative;");
        }
    }

    render() {
        const {stepNum,leftCatalog, isShow, addMenuVisible, targetName, ifempty, targetNameNum, menuType} = this.state;
        const {} = this.props
        return (
            <div className="Comment-Leftmien">
                <ul>
                    {leftCatalog ? leftCatalog.map((item, index) => {
                            return <li key={index} className={stepNum === index ? 'on' : ''}
                                  >
                            <h4><i className="icon"/> {item.seniorName}</h4>
                            {item.isSelf === 0 ? "" :
                                <p className="p_rightbut">
                                    <Button onClick={() => {
                                        this.updateMenu("update", item)
                                    }}><FillIcon/></Button>
                                    <Button onClick={() => {
                                        this.deleteMenu(item.id)
                                    }}><EmptyIcon/></Button>
                                </p>
                            }
                            <div className={item.isSelf === 0 ? "li_cell_bottom_no_control" : "li_cell_bottom"} onClick={() => this.selectSubTaskMethod(item, index)}/>

                        </li>
                    }) : ''}
                </ul>
                {isShow ?
                    <div className="more_but">
                        <Button type="primary" onClick={() => this.addRenWu("add")}><IconLeft/>添加建设任务</Button>
                    </div> : ''
                }
                <Modal
                    title={menuType === 'add' ? "添加建设任务" : "编辑名称"}
                    visible={addMenuVisible}
                    onOk={this.handleTargetSave}
                    onCancel={() => {
                        this.setState({addMenuVisible: false})
                    }}
                    wrapClassName="add-indicator"
                    centered
                >
                    <div className="indicator-div">
                        <span><i style={{color:'#f5222d'}}>*</i>任务名称</span>
                        <Input onChange={this.changeTargetName} placeholder="请输入建设任务名称" value={targetName}
                               maxLength={100}/>
                        <span style={{paddingLeft: "10px"}}>{targetNameNum}/100</span>
                    </div>
                    {ifempty && <p className="modal-tip" style={{color:'#f5222d',marginLeft:"70px",marginTop:"10px"}}>请填写建设任务名称</p>}
                </Modal>
            </div>
        );
    }
}

export default Leftmien;
