/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-08-24 17:09:30
 * @LastEditors: nliu
 * @LastEditTime: 2021-08-25 14:15:34
 */
// 效能填报头部,这里应该是通过前一个页面传参请求
import React, {Component} from 'react';
import {Card, Row, Col} from 'antd';
import {Titleicon, Icondh} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {SchoolIcon, LbIcon, XnfzIcon, TbndzIcon} from '@/components/iconSvg'
import "./index.scss";
import Cookies from "js-cookie";
import {getSchool} from "../../../api/school";

@withRouter
class EffectivenessTop extends Component {
    state = {
        // todo 需要动态获取学校信息才对
        schoolInfo: this.props.inputData ||  JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
    };

    // componentDidMount() {
    //     getSchool(this.props.schoolEduCode).then(res => {
    //         console.log(res)
    //         console.log(res)
    //     });
    // }

    render() {
        const {schoolInfo} = this.state;
        return (
            <div className="Comment-EffectivenessTop">
                <div className="version-number">
                    <h2>
                        <i>版本号</i>
                        {/*是个啥??*/}
                        <span>GZX-A-41330126061-(2021)</span>
                    </h2>
                </div>
                <div className="version">
                    <div>
                        <ul className="list">
                            <li>
                                <div className="text">
                                    <SchoolIcon/>
                                    项目建设学校全称：{this.state.schoolInfo.schoolName}
                                </div>
                            </li>
                            <li>
                                <div className="text">
                                    <TbndzIcon/>
                                    填报年度：{schoolInfo && schoolInfo.writeYear ? schoolInfo.writeYear : new Date().getFullYear()}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

EffectivenessTop.defaultProps = {
    schoolEduCode: "",
    inputData:null
}

export default EffectivenessTop;
