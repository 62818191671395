// 项目情况-平台情况
import React from "react";
import {DatePicker, Form, Input, message, Radio, Select, Button, Cascader, Tooltip, Spin} from 'antd';
import "./index.scss";
import moment from "moment";
import {uploadFile} from '../../../../api/uploadFile';
import {Upicon, Upicondele, Upiconwc} from "../../../../components/iconSvg";

const {MonthPicker} = DatePicker;

function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}

class AddFzsxcl extends React.Component {
    state = {
        tableData: [],
        resourceBookDetail: this.props.resourceBookDetail,
        majorList: this.props.majorList,
    };

    componentDidMount() {
        let upFile = {
            originName: this.props.resourceBookDetail.resourceName,
            fileId: this.props.resourceBookDetail.fileId,
            fileSuffix: this.props.resourceBookDetail.resourceSuffix,
            fileSize: (this.props.resourceBookDetail.resourceSize / 1024).toFixed(2)
        }
        let bookMajor = []
        if (JSON.stringify(this.props.resourceBookDetail) !== "{}") {
            bookMajor.push(this.props.resourceBookDetail.majorOne)
            bookMajor.push(this.props.resourceBookDetail.majorTwo)
            bookMajor.push(this.props.resourceBookDetail.courseMajor)
        }
        this.setState({
            bookMajor: bookMajor,
            file: this.props.resourceBookDetail.fileId ? upFile : null,
        }, () => {
            if (JSON.stringify(this.props.resourceBookDetail) !== "{}") {
                this.props.form.setFieldsValue({
                    bookName: this.props.resourceBookDetail.bookName,
                    bookMajor: bookMajor,
                    bookType: this.props.resourceBookDetail.bookType,
                    bookPress: this.props.resourceBookDetail.bookPress,
                    bookYear: moment(this.props.resourceBookDetail.bookYear),
                    bookNumber: this.props.resourceBookDetail.bookNumber,
                    isVirtualSimulation: this.props.resourceBookDetail.isBase,
                    fileId: this.props.resourceBookDetail.fileId
                })
            }
        })
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {file, majorList} = this.state
        const disabledDay = current => {
            const nextMonth = moment(moment().add(1, 'months').format("YYYY-MM'"), "YYYY-MM'")
            return !!current ? current >= nextMonth : false
        }
        return (
            <div className="AddFzsxcl">
                <Form>
                    <Form.Item className="li_fora">
                        <span className={'label'}><span className='red'>*</span>教材类型</span>
                        {getFieldDecorator('bookType', {
                            initialValue: "",
                            rules: [
                                {
                                    required: true,
                                    message: '请选择教材类型!'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>活页式</Radio>
                            <Radio value={2}>VR实训手册</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item className="li_fora">
                        <span className={'label'}><span className='red'>*</span>课程名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('bookName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择课程名称`,
                                    },
                                ],
                            })(
                                <Input placeholder='请输入内容' maxLength={30} className="input_in"
                                       suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`bookName`) ? this.props.form.getFieldValue(`bookName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item className="li_fora">
                        <span className={'label'}><span className='red'>*</span>所属专业</span>
                        {getFieldDecorator('bookMajor', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择所属专业`,
                                },
                            ],
                        })(<Cascader
                            // defaultValue={['510101']}
                            options={majorList}
                        />)}
                    </Form.Item>
                    <Form.Item className="li_fora">
                        <span className={'label'}><span className='red'>*</span>出版社</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('bookPress', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写出版社`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请输入内容' maxLength={30} className="input_in"
                                       suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`bookPress`) ? this.props.form.getFieldValue(`bookPress`).length : 0}/30</span>}/>
                            )}

                        </div>
                    </Form.Item>
                    <Form.Item className="li_fora">
                        <span className={'label'}><span className='red'>*</span>出版年份</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('bookYear', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请选择出版年份`,
                                    },

                                ],
                            })(
                                <MonthPicker disabledDate={disabledDay} format={"YYYY-MM"} placeholder={"请选择出版年份"}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item className="li_fora">
                        <span className={'label'}><span className='red'>*</span>ISBN号</span>
                        {getFieldDecorator('bookNumber', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择ISBN号`,
                                },
                            ],
                        })(<Input placeholder='请输入内容' maxLength={30} className="input_in" suffix={<span className='len'>
                                                        {this.props.form.getFieldValue(`bookNumber`) ? this.props.form.getFieldValue(`bookNumber`).length : 0}/30</span>}/>)}
                    </Form.Item>

                    <Form.Item className="li_fora">
                        <span className={'label label1'}><span className='red'>*</span>是否为虚拟仿真</span>
                        {getFieldDecorator('isVirtualSimulation', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择是否为虚拟仿真!'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    {/* <Form.Item className="li_fora">
                        <span className={'label label1'}><span className='red'>*</span>是否依托基地开发</span>
                        {getFieldDecorator('isBase', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: '请选择课程类型!'
                                },
                            ],
                        })(<Radio.Group>
                            <Radio value={1}>是</Radio>
                            <Radio value={0}>否</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    */}
                    <Form.Item className="li_formup li_fora">
                        <span className={'label'}><span className='red'>*</span>佐证材料</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button">
                                <div className="span_right">
                                    <label htmlFor="file" className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                        onChange={this.multipartUpload}
                                        name="myfile"
                                        id="file"
                                        style={{display: "none"}}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {file ? <Tooltip placement="topLeft" title={file.originName}>
                                        <li className="title_de">{file.fileId ? <Upiconwc/> : <Spin/>}<span
                                            className="tie_span">{file.originName}</span><i className="Upicona"
                                                                                            onClick={this.deleteFile}><Upicondele/></i>
                                        </li>
                                    </Tooltip> : null}

                                </ul>
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

AddFzsxcl = Form.create()(AddFzsxcl);
export default AddFzsxcl;
