/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-09-02 11:32:34
 * @LastEditors: nliu
 * @LastEditTime: 2021-09-04 18:58:55
 */
// import 'babel-polyfill'
import React from "react";
import RouterView from "../../router/router_view";
import { HashRouter, Redirect } from "react-router-dom";
import Login from "@/page/login";
export default (props) => {
    console.log(props.routers)
  return <div>
      
      <RouterView routers={props.routers}></RouterView>
  </div>
    
};
