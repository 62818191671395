/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Card, Row, Col, Button, Form, Input, Select, DatePicker, Modal, Radio, InputNumber, Table, message} from 'antd';
// import { saveUpdateSchool,getSchoolProfile } from "@/api/doublehigh/project-school.js/";
import './index.scss';
import Header from '../../components/header'
import Footer from '../../components/footer'
import StepTop from '../components/StepTop';
import {ScoolIcon1, ScoolIcon2, TianJia} from "../../components/iconSvg";
import moment from "moment";
import {chinaAllProvinceArr, chinaAllProvinceCityArr} from '../../config/common';
import {
    getSchoolInfos,
    updateSchoolInfo
} from '../../api/school'
import {selectRegion} from '../../api/sharing'
import Cookies from "js-cookie";

import {updateProcessStatus} from "../../api/effect";
import {getCanEdit, getTargetId} from "../../until/cookie";
import Project_School_Modal_Component from "./components";

const monthFormat = 'YYYY-MM';
const {TextArea} = Input;
const {Option} = Select;
const {MonthPicker} = DatePicker;
const provinceData = chinaAllProvinceArr;
const cityData = chinaAllProvinceCityArr

function disabledDate(current) {
    return current && current > moment().endOf('day');
}

class Project_School extends React.Component {
    state = {
        // 进度
        processCode: '2.1',

        cities: [],
        secondCity: '',
        textValue: '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        userInfo: JSON.parse(Cookies.get('userInfo')) ? JSON.parse(Cookies.get('userInfo')) : "",
        schoolId: '',
        totalGoalTextLength: '',
        createSchoolTime: '',
        province: '',
        provinceCode: "",
        addressProvince: '',
        schoolNatureDic: [
            {label: '公办', value: 1},
            {label: '民办', value: 2}
        ],
        isChange: true,
        userId: '',
        secondCode: '',
        confirmLoading: false,
        visible: false,
        category: '',
        isMessage: '',
        sysType: '',
        schoolDetailVo: {},
        legalPersonContactVo: {},
        projectContactVo: [],
        organizerType: 1,
        regionList: [],
        pageSize: 0,
        pageNum: 1,
        // 联系人列表
        linkManData: {},
        currentEditLinkManId: undefined,
        isHaveLinkManData: false
    };

    componentDidMount() {
        this.getSchoolInfos()
    }

    // section 获取页面信息
    getSchoolInfos = () => {
        let data = {
            schoolEduCode: this.state.schoolInfo.schoolEduCode,
            taskId: this.state.schoolInfo.taskId
        }
        getSchoolInfos(data).then(res => {
            console.log('getSchoolInfos', res)
            if (res.schoolDetailVo) {
                this.setState({
                    schoolDetailVo: res.schoolDetailVo, // 学校基础信息
                    legalPersonContactVo: res.legalPersonContactVo || {}, // 法人联系人信息
                    createSchoolTime: res.schoolDetailVo.createSchoolTime,
                    provinceCode: res.schoolDetailVo.provinceCode,
                    cityCode: res.schoolDetailVo.cityCode,
                    linkManData: res.pageData, // 联系人列表
                    isHaveLinkManData: (res.pageData?.data ? res.pageData.data : []).length > 0
                }, () => {
                    this.selectRegion()
                })
                this.props.form.setFieldsValue({
                    // 学校基本
                    address: res.schoolDetailVo.address,
                    organizerType: res.schoolDetailVo.organizerType,
                    postCode: res.schoolDetailVo.postCode,
                    schoolName: res.schoolDetailVo.schoolName,
                    schoolNature: res.schoolDetailVo.schoolNature,
                    schoolWebsite: res.schoolDetailVo.schoolWebsite,

                    // 法人
                    leEmail: res.legalPersonContactVo ? res.legalPersonContactVo.email : "",
                    lePhone: res.legalPersonContactVo ? res.legalPersonContactVo.phone : "",
                    leUserFax: res.legalPersonContactVo ? res.legalPersonContactVo.userFax : "",
                    leUserName: res.legalPersonContactVo ? res.legalPersonContactVo.userName : "",
                    leUserPost: res.legalPersonContactVo ? res.legalPersonContactVo.userPost : "",
                    leWorkPhone: res.legalPersonContactVo ? res.legalPersonContactVo.workPhone : "",
                })
            }
        })
    }

    previousStep = () => {
        this.props.history.push({
            pathname: '/construction_plan',
        })
    }
    saveUpdateSchool = () => {
        const {isHaveLinkManData} = this.state

        if (!isHaveLinkManData) {
            message.error('请填写项目联系人')
            return
        }


        this.props.form.validateFields((err, value) => {
            console.log('err ', err)
            if (!err) {
                console.log('this.state.schoolDetailVo.schoolId', this.state.schoolDetailVo.id)
                if (this.state.schoolDetailVo.id) {
                    let updateSchoolQo = {

                        // 学校基本详情
                        schoolInfoQo: {
                            id: this.state.schoolDetailVo.id,
                            address: value.address,
                            city: value.city,
                            cityCode: this.state.cityCode,
                            createSchoolTime: moment(value.createSchoolTime).format('YYYY-MM-DD HH:MM:ss'),
                            createUserId: this.state.userInfo.id,
                            organizerType: this.state.organizerType,
                            postCode: value.postCode,
                            province: value.province,
                            provinceCode: value.provinceCode,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            schoolName: value.schoolName,
                            schoolNature: value.schoolNature,
                            schoolWebsite: value.schoolWebsite,
                            taskId: this.state.schoolInfo.taskId,
                        },

                        // 法人信息
                        legalPersonContactQo: {
                            id: this.state.legalPersonContactVo.id,
                            createUserId: this.state.userInfo.id,
                            email: value.leEmail,
                            phone: value.lePhone,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            taskId: this.state.schoolInfo.taskId,
                            userFax: value.leUserFax,
                            userName: value.leUserName,
                            userPost: value.leUserPost,
                            workPhone: value.leWorkPhone
                        },
                    }
                    console.log('下一步接口传参===>>>>', updateSchoolQo)
                    updateSchoolInfo(updateSchoolQo).then(res => {
                        if (res) {

                            // 更新进度
                            let progressData = {
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                taskId: this.state.schoolInfo.taskId,
                                processCode: this.state.processCode
                            }

                            updateProcessStatus(progressData).then(res => {
                                this.props.history.push({
                                    pathname: '/status_project',
                                })
                            })
                        }
                    })
                }
            } else {
                console.log('======else', this.state.schoolDetailVo.id)
            }
        })

    }
    handleReset = () => {
        this.props.form.resetFields();
    }
    //验证空格
    handleValueFromEvent = (event) => {
        let value = event.target ? event.target.value : event
        if (this.state.sysType === 1) {
            return value.replace(/\s+/g, '')
        } else {
            return value
        }
    }
    selectRegion = () => {
        selectRegion().then(res => {
            this.setState({
                regionList: res
            }, () => {
                let {regionList} = this.state
                let cities = regionList.filter(e => e.code === this.state.provinceCode);
                if (!cities || cities.length === 0) {
                    return;
                }
                this.setState({
                    cities: cities[0].regionTwoVoList
                })
            })
        })
    }
    handleProvinceChange = value => {
        let {regionList} = this.state;
        let cities = regionList.filter(e => e.code === value);
        let city = []
        if (cities.length > 0) {
            city = cities[0].regionTwoVoList
        }
        this.setState({
            cities: city,
            cityCode: '',
            provinceCode: value
        }, () => {
            this.props.form.setFieldsValue({"cityCode": ''})
            this.props.form.setFieldsValue({"provinceCode": value})
        });
    };
    onSecondCityChange = (value, key) => {
        console.log('111', key)
        this.setState({
            cityCode: value
        }, () => {
            this.props.form.setFieldsValue({"cityCode": value})
        });

    };

    organizerType = value => {
        this.setState({
            organizerType: value
        })
    }


    // section Action
    haveLinkManDataCallback = (isHaveLinkManData) => {
        this.setState({isHaveLinkManData: isHaveLinkManData})
    }


    // section render
    render() {
        const canEdit = getCanEdit()

        const {
            visible, confirmLoading, messageInfo, cities, createSchoolTime, cityCode, provinceCode,
            schoolInfo, organizerType, regionList
        } = this.state;


        const {getFieldDecorator} = this.props.form;

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 4},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 20},
            },
        };
        console.log('organizerType渲染的：：：', organizerType)

        return (
            <div className="ProjectSchool main-bg">
                {/*顶部star*/}
                <Header/>
                <div className="Task-top">
                    <div className="main-cont main_cont">
                        <Card>
                            <Row>
                                <Col span={24} className="col-li">
                                    <StepTop setCode={this.setCode} parentCode={"2"}/>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
                <div className="ProjectSchool-bottom">
                    <div className="main-cont bottom-warp main_cont">
                        <Row className="row">
                            <Form {...formItemLayout} >
                                <Col span={24}>
                                    <div className="title-h3">
                                    </div>
                                    <Card className="card-main clearfix">
                                        {/*<div className="title-h3 title-h3_lcl">*/}
                                        {/*    <div className="title-top">*/}
                                        {/*        <h4 className='title_lcl_change'>项目学校建设任务书</h4>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                        <Row>
                                            <Col span={24} className="col-list clearfix">
                                                <h3 className='middle-title'>2-1-1 学校基本情况</h3>

                                                <h4 className="title-b"><ScoolIcon1/>1. 申报学校情况</h4>
                                                <div className="form-list">
                                                    <ul>
                                                        <div className="clearfix">
                                                            <li id='schoolName'>
                                                                <Form.Item className="Item-li" label={`申报学校`}>
                                                                    {getFieldDecorator('schoolName', {
                                                                        initialValue: schoolInfo ? schoolInfo.schoolName : '',
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写学校名称`,
                                                                            },
                                                                            {
                                                                                max: 100,
                                                                                message: "文本过长，最多100字"
                                                                            },
                                                                            {
                                                                                validator: '申报学校'
                                                                            },
                                                                        ],
                                                                    })(<Input disabled={true} className="input"
                                                                              maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='secondCity' className="li-position">
                                                                <Form.Item label={`所在地区`} className="Item-li select-li"
                                                                           hasFeedback validateStatus="error">
                                                                    {getFieldDecorator('provinceCode', {
                                                                        initialValue: this.state.provinceCode,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请选择所在地区`,
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <div className="inputa">
                                                                            <Select className="select-li"
                                                                                    dropdownClassName='Date-main-select'
                                                                                    value={provinceCode}
                                                                                    onChange={this.handleProvinceChange}
                                                                                    placeholder={"请选择省份"}
                                                                            >
                                                                                <Option key={-1}
                                                                                        value="请选择省份">请选择省份</Option>
                                                                                {regionList.map(item => (
                                                                                    <Option key={item.code}
                                                                                            value={item.code}>{item.name}</Option>
                                                                                ))}
                                                                            </Select>
                                                                        </div>)}
                                                                </Form.Item>
                                                                <Form.Item className="Item-li select-right" hasFeedback
                                                                           validateStatus="error">
                                                                    {getFieldDecorator('cityCode', {
                                                                        initialValue: this.state.cityCode,
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请选择市`,
                                                                            },
                                                                        ],
                                                                    })( // className="inputa"
                                                                        <Select className="select-li"
                                                                                dropdownClassName='Date-main-select'
                                                                                value={cityCode}
                                                                                onChange={this.onSecondCityChange}
                                                                        >
                                                                            <Option disabled key={-1}
                                                                                    value="">请选择市</Option>
                                                                            {cities.map(item => (
                                                                                <Option key={item.code}
                                                                                        value={item.code}>{item.name}</Option>
                                                                            ))}
                                                                        </Select>
                                                                    )}
                                                                </Form.Item>
                                                            </li>
                                                        </div>
                                                        <div className="clearfix">
                                                            <li id='fillingDate'>
                                                                <Form.Item className="Item-li" label={`建校时间`}>
                                                                    {getFieldDecorator('createSchoolTime', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请选择建校时间`,
                                                                            },
                                                                        ],
                                                                        initialValue: createSchoolTime ? moment(createSchoolTime, 'YYYY-MM') : null
                                                                    })(<MonthPicker disabledDate={disabledDate}
                                                                                    className="input"
                                                                                    format={monthFormat}
                                                                                    dropdownClassName='Date-main'/>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='schoolNature'>
                                                                <Form.Item label={`院校性质`}
                                                                           className="Item-li select-li select-raido"
                                                                           style={{marginTop: '-6px'}}
                                                                           hasFeedback validateStatus="error">
                                                                    {getFieldDecorator('schoolNature', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请选择院校性质`,
                                                                            },
                                                                        ],
                                                                        initialValue: 1
                                                                    })(
                                                                        // <div style={{marginTop: '-6px'}}>

                                                                        <Radio.Group>
                                                                            <Radio value={1}>公办</Radio>
                                                                            <Radio value={2}>民办</Radio>
                                                                            <Radio value={3}>合作办学</Radio>
                                                                        </Radio.Group>
                                                                        // </div>
                                                                    )}
                                                                </Form.Item>
                                                            </li>
                                                        </div>
                                                        <div className="clearfix">
                                                            <li id='organizerType'>
                                                                <Form.Item label={`培育单位类型`}
                                                                           className="Item-li select-li" hasFeedback
                                                                           validateStatus="error">
                                                                    {getFieldDecorator('organizerType', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写培育单位类型`,
                                                                            },
                                                                        ],
                                                                    })(
                                                                        <Select value={organizerType}
                                                                                onChange={this.organizerType}>
                                                                            <Option value={1}>高等职业学校</Option>
                                                                            <Option value={2}>中等职业学校</Option>
                                                                            <Option value={3}>本科层次职业学校</Option>
                                                                        </Select>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='postCode'>
                                                                <Form.Item className="Item-li" label={`邮政编码`}>
                                                                    {getFieldDecorator('postCode', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写邮编`,
                                                                            },
                                                                            // {
                                                                            //     validator:this.validatePostCode
                                                                            // }
                                                                        ],
                                                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                                    })(<Input className="input" maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='schoolWebsite'>
                                                                <Form.Item className="Item-li" label={`学校网址`}>
                                                                    {getFieldDecorator('schoolWebsite', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请输入学校网址`,
                                                                            },
                                                                            // {
                                                                            //     validator:this.validatehttp
                                                                            // }
                                                                        ],
                                                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                                    })(<Input placeholder="请输入http://或https://开头的学校网址"
                                                                              className="input" maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>
                                                        </div>
                                                        <div className="clearfix">
                                                            <li className={'address'}>
                                                                <Form.Item className="Item-li" label={`通讯地址`}>
                                                                    {getFieldDecorator('address', {
                                                                        initialValue: '',
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写通讯地址`,
                                                                            },
                                                                            // {
                                                                            //     max: 100,
                                                                            //     message: "文本过长，最多100字"
                                                                            // },
                                                                            // {
                                                                            //     validator: this.state.sysType==2&&this.validateServiceName
                                                                            // },
                                                                        ],
                                                                    })(<Input placeholder="请填写通讯地址详细信息"
                                                                              className="input" maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>

                                                        </div>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col span={24} className="col-list clearfix">
                                                <div className="form-list">
                                                    {/*<h4 className="title-b"><IconFont type="icon-iconfontabout" className="icona"/>法人代表信息</h4>*/}
                                                    <h4 className="title-b"><ScoolIcon1/>2. 法人代表信息</h4>
                                                    <ul>
                                                        <div className="clearfix">
                                                            <li id='userName'>
                                                                <Form.Item className="Item-li" label={`姓名`}>
                                                                    {getFieldDecorator('leUserName', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写法人姓名`,
                                                                            }
                                                                        ],
                                                                        getValueFromEvent: (e) => {
                                                                            return this.handleValueFromEvent(e)
                                                                        }
                                                                    })(<Input maxLength={100} className="input"
                                                                              placeholder={'请填写法人姓名'}/>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='userJob'>
                                                                <Form.Item className="Item-li" label={`职务`}>
                                                                    {getFieldDecorator('leUserPost', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写职务`,
                                                                            },
                                                                            {
                                                                                max: 100,
                                                                                message: "文本过长，最多100字"
                                                                            },
                                                                            {
                                                                                validator: this.state.sysType == 2 && this.validateServiceName
                                                                            },
                                                                        ],
                                                                        getValueFromEvent: (e) => {
                                                                            return this.handleValueFromEvent(e)
                                                                        }
                                                                    })(<Input maxLength={100} className="input"/>)}
                                                                </Form.Item>
                                                            </li>
                                                        </div>
                                                        <div className="clearfix">
                                                            <li id='workPhone'>
                                                                <Form.Item className="Item-li" label={`办公电话`}>
                                                                    {getFieldDecorator('leWorkPhone', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写座机电话`,
                                                                            },
                                                                            // {
                                                                            //     validator:this.validateInvestMoney
                                                                            // }
                                                                        ],
                                                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                                    })(<Input className="input" maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='userFax'>
                                                                <Form.Item className="Item-li" label={`传真`}>
                                                                    {getFieldDecorator('leUserFax', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写传真`,
                                                                            },
                                                                            // {
                                                                            //     validator:this.validateInvestfax
                                                                            // }
                                                                        ],
                                                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                                    })(<Input className="input" maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>
                                                        </div>
                                                        <div className="clearfix">
                                                            <li id='phone'>
                                                                <Form.Item className="Item-li" label={`手机`}>
                                                                    {getFieldDecorator('lePhone', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写手机号码`,
                                                                            },
                                                                            // {
                                                                            //     validator:this.validatePhone
                                                                            // }
                                                                            {
                                                                                pattern: /^1[0-9]{10}/,
                                                                                message: '请输入正确的手机号'
                                                                            },
                                                                        ],
                                                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                                    })(<Input className="input"
                                                                              maxLength={11}
                                                                              type={'tel'}/>)}
                                                                </Form.Item>
                                                            </li>
                                                            <li id='email'>
                                                                <Form.Item className="Item-li" label={`电子邮箱`}>
                                                                    {getFieldDecorator('leEmail', {
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写电子邮箱`,
                                                                            },
                                                                            // {
                                                                            //     validator:this.validateEmail
                                                                            // }
                                                                        ],
                                                                        getValueFromEvent: event => event.target.value.replace(/\s+/g, '')
                                                                    })(<Input className="input" maxLength={100}/>)}
                                                                </Form.Item>
                                                            </li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </Col>
                                            <Col span={24} className="col-list-table clearfix">
                                                <div className="form-list">
                                                    <Project_School_Modal_Component
                                                        isCanEdit={true}
                                                        haveLinkManDataCallback={this.haveLinkManDataCallback}
                                                    />
                                                </div>
                                            </Col>


                                        </Row>
                                    </Card>
                                </Col>
                            </Form>
                        </Row>
                        <div className="bottom-but">
                            <Button className="but-li" onClick={() => this.previousStep()}>
                                上一步
                            </Button>
                            <Button className="but-li" type="primary" onClick={() => this.saveUpdateSchool()}>
                                保存并进入下一步
                            </Button>
                            <Button className="but-li" onClick={this.handleReset}>重置</Button>
                        </div>
                        <Modal
                            wrapClassName='Modal-mainpic-html'
                            className='Modal-but'
                            title={
                                <div>
                                    温馨提示
                                </div>
                            }
                            centered={true}
                            visible={visible}
                            onOk={this.handleOk}
                            okText="确定"
                            confirmLoading={confirmLoading}
                            onCancel={this.handleCancel}
                            closable={false}
                        >
                            <div className="text-conta">
                                <div dangerouslySetInnerHTML={{__html: messageInfo}}/>
                            </div>
                        </Modal>
                    </div>
                </div>

                {/*顶部star*/}
                <Footer/>
            </div>
        );
    }
}

Project_School = Form.create()(Project_School);
export default Project_School;
