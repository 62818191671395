import { post } from '../../until/axios_instans';
// 新增/修改经费支出
export function addAndUpdateExpenditureRequest(data) {
    return post(window.apiShixun+'/api/v1/invest/saveOutBudget', data);
}

// 查询全部经费支出
export function getExpenditureList(data) {
    return post(window.apiShixun+'/api/v1/invest/selectOutBudgets', data);
}

// 删除建设任务子项
export function deleteExpenditure(data) {
    return post(window.apiShixun+'/api/v1/invest/deleteOutBudget', data);
}






