import React from "react";
import {
    Form,
    Row,
    Col,
    Radio,
    Table,
    Button,
    Card,
    Input,
    Select,
    Modal,
    Upload,
    message,
    Drawer,
    InputNumber
} from 'antd';
import {useParams, withRouter} from "react-router-dom";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import AddTeacherMaterial from "../../components/addTeacherMaterial";
import AddTeaStuMaterial from "../../components/addTeaStuMaterial";
import AddTeacherLeader from "../../components/addTeacherLeader";
import AddTeacherProject from '../../components/addTeacherProject'
import UploaderComponent from "../../components/uploader_component";
import {
    addPartakeTrain,
    updatePartakeTrain,
    getPartakeTrain,
    deletePartakeTrain,
    teacherList,
    addPartakeCourse,
    getPartakeCourse,
    updatePartakeCourse,
    deletePartakeCourse,
    addPartakeLeader,
    updatePartakeLeader,
    getPartakeLeader,
    deletePartakeLeader,
    importPartakeCourse,
    importPartakeTrain,
    importPartakeLeader,
    getPartakeProject,
    editPartakeProject,
    deletePartakeProject
} from "../../api/teacherDevelopment"
import {downTemplate, getSharingMajorList} from "../../api/sharing"
import {updateFactor, updateSchoolTeacherTotalNum} from '../../api/factor';
import "./index.scss";
import axios from 'axios';
import md5 from "js-md5";
import {
    TeacherDevelopmentUpload,
    TeacherDevelopmentAddPeop,
    Daoru, ExcelImport
} from "../../components/iconSvg";
import EffTop from "../components/EffectivenessTop";
import LeftEfficiencyside from "../components/Left_Efficiencyside";
import Cookies from "js-cookie";
import moment from "moment";
import LookCheck from "../components/look_Check";
import {getCanEdit, getRoleType, getTargetId, RoleType} from "../../until/cookie";
import TableCmp from "./components/TableCmp";
import ModalCmp from "./components/ModalCmp";
import FileLook from "../components/file_look";
import ImportComponent from "../../components/importComponent/ImportComponent";
import {BaseUrl} from "../../config/common";

import ModalFrame from './components/MedalItemCmp'
import {validData} from "../../api/target";

export default Form.create()(
    withRouter(
        class Teacher_Development extends React.Component {
            state = {
                addTeacherMaterialModal: false,//佐证材料Modal
                addTeaStuMaterialModal: false,//佐证材料2Modal
                addTeacherLeaderModal: false,//带头人Modal
                value: 1,
                dataList: [],
                pageNum: 1,//页数
                pageSize: 10,//条数
                total: 0,
                pageNumone: 1,//页数
                pageSizeone: 10,//条数
                totalone: 0,
                pageNumtwo: 1,//页数
                pageSizetwo: 10,//条数
                totaltwo: 0,
                columnsADataSource: [],
                columnsBDataSource: [],
                columnsCDataSource: [],
                columnsAData: [],
                columnsBData: [],
                columnsCData: [],
                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                // twoLevelTargetId: Cookies.get('twoLevelTargetId') ? Cookies.get('twoLevelTargetId') : "",
                twoLevelTargetId: getTargetId("teacher_Development"),
                partakeTrainId: '',
                partakeCourseId: '',
                partakeLeaderId: '',
                train: null,
                course: null,
                leader: null,
                factorContent: 0,
                teacherProjectVO: null,
                teacherProjectVisible: false,
                teacherProjectRecord: null,
                featureSelfDataVo: {
                    "id": "",
                    "resourceVos": [],
                    "selfData": "",
                    "selfId": "",
                    "targetMark": "",
                    "targetName": ""
                },
                medalData: {
                    factorContent: null,
                    factorId: '1',
                    factorName: '数量',
                    // id: "e26c696eb1904d7e87b83a0bf974b41f",
                    listVO: {
                        pageNum: 1,
                        pageSize: 10,
                        startRow: 1,
                        endRow: 10,
                        total: 11,
                        cmTotal: 0,
                        pages: 2,
                        data: []
                    },
                    targetMark: "1.4.5",
                    targetName: "教师参加省级及以上虚拟仿真类大赛获奖",
                    year: 2021
                }
            };


            componentDidMount() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
                this.teacherList()
                this.getSharingMajor()
            }

            componentWillUnmount() {
                clearInterval(this.timer);
            }

            // 查看资料
            handLook = (record) => {
                const url = BaseUrl + record.fileId
                this.props.history.push({pathname: "/file_Book", query: {openUrl: url}})
            }

            /**
             *  隐藏预览的回调
             */
            dismiss = () => {
                this.setState({
                    isShow: false
                })
            }

            onCancel = () => {
                this.setState({
                    examine: false
                })
            }

            handExamine = () => {
                this.setState({
                    examine: true
                })
            }


            teacherList = () => {
                let data = {
                    id: "",
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.twoLevelTargetId
                }
                teacherList(data).then(res => {
                    console.log('res', res)
                    if (res) {
                        let columnsBBaiFenBi = res.teacherCourseVo.courseResourceNum / res.teacherCourseVo.partakeCourseVoList.total
                        res = res || {}
                        this.setState({
                            columnsAData: res.teacherTrainVo,
                            teacherNum: res.teacherTrainVo.teacherNum,
                            factorContent: res.teacherTrainVo.teacherTotalNum,
                            columnsBData: res.teacherCourseVo,
                            columnsCData: res.teacherLeaderVo,
                            columnsBBaiFenBi: columnsBBaiFenBi,
                            teacherProjectVO: res.teacherProjectVo || {},
                            medalData: {
                                ...this.state.medalData,
                                id: res.teacherContestVo?.id,
                                listVO: res.teacherContestVo?.targetVsContestVoList || {}
                            }
                        })
                        if (res.teacherTrainVo) {
                            this.setState({
                                columnsADataSource: res.teacherTrainVo.partakeTrainVoList.data,
                                columnsATotal: res.teacherTrainVo.partakeTrainVoList.total,
                            })
                        }
                        if (res.teacherCourseVo) {
                            this.setState({
                                columnsBDataSource: res.teacherCourseVo.partakeCourseVoList.data,
                                columnsBTotal: res.teacherCourseVo.partakeCourseVoList.total,
                            })
                        }
                        if (res.teacherLeaderVo) {
                            this.setState({
                                columnsCDataSource: res.teacherLeaderVo.partakeLeaderVoList.data,
                                columnsCTotal: res.teacherLeaderVo.partakeLeaderVoList.total,
                            })
                        }
                    }

                })
            }

            updatePartakeTrain = (record) => {
                this.setState({
                    addTeacherMaterialModal: true,
                    partakeTrainId: record.id,
                    train: record
                })
            }

            updatePartakeCourse = (record) => {
                console.log('record  方法进来：：：', record)
                this.setState({
                    addTeaStuMaterialModal: true,
                    partakeCourseId: record.id,
                    course: record
                })
            }

            updatePartakeLeader = (record, type) => {
                if (type == 'project') {
                    this.setState({
                        teacherProjectVisible: true,
                        teacherProjectRecord: record
                    })
                    return false
                }
                this.setState({
                    addTeacherLeaderModal: true,
                    partakeLeaderId: record.id,
                    leader: record
                })
            }


            getTeacherProject = (type, page, size) => {
                let data = {
                    id: "",
                    pageNum: page || this.state.teacherProjectVO.partakeProjectVoList?.pageNum || 1,
                    pageSize: size || this.state.teacherProjectVO.partakeProjectVoList?.pageSize || 10,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.teacherProjectVO.id
                }
                getPartakeProject(data).then(res => {
                    console.log('res', res)
                    if (res) {
                        let r = {...this.state.teacherProjectVO, ...res};
                        this.setState({
                            teacherProjectVO: r
                        })

                    }

                })
            }

            deletePartakeTrain = async (record) => {
                console.log('111')
                let data = {
                    id: record.id,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsAData.id
                }
                deletePartakeTrain(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getPartakeTrain(1)
                    }
                })
            }

            deletePartakeCourse = async (record) => {
                let data = {
                    id: record.id,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsBData.id
                }
                deletePartakeCourse(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getPartakeCourse(1)
                    }
                })
            }


            deletePartakeLeader = async (record) => {
                console.log('333')
                let data = {
                    id: record.id,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsCData.id
                }
                deletePartakeLeader(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getPartakeLeader(1)
                    }
                })
            }


            deletePartakeProject = async (record) => {
                console.log('333')
                let data = {
                    id: record.id,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.teacherProjectVO.id
                }
                deletePartakeProject(data).then(res => {
                    if (res) {
                        message.success("删除成功")
                        this.getTeacherProject('', 1)
                    }
                })
            }


            // selectSubTask = (upseniorId) => {
            //     let data = {
            //         taskId: this.state.taskId,
            //         schoolEduCode: this.state.schoolEduCode,
            //         seniorId: upseniorId
            //     }
            //     selectInvest(data).then((res) => {
            //         let ifComplete = false
            //         if (res && res.length > 0) {
            //             ifComplete = true
            //         }
            //         this.setState({
            //             dataList: res ? res : [],
            //             ifComplete
            //         });
            //     });
            // }
            qiehuancd = (e) => {
                console.log("res3333", e)
                this.setState({rightDq: e})
            }
            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }


            onChange = e => {
                console.log('radio checked', e.target.value);
                this.setState({
                    value: e.target.value,
                });
            };


            addTeacherMaterial = (type) => {
                if (type === 'project') {
                    this.setState({
                        teacherProjectVisible: true,
                        teacherProjectRecord: null,
                        partakeTrainId: ''
                    })
                    return false;
                }
                this.setState({
                    addTeacherMaterialModal: true,
                    partakeTrainId: '',
                    train: null
                })
            }
            addTeaStuMaterial = () => {
                this.setState({
                    addTeaStuMaterialModal: true,
                    partakeCourseId: '',
                    course: null
                })
            }

            addTeacherLeader = () => {
                this.setState({
                    addTeacherLeaderModal: true,
                    partakeLeaderId: '',
                    leader: null
                })
            }

            handleCancel = (type) => {
                if (type === 'project') {
                    this.setState({
                        teacherProjectVisible: false,
                        teacherProjectRecord: null
                    })
                    return false;
                }

                this.setState({
                    addTeacherMaterialModal: false,
                    addTeaStuMaterialModal: false,
                    addTeacherLeaderModal: false,
                })
            }

            // 上一步
            previous = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }


                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "platform_Schoolresources") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/platform_Schoolresources',
                            })
                            break;
                        }
                    }
                }
            }
            // 下一步
            next = async () => {
                const lastTargetId = Cookies.get("twoLevelTargetId")
                if (lastTargetId) {
                    validData(lastTargetId);
                }


                let targetList = this.LeftEfficiencyside.state.targetList
                let targetId = ''
                for (let i = 0; i < targetList.length; i++) {
                    for (let j = 0; j < targetList[i].targetDetails.length; j++) {
                        if (targetList[i].targetDetails[j].path === "construction_Effective") {
                            targetId = targetList[i].targetDetails[j].id
                            Cookies.set('twoLevelTargetId', targetId);
                            this.props.history.push({
                                pathname: '/construction_Effective',
                            })
                            break;
                        }
                    }
                }
            }
            //section 1.4.1
            //分页切换
            pageChange = (pageNum) => {
                this.setState({pageNum}, () => {
                    this.getPartakeTrain()
                });
            }
            //分页条数切换
            onShowSizeChange = (pageNum, pageSize) => {
                this.setState({pageNum: 1, pageSize}, () => {
                    this.getPartakeTrain()
                });
            }

            getPartakeTrain = (pageNumFirst) => {
                const {pageNum, pageSize} = this.state
                let data = {
                    pageNum: pageNumFirst || pageNum,
                    pageSize: pageSize,
                    id: "",
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsAData.id
                }
                if (pageNumFirst) {
                    this.setState({
                        pageNum: pageNumFirst
                    })
                }
                getPartakeTrain(data).then(res => {
                    console.log('res', res)
                    if (res) {
                        this.setState({
                            columnsADataSource: res.partakeTrainVoPageData.data,
                            columnsATotal: res.partakeTrainVoPageData.total,
                            teacherNum: res?.teacherNum,
                            factorContent: res?.teacherTotalNum,
                        })
                    }

                })
            }

            //section 1.4.2
            //第二个表格分页切换
            pageChangeone = (pageNum) => {
                this.setState({pageNumone: pageNum}, () => {
                    this.getPartakeCourse()
                });
            }
            //第二个表格分页条数切换
            onShowSizeChangeone = (pageNum, pageSize) => {
                this.setState({pageSizeone: pageSize, pageNumone: 1}, () => {
                    this.getPartakeCourse()
                });
            }


            getPartakeCourse = (pageNumoneFirst) => {
                const {pageNumone, pageSizeone, columnsBData} = this.state
                let data = {
                    id: "",
                    pageNum: pageNumoneFirst || pageNumone,
                    pageSize: pageSizeone,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsBData.id
                }
                if (pageNumoneFirst) {
                    this.setState({
                        pageNumone: pageNumoneFirst
                    })
                }
                getPartakeCourse(data).then(res => {
                    if (res) {
                        this.setState({
                            columnsBDataSource: res.data,
                            columnsBTotal: res.total,
                        }, () => {
                            if (res.total >= columnsBData?.teacherTotalNum) {
                                this.setState({
                                    columnsBData: {...columnsBData, teacherTotalNum: Number(res.total)}
                                })
                                this.updateColumnsBData(res.total);
                            }
                        })

                    }

                })
            }
            //section 1.4.3
            //第三个表格分页切换
            pageChangetwo = (pageNum) => {
                this.setState({pageNumtwo: pageNum}, () => {
                    this.getPartakeLeader()
                });
            }
            //第三个表格分页条数切换
            onShowSizeChangetwo = (pageNum, pageSize) => {
                this.setState({pageNumtwo: 1, pageSizetwo: pageSize}, () => {
                    this.getPartakeLeader()
                });
            }

            //section 1.4.4
            changePage = (page, size, type) => {
                this.getTeacherProject('', page, size)
            }

            onShowSizeChange1_4_4 = (pageNum, pageSize) => {
                this.setState({pageNumtwo: 1, pageSizetwo: pageSize}, () => {
                    this.getTeacherProject('', 1, pageSize)
                });
            }

            getPartakeLeader = (pageNumtwoFirst) => {
                const {pageSizetwo, pageNumtwo} = this.state
                let data = {
                    pageNum: pageNumtwoFirst || pageNumtwo,
                    pageSize: pageSizetwo,
                    id: "",
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                    targetId: this.state.columnsCData.id
                }
                if (pageNumtwoFirst) {
                    this.setState({
                        pageNumtwo: pageNumtwoFirst
                    })
                }
                getPartakeLeader(data).then(res => {
                    console.log('res', res)
                    if (res) {
                        this.setState({
                            columnsCDataSource: res.data,
                            columnsCTotal: res.total
                        })
                    }

                })
            }
            columnsATiJiao = () => {
                let file = this.AddTeacherMaterial.getState()
                if (this.state.partakeTrainId) {
                    this.AddTeacherMaterial.props.form.validateFields((err, value) => {
                        if (err) return;
                        let param = {
                            evidenceQo: {
                                fileId: file?.fileId,
                                resourceId: file?.resourceId,
                                resourceName: file?.originName,
                                resourceSize: file?.fileSize,
                                resourceSuffix: file?.fileSuffix
                            },
                            id: this.state.partakeTrainId,
                            partakeNumber: value.partakeNumber,
                            isVirtualSimulation: value.isVirtualSimulation,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            targetId: this.state.columnsAData.id,
                            trainAddress: value.trainAddress,
                            trainName: value.trainName,
                            trainTime: value.trainTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                        }
                        updatePartakeTrain(param).then(res => {
                            if (res) {
                                message.success("修改成功")
                                this.setState({
                                    addTeacherMaterialModal: false,
                                    file: null,
                                    train: ''
                                }, () => {
                                    this.AddTeacherMaterial.props.form.resetFields();
                                    this.getPartakeTrain()
                                })
                            }

                        })
                    })
                } else {
                    this.AddTeacherMaterial.props.form.validateFields((err, value) => {
                        if (!err) {
                            let param = {
                                evidenceQo: {
                                    fileId: file?.fileId,
                                    resourceId: file?.resourceId,
                                    resourceName: file?.originName,
                                    resourceSize: file?.fileSize,
                                    resourceSuffix: file?.fileSuffix
                                },
                                partakeNumber: value.partakeNumber,
                                isVirtualSimulation: value.isVirtualSimulation,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsAData.id,
                                trainAddress: value.trainAddress,
                                trainName: value.trainName,
                                trainTime: value.trainTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                            }
                            addPartakeTrain(param).then(res => {
                                if (res) {
                                    message.success("添加成功")
                                    this.setState({
                                        addTeacherMaterialModal: false,
                                        file: null
                                    }, () => {
                                        this.AddTeacherMaterial.props.form.resetFields();
                                        this.getPartakeTrain(1)
                                    })
                                }
                            })
                        }
                    })
                }
            }

            columnsATiJiaoAndAdd = () => {
                let file = this.AddTeacherMaterial.getState()
                if (this.state.partakeTrainId) {
                    this.AddTeacherMaterial.props.form.validateFields((err, value) => {
                        if (err) return;
                        let param = {
                            evidenceQo: {
                                fileId: file?.fileId,
                                resourceId: file?.resourceId,
                                resourceName: file?.originName,
                                resourceSize: file?.fileSize,
                                resourceSuffix: file?.fileSuffix
                            },
                            id: this.state.partakeTrainId,
                            partakeNumber: value.partakeNumber,
                            isVirtualSimulation: value.isVirtualSimulation,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            targetId: this.state.columnsAData.id,
                            trainAddress: value.trainAddress,
                            trainName: value.trainName,
                            trainTime: value.trainTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                        }
                        updatePartakeTrain(param).then(res => {
                            if (res) {
                                message.success("修改成功")
                                this.setState({
                                    file: null,
                                    train: ''
                                }, () => {
                                    this.AddTeacherMaterial.props.form.resetFields()
                                    this.AddTeacherMaterial.deleteFile()
                                    this.getPartakeTrain()
                                })
                            }

                        })
                    })
                } else {
                    this.AddTeacherMaterial.props.form.validateFields((err, value) => {
                        if (!err) {
                            let param = {
                                evidenceQo: {
                                    fileId: file?.fileId,
                                    resourceId: file?.resourceId,
                                    resourceName: file?.originName,
                                    resourceSize: file?.fileSize,
                                    resourceSuffix: file?.fileSuffix
                                },
                                partakeNumber: value.partakeNumber,
                                isVirtualSimulation: value.isVirtualSimulation,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsAData.id,
                                trainAddress: value.trainAddress,
                                trainName: value.trainName,
                                trainTime: value.trainTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                            }
                            addPartakeTrain(param).then(res => {
                                if (res) {
                                    message.success("添加成功")
                                    this.setState({
                                        // addTeacherMaterialModal: false
                                        file: null,
                                        train: ''
                                    }, () => {
                                        this.AddTeacherMaterial.props.form.resetFields()
                                        this.AddTeacherMaterial.deleteFile()
                                        this.getPartakeTrain(1)
                                    })
                                }
                            })
                        }
                    })
                }
            }

            columnsBTiJiao = (type) => {
                let file = this.AddTeaStuMaterial.getState()
                if (this.state.partakeCourseId) {
                    this.AddTeaStuMaterial.props.form.validateFields((err, value) => {
                        if (!err) {
                            let param = {
                                // evidenceQo: {
                                //     fileId: file?.fileId,
                                //     resourceId: file?.resourceId,
                                //     resourceName: file?.originName,
                                //     resourceSize: file?.fileSize,
                                //     resourceSuffix: file?.fileSuffix
                                // },
                                id: this.state.partakeCourseId,
                                partakeTeacher: value.partakeTeacher,
                                belongFaculty: value.belongFaculty,

                                majorOne: value.courseMajor[0],
                                majorTwo: value.courseMajor[1],
                                courseMajor: value.courseMajor[2],
                                courseName: value.courseName,
                                trainTime: value.trainTime,
                                // isBase: value.isBase,
                                // partakeStudent: value.partakeStudent,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsBData.id
                            }
                            updatePartakeCourse(param).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        file: null,
                                        addTeaStuMaterialModal: false
                                    }, () => {
                                        this.AddTeaStuMaterial.props.form.resetFields()
                                        this.AddTeaStuMaterial.deleteFile()
                                        this.getPartakeCourse()
                                    })
                                }

                            })
                        }
                    })
                } else {
                    this.AddTeaStuMaterial.props.form.validateFields((err, value) => {
                        if (!err) {
                            // let data = {
                            //     evidenceQo: {
                            //         fileId: file?.fileId,
                            //         resourceId: file?.resourceId,
                            //         resourceName: file?.originName,
                            //         resourceSize: file?.fileSize,
                            //         resourceSuffix: file?.fileSuffix
                            //     },
                            //     majorOne: value.courseMajor[0],
                            //     majorTwo: value.courseMajor[1],
                            //     courseMajor: value.courseMajor[2],
                            //     courseName: value.courseName,
                            //     isBase: value.isBase,
                            //     partakeStudent: value.partakeStudent,
                            //     partakeTeacher: value.partakeTeacher,
                            //     schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            //     targetId: this.state.columnsBData.id
                            // }
                            let data = {
                                // evidenceQo: {
                                //     fileId: file?.fileId,
                                //     resourceId: file?.resourceId,
                                //     resourceName: file?.originName,
                                //     resourceSize: file?.fileSize,
                                //     resourceSuffix: file?.fileSuffix
                                // },
                                id: this.state.partakeCourseId,
                                partakeTeacher: value.partakeTeacher,
                                belongFaculty: value.belongFaculty,

                                majorOne: value.courseMajor[0],
                                majorTwo: value.courseMajor[1],
                                courseMajor: value.courseMajor[2],
                                courseName: value.courseName,
                                trainTime: value.trainTime,
                                // isBase: value.isBase,
                                // partakeStudent: value.partakeStudent,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsBData.id
                            }
                            addPartakeCourse(data).then(res => {
                                if (res) {
                                    message.success("添加成功")
                                    this.setState({
                                        file: null,
                                        addTeaStuMaterialModal: false
                                    }, () => {
                                        this.AddTeaStuMaterial.props.form.resetFields()
                                        this.AddTeaStuMaterial.deleteFile()
                                        this.getPartakeCourse(1)
                                    })
                                }

                            })
                        }

                    })
                }
            }
            columnsBTiJiaoAndAdd = () => {
                let file = this.AddTeaStuMaterial.getState()
                if (this.state.partakeCourseId) {
                    this.AddTeaStuMaterial.props.form.validateFields((err, value) => {
                        if (!err) {
                            // let param = {
                            //     evidenceQo: {
                            //         fileId: file?.fileId,
                            //         resourceId: file?.resourceId,
                            //         resourceName: file?.originName,
                            //         resourceSize: file?.fileSize,
                            //         resourceSuffix: file?.fileSuffix
                            //     },
                            //     id: this.state.partakeCourseId,
                            //     majorOne: value.courseMajor[0],
                            //     majorTwo: value.courseMajor[1],
                            //     courseMajor: value.courseMajor[2],
                            //     courseName: value.courseName,
                            //     isBase: value.isBase,
                            //     partakeStudent: value.partakeStudent,
                            //     partakeTeacher: value.partakeTeacher,
                            //     schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            //     targetId: this.state.columnsBData.id
                            // }
                            let param = {
                                // evidenceQo: {
                                //     fileId: file?.fileId,
                                //     resourceId: file?.resourceId,
                                //     resourceName: file?.originName,
                                //     resourceSize: file?.fileSize,
                                //     resourceSuffix: file?.fileSuffix
                                // },
                                id: this.state.partakeCourseId,
                                partakeTeacher: value.partakeTeacher,
                                belongFaculty: value.belongFaculty,

                                majorOne: value.courseMajor[0],
                                majorTwo: value.courseMajor[1],
                                courseMajor: value.courseMajor[2],
                                courseName: value.courseName,
                                trainTime: value.trainTime,
                                // isBase: value.isBase,
                                // partakeStudent: value.partakeStudent,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsBData.id
                            }
                            updatePartakeCourse(param).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        // addTeacherMaterialModal: false
                                        file: null,
                                        course: ''
                                    }, () => {
                                        this.AddTeaStuMaterial.props.form.resetFields()
                                        this.AddTeaStuMaterial.deleteFile()
                                        this.getPartakeCourse()
                                    })
                                }

                            })
                        }
                    })
                } else {
                    this.AddTeaStuMaterial.props.form.validateFields((err, value) => {
                        if (!err) {
                            // let data = {
                            //     evidenceQo: {
                            //         fileId: file?.fileId,
                            //         resourceId: file?.resourceId,
                            //         resourceName: file?.originName,
                            //         resourceSize: file?.fileSize,
                            //         resourceSuffix: file?.fileSuffix
                            //     },
                            //     majorOne: value.courseMajor[0],
                            //     majorTwo: value.courseMajor[1],
                            //     courseMajor: value.courseMajor[2],
                            //     courseName: value.courseName,
                            //     isBase: value.isBase,
                            //     partakeStudent: value.partakeStudent,
                            //     partakeTeacher: value.partakeTeacher,
                            //     schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            //     targetId: this.state.columnsBData.id
                            // }
                            let data = {
                                // evidenceQo: {
                                //     fileId: file?.fileId,
                                //     resourceId: file?.resourceId,
                                //     resourceName: file?.originName,
                                //     resourceSize: file?.fileSize,
                                //     resourceSuffix: file?.fileSuffix
                                // },
                                id: this.state.partakeCourseId,
                                partakeTeacher: value.partakeTeacher,
                                belongFaculty: value.belongFaculty,

                                majorOne: value.courseMajor[0],
                                majorTwo: value.courseMajor[1],
                                courseMajor: value.courseMajor[2],
                                courseName: value.courseName,
                                trainTime: value.trainTime,
                                // isBase: value.isBase,
                                // partakeStudent: value.partakeStudent,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsBData.id
                            }
                            addPartakeCourse(data).then(res => {
                                if (res) {
                                    message.success("添加成功")
                                    this.setState({
                                        // addTeaStuMaterialModal: false
                                        file: null,
                                        course: ''
                                    }, () => {
                                        this.AddTeaStuMaterial.props.form.resetFields()
                                        this.AddTeaStuMaterial.deleteFile()
                                        this.getPartakeCourse(1)
                                    })
                                }

                            })
                        }

                    })
                }
            }

            columnsCTiJiao = () => {
                let file = this.AddTeacherLeader.getState()
                if (this.state.partakeLeaderId) {
                    this.AddTeacherLeader.props.form.validateFields((err, value) => {
                        if (!err) {
                            let param = {
                                evidenceQo: {
                                    fileId: file?.fileId,
                                    resourceId: file?.resourceId,
                                    resourceName: file?.originName,
                                    resourceSize: file?.fileSize,
                                    resourceSuffix: file?.fileSuffix
                                },
                                id: this.state.partakeLeaderId,
                                applyMajor: value.applyMajor,
                                leaderContact: value.leaderContact,
                                leaderDesc: value.leaderDesc,
                                leaderName: value.leaderName,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsCData.id
                            }
                            updatePartakeLeader(param).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        file: null,
                                        addTeacherLeaderModal: false
                                    }, () => {
                                        this.AddTeacherLeader.props.form.resetFields()
                                        this.AddTeacherLeader.deleteFile()
                                        this.getPartakeLeader()
                                    })
                                }

                            })
                        }
                    })
                } else {
                    this.AddTeacherLeader.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                evidenceQo: {
                                    fileId: file?.fileId,
                                    resourceId: file?.resourceId,
                                    resourceName: file?.originName,
                                    resourceSize: file?.fileSize,
                                    resourceSuffix: file?.fileSuffix
                                },
                                applyMajor: value.applyMajor,
                                leaderContact: value.leaderContact,
                                leaderDesc: value.leaderDesc,
                                leaderName: value.leaderName,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsCData.id
                            }
                            addPartakeLeader(data).then(res => {
                                if (res) {
                                    message.success("添加成功")
                                    this.setState({
                                        file: null,
                                        addTeacherLeaderModal: false
                                    }, () => {
                                        this.AddTeacherLeader.props.form.resetFields()
                                        this.AddTeacherLeader.deleteFile()
                                        this.getPartakeLeader(1)
                                    })
                                }
                            })
                        }
                    })
                }
            }

            columnsCTiJiaoAndAdd = () => {
                let file = this.AddTeacherLeader.getState()
                if (this.state.partakeLeaderId) {
                    this.AddTeacherLeader.props.form.validateFields((err, value) => {
                        if (!err) {
                            let param = {
                                evidenceQo: {
                                    fileId: file?.fileId,
                                    resourceId: file?.resourceId,
                                    resourceName: file?.originName,
                                    resourceSize: file?.fileSize,
                                    resourceSuffix: file?.fileSuffix
                                },
                                id: this.state.partakeLeaderId,
                                applyMajor: value.applyMajor,
                                leaderContact: value.leaderContact,
                                leaderDesc: value.leaderDesc,
                                leaderName: value.leaderName,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsCData.id
                            }
                            updatePartakeLeader(param).then(res => {
                                if (res) {
                                    message.success("修改成功")
                                    this.setState({
                                        file: null,
                                        // addTeacherMaterialModal: false
                                        leader: ''
                                    }, () => {
                                        this.AddTeacherLeader.props.form.resetFields()
                                        this.AddTeacherLeader.deleteFile()
                                        this.getPartakeTrain()
                                    })
                                }

                            })
                        }
                    })
                } else {
                    this.AddTeacherLeader.props.form.validateFields((err, value) => {
                        if (!err) {
                            let data = {
                                evidenceQo: {
                                    fileId: file?.fileId,
                                    resourceId: file?.resourceId,
                                    resourceName: file?.originName,
                                    resourceSize: file?.fileSize,
                                    resourceSuffix: file?.fileSuffix
                                },
                                applyMajor: value.applyMajor,
                                leaderContact: value.leaderContact,
                                leaderDesc: value.leaderDesc,
                                leaderName: value.leaderName,
                                schoolEduCode: this.state.schoolInfo.schoolEduCode,
                                targetId: this.state.columnsCData.id
                            }
                            addPartakeLeader(data).then(res => {
                                if (res) {
                                    message.success("添加成功")
                                    this.setState({
                                        file: null,
                                        // addTeacherLeaderModal: false
                                        leader: ''
                                    }, () => {
                                        this.AddTeacherLeader.props.form.resetFields()
                                        this.AddTeacherLeader.deleteFile()
                                        this.getPartakeLeader(1)
                                    })
                                }
                            })
                        }
                    })
                }
            }


            teacherProjectCommitAndAdd = (type = "addOnly") => {
                let form = this.AddTeaProject.props.form;
                // let file = this.AddTeaProject.getState()
                form.validateFields((err, values) => {
                    console.log(values)
                    if (err) {
                        return false;
                    }
                    let fileParams = {
                        // evidenceQo: {
                        //     fileId: file.fileId,
                        //     resourceId: file.resourceId,
                        //     resourceName: file.originName,
                        //     resourceSize: file.fileSize,
                        //     resourceSuffix: file.fileSuffix
                        // }
                    }
                    const {fileId, ...otherParams} = values
                    let params = {
                        ...otherParams, ...fileParams,
                        targetId: this.state.teacherProjectVO.id,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode
                    };
                    // section 日期修复代码
                    params.projectStartTime = params.projectStartTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                    params.projectEndTime = params.projectEndTime.format('YYYY-MM-DDTHH:mm:ss') + 'Z'

                    editPartakeProject(params).then(res => {
                        if (params.id) {
                            // message.success('修改成功')
                            this.getTeacherProject(type)
                        } else {
                            // message.success('保存成功')
                            this.getTeacherProject(type, 1)
                        }
                        if (type != 'addOnly') {
                            message.success('添加成功')
                            this.AddTeaProject.props.form.resetFields()
                            // this.AddTeaProject.deleteFile()
                        } else {
                            this.setState({
                                teacherProjectVisible: false
                            }, () => {
                                if (params.id) {
                                    message.success('修改成功')
                                } else {
                                    message.success('添加成功')
                                }
                            })
                        }
                    })
                })
            }

            // 导入文件
            importPartakeTrain = async (e) => {
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('targetId', this.state.columnsAData.id)
                fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
                importPartakeTrain(fromData).then(res => {
                    message.success('导入成功')
                    this.getPartakeTrain(1)
                })
            }
            // 导入文件
            importPartakeCourse = async (e) => {
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('targetId', this.state.columnsBData.id)
                fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
                importPartakeCourse(fromData).then(res => {
                    message.success('导入成功')
                    this.getPartakeCourse(1)
                })
            }
            // 导入文件
            importPartakeLeader = async (e) => {
                let list = [...e.target.files];
                let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
                if (thisFileName === "") {
                    message.error('文件名不能为空')
                    return
                }
                let flag = false;
                let checkType = "xls、xlsx";
                list.forEach((k) => {
                    const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
                    const fileNameLen = k.name.length; // 取到文件名长度
                    const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
                    if (!checkType.includes(fileType)) {
                        message.error("上传的资源格式不符")
                        flag = true
                    }
                });
                //类型不符停止进行
                if (flag) {
                    return
                }
                const fromData = new FormData()
                fromData.append('file', list[0])
                fromData.append('targetId', this.state.columnsCData.id)
                fromData.append('schoolEduCode', this.state.schoolInfo.schoolEduCode)
                importPartakeLeader(fromData).then(res => {
                    message.success('导入成功')
                    this.getPartakeLeader(1)
                })
            }

            //下载文件
            dowLoadFile = (targetMark, targetName) => {
                console.log("targetMark", targetMark)
                let now = new Date().getTime();
                axios.get(downTemplate(targetMark), {
                    headers: {
                        'content-type': 'application/json; charset=utf-8',
                        // token: getToken(),
                        "z-ts": now,
                        "z-sign": md5(now + "")
                    },
                    responseType: 'blob', // --设置请求数据格式
                }).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${targetName}.xls`);
                    link.click();
                })
            }

            getSharingMajor = () => {
                getSharingMajorList({stageId: 2}).then((res) => {
                    if (res) {
                        this.setState({
                            majorList: res,
                        });
                    }
                });
            }

            updateFactor = (value) => {
                let data = {
                    id: this.state.columnsAData.factorId,
                    factorContent: Number(value),
                }
                updateFactor(data).then(res => {
                    if (res) {
                        // message.success("操作成功")
                        if (this.state.teacherNum === 0) {
                            this.setState({
                                columnsABaiFenBi: 0
                            })
                        } else {
                            this.setState({
                                columnsABaiFenBi: (value / this.state.teacherNum) * 100
                            })
                        }
                    }
                })
            }
            updateColumnsBData = (value) => {
                let {columnsBTotal} = this.state;
                if ((!value && value !== 0) || Number(value) < columnsBTotal || (value === 0 && columnsBTotal !== 0)) {
                    return;
                }
                let data = {
                    id: this.state.columnsBData.factorId,
                    // factorContent: Number(value),
                    teacherNumber: Number(value),
                    schoolEduCode: this.state.schoolInfo.schoolEduCode,
                }
                updateSchoolTeacherTotalNum(data).then(res => {
                    // if (res) {
                    //     // message.success("操作成功")
                    //     if (this.state.teacherNum === 0) {
                    //         this.setState({
                    //             columnsABaiFenBi: 0
                    //         })
                    //     } else {
                    //         this.setState({
                    //             columnsABaiFenBi: (value / this.state.teacherNum) * 100
                    //         })
                    //     }
                    // }
                })
            }
            onChangeFactor = (value) => {
                this.setState({
                    factorContent: Number(value)
                })
            }

            onChangeColumnsBData = (value) => {
                let {columnsBData, columnsBTotal} = this.state;
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    if (!value && value !== 0) {
                        return;
                    }
                    if (Number(value) < columnsBTotal || (value === 0 && columnsBTotal !== 0)) {
                        message.destroy();
                        message.warning('教师总数不能小于参与虚拟仿真实训资源开发的教师数!');
                        return;
                    }
                    this.setState({
                        columnsBData: {...columnsBData, teacherTotalNum: Number(value)}
                    })
                }, 500);
            }

            // section Render
            render() {
                const canEdit = getCanEdit()
                let {pageNum, pageSize, pageNumone, pageSizeone, pageNumtwo, pageSizetwo} = this.state;
                const columnsA = [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                        render: (text, record, index) => {
                            // return <span>{index + 1}</span>
                            return <span>{(pageNum - 1) * pageSize + (index + 1)}</span>
                        }
                    },
                    {title: '培训项目', dataIndex: 'trainName', key: 'programsName', align: 'center', width: '200'},
                    {
                        title: '是否为虚拟仿真',
                        dataIndex: 'isVirtualSimulation',
                        key: 'isVirtualSimulation',
                        align: 'center',
                        width: '140',
                        render: (text, record) => {
                            return <span>{text ? '是' : '否'}</span>
                        }
                    },
                    {
                        title: '培训时间', dataIndex: 'trainTime', key: 'trainTime', align: 'center', width: '150',
                        render: (text, record) => {
                            return <span>{moment(text).utcOffset(+8).format("YYYY-MM-DD HH:mm")}</span>
                            // return <span>{(text || '').replace(/T/g, ' ')}</span>
                        }
                    },
                    {title: '培训地点', dataIndex: 'trainAddress', key: 'trainAddress', align: 'center', width: '150'},
                    {title: '参加人员数量', dataIndex: 'partakeNumber', key: 'partakeNumber', align: 'center', width: '140'},
                    // {
                    //     title: '佐证材料', dataIndex: 'fileId', key: 'fileId', align: 'center', width: '150',
                    //     render: (text, record) => record.fileId ?
                    //         <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> :
                    //         <span>未上传</span>
                    // }
                ]
                if (canEdit) columnsA.push({
                    title: '操作', align: 'center',
                    width: 220, fixed: "right",
                    dataIndex: 'oper',
                    key: 'oper',
                    render: (text, record) => <div className='render-span vertical_j oper'>
                        <div>
                            <span className='cz' onClick={() => this.updatePartakeTrain(record)}>编辑 </span>
                            <span className='cz' onClick={() => this.deletePartakeTrain(record)}>删除</span>
                        </div>
                    </div>
                })
                const columnsB = [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                        render: (text, record, index) => {
                            // return <span>{index + 1}</span>
                            return <span>{(pageNumone - 1) * pageSizeone + (index + 1)}</span>
                        }
                    },
                    {
                        title: '参与开发教师姓名',
                        dataIndex: 'partakeTeacher',
                        key: 'partakeTeacher',
                        align: 'center',
                        width: '200'
                    },
                    {
                        title: '所属院系',
                        dataIndex: 'belongFaculty',
                        key: 'belongFaculty',
                        align: 'center',
                        width: '150'
                    },
                    {
                        title: '所属专业',
                        dataIndex: 'courseMajorName',
                        key: 'courseMajorName',
                        align: 'center',
                        width: '150'
                    },
                    // {
                    //     title: '所属专业', dataIndex: 'isBase', key: 'isBase', align: 'center', width: '150',
                    //     render: (text, record) => {
                    //         return <span>{text === "0" ? "否" : text === "1" ? "是" : ''}</span>
                    //     }
                    // },
                    {
                        title: '虚拟仿真实训资源名称',
                        dataIndex: 'courseName',
                        key: 'courseName',
                        align: 'center',
                        width: '150'
                    },
                    // {
                    //     title: '参与的学生姓名',
                    //     dataIndex: 'partakeStudent',
                    //     key: 'partakeStudent',
                    //     align: 'center',
                    //     width: '150'
                    // },
                    // {
                    //     title: '佐证材料',
                    //     dataIndex: 'fileId',
                    //     key: 'fileId',
                    //     align: 'center',
                    //     width: 100,
                    //     render: (text, record) => record.fileId ?
                    //         <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> :
                    //         <span>未上传</span>
                    // }
                ]
                if (canEdit) columnsB.push({
                    title: '操作', align: 'center',
                    width: 220, fixed: "right",
                    dataIndex: 'oper',
                    key: 'oper',
                    render: (text, record) => <div className='render-span vertical_j oper'>
                        <div>
                            <span className='cz' onClick={() => this.updatePartakeCourse(record)}>编辑 </span>
                            <span className='cz' onClick={() => this.deletePartakeCourse(record)}>删除</span>
                        </div>
                    </div>
                })
                const columnsC = [
                    {
                        title: '序号', dataIndex: 'index', key: 'index', align: 'center', width: 80,
                        render: (text, record, index) => {
                            // return <span>{index + 1}</span>
                            return <span>{(pageNumtwo - 1) * pageSizetwo + (index + 1)}</span>
                        }
                    },
                    {title: '带头人姓名', dataIndex: 'leaderName', key: 'leaderName', align: 'center', width: '200'},
                    {title: '联系方式', dataIndex: 'leaderContact', key: 'leaderContact', align: 'center', width: '150'},
                    {title: '虚拟仿真应用专业名称', dataIndex: 'applyMajor', key: 'applyMajor', align: 'center', width: '150'},
                    {title: '带头人的个人简介', dataIndex: 'leaderDesc', key: 'leaderDesc', align: 'center', width: '150'},
                    // {
                    //     title: '佐证材料',
                    //     dataIndex: 'fileId',
                    //     key: 'fileId',
                    //     align: 'center',
                    //     width: 100,
                    //     render: (text, record) => record.fileId ?
                    //         <span className={'chakan'} onClick={() => this.handLook(record)}>查看</span> :
                    //         <span>未上传</span>
                    // }
                ]
                if (canEdit) columnsC.push({
                    title: '操作', align: 'center',
                    width: 220, fixed: "right",
                    dataIndex: 'oper',
                    key: 'oper',
                    render: (text, record) => <div className='render-span vertical_j oper'>
                        <div>
                            <span className='cz' onClick={() => this.updatePartakeLeader(record)}>编辑 </span>
                            <span className='cz' onClick={() => this.deletePartakeLeader(record)}>删除</span>
                        </div>
                    </div>
                })
                const {
                    resourceId,
                    examine,
                    teacherNum,
                    peopleNum,
                    partakeCourseId,
                    partakePlanId,
                    partakeLeaderId,
                    partakeTrainId,
                    columnsBBaiFenBi,
                    columnsABaiFenBi,
                    columnsATotal,
                    columnsBTotal,
                    columnsCTotal,
                    majorList,
                    leader,
                    course,
                    train,
                    columnsAData,
                    columnsBData,
                    columnsCData,
                    columnsADataSource,
                    columnsBDataSource,
                    columnsCDataSource,
                    dataSource,
                    addTeacherMaterialModal,
                    addTeaStuMaterialModal,
                    addTeacherLeaderModal,
                    // pageNum,
                    // pageSize,
                    total,
                    totalone,
                    // pageNumone,
                    // pageSizeone,
                    // pageNumtwo,
                    // pageSizetwo,
                    totaltwo,
                    teacherProjectVO,
                    teacherProjectRecord,
                    teacherProjectVisible,
                    factorContent,
                    isShow,
                    openUrl
                } = this.state;

                const {partakeProjectVoList = {}} = teacherProjectVO || {}
                const {
                    data: columnsDSource,
                    pageNum: pageD = 1,
                    pageSize: pageSizeD = 10,
                    total: totalD
                } = partakeProjectVoList
                const teacherProjectVoColumns = [
                    {
                        title: '序号',
                        dataIndex: 'id',
                        width: 80,
                        align: 'center',
                        key: 'rowIndex',
                        render: (text, record, index) => <span>{(pageD - 1) * pageSizeD + (index + 1)}</span>,

                    },
                    {
                        title: '课题名称',
                        dataIndex: 'projectName',
                        align: 'center',
                        render: text => <span>{text || '--'}</span>
                    },
                    {
                        title: '课题级别',
                        align: 'center',
                        dataIndex: 'projectLevel',
                        render: (text, record) => <span>{record.projectLevelName || '--'}</span>

                    },
                    {
                        title: '课题来源',
                        align: 'center',
                        dataIndex: 'projectSource',

                    },
                    // {
                    //     title: '是否为虚拟仿真实训教学模式研究',
                    //     dataIndex: 'isResearch',
                    //     align: 'center',
                    //     render: text => <span>{text == '0' ? '否' : '是'}</span>

                    // },
                    {
                        title: '课题负责人',
                        dataIndex: 'projectPresenter',
                        align: 'center',
                        render: text => <span>{text}</span>
                    },
                    // {
                    //     title: '课题主持人',
                    //     align: 'center',
                    //     dataIndex: 'projectPresenter',

                    // },
                    {
                        title: '立项日期',
                        align: 'center',
                        dataIndex: 'projectStartTime',
                        render: text => moment(text).format('YYYY-MM-DD HH:mm')

                    },
                    {
                        title: '结题日期',
                        align: 'center',
                        dataIndex: 'projectEndTime',
                        render: text => moment(text).format('YYYY-MM-DD HH:mm')
                    }
                ]

                if (canEdit) teacherProjectVoColumns.push({
                    title: '操作', align: 'center',
                    width: 220, fixed: "right",
                    dataIndex: 'oper',
                    key: 'oper',
                    render: (text, record) => <div className='render-span vertical_j oper'>
                        <div>
                            <span className='cz' onClick={() => this.updatePartakeLeader(record, 'project')}>编辑 </span>
                            <span className='cz' onClick={() => this.deletePartakeProject(record, 'project')}>删除</span>
                        </div>
                    </div>
                })

                console.log('course', course)
                const {getFieldDecorator} = this.props.form;

                const {Option} = Select;

                const props = {
                    name: 'file',
                    action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
                    headers: {
                        authorization: 'authorization-text',
                    },
                    onChange(info) {
                        if (info.file.status !== 'uploading') {
                            console.log(info.file, info.fileList);
                        }
                        if (info.file.status === 'done') {
                            message.success(`${info.file.name} file uploaded successfully`);
                        } else if (info.file.status === 'error') {
                            message.error(`${info.file.name} file upload failed.`);
                        }
                    },
                };

                return (
                    <div className="Teacher_Development Fillside_warp">
                        <Heder/>
                        <div className="Efficiency_header">
                            <div className="main_cont">
                                <EffTop/>
                            </div>
                        </div>
                        <div className="Fillside_bottom clearfix">
                            <div className="main_cont">
                                <div className="Fillside_warp" id='Fillside_warp'>
                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="leftcollect" id="leftcollect">
                                            <div className="Double-left-table">
                                                <LeftEfficiencyside onRef={(ref) => {
                                                    this.LeftEfficiencyside = ref;
                                                }}/>
                                            </div>
                                        </Col>
                                        <Col span={20} className="Task_right">
                                            <div className="right_ReformPersonnel">
                                                <div className="common-container first-contianer-141">
                                                    {/* // 1.4.1 start */}
                                                    <div className="title_top">
                                                        <h4>1.4.1 参加虚拟仿真实训教学专题培训的教师占比</h4>
                                                    </div>

                                                    {/* <div style={{ color: '#D75746', marginLeft: '35px', paddingTop: '0px', paddingBottom: '20px' }}>*仅添加虚拟仿真实训教学专题培训的信息</div> */}

                                                    <div className="conte">

                                                        <div className='conteCompPercent'>

                                                            <Row style={{width: '100%'}}>
                                                                <Col span={8} style={{width: 'auto'}}>
                                                                    <span className='CCPA'>教师总人次</span>
                                                                    {/* <InputNumber min={0} className='input_bg'
                                                                             disabled={!canEdit}
                                                                             value={factorContent ? factorContent : 0}
                                                                             onChange={e => this.onChangeFactor(e)}
                                                                             onBlur={e => this.updateFactor(e.target.value)}
                                                                             placeholder="数值填充"/> */}
                                                                    <div className='conteInput_right'
                                                                         style={{marginTop: '0px'}}>{Number(factorContent) ? factorContent : 0}</div>
                                                                </Col>
                                                                <Col span={8} style={{width: 'auto'}}>
                                                                    <span
                                                                        // className='CCPA CCPB'>参加虚拟仿真培训教师数{columnsAData.year}完成值</span>
                                                                        className='CCPA CCPB'
                                                                        style={{marginLeft: '22px'}}>虚拟仿真类教师人次</span>
                                                                    <div
                                                                        className='conteInput_right'>{teacherNum ? teacherNum : 0}</div>
                                                                </Col>
                                                                <Col span={8} style={{
                                                                    marginTop: (teacherNum && factorContent !== 0 && (teacherNum / factorContent) * 100 <= 30) ? '0px' : '0px',
                                                                    width: 'auto'
                                                                }}>
                                                                    <span
                                                                        // className='CCPA CCPC'>基地教师参加虚拟仿真专题培训占比{columnsAData.year}</span>
                                                                        className='CCPA CCPC' style={{
                                                                        width: 'auto',
                                                                        marginLeft: '22px'
                                                                    }}>占比</span>
                                                                    <div
                                                                        className={`conteInput_right ${teacherNum && Number(factorContent) !== 0 && (teacherNum / factorContent) * 100 <= 30 ? 'conteInput_right_warning_border' : ''}`}>{teacherNum && Number(factorContent) !== 0 ? ((teacherNum / factorContent) * 100).toFixed(2) : 0}%
                                                                    </div>
                                                                    {
                                                                        (teacherNum && factorContent !== 0 && (teacherNum / factorContent) * 100 <= 30) ?
                                                                            <div
                                                                                className='conteInput_right_warning'>*该项指标需高于30%</div> : ''
                                                                    }
                                                                    {/* <div
                                                                    className={`conteInput_right ${teacherNum && factorContent !== 0 && (teacherNum / factorContent) * 100<=30?'conteInput_right_warning_border':''}`}>{teacherNum && factorContent !== 0 ? ((teacherNum / factorContent) * 100).toFixed(2) : 0}%
                                                                </div>
                                                                {
                                                                    teacherNum && factorContent !== 0 && (teacherNum / factorContent) * 100<=30 && <div className='conteInput_right_warning'>*该项指标需高于30%</div>
                                                                } */}
                                                                    {/*<Input className='conteInputA' placeholder="80" />*/}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {/* <div style={{ color: '#D75746', paddingTop: '20px' }}>*仅添加虚拟仿真实训教学专题培训的信息</div> */}
                                                        {canEdit ? <div className="button_bottom">
                                                            <div className='conteBtnDiv'
                                                                 onClick={this.addTeacherMaterial}>
                                                                <TeacherDevelopmentUpload/><span
                                                                className='conteBtnDivSpan'>添加培训项目</span>
                                                            </div>
                                                            <div style={{marginTop: '20px'}}>
                                                                <ImportComponent
                                                                    targetId={columnsAData?.id}
                                                                    targetMark="1.4.1"
                                                                    downloadName="参加虚拟仿真实训教学专题培训的教师占比"
                                                                    onImportSuccess={() => {
                                                                        this.getPartakeTrain(1)
                                                                    }}
                                                                />
                                                            </div>

                                                            {/*<div className='conteBtnDiv' >*/}
                                                            {/*    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}*/}
                                                            {/*           onChange={this.importPartakeTrain}/><ExcelImport/><span className='conteBtnDivSpan'>Excel导入</span></div>*/}
                                                            {/*<div className='conteBtnDiv' onClick={() => this.dowLoadFile(columnsAData.targetMark, columnsAData.targetName)}><Daoru/><span className='conteBtnDivSpan'>下载导入模版</span></div>*/}
                                                        </div> : ""}
                                                        <div className={`conteTable ${canEdit ? '' : 'cannotEdit'}`}
                                                             style={{marginBottom: columnsADataSource?.length > 0 ? '-65px' : '-5px'}}>
                                                            <Table columns={columnsA} dataSource={columnsADataSource}
                                                                   bordered={true} className='table1'
                                                                   locale={{
                                                                       emptyText: <>
                                                                           <div className="Empty-content">
                                                                               <div className="text">
                                                                                   <img
                                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                                   <span>暂无数据</span>
                                                                               </div>
                                                                           </div>
                                                                       </>
                                                                   }}
                                                                   pagination={
                                                                       {
                                                                           total: columnsATotal,
                                                                           pageSize: pageSize,
                                                                           current: pageNum,
                                                                           onChange: this.pageChange,
                                                                           onShowSizeChange: this.onShowSizeChange,
                                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                                           showSizeChanger: true,
                                                                           // position:'bottomRight'
                                                                       }
                                                                   }
                                                            />

                                                        </div>
                                                        <div className='addSupportingMaterials'>
                                                            <UploaderComponent
                                                                editEnable={canEdit}
                                                                uploadTitle="添加佐证材料"
                                                                targetId={columnsAData?.id}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // 1.4.2 start */}
                                                <div className="common-container">
                                                    <div className="title_top title_topB">
                                                        <h4>1.4.2 参与虚拟仿真实训资源开发的教师占比</h4>
                                                    </div>
                                                    <div className="conte ">
                                                        <div className='conteCompPercent'>
                                                            <Row>
                                                                <Col span={8} style={{width: 'auto'}}>
                                                                    <span className='CCPA'
                                                                          style={{marginTop: '0px'}}>学校教师总数</span>
                                                                    <InputNumber min={0} className='input_bg'
                                                                                 style={{marginTop: '0px'}}
                                                                                 disabled={!canEdit}
                                                                                 value={columnsBData.teacherTotalNum ? columnsBData.teacherTotalNum : 0}
                                                                                 onChange={e => this.onChangeColumnsBData(e)}
                                                                                 onBlur={e => this.updateColumnsBData(e.target.value)}
                                                                                 placeholder="教师总数"/>
                                                                </Col>
                                                                <Col span={8} style={{width: 'auto'}}>
                                                                    <span
                                                                        // 参与开发虚拟仿真实训资源的教师数/教师总数
                                                                        className='CCPA CCPB'
                                                                        style={{marginLeft: '-20px', width: 'auto'}}>
                                                                        {/* 师生开发的课程资源数{columnsBData.year}完成值 */}
                                                                        参与开发的教师数
                                                                    </span>
                                                                    <div
                                                                        className='conteInput_right'>{columnsBTotal ? columnsBTotal : 0}</div>
                                                                </Col>
                                                                <Col span={8} style={{width: 'auto'}}>
                                                                    <span
                                                                        className='CCPA CCPC'
                                                                        style={{width: 'auto', marginLeft: '22px'}}>
                                                                        {/* 师生参与虚拟仿真课程资源开发占比{columnsBData.year} */}
                                                                        占比
                                                                    </span>
                                                                    <div
                                                                        className={`conteInput_right ${columnsBTotal && columnsBData?.teacherTotalNum && columnsBData.teacherTotalNum !== 0 && (columnsBTotal / (columnsBData.teacherTotalNum)) * 100 <= 10 ? 'conteInput_right_warning_border' : ''}`}>{columnsBTotal && columnsBData?.teacherTotalNum && columnsBData.teacherTotalNum !== 0 ? (columnsBTotal / (columnsBData.teacherTotalNum) * 100).toFixed(2) : 0} %
                                                                    </div>

                                                                    {/* <div
                                                                    className={`conteInput_right ${teacherNum && factorContent !== 0 && (teacherNum / factorContent) * 100<=30?'conteInput_right_warning_border':''}`}>{teacherNum && factorContent !== 0 ? ((teacherNum / factorContent) * 100).toFixed(2) : 0}%
                                                                </div> */}
                                                                    {
                                                                        (columnsBTotal && columnsBData?.teacherTotalNum && columnsBData.teacherTotalNum !== 0 && (columnsBTotal / (columnsBData.teacherTotalNum)) * 100 <= 10) ?
                                                                            <div
                                                                                className='conteInput_right_warning'>*该项指标需高于10%</div> : ''
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {canEdit ? <div className="button_bottom">
                                                            <div className='conteBtnDiv'
                                                                 onClick={this.addTeaStuMaterial}>
                                                                <TeacherDevelopmentUpload/><span
                                                                className='conteBtnDivSpan'>添加参与开发教师</span>
                                                            </div>
                                                            <div style={{marginTop: '20px'}}>
                                                                <ImportComponent
                                                                    targetId={columnsBData?.id}
                                                                    targetMark="1.4.2"
                                                                    downloadName="参与虚拟仿真实训资源开发的教师占比"
                                                                    onImportSuccess={() => {
                                                                        this.getPartakeCourse(1)
                                                                    }}
                                                                />
                                                            </div>
                                                            {/*<div className='conteBtnDiv'>*/}
                                                            {/*    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}*/}
                                                            {/*           onChange={this.importPartakeCourse}/><ExcelImport/><span className='conteBtnDivSpan'>Excel导入</span></div>*/}
                                                            {/*<div className='conteBtnDiv' onClick={() => this.dowLoadFile(columnsBData.targetMark, columnsBData.targetName)}><Daoru/><span className='conteBtnDivSpan'>下载导入模版</span></div>*/}
                                                        </div> : ""}
                                                        <div className={`conteTable ${canEdit ? '' : 'cannotEdit'}`}
                                                             style={{marginBottom: columnsBDataSource?.length > 0 ? '-65px' : '-5px'}}>
                                                            <Table columns={columnsB} dataSource={columnsBDataSource}
                                                                   bordered={true} className='table1'
                                                                   locale={{
                                                                       emptyText: <>
                                                                           <div className="Empty-content">
                                                                               <div className="text">
                                                                                   <img
                                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                                   <span>暂无数据</span>
                                                                               </div>
                                                                           </div>
                                                                       </>
                                                                   }}
                                                                   pagination={
                                                                       {
                                                                           total: columnsBTotal,
                                                                           pageSize: pageSizeone,
                                                                           current: pageNumone,
                                                                           onChange: this.pageChangeone,
                                                                           onShowSizeChange: this.onShowSizeChangeone,
                                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                                           showSizeChanger: true,
                                                                           // position:'bottomRight'
                                                                       }
                                                                   }
                                                            />
                                                        </div>
                                                        <div className='addSupportingMaterials'>
                                                            <UploaderComponent
                                                                editEnable={canEdit}
                                                                uploadTitle="添加佐证材料"
                                                                targetId={columnsBData?.id}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // 1.4.3 start */}
                                                <div className="common-container">
                                                    <div className="title_top title_topB">
                                                        <h4>1.4.3 引进、培养虚拟现实技术应用专业带头人和骨干教师数</h4>
                                                    </div>
                                                    <div style={{
                                                        color: '#D75746',
                                                        marginLeft: '35px',
                                                        paddingTop: '0px',
                                                        paddingBottom: '20px'
                                                    }}>*专业带头人和骨干教师需师德师风高尚、技能水平高、业务能力强
                                                    </div>

                                                    <div className="conte ">
                                                        <div className='conteCompPercent'>
                                                            <Row>
                                                                <Col span={8}>
                                                                    {/* <span className='CCPA'>{columnsCData.year}完成值</span> */}
                                                                    <span className='CCPA'>人数</span>
                                                                    {/*<Input className='conteInput' placeholder="数值填充" />*/}
                                                                    <div
                                                                        className='conteInput_right'>{columnsCTotal ? columnsCTotal : 0}</div>
                                                                    <span className='CCPA'>位</span>
                                                                </Col>
                                                                {/* <Col span={8}>
                                                                <span className='CCPA CCPB'>统计单位</span>
                                                                <div className='conteInput_right'>个</div>
                                                            </Col> */}
                                                            </Row>
                                                        </div>
                                                        {canEdit ? <div className="button_bottom">
                                                            <div className='conteBtnDiv'
                                                                 onClick={this.addTeacherLeader}>
                                                                <TeacherDevelopmentAddPeop/><span
                                                                className='conteBtnDivSpan'>添加人员</span>
                                                            </div>
                                                            <div style={{marginTop: '20px'}}>
                                                                <ImportComponent
                                                                    targetId={columnsCData?.id}
                                                                    targetMark="1.4.3"
                                                                    downloadName="引进、培养虚拟现实技术应用专业带头人和骨干教师数"
                                                                    onImportSuccess={() => {
                                                                        this.getPartakeLeader(1)
                                                                    }}
                                                                />
                                                            </div>
                                                            {/*<div className='conteBtnDiv'>*/}
                                                            {/*    <Input id='importFile' type="file" accept="image" onClick={(event)=> { event.target.value = null }}*/}
                                                            {/*           onChange={this.importPartakeLeader}/><ExcelImport/><span className='conteBtnDivSpan'>Excel导入</span></div>*/}
                                                            {/*<div className='conteBtnDiv' onClick={() => this.dowLoadFile(columnsCData.targetMark, columnsCData.targetName)}><Daoru/><span className='conteBtnDivSpan'>下载导入模版</span></div>*/}
                                                        </div> : ""}

                                                        <div className={`conteTable ${canEdit ? '' : 'cannotEdit'}`}
                                                             style={{marginBottom: columnsCDataSource?.length > 0 ? '-65px' : '-5px'}}>
                                                            <Table columns={columnsC} dataSource={columnsCDataSource}
                                                                   bordered={true} className='table1'
                                                                   locale={{
                                                                       emptyText: <>
                                                                           <div className="Empty-content">
                                                                               <div className="text">
                                                                                   <img
                                                                                       src={window.$$imgSrcLcl + 'base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg'}/>
                                                                                   <span>暂无数据</span>
                                                                               </div>
                                                                           </div>
                                                                       </>
                                                                   }}
                                                                   pagination={
                                                                       {
                                                                           total: columnsCTotal,
                                                                           pageSize: pageSizetwo,
                                                                           current: pageNumtwo,
                                                                           onChange: this.pageChangetwo,
                                                                           onShowSizeChange: this.onShowSizeChangetwo,
                                                                           pageSizeOptions: ['10', '20', '30', '40'],
                                                                           showSizeChanger: true,
                                                                           // position:'bottomRight'
                                                                       }
                                                                   }
                                                            />
                                                        </div>
                                                        <div className='addSupportingMaterials'>
                                                            <UploaderComponent
                                                                editEnable={canEdit}
                                                                uploadTitle="添加佐证材料"
                                                                targetId={columnsCData?.id}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // 1.4.4 start */}
                                                <div className="XNFZKT-container">
                                                    <div className="title_top title_topB">
                                                        <h4>1.4.4 教师主持省级及以上虚拟仿真实训教学模式研究课题数</h4>
                                                    </div>

                                                    <div className="conte">

                                                        <div className='conteCompPercent'>
                                                            <Row>
                                                                <Col span={8}>
                                                                    <span
                                                                        className='CCPA CCPC'>数量</span>
                                                                    <div
                                                                        className='conteInput_right'>{totalD || 0}
                                                                    </div>
                                                                    {/*<Input className='conteInputA' placeholder="80" />*/}
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        {canEdit ? <div className="button_bottom">
                                                            <div className='conteBtnDiv'
                                                                 onClick={this.addTeacherMaterial.bind(this, 'project')}>
                                                                <TeacherDevelopmentUpload/><span
                                                                className='conteBtnDivSpan'>添加虚拟仿真课题</span>
                                                            </div>
                                                            <div style={{marginTop: '20px'}}>
                                                                <ImportComponent
                                                                    targetId={teacherProjectVO?.id}
                                                                    targetMark="1.4.4"
                                                                    downloadName="教师主持省级及以上虚拟仿真实训教学模式研究课题数"
                                                                    onImportSuccess={() => {
                                                                        this.getTeacherProject()
                                                                    }}
                                                                />
                                                            </div>
                                                        </div> : ""}
                                                        <div className={`conteTable ${canEdit ? '' : 'cannotEdit'}`}>
                                                            <TableCmp data={columnsDSource}
                                                                      columns={teacherProjectVoColumns}
                                                                      pageSize={pageSizeD}
                                                                      pageNum={pageD} total={totalD}
                                                                      changePage={this.changePage}
                                                                      pageSizeChange={this.onShowSizeChange1_4_4}
                                                            />
                                                            <div className='addSupportingMaterials'
                                                                 style={{marginTop: columnsDSource?.length > 0 ? '-66px' : '-5px'}}>
                                                                <UploaderComponent
                                                                    editEnable={canEdit}
                                                                    uploadTitle="添加佐证材料"
                                                                    targetId={teacherProjectVO?.id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* // 1.4.4 end */}
                                                {/* // 1.4.5 start */}
                                                <> {
                                                    this.state.medalData?.id ?
                                                        <ModalFrame canEdit={canEdit} data={this.state.medalData}
                                                                    addText="添加获奖教师" modalTitle="获奖教师"
                                                                    schoolEduCode={this.state.schoolInfo.schoolEduCode}>
                                                            {/* <Button onClick={this.onChilderClick}>测试children的点击事件</Button> */}
                                                        </ModalFrame> : null
                                                }
                                                </>

                                                {/* // 1.5.4 end */}
                                                {canEdit ? <div className="bottom-but">
                                                    <Button className="but-li" onClick={() => this.previous()}>
                                                        上一页
                                                    </Button>
                                                    <Button className="but-li" type="primary"
                                                            onClick={() => this.next()}>
                                                        下一页
                                                    </Button>
                                                </div> : ""}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        {/*添加培训项目*/}
                        <Modal
                            title={partakeTrainId ? "编辑培训项目" : "添加培训项目"}
                            visible={addTeacherMaterialModal}
                            destroyOnClose={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            width={660}
                            wrapClassName="wappop_up"
                            className="add_supporting add_supporting-jsfz"
                            footer={
                                <div className='addModalBtn'>
                                    <Button className='quxiao' onClick={() => this.setState({
                                        addTeacherMaterialModal: false,
                                        train: null
                                    })}>取 消</Button>
                                    <Button onClick={this.columnsATiJiao} type='primary'>提交</Button>
                                    {train ? '' :
                                        <Button onClick={this.columnsATiJiaoAndAdd} type='primary'>提交并继续添加</Button>
                                    }
                                </div>
                            }
                        >
                            <AddTeacherMaterial data={train} wrappedComponentRef={form => {
                                console.log(form, 'form')
                                this.AddTeacherMaterial = form;
                            }}/>
                        </Modal>

                        {/*添加参与开发教师*/}
                        <Modal
                            title={partakeCourseId ? "编辑参与开发教师" : "添加参与开发教师"}
                            visible={addTeaStuMaterialModal}
                            destroyOnClose={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            wrapClassName="wappop_up"
                            width={660}
                            className="add_supporting add_supporting-jsfz"
                            footer={
                                <div className='addModalBtn'>
                                    <Button className='quxiao'
                                            onClick={() => this.setState({addTeaStuMaterialModal: false})}>取 消</Button>
                                    <Button onClick={this.columnsBTiJiao} type='primary'>提交</Button>
                                    {course ? '' :
                                        <Button onClick={this.columnsBTiJiaoAndAdd} type='primary'>提交并继续添加</Button>
                                    }
                                </div>
                            }
                        >
                            <AddTeaStuMaterial majorList={majorList} data={course} wrappedComponentRef={form => {
                                this.AddTeaStuMaterial = form;
                            }}/>
                        </Modal>

                        {/*添加带头人*/}
                        <Modal
                            // title={partakePlanId ? "编辑人员" : "添加人员"}
                            title={partakeLeaderId ? "编辑人员" : "添加人员"}
                            visible={addTeacherLeaderModal}
                            destroyOnClose={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            wrapClassName="wappop_up"
                            className="add_stuend add_supporting add_supporting-jsfz"
                            width={660}
                            footer={
                                <div className='addModalBtn'>
                                    <Button className='quxiao'
                                            onClick={() => this.setState({addTeacherLeaderModal: false})}>取 消</Button>
                                    <Button onClick={this.columnsCTiJiao} type='primary'>提交</Button>
                                    {leader ? '' :
                                        <Button onClick={this.columnsCTiJiaoAndAdd} type='primary'>提交并继续添加</Button>
                                    }
                                </div>
                            }
                        >
                            <AddTeacherLeader data={leader} wrappedComponentRef={form => {
                                this.AddTeacherLeader = form;
                            }}/>
                        </Modal>

                        <FileLook openUrl={openUrl} isShow={isShow} dismiss={this.dismiss}></FileLook>

                        {/*顶部star*/}
                        <Footer/>


                        {/* 1.4.4 添加课程 */}
                        {/*新增证明材料-教师-学生*/}
                        <Modal
                            title={teacherProjectRecord && teacherProjectRecord.id ? "编辑虚拟仿真课题" : "添加虚拟仿真课题"}
                            visible={teacherProjectVisible}
                            destroyOnClose={true}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel.bind(this, 'project')}
                            wrapClassName="wappop_up"
                            width={660}
                            className="add_supporting add_supporting-jsfz"
                            footer={
                                <div className='addModalBtn'>
                                    <Button className='quxiao'
                                            onClick={() => this.handleCancel('project')}>取 消</Button>
                                    <Button onClick={this.teacherProjectCommitAndAdd.bind(this, 'addOnly')}
                                            type='primary'>提交</Button>
                                    {teacherProjectRecord ? '' :
                                        <Button onClick={this.teacherProjectCommitAndAdd.bind(this)}
                                                type='primary'>提交并继续添加</Button>
                                    }
                                </div>
                            }
                        >
                            <AddTeacherProject data={teacherProjectRecord}
                                               wrappedComponentRef={form => {
                                                   this.AddTeaProject = form;
                                               }}
                            />
                        </Modal>
                        {/* <ModalCmp show={teachProjectVisible}
                    title="添加课程"
                    data={teacherProjectRecord}
                    formItemData={[
                        {lable:'id', name:'id', type:'text', hidden:true},
                        {lable:'课程名称', name:'projectName', type:'text'},
                        {lable:'课题级别', name:'projectLevel', type:'text'},
                        {lable:'课题来源', name:'projectSource', type:'text'},
                        {lable:'是否为虚拟仿真实训教学模式研究', name:'isResearch', type:'radio'},
                        {lable:'课题主持人', name:'projectPresenter', type:'text'},
                        {lable:'立项日期', name:'projectStartTime', type:'date'},
                        {lable:'结题日期', name:'projectEndTime', type:'date'},
                    ]} buttons={[
                        {lable:'取消', type:'', onClick:this.cancelBtn},
                        {lable:'提交', type:'primary', onClick:this.saveBtn},
                        {lable:'提交并添加', type:'primary', onClick:this.saveBtnAndAdd},
                    ]}/> */}
                    </div>
                )
            }
        }
    )
)
