import React, {Component} from 'react'
import {Input, Select, Form, Button, message, DatePicker, Tooltip, Spin,InputNumber} from "antd";
import {uploadFile} from "../../api/uploadFile";
import moment from "moment";
import {Upicon, Upicondele, Upiconwc} from "../iconSvg";
import {disabledDate} from "../../until/date";

const {TextArea} = Input;

class Communicatee extends React.Component {
    state = {}

    componentDidMount() {
        let {data} = this.props;
        console.log('data', data)
        if (data) {
            let upFile = {
                originName: data.resourceName,
                fileId: data.fileId,
                fileSuffix: data.resourceSuffix,
                fileSize: data.fileSize ? (data.fileSize / 1024).toFixed(2) : null
            }
            this.setState({
                file: data.fileId ? upFile : null,
            })
            this.props.form.setFieldsValue({
                personnelName: data.personnelName,
                personnelTitle: data.personnelTitle,
                exchangeTime: moment(data.exchangeTime),
                exchangeContent: data.exchangeContent,
                fileId: data.fileId,
                personnelNum:data.personnelNum,
            })
        }
    }

    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = {originName: list[0].name}
        this.setState({
            file: upFile
        })
        let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === "") {
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag) {
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null,
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName: res.originFileName,
                        fileId: res.fpName,
                        fileSuffix: res.mediaType,
                        resourceId: res.id,
                        fileSize: (res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //删除
    deleteFile = () => {
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        let {file} = this.state
        return (
            <>
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>交流对象</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('personnelName', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写交流对象`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请填写交流对象' maxLength={30} suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`personnelName`) ? this.props.form.getFieldValue(`personnelName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>头衔称号</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('personnelTitle', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写头衔称号`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input placeholder='请填写头衔称号' maxLength={30} suffix={<span className='len'>
                                                                    {this.props.form.getFieldValue(`personnelTitle`) ? this.props.form.getFieldValue(`personnelTitle`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>交流人数</span>
                        <div className={'input-wrap'}>
                        {
                            getFieldDecorator('personnelNum', {
                                initialValue: null,
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入交流人数'
                                    },
                                ]
                            })(
                                <InputNumber min={0}  maxLength={9} placeholder='请输入正整数' style={{ width: 330 }} />
                            )
                        }
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>交流内容</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('exchangeContent', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写交流内容`,
                                    },
                                    {
                                        max: 100,
                                        message: "文本过长，最多100字"
                                    },
                                ],
                            })(
                                <TextArea className="TextArea-pic" rows={3} maxLength={100} placeholder={'请填写交流内容'}/>
                            )}
                            <span className='textarea_len'>
                                {this.props.form.getFieldValue(`exchangeContent`) ? this.props.form.getFieldValue(`exchangeContent`).length : 0}/100
                            </span>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>交流时间</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('exchangeTime', {
                                initialValue: '',
                                rules: [
                                    {
                                        required: true,
                                        message: `请输入交流时间`,
                                    }
                                ],
                            })(
                                <DatePicker
                                    disabledDate={disabledDate}
                                    placeholder={'请选择交流时间'}
                                    showTime={{format: 'HH:mm'}}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item className="li_formup">
                        <span className={'label'}><span className='red'>*</span>佐证材料</span>
                        {getFieldDecorator('fileId', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请选择佐证材料`,
                                },
                            ],
                        })(
                            <div className="up_button_one">
                                <div className="span_right">
                                    <label htmlFor="file" className="inputlabelBox">
                                        <span><Upicon/>上传资料</span>
                                    </label>
                                    <input
                                        type="file"
                                        accept="image"
                                        onChange={this.multipartUpload}
                                        onClick={(event) => {
                                            event.target.value = null
                                        }}
                                        name="myfile"
                                        id="file"
                                        style={{display: "none"}}
                                    />
                                    <span className={'tip'}>
                                        支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                    </span>
                                </div>
                                <ul className="bottom_tit">
                                    {/*上传中*/}
                                    {file ? <Tooltip placement="topLeft" title={file.originName}>
                                        <li className="title_de">{file.fileId ? <Upiconwc/> : <Spin/>}<span
                                            className="tie_span">{file.originName}</span><i className="Upicona"
                                                                                            onClick={this.deleteFile}><Upicondele/></i>
                                        </li>
                                    </Tooltip> : null}
                                    {/*已完成*/}
                                    {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                                </ul>
                            </div>
                        )}
                    </Form.Item>
                </Form>
            </>
        )
    }
}


Communicatee = Form.create()(Communicatee);
export default Communicatee;
