// 课程共享

import { post } from '../../until/axios_instans';

// 获取课程共享
export function courseSharingList(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/courseSharingList', data);
}
// 新增共享机构
export function addSharingOrgan(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/addSharingOrgan', data);
}
// 修改共享机构
export function updateSharingOrgan(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/updateSharingOrgan', data);
}
// 获取共享机构-课程
export function getSharingOrganCourse(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/getSharingOrganCourse', data);
}
// 获取共享机构-企业
export function getSharingOrganEnterprise(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/getSharingOrganEnterprise', data);
}
// 删除共享机构
export function deleteSharingOrgan(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/deleteSharingOrgan', data);
}
// 导入共享机构
export function importSharingOrgan(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/importSharingOrgan', data);
}
// 新增资源共享
export function addSharingResources(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/addSharingResources', data);
}
// 修改资源共享
export function updateSharingResources(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/updateSharingResources', data);
}
// 获取资源共享
export function getSharingResources(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/getSharingResources', data);
}
// 删除资源共享
export function deleteSharingResources(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/deleteSharingResources', data);
}
// 导入资源共享
export function importSharingResources(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/importSharingResources', data);
}
// 新增总人数
export function addNum(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/addNum', data);
}
// 修改总人数
export function updateNum(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/updateNum', data);
}
// 获取总人数
export function getNum(data) {
    return post(window.apiShixun+'/api/v1/courseSharing/getNum', data);
}
