import React, { Component } from "react";
import { Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Radio, Row, Spin, Table, Tooltip } from 'antd';
import { Professionalicon } from "@@/iconSvg";
import ImportComponent from "@@/importComponent/ImportComponent";
import Forms from './Forms';
import Cookies from "js-cookie";
import "./styles.scss";
import { getMajor, deleteMajor, getSharingMajorList, updateMajor, addMajor } from "../../../../api/sharing";
import axios from "axios";
import { getCanEdit, getRoleType, getSecondTargetList, getTargetId, RoleType, getUserInfo } from "../../../../until/cookie";
// import Professional from "../../components/addprofessional";
import Professional from "../../../../../src/components/addprofessional"

export default class Index extends Component {
  state = {
    total: 0,
    pageSize: 0,
    current: 1,
    data: [],
    modalVisible: false,
    planFactorContent: 0,
    planTotal: 0,
    editObj: {},
    getTargetId: getSecondTargetList("platform_talenttraining")[0], //2.1.1
    schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
    majorList: [],
    majorId: [],
    major: [],
    majorBaseNum:0,
    pageNum: 1,
  }

  componentDidMount() {
    this.initGetList()
    this.getSharingMajor()
    console.log(this.state.schoolEduCode);
  }
  componentWillUpdate() {
    console.log('this.prop211s', this.props)

  }
  // 请求数据
  initGetList = async (pageNum = 1, pageSize = 10) => {
    const params = {
      pageNum,
      pageSize,
      targetId: this.state.getTargetId,
      schoolEduCode: this.state.schoolEduCode
    }
    getMajor(params).then(res => {
      console.log(res);
      if (res) {
        const { pageNum, pageSize, total, data } = res.majorVoPageData;
        data.map((element, i) => element.index = (pageNum - 1) * pageSize + (i + 1));
        console.log('res请求数据', res.majorVoPageData)
        this.setState({
          pageNum,
          pageSize,
          total,
          data,
          majorBaseNum: res.majorBaseNum,
          totalNum: res.totalNum
        });
      }
    })
  }
  // updateMajor = (record) => {
  //   this.setState({
  //       // addPlatforMsgModal: true,
  //       majorId: record.id,
  //       major: record
  //   })
  // }

  getSharingMajor = () => {
    getSharingMajorList({ stageId: 2 }).then((res) => {
      if (res) {
        this.setState({
          majorList: res,
        });
      }
    });
  }
  // 添加单条数据
  onInsertData = (e) => {
    this.setState({ modalVisible: true, editObj: {},major:{} });
    // this.updateMajor(e)

  };

  // 修改单条数据
  onUpdateRecord = editObj => this.setState({ editObj,major:editObj }, () => {
    this.setState({
      modalVisible: true
    })
  });


  // 删除单条数据
  onDeleteRecord = (e) => {
    // console.log(e)
    const { getTargetId } = this.state;
    deleteMajor({ id: e.id, targetId: getTargetId }).then(res => {
      if (res) {
        if (res.code == 0) {
          message.success('成功');
          this.initGetList(1, this.state.pageSize);
        }
      }
    })
  };

  // 提交表单
  onSubmitForm = (loop) => {
    console.log(this.state.editObj);
    if (this.state.editObj?.id) {
      this.Professional.props.form.validateFields((err, value) => {
        console.log('value2.1.1', value)
        if (!err) {
          let data = {
            id: this.state.editObj.id,
            isBase: value.isBase,
            majorOne:value.majorName[0],
            majorTwo:value.majorName[1],
            majorCode:value.majorName[2],
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.getTargetId
          }
          console.log('data', data)
          updateMajor(data).then(res => {
            if (res) {
              message.success("修改成功")
              this.setState({
                modalVisible: false,
                major: ''
              }, () => {
                this.Professional.props.form.resetFields()
                this.initGetList(this.state.pageNum, this.state.pageSize)
              })
            }
          })
        }
      })
    } else {
      this.Professional.props.form.validateFields((err, value) => {
        console.log('value2.1.1', value)
        if (!err) {
          let data = {
            // id: this.state.editObj.id,
            isBase: value.isBase,
            majorName:value.majorName[0],
            majorOne:value.majorName[0],
            majorTwo:value.majorName[1],
            majorCode:value.majorName[2],
            schoolEduCode: this.state.schoolEduCode,
            targetId: this.state.getTargetId
          }
          console.log('data', data)
          addMajor(data).then(res => {
            if (res) {
              message.success("添加成功")
              if ( !loop ) {
                this.setState({
                  modalVisible: false,
                  major: ''
                }, () => {
                  this.Professional.props.form.resetFields()
                  this.initGetList(1)
                })
              } else {
                this.setState({
                  // modalVisible: false,
                  major: ''
                }, () => {
                  this.Professional.props.form.resetFields()
                  this.initGetList(1)
                })
              }
              
            }
          })
        }
      })
    }

    // if (this.state.majorId) {
    //   this.modalForm.props.form.validateFields((err, value) => {
    //     console.log('修改  ：：： ', value)
    //     if (!err) {

    //     }
    //   })
    // } else {
    //   this.modalForm.props.form.validateFields((err, value) => {
    //     console.log('专业新增  ：：： ', value)
    //     if (!err) {
    //     }
    //   })
    // }
  }

  // 切换页码
  onPageChange = (current, pageSize) => {
    this.setState({ current, pageSize }, () => { this.initGetList(current, pageSize); });
  };

  // 切换页码
  onShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize }, () => { this.initGetList(1, pageSize); });
  };
  updatePlanNum = (value) => {
    console.log('value', value)
    // let data = {
    //     id: this.state.trainPlanInfo.factorId,
    //     factorContent: Number(value)
    // }
    // updateFactor(data).then(res => {
    //     if (res) {
    //         // message.success("操作成功")

    //     }
    // })
  }
  planGiaBian = (value) => {
    this.setState({
      planFactorContent: Number(value)
    })
  }
  render() {
    const { pageNum,getTargetId, total, pageSize, data, editObj, planTotal, planFactorContent, modalVisible, majorList, major, majorId,majorBaseNum } = this.state;
    const { canEdit } = this.props;


    let columns = [
      {
        title: "序号",
        dataIndex: "index",
        key: "index",
        align: "center",
        width: 80,
        
      },
      {
        title: "专业名称",
        dataIndex: "majorName",
        key: "majorName",
        align: "center",
      },
      {
        title: "专业代码",
        dataIndex: "majorCode",
        key: "majorCode",
        align: "center",

      },
      {
        title: "是否为虚拟仿真实训服务的专业",
        dataIndex: "isBase",
        key: "isBase",
        align: "center",
        render: (text, record) =>
          text === 0 ? <span>否</span> : <span>是</span>,
      }
    ];

    if (canEdit) {
      columns.push({
        title: '操作',
        align: 'center',
        width: 200,
        dataIndex: 'oper',
        key: 'oper',
        render: (_, record, index) => <div className='render-span vertical_j oper'>
          <div>
            <span className="cz" onClick={() => this.onUpdateRecord(record, index)}>编辑</span>
            <span className="cz" onClick={() => this.onDeleteRecord(record)}>删除</span>
          </div>
        </div>
      })
    }

    return (
      <div className="effective_1_5_5_wrap">
        <ul className={'tongjiul1'}>
          <li>
            <span className={'span'}>专业总数</span>
            <div className={'btn'}>{total ? total : 0}</div>
            {/* <div className={' btn1'}>
              <InputNumber min={0} className='input_bg' disabled={!canEdit}
                onBlur={e => this.updatePlanNum(e.target.value)}
                onChange={e => this.planGiaBian(e)}
                value={planFactorContent ? planFactorContent : 0}
                placeholder={'数值填充'} />
            </div> */}
          </li>
          <li>
            <span className={'span '}>虚拟仿真实训服务的专业数</span>
            <div className={'btn'}>{majorBaseNum ? majorBaseNum : 0}</div>
          </li>
          <li>
            <span className={'span'}>占比</span>
            {/* 虚拟仿真实训服务的专业数/专业总数× 100% */}
            <div  className={'btn'}  
              style={((majorBaseNum/total) * 100).toFixed(2) < 30 ?{borderColor:'#d75746'}:{borderColor:'#d9d9d9'}}
            >     
                {((majorBaseNum / total) * 100).toFixed(2) ==NaN || (!majorBaseNum && !total) ? 0: ((majorBaseNum / total) * 100).toFixed(2) }%
            </div>
            {
              ((majorBaseNum / total) * 100).toFixed(2) <30 &&(
                <span className="span span1" style={{color:'#d75746'}}>*该项指标需达30%</span>
              )
            }
          </li>
        </ul>

        <div className="insert_upload_button">
          {canEdit ? (
            <div className="conteBtnDiv conteBtnDivtadd" onClick={this.onInsertData}>
              <Professionalicon />
              <span className="conteBtnDivSpan">添加专业</span>
            </div>
          ) : (
            ""
          )}
          {
            canEdit ? (
            <ImportComponent
              targetId={getTargetId}
              targetMark="2.1.1"
              downloadName="虚拟仿真实训服务专业的占比"
              onImportSuccess={() => {
                // todo 刷新此项即可
                // alert("成功")
                this.initGetList(1);
              }}
            />
            ) :("")
          }
          
        </div>

        <div style={{ marginBottom: "50px",marginRight:'70px' }}>
          <div className="conteTable conteTable214">
            <Table
              columns={columns}
              dataSource={data}
              bordered={true}
              locale={{
                emptyText: (
                  <>
                    <div className="Empty-content">
                      <div className="text">
                        <img src={window.$$imgSrcLcl + "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"} />
                        <span>暂无数据</span>
                      </div>
                    </div>
                  </>
                ),
              }}
              pagination={{
                total: total,
                pageSize: pageSize,
                current: pageNum,
                onChange: this.onPageChange,
                onShowSizeChange: this.onShowSizeChange,
                pageSizeOptions: ["10", "20", "30", "40"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>



        <Modal
          title={!editObj.id ? '添加专业' : '编辑专业'}

          visible={this.state.modalVisible}
          width={660}
          destroyOnClose={true}
          closable={false}
          maskClosable={false}
          centered={true}
          onCancel={this.handleCancel}
          className="Professional_con"
          wrapClassName="wappop_up"
          footer={
            <div className='addModalBtn'>
              <Button onClick={() => this.setState({ modalVisible: false })}>取 消</Button>
              <Button onClick={()=>{this.onSubmitForm(false)}} type='primary'>提交</Button>
              {
                editObj.id ? '' : <Button onClick={() => {
                  this.onSubmitForm(true)
                }} type='primary'>提交并继续添加</Button>
              }
            </div>
          }
        >
          <Professional majorList={majorList} data={major} wrappedComponentRef={form => {
            this.Professional = form;
          }} />
          {console.log('majorList',majorList)}
          {/* {console.log('majorList',majorList)} */}
        </Modal>
      </div>
    );
  }
}
