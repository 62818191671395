// 添加特色创新
import React, {Component} from 'react';
import {Button, Form, Input, message, Radio, Spin, Tooltip} from 'antd';
import moment from "moment";
import {uploadFile} from "../../../api/uploadFile";
import "./index.scss";
import {Upicon, Upicondele, Upiconwc} from "../../../components/iconSvg";
const { TextArea } = Input;

class AddFormtask extends Component {
    state = {
        updateDetail:this.props.updateDetail,
    };

    componentDidMount() {
        let upFile = {
            originName:this.props.updateDetail.resourceName,
            fileId:this.props.updateDetail.fileId,
            fileSuffix:this.props.updateDetail.resourceSuffix,
            fileSize:(this.props.updateDetail.resourceSize / 1024).toFixed(2)
        }
        this.setState({
            file: this.props.updateDetail.fileId ? upFile : null,
        })
        console.log("file",this.props.file)
        this.props.form.setFieldsValue({
            taskName: this.props.updateDetail.taskName,
            isComplete: this.props.updateDetail.isComplete,
            overview: this.props.updateDetail.overview,
            fileId:this.props.updateDetail.fileId
        })
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let thisFileName =list[0].name.substring(0,list[0].name.lastIndexOf('.')).trim()
        if (thisFileName === ""){
            message.error('文件名不能为空')
            return
        }
        let flag = false;
        list.forEach((k) => {
            const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen = k.name.length; // 取到文件名长度
            const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
            if (!this.checkFileType(fileType)) {
                message.error("上传的资源格式不符")
                flag = true
            }
        });
        //类型不符停止进行
        if (flag){
            document.getElementById("file").value = '';
            return
        }
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        let {file} = this.state
        if (!v) return;
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 20971520) {
                this.setState({
                    file: null
                })
                return this.mesWarning('文件大于20MB');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //返回上传File
    getFile = () => {
        return this.state.file
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、doc、docx、xls、xlsx、ppt、pptx、txt、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }
    render() {
        const {getFieldDecorator }=this.props.form
        const {name,file} = this.state;
        return (
            <Form>
                <Form.Item>
                    <span className={'label'}><span className='red'>*</span>任务名称</span>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('taskName', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写任务名称`,
                                },
                                {
                                    max: 30,
                                    message: "文本过长，最多30字"
                                },
                            ],
                        })(
                            <Input placeholder='请输入任务名称' maxLength={30} suffix={<span className='len'>
                            {this.props.form.getFieldValue(`taskName`) ? this.props.form.getFieldValue(`taskName`).length : 0}/30</span>}/>
                        )}
                    </div>
                </Form.Item>
                <Form.Item>
                    <span className={'label'}><span className='red'>*</span>是否完成</span>
                    {getFieldDecorator('isComplete', {
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: '请选择是否完成!'
                            },
                        ],
                    })(<Radio.Group onChange={this.changeType}>
                        <Radio value={0}>否</Radio>
                        <Radio value={1}>是</Radio>
                    </Radio.Group>)}
                </Form.Item>
                <Form.Item className="TextArea_li">
                    <span className={'label'}><span className='red'>*</span>情况说明</span>
                    <div className={'input-wrap'}>
                        {getFieldDecorator('overview', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: `请填写情况说明`,
                                },
                                {
                                    max: 100,
                                    message: "文本过长，最多100字"
                                },
                            ],
                        })(
                            <TextArea className="input"  maxLength={100} placeholder={'请输入情况说明'} autoSize={{ minRows: 3, maxRows: 5 }}/>
                        )}
                        <span className='textarea_len'>
                                        {this.props.form.getFieldValue(`overview`) ? this.props.form.getFieldValue(`overview`).length : 0}/100
                                    </span>
                    </div>
                </Form.Item>
                <Form.Item className="li_formup">
                    <span className={'label'}><span className='red'>*</span>材料证明</span>
                    {getFieldDecorator('fileId', {
                        initialValue: '',
                        rules: [
                            {
                                required: true,
                                message: `请选择佐证材料`,
                            },
                        ],
                    })(
                        <div className="up_button">
                            <div className="span_right">
                                <label htmlFor="file"  className="inputlabelBox">
                                    <span><Upicon/>上传资料</span>
                                </label>
                                <input
                                    type="file"
                                    accept="image"
                                    onClick={(event)=> { event.target.value = null }}
                                    onChange={this.multipartUpload}
                                    name="myfile"
                                    id="file"
                                    style={{ display: "none" }}
                                />
                                <span className={'tip'}>
                                                支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                            </span>
                            </div>
                            <ul className="bottom_tit">
                                {file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}
                                {/*上传中*/}
                                {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Spin /><span className="tie_span">{file.originName}</span><i className="Upicona"  onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}*/}
                                {/*已完成*/}
                                {/*{file? <Tooltip placement="topLeft" title={file.originName}><li className="title_de"><Upiconwc /><span className="tie_span">{file.originName}</span><Upicondele/></li></Tooltip>:null}*/}
                            </ul>
                        </div>
                    )}
                </Form.Item>

            </Form>
        );
    }
}

const addFormtask = Form.create()(AddFormtask);
export default addFormtask;

