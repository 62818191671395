import React, { Component } from "react";
import { Table, Modal, Button, InputNumber, message, Input } from "antd";
import { Professionalicon } from "@@/iconSvg";
import ImportComponent from "@@/importComponent/ImportComponent";
import Forms from './Forms';
import moment from "moment";
import axios from "axios";
import Cookies from "js-cookie";
import UploaderComponent from "@@/uploader_component";
import { formatDate } from "../../../../until/date";
import {
  getMegagame,
  addMegagame,
  deleteMegagame,
  updateMegagame,
  downloadBusTemplate,
  excelBusData
} from "@/api/cultivationTalents"
import {
  selectTargetQuestion
} from '@/api/constructionResults'
import { Daoru, ExcelImport } from "@@/iconSvg";
import md5 from "js-md5"


import "./styles.scss";

export default class platTalent2_1_7 extends Component {
  state = {
    total: 0,
    pageSize: 0,
    current: 1,
    data: [],
    modalVisible: false,

    dataSource2: [],
    trainPlanInfo: [],
    planFactorContent: [],
    planTotal: [],
    trainPlanInfo: [],
    pageSizeone: [],
    featureSelfDataVo: {
      "id": "",
      "resourceVos": [],
      "selfData": "",
      "selfId": "",
      "targetMark": "",
      "targetName": ""
    },

    total: 0,
    pageSize: 0,
    pageNum: 1,
    data: [],
    modalVisible: false,
    schoolInfo: JSON.parse(Cookies.get('schoolInfo')),
    typeList: [],
    editObj: {},
    schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",

  }

  componentDidMount() {
    this.initGetList();
    // this.getDataSource()
  }

  getDataSource = () => {
    const params = this.state.featureSelfDataVo
    params.schoolEduCode = JSON.parse(Cookies.get('schoolInfo')).schoolEduCode || ""
    params.targetId = this.props.getTargetId
    selectTargetQuestion(params).then(resourceVos => {
      console.log("resourceVos", resourceVos)
      //获取原有信息
      const featureSelfDataVo = this.state.featureSelfDataVo
      //获取原有信息
      featureSelfDataVo.resourceVos = resourceVos?.result || []

      // 保存文件id列表并请求后台告知
      this.setState({ featureSelfDataVo })

    })
  }
  // 请求数据
  initGetList = async (pageNum = 1, pageSize = 10) => {
    /**
  * "pageNum": "", 当前页
  * "pageSize": "", 每页记录数
  * "schoolEduCode": "", 学校编码
  * "targetId": ""  指标ID
 */

    const params = {
      id: "3",
      pageNum,
      pageSize,
      "schoolEduCode": JSON.parse(Cookies.get('schoolInfo')).schoolEduCode,
      "targetId": this.props.getTargetId
    }

    const res = await getMegagame(params);
    console.log(res, "res");
    if (res) {
      const { pageNum, pageSize, cmTotal, total, data } = res?.megagameVoPageData;
      const { megagameNum } = res;

      // //时间转换
      // data.forEach((item) => {
      //   item.megagameTime = item.megagameTime.replace(/T/g, ' ')
      //   // item.megagameTime=item.megagameTime
      // })

      data.map((element, i) => element.index = (pageNum - 1) * pageSize + (i + 1));
      this.setState({
        pageNum,
        pageSize,
        cmTotal,
        total,
        data,
        megagameNum
      })
    }
  }

  // 添加单条数据
  onInsertData = () => this.setState({ editObj: {} }, () => this.setState({ modalVisible: true }));

  // 修改单条数据
  onUpdateRecord = editObj => {
    console.log(editObj)
    this.setState({ editObj }, () => this.setState({ modalVisible: true }));
  }

  // 删除单条数据
  onDeleteRecord = async id => {
    // "id": "",
    // "pageNum": 1,
    // "pageSize": 10,
    // "schoolEduCode": "",
    // "targetId": ""
    const params = {
      id,
      "pageNum": 1,
      "pageSize": 10,
      "schoolEduCode": JSON.parse(Cookies.get('schoolInfo')).schoolEduCode,
      "targetId": this.props.getTargetId
    }

    const res = await deleteMegagame(params);

    if (res.code === 0) {
      message.success('删除成功');
      this.initGetList(1, this.state.pageSize);
    }
  };

  // 提交表单
  onSubmitForm = (loop) => {
    /**
     *  "id": "", 主键ID
     *  "situationName": "", 名称
     *  "description": "", 情况说明
     *  "situationType": 0, 情况类型
     *  "schoolEduCode": "", 学校编码
     *  "targetId": ""  指标ID
     * "gradePrize": 0,
     * 
       "certificateName": "",
       "schoolEduCode": "",
       "studentNum": 0,
       "targetId": ""
   */

    const params = {
      schoolEduCode: JSON.parse(Cookies.get('schoolInfo')).schoolEduCode,
      targetId: this.props.getTargetId,
    }

    if (this.state.editObj?.id) {

      this.modalForm.props.form.validateFields(async (err, value) => {
        console.log(value, "valuevaluevalue1")
        if (!err) {
          // const values = { ...params, ...value, id: this.state.editObj?.id,megagameTime: fillingDate }
          // const values = {...params, ...value, id: this.state.editObj?.id}
          let dateTime = moment(formatDate(value.megagameTime))

          const params2 = {
            ...params,
            ...value,
            id: this.state.editObj?.id,
            megagameTime: dateTime
          }


          const res = await updateMegagame(params2);
          if (res.code === 0) {
            message.success('修改成功');
            this.setState({ modalVisible: false });
            this.initGetList(this.state.pageNum, 10);
          }
        }
      })
    } else {
      this.modalForm.props.form.validateFields(async (err, value) => {
        if (!err) {
          console.log(value, "valuevaluevalue")
          const values = { ...params, ...value }
          const res = await addMegagame(values);
          console.log(res, "recordrecordrecord")
          if (res?.code === 0) {
            message.success('新增成功');

            if (loop) {
              this.modalForm.props.form.resetFields()
            } else {
              this.setState({ modalVisible: false });
            }

            this.initGetList(1, this.state.pageSize);

          }
        }
      })
    }
  }
  // 切换页码
  onPageChange = (current, pageSize) => {
    this.setState({ current, pageSize }, () => { this.initGetList(current, pageSize); });
  };

  // 切换页码
  onShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize }, () => { this.initGetList(1, pageSize); });
  };

  state = {

    dataSource2: [],
    trainPlanInfo: [],
    planFactorContent: [],
    planTotal: [],
    trainPlanInfo: [],
    pageSizeone: [],
    featureSelfDataVo: {
      "id": "",
      "resourceVos": [],
      "selfData": "",
      "selfId": "",
      "targetMark": "",
      "targetName": ""
    },
  };
  updatePlanNum = (value) => {
    console.log('value', value)
    let data = {
      id: this.state.trainPlanInfo.factorId,
      factorContent: Number(value)
    }
  }
  handlerUploadFiles = (files) => {
    if (undefined === this.state.featureSelfDataVo) {
      return
    }

    //获取原有信息
    const featureSelfDataVo = this.state.featureSelfDataVo
    //获取原有信息
    featureSelfDataVo.resourceVos = (featureSelfDataVo.resourceVos || []).concat((files || []))

    // 保存文件id列表并请求后台告知
    this.setState({ featureSelfDataVo })


  }
  // 111
  handlerDeleteFile = (index) => {
    //获取原有信息
    const featureSelfDataVo = this.state.featureSelfDataVo
    if (undefined === featureSelfDataVo ||
      undefined === featureSelfDataVo.resourceVos ||
      featureSelfDataVo.resourceVos.length <= 1) {
      // return
    }

    // 删除下表下的元素
    featureSelfDataVo.resourceVos.splice(index, 1)

    // 保存文件id列表并请求后台告知
    this.setState({ featureSelfDataVo })
  }
  updatePlan = (record) => {
    this.setState({
      showPersonnel: true,
      planId: record.id,
      plan: record
    })
  }
  deletePlan = (record) => {
    let data = {
      id: record.id,
      schoolEduCode: this.state.schoolInfo.schoolEduCode,
      targetId: this.state.trainPlanInfo.id
    }
    // deleteTrainPlan(data).then(res => {
    //   if (res) {
    //     message.success("删除成功")
    //     this.getTrainPlan()
    //   }
    // })
  }
  /**
   *  方案   第二个table
   * */
  // getTrainPlan = () => {
  //   let data = {
  //     schoolEduCode: this.state.schoolInfo.schoolEduCode,
  //     targetId: this.state.trainPlanInfo.id,
  //     pageNum: this.state.pageNumone,
  //     pageSize: this.state.pageSizeone
  //   }
  //   getTrainPlan(data).then(res => {
  //     if (res) {
  //       this.setState({
  //         dataSource2: res.trainPlanVoPageData.data,
  //         planTotal: res.trainPlanVoPageData.total,
  //         planFactorContent: res.factorContent
  //       })
  //     }
  //   })
  // }
  planGiaBian = (value) => {
    this.setState({
      planFactorContent: Number(value)
    })
  }

  //下载文件
  dowLoadFile = (targetMark, targetName) => {
    console.log("targetMark", targetMark, targetName)
    let now = new Date().getTime();
    axios.get(downloadBusTemplate(targetMark), {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        // token: getToken(),

        "z-ts": now,
        "z-sign": md5(now + "")
      },
      responseType: 'blob', // --设置请求数据格式
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${targetName}.xls`);
      link.click();
    })
  }

  // 这里根据传进来的数据来导入文件,例如外部id等
  importFileResources = async (e) => {
    let list = [...e.target.files];
    let thisFileName = list[0].name.substring(0, list[0].name.lastIndexOf('.')).trim()
    if (thisFileName === "") {
      message.error('文件名不能为空')
      return
    }
    let flag = false;
    let checkType = "xls、xlsx";
    list.forEach((k) => {
      const fileNameindex = k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
      const fileNameLen = k.name.length; // 取到文件名长度
      const fileType = k.name.substring(fileNameindex + 1, fileNameLen); // 截
      if (!checkType.includes(fileType)) {
        message.error("上传的资源格式不符")
        flag = true
      }
    });
    //类型不符停止进行
    if (flag) {
      return
    }
    const fromData = new FormData()
    fromData.append('file', list[0])
    fromData.append('targetId', this.props.getTargetId)
    const schoolEduCode = JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : ""
    fromData.append('schoolEduCode', schoolEduCode)
    fromData.append('templateNo', '2.1.7')
    // todo 此接口需要替换成统一的接口
    excelBusData(fromData).then(res => {
      message.success('导入成功')
      // 之后给回调到外部重新刷新当前选项即可(暂时简单的刷新,不做本地局部添加数据处理)
      // this.selectTargetResourceReplace()
      this.initGetList(1, this.state.pageSize);
    })
  }

  render() {
    const { total, pageNum, pageSize, data, modalVisible, editObj, typeList, megagameNum } = this.state;
    const { canEdit, targetId, getTargetId } = this.props;
    const uploadNeedData = (data) => {
      let needArray = []
      let uploadData = data || []
      uploadData.map(item => {
        needArray.push(
          {
            "id": item.resourceId,
            "originFn": item.resourceName,
            "fileId": item.fileId,
            "fileName": item.resourceId + '.' + item.resourceSuffix,
            "fileSize": item.resourceSize,
            "createTime": item.createTime
          })
      })
      return needArray
    }
    console.log(canEdit, 11111111111)
    let columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        align: 'center',
        width: 60,
        // render: (text, record, index) => {
        //   return <span className={'xuhao'}>{item.index}</span>
        // }
      },
      {
        title: '大赛名称',
        dataIndex: 'megagameName',
        key: 'megagameName',
        align: 'center',
        width: 150
      },
      {
        title: '大赛时间',
        dataIndex: 'megagameTime',
        key: 'megagameTime',
        align: 'center',
        width: 150,
        render: (text, record) => {
          return <span>{moment(text).utcOffset(+8).format("YYYY-MM-DD HH:mm")}</span>
        }
      },
      {
        title: '获奖学生姓名',
        dataIndex: 'studentName',
        key: 'studentName',
        align: 'center',
        width: 150
      },
      {
        title: '获奖等级',
        dataIndex: 'gradePrize',
        key: 'gradePrize',
        align: 'center',
        width: 150
      }
      // {
      //   title: "年度在校生计划实训学时",
      //   dataIndex: "hasComplete",
      //   key: "hasComplete",
      //   align: "center",
      //   render: (text, record) =>
      //     text === 0 ? <span>否</span> : <span>是</span>,
      // },
    ];

    if (canEdit) {
      columns.push({
        title: '操作',
        align: 'center',
        width: 200,
        dataIndex: 'oper',
        key: 'oper',
        render: (_, record) => <div className='render-span vertical_j oper'>
          <div>
            <span className="cz" onClick={() => this.onUpdateRecord(record)}>编辑</span>
            <span className="cz" onClick={() => this.onDeleteRecord(record?.id)}>删除</span>
          </div>
        </div>
      })
    }

    return (
      <div className={'cont1'}>
        <div
          className={'titleName'}>2.1.7 学生参加省级及以上虚拟仿真类大赛获奖
        </div>
        <div className="platTalent2_1_7">


          <ul className={'tongjiul1'}>
            <li>
              <span className={'span-auto'}>获奖数量</span>
              <div className={'btn btn1'}>
                <div className={'btn'}>{megagameNum ? megagameNum : 0}</div>

                {/* <InputNumber min={0} className='input_bg'
                  onBlur={e => this.updatePlanNum(e.target.value)}
                  onChange={e => this.planGiaBian(e)}
                  value={megagameNum ? megagameNum : 0}
                  // value={0}
                  disabled={true}
                  // disabled={!canEdit}
                  placeholder={''} /> */}
              </div>
            </li>
          </ul>

          <div className="insert_upload_button">
            {canEdit ? (
              <div className="conteBtnDiv" onClick={this.onInsertData}>
                <Professionalicon />
                <span className="conteBtnDivSpan">添加</span>
              </div>
            ) : (
              ""
            )}
            {
              canEdit ? <div>
                <div className='conteBtnDiv'>
                  <Input id='importFile' type="file" accept="image" onClick={(event) => { event.target.value = null }}
                    onChange={this.importFileResources} /><ExcelImport /><span className='conteBtnDivSpan'>Excel导入</span>
                </div>
                <div className='conteBtnDiv' onClick={() => this.dowLoadFile('2.1.7', '学生参加省级及以上虚拟仿真类大赛获奖')}><Daoru /><span className='conteBtnDivSpan'>下载导入模版</span></div>
              </div> : ""
            }
          </div>

          <div>
            {/* style={{ marginBottom: "100px" }} */}
            <div className="conteTable">
              <Table
                columns={columns}
                // dataSource={targetMegagameVo?.megagameList?.data}
                dataSource={data}
                bordered={true}
                className={"table1"}
                locale={{
                  emptyText: (
                    <>
                      <div className="Empty-content">
                        <div className="text">
                          <img src={window.$$imgSrcLcl + "base/cbs/image/10e576f844a6477b9663536f86d95d5d.jpg"} />
                          <span>暂无数据</span>
                        </div>
                      </div>
                    </>
                  ),
                }}
                pagination={{
                  total: total,
                  pageSize: pageSize,
                  onChange: this.onPageChange,
                  onShowSizeChange: this.onShowSizeChange,
                  pageSizeOptions: ["10", "20", "30", "40"],
                  showSizeChanger: true,
                  current: pageNum
                }}
              />
            </div>

            <UploaderComponent
              targetId={getTargetId}
              editEnable={canEdit}
              uploadTitle="添加佐证材料"
            // fileList={uploadNeedData(this.state.featureSelfDataVo.resourceVos)}
            // onDelete={this.handlerDeleteFile}
            // onGetFiles={this.handlerUploadFiles} 

            />

          </div>

          <Modal
            title={!editObj?.id ? '添加学生大赛' : '编辑学生大赛'}
            visible={this.state.modalVisible}
            width={660}
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            centered={true}
            onCancel={() => { }}
            className="Professional_con"
            wrapClassName="wappop_up"
            footer={
              <div className='addModalBtn'>
                <Button onClick={() => { this.setState({ modalVisible: false }) }}>取 消</Button>
                <Button onClick={this.onSubmitForm.bind(this, false)} type='primary'>提交</Button>
                {
                  editObj?.id ? '' : <Button onClick={() => this.onSubmitForm(true)} type='primary'>提交并继续添加</Button>
                }
              </div>
            }
          >
            <Forms
              record={editObj}
              wrappedComponentRef={form => { this.modalForm = form }}
            />
          </Modal>
        </div>


      </div>


    );
  }
}
