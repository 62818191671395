import React, { Component } from 'react'
import { Button, Col, Drawer, Form, Input, InputNumber, message, Modal, Row, Spin, Table, Tooltip, } from 'antd';
import {
    addResources,
    checkPlatformService,
    deleteServiceResources,
    importPlatformService,
    saveSatisfaction,
    selectAllResourcesService,
    selectSatisfaction,
    selectServiceResourcesById,
    updateFactor,
    updateServiceResources
} from '@/api/target'
import {
    selectTargetQuestion
} from '@/api/constructionResults'
import Cookies from "js-cookie";
import UploaderComponent from "@@/uploader_component";
import './index.scss'

export default class PlatTalent2_1_8 extends Component {

    state = {

        addEvidence: false,  //上传满意度报告
        satisfactionInfo: {
            fileIdList: [],
            id: 0,
            schoolEduCode: "",
            // 社会人员满意度
            socDegree: 0,
            // 学生满意度
            stuDegree: 0,
            targetId: "",
            taskId: "",
            // 教师满意度
            teaDegree: 0,
            files: []
        },
        featureSelfDataVo: {
            "id": "",
            "resourceVos": [],
            "selfData": "",
            "selfId": "",
            "targetMark": "",
            "targetName": ""
        },
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
    }
    componentDidMount() {
        this.selectSatisfaction()
    }
    // 获取满意度
    selectSatisfaction = () => {
        if (JSON.parse(Cookies.get('schoolInfo'))) {
            let params = {}   
            params.schoolEduCode = JSON.parse(Cookies.get('schoolInfo')).schoolEduCode
            params.targetId = this.props.getTargetId
            selectSatisfaction(params.schoolEduCode, params.targetId).then(satisfactionInfo => {
                console.log("获取满意度satisfactionInfo", satisfactionInfo)
                if (undefined === satisfactionInfo) {
                    return
                }
                const files = satisfactionInfo.files || [];
                satisfactionInfo.fileIdList = files.map(file => file.id)
                this.setState({
                    satisfactionInfo
                })
            });
        }
    }
    getDataSource = () => {
        const params = this.state.satisfactionInfo
        params.schoolEduCode = this.state.schoolEduCode
        params.targetId = this.props.getTargetId
        selectTargetQuestion(params).then(resourceVos => {
            console.log("resourceVos", resourceVos)
            //获取原有信息
            const featureSelfDataVo = this.state.featureSelfDataVo
            //获取原有信息
            featureSelfDataVo.resourceVos = resourceVos?.result || []

            // 保存文件id列表并请求后台告知
            this.setState({ featureSelfDataVo })

        })
    }
    handlerUploadFiles = (files) => {
        //获取原有信息
        const satisfactionInfo = this.state.satisfactionInfo

        // 文件列表
        const finalFiles = satisfactionInfo.files ?? []
        // 添加插入的文件列表
        finalFiles.splice(0, 0, ...files)
        // 获取文件id
        satisfactionInfo.fileIdList = finalFiles.map(file => file.id)
        satisfactionInfo.files = finalFiles

        // 保存文件id列表并请求后台告知
        this.setState({ satisfactionInfo }, () => {
            if (this.state.satisfactionInfo) {
                const params = this.state.satisfactionInfo
                params.schoolEduCode = this.state.schoolEduCode
                params.targetId = this.props.getTargetId
                saveSatisfaction(params).then(res => {
                    if (this.state.schoolEduCode) {
                        selectSatisfaction(this.state.schoolEduCode, this.props.getTargetId).then(satisfactionInfo => {
                            this.setState({
                                satisfactionInfo
                            }, () => {
                                message.success("添加成功")
                            })
                        });
                    }
                })
            }
        })
    }
    handlerDeleteFile = (index) => {
        //获取原有信息
        const satisfactionInfo = this.state.satisfactionInfo

        // 文件列表
        const finalFiles = satisfactionInfo.files ?? []
        // 删除下表下的元素
        finalFiles.splice(index, 1)
        // 获取文件id
        satisfactionInfo.fileIdList = finalFiles.map(file => file.id)
        satisfactionInfo.files = finalFiles

        // 保存文件id列表并请求后台告知
        this.setState({ satisfactionInfo }, () => {
            if (this.state.satisfactionInfo) {
                const params = this.state.satisfactionInfo
                params.schoolEduCode = this.state.schoolEduCode
                params.targetId = this.props.getTargetId
                saveSatisfaction(params).then(res => {
                    if (this.state.schoolEduCode) {
                        selectSatisfaction(this.state.schoolEduCode, this.props.getTargetId).then(satisfactionInfo => {
                            this.setState({
                                satisfactionInfo
                            }, () => {
                                message.success("删除成功")
                            })
                        });
                    }
                })
            }
        })
    }

    handlerUploadFiles = (files) => {
        if (undefined === this.state.featureSelfDataVo) {
            return
        }

        //获取原有信息
        const featureSelfDataVo = this.state.featureSelfDataVo
        //获取原有信息
        featureSelfDataVo.resourceVos = (featureSelfDataVo.resourceVos || []).concat((files || []))

        // 保存文件id列表并请求后台告知
        this.setState({ featureSelfDataVo })
    }
    // 111
    handlerDeleteFile = (index) => {
        //获取原有信息
        const featureSelfDataVo = this.state.featureSelfDataVo
        if (undefined === featureSelfDataVo ||
            undefined === featureSelfDataVo.resourceVos ||
            featureSelfDataVo.resourceVos.length <= 1) {
            // return
        }

        // 删除下表下的元素
        featureSelfDataVo.resourceVos.splice(index, 1)

        // 保存文件id列表并请求后台告知
        this.setState({ featureSelfDataVo })
    }
    // 更新满意度
    saveSatisfactionInfo = (v) => {
        let value = v.replace("%", "");
        console.log("value=>", value)

        // const params = this.state.satisfactionInfo
        // params.schoolEduCode = this.state.schoolEduCode
        // params.targetId = this.props.getTargetId
        // saveSatisfaction(params).then(res => {
        //     message.success('修改成功', 1.5);
        // })
        // return 

        if (this.state.satisfactionInfo && value) {

            const reg = /^\d+$|^\d*\.\d+$/g;
            const startValue = value?.split('.')
            const regEmpty = /^\s*$/g;
            if (value && !reg.test(value) || !startValue[0]) {
                message.warn('只能输入数字')
                return
            } else if (value && regEmpty.test(value)) {
                message.warn('名称不能为空')
                return
            } else {
                const params = this.state.satisfactionInfo
                params.schoolEduCode = this.state.schoolEduCode
                params.targetId = this.props.getTargetId
                saveSatisfaction(params).then(res => {
                    message.success('修改成功', 1.5);
                })
            }
        } else {
            message.warn('请输入数字', 1.5)
        }
    }

    onChangeTeaDegree = (value) => {
        console.log(value)
        // if (e) {
        //     let value = null
        //     String(e).indexOf("%")>-1? value = e.replace("%", ""):value = e
        //     const reg = /^\d+$|^\d*\.\d+$/g;
        //     const startValue = value?.split('.')
        //     const regEmpty = /^\s*$/g;
        //     if (value && !reg.test(value) || !startValue[0]) {
        //         message.warn('只能输入数字')
        //         return
        //     } else if (value && regEmpty.test(value)) {
        //         message.warn('名称不能为空')
        //         return
        //     } else {
        //         const satisfactionInfo = this.state.satisfactionInfo
        //         satisfactionInfo.teaDegree = value +"%"
        //         this.setState({ satisfactionInfo })
        //     }
        // }

        const satisfactionInfo = this.state.satisfactionInfo
        satisfactionInfo.teaDegree = value
        this.setState({ satisfactionInfo })


    }
    onChangeStuDegree = (value) => {
        // if (e) {
        //     let value = null
        //     String(e).indexOf("%")>-1? value = e.replace("%", ""):value = e
        //     const reg = /^\d+$|^\d*\.\d+$/g;
        //     const startValue = value?.split('.')
        //     const regEmpty = /^\s*$/g;
        //     if (value && !reg.test(value) || !startValue[0]) {
        //         message.warn('只能输入数字')
        //         return
        //     } else if (value && regEmpty.test(value)) {
        //         message.warn('名称不能为空')
        //         return
        //     } else {
        //         const satisfactionInfo = this.state.satisfactionInfo
        //         satisfactionInfo.stuDegree = value+"%"
        //         this.setState({ satisfactionInfo })
        //     }
        // }

        const satisfactionInfo = this.state.satisfactionInfo
        satisfactionInfo.stuDegree = value
        this.setState({ satisfactionInfo })

    }
    uploadNeedData = (data) => {
        let needArray = []
        let uploadData = data || [];
        console.log(uploadData, "uploadData")
        uploadData.length > 1 && uploadData.map(item => {
            needArray.push(
                {
                    "id": item.resourceId,
                    "originFn": item.resourceName,
                    "fileId": item.fileId,
                    "fileName": item.resourceId + '.' + item.resourceSuffix,
                    "fileSize": item.resourceSize,
                    "createTime": item.createTime
                })
        })
        return needArray
    }

    render() {
        const { addEvidence, satisfactionInfo } = this.state
        const { canEdit,getTargetId } = this.props;

        return (
            <div className={'cont1'}>
                <div className={'titleName'}>2.1.8 教师和学生对虚拟仿真实训的满意度</div>
                <ul className={'tongjiul'}>
                    <li>
                        <span className={'span-auto'}>教师满意度</span>
                        <span className={'btn btn1 btn-num'}>


                            <InputNumber min={0}
                                // value={this.state.resourcesPeriod2}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                // parser={value => /^\d+$/.test(value)?value:0}
                                max={100}
                                disabled={!canEdit}
                                value={this.state.satisfactionInfo.teaDegree}
                                onChange={this.onChangeTeaDegree}
                                placeholder={'数值填充'}
                                onBlur={e => this.saveSatisfactionInfo(e.target.value)} />


                        </span>
                    </li>
                    <li>
                        <span className={'span-auto'}>学生满意度</span>
                        <span className={'btn btn1 btn-num'}>
                            {/* <InputNumber min={0}
                            // value={this.state.resourcesPeriod2}
                            //  formatter={value => `${value}%`}
                            //  max={100}
                            //  disabled={!canEdit}
                            //  value={this.state.satisfactionInfo.stuDegree}
                            //  onChange={this.onChangeStuDegree}
                            //  placeholder={'数值填充'}
                            //  onBlur={e => this.saveSatisfactionInfo(e.target.value)}

                            /> */}
                            <InputNumber min={0}
                                // value={this.state.resourcesPeriod2}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                // parser={value => /^\d+$/.test(value)?value:0}
                                max={100}
                                disabled={!canEdit}
                                value={this.state.satisfactionInfo.stuDegree}
                                onChange={this.onChangeStuDegree}
                                placeholder={'数值填充'}
                                onBlur={e => this.saveSatisfactionInfo(e.target.value)} />




                        </span>
                    </li>
                </ul>
                {/* <UploaderComponent
                    editEnable={canEdit}
                    uploadTitle="上传问卷调查"
                    fileList={this.uploadNeedData(this.state.featureSelfDataVo.resourceVos)}
                    onDelete={this.handlerDeleteFile}
                    onGetFiles={this.handlerUploadFiles}
                /> */}
                <UploaderComponent
                    targetId={getTargetId}
                    editEnable={canEdit}
                    uploadTitle="上传问卷调查报告"

                />
            </div>


        )
    }
}
