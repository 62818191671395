import { post } from '../../until/axios_instans';
// 指标

// 获取资金执行总数据
export function selectTargetFund(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/selectTargetFund', data);
}
// 指标-资金执行新增   先删后插
export function saveTargetFundInvest(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/saveTargetFundInvest', data);
}
// 指标-资金执行查询
export function selectTargetFundInvest(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/selectTargetFundInvest', data);
}

// 指标-资金执行-任务-新增
export function addTargetFundCompletion(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/addTargetFundCompletion', data);
}
// 指标-资金执行-任务-查询
export function selectTargetFundCompletion(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/selectTargetFundCompletion', data);
}
// 指标-资金执行-任务-删除
export function deleteTargetFundCompletion(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/deleteTargetFundCompletion', data);
}
// 指标-资金执行-任务-修改
export function updateTargetFundCompletion(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/updateTargetFundCompletion', data);
}
// 导入-指标-资金执行-任务
export function checkImportTargetFundCompletion(data) {
    return post(window.apiShixun+'/api/v1/moneyPerform/checkImportTargetFundCompletion', data);
}



// 获取校本资源总数据
export function selectTargetResource(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/selectTargetResource', data);
}
// 指标-校本资源-资源更新占比新增
export function saveTargetResourceReplace(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/saveTargetResourceReplace', data);
}
// 指标-校本资源-资源更新占比查询
export function selectTargetResourceReplace(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/selectTargetResourceReplace', data);
}
// 指标-校本资源-资源更新占比-删除
export function deleteTargetResourceReplace(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/deleteTargetResourceReplace', data);
}
// 指标-校本资源-资源更新占比-修改
export function updateTargetResourceReplace(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/updateTargetResourceReplace', data);
}
// 导入-指标-校本资源-资源更新占比
export function checkImportTargetResourceReplace(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/checkImportTargetResourceReplace', data);
}



// 指标-校本资源-资源课程占比新增
export function saveTargetResourceCourse(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/saveTargetResourceCourse', data);
}
// 指标-校本资源-资源课程占比查询
export function selectTargetResourceCourse(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/selectTargetResourceCourse', data);
}
// 指标-校本资源-资源课程占比-删除
export function deleteTargetResourceCourse(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/deleteTargetResourceCourse', data);
}
// 指标-校本资源-资源课程占比-修改
export function updateTargetResourceCourse(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/updateTargetResourceCourse', data);
}
// 导入-指标-校本资源-资源课程占比
export function checkImportTargetResourceCourse(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/checkImportTargetResourceCourse', data);
}




// 指标-校本资源-课程占比-新增
export function saveTargetResourceSubject(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/saveTargetResourceSubject', data);
}
// 指标-校本资源-课程占比-查询
export function selectTargetResourceSubject(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/selectTargetResourceSubject', data);
}
// 指标-校本资源-课程占比-删除
export function deleteTargetResourceSubject(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/deleteTargetResourceSubject', data);
}
// 指标-校本资源-课程占比-修改
export function updateTargetResourceSubject(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/updateTargetResourceSubject', data);
}
// 导入-指标-校本资源-课程占比
export function checkImportTargetResourceSubject(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/checkImportTargetResourceSubject', data);
}




// 指标-校本资源-教材占比新增
export function saveTargetResourceBook(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/saveTargetResourceBook', data);
}
// 指标-校本资源-教材占比查询
export function selectTargetResourceBook(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/selectTargetResourceBook', data);
}
// 指标-校本资源-教材占比-删除
export function deleteTargetResourceBook(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/deleteTargetResourceBook', data);
}
// 指标-校本资源-教材占比-修改
export function updateTargetResourceBook(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/updateTargetResourceBook', data);
}
// 导入-指标-校本资源-教材占比
export function checkImportTargetResourceBook(data) {
    return post(window.apiShixun+'/api/v1/schoolResources/checkImportTargetResourceBook', data);
}

 // 导入
 export function excelBusData(data) {
    return post(window.apiShixun + '/api/v1/import/excelBusData', data);
}

// excel 文件导入
export function importFile(data) {
    return post(window.apiShixun+'/api/v1/import/excelBusData', data);
}









