// 填写任务书步骤条
import React, {Component} from 'react';
import {Card, Row, Col} from 'antd';
import "./index.scss";
import {Titleicon, Icondh} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {getTaskProcessList} from "../../../api/process"
import Cookies from "js-cookie";

@withRouter
class Assignment extends Component {
    state = {
        userId: '',
        schoolId: '',
        stepNum: 0,
        childrenStepNum: 0,
        stepList: [],
        parthName: '',
        ifShowChild: true,
        forstCode: 0,
        secondCode: 0,
        categoryId: '',
        categoryIdurl: "",
        ifFefreshLeft: this.props.ifFefreshLefts,
        newtype: '',
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        processList: [],
        processCode: "",
        taskProcessChildrenVoList: [],
        parentCode: this.props.parentCode,
    };

    componentDidMount() {
        this.getTaskProcessList();
    }

    getTaskProcessList = async () => {
        let data = {
            taskId: this.state.taskId,
            schoolEduCode: this.state.schoolEduCode
        }
        await getTaskProcessList(data).then((res) => {
            this.setState({
                processList: res ? res : []
            },);
        });
        //let parentCode = Cookies.get('parentCode')
        const {parentCode} = this.state
        if (parentCode !== null || parentCode !== undefined) {
            this.state.processList.map((item, index) => {
                if (item.processCode === parentCode) {
                    this.setState({taskProcessChildrenVoList: item.taskProcessChildrenVoList})
                    return
                }
            })
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.ifFefreshLeft && nextProps.ifFefreshLeft != this.state.ifFefreshLeft) {
            this.getTaskProcessList(this.state.taskId);
            this.setState({ifFefreshLeft: 1})
        }
    }

    /**
     *  去掉 StepTop的点击
     */
    handleComplete = (item) => {
        // this.setState({
        //     processCode:item.processCode,
        //     taskProcessChildrenVoList:item.taskProcessChildrenVoList,
        //     ifShowChild:false
        // },()=>{
        //     let pathName = ''
        //     if(item.processCode === '1'){
        //         pathName = '/construction_plan'
        //     }else if(item.processCode === '2'){
        //         pathName = '/project_school'
        //     }else if(item.processCode === '3'){
        //         pathName = '/characteristic_creation'
        //     }else if(item.processCode === '4'){
        //         pathName = '/upload_Document'
        //     }else if(item.processCode === '5'){
        //         pathName = '/content_validation'
        //     }
        //     this.props.history.push({
        //         pathname: pathName,
        //     })
        // })
        // Cookies.set('parentCode', item.processCode)
        // this.setState({
        //     ifShowChild:true
        // })
    }
    /**
     *  去掉 StepTop的点击
     */
    handleProcess = (item, code) => {
        // let parentCode = item.parentCode
        // let processCode = item.processCode
        // Cookies.set('parentCode',parentCode)
        // Cookies.set('processCode',processCode)
        // this.setState({
        // },()=>{
        //     console.log("item11111",item)
        //     let pathName = ''
        //     if(code === '2.1'){
        //         pathName = '/project_school'
        //     }else if(code === '2.2'){
        //         pathName = '/status_project'
        //     }else if(code === '2.3'){
        //         pathName = '/xmxxjs_renwushu'
        //     }else if(code === '2.4'){
        //         pathName = '/school_jianshe'
        //     }else if(code === '2.5'){
        //         pathName = '/reform_personnel'
        //     }
        //     this.props.history.push({
        //         pathname: pathName,
        //     })
        // })
    }

    render() {
        const {
            stepList,
            stepNum,
            newtype,
            childrenStepNum,
            ifShowChild,
            categoryId,
            parthName,
            categoryIdurl,
            forstCode,
            secondCode
        } = this.state;
        const {processList, processCode, taskProcessChildrenVoList} = this.state;
        return (
            <div className="Comment-Assignment">
                <div className="step-top-common step-top-common-active">
                    <h3><i><Titleicon/></i>职业教育示范性虚拟仿真实训基地建设任务书</h3>
                </div>
                <div hidden={undefined === processList || processList.length === 0} className="list_topul"
                     style={{"height": taskProcessChildrenVoList.length > 0 ? "192px" : "83px"}}>
                    <div className="clearfix">
                        <ul className="ul-top">
                            {processList ? processList.map((item, index) => (
                                // 2应该是li activeing,目前后台处理暂时有问题,先都按照未完成处理
                                <div className="div-li" key={index} onClick={() => this.handleComplete(item)}>
                                    <li className={item.status === 1 ? `li get-active` : item.status === 2 ? `li get-active` : item.status === 3 ? `li Past-active` : `li`}>
                                        <i className="num"><Icondh/></i>
                                        <span>{item.processName}</span>
                                        {/*2应该是 进行中,目前后台处理暂时有问题,先都按照未完成处理*/}
                                        <b>({item.status === 1 ? "未完成" : item.status === 2 ? "未完成" : item.status === 3 ? "已完成" : ""})</b>
                                    </li>
                                </div>
                            )) : ""}
                        </ul>
                    </div>
                    <div className="clearfix">
                        <ul className="ul-bottom">
                            <div className="div-bottom">
                                {taskProcessChildrenVoList ? taskProcessChildrenVoList.map((twoItem, indextwo) => (
                                    <li className={twoItem.status === 1 ? `span-li get-active` : twoItem.status === 2 ? `span-li activeing` : twoItem.status === 3 ? `span-li Past-active` : `span-li`}
                                        key={indextwo} onClick={() => this.handleProcess(twoItem, twoItem.processCode)}>
                                        <span className={`on`}></span>
                                        <p>{twoItem.processName ? twoItem.processName : ""}</p>
                                    </li>
                                )) : ""}
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default Assignment;
