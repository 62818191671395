import { post } from '../../until/axios_instans';
// 保存任务书
export function addTaskBook(data) {
    return post(window.apiShixun+'/api/v1/taskBook/addTaskBook', data);
}
// 获取任务书信息
export function getTaskBookInfo(data) {
    return post(window.apiShixun+'/api/v1/taskBook/getTaskBookInfo', data);
}
// 修改任务书
export function updateTaskBook(data) {
    return post(window.apiShixun+'/api/v1/taskBook/updateTaskBook', data);
}
// 修改任务书-基础信息使用
export function updateTaskBookInfo(data) {
    return post(window.apiShixun+'/api/v1/taskBook/updateTaskBookInfo', data);
}

export function finalCheckTaskBook(data) {
    return post(window.apiShixun+'/api/v1/taskBook/finalCheckTaskBook', data);
}

export function reportTaskBook(data) {
    return post(window.apiShixun+'/api/v1/taskBook/reportTaskBook', data);
}
export function addTaskFile(data) {
    return post(window.apiShixun+'/api/v1/taskfile/addTaskFile', data);
}
export function selectTaskFile(data) {
    return post(window.apiShixun+'/api/v1/taskfile/selectTaskFile', data);
}







