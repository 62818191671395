/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Card, Row, Col, Button, Modal} from 'antd';
import './index.less';
// import {
//     getLeftCatalog,
//     judgeLeftCatalog,
//     indicatorJudgeLeftCatalog,
//     indicatorsJudgeLeftCatalog
// } from '@/api/doublehigh/senior/';


class SkipComponent extends Component {
    state = {
        seniorId: this.props.seniorId,
        taskId: localStorage.getItem("taskInfo") ? JSON.parse(localStorage.getItem("taskInfo")).taskId : "",
        upSeniorId: this.props.upSeniorId,
        confirmLoading: false,
        visible: false,
        visibles: false,
        messageInfo: "",
        nextUrl: this.props.nextUrl,
        prevUrl: this.props.prevUrl
    };

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.upSeniorId !== this.props.upSeniorId) {
            this.setState({
                upSeniorId: this.props.upSeniorId
            })
        }
    }


    LinkTo = (type) => {
        const {nextUrl, prevUrl} = this.state
        let Url = "";
        if (type === "pre") {
            Url = prevUrl
        } else {
            Url = nextUrl
        }
        this.props.history.push(Url)
    }

    next = async () => {
        // let data = await judgeLeftCatalog({taskId: this.state.taskId, upseniorId: this.state.upSeniorId});
        // if (data.code === 1000) {
        //     this.setState({
        //         visible: true,
        //         messageInfo: data.message
        //     })
        // } else {
        //     this.LinkTo('next')
        // }
    }

    previous = async () => {
        this.setState({
            visibles: true,
            messageInfo: "未保存的数据将会丢失，确定返回上一页吗？"
        })
    }

    indicatorNext = async () => {
        const {upseniorIds, firstCode, secondCode} = this.props;
        if (typeof (upseniorIds) !== "undefined" && upseniorIds !== null && upseniorIds !== '') {
            // let data = await indicatorsJudgeLeftCatalog({
            //     taskId: this.state.taskId,
            //     parentCode: firstCode,
            //     processCode: secondCode,
            //     upseniorId: upseniorIds
            // });
            // if (data.code === 1000) {
            //     this.setState({
            //         visible: true,
            //         messageInfo: data.message
            //     })
            // } else {
            //     this.LinkTo('next')
            // }
        } else {
            // let data = await indicatorJudgeLeftCatalog({taskId: this.state.taskId, upseniorId: this.state.upSeniorId});
            // if (data.code === 1000) {
            //     this.setState({
            //         visible: true,
            //         messageInfo: data.message
            //     })
            // } else {
            //     this.LinkTo('next')
            // }
        }
    }

    indicatorPrevious = async () => {
        // let data = await indicatorJudgeLeftCatalog({taskId: this.state.taskId, upseniorId: this.state.upSeniorId});
        // if (data.code === 1000) {
        //     this.setState({
        //         visibles: true,
        //         messageInfo: "未保存的数据将会丢失，确定返回上一页吗？"
        //     })
        // } else {
        //     this.LinkTo('pre')
        // }
    }

    handleOk = async () => {
        this.setState({
            visible: false,
            visibles: false,
            confirmLoading: false
        });
        // this.LinkTo('next')
    };

    prevHandleOk = async () => {
        this.setState({
            visible: false,
            visibles: false,
            confirmLoading: false
        });
        this.LinkTo('pre')
    };

    handleCancel = async () => {
        this.setState({
            visible: false,
            visibles: false,
            confirmLoading: false
        });
    };

    render() {
        const {dataList, leftCatalog, messageInfo, visible, confirmLoading, visibles} = this.state;
        return (
            <div>
                <div className="step-button-box">
                    <Button onClick={this.props.skipType === 1 ? () => this.indicatorPrevious() : () => this.previous()}
                            className="but-li">
                        上一步
                    </Button>
                    <Button onClick={this.props.skipType === 1 ? () => this.indicatorNext() : () => this.next()}
                            type="primary" className="but-li">
                        下一步
                    </Button>
                </div>
                <Modal
                    wrapClassName='Modal-mainpic-html'
                    className='Modal-but'
                    title={
                        <div>
                            {/*<InfoCircleOutlined/>*/}
                            {/*<img src={require('../../../../assets/images/icon-7.png')}/>*/}
                            温馨提示
                        </div>
                    }
                    centered={true}
                    visible={visible}
                    onOk={this.handleOk}
                    okText="确定"
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    closable={false}
                >
                    <div className="text-conta">
                        <div dangerouslySetInnerHTML={{__html: messageInfo}}/>
                    </div>
                </Modal>
                <Modal
                    wrapClassName='Modal-mainpic-html'
                    className='Modal-but'
                    title={
                        <div>
                            {/*<InfoCircleOutlined/>*/}
                            {/*<img src={require('../../../../assets/images/icon-7.png')}/>*/}
                            消息提醒
                        </div>
                    }
                    centered={true}
                    visible={visibles}
                    onOk={this.prevHandleOk}
                    okText="确定"
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    closable={false}
                >
                    <div className="text-conta">
                        <div dangerouslySetInnerHTML={{__html: messageInfo}}/>
                    </div>
                </Modal>
            </div>


        );
    }
}

export default SkipComponent;
