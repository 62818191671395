import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import Cookies from "js-cookie";

const addStyle = {
    background: '#3e78ed',
    color: '#ffffff',
    border: 'solid 1px #3e78ed'
}
const delStyle = {
    color: '#3e78ed',
    cursor: 'pointer',
    fontSize: '14px'
}

function buttons(text, event) {                             //按钮集合
    return {
        'table_btn': <span {...event} style={delStyle}>{text}</span>,
        'sure_btn': <Button {...event} style={addStyle}>{text}</Button>,
        'cancel_btn': <Button {...event}>{text}</Button>
    }
}

export default class RenderButton extends Component {
    render() {
        // 暂时改为直接从缓存里获取按钮权限，怀疑是action获取GET_BUTTON_ROLE 没有被调用
        const buttonRole = Cookies.get('buttonPerms') ? JSON.parse(Cookies.get('buttonPerms')) : []
        const { text, id, type, children, event } = this.props;
        // console.log("###buttonRole",buttonRole)
        if (!buttonRole.includes(id)) {                      //判断按钮权限中是否包含传过来的id
            return null;
        }
        if (buttons(text)[type]) {                          //判断type
            return buttons(text, event)[type]
        } else if (children) {                              //自定义按钮
            return children;
        } else {                                            //缺少参数
            console.error('RenderButton缺少参数');
            return null;
        }
    }
}

// const mapStateToProps = ({ MAIN }) => {
//     const { buttonRole } = MAIN;
//     return {
//         buttonRole
//     }
// }
//
//
// RenderButton = connect(mapStateToProps)(RenderButton);
// export default RenderButton;
