// 导航
import React, {Component} from 'react';
import './index.scss';
import '../../../reset_style/index.less';
import {Modal, Button, Row, Form, Col, Input, message, InputNumber, Table, Tooltip, Spin, Select} from 'antd';
import {withRouter} from 'react-router-dom'
import Cookies from "js-cookie";
import {updatePassword} from '../../../api/login';
import RenderButton from '../../../components/renderButton/index'
import md5 from "js-md5"
import {deleteTargetResourceCourse} from "../../../api/targetResource";
// import {TianJia} from "../../iconSvg";
import {getCanEdit} from "../../../until/cookie";
import {Logo, WenxinTip, TianJia, Upicon, Upiconwc, Upicondele} from "../../iconSvg";
import moment from "moment";
import {uploadFile} from "../../../api/uploadFile";
import {
    addResources,
    checkPlatformService,
    deleteServiceResources,
    importPlatformService,
    saveSatisfaction,
    selectAllResourcesService,
    selectSatisfaction,
    selectServiceResourcesById,
    updateFactor,
    updateServiceResources
} from '../../../api/target'

@withRouter
class PlatformService_2_2_3 extends Component {
    state = {
        data_2_2_3: {},
        navName: "",
        ispassword: false,
        userInfo: Cookies.get("userInfo") ? JSON.parse(decodeURI(Cookies.get("userInfo"))) : {},
        saveStatus: true
    };

    componentDidMount() {
        this.setState({
            navName: this.props.navName,
        });
    }

    UNSAFE_componentWillReceiveProps(props) {
        const {data_2_2_3} = this.state
        if (undefined !== props.data_2_2_3?.id) {
            this.setState({
                data_2_2_3: props.data_2_2_3
            })
        }
    }

    // section Action
    handleCancel = () => {
        this.setState({showAddJl: false});
    };

    onchangePersonNum1 = (e) => {
        this.setState({studentNum1: e})
    }

    // section render
    render() {
        const canEdit = getCanEdit()

        const {
            data_2_2_3
        } = this.state

        const cloudFictitiousSimulationTotalNum = data_2_2_3?.cloudFictitiousSimulationTotalNum ? data_2_2_3.cloudFictitiousSimulationTotalNum : 0
        // section head
        const inputSection =
            <div>
                <ul className={'platformService_2_2_3'}>
                    <li>
                        <span className={'span1 span2'}>云端虚拟仿真实训总人时数</span>
                        <div className={'btn'}>{cloudFictitiousSimulationTotalNum}</div>
                    </li>
                </ul>

                <ul>
                    <li>
                        <div style={{color:'rgba(215, 87, 71, 1)'}}>*计算结果来自2.2.2中表格数据</div>
                    </li>

                </ul>
            </div>

        return (
            <div className={'cont1'}>
                <div className={'titleName'}>2.2.3 社会人员云端虚拟仿真实训总人时</div>
                {inputSection}
            </div>
        );
    }
}

const PlatformServiceComponent_2_2_3 = Form.create()(PlatformService_2_2_3);
export default PlatformServiceComponent_2_2_3;
