import { post } from '../../until/axios_instans';
// 新增建设任务子项
export function addExpenditureSource(data) {
    return post(window.apiShixun+'/api/v1/invest/saveInvest', data);
}
// 查询建设任务子项
export function getExpenditureSource(data) {
    return post(window.apiShixun+'/api/v1/invest/selectInvest', data);
}
// 修改建设任务子项
export function updateExpenditureSource(data) {
    return post(window.apiShixun+'/api/v1/invest/updateInvest', data);
}
// 删除建设任务子项
export function deleteInvest(data) {
    return post(window.apiShixun+'/api/v1/invest/deleteInvest', data);
}






