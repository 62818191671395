// 预期成效和保障-特色创新
import React from "react";
import {Form, Row, Col, Input, Card, Button, Radio, message} from 'antd';
import "./index.scss";
import Heder from "../../components/header";
import Footer from "../../components/footer";
import StepTop from "../components/StepTop";
import Leftmien from "../components/Left_mien";
import FormComponent from "../components/addForm";
import {AddIcon} from '@/components/iconSvg'
import {withRouter} from "react-router-dom";
import {
    getEffectReview,
    saveEffectReview,
    updateEffectReview,
    saveEffectReviewDetail,
    updateEffectReviewDetail,
    deleteEffectReviewDetail, effectPreview, updateProcessStatus
} from "../../api/effect"
import Cookies from "js-cookie";
import {Logger} from "trtc-js-sdk";
import {isEmpty, isNotEmpty} from "../../until/isEmpty";

const {TextArea} = Input;


export default Form.create()(
    withRouter(
        class CharacteristicCreation extends React.Component {
            state = {
                itemNum: 0,
                itemNums: 0,
                formObj: [
                    {
                        id: "",
                        objectName: "",
                        objectContent: ""
                    }
                ],
                formObj1: [
                    {
                        id: "",
                        objectName: "",
                        objectContent: ""
                    }
                ],
                schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
                upseniorId: '',

                leftCatalog: [
                    {
                        id: "",
                        seniorName: '预期成效',
                        isSelf: 0
                    },
                    {
                        id: "",
                        seniorName: '保障措施',
                        isSelf: 0
                    },
                    {
                        id: "",
                        seniorName: '特色创新',
                        isSelf: 0
                    }

                ], isShow: false,
                stepNum: 0,
                label: '预期成效综述',
                stepList: [{
                    label: '预期成效综述',
                    label1: "标志性成果",
                    labelDes: '成果详细说明',
                }, {
                    label: '保障措施综述',
                    label1: "保障措施",
                    labelDes: '详细说明'
                }, {
                    label: '特色创新综述',
                    label1: "特色创新点",
                    labelDes: '详细说明'
                }],
                tableData: [],
                effectlist: null

            };

            componentDidMount() {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
                window.addEventListener('scroll', this.bindHandleScroll)
                let data = {
                    taskId: this.state.schoolInfo.taskId,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode
                }
                effectPreview(data).then(res => {
                    this.setState({
                        effectlist: res
                    }, () => {
                        const formObj = this.getEffectDetailPreviewVos(0)
                        console.log("更新=====", formObj.length)
                        this.setState({
                            formObj: formObj
                        })
                    })

                })
            }

            // 提交数据的刷新
            refreshEffectPreview = () => {
                let data = {
                    taskId: this.state.schoolInfo.taskId,
                    schoolEduCode: this.state.schoolInfo.schoolEduCode
                }
                effectPreview(data).then(res => {

                    this.setState({
                        formObj1: [{
                            id: "",
                            objectName: "",
                            objectContent: ""
                        }],
                        effectlist: res
                    })
                    const formObj = this.getEffectDetailPreviewVos(this.state.stepNum)
                    this.setState({
                        formObj: formObj
                    })

                })
            }

            /**
             *   获取综述的详情说明
             */
            getEffectDetailPreviewVos(step) {
                console.log("getEffectDetailPreviewVos" + step)
                if (this.state.effectlist != null && this.state.effectlist.length > step && this.state.effectlist[step].effectVos != null && this.state.effectlist[step].effectVos.length > 0 && this.state.effectlist[step].effectVos[0].effectDetailPreviewVos != null && this.state.effectlist[step].effectVos[0].effectDetailPreviewVos.length > 0) {
                    return this.state.effectlist[step].effectVos[0].effectDetailPreviewVos
                } else {
                    return this.state.formObj1
                }
            }

            /**
             *  获取综述
             */
            getOverview(step) {
                if (this.state.effectlist != null && this.state.effectlist.length > 0 && this.state.effectlist[step].effectVos != null && this.state.effectlist[step].effectVos.length > 0) {
                    return this.state.effectlist[step].effectVos[0].overview
                } else {
                    return ""
                }

            }

            /**
             *  获取综述的Id
             */
            getOverviewId(step) {
                if (this.state.effectlist != null && this.state.effectlist.length > 0 && this.state.effectlist[step].effectVos != null && this.state.effectlist[step].effectVos.length > 0) {
                    return this.state.effectlist[step].effectVos[0].id
                } else {
                    return ""
                }

            }

            /**
             *
             *  是否包含有综述
             */
            isEffectPriviewVos(step) {
                return this.state.effectlist != null && this.state.effectlist.length > 0 && this.state.effectlist[step].effectVos != null && this.state.effectlist[step].effectVos.length > 0
            }

            /**
             *
             *  是否包含有综述的详情说明
             */
            isEffectDetailPriviewVos(step) {
                return this.state.effectlist != null && this.state.effectlist.length > step && this.state.effectlist[step].effectVos != null && this.state.effectlist[step].effectVos.length > 0 && this.state.effectlist[step].effectVos[0].effectDetailPreviewVos != null && this.state.effectlist[step].effectVos[0].effectDetailPreviewVos.length > 0
            }

            componentWillUnmount() {

            }


            //保存特色创新综述
            saveEffectReview = () => {
                console.log('333')
                this.props.form.validateFields((err, value) => {
                    console.log('value', value)
                    if (!err) {
                        var content = ""
                        if (this.state.stepNum === 0) {
                            content = value.overview
                        } else if (this.state.stepNum === 1) {
                            content = value.overview1
                        } else {
                            content = value.overview2
                        }
                        // 获取总数的id
                        var overviewId = this.getOverviewId(this.state.stepNum)
                        let data = {
                            id: overviewId,
                            taskId: this.state.schoolInfo.taskId,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            seniorId: this.state.upseniorId,
                            overview: content,
                            effectType: this.state.stepNum + 1
                        }

                        if (isNotEmpty(overviewId)) {
                            updateEffectReview(data).then(res => {
                                if (res) {
                                    message.success("操作成功")

                                    this.refreshEffectPreview()

                                }
                            })
                        } else {
                            saveEffectReview(data).then(res => {
                                if (res) {
                                    message.success("操作成功")

                                    this.refreshEffectPreview()

                                }
                            })
                        }


                    }

                })
            }

            // 重置特色创新综述
            resetEffect = () => {
                let {stepNum, effectlist} = this.state
                if (effectlist != null && effectlist.length > 0 && effectlist[stepNum].effectVos != null && effectlist[stepNum].effectVos.length > 0) {
                    effectlist[stepNum].effectVos[0].overview = ""
                    this.setState({
                        effectlist: effectlist
                    })
                }
                this.props.form.resetFields();
            }


            bindHandleScroll = (event) => {
                let ctx = this;
                let clientHeight = document.documentElement.clientHeight; //可视区域高度
                let scrollTop = document.documentElement.scrollTop;  //滚动条滚动高度
                if (scrollTop > 200) {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                } else {
                    document.getElementById("left-top") && document.getElementById("left-top").setAttribute("style", `top:0;position:relative;height: ${clientHeight - 240}px`);
                }
            }
            //新增
            addFormItem = () => {
                const {stepNum} = this.state

                const list = this.getEffectDetailPreviewVos(stepNum)

                list.push({
                    id: "",
                    objectName: "",
                    objectContent: ""
                })

                this.setState({
                    formObj: list,
                })
            }


            delFile = () => {

            }


            saveEffectDetail=(form, name, content, tableData, effectDetailId,effectId)=>{

                var fileIdList = []
                if (tableData != null && tableData.length > 0) {
                    tableData.map((item, index) => {
                        fileIdList.push(item.id);
                    })
                }
                if (fileIdList.length === 0) {
                    message.error("请先上传附件")
                    return;
                }

                let data = {
                    id: effectDetailId,
                    effectId: effectId,
                    objectName: name,
                    objectContent: content,
                    fileIdList: fileIdList
                };

                if (isNotEmpty(effectDetailId)) {
                    updateEffectReviewDetail(data).then(res => {
                        if (res) {
                            message.success("操作成功")
                            form.resetFields()
                            this.refreshEffectPreview()

                        }
                    })
                } else {
                    saveEffectReviewDetail(data).then(res => {
                        if (res) {
                            message.success("操作成功")
                            form.resetFields()
                            this.refreshEffectPreview()

                        }
                    })
                }
            }
            

            /**
             *   保存-----综述的详情接口
             */
            saveEffectReviewDetail = (form, name, content2, tableData, effectDetailId) => {

                this.props.form.validateFields((err, value) => {
                    console.log('value', value)
                    if (!err) {
                        var content = ""
                        if (this.state.stepNum === 0) {
                            content = value.overview
                        } else if (this.state.stepNum === 1) {
                            content = value.overview1
                        } else {
                            content = value.overview2
                        }
                        // 获取总数的id
                        var overviewId = this.getOverviewId(this.state.stepNum)
                        let data = {
                            id: overviewId,
                            taskId: this.state.schoolInfo.taskId,
                            schoolEduCode: this.state.schoolInfo.schoolEduCode,
                            seniorId: this.state.upseniorId,
                            overview: content,
                            effectType: this.state.stepNum + 1
                        }

                        var fileIdList = []
                        if (tableData != null && tableData.length > 0) {
                            tableData.map((item, index) => {
                                fileIdList.push(item.id);
                            })
                        }
                        if (fileIdList.length === 0) {
                            message.error("请先上传附件")
                            return;
                        }


                        var effectId = ""
                        if (this.state.effectlist != null) {
                            var effectVos = this.state.effectlist[this.state.stepNum].effectVos;
        
                            if (effectVos != null && effectVos.length > 0) {
                                effectId = effectVos[0].id
                            }
        
                        }

                        if (isNotEmpty(overviewId)) {
                            updateEffectReview(data).then(res => {
                                if (res) {
                                   this.saveEffectDetail(form, name, content2, tableData, effectDetailId,effectId)
                                }
                            })
                        } else {
                            saveEffectReview(data).then(res => {
                                if (res) {
                                    this.saveEffectDetail(form, name, content2, tableData, effectDetailId,res)
                                }
                            })
                        }


                    }

                })

              
               

            }

            /**
             *   删除-----综述的详情接口
             */
            delFormItem = (effectId) => {

                if (isNotEmpty(effectId)) {
                    let data = {
                        id: effectId
                    }
                    deleteEffectReviewDetail(data).then(res => {
                        if (res) {
                            message.info("删除成功")
                            this.refreshEffectPreview()
                        }
                    })
                } else {
                    this.refreshEffectPreview()
                }


            }

            getTableData = (item) => {
                return item && item.files ? item.files : []
            }

            getFormComponent = (step) => {

                const formObj = this.state.formObj
                return formObj ? formObj.map((item, index) => {
                    return (
                        <FormComponent formData={this.state.stepList[step]} name={item.formItem}
                                       delFormItem={this.delFormItem}
                                       key={item.formItem} num={index}
                                       step={step}
                                       item={item}
                                       tableData={this.getTableData(item)}
                                       saveEffectReviewDetail={this.saveEffectReviewDetail}
                                       wrappedComponentRef={(form) => this['formAssignment' + item.formItem] = form}/>
                    )
                }) : ''

            }

            selectSubTask = (id) => {

            }
            /**
             * 左边栏目切换
             * */
            switchColumn = (stepNum) => {
                const list = this.getEffectDetailPreviewVos(stepNum)
                console.log("switchColumn=======" + list.length)
                this.setState(
                    {
                        stepNum: stepNum,
                        formObj: list
                    }
                )

            }
            qiehuancd = (id) => {
                console.log("qiehuancd=====" + id)
                this.state.upseniorId = id

            }
            /**
             * 下一步
             * */
            next = () => {

                if (this.state.stepNum === 2) {

                    if (!this.isEffectPriviewVos(0)) {
                        message.error("请填写预期成效的综述")
                        return
                    }
                    if (!this.isEffectDetailPriviewVos(0)) {
                        message.error("请填写预期成效的说明")
                        return
                    }

                    if (!this.isEffectPriviewVos(1)) {
                        message.error("请填写保障措施的综述")
                        return

                    }
                    if (!this.isEffectDetailPriviewVos(1)) {
                        message.error("请填写保障措施的说明")
                        return

                    }
                    if (!this.isEffectPriviewVos(2)) {
                        message.error("请填写特色创新的综述")
                        return
                    }
                    if (!this.isEffectDetailPriviewVos(2)) {
                        message.error("请填写特色创新的说明")
                        return
                    }

                    let data = {
                        taskId: this.state.schoolInfo.taskId,
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        processCode: "3"
                    }
                    updateProcessStatus(data).then(res => {
                        if (res) {
                            //  跳转
                            this.props.history.push('/upload_Document')
                        }

                    })


                } else if (this.state.stepNum === 1) {
                    this.switchColumn(2)
                } else if (this.state.stepNum === 0) {
                    this.switchColumn(1)
                }
            }
            /**
             *  上一步
             * */
            back = () => {
                if (this.state.stepNum === 2) {
                    this.switchColumn(1)

                } else if (this.state.stepNum === 1) {
                    this.switchColumn(0)

                } else {
                    this.props.history.goBack()
                }
            }

            render() {
                const {getFieldDecorator} = this.props.form
                const {leftCatalog, isShow, stepNum, formObj} = this.state;
                const labelWidth = 110
                return (
                    <div className="CharacteristicCreation_creation">
                        <Heder/>
                        <div className="Task-top">
                            <div className="main_cont">
                                <Card>
                                    <Row>
                                        <Col span={24} className="col-li">
                                            <StepTop/>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </div>
                        <div className="Task-bottom clearfix">
                            <div className="main_cont">
                                <div className="Task-bottom-main">

                                    <Row type="flex" justify="space-between">
                                        <Col span={4} className="Task_left" id="left-top">
                                            <div className="Double-left-table">
                                                <Leftmien leftCatalog={leftCatalog} isShow={isShow}
                                                          stepNum={this.state.stepNum}
                                                          upseniorId={"3"}
                                                          nextStep={this.state.nextStep}
                                                          selectSubTask={this.selectSubTask}
                                                          qiehuancd={this.qiehuancd}
                                                          switchColumn={this.switchColumn}
                                                />
                                            </div>
                                        </Col>
                                        <Col span={20} className="Task_right">
                                            <div className="Characteristic_cont">
                                                <h3 className='middle-title'>{this.state.stepNum === 0 ? "3-1 预期成效" : this.state.stepNum === 1 ? "3-2 保障措施" : "3-3 特色创新"}</h3>
                                                <Form>
                                                    <div className="form_top">

                                                        {
                                                            this.state.stepNum === 0 ?
                                                                <Form.Item label={this.state.stepList[0].label}
                                                                           labelCol={{style: {width: labelWidth}}}
                                                                           onFocus={this.doFocus}
                                                                           onBlur={this.doBlur}>
                                                                    {getFieldDecorator('overview', {
                                                                        initialValue: this.getOverview(0),
                                                                        rules: [
                                                                            {
                                                                                required: true,
                                                                                message: `请填写` + this.state.stepList[0].label,
                                                                            },
                                                                            {
                                                                                min: 2,
                                                                                message: "文本最少2字"
                                                                            },
                                                                            {
                                                                                max: 1000,
                                                                                message: "文本最多1000字"
                                                                            },
                                                                        ],
                                                                    })(<TextArea maxLength={1000}
                                                                                 className="TextArea-pic" rows={3}/>)}
                                                                    <p className="font-num">{this.props.form.getFieldValue(`overview`) ? this.props.form.getFieldValue(`overview`).length : 0}/1000</p>
                                                                </Form.Item> : this.state.stepNum === 1 ?
                                                                    <Form.Item label={this.state.stepList[1].label}
                                                                               labelCol={{style: {width: labelWidth}}}
                                                                               onFocus={this.doFocus}
                                                                               onBlur={this.doBlur}>
                                                                        {getFieldDecorator('overview1', {
                                                                            initialValue: this.getOverview(1),
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: `请填写` + this.state.stepList[1].label,
                                                                                },
                                                                                {
                                                                                    min: 2,
                                                                                    message: "文本最少2字"
                                                                                },
                                                                                {
                                                                                    max: 1000,
                                                                                    message: "文本最多1000字"
                                                                                },
                                                                            ],
                                                                        })(<TextArea maxLength={1000}
                                                                                     className="TextArea-pic" rows={3}/>)}
                                                                        <p className="font-num">{this.props.form.getFieldValue(`overview1`) ? this.props.form.getFieldValue(`overview1`).length : 0}/1000</p>
                                                                    </Form.Item> :
                                                                    <Form.Item label={this.state.stepList[2].label}
                                                                               labelCol={{style: {width: labelWidth}}}
                                                                               onFocus={this.doFocus}
                                                                               onBlur={this.doBlur}>
                                                                        {getFieldDecorator('overview2', {
                                                                            initialValue: this.getOverview(2),
                                                                            rules: [
                                                                                {
                                                                                    required: true,
                                                                                    message: `请填写` + this.state.stepList[2].label,
                                                                                },
                                                                                {
                                                                                    min: 2,
                                                                                    message: "文本最少2字"
                                                                                },
                                                                                {
                                                                                    max: 1000,
                                                                                    message: "文本最多1000字"
                                                                                },
                                                                            ],
                                                                        })(<TextArea maxLength={1000}
                                                                                     className="TextArea-pic" rows={3}/>)}
                                                                        <p className="font-num">{this.props.form.getFieldValue(`overview2`) ? this.props.form.getFieldValue(`overview2`).length : 0}/1000</p>
                                                                    </Form.Item>
                                                        }


                                                        {/* <div className="bottom-but">
                                                            <Button className="but-li"
                                                                    htmlType="submit" onClick={this.resetEffect}>
                                                                重置
                                                            </Button>
                                                            <Button className="but-li"
                                                                    type="primary"
                                                                    htmlType="submit"
                                                                    onClick={() => this.saveEffectReview()}>
                                                                保存
                                                            </Button>
                                                        </div> */}

                                                    </div>
                                                    <div className="form_bottom">
                                                        {this.state.stepNum === 2 ?
                                                            <h3>可列举项目在与行业、企业合作共建共享，推动教学形式创新、技术创新、组织模式创新的关键特色或创新点</h3> : ""}
                                                        <div className="form_con">
                                                            {this.state.stepNum === 0 ? this.getFormComponent(0) : this.state.stepNum === 1 ? this.getFormComponent(1) : this.getFormComponent(2)}
                                                        </div>
                                                        <div className="bottom_more">
                                                            <span className="more_open"
                                                                  onClick={this.addFormItem}><AddIcon/>{this.state.stepNum === 0 ? "添加标志性成果" : this.state.stepNum === 1 ? "添加保障措施" : "添加特色创新"}</span>
                                                        </div>
                                                    </div>


                                                </Form>
                                                <div className="but-tj">
                                                    <Button className="but"
                                                            htmlType="submit" onClick={this.back}>
                                                        上一步
                                                    </Button>
                                                    <Button className="but" type="primary"
                                                            htmlType="submit" onClick={this.next}>
                                                        下一步
                                                    </Button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                );
            }
        }
    )
);
