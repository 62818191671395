import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import clns from 'classnames'
import "./index.scss"


export default class RectCmp extends PureComponent {
  render() {
    let { className, style, text, status, displayOrder, width, height } = this.props
    const styles = (style) => {
      style = style || {};
      if (width >= 0) {
        style.width = width + 'px';
      }
      if (height >= 0) {
        style.height = height + 'px';
      }
      return style;
    }
    return <span>
      <div className={clns("lct","rect-cmp", className, {
        "actived": !!status
      })} style={{ ...styles(style) }} onClick={() => {
        this.props.onClick && this.props.onClick()
      }}>
        {text}
      </div>
    </span>
  }
}
RectCmp.propTypes = {
  text: PropTypes.string,
  displayOrder: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.any,
  style: PropTypes.any,
  width: PropTypes.number,
  height: PropTypes.number
}