// 底部
import React from "react";
import "./index.scss";

export default class Footer extends React.Component {
  componentDidMount() {

  }

  render() {
    return (
      <div className="footer-wrap">
        <div className="main_cont">
          <span>版权所有 ：北京云圈科技有限公司</span>
        </div>
      </div>
    );
  }
}
