// 建设成效

import {post, get} from '../../until/axios_instans';

// 建设成效List
export function constructionEffectiveList(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/constructionEffectiveList', data);
}

// 保存成效任务
export function saveEffectTask(data) {
    return post(window.apiShixun + '/api/v1/preview/saveEffectTask', data);
}

// 获取成效任务
export function effectTasks(data) {
    return post(window.apiShixun + '/api/v1/preview/effectTasks', data);
}

// 删除成效任务
export function deleteEffectTask(id) {
    return post(window.apiShixun + '/api/v1/preview/deleteEffectTask', {id});
}

// 查询指标问题(1.5.3到1.5.5的接口)
export function selectTargetQuestion(data) {
    return post(window.apiShixun + '/api/v1/target/selectTargetQuestion', data);
}

// 保存指标问题(1.5.3到1.5.5的接口)
export function saveTargetQuestion(data) {
    return post(window.apiShixun + '/api/v1/target/saveTargetQuestion', data);
}


// 新增专业
export function addMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/addMajor', data);
}

// 修改专业
export function updateMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/updateMajor', data);
}

// 获取专业
export function getMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/getMajor', data);
}

// 删除专业
export function deleteMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/deleteMajor', data);
}

// 导入专业
export function importMajor(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/importMajor', data);
}

// 新增专业群
export function addMajorGroup(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/addMajorGroup', data);
}

// 修改专业群
export function updateMajorGroup(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/updateMajorGroup', data);
}

// 获取专业群
export function getMajorGroup(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/getMajorGroup', data);
}

// 删除专业群
export function deleteMajorGroup(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/deleteMajorGroup', data);
}

// 导入专业群
export function importMajorGroup(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/importMajorGroup', data);
}

// 新增覆盖专业学生人数占比
export function addStudentNum(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/addStudentNum', data);
}

// 修改覆盖专业学生人数占比
export function updateStudentNum(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/updateStudentNum', data);
}

// 删除覆盖专业学生人数占比
export function deleteStudentNum(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/deleteStudentNum', data);
}

// 获取覆盖专业学生人数占比
export function getStudentNum(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/getStudentNum', data);
}

// 专业下拉
export function selectMajorList(data) {
    return post(window.apiShixun + '/api/v1/constructionEffective/selectMajorList', data);
}

// 获取解决问题的列表数据
export function selectSolveDifficulty(data) {
    return post(window.apiShixun + '/api/v1/targetSolveDifficulty/selectSolveDifficulty', data);
}

// 保存解决难题数据
export function saveSolveDifficulty(data) {
    return post(window.apiShixun + '/api/v1/targetSolveDifficulty/saveSolveDifficulty', data);
}

// 删除解决难题数据
export function deleteSolveDifficulty(data) {
    return post(window.apiShixun + '/api/v1/targetSolveDifficulty/deleteSolveDifficulty', data);
}

// 1.5.4 通过分组名称获取字典信息
export function getCommonDic(data) {
    return get(window.apiShixun + '/api/v1/commonDic/getCommonDic', data);
}

// 1.5.4 虚拟仿真实训体现行业企业 列表
export function newSituation(data) {
   return post(window.apiShixun + '/api/v1/targetNewSituation/newSituation', data);
}

// 1.5.4 虚拟仿真实训体现行业企业 保存
export function saveNewSituation(data) {
    return post(window.apiShixun + '/api/v1/targetNewSituation/saveNewSituation', data);
}

// 1.5.4 虚拟仿真实训体现行业企业 删除
export function deleteNewSituation(data) {
    return post(window.apiShixun + '/api/v1/targetNewSituation/deleteNewSituation', data);
}

// 1.5.5 虚拟仿真实训基地年度平均利用率 列表
export function getLaboratoryUsing(data) {
    return post(window.apiShixun + '/api/v1/targetLaboratoryUsing/laboratoryUsing', data);
 }

 // 下载解决难题数据
export function downloadBusTemplate(data) {
    return window.apiShixun + '/api/v1/import/downloadBusTemplate?templateNo='+ data;
}
 // 导入
export function excelBusData(data) {
    return post(window.apiShixun + '/api/v1/import/excelBusData', data);
}
// 1.5.5 虚拟仿真实训基地年度平均利用率 保存
export function savelaboratoryUsing(data) {
    return post(window.apiShixun + '/api/v1/targetLaboratoryUsing/savelaboratoryUsing', data);
}

// 1.5.5 虚拟仿真实训基地年度平均利用率 删除
export function deletelaboratoryUsing(data) {
    return post(window.apiShixun + '/api/v1/targetLaboratoryUsing/deletelaboratoryUsing', data);
}

