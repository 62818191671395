/*
 * @Descripttion: 
 * @version: 
 * @Author: nliu
 * @Date: 2021-09-04 17:30:35
 * @LastEditors: nliu
 * @LastEditTime: 2021-09-04 18:19:36
 */
export function range(start, end) {
  const result = [];
  for (let i = start+1; i < end; i++) {
    result.push(i);
  }
  return result;
}