import React, {Component} from 'react';
import {Card, Row, Col, Button, Form, Input, Select, DatePicker} from 'antd';
import './index.scss';
import Header from '../../components/header'
import Footer from '../../components/footer'
import StepTop from '../components/StepTop'
import moment from "moment";
import Cookies from "js-cookie";
import {addTaskBook, getTaskBookInfo, updateTaskBook} from '../../api/taskBook'
import {formatDate} from "../../until/date";

const {Search} = Input;
const {TextArea} = Input;
const monthFormat = 'YYYY-MM-DD';
const {Option} = Select;

function disabledDate(current) {
    return current && current > moment().endOf('day');
}

class ConstructionPlan extends React.Component {
    state = {
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
        schoolInfo: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')) : "",
        proTextValue: '',
        hostTextValue: '',
        taskBookList: '',
        proTextLength: '',
        organizerTextLength: '',
        schoolId: '',
        userId: '',
        category: '',
        chinaAllProvinceList: [{
            label: '1',
            value: '1'
        }],
        fillingDate: "",
        isChange: true,
        secondCode: '',
        sysType: '',
    };

    componentDidMount() {
        console.log('Cookies.get(\'taskId\')', this.state.taskId)
        this.getTaskBookInfo()
    }

    getTaskBookInfo = () => {
        console.log('this.state.taskId', this.state.taskId)
        let data = {
            taskId: this.state.taskId ? this.state.taskId : ''
        }
        getTaskBookInfo(data).then(res => {
            if (res) {
                console.log('getTaskBookInfo', res)
                this.setState({
                    fillingDate: res.fillingDate, // 填报日期
                })
                this.props.form.setFieldsValue({
                    baseName: res.baseName, // 基地名称
                    cooperationUnit: res.cooperationUnit, // 合作单位
                    basePerson: res.basePerson, // 基地负责人
                    contactMode: res.contactMode, // 联系方式
                })
            }
        })
    }

    // 重置
    handleReset = () => {
        this.props.form.resetFields();
    };
    //下一步
    save = () => {
        this.props.form.validateFields((err, value) => {
            if (!err) {

                let fillingDate = moment(formatDate(value.fillingDate))
                if (this.state.taskId) {
                    let update = {
                        id: this.state.taskId,
                        baseName: value.baseName,
                        hostSchool: value.hostSchool,
                        cooperationUnit: value.cooperationUnit,
                        basePerson: value.basePerson,
                        contactMode: value.contactMode,
                        fillingDate: fillingDate,
                    }
                    updateTaskBook(update).then(res => {
                        if (res) {
                            this.props.history.push({
                                pathname: '/project_school',
                            })
                        }
                    })
                } else {
                    let save = {
                        schoolEduCode: this.state.schoolInfo.schoolEduCode,
                        baseName: value.baseName,
                        hostSchool: value.hostSchool,
                        cooperationUnit: value.cooperationUnit,
                        basePerson: value.basePerson,
                        contactMode: value.contactMode,
                        fillingDate: fillingDate,
                    }
                    addTaskBook(save).then(res => {
                        if (res) {
                            let schoolInfo = this.state
                            console.log('缓存：：schoolInfo', schoolInfo)
                            let cookieData = {
                                id: schoolInfo.schoolInfo.id,
                                taskId: res.taskId,
                                schoolName: schoolInfo.schoolInfo.schoolName,
                                schoolEduCode: schoolInfo.schoolInfo.schoolEduCode,
                            }
                            Cookies.set('schoolInfo', cookieData);
                            this.props.history.push({
                                pathname: '/project_school',
                            })
                        }
                    })
                }
            }
        })
    }

    render() {
        const {getFieldDecorator} = this.props.form
        const {fillingDate, schoolInfo} = this.state;
        console.log('schoolInfo :: ', schoolInfo)
        console.log('schoolInfo.schoolName :: ', schoolInfo.schoolName)
        return (
            <div className="Constructionplan main-bg">
                {/*顶部star*/}
                <Header/>
                <div className="Task-top main_cont">
                    <div className="main-cont">
                        <Card>
                            <Row>
                                <Col span={24} className="col-li">
                                    <StepTop setCode={this.setCode}/>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
                <div className="Mission-bottom clearfix main_cont">
                    <div className="main-cont Mission">
                        <div className="Mission-pic">
                            <h3 className="title">
                                <span className='title_lcl_change'>建设任务书封皮</span>
                            </h3>
                            <div className="form-main">
                                <Form>
                                    <Form.Item className="Item-li" label={`基地名称`}>
                                        {getFieldDecorator('baseName', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写基地名称`,
                                                },
                                                {
                                                    max: 50,
                                                    message: "文本过长，最多50字"
                                                },
                                            ],
                                        })(<Input className="input" maxLength={50}/>)}
                                    </Form.Item>
                                    <Form.Item className="Item-li" label={`申报学校`}>
                                        {getFieldDecorator('hostSchool', {
                                            initialValue: schoolInfo ? schoolInfo.schoolName : ''
                                        })(<Input className="input" disabled={true}/>)}
                                    </Form.Item>

                                    <Form.Item className="Item-li" label={`合作单位`}>
                                        {getFieldDecorator('cooperationUnit', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写合作单位`,
                                                },
                                                {
                                                    max: 100,
                                                    message: "文本过长，最多100字"
                                                },
                                            ],
                                        })(
                                            <Input className="input" maxLength={100}/>
                                        )
                                        }
                                    </Form.Item>
                                    <div className="hit-cooperationUnit">
                                        {'* 多个合作单位, 中间用分号；隔开'}
                                    </div>

                                    <Form.Item className="Item-li" label={`基地负责人`}>
                                        {getFieldDecorator('basePerson', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请填写基地负责人`,
                                                },
                                                {
                                                    max: 20,
                                                    message: "文本过长，最多20字"
                                                },
                                            ],
                                        })(<Input className="input" maxLength={20}/>)}
                                    </Form.Item>
                                    <Form.Item className="Item-li" label={`联系方式`}>
                                        {getFieldDecorator('contactMode', {
                                            rules: [{
                                                required: true,
                                                pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                                                message: '请输入正确手机号码'
                                            }],
                                        })(<Input className="input" maxLength={11}/>)}
                                    </Form.Item>
                                    <Form.Item className="Item-li" label={`填表日期`}>
                                        {getFieldDecorator('fillingDate', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `请选择填表日期`,
                                                },
                                            ],
                                            initialValue: fillingDate ? moment(fillingDate, 'YYYY-MM-DD') : null
                                        })(<DatePicker disabledDate={disabledDate} className="input"
                                                       format={monthFormat} dropdownClassName='Date-main'/>)}
                                    </Form.Item>

                                </Form>
                            </div>
                            <div className="but-tj">
                                <Button className="but" onClick={this.handleReset}>重置</Button>
                                <Button type="primary" className="but" onClick={() => this.save()}>下一步</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*顶部star*/}
                <Footer/>
            </div>
        );
    }
}

ConstructionPlan = Form.create()(ConstructionPlan);
export default ConstructionPlan;
