import moment from "moment";

// 时区处理工具类
export function formatDate(time) {
    let date = new Date(time);
    date.setHours(date.getHours() + 8);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
}


// 失效时间,可用在日期上当做今天
export function disabledDate(current) {
    return current && current > moment().endOf('day');
}
