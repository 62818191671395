/*eslint eqeqeq: ["off", "smart"]*/
import React, {Component} from 'react';
import {Input, Upload, Card, Row, Col, Form, Tooltip, Spin, Button, message} from 'antd';
import {UploadFile} from '@/components/iconSvg'
import './index.scss';
import Header from '../../components/header'
import Footer from '../../components/footer'
import StepTop from '../components/StepTop';
import moment from "moment";
import Cookies from "js-cookie";
import {addTaskFile, selectTaskFile} from "../../api/taskBook";
import {uploadFile} from "../../api/uploadFile";
import {Upicondele, Upiconwc} from "../../components/iconSvg";
// import {message} from "../../components/global_components/confirm";
//
class UploadDocument extends React.Component {
    state = {
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        taskId: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).taskId : '',
       
    };

    componentDidMount() {
        this.selectTaskFile()
    }

//查询列表
    selectTaskFile = () => {
        let data = {
            schoolEduCode: this.state.schoolEduCode,
            taskId: this.state.taskId
        }
        selectTaskFile(data).then((res) => {
            if (res) {
                for (let i = 0; i < res.length; i++) {
                    if (res[i].fileType === 1) {
                        let upFile = {
                            taskId: this.state.taskId,
                            schoolEduCode: this.state.schoolEduCode,
                            fileType: res[i].fileType,
                            resourceName: res[i].resourceName,
                            fileId: res[i].resourceName,
                            resourceSuffix: res[i].resourceSuffix,
                            resourceId: res[i].resourceId,
                            resourceSize: res[i].resourceSize
                        }
                        this.setState({
                            file: upFile
                        })
                    } else if (res[i].fileType === 2) {
                        let upFile = {
                            taskId: this.state.taskId,
                            schoolEduCode: this.state.schoolEduCode,
                            fileType: res[i].fileType,
                            resourceName: res[i].resourceName,
                            fileId: res[i].resourceName,
                            resourceSuffix: res[i].resourceSuffix,
                            resourceId: res[i].resourceId,
                            resourceSize: res[i].resourceSize
                        }
                        this.setState({
                            fileTwo: upFile
                        })
                    } else if (res[i].fileType === 3) {
                        let upFile = {
                            taskId: this.state.taskId,
                            schoolEduCode: this.state.schoolEduCode,
                            fileType: res[i].fileType,
                            resourceName: res[i].resourceName,
                            fileId: res[i].resourceName,
                            resourceSuffix: res[i].resourceSuffix,
                            resourceId: res[i].resourceId,
                            resourceSize: res[i].resourceSize
                        }
                        this.setState({
                            fileThree: upFile
                        })
                    } else if (res[i].fileType === 4) {
                        let upFile = {
                            taskId: this.state.taskId,
                            schoolEduCode: this.state.schoolEduCode,
                            fileType: res[i].fileType,
                            resourceName: res[i].resourceName,
                            fileId: res[i].resourceName,
                            resourceSuffix: res[i].resourceSuffix,
                            resourceId: res[i].resourceId,
                            resourceSize: res[i].resourceSize
                        }
                        this.setState({
                            fileFour: upFile
                        })
                    }
                }
            }
        });
    }

    //上传文件
    beforeUploadFour = async (file) => {
        const {schoolEduCode, taskId} = this.state
        let upFile = {resourceName: file.name}
        this.setState({
            fileFour: upFile
        })
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', 'vb')
        const isLt5M = file.size / 1024 / 1024 < 20;
        if (!isLt5M) {
            message.error("文件不能大于20M");
            this.setState({
                file: ""
            })
            return false;
        }
        let res = await uploadFile(formdata);
        if (res instanceof Object) {
            let upFile = {
                taskId: taskId,
                schoolEduCode: schoolEduCode,
                fileType: 4,
                resourceName: res.originFileName,
                fileId: res.fpName,
                resourceSuffix: res.mediaType,
                resourceId: res.id,
                resourceSize: (res.fileSize / 1024).toFixed(2)
            }
            this.setState({
                fileFour: upFile
            })

        }
    };

    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "pdf、PDF";
        return checkType.includes(type)
    };


    //上传文件
    beforeUploadThree = (file) => {
        const {schoolEduCode, taskId} = this.state
        let upFile = {resourceName: file.name}
        const fileNameindex = file.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
        const fileNameLen = file.name.length; // 取到文件名长度
        const fileType = file.name.substring(fileNameindex + 1, fileNameLen); // 截
        if (!this.checkFileType(fileType)) {
            message.error("上传的资源格式不符, 请上传pdf、PDF类型文件")
            return
        }

        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', 'vb')
        const isLt5M = file.size / 1024 / 1024 < 20;
        if (!isLt5M) {
            message.error("文件不能大于20M");
            return false;
        }

        this.setState({
            fileThree: upFile
        })

        uploadFile(formdata).then(res => {
            if (res instanceof Object) {
                let upFile = {
                    taskId: taskId,
                    schoolEduCode: schoolEduCode,
                    fileType: 3,
                    resourceName: res.originFileName,
                    fileId: res.fpName,
                    resourceSuffix: res.mediaType,
                    resourceId: res.id,
                    resourceSize: (res.fileSize / 1024).toFixed(2)
                }
                this.setState({
                    fileThree: upFile
                })

            } else {
                this.setState({
                    fileThree: null
                })
            }
        });

    };
    //上传文件
    beforeUploadTwo = async (file) => {
        const {schoolEduCode, taskId} = this.state
        let upFile = {resourceName: file.name}
        this.setState({
            fileTwo: upFile
        })
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', 'vb')
        const isLt5M = file.size / 1024 / 1024 < 20;
        if (!isLt5M) {
            message.error("文件不能大于20M");
            this.setState({
                fileTwo: null
            })
            return false;
        }
        let res = await uploadFile(formdata);
        if (res instanceof Object) {
            let upFile = {
                taskId: taskId,
                schoolEduCode: schoolEduCode,
                fileType: 2,
                resourceName: res.originFileName,
                fileId: res.fpName,
                resourceSuffix: res.mediaType,
                resourceId: res.id,
                resourceSize: (res.fileSize / 1024).toFixed(2)
            }
            this.setState({
                fileTwo: upFile
            })

        }
    };
    //上传文件
    beforeUpload = async (file) => {
        const {schoolEduCode, taskId} = this.state
        let upFile = {resourceName: file.name}
        this.setState({
            file: upFile
        })
        const formdata = new FormData();
        formdata.append('file', file);
        formdata.append('folder', 'vb')
        const isLt5M = file.size / 1024 / 1024 < 20;
        if (!isLt5M) {
            message.error("文件不能大于20M");
            this.setState({
                file: null
            })
            return false;
        }
        let res = await uploadFile(formdata);
        if (res instanceof Object) {
            let upFile = {
                taskId: taskId,
                schoolEduCode: schoolEduCode,
                fileType: 1,
                resourceName: res.originFileName,
                fileId: res.fpName,
                resourceSuffix: res.mediaType,
                resourceId: res.id,
                resourceSize: (res.fileSize / 1024).toFixed(2)
            }
            this.setState({
                file: upFile
            })

        }
    };
    //删除
    deleteFile = () => {
        this.setState({
            file: null
        })
    }
    //删除
    deleteFileTwo = () => {
        this.setState({
            fileTwo: null
        })
    }
    //删除
    deleteFileThree = () => {
        this.setState({
            fileThree: null
        })
    }
    //删除
    deleteFileFour = () => {
        this.setState({
            fileFour: null
        })
    }
    // 上一步
    back = () => {
        this.props.history.goBack()
    }
    //下一步
    next = () => {
        const {file, fileTwo, fileThree, fileFour, taskId, schoolEduCode} = this.state;
        let addTaskFileQo = []
        if (!fileThree) {
            message.error("请上传建设方案")
            return
        } else {
            addTaskFileQo.push(fileThree)
        }
        let data = {
            taskFileQoList: addTaskFileQo,
            processCode: "4",
            taskId: taskId,
            schoolEduCode: schoolEduCode

        }
        addTaskFile(data).then((res) => {
            if (res) {
                message.success("保存成功")
                this.props.history.push("/content_validation")
            }
        });
    }

    render() {
        const {file, fileTwo, fileThree, fileFour} = this.state;

        const uploadButton = (
            <div className="upload">
                <div className="upload_bg">
                    <img src={require('../../assets/images/icon_file_upload.png')}></img>
                    <div style={{marginTop: 8}}>{file ? "重新上传" : "点击上传文件"}</div>
                </div>
            </div>
        );
        return (
            <div className="Upload_Document main-bg">
                {/*顶部star*/}
                <Header/>
                <div className="Task-top">
                    <div className="main-cont main_cont">
                        <Card>
                            <Row>
                                <Col span={24} className="col-li">
                                    <StepTop setCode={this.setCode}/>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                </div>
                <div className="content main_cont">
                    <div className={'cont-center'}>

                        {/* <div className='recommendedschool webStyle'>
                            <h3>学校推荐意见</h3>
                            <Upload
                                fileList={[]}
                                multiple={true}
                                beforeUpload={ this.beforeUpload}
                                listType="picture-card"
                                showUploadList={false}
                                accept={'.jpg'}>
                                <Button>111111</Button>
                            </Upload>
                            {file? <Tooltip placement="topLeft" title={file.resourceName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.resourceName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>:null}
                            <p>提示：只能上传一份文档</p>


                        </div> */}
                        {/* <div className='recommendedcity webStyle'>
                            <h3>市级推荐意见</h3>
                            <Upload
                                fileList={[]}
                                multiple={true}
                                beforeUpload={ this.beforeUploadTwo}
                                listType="picture-card"
                                showUploadList={false}
                                accept={'.jpg'}>
                                <Button>22222222</Button>
                            </Upload>
                            {fileTwo? <Tooltip placement="topLeft" title={fileTwo.resourceName}><li className="title_de">{fileTwo.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{fileTwo.resourceName}</span><i className="Upicona" onClick={this.deleteFileTwo}><Upicondele/></i> </li></Tooltip>:null}
                            <p>提示：只能上传一份文档</p>
                        </div> */}


                        <div className='constructionplan webStyle'>
                            <h3>上传本院校的建设方案（PDF盖章文件扫描版)</h3>
                            <Upload
                                fileList={[]}
                                multiple={true}
                                beforeUpload={this.beforeUploadThree}
                                showUploadList={false}
                            >
                                {
                                    uploadButton
                                }
                                {/* <Button>上传文件</Button> */}
                            </Upload>

                            {fileThree ? <Tooltip placement="topLeft" title={fileThree.resourceName}>
                                <li className="title_de">{fileThree.fileId ? <Upiconwc/> : <Spin/>}<span
                                    className="tie_span">{fileThree.resourceName}</span><i className="Upicona"
                                                                                           onClick={this.deleteFileThree}><Upicondele/></i>
                                </li>
                            </Tooltip> : null}
                            <p>提示：只能上传一份文档</p>


                        </div>


                        {/* <div className='reporttable webStyle'>
                            <h3 style={{marginLeft:'-30px'}}>职业教育虚拟仿真实训基地申报汇总表</h3>
                            <Upload
                                fileList={[]}
                                multiple={true}
                                beforeUpload={ this.beforeUploadFour}
                                listType="picture-card"
                                showUploadList={false}
                                accept={".jpg,.doc"}>
                                <Button>11111111111</Button>
                            </Upload>
                            {fileFour? <Tooltip placement="topLeft" title={fileFour.resourceName}><li className="title_de">{fileFour.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{fileFour.resourceName}</span><i className="Upicona" onClick={this.deleteFileFour}><Upicondele/></i> </li></Tooltip>:null}
                            <p style={{marginLeft:'-47px'}}>提示：只能上传一份文档</p>
                         </div> */}

                        <div className="but-tj">
                            <Button className="but"
                                    htmlType="submit" onClick={this.back}>
                                上一步
                            </Button>
                            <Button className="but" type="primary"
                                    htmlType="submit" onClick={this.next}>
                                下一步
                            </Button>
                        </div>
                    </div>
                </div>
                {/*顶部star*/}
                <Footer/>
            </div>
        );
    }
}

UploadDocument = Form.create()(UploadDocument);
export default UploadDocument;
