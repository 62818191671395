// 1.1.2资金预算到账率
import React from "react";
import {Col, Form, Row, Table, Input, Button, InputNumber, Modal, Radio, message} from 'antd';
import {withRouter} from "react-router-dom";
import Cookies from "js-cookie";
import {saveTargetFundInvest} from "../../../../api/targetResource";
import "../../index.scss";
import {getRoleType, RoleType} from "../../../../until/cookie";

const {TextArea} = Input

class Budgetarrival extends React.Component {
    state = {
        schoolEduCode: JSON.parse(Cookies.get('schoolInfo')) ? JSON.parse(Cookies.get('schoolInfo')).schoolEduCode : "",
        targetId: this.props.targetIdTwo,
        saveStatus: true,
        selectTargetFundInvestVo: this.props.moneyPerformTwoVo112,
        year: new Date().getFullYear(),
        // moneyPerformTwoVo112:this.props.moneyPerformTwoVo112
    };

    componentDidMount() {
        console.log(this.state.selectTargetFundInvestVo)
        console.log(this.state.selectTargetFundInvestVo)
        console.log(this.state.selectTargetFundInvestVo)
        console.log(this.state.selectTargetFundInvestVo)
    }

    //保存
    save = (e) => {
        const {saveStatus} = this.state
        if (saveStatus) {
            this.setState({saveStatus: false})
            e.preventDefault();
            this.props.form.validateFields((err, values) => {
                let parmes = []
                if (!err) {
                    this.state.selectTargetFundInvestVo.map((item, index) => {
                        item.targetFundInvestVoList.map((todo, k) => {
                            let data = {
                                "displayOrder": k,
                                "investMoney": values[`investMoney${item.type}${k}`],
                                "receivedMoney": values[`receivedMoney${item.type}${k}`],
                                "moneyAccount": values[`moneyAccount${item.type}${k}`],
                                "type": item.type,
                                "year": 2020 + index,
                            }
                            parmes.push(data)
                        })

                    })
                    // let investInYearQos = []
                    saveTargetFundInvest({
                        "targetId": this.state.targetId,
                        "schoolEduCode": this.state.schoolEduCode, targetFundInvestQoList: parmes
                    }).then(res => {
                        message.success("保存成功")
                    })
                } else {
                    window.scrollTo({
                        top: 390,
                        behavior: 'smooth',
                    });
                }
            })
            setTimeout(() => {
                this.setState({saveStatus: true})
            }, 3000)
        }
    }

    handleTotalOneInt = () => {
        let total = 0
        // let value1 = this.props.form.getFieldValue(`investMoney10`) ? parseInt(this.props.form.getFieldValue(`investMoney10`)) : 0
        // let value2 = this.props.form.getFieldValue(`investMoney20`) ? parseInt(this.props.form.getFieldValue(`investMoney20`)) : 0
        // let value3 = this.props.form.getFieldValue(`investMoney30`) ? parseInt(this.props.form.getFieldValue(`investMoney30`)) : 0
        // total = total + value1 + value2 + value3

        const value1 = this.state.selectTargetFundInvestVo[0].targetFundInvestVoList[0].investMoney ?? 0
        const value2 = this.state.selectTargetFundInvestVo[1].targetFundInvestVoList[0].investMoney ?? 0
        const value3 = this.state.selectTargetFundInvestVo[2].targetFundInvestVoList[0].investMoney ?? 0
        total = total + value1 + value2 + value3
        return total
    }
    //合计
    handleTotalOne = () => {
        let total = 0
        // let value1 = this.props.form.getFieldValue(`investMoney10`) ? parseInt(this.props.form.getFieldValue(`investMoney10`)) : 0
        // let value2 = this.props.form.getFieldValue(`investMoney20`) ? parseInt(this.props.form.getFieldValue(`investMoney20`)) : 0
        // let value3 = this.props.form.getFieldValue(`investMoney30`) ? parseInt(this.props.form.getFieldValue(`investMoney30`)) : 0
        // total = total + value1 + value2 + value3

        const value1 = this.state.selectTargetFundInvestVo[0].targetFundInvestVoList[0].investMoney ?? 0
        const value2 = this.state.selectTargetFundInvestVo[1].targetFundInvestVoList[0].investMoney ?? 0
        const value3 = this.state.selectTargetFundInvestVo[2].targetFundInvestVoList[0].investMoney ?? 0
        total = total + value1 + value2 + value3


        return total.toLocaleString()
    }
    //合计
    handleTotalTwo = () => {
        let total = 0
        let value1 = this.props.form.getFieldValue(`receivedMoney10`) ? parseInt(this.props.form.getFieldValue(`receivedMoney10`)) : 0
        let value2 = this.props.form.getFieldValue(`receivedMoney20`) ? parseInt(this.props.form.getFieldValue(`receivedMoney20`)) : 0
        let value3 = this.props.form.getFieldValue(`receivedMoney30`) ? parseInt(this.props.form.getFieldValue(`receivedMoney30`)) : 0
        total = total + value1 + value2 + value3
        return total.toLocaleString()
    }
    handleTotalTwoInt = () => {
        let total = 0
        let value1 = this.props.form.getFieldValue(`receivedMoney10`) ? parseInt(this.props.form.getFieldValue(`receivedMoney10`)) : 0
        let value2 = this.props.form.getFieldValue(`receivedMoney20`) ? parseInt(this.props.form.getFieldValue(`receivedMoney20`)) : 0
        let value3 = this.props.form.getFieldValue(`receivedMoney30`) ? parseInt(this.props.form.getFieldValue(`receivedMoney30`)) : 0
        total = total + value1 + value2 + value3
        return total
    }
    handleTotalThreeInt = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`moneyAccount10`) ? parseInt(this.props.form.getFieldValue(`moneyAccount10`)) : 0
        let value2 = this.props.form.getFieldValue(`moneyAccount20`) ? parseInt(this.props.form.getFieldValue(`moneyAccount20`)) : 0
        let value3 = this.props.form.getFieldValue(`moneyAccount30`) ? parseInt(this.props.form.getFieldValue(`moneyAccount30`)) : 0
        total = total + value1 + value2 + value3
        const value = this.handleTotalOneInt()
        if (value === 0 || value === '0') {
            return 0
        }
        // return (first * 100 / value).toFixed(2)
        // alert((parseInt(this.handleTotalTwo()) * 100 / value).toFixed(2))
        return (this.handleTotalTwoInt() * 100 / value);
    }
    //合计
    handleTotalThree = () => {
        return this.handleTotalThreeInt().toFixed(2)
    }
    //合计
    handleTotalFour = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`investMoney11`)
        let value2 = this.props.form.getFieldValue(`investMoney21`)
        let value3 = this.props.form.getFieldValue(`investMoney31`)
        if (!value1 || !value2 || !value3) {
            return "-"
        }
        total = total + value1 + value2 + value3;
        return total.toLocaleString()
    }
    //合计
    handleTotalFive = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`receivedMoney11`)
        let value2 = this.props.form.getFieldValue(`receivedMoney21`)
        let value3 = this.props.form.getFieldValue(`receivedMoney31`)
        if (!value1 || !value2 || !value3) {
            return "-"
        }
        total = total + value1 + value2 + value3;
        return total.toLocaleString()
    }
    //合计
    handleTotalsix = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`moneyAccount11`) ? parseInt(this.props.form.getFieldValue(`moneyAccount11`)) : 0
        let value2 = this.props.form.getFieldValue(`moneyAccount21`) ? parseInt(this.props.form.getFieldValue(`moneyAccount21`)) : 0
        let value3 = this.props.form.getFieldValue(`moneyAccount31`) ? parseInt(this.props.form.getFieldValue(`moneyAccount31`)) : 0
        total = total + value1 + value2 + value3
        // return total.toLocaleString()
        return "-"
    }
    //合计
    handleTotalseven = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`investMoney12`) ? parseInt(this.props.form.getFieldValue(`investMoney12`)) : 0
        let value2 = this.props.form.getFieldValue(`investMoney22`) ? parseInt(this.props.form.getFieldValue(`investMoney22`)) : 0
        let value3 = this.props.form.getFieldValue(`investMoney32`) ? parseInt(this.props.form.getFieldValue(`investMoney32`)) : 0
        total = total + value1 + value2 + value3
        // return total.toLocaleString()
        return "-"
    }
    //合计
    handleTotaleight = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`receivedMoney12`) ? parseInt(this.props.form.getFieldValue(`receivedMoney12`)) : 0
        let value2 = this.props.form.getFieldValue(`receivedMoney22`) ? parseInt(this.props.form.getFieldValue(`receivedMoney22`)) : 0
        let value3 = this.props.form.getFieldValue(`receivedMoney32`) ? parseInt(this.props.form.getFieldValue(`receivedMoney32`)) : 0
        total = total + value1 + value2 + value3
        // return total.toLocaleString()
        return "-"
    }
    //合计
    handleTotalnine = () => {
        let total = 0

        let value1 = this.props.form.getFieldValue(`moneyAccount12`) ? parseInt(this.props.form.getFieldValue(`moneyAccount12`)) : 0
        let value2 = this.props.form.getFieldValue(`moneyAccount22`) ? parseInt(this.props.form.getFieldValue(`moneyAccount22`)) : 0
        let value3 = this.props.form.getFieldValue(`moneyAccount32`) ? parseInt(this.props.form.getFieldValue(`moneyAccount32`)) : 0
        total = total + value1 + value2 + value3
        // return total.toLocaleString()
        return "-"
    }

    render() {
        const roleType = getRoleType()
        const canEdit = roleType === RoleType.school
        const {getFieldDecorator} = this.props.form
        const {selectTargetFundInvestVo} = this.state;
        // console.log(selectTargetFundInvestVo)

        return (
            <div className="Budgetarrival">
                <div className="bottom_table">
                    <div className="mactable_cont">
                        <ul>
                            <li className="fira">
                                <h5>来源渠道</h5>
                            </li>
                            <li>
                                <span className="span_title">2021资金预算（元）</span>
                            </li>
                            <li>
                                <span className="span_title">2021实际到账资金（元）</span>
                            </li>
                            <li>
                                <span className="span_title">资金到账率（%）</span>
                            </li>
                            <li>
                                <span className="span_title">2022资金预算（元）</span>
                            </li>
                            <li>
                                <span className="span_title">2022实际到账资金（元）</span>
                            </li>
                            <li>
                                <span className="span_title">资金到账率（%）</span>
                            </li>
                            <li>
                                <span className="span_title">2023资金预算（元）</span>
                            </li>
                            <li>
                                <span className="span_title">2023实际到账资金（元） </span>
                            </li>
                            <li>
                                <span className="span_title">资金到账率（%）</span>
                            </li>
                        </ul>
                        {selectTargetFundInvestVo ? selectTargetFundInvestVo.map((item, index) => (
                            <ul>
                                <li className="fira">
                                    <h5>{item.type === 1 ? "政府" : item.type === 2 ? "学校" : item.type === 3 ? "行业企业" : ""}</h5>
                                </li>
                                {item ? item.targetFundInvestVoList.map((itemTwo, indexTwo) => {
                                    const percentValue = itemTwo.investMoney ? (this.props.form.getFieldValue(`receivedMoney${item.type}${indexTwo}`) / itemTwo.investMoney * 100).toFixed(2) : '';
                                    // const percentValue = this.props.form.getFieldValue(`investMoney${item.type}${indexTwo}`) ? (this.props.form.getFieldValue(`receivedMoney${item.type}${indexTwo}`) / this.props.form.getFieldValue(`investMoney${item.type}${indexTwo}`) * 100).toFixed(2) : '';
                                    // 输入1.1.1的时候,这里读取预算会是修改之前的值,不合适,但是直接读取属性则没问题
                                    // 本身的值则可以通过表单获取正确

                                    //itemTwo.receivedMoney是死的值,是请求后带过来的初始值,计算的时候不合适
                                    // console.log(itemTwo.receivedMoney)
                                    // console.log(this.props.form.getFieldValue(`receivedMoney${item.type}${indexTwo}`));
                                    console.log(itemTwo.receivedMoney)
                                    console.log(this.props.form.getFieldValue(`receivedMoney${item.type}${indexTwo}`));
                                    // const percentValue = itemTwo.investMoney ? (itemTwo.receivedMoney / itemTwo.investMoney * 100).toFixed(2) : ""
                                    return ((
                                        <div>
                                            <li>
                                                <Form.Item className="ro-cont">
                                                    {getFieldDecorator(`investMoney${item.type}${indexTwo}`, {
                                                        initialValue: itemTwo.investMoney ? itemTwo.investMoney : null,
                                                        rules: [
                                                            {
                                                                required: indexTwo <= 0,
                                                                pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                                                message: `请输入0或正整数(元)`,
                                                            },
                                                        ],
                                                    })
                                                    (<InputNumber
                                                        formatter={value => {
                                                            return itemTwo.investMoney ? itemTwo.investMoney : null
                                                        }} min={0} max={999999999} disabled={true}/>)
                                                    }
                                                </Form.Item>
                                            </li>
                                            <li>
                                                <Form.Item className="ro-cont">
                                                    {getFieldDecorator(`receivedMoney${item.type}${indexTwo}`, {
                                                        initialValue: itemTwo.receivedMoney ? itemTwo.receivedMoney : null,
                                                        rules: [
                                                            {
                                                                required: indexTwo > 0 ? false : true,
                                                                pattern: new RegExp(/^[0-9]\d*$/, "g"),
                                                                message: `请输入0或正整数(元)`,
                                                            },
                                                        ],
                                                    })
                                                    (<InputNumber
                                                        onChange={(e) => this.props.onChange(e, item.type, indexTwo)}
                                                        min={0} max={999999999}
                                                        disabled={canEdit ? (indexTwo > 0) : true}/>)
                                                    }
                                                </Form.Item>
                                            </li>
                                            <li>
                                                <Form.Item className="ro-cont">
                                                    {getFieldDecorator(`moneyAccount${item.type}${indexTwo}`, {
                                                        initialValue: percentValue,
                                                        rules: [
                                                            {
                                                                required: false,
                                                                // pattern:new RegExp(/^[0-9]\d*$/,"g"),
                                                                message: `请输入0或正整数(元)`,
                                                            },
                                                        ],
                                                    })
                                                    (<InputNumber
                                                        formatter={value => percentValue ? percentValue + "%" : null}
                                                        min={0} max={999999999} disabled={true}/>
                                                    )}
                                                </Form.Item>
                                            </li>
                                        </div>
                                    ))
                                }) : ""}
                            </ul>
                        )) : ""}
                        <ul>
                            <li className="fira">
                                <h5>总金额合计</h5>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalOne()}</span>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalTwo()}</span>
                            </li>
                            <li className="last-li">
                                <Form.Item className="ro-cont"
                                           validateStatus={this.handleTotalThreeInt() > 90 ? 'success' : 'error'}
                                           help={this.handleTotalThreeInt() > 90 ? "" : "*该项指标需高于90%"}>
                                    {getFieldDecorator('receiveTotal', {
                                        initialValue: this.handleTotalThree(),
                                    })
                                    (<InputNumber formatter={(value) => value + "%"} value={this.handleTotalThree()}
                                                  min={0} max={999999999}
                                                  disabled={true}/>)
                                    }
                                </Form.Item>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalFour()}</span>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalFive()}</span>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalsix()}</span>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalseven()}</span>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotaleight()}</span>
                            </li>
                            <li className="last-li">
                                <span>{this.handleTotalnine()}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                {canEdit ? <div className="bottom_but">
                    {/*<Button>重置</Button>*/}
                    <Button onClick={this.save} type="primary">保存</Button>
                </div> : ""}
            </div>
        );
    }
}

Budgetarrival.defaultProps = {
    // 实际到账金额的变动
    onChange: (e, item, indexTwo) => {

    }
}

export default Form.create()(Budgetarrival);
