// 资金执行
import React from "react";
import { Button,  Form } from 'antd';

import RectCmp from './components/Rect'

import { withRouter } from "react-router-dom";
import "./index.scss";


export default Form.create()(
    withRouter(
        class MoneyPerform extends React.Component {
            timer;
            state = {
center:true,
                step: 0,
                displayData: [0],
                data: [
                    {
                        row: 1, dir: 1, data: [{
                            id: '1', text: '接收上级部门党务文件并领会文件精神及细则', displayOrder: 1, dir: 'L'
                        },
                        {
                            id: '2', text: '向党总支书记汇报并取得处理意见', displayOrder: 2, dir: 'L'
                        },
                        {
                            id: '3', text: '行政会组织党员领导干部学习文件精神', displayOrder: 3, dir: 'L'
                        },
                        {
                            id: '4', text: '将文件下发各支部，并对文件精神进行学习、落实', displayOrder: 4, dir: 'LD'
                        },]
                    },

                    {
                        row: 2, dir: -1, data: [{
                            id: '5', text: '将文件落实情况记录并上报上级部门', displayOrder: 5, dir: 'R'
                        },
                        {
                            id: '6', text: '将文件及相关材料存档', displayOrder: 6, dir: 'R'
                        }]
                    }
                ]
            };

            componentDidMount() {
                if (this.props.onRef) {
                    this.props.onRef(this);
                }
                let mainCont = document.getElementById('Fillside_warp')
                let mainContWith = mainCont && mainCont.offsetWidth
                let windowWidth = document.documentElement.clientWidth
                let leftMain = document.getElementById('leftcollect')
                if (leftMain) {
                    leftMain.style.left = (windowWidth - mainContWith) / 2
                }

                // 左侧跟随屏幕
                window.addEventListener('scroll', this.bindHandleScroll)
                // 左侧跟随屏幕


                this.setState({
                    linkPath: this.props.location.pathname.substr(1)
                })
            }



            // 左侧跟随屏幕
            bindHandleScroll = (event) => {
                let scrollTop = event.srcElement ? event.srcElement.documentElement.scrollTop : false || window.pageYOffset || (event.srcElement ? event.srcElement.body.scrollTop : 0)

                if (scrollTop > 160) {
                    document.getElementById("leftcollect") && document.getElementById("leftcollect").setAttribute("style", "top:100px;position:fixed;");
                } else {
                    document.getElementById("leftcollect") && document.getElementById("leftcollect").setAttribute("style", "top:0;position:relative;");
                }
            }


            onClick = (v) => {
                if (this.timer) {
                    clearInterval(this.timer)
                    this.timer = null;
                }
                let { displayData } = this.state
                if (!displayData.includes(v)) {
                    displayData.push(v)
                }
                this.setState({
                    step: v,
                    displayData
                })
            }

            onAutoPlay = () => {

                if (!this.timer) {
                    this.timer = setInterval(() => {
                        let { step, displayData } = this.state
                        if (step > 28) {
                            clearInterval(this.timer)
                            this.timer = null;
                            return;
                        }
                        step = step + 1;
                        if (!displayData.includes(step)) {
                            displayData.push(step)
                        }
                        this.setState({
                            step,
                            displayData
                        })
                    }, 2000)
                }
            }
           onCenter = () => {
                this.setState({
                    center:!this.state.center
                })
           }

            // section Render
            render() {
                let { step, data, displayData, center } = this.state
                let lineV = (arr) => {
                    let lth = arr.length - 1;
                    return arr[lth].height / 2;
                }
                let getArrawSize = params => {
                    if (params == 'L' || params == 'R') {
                        return { width: '68px', height: '10px' }
                    }
                    if (params == 'LD') {
                        return { width: '10px', height: '103px' }
                    }
                    return null
                }

                let displayStatus = (params) => {
                    return { display: step == params.displayOrder || displayData.includes(params.displayOrder) ? '' : 'none' }
                }

                let getImagePath = (params) => {
                    if (params == 'L') {
                        return `https://cdn.zjyjc.com/public_static/YouErYuan/arrow-left.png`
                    }
                    if (params == 'LD') {
                        return `https://cdn.zjyjc.com/public_static/YouErYuan/arrow-down.png`
                    }
                    if (params == 'R') {
                        return `https://cdn.zjyjc.com/public_static/YouErYuan/arrow-right.png`
                    }
                    return ''
                }

                // 将数据组成二维数组
                const rebuildData = (arr, condition = "LD") => {
                    let newData = []
                    while (arr.length > 0) {
                        let index = arr.findIndex(s => s.dir === condition);
                        if (index >= 0) {
                            newData.push(arr.splice(0, index + 1))
                        } else {
                            newData.push(arr.splice(0, arr.length))
                        }
                    }
                    return newData
                }

                let newData = rebuildData([...data])

                console.log(newData)


                let row1Data = data[0].data;
                let row2Data = data[1].data;
                return (
                   
                                            <div className="liuchengtu-wrapper">
                                                <div style={{ textAlign: 'right', paddingTop: 20, paddingBottom: 20 }}>
                                                {/* <Button onClick={this.onCenter} className='but-li' type="primary" style={{marginRight:20}}>居中</Button> */}
                                                    <Button onClick={this.onAutoPlay} className='but-li' type="primary">自动播放</Button>
                                                </div>

                                                <div style={{width:'877px', height:'900px', ...(center? {margin:'0 auto'} : {}) }} >

                                                    <ul className="horizontal" style={{ display: "flex", alignItems: "center", justifyContent: 'flex-start' }}>
                                                        <li>
                                                            <RectCmp text={row1Data[0].text} displayOrder={row1Data[0].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[0].displayOrder} />
                                                        </li>
                                                        <li style={{ ...displayStatus(row1Data[0]) }}>
                                                            <img src={getImagePath(row1Data[0].dir)} style={{ ...getArrawSize(row1Data[0].dir) }} />
                                                        </li>
                                                        <li style={{ ...displayStatus(row1Data[0]) }}>
                                                            <RectCmp text={row1Data[1].text} displayOrder={row1Data[1].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[1].displayOrder} /></li>


                                                        <li style={{ ...displayStatus(row1Data[1]) }}>
                                                            <img src={getImagePath(row1Data[1].dir)} style={{ ...getArrawSize(row1Data[1].dir) }} />
                                                        </li>
                                                        <li style={{ ...displayStatus(row1Data[1]) }}>
                                                            <RectCmp text={row1Data[2].text} displayOrder={row1Data[2].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[2].displayOrder} /></li>


                                                        <li style={{ ...displayStatus(row1Data[2]) }}>
                                                            <img src={getImagePath(row1Data[2].dir)} style={{ ...getArrawSize(row1Data[2].dir) }} />
                                                        </li>
                                                        <li style={{ ...displayStatus(row1Data[2]) }}>
                                                            <RectCmp text={row1Data[3].text} displayOrder={row1Data[3].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row1Data[3].displayOrder} /></li>

                                                    </ul>

                                                    <ul style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}>
                                                        <li style={{ ...displayStatus(row1Data[3]), paddingRight: '84px' }}>
                                                            <img src={getImagePath(row1Data[3].dir)} style={{ ...getArrawSize(row1Data[3].dir) }} />
                                                        </li>
                                                    </ul>

                                                    <ul className="horizontal" style={{ display: "flex", alignItems: "center", justifyContent: 'flex-end' }}>

                                                        <li style={{ ...displayStatus(row2Data[0]) }}>
                                                            <RectCmp text={row2Data[1].text} displayOrder={row2Data[1].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row2Data[1].displayOrder} /></li>

                                                        <li style={{ ...displayStatus(row2Data[0]) }}>
                                                            <img src={getImagePath('R')} style={{ ...getArrawSize('R') }} />
                                                        </li>
                                                        <li style={{ ...displayStatus(row1Data[3]) }}>
                                                            <RectCmp text={row2Data[0].text} displayOrder={row2Data[0].displayOrder} onClick={this.onClick} onClick={this.onClick} status={step == row2Data[0].displayOrder} />
                                                        </li>


                                                    </ul>

                                                </div>
                                            </div>
                                      
                );
            }
        }
    )
);
