// 项目情况-平台情况
import React from "react";
import {DatePicker, Form, Input, message, Radio, Select,Button,Tooltip,Spin} from 'antd';
import "./index.scss";
import { uploadFile } from '../../../../api/uploadFile';
import moment from "moment";
import {Upicon,Upicondele,Upiconwc} from "../../../../components/iconSvg";
function transTime(time) {
    return (
        new Date(time).toLocaleDateString().replace(/\//g, "-") +
        " " +
        new Date(time).toLocaleTimeString().slice(2)
    );
}
class AddZzcl extends React.Component {
    state = {
        address:'',
        lianjie:'',
        jidiName:'',
        neirong:'',
        tableData:[],
        cailiaoType: 1, //材料类型
        neirongType: 1,//内容类型
        cailiaoType1: 1,
        file: null,
    };

    componentDidMount() {
        this.props.onRef(this)
        const {editAppFlag,fileData,editAppInfo} = this.props
        if (editAppFlag){
            let data = null
            if (fileData){
                data = fileData
                data.originName = fileData.resourceName
            }
            this.setState({
                neirong: editAppInfo.applyName,
                neirongType: editAppInfo.applyType,
                jidiName: editAppInfo.baseName,
                cailiaoType: editAppInfo.type,
                file: data
            })
            if (editAppInfo.type === 1){
                this.setState({
                    lianjie: editAppInfo.linkAddress
                })
            }else if (editAppInfo.type === 2){
                this.setState({
                    address: editAppInfo.linkAddress
                })
            }
        }
    }
    //上传方法
    multipartUpload = async (e) => {
        let list = [...e.target.files];
        let { tableData } = this.state;
        let upFile = { originName:list[0].name }
        this.setState({
            file: upFile
        })
        let checkStatus=false
        list.forEach((k) => {
            const fileNameindex =  k.name.lastIndexOf('.'); // 取到文件名开始到最后一个点的长度
            const fileNameLen =  k.name.length; // 取到文件名长度
            const fileType =  k.name.substring(fileNameindex+1, fileNameLen); // 截
            const type =  k.name.substring(fileNameindex, fileNameLen); // 截
            if(!this.checkFileType(fileType)){
                this.mesError('您上传文件后缀不符，请查看格式说明')
                checkStatus=true
                return
            }
            if (tableData.find((v) => v.resourceName === k.name)){
                this.mesWarning('该资源已存在')
                document.getElementById("file").value = '';
                checkStatus=true
                return
            }
            if(k.size / 1048576>1024){
                this.mesWarning('资源大小不能超过1G')
                document.getElementById("file").value = '';
                checkStatus=true
                return
            }
            let ymd = moment(new Date()).format('YYYY-MM-DD');
            ymd = ymd.replace(/-/g, '');
            // let fieldId = Math.random().toString(2, 4).substr(5, 5) + new Date() * 1;
            // fieldId = `zjyd-space-front-guanli/${ymd}/${fieldId}`
            // k["fieldId"]=fieldId
            if (!tableData.find((v) => v.resourceName === k.name)) {
                tableData = []
                // console.log("kkkkkkkkkkkkk:",k)
                tableData.push({
                    // fieldId:fieldId,
                    originName: k.name,
                    fileSuffix: fileType,
                    fileSize: k.size>=1048576?(k.size / 1048576).toFixed(2) + "MB":(k.size / 1024).toFixed(2) + "K",
                    operationTime: transTime(k.lastModifiedDate),
                    time: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                    plan: 0,
                });
            }
        });
        //类型不符停止进行
        if (checkStatus){
            document.getElementById("file").value = '';
            return
        }
        // console.log("ttttt---")
        await this.setState({
            tableData: tableData,
        });
        let f = 0;
        let fn = async (a) => {
            f++;
            let da = await this.uploadFile(list[a]);
            if (da === 1 && f < list.length) {
                return fn(f);
            }
        };
        fn(f);
    };
    //上传文件
    uploadFile = async (v) => {
        const {fileType} = this.state
        if (!v) return;
        let opt = localStorage.getItem("uploadList")
            ? JSON.parse(localStorage.uploadList)
            : {};
        return new Promise((reslove, reject) => {
            // console.log('sd', v)
            if (v.size > 1024 * 1024 * 1024) {
                return this.mesWarning('文件大小≤1G');
            }
            const fromData = new FormData()
            fromData.append('file', v)
            fromData.append('folder', 'vb')
            uploadFile(fromData).then(res => {
                if (res) {
                    let upFile = {
                        originName:res.originFileName,
                        fileId:res.fpName,
                        fileSuffix:res.mediaType,
                        resourceId:res.id,
                        fileSize:(res.fileSize / 1024).toFixed(2)
                    }
                    this.setState({
                        file: upFile
                    })
                    reslove(1);
                }
            })
        });
    };
    //检测文件后缀是否符合要求
    checkFileType = (type) => {
        let checkType = "mp3、wav、wmv、doc/docx、xls/xlsx、ppt/pptx、pdf、txt、mp4、avi、rm/rmvb、mov、wmv、swf、flv、jpg、jpeg、png、bmp、gif";
        return checkType.includes(type)
    };
    //材料类型切换
    onChange = (e) =>{
        this.setState({cailiaoType: e.target.value})
    }
    // 内容类型切换
    changeType = (e) =>{
        this.setState({neirongType: e.target.value})
        if (e.target.value === 2){
            this.setState({
                cailiaoType: 1
            })
        }
    }
    //组件重置
    reset = () => {
        this.setState({
            fileResult: [],
            neirong: '',
            neirongType: 1,
            jidiName: '',
            cailiaoType: 1,
            cailiaoType1: 1,
            lianjie: '',
            address: '',
            file: null,
        })
        this.props.form.resetFields();
    };
    //返回上传list列表
    getTableData = () => {
        return this.state.file
    };
    //删除
    deleteFile =() =>{
        this.props.form.setFieldsValue({fileId: null});
        this.setState({
            file: null
        })
    }

    // 校验网址格式
    validatehttp =  (rule, value, callback) => {
        let pattern =  /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/|www\.)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~\/])+$/;
        if (value && !pattern.test(value)){
            callback('请输入正确网址链接')
        } else {
            callback();
        }
    }

    render() {
        const {getFieldDecorator }=this.props.form
        const {neirongType, cailiaoType,cailiaoType1,neirong,jidiName,lianjie,address,tableData,file} = this.state
        return (
            <div className="AddZzcl" >
                <Form>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>内容名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('neirong', {
                                initialValue: neirong,
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写内容名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input className="input" maxLength={30} placeholder={'请输入内容'} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`neirong`) ? this.props.form.getFieldValue(`neirong`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>内容类型</span>
                        {getFieldDecorator('neirongType', {
                            initialValue: neirongType,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择内容类型!'
                                },
                            ],
                        })(<Radio.Group onChange={this.changeType}>
                            <Radio value={1}>线上</Radio>
                            <Radio value={2}>线下</Radio>
                        </Radio.Group>)}
                    </Form.Item>
                    <Form.Item>
                        <span className={'label'}><span className='red'>*</span>基地名称</span>
                        <div className={'input-wrap'}>
                            {getFieldDecorator('jidiName', {
                                initialValue: jidiName,
                                rules: [
                                    {
                                        required: true,
                                        message: `请填写基地名称`,
                                    },
                                    {
                                        max: 30,
                                        message: "文本过长，最多30字"
                                    },
                                ],
                            })(
                                <Input className="input" maxLength={30} placeholder={'请输入虚拟仿真实训基地名称'} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`jidiName`) ? this.props.form.getFieldValue(`jidiName`).length : 0}/30</span>}/>
                            )}
                        </div>
                    </Form.Item>
                    {neirongType === 1 ?
                        <Form.Item className="li_fo">
                            <span className={'label'}><span className='red'>*</span>材料类型</span>
                            {getFieldDecorator('cailiaoType', {
                                initialValue: cailiaoType,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择材料类型!'
                                    },
                                ],
                            })(<Radio.Group onChange={this.onChange}>
                                <Radio value={1}>链接</Radio>
                                <Radio value={2}>线下地址</Radio>
                                <Radio value={3}>文档资料</Radio>
                            </Radio.Group>)}
                        </Form.Item> :
                        <Form.Item>
                            <span className={'label'}><span className='red'>*</span>材料类型</span>
                            {getFieldDecorator('cailiaoType1', {
                                initialValue: cailiaoType1,
                                rules: [
                                    {
                                        required: true,
                                        message: '请选择材料类型!'
                                    },
                                ],
                            })(<Radio.Group onChange={this.onChange} defaultValue={1}>
                                <Radio value={1}>链接</Radio>
                            </Radio.Group>)}
                        </Form.Item>
                    }
                    {cailiaoType === 1 ?
                        <Form.Item>
                            <div className={'input-wrap input-wrap1'}>
                                {getFieldDecorator('lianjie', {
                                    initialValue: lianjie,
                                    rules: [
                                        {
                                            required: true,
                                            message: `请填写链接`,
                                        },
                                        {
                                            validator:this.validatehttp
                                        }
                                    ],
                                })(
                                    <Input className="input" placeholder={'请输入网页地址，如http://'} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`lianjie`) ? this.props.form.getFieldValue(`lianjie`).length : 0}/30</span>}/>
                                )}
                            </div>
                        </Form.Item> : cailiaoType === 2 ?
                            <Form.Item>
                                <div className={'input-wrap input-wrap1'}>
                                    {getFieldDecorator('address', {
                                        initialValue: address,
                                        rules: [
                                            {
                                                required: true,
                                                message: `请填写通讯地址`,
                                            },
                                            {
                                                max: 30,
                                                message: "文本过长，最多30字"
                                            },
                                        ],
                                    })(
                                        <Input className="input" maxLength={30} placeholder={'请输入通讯地址'} suffix={<span className='len'>
                                {this.props.form.getFieldValue(`address`) ? this.props.form.getFieldValue(`address`).length : 0}/30</span>}/>
                                    )}
                                </div>
                            </Form.Item> :
                            <Form.Item className="li_formup li_formup_a">
                                {getFieldDecorator('cailiao', {
                                    initialValue: file,
                                    rules: [
                                        {
                                            required: true,
                                            message: `请选择支撑材料`,
                                        },
                                    ],
                                })(
                                    <div className="up_button">
                                        <div className="span_right">
                                            <label htmlFor="file"  className="inputlabelBox">
                                                <span><Upicon/>上传资料</span>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image"
                                                onChange={this.multipartUpload}
                                                name="myfile"
                                                id="file"
                                                style={{ display: "none" }}
                                            />
                                            <span className={'tip'}>
                                                支持：pdf、doc/docx、xls/xlsx<br/>ppt/pptx、txt、jpg、jpeg、png、bmp gif格式
                                            </span>
                                        </div>
                                        <ul className="bottom_tit">
                                            {/*上传中*/}
                                            {file ?<Tooltip placement="topLeft" title={file.originName}><li className="title_de">{file.fileId? <Upiconwc />: <Spin />}<span className="tie_span">{file.originName}</span><i className="Upicona" onClick={this.deleteFile}><Upicondele/></i> </li></Tooltip>: null}
                                            {/*已完成*/}
                                        </ul>
                                    </div>
                                )}
                            </Form.Item>
                    }

                </Form>
            </div>
        );
    }
}
AddZzcl = Form.create()(AddZzcl);
export default AddZzcl;
