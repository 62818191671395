import { post } from '../../until/axios_instans';
// 获取顶部流程List
export function getTaskProcessList(data) {
    return post(window.apiShixun+'/api/v1/taskprocess/getTaskProcessList', data);
}

export function completeFill(data) {
    return post(window.apiShixun+'/api/v1/taskBook/completeFill', data);
}






